import { Box, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import FabButtonOrder from '../../Common/components/FabButtonOrder';
import HideOnScroll from '../../Common/components/HideOnScroll';
import { useHeaderHeight } from '../../config/hooks/useHeaderHeight';
import * as ROUTES from '../../config/routes';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import CatalogsGridComponent from '../components/CatalogsGridComponent';

/**
 * Display sub categories in a grid
 */
const SubcategoriesPage = () => {

    const params = useParams<ROUTES.RouteParams>();
    const { selectedCatalog } = useTypedSelector((state: RootState) => state.locations);

    const showHeader = !selectedCatalog?.display || !selectedCatalog?.display.hide_header;

    const theme = useTheme();
    const headerHeight = useHeaderHeight();

    return (

        <Box
            p={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
            height="100%"
        >
            <Box>
                <HideOnScroll>

                    <Box
                        pb={1}
                        position="sticky"
                        style={{
                            position: 'sticky',
                            top: showHeader ? headerHeight : 0,
                            backgroundColor: theme.palette.background.default
                        }}
                    >

                    </Box>

                </HideOnScroll>

                <Box pt={2}>
                    <CatalogsGridComponent parentCategoryRef={params.categoryRef} />
                </Box>

            </Box>

            <Box position="sticky" bottom="0px">
                <FabButtonOrder />
            </Box>
        </Box>
    )
}


export default SubcategoriesPage
