import { ProductExtended } from "../../../model/ProductExtended/ProductExtended";
import { Money, moneyToNumber } from "../../common/models/Money";
import AnalyticsAddToCartEvent from "../models/AnalyticsAddToCartEvent";
import AnalyticsItem from "../models/AnalyticsItem";

export const analyticsHelper = {

    getAnalyticsItem(product: ProductExtended, skuRef: string, priceWithOptions?: Money, quantity?: number): AnalyticsItem {

        let price: number = 0;
        if (priceWithOptions) {
            price = moneyToNumber(priceWithOptions, false, product);
        } else {
            const sku = product.skus.find((sku) => sku.ref === skuRef);
            if (sku) {
                price = moneyToNumber(sku.reduced_price ?? sku.price, false, sku);
            }
        }

        const analyticsItem: AnalyticsItem = {
            item_id: product.ref,
            item_name: product.name,
            item_category: product.category_ref,
            item_variant: skuRef,
            price: price,
            quantity: quantity
        }
        return analyticsItem;
    },

    getAddToCartEvenForOneItem(accountId: string, locationId: string, currency: string, product: ProductExtended, skuRef: string, priceWithOptions: Money, quantity: number): AnalyticsAddToCartEvent {
        const analyticsItem = this.getAnalyticsItem(product, skuRef, priceWithOptions, quantity);
        const fullPrice = analyticsItem.price * quantity;
        const addToCartEvent: AnalyticsAddToCartEvent = {
            account_id: accountId,
            location_id: locationId,
            currency: currency,
            items: [analyticsItem],
            value: fullPrice
        }
        return addToCartEvent;
    }
}
export default analyticsHelper;