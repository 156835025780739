import { Location, SupportedPayementType, SupportedServiceType } from "../../../model/Location";
import { OrderInBase, OrderPayment, PaymentType } from "../../../model/Order";
import { addMoney, getCurrency, moneyToNumber, numberToMoney } from "../../common/models/Money";
import LocationPaymentFeeConfig from "../models/LocationPaymentFeeConfig";
import LocationSupportedPayments from "../models/LocationSupportedPayments";
import LyraMarketplaceCredentials from "../models/lyramarketplace/LyraMarketplaceCredentials";
import { OrderRefundStatus } from "../models/OrderRefundStatus";
import PaymentFeeConfig from "../models/PaymentFeeConfig";
import PaymentSplitBySeller from "../models/PaymentSplitBySeller";
import StripeConnectCredentials from "../models/stripeconnect/StripeConnectCredentials";
import { feesService } from "./FeesService";

class MarketplacePaymentHelper {
    getMarketplacePayment(location: Location, marketplacePaymentType?: PaymentType): SupportedPayementType | undefined {
        return location?.supported_payment_types?.find((payment) => {
            if (marketplacePaymentType) {
                return payment.type === marketplacePaymentType;
            }

            return payment.type === PaymentType.LYRA_MARKETPLACE || payment.type === PaymentType.STRIPE_CONNECT
        })
    }

    getSellerChildOrderPayments(orderPayments: OrderPayment[], sellerId: string): OrderPayment[] {
        const childOrderPayments: OrderPayment[] = [];
        orderPayments.forEach((orderPayment) => {
            if (orderPayment.split_by_seller && orderPayment.split_by_seller[sellerId]) {
                const sellerPart = orderPayment.split_by_seller[sellerId];
                const childOrderPayment: OrderPayment = {
                    ...orderPayment,
                    fee_amount: sellerPart.fee_amount,
                    amount: addMoney(sellerPart.amount, sellerPart.fee_amount),
                    seller_id: sellerId,
                    refunds: orderPayment.refunds?.filter((refund) => refund.seller_id === sellerId)
                }
                // TODO: check other parameters
                childOrderPayments.push(childOrderPayment);
            }
        });
        return childOrderPayments;
    }

    getMarketplaceChildLocationSellerId(parentLocation: Location, childLocation: Location): string | undefined | null {
        const marketplacePayment = this.getMarketplacePayment(parentLocation);
        if (marketplacePayment) {
            const childLocationPayment = this.getMarketplacePayment(childLocation, marketplacePayment.type);
            return childLocationPayment?.seller_id;
        }
        return null;
    }

    hasChildOrderSellers(order: OrderInBase) {
        if (order.child_orders) {
            for (let childOrderId in order.child_orders) {
                const childOrderState = order.child_orders[childOrderId]
                if (childOrderState.payment_seller_id && childOrderState.order_percentage && childOrderState.order_percentage > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    hasMultipleSellers(order: OrderInBase, paymentIntentId: string): boolean {
        const payments = order?.payments?.find((orderPayment) => orderPayment.payment_intent_id === paymentIntentId);
        if (payments) {
            if (payments.split_by_seller) {
                return Object.keys(payments.split_by_seller).length > 1;
            }
        }
        return false;
    }

    computePaymentSplitBySeller(order: OrderInBase, mainSellerId: string, feeConfig: PaymentFeeConfig, orderPayment: OrderPayment): PaymentSplitBySeller {

        const splitBySeller: PaymentSplitBySeller = {};
        const currency = getCurrency(orderPayment.amount);
        const paymentAmount = moneyToNumber(orderPayment.amount, true);

        if (this.hasChildOrderSellers(order)) {
            let leftPaymentAmount = paymentAmount;
            const percentageBySeller: { [sellerId: string]: number } = {}
            let totalPercentage = 0;
            if (order.child_orders) {
                for (let childOrderId in order.child_orders) {
                    const childOrderState = order.child_orders[childOrderId]
                    if (childOrderState.payment_seller_id && childOrderState.order_percentage) {
                        percentageBySeller[childOrderState.payment_seller_id] = childOrderState.order_percentage!;
                        totalPercentage += childOrderState.order_percentage!;

                        splitBySeller[childOrderState.payment_seller_id] = {
                            location_id: childOrderState.location_id,
                            order_id: childOrderState.order_id,
                            seller_id: childOrderState.payment_seller_id,
                            amount: numberToMoney(0, currency),
                            fee_amount: numberToMoney(0, currency),
                        };
                    }
                }
            }
            const remainingPercentage = Math.round(100 * (100 - totalPercentage)) / 100;
            if (remainingPercentage > 0) {
                percentageBySeller[mainSellerId] = remainingPercentage;
                splitBySeller[mainSellerId] = {
                    location_id: order.location_id,
                    order_id: order.id,
                    seller_id: mainSellerId,
                    amount: numberToMoney(0, currency),
                    fee_amount: numberToMoney(0, currency),
                };
            }

            let floatingParts: { seller_id: string; payment_amount: number; }[] = [];
            for (let sellerId in percentageBySeller) {
                const sellerPercentage = percentageBySeller[sellerId];
                let sellerPaymentAmount = paymentAmount * (sellerPercentage ?? 0) / 100;
                const sellerPaymentAmountFloatPart = sellerPaymentAmount - Math.floor(sellerPaymentAmount);
                floatingParts.push({ seller_id: sellerId, payment_amount: sellerPaymentAmountFloatPart });
                sellerPaymentAmount = Math.floor(sellerPaymentAmount);
                leftPaymentAmount -= sellerPaymentAmount;
                splitBySeller[sellerId].amount = numberToMoney(sellerPaymentAmount, currency, true);
            }

            // Handle cent rounding
            if (leftPaymentAmount > 0) {
                //  Sort by remaining part desc
                floatingParts = floatingParts.sort((a, b) => b.payment_amount - a.payment_amount);
                while (leftPaymentAmount > 0) {
                    for (let floatingPart of floatingParts) {
                        const sellerPart = splitBySeller[floatingPart.seller_id];
                        if (leftPaymentAmount > 0) {
                            sellerPart.amount = numberToMoney(moneyToNumber(sellerPart.amount, true) + 1, currency, true);
                            leftPaymentAmount -= 1;
                        }
                    }
                }
            }

            // Now that everything has been correctly rounded, remove refunds
            // and the compute marketplace fee
            for (let sellerId in splitBySeller) {
                const sellerPart = splitBySeller[sellerId];
                let sellerPartAmount = moneyToNumber(sellerPart.amount, true);
                const sellerRefunds = orderPayment.refunds?.filter((refund) => refund.seller_id === sellerId || (sellerId === mainSellerId && !refund.seller_id));
                sellerRefunds?.forEach((refund) => {
                    if (refund.status === OrderRefundStatus.REFUNDED) {
                        sellerPartAmount -= moneyToNumber(refund.amount, true);
                    }
                });
                if (sellerPartAmount > 0) {
                    const marketplaceFeeInCents = feesService.computeMarketplaceFee(sellerPartAmount / 100, feeConfig, true);
                    sellerPart.amount = numberToMoney(sellerPartAmount - marketplaceFeeInCents, currency, true);
                    sellerPart.fee_amount = numberToMoney(marketplaceFeeInCents, currency, true);
                } else {
                    sellerPart.amount = numberToMoney(0, currency);
                    sellerPart.fee_amount = numberToMoney(0, currency);
                }
            }
        } else {
            let leftPaymentAmount = paymentAmount;
            orderPayment.refunds?.forEach((refund) => {
                if (refund.status === OrderRefundStatus.REFUNDED) {
                    leftPaymentAmount -= moneyToNumber(refund.amount, true);
                }
            });
            const marketplaceFeeInCents = feesService.computeMarketplaceFee(leftPaymentAmount / 100, feeConfig, true);
            const sellerPaymentAmountInCents = leftPaymentAmount - marketplaceFeeInCents;
            splitBySeller[mainSellerId] = {
                location_id: order.location_id,
                order_id: order.id,
                seller_id: mainSellerId,
                amount: numberToMoney(sellerPaymentAmountInCents, currency, true),
                fee_amount: numberToMoney(marketplaceFeeInCents, currency, true),
            };
        }

        return splitBySeller;
    }

    /**
     * This function is used to get all the fee configs from the PSP credentials.
     * Not used to compute the fees for real!
     * @param locationSupportedPayments 
     * @param credentials 
     * @param paymentType 
     * @returns 
     */
    getFeeConfigsFromCredentials(
        locationSupportedPayments: LocationSupportedPayments,
        credentials: StripeConnectCredentials | LyraMarketplaceCredentials,
        paymentType: PaymentType.STRIPE_CONNECT | PaymentType.LYRA_MARKETPLACE
    ): LocationPaymentFeeConfig[] {
        const {
            id: locationId,
            name: locationName,
            account_id: accountId,
            supported_service_types: supportedServiceTypes,
            delivery_providers: deliveryProviders
        } = locationSupportedPayments;

        const feeConfigs: LocationPaymentFeeConfig[] = [];
        let marketplaceId = "";
        if (paymentType === PaymentType.LYRA_MARKETPLACE) {
            marketplaceId = (credentials as LyraMarketplaceCredentials).marketplace_uuid;
        } else {
            marketplaceId = (credentials as StripeConnectCredentials).marketplace_account_id;
        }

        if (credentials.fee_configs.length === 0) {
            return feeConfigs
        }

        supportedServiceTypes.forEach((serviceType) => {
            let filteredFeeConfigs: PaymentFeeConfig[] = []

            if (
                serviceType === SupportedServiceType.DELIVERY
                && deliveryProviders
                && deliveryProviders.length > 0
            ) {
                deliveryProviders.forEach((deliveryProvider) => {
                    const providerFeeConfigs = feesService.filterFeeConfigs(
                        credentials.fee_configs,
                        undefined,
                        serviceType,
                        undefined,
                        deliveryProvider.type,
                        credentials.fee_category || "standard",
                    )

                    filteredFeeConfigs = filteredFeeConfigs.concat(providerFeeConfigs)
                })

            } else {
                filteredFeeConfigs = feesService.filterFeeConfigs(
                    credentials.fee_configs,
                    undefined,
                    serviceType,
                    undefined,
                    undefined,
                    credentials.fee_category || "standard",
                )
            }

            filteredFeeConfigs.forEach((feeConfig) => {
                if (!feeConfigs.some((f) => feeConfig.ref === f.ref)) { // Avoiding duplicates
                    feeConfigs.push({
                        ...feeConfig,
                        marketplace_id: marketplaceId,
                        location_id: locationId,
                        account_id: accountId,
                        location_name: locationName,
                        payment_type: paymentType,
                    })
                }
            })
        })

        return feeConfigs;
    }
}
const marketplacePaymentHelper = new MarketplacePaymentHelper();
export default marketplacePaymentHelper;