import { isTemporallyAvailableForOrder } from "../../../functions/Helpers";
import { Catalog, Option, OptionList, Product, Sku } from "../../../model/Catalog";
import { Order, OrderOption } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import { getOrderItemExtended } from "../../orders/helpers/OrderHelpers";

class ProductService {

    getProductOptionListRefs = (product: Product): string[] | undefined => {
        // For now, same option list ref is supposed to be shared by skus
        const skusWithOptions = product.skus?.find(sku => sku.option_list_refs && sku.option_list_refs.length);
        return skusWithOptions?.option_list_refs;
    }

    getSkuOptionList = (sku: Sku, optionLists: OptionList[]): OptionList[] | null => {

        let skuOptionList: OptionList[] | null = null
        if (sku.option_list_refs) {
            sku.option_list_refs.forEach(optionListRef => {
                const optionList = optionLists.filter(optionList => optionList.ref === optionListRef)
                if (!skuOptionList) {
                    skuOptionList = []
                }
                skuOptionList.push(...optionList)
            })
            return skuOptionList
        }
        return null
    }

    getProductBasedOnSkuRef = (skuRef: string, catalog: Catalog): Product | undefined => {
        const foundProduct: Product | undefined = catalog.data.products.find((product: Product) => {
            // iter sku for eachProduct
            const foundSku = product.skus.find((sku: Sku) => {
                if (sku.ref === skuRef) {
                    return true;
                }
                return false;
            });
            if (foundSku) {
                return true;
            }
            return false;
        })
        return foundProduct;
    }

    /**
     * TODO: remove location id, catalog id ?
     * @param productRef 
     * @param products 
     * @param locationId 
     * @param catalogId 
     * @returns 
     */
    getProductBasedOnProductRef = (productRef: string, products: Product[], locationId: string, catalogId: string, doNotThrow?: boolean): Product | undefined => {
        let usedProduct = products.find(p => p.ref === productRef)

        if (!usedProduct) {
            if (!doNotThrow) {
                throw OrderError.PRODUCT_NOT_FOUND.withValue({
                    "location": locationId,
                    "catalog": catalogId,
                    "product": productRef
                })
            } else {
                return undefined;
            }
        } else {
            return usedProduct
        }

    }

    getSkuBasedOnSkuRefAndProduct = (skuRef: string, product: Product, locationId: string, catalogId: string, doNotThrow?: boolean): Sku | undefined => {
        let usedSku = product.skus.find(s => s.ref === skuRef)

        if (!usedSku) {
            if (!doNotThrow) {
                throw OrderError.SKU_NOT_FOUND.withValue({
                    "location": locationId,
                    "catalog": catalogId,
                    "product": product.ref,
                    "sku": skuRef
                })
            } else {
                return undefined;
            }
        } else {
            return usedSku
        }

    }

    getSkuBasedOnProducRefAndSkuRefAndProducts = (productRef: string, skuRef: string, products: Product[], locationId: string, catalogId: string): Sku => {
        const usedProduct = products.find(p => p.ref === productRef)

        if (usedProduct) {
            const usedSku = usedProduct.skus.find(s => s.ref === skuRef)

            if (usedSku) {

                return usedSku

            } else {
                throw OrderError.SKU_NOT_FOUND.withValue({
                    "location": locationId,
                    "catalog": catalogId,
                    "product": usedProduct.ref,
                    "sku": skuRef
                })
            }
        } else {
            throw OrderError.PRODUCT_NOT_FOUND.withValue({
                "location": locationId,
                "catalog": catalogId,
                "product": productRef,
                "sku": skuRef
            })
        }
    }

    /**
     * // TODO: test it
     * @param orderItems 
     * @param catalogProducts 
     * @param catalogOptionLists 
     * @param locationId 
     * @param catalogId 
     */
    checkAllOrderItemOptions = (
        order: Pick<Order, "items" | "service_type" | "end_preparation_time" | "expected_time">,
        catalogProducts: Product[],
        catalogOptionLists: OptionList[],
        locationId: string,
        catalogId: string,
        timezoneName: string,
    ) => {

        order.items.forEach((item) => {

            // Do not try to check options if the item has already been sent to the API
            if (item.update_id) {
                return;
            }

            const extendedItem = getOrderItemExtended(item, catalogProducts, catalogOptionLists, catalogId, locationId);

            if (extendedItem.skuOptionList) {
                const invalidOptionList = productService.checkOrderOptions(extendedItem.options, extendedItem.skuOptionList, order, timezoneName);
                if (invalidOptionList && invalidOptionList.length) {
                    const invalidOptionListRef = invalidOptionList.map((optionList: OptionList) => optionList.ref)
                    throw OrderError.INVALID_OPTIONS.withValue({
                        invalidOptionListRef,
                        invalidOptionList,
                        item,
                    })
                }
            }
        });
    }

    /**
     * Check that the list of give order options are valid = respecting min/max constraints
     * @param orderOptions 
     */
    checkOrderOptions(
        orderOptions: OrderOption[],
        skuOptionLists: OptionList[],
        order: Pick<Order, "service_type" | "end_preparation_time" | "expected_time">,
        timezoneName: string,
    ): OptionList[] | null {

        if (skuOptionLists) {

            // If at least one is invalid, return an array which will trigger an error above
            let invalidOptionList: OptionList[] | null = null;
            skuOptionLists.forEach((skuOptionList) => {

                // Checking min & max for a disabled option list makes no sense
                if (!this.isOptionListAvailable(
                    skuOptionList,
                    order,
                    timezoneName,
                )) {
                    return;
                }

                if (skuOptionList.min || skuOptionList.max) {
                    const relatedOrderOptions = orderOptions.filter((orderOption) => orderOption.option_list_ref === skuOptionList.ref);
                    if (skuOptionList.min && relatedOrderOptions.length < skuOptionList.min) {
                        if (!invalidOptionList) {
                            invalidOptionList = [];
                        }
                        invalidOptionList.push(skuOptionList);
                    } else if (skuOptionList.max && relatedOrderOptions.length > skuOptionList.max) {
                        if (!invalidOptionList) {
                            invalidOptionList = [];
                        }
                        invalidOptionList.push(skuOptionList);
                    }
                }
            })
            return invalidOptionList;
        }

        return null;
    }

    isOptionListAvailable = (
        optionList: OptionList,
        order: Pick<Order, "service_type" | "end_preparation_time" | "expected_time">,
        timezoneName: string,
    ): boolean => {
        return Boolean(
            !optionList.disable
            && (
                !optionList.restrictions
                || isTemporallyAvailableForOrder(order, timezoneName, optionList.restrictions, false)
            )
        );
    }

    /**
     * Check if an OptionList is disable
     * @param orderOptions 
     * @param optionLists 
     * @returns 
     */
    checkDisableOptionsList(orderOptions: OrderOption[], skuOptionList: OptionList[]): OptionList[] | null {
        if (orderOptions && skuOptionList) {
            let OptionListDisable: OptionList[] | null = null
            skuOptionList.forEach((skuOptionList) => {
                const relatedOrderOption: OrderOption[] = orderOptions.filter(orderOption => orderOption.option_list_ref === skuOptionList.ref)
                if (relatedOrderOption && relatedOrderOption.length && skuOptionList.disable) {
                    if (!OptionListDisable) {
                        OptionListDisable = []
                    }
                    OptionListDisable.push(skuOptionList)
                }
            })
            return OptionListDisable;
        }
        return null
    }

    /**
     * Check if OrderOptions are disable
     * @param orderOptions 
     * @param optionLists 
     * @returns 
     */
    checkDisableOptions(orderOptions: OrderOption[], skuOptionLists: OptionList[], catalogOptions: Option[]): OrderOption[] | null {

        if (orderOptions && skuOptionLists) {

            let disableOptions: OrderOption[] | null = null
            skuOptionLists.forEach((skuOptionList: OptionList) => {

                // define if optionList is present in order
                const relatedOrderOptions: OrderOption[] = orderOptions.filter((orderOption: OrderOption) => orderOption.option_list_ref === skuOptionList.ref)
                if (relatedOrderOptions && relatedOrderOptions.length) {

                    // retrieve Option in optionList and check disable parameter
                    relatedOrderOptions.forEach((orderOption: OrderOption) => {

                        const relatedOption = catalogOptions.find(o => o.ref === orderOption.ref)
                        if (relatedOption && relatedOption.disable) {

                            if (!disableOptions) {
                                disableOptions = []
                            }
                            disableOptions.push(orderOption)
                        }
                    })
                }
            })
            return disableOptions
        }
        return null
    }
}
const productService = new ProductService();
export default productService;