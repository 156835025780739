import { ACCOUNT_PARAM, ACCOUNT_ROUTE } from "../../accounts/configs/AccountsApiRoutes";
import { AUTHENTICATION_AUTHORIZE_KEYWORD } from "../../authentications/configs/AuthenticationApiRoutes";
import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import { EnvNames } from "../../common/configs/EnvNames";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_KEY, LOCATION_PARAM, LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";
import { ConnectorType } from "../models/ConnectorsType";

export const CONNECTOR_TYPE_KEY = "connectorType";
export const CONNECTOR_TYPE_PARAM = `:${CONNECTOR_TYPE_KEY}`;
export const CONNECTORS_LOCATION_BASE_ROUTE = `${LOCATION_ROUTE}/${MylemonadeScopes.CONNECTORS}`;
export const CONNECTOR_LOCATION_ROUTE = `${CONNECTORS_LOCATION_BASE_ROUTE}/${CONNECTOR_TYPE_PARAM}`;
export const CONNECTOR_ACCOUNT_ROUTE = `${ACCOUNT_ROUTE}/${MylemonadeScopes.CONNECTORS}/${CONNECTOR_TYPE_PARAM}`;
export const CONNECTOR_GENERAL_ROUTE = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.CONNECTORS}/${CONNECTOR_TYPE_PARAM}`;
export const CONNECTORS_GLOBAL_ROUTE = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.CONNECTORS}`;

export const CONNECTOR_ROUTE_IMPORT = `${CONNECTOR_ACCOUNT_ROUTE}/import`;
export const CONNECTOR_ROUTE_CONNECT = `${CONNECTOR_LOCATION_ROUTE}/connect`;
export const SUB_CONNCETOR_ROUTE = `${CONNECTOR_LOCATION_ROUTE}/subconnect`;
export const CONNECTOR_CREDENTIALS = `${CONNECTOR_LOCATION_ROUTE}/credentials`;

export const CONNECTOR_ROUTE_ORDERS_IMPORT_DATE = `${CONNECTOR_LOCATION_ROUTE}/orders/import-date`;
export const CONNECTOR_ROUTE_IMPORT_ORDERS = `${CONNECTOR_LOCATION_ROUTE}/import/orders`;
export const CONNECTOR_ROUTE_EXPORTS = `${CONNECTOR_LOCATION_ROUTE}/exports`;
export const CONNECTOR_ROUTE_IMPORT_ORDERS_WEBHOOK = `${CONNECTOR_ROUTE_IMPORT_ORDERS}/webhook`;
export const CONNECTOR_ROUTE_GET_WALLET = `${CONNECTOR_LOCATION_ROUTE}/wallet`;

export const CONNECTOR_URL_REDIRECT_KEY = "redirectUrl";
export const CONNECTOR_EMAIL_KEY = "email";
export const CONNECTOR_FORCE_ENV = "env";
export const CONNECTOR_ROUTE_GET_OAUTH_URL = `${CONNECTOR_ACCOUNT_ROUTE}/${AUTHENTICATION_AUTHORIZE_KEYWORD}`;
export const CONNECTOR_ROUTE_AUTHENTICATION_LOCATIONS = `${CONNECTOR_GENERAL_ROUTE}/authentication`;
export const CONNECTOR_ROUTE_GET_LOCATIONS = `${CONNECTOR_GENERAL_ROUTE}/${MylemonadeScopes.LOCATIONS}`;
export const CONNECTOR_ROUTE_GET_CUSTOMERS = `${CONNECTOR_GENERAL_ROUTE}/${MylemonadeScopes.CUSTOMERS}`;
export const CONNECTOR_ROUTE_CATALOGS = `${CONNECTOR_GENERAL_ROUTE}/${MylemonadeScopes.CATALOGS}`;
export const CONNECTOR_ROUTE_EXPORT_CATALOGS = `${CONNECTOR_ROUTE_CATALOGS}/export`;
export const CONNECTOR_ROUTE_LOYALTY = `${CONNECTOR_GENERAL_ROUTE}/${MylemonadeScopes.LOYALTIES}`;
export const CONNECTOR_ROUTE_LOYALTY_SIMULATE_ORDER = `${CONNECTOR_ROUTE_LOYALTY}/simulate-order`;
export const CONNECTOR_ROUTE_LOYALTY_CONFIRM_ORDER = `${CONNECTOR_ROUTE_LOYALTY}/confirm-order`;
export const CONNECTOR_ROUTE_GET_DAILY_ORDERS = `${CONNECTORS_GLOBAL_ROUTE}/daily-orders`

export const IS_SUB_CONNECTOR_QUERY = "?isSubConnector=true"

export const ROUTE_CONNECTOR_ROOT = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.CONNECTORS}`;

const connectorsApiRoutes = {
    CONNECTORS_LOCATION_ROUTE: CONNECTORS_LOCATION_BASE_ROUTE,
    CONNECTOR_ROUTE_IMPORT,
    CONNECTOR_ROUTE_GET_CUSTOMERS,
    CONNECTOR_ROUTE_EXPORT_CATALOGS,
    CONNECTOR_ROUTE_LOYALTY_SIMULATE_ORDER,
    CONNECTOR_ROUTE_LOYALTY_CONFIRM_ORDER,
    CONNECTOR_ROUTE_GET_DAILY_ORDERS,
    CONNECTOR_ROUTE_GET_WALLET,
    CONNECTOR_CREDENTIALS,
};
export default connectorsApiRoutes;

export const getImportRoute = (accountId: string, connectorType: ConnectorType): string => {
    return CONNECTOR_ROUTE_IMPORT.replace(ACCOUNT_PARAM, accountId).replace(CONNECTOR_TYPE_PARAM, connectorType);
}

export const getConnectorRoute = (accountId: string, locationId: string, connectorType: ConnectorType, isSubConnector?: boolean) => {
    const url = CONNECTOR_LOCATION_ROUTE.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(CONNECTOR_TYPE_PARAM, connectorType);
    if (isSubConnector) {
        return url + IS_SUB_CONNECTOR_QUERY
    }
    return url
}

export const getConnectRoute = (accountId: string, locationId: string, connectorType: ConnectorType): string => {
    return CONNECTOR_ROUTE_CONNECT.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(CONNECTOR_TYPE_PARAM, connectorType);
}
export const getSubConnectorRoute = (accountId: string, locationId: string, connectorType: ConnectorType): string => {
    return SUB_CONNCETOR_ROUTE.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(CONNECTOR_TYPE_PARAM, connectorType);
}

export const getConnectorCredentialsRoute = (accountId: string, locationId: string, connectorType: ConnectorType): string => {
    return CONNECTOR_CREDENTIALS.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(CONNECTOR_TYPE_PARAM, connectorType);
}

export const getConnectorAuthenticationLocationsRoute = (connectorType: ConnectorType): string => {
    return CONNECTOR_ROUTE_AUTHENTICATION_LOCATIONS.replace(CONNECTOR_TYPE_PARAM, connectorType);
}

export const getConnectorOauthUrlRoute = (accountId: string, connectorType: ConnectorType, redirectUrl: string, email?: string, locationId?: string, forceEnv?: EnvNames): string => {
    let route = CONNECTOR_ROUTE_GET_OAUTH_URL.replace(ACCOUNT_PARAM, accountId).replace(CONNECTOR_TYPE_PARAM, connectorType);
    route = `${route}?${CONNECTOR_URL_REDIRECT_KEY}=${redirectUrl}`;
    if (email) {
        route = `${route}&${CONNECTOR_EMAIL_KEY}=${email}`;
    }
    if (locationId) {
        route = `${route}&${LOCATION_KEY}=${locationId}`;
    }
    if (forceEnv) {
        route = `${route}&${CONNECTOR_FORCE_ENV}=${forceEnv}`;
    }
    return route;
}

export const getConnectorGetLocationsRoute = (connectorType: ConnectorType): string => {
    return CONNECTOR_ROUTE_GET_LOCATIONS.replace(CONNECTOR_TYPE_PARAM, connectorType);
}

export const getConnectorExportCatalogRoute = (connectorType: ConnectorType): string => {
    return CONNECTOR_ROUTE_EXPORT_CATALOGS.replace(CONNECTOR_TYPE_PARAM, connectorType);
}

export const getConnectorGetWalletRoute = (accountId: string, locationId: string, connectorType: ConnectorType): string => {
    return CONNECTOR_ROUTE_GET_WALLET.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(CONNECTOR_TYPE_PARAM, connectorType);
}
