import { langCodes } from "./LangCodes"

/**
 * From https://github.com/Cloudoki/iso-language-codes
 */

/**
 * Takes a locale code and checks if exists in the data dictionary
 * @param {string} localeCode - Language code (e.g. "en-GB")
 * @return {boolean}
 */
export const validateLocaleCode = (localeCode: string): boolean => {
    return langCodes.findIndex((l) => l.code === localeCode) !== -1
}

/**
 * Takes a language code and checks if exists in the data dictionary
 * @param {string} languageCode - Language code (e.g. "en")
 * @return {boolean}
 */
export const validateLanguageCode = (languageCode: string) => {
    return langCodes.findIndex((l) => l.langCode === languageCode) !== -1
}

export const guessCountryCode = (languageCode: string): string => {

    if (languageCode === "en") {
        // GB for now, TODO: how to choose between GB and US
        return "gb";
    } else {
        // Try to find the country with the same countrycode as the language code
        const country = langCodes.find((l) => l.langCode === languageCode && l.countryCode === languageCode.toUpperCase());
        if (country) {
            return country.countryCode;
        } else {
            const firstCountry = langCodes.find((l) => l.langCode === languageCode);
            if (firstCountry) {
                return firstCountry.countryCode;
            } else {
                // Not found 
                return languageCode;
            }
        }
    }
}

/**
 * Takes a country code and returns the list of languages valid for it
 * @param {string} countryCode - Country code (e.g. "PT")
 * @return {string[]} Languages list
 */
export const findCountryLanguages = (countryCode: string): string[] => {
    const countryEntries = langCodes.filter((l) => l.countryCode === countryCode)
    const langSet = new Set<string>()

    countryEntries.forEach((e, i) => langSet.add(countryEntries[i].langCode))

    return Array.from(langSet)
}

/**
 * Takes a country code and returns the list of locales valid for it
 * @param {string} countryCode - Country code (e.g. "PT")
 * @return {string[]} Locales list
 */
export const findCountryLocales = (countryCode: string) => {
    const countryEntries = langCodes.filter((l) => l.countryCode === countryCode)
    const localeSet = new Set()

    countryEntries.forEach((e, i) => localeSet.add(countryEntries[i].code))

    return Array.from(localeSet)
}

/**
 * Returns a list with a map of all existing locale codes and the respective country+language
 * @return {Object} Locales list in the form of a map: ['pt-PT'] => 'Portuguese (PT)'
 */
export const locales = (): { [key: string]: string } => {
    const map: { [key: string]: string } = {}
    for (const l of langCodes) {
        map[l.code] = `${l.language} (${l.countryCode})`
    }
    return map
}
