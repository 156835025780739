import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, Divider, FormControlLabel, Typography, useTheme } from '@mui/material';
import reduce from "lodash/reduce";
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../Common/helper/TextHelper';
import { Option, OptionLine, OptionList } from '../../my-lemonade-library/model/Catalog';
import { OrderOption } from '../../my-lemonade-library/model/Order';
import { OptionLineExtBO } from '../../my-lemonade-library/src/catalogs/models/OptionLineExtBO';
import { OptionListExtBO } from '../../my-lemonade-library/src/catalogs/models/OptionListExtBO';
import { moneyToNumber, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import PlusMinusQuantityButton from '../../orders/components/PlusMinusQuantityButton';
import { RADIO_SIZE } from '../configs/styleConfig';


type ValueChangeEvent = {
    option: Option,
    option_line: OptionLine,
    option_list: OptionListExtBO,
    quantity: number,
    maxReached: boolean
}

interface ProductOptionsCheckBoxProps {
    option_list: OptionListExtBO,
    selected: OrderOption[],
    onChange: (value: ValueChangeEvent) => void,
    readonly: boolean,
}

const getOptionDisplayPrice = (option_list: OptionList, option_line: OptionLine, quantity: number): string | null => {
    if (option_list.max_before_extra) {
        const numberOfOptionsSelectedInList = quantity;
        if (
            numberOfOptionsSelectedInList
            && numberOfOptionsSelectedInList >= option_list.max_before_extra
            && option_line.extra_price
        ) {
            return MoneyToStringWithSymbol(option_line.extra_price);
        }
    }

    if (moneyToNumber(option_line.price) !== 0) {
        return MoneyToStringWithSymbol(option_line.price);
    }

    return null;
}

const ProductOptionsCheckBox: React.FC<ProductOptionsCheckBoxProps> = (props) => {

    const { option_list, selected, onChange, } = props

    const theme = useTheme();
    const intl = useIntl();

    const maxReached = useMemo(() => {
        if (typeof option_list.max !== "number") {
            return false;
        }

        return selected.length === option_list.max;
    }, [option_list.max, selected.length])

    const count_by_option = useMemo(() => reduce<OrderOption, { [option_ref: string]: number }>(selected,
        (acc, selected_option) => {
            const { ref } = selected_option;

            acc[ref] = (acc[ref] || 0) + 1;
            return acc;
        },
        {}
    ), [selected]);


    const renderItem = useCallback((option_line: OptionLineExtBO) => {
        const { option_ref, option } = option_line;
        const value = `${option_list.ref}-${option_ref}`;
        const quantity = count_by_option[option_ref] || 0;
        const checked = !!quantity;

        let label = capitalizeFirstLetter(
            intl.formatMessage({
                id: translationService.getOptionNameTranslationKey(option),
                defaultMessage: option.name,
            })
        )

        const display_price = getOptionDisplayPrice(option_list, option_line, selected.length);

        return (
            <Box key={value}>
                <Divider />
                <Box
                    display='flex'
                    flexDirection='row'
                    width={1}
                    justifyContent='space-between'
                    alignItems='center'
                    my={1}
                >
                    <FormControlLabel
                        data-test={`option_${option_ref}`}
                        style={{
                            display: "flex",
                            flex: 1
                        }}
                        control={
                            !props.readonly ?
                                <Checkbox
                                    data-test={`controller_option_${option_ref}`}
                                    icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color='primary'
                                    checked={checked}
                                    name={option.name}
                                    onChange={() => {
                                        onChange({ option, option_line, option_list, quantity: quantity ? 0 : 1, maxReached })
                                    }}
                                /> :
                                <Box data-test={`box_option_${option_ref}`} width={RADIO_SIZE} height={RADIO_SIZE}></Box>
                        }
                        label={label}
                    />
                    <Box
                        display='flex'
                        alignItems='center'
                    >
                        {checked && option_list.repeatable &&
                            <PlusMinusQuantityButton
                                quantity={quantity}
                                onAdd={() => {
                                    onChange({ option, option_line, option_list, quantity: quantity + 1, maxReached })
                                }}
                                onMinus={() => onChange({ option, option_line, option_list, quantity: quantity - 1, maxReached })}
                                smallButtons={true}
                                dataTestSuffix={`option_${option_ref}`}
                            />
                        }
                        {!!display_price &&
                            <Typography
                                variant="body1"
                                style={{
                                    color: theme.palette.text.disabled,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {display_price}
                            </Typography>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }, [count_by_option, intl, maxReached, onChange, option_list, props.readonly, theme.palette.text.disabled, selected])

    return (
        <Box width='100%'>
            {option_list.option_lines.filter((ol) => !ol.option.disable).map(renderItem)}
        </Box>
    )
}

export default ProductOptionsCheckBox;