import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router';
import useGetRestoTheme from '../../Common/customHooks/useGetRestoTheme';
import actions from "../../Locations/redux/LocationActions";
import * as ROUTES from '../../config/routes';
import { RouteParams } from '../../config/routes';
import TimeSelectCard from '../../customers/components/TimeSelectCard';
import TimeSelectForm from '../../customers/components/TimeSelectForm';
import ExpectedTimeAsap from '../../delivery/components/ExpectedTimeAsap';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import TimeSlot from '../../my-lemonade-library/src/common/models/TimeSlot';
import { orderActions } from "../../orders/redux/OrderActions";
import { RootState, useTypedSelector } from '../../redux/root-reducer';


/**
 * get the params in the URL (here we are looking for ?orderId=xxxxxx)
 * @returns 
 */
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface OrderErrorTimeSlotProps {
    errorCode: string
    errorValue: { [key: string]: any | any[] }

}
/**
 * Specific content for full or unavailable slot => ask new expected_time and redirect to OrderSummaryPage
 * TODO: if clearIcon click => reload location and redirect to categorie page
 * @param props 
 * @returns 
 */
const OrderErrorTimeSlot: React.FC<OrderErrorTimeSlotProps> = (props) => {

    const { errorCode, errorValue } = props;

    const theme = useTheme();
    const query = useQuery();
    const isGetRestoTheme = useGetRestoTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch<RouteParams>();

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { themeName } = useTypedSelector((state: RootState) => state.theme);
    const { selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const [displayDateTimeSelectors, setDisplayDateTimeSelectors] = useState<boolean>(true);
    const [newExpectedTime, setNewExpectedTime] = useState<Date | undefined>();
    const [isAsap, setIsAsap] = useState<boolean>(false);

    /**
     * Set date and time if selected or asap. Unset the other one
     * @param value 
     */
    const setDateTimeAndAsap = (asap: boolean, value?: Date): void => {
        setIsAsap(asap)
        setNewExpectedTime(value)
    }

    const onNewExepectedTimeValidation = () => {

        if (newExpectedTime || isAsap) {

            log.debug(`New expected time selected: ${newExpectedTime}, asap: ${isAsap}`);

            // Remove delivery delay to compute the end preparation time
            let endPreparationTime = newExpectedTime;
            if (newExpectedTime && selectedTable.service_type === SupportedServiceType.DELIVERY) {
                endPreparationTime = DateTime.fromJSDate(newExpectedTime).minus({ minutes: order.delivery?.delivery_delay ?? 0 }).toJSDate();
            }

            dispatch(orderActions.setExpectedTime(newExpectedTime, isAsap, endPreparationTime));
            history.push(generatePath(ROUTES.LocationHome + ROUTES.OrderSummaryPage, { tableLinkKey: match.params.tableLinkKey }));
        }
    }

    const onClearIconClick = () => {
        dispatch(actions.loadLocation(
            match.params.tableLinkKey,
            query,
            null,
            null,
            themeName,
        ));
        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
    }

    const timeSlot = errorValue.time_slot ? errorValue.time_slot as TimeSlot : null

    if (order) {
        return (
            <Box position={"relative"} px={2} flex={1} height={1} display={"flex"} width={1} flexDirection="column" alignItems={"center"}>
                <IconButton
                    style={{
                        position: "absolute",
                        top: -25,
                        right: -15
                    }}
                    onClick={onClearIconClick}
                    size="large">
                    <ClearIcon />
                </IconButton>
                <Box display={"flex"} flexDirection={"column"} alignItems="center" width={1}>
                    <Typography variant="h3" color="textSecondary" style={{ fontWeight: "bold", textAlign: "center" }}>
                        <FormattedMessage id="oops" />
                    </Typography>
                    <Typography variant="h3" color="textSecondary" style={{ fontWeight: "bold", textAlign: "center" }}>
                        <FormattedMessage id={`error.${errorCode}.descriptions`} values={{
                            hour: timeSlot && timeSlot.start_hour.split(':')[0],
                            minutes: timeSlot && timeSlot.start_hour.split(':')[1]
                        }} />
                    </Typography>
                </Box>
                <Box flex={1} width={1} display="flex" flexDirection={"column"} height={1} justifyContent="center">
                    <Typography variant="body1" color="textSecondary" style={{ textAlign: "center", padding: theme.spacing(3, 0, 3, 0) }}>
                        <FormattedMessage id={`error.${errorCode}.reason`} values={{
                            hour: timeSlot && timeSlot.start_hour.split(':')[0],
                            minutes: timeSlot && timeSlot.start_hour.split(':')[1]
                        }} />
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection='column'
                        width={1}
                        style={{ backgroundColor: theme.palette.background.paper }}
                        p={isGetRestoTheme ? 2 : 0}
                        border={isGetRestoTheme ? 1 : 0}
                        borderLeft={0}
                        borderRight={0}
                        borderColor='grey.300'
                    >

                        <ExpectedTimeAsap
                            onChange={setDateTimeAndAsap}
                            checked={isAsap}
                            dateTimeSelected={newExpectedTime}
                            serviceType={order.service_type}
                        />


                        <TimeSelectCard
                            onClick={() => {
                                setDateTimeAndAsap(false)
                                setDisplayDateTimeSelectors(true);
                            }}
                            checked={isAsap === false}
                        />
                        {
                            displayDateTimeSelectors
                            && isAsap === false
                            && setDateTimeAndAsap
                            &&

                            <TimeSelectForm
                                serviceType={order.service_type}
                                dateTimeSelected={newExpectedTime}
                                onDateTimeSelectedChange={setDateTimeAndAsap}
                                isGetRestoTheme={isGetRestoTheme}
                            />

                        }
                    </Box>

                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    data-test="order_error_timeslot.button.save"
                    style={{
                        textTransform: "none",
                        width: '100%',
                        padding: theme.spacing(1.5, 2)
                    }}
                    onClick={onNewExepectedTimeValidation}

                >
                    <FormattedMessage id={`error.${errorCode}.button.replace`} />
                </Button>
            </Box>
        );
    }
    return null
}

export default OrderErrorTimeSlot;