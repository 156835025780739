import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import DefaultBackground from "../../assets/bg_home.png"
import { subCategoriesList } from '../../Categories/services/categoriesServices'
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt'
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended'
import { RootState, useTypedSelector } from '../../redux/root-reducer'
import ProductModal from '../pages/ProductModal'
import ProductsCardsComponent from './ProductsCardsComponent'

type ProductsGridComponentProps = {
    rootCatRef: string
}

export const ProductsGridComponent = (props: ProductsGridComponentProps) => {

    const { rootCatRef } = props

    const { selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations)
    const { order } = useTypedSelector((state: RootState) => state.order)

    const [allItems, setAllItems] = useState<Array<ProductExtended | DealExt>>([])
    const [selectedItem, setSelectedItem] = useState<ProductExtended | DealExt | undefined>(undefined)
    const [note, setNote] = useState<string>('')

    /**
     * Getting the products to display.
     * We check if we have to display a main or a sub cat, then we filter
     * the products to display them.
     */
    useEffect(() => {
        if (selectedCatalog) {
            let products: (ProductExtended | DealExt)[] = []
            const rootCat = selectedCatalog.data.categories.find(cat => cat.ref === rootCatRef)

            if (rootCat) {
                const subCats = subCategoriesList(order, rootCat, selectedCatalog, selectedTable)

                if (subCats.length) {
                    products = subCats.reduce((acc: (ProductExtended | DealExt)[], subCat) => {
                        const subCatProducts = selectedCatalog.data.products.filter(prod => prod.category_ref === subCat.ref)
                        acc = acc.concat(subCatProducts)

                        return acc
                    }, [])

                } else {
                    products = selectedCatalog.data.products.filter(prod => prod.category_ref === rootCatRef)
                }
            }
            const deals: DealExt[] = selectedCatalog.data.deals.filter(deal => deal.category_ref === rootCatRef)
            const productsAndDeals: (ProductExtended | DealExt)[] = products.concat(deals)

            setAllItems(productsAndDeals)
        }

    }, [selectedCatalog])

    const getImage = (product: ProductExtended | DealExt) => {
        if (product.image) {
            return product.image
        } else if (selectedCatalog) {
            const image = selectedCatalog.data.categories.find(cat => cat.ref === product.category_ref)?.icon
            return image ? image : DefaultBackground
        } else {
            return DefaultBackground
        }
    }

    return (

        <Box data-test='product_grid_component'>

            {selectedItem &&
                <ProductModal
                    selectedProduct={selectedItem as ProductExtended}
                    open={selectedItem !== undefined}
                    closeModal={() => setSelectedItem(undefined)}
                    note={note}
                    setNote={setNote}
                    backToMenu
                />
            }

            {allItems.map((item, index) => index % 2 === 1 ?

                null
                :
                (<Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-evenly"
                >
                    <Box width="40%">

                        <ProductsCardsComponent
                            openModal={setSelectedItem}
                            item={item}
                            categoryIcon={getImage(allItems[index])}
                        />

                    </Box>

                    {allItems[index + 1] ?

                        <Box width="40%">
                            <ProductsCardsComponent
                                openModal={setSelectedItem}
                                item={allItems[index + 1]}
                                categoryIcon={getImage(allItems[index + 1])}
                            />

                        </Box>
                        :
                        <Box width="40%" />
                    }

                </Box >)
            )}
        </Box >
    );
}


export default ProductsGridComponent
