import LocationInvoicesConfig from "../../invoices/models/LocationInvoicesConfig";
import LocationCustomerActivationsConfig from "../../marketingActivation/models/LocationCustomerActivationsConfig";
import LocationPrinters from "../../printers/models/LocationPrinters";
import { Tips } from "../../tips/models/Tips";
import LocationOrdersCurrentDisplay from "./LocationOrdersCurrentDisplay";
import { PreparationTime } from "./PreparationTime";
import { PreparationTimeOverride } from "./PreparationTimeOverride";

/**
 * This number represents the number of minutes after which an order in
 * latestOrders > tableOrders won't be loaded. This is the final check before
 * loading it, after checking the setting webapp_table_orders. If the order is
 * "recent", it will be lodaded. Otherwise, it won't.
 * By default: after 15 minutes, a new draft order will be created
 */
export const DEFAULT_DELAY_TO_JOIN_SHARED_ORDERS = 15;

/**
* Config for printers, alarms, qrcode
*/
export default interface LocationOrdersConfig {

    /**
     * For backoffice only. Setups the tabs in the currentOrderSummary page, and
     * tells which status go to which tab.
     */
    current_display?: LocationOrdersCurrentDisplay;

    printers?: LocationPrinters;

    invoices?: LocationInvoicesConfig;

    preparation_times?: PreparationTime[];

    preparation_time_override?: PreparationTimeOverride;

    /**
     * If true, the user will be able to pay online an order that
     * has been finalized. For example, if the waiter is too long,
     * or he want to split the bill, he can go to the "myInformation" page, find
     * the order and pay it online.
     * If false, the pay button won't appear.
     */
    allow_online_payment_for_opened_orders?: boolean;

    /**
     * Can users edit an opened order (order alread sent to connector) before paying it
     */
    allow_edit_opened_orders?: boolean;

    /**
     * This variable controls the behaviour of the webapp when dealing with
     * tableOrders (in latestOrders).
     * 
     * NONE (or null, or undefined) -> the tableOrders won't be displayed in
     * the account page, and if there is a draft in tableOrders, it won't be
     * loaded.
     *  
     * FORCE_LOAD -> the tableOrders will be displayed in the account page,
     * and if there is a draft in tableOrders, it will be loaded (after checking
     * some conditions, see the flowChart in the README.)
     */
    webapp_table_orders?: WebappTableOrders;

    /**
     * This boolean controls whether or not the "share" icon will be displayed
     * in the webapp. If the icon is not displayed, the user won't be able to 
     * share the order.
     * WARNING: the process after scanning the QR Code is independant: users can
     * still contribute to an order if an order is going on the table and if the
     * setting webapp_table_orders is set to FORCE_LOAD (see above.)  
     */
    allow_share_order?: boolean;

    /**
     * This number represents the number of minutes after which an order in
     * latestOrders > tableOrders won't be loaded. This is the final check before
     * loading it, after checking the setting webapp_table_orders. If the order is 
     * "recent", it will be lodaded. Otherwise, it won't.
     */
    delay_to_join_shared_order?: number;

    /**
     * This settings controls whether the tableOrders of the webapp will be
     * fetched from the API (and so maybe from other services such as Zelty) or
     * from firestore directly (only our orders).
     */
    allow_fetch_external_table_orders?: boolean;

    /**
     * UNIT: SECONDS.
     * When the setting "allow_fetch_external_table_orders" is set to true and there is a
     * connector available, the webapp will refresh the current order each time the user goes to
     * the summary page or the home page. 
     * This delay is here to ensure that the webapp won't refresh the order too often (for example, when in
     * checkout service_type).
     */
    delay_before_refreshing_order_again?: boolean;

    /**
     * On the webapp, we won't display any timeslots after this time limit (in days).
     * For example if we set it to 2, when on the webapp the user will only be able
     * to place an order for today and tomorrow.
     * default value, can be overriden by restrictions
    */
    default_timeslots_display_time_limit?: number | null;

    disable_auto_deal?: boolean;

    tips?: Tips

    customer_activations?: LocationCustomerActivationsConfig;
}

export enum WebappTableOrders {

    NONE = "none",
    FORCE_LOAD = "force_load"
}

export const DEFAULT_DELAY_BEFORE_REFRESHING_ORDER_AGAIN = 15;