import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import React from 'react'
import { useIntl } from 'react-intl'
import { getTimezoneName } from '../../../my-lemonade-library/model/Location'
import { RootState, useTypedSelector } from '../../../redux/root-reducer'

interface DeliveryAndCheckoutDelayProps {
    parentName?: string
}

const DeliveryAndCheckoutDelay: React.FC<DeliveryAndCheckoutDelayProps> = (props) => {

    const intl = useIntl()

    const { currentLang } = useTypedSelector((state: RootState) => state.lang)
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)
    const { order } = useTypedSelector((state: RootState) => state.order)

    // Calculate the delivery or collection delay
    const displayDelay = (): string => {

        if (order.expected_time) {
            const expectedTime = DateTime.fromJSDate(order.expected_time)
            const isoDate: string = expectedTime.setZone(getTimezoneName(selectedLocation)).setLocale(currentLang).toLocaleString({ weekday: 'short', month: 'short', day: '2-digit' });
            const expectedTimeHour = expectedTime.setZone(getTimezoneName(selectedLocation)).setLocale(currentLang).toLocaleString(DateTime.TIME_SIMPLE);
            if (expectedTime.ordinal === DateTime.now().ordinal) {
                return intl.formatMessage({ id: `header.${order.service_type}.date_today` }, { hour: expectedTimeHour });
            } else if (expectedTime.ordinal === DateTime.now().ordinal + 1) {
                return intl.formatMessage({ id: `header.${order.service_type}.date_tomorrow` }, { hour: expectedTimeHour });
            } else {
                return intl.formatMessage({ id: `header.${order.service_type}.date` }, { date: isoDate, hour: expectedTimeHour });
            }
        }

        return ''
    }

    return (
        <Typography
            data-test={`${props.parentName || ''}delivery-and-checkout-delay`}
            variant='caption'
            color='primary'
        >
            {displayDelay()}
        </Typography>
    )
}

export default DeliveryAndCheckoutDelay