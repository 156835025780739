import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import { ORDER_ID_PARAM_KEY } from "../../orders/configs/OrdersApiRoutes";
import { THEME_QUERY_PARAM_NAME } from "../../theme/models/ThemeRouterConfig";
import { ThemeList } from "../../theme/models/ThemesList";
import { UserAccessRole } from "../../users/models/UserAccessRole";
import { AUTHENTICATION_AUTHORIZE_KEYWORD, AUTHENTICATION_PROVIDER_KEY } from "../configs/AuthenticationApiRoutes";
import { FIRESTORE_CUSTOMER_COLLECTION, FIRESTORE_USERS_COLLECTION } from '../configs/AuthenticationConfig';
import { SignInProviders } from "../models/BaseUser";
import Permissions from "../models/Permissions";
import USER_ROLE_PERMISSIONS from "../models/UserRolePermissions";

export const getCustomerFirestoreCollectionPath = (account_id: string, location_id: string): string => {
    return `${getLocationFirestoreDocPath(account_id, location_id)}/${FIRESTORE_CUSTOMER_COLLECTION}`
}

export const getCustomerFirestoreDocPath = (account_id: string, location_id: string, customer_id: string): string => {
    return `${getCustomerFirestoreCollectionPath(account_id, location_id)}/${customer_id}`
}

export const getUserFirestoreDocPath = (user_id: string): string => {
    return `${FIRESTORE_USERS_COLLECTION}/${user_id}`;
}

export const getTermsFirestoreCollectionPath = (): string => {
    return "terms";
}

export const getTermsFirestoreDocumentPath = (doc_id: string): string => {
    return `${getTermsFirestoreCollectionPath()}/${doc_id}`
}

export const checkRolePermissions = (role: UserAccessRole, permissions: Permissions): boolean => {
    const userPermissions = USER_ROLE_PERMISSIONS[role]

    return !!userPermissions?.[permissions]
}

export const getOauth2RedirectUrl = (host: string, authProvider: SignInProviders, themeName?: ThemeList): string => {
    let redirectUrl = `${host}/${AUTHENTICATION_AUTHORIZE_KEYWORD}?${AUTHENTICATION_PROVIDER_KEY}=${authProvider}`;
    if (themeName) {
        redirectUrl = `${redirectUrl}&${THEME_QUERY_PARAM_NAME}=${themeName}`;
    }
    return redirectUrl;
}

export const getSigninWithEmailRedirectUrl = (currentUrl: string, authProvider: SignInProviders, themeName?: ThemeList, orderId?: string): string => {
    let redirectUrl = `${currentUrl}?${AUTHENTICATION_PROVIDER_KEY}=${authProvider}`;
    if (themeName) {
        redirectUrl = `${redirectUrl}&${THEME_QUERY_PARAM_NAME}=${themeName}`;
    }
    if (orderId) {
        redirectUrl = `${redirectUrl}&${ORDER_ID_PARAM_KEY}=${orderId}`;
    }
    return redirectUrl;
}
