import log, { getMylemonadeContext } from "../../Common/services/LogService";
import { DeploymentName } from "../../my-lemonade-library/src/common/models/DeploymentInfo";
import { extractDateFromTimestampOrString } from "../../my-lemonade-library/src/common/services/DateHelper";
import { AuthenticationErrorType } from "../models/AuthenticationErrorType";
import { AuthenticationState } from "../models/AuthenticationState";
import { ACCEPT_TERMS_SUCCESS, AUTHENTICATE_USER, AUTHENTICATE_USER_SUCCESS, AuthenticationActionsType, AUTHENTICATION_ERROR, AUTHENTICATION_LOCATION_PROCESS, CONTINUE_AS_GUEST, CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_SUCCESS_RESET, RESET_GUEST_STATE, RESET_PASSWORD, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS, SET_USER_LOYALTY_BALANCE, SIGNOUT_USER, SIGNOUT_USER_SUCCESS, UPDATE_USER_SUCCESS } from "./AuthenticationActions";

//TODO: refactor state.data => split customer_information and authentication parameters

const defaultAuthenticationState: AuthenticationState = {
    login: false,
    error: false,
    loading: false,
    //TODO: Not good way but use to confirm to user that the email is correctly send
    recovery_password_email_send: false,
    errorMessage: null,
    errorType: null,
    customTokenSigninCount: 0,
    data: {
        location_authentication_config: {
            authentication_process: null,
            authentication_mandatory: null,
            email_domains_allowed: null,
            only_email_verified: false,
        },
        user_authentication_state: {
            user: null,
            provider: null,
            register_on_location: false,
            is_anonymous: true,
            is_email_verified: false,
        }
    },
    register_success: false,
}

export const authenticationReducer = (state: AuthenticationState = defaultAuthenticationState, action: AuthenticationActionsType): AuthenticationState => {

    switch (action.type) {

        case RESET_PASSWORD:
            return {
                ...state,
                loading: true
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                errorType: null,
                errorMessage: null,
                recovery_password_email_send: true
            }

        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
                errorType: AuthenticationErrorType.PASSWORD_RECOVERY,
                errorMessage: action.payload
            }
        case CREATE_USER:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                errorType: null,
                register_success: false,
            }

        case CREATE_USER_SUCCESS:
            return {
                ...state,
                register_success: true,
            }

        case CREATE_USER_SUCCESS_RESET:
            return {
                ...state,
                register_success: false,
                error: false,
                errorMessage: null,
                errorType: null
            }

        case AUTHENTICATE_USER:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                errorType: null,
                register_success: false,
            }

        case AUTHENTICATE_USER_SUCCESS:

            // Set the context for remote logs
            getMylemonadeContext().user_id = action.payload.customer?.uid;

            let customTokenSigninCount = state.customTokenSigninCount;
            if (action.payload.withCustomToken) {
                customTokenSigninCount++;
                if (customTokenSigninCount > 1) {
                    log.error(`User ${action.payload.customer?.uid} (anonymous ${action.payload.is_anonymous}) has signed in with custom token more than once (${customTokenSigninCount})`);
                }
            }

            const acceptedTerms = action.payload.customer?.last_accepted_terms;
            Object.values(acceptedTerms ?? {}).forEach((deploymentsWithDate) => {
                Object.entries(deploymentsWithDate).forEach(([key, date]) => {
                    const parsedDate = extractDateFromTimestampOrString(date);
                    if (parsedDate) {
                        deploymentsWithDate[key as DeploymentName] = new Date(parsedDate);
                    }
                });
            });

            return {
                ...state,
                loading: false,
                login: true,
                error: false,
                errorMessage: null,
                customTokenSigninCount: customTokenSigninCount,
                data: {
                    ...state.data,
                    location_authentication_config: {
                        ...state.data.location_authentication_config
                    },
                    user_authentication_state: {
                        ...state.data.user_authentication_state,
                        user: action.payload.customer,
                        register_on_location: action.payload.registerOnLocation,
                        provider: action.payload.provider ? action.payload.provider : null,
                        is_anonymous: action.payload.is_anonymous,
                        is_email_verified: action.payload.user_email_verified ? action.payload.user_email_verified : false,
                    }
                },
                register_success: false,
            }

        case AUTHENTICATION_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload.errorMessage,
                errorType: action.payload.errorType ? action.payload.errorType : null,
                register_success: false,
            }

        case SIGNOUT_USER:
            return {
                ...state,
                loading: true,
                register_success: false,
            }

        case SIGNOUT_USER_SUCCESS:
            return {
                ...state,
                login: false,
                data: {
                    ...state.data,
                    user_authentication_state: {
                        ...state.data.user_authentication_state,
                        user: null,
                    }
                },
                register_success: false,
            }

        case AUTHENTICATION_LOCATION_PROCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    location_authentication_config: {
                        ...state.data.location_authentication_config,
                        authentication_providers: action.payload.authentication_providers,
                        authentication_mandatory: action.payload.authentication_mandatory,
                        authentication_process: action.payload.authentication_process,
                        email_domains_allowed: action.payload.email_domains_allowed,
                        only_email_verified: action.payload.customer_email_verified
                    }
                },
                register_success: false,
            }
        }

        case CONTINUE_AS_GUEST: {

            // We split in 2 depending on when the user clicked on guest: beginning or
            // end of the flow?
            // WARNING: we set the info to the customer, AND we also spread it to firestore,
            // see the function continueAsGuest in AuthenticationSaga for more info.

            // End
            if (action.payload) {

                return {
                    ...state,
                    data: {
                        ...state.data,
                        user_authentication_state: {
                            ...state.data.user_authentication_state,
                            user: {
                                ...state.data.user_authentication_state.user,
                                has_clicked_guest_end: true,
                            }
                        }
                    }
                }
            }

            // Beginning
            return {
                ...state,
                data: {
                    ...state.data,
                    user_authentication_state: {
                        ...state.data.user_authentication_state,
                        user: {
                            ...state.data.user_authentication_state.user,
                            has_clicked_guest_beginning: true,
                        }
                    }
                }
            }
        }

        case RESET_GUEST_STATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user_authentication_state: {
                        ...state.data.user_authentication_state,
                        user: {
                            ...state.data.user_authentication_state.user,
                            has_clicked_guest_beginning: false,
                            has_clicked_guest_end: false,
                        }
                    }
                }
            }
        }

        case SET_USER_LOYALTY_BALANCE: {

            if (action.payload.newBalance) {

                return {
                    ...state,
                    data: {
                        ...state.data,
                        user_authentication_state: {
                            ...state.data.user_authentication_state,
                            user: {
                                ...state.data.user_authentication_state.user,
                                loyalty_balance: action.payload.newBalance,
                            }
                        }
                    }
                };
            }

            return state;
        }

        case UPDATE_USER_SUCCESS: {
            if (action.payload.customer) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        user_authentication_state: {
                            ...state.data.user_authentication_state,
                            user: action.payload.customer
                        }
                    }
                }
            }

            return state
        }

        case ACCEPT_TERMS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user_authentication_state: {
                        ...state.data.user_authentication_state,
                        user: {
                            ...state.data.user_authentication_state.user,
                            last_accepted_terms: action.payload.lastAcceptedTerms,
                        }
                    }
                }
            }
        }

        default:
            return state
    }
}
