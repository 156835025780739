import { Edit } from "@mui/icons-material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { cursor } from '../../Common/StyledComponents';
import theme from '../../config/theme';

interface AddressDisplayProps {
    dataTestInput?: string
    dataTestReminder?: string
    icon?: JSX.Element
    title?: string
    addressToDisplay?: string
    addressNote?: string
    checked?: boolean
    isInput?: boolean,
    onInputClick?: () => void
    onNoteClick?: () => void
}

const AddressDisplay: React.FC<AddressDisplayProps> = (props) => {

    const { icon, title, addressToDisplay, addressNote, isInput, onInputClick, onNoteClick,
        checked, dataTestInput, dataTestReminder } = props;

    const intl = useIntl()
    return (
        <Box
            data-test={dataTestInput}
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            onClick={(event: any) => { if (onInputClick) { onInputClick() } }}
            style={{
                ...cursor,
            }}
        >

            <Box
                mr={2}
                display="flex"
                alignItems="center"
                onClick={() => {
                    if (onNoteClick) { onNoteClick() }
                }}>
                {icon}
            </Box>

            <Box display={"flex"} flexDirection={"column"} flex={1}>
                {
                    title &&
                    <Typography variant="subtitle1">
                        {title}
                    </Typography>

                }
                <Box display={"flex"} flexDirection={"column"} flex={1}>

                    <Typography variant={addressToDisplay ? "body1" : "subtitle1"}>
                        {addressToDisplay}
                    </Typography>

                    {
                        onNoteClick

                            ? <Box display="flex" alignItems={"center"} justifyContent="flex-start">
                                <Typography
                                    data-test={dataTestReminder}
                                    variant="caption"
                                    style={{ color: theme.palette.grey[400] }}
                                >
                                    {addressNote ?? intl.formatMessage({ id: "delivery.delivery_reminder.fallback" })}
                                </Typography>
                                <IconButton
                                    style={{
                                        ...cursor,
                                        color: theme.palette.grey[400],
                                        padding: theme.spacing(0, 0, 0, 1),
                                    }}
                                    onClick={() => { onNoteClick() }}
                                    size="large">
                                    <Edit style={{ fontSize: theme.typography.fontSize }} />
                                </IconButton>
                            </Box>

                            : addressNote
                                ? <Typography
                                    data-test={dataTestReminder}
                                    variant="caption"
                                    style={{ color: theme.palette.grey[400], width: "100%" }}
                                >
                                    {addressNote}
                                </Typography>
                                : ""
                    }
                </Box>
            </Box>

            {isInput &&
                <>
                    <CheckCircleRoundedIcon
                        color="primary"
                        style={{
                            // Display only if it's selected
                            display: checked ? "unset" : "none",
                        }}
                    />

                    <RadioButtonUncheckedIcon
                        color="disabled"
                        style={{
                            // Display only if it's NOT selected
                            display: checked
                                ? "none"
                                : "unset",
                            opacity: .3,
                        }}
                    />
                </>}
        </Box>
    );
}

export default AddressDisplay;