import { Box, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import log from '../../Common/services/LogService';
import { getDealRoute } from '../../deals/configs/DealRoutes';
import { isTemporallyAvailableForOrder } from '../../my-lemonade-library/functions/Helpers';
import { Deal, getTimezoneName, Product } from '../../my-lemonade-library/model/Catalog';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import TextInputBottomModal from '../../orders/components/TextInputBottomModal';
import { useTypedSelector } from '../../redux/root-reducer';
import ProductModal from '../pages/ProductModal';
import ProductsItemListComponent from './ProductsItemListComponent';


interface DisplayProductsProps {
    grid?: boolean,
    products: ProductExtended[],
    deals?: Deal[],
    categoryIcon?: string | Array<string | null>,
    interactif?: boolean,
}
/**
 * Component in charge to render a product list
 * @param props 
 * @deprecated only used in /happyhour page which is not reached anywhere. To delete
 */
const DisplayProducts: React.FC<DisplayProductsProps> = (props) => {

    const [productOpen, setProductOpen] = useState<boolean>(false);
    const [openProductNoteModal, setOpenProductNoteModal] = useState<boolean>(false);
    const [itemNote, setItemNote] = useState<string>("")
    const [selectedProduct, setSelectedProduct] = useState<ProductExtended | null>(null);
    const { supportedLanguages, currentLang } = useTypedSelector((state) => state.lang)
    const { selectedLocation, selectedCatalog, tableLinkId } = useTypedSelector((state) => state.locations)
    const { order } = useTypedSelector((state) => state.order)
    const history = useHistory();
    const theme = useTheme();
    const intl = useIntl();

    if ((!props.products || props.products.length === 0) && (!props.deals || props.deals.length === 0)) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography>
                    {intl.formatMessage({ id: "ProductPage.noProducts" })}
                </Typography>
            </Box>
        );
    }

    const openModal = (item: ProductExtended | DealExt) => {
        const deal = item as DealExt;
        const product = item as Product;
        // Use the deal property to check if it's a deal or not
        if (deal.lines) {
            history.push(getDealRoute(tableLinkId, deal))
        } else {
            setSelectedProduct(product);
            setProductOpen(true);
        }

    }

    const closeModal = () => {

        setSelectedProduct(null);
        setProductOpen(false);
        setItemNote("");
    }

    const filteredProducts = () => {
        if (selectedLocation && selectedLocation.display_unavailable_product) {
            //log.info(`Display unavailable products (${props.products.length} in total)`)
            return props.products
        } else {
            //log.info(`Filter products (${props.products.length} in total)`)
            return props.products.filter((product) => {
                let available = true
                if (product.restrictions) {
                    //log.info(`Checking restrictions for product ${product.name}`, product.restrictions)
                    available = isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), product.restrictions, false)
                } else {
                    //log.info(`No restrictions for product ${product.name}`)
                }
                return (!product.disable && available)
            })
        }
    }

    const filteredDeals = props.deals ? props.deals.filter((deal) => {
        let available = true
        if (deal.restrictions) {
            //log.info(`Checking restrictions for deal ${deal.name} with service type ${order.service_type} and expected time ${order.expected_time}`, deal.restrictions)
            available = isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), deal.restrictions, false)
            //log.info(`Deal ${deal.name} available: ${available}`)
        }
        return (!deal.disable && available)
    }) : []

    if (!selectedCatalog) {
        return null
    }

    if (props.grid) {

        log.debug(`Display category in grid`);

        return (

            <Box mb={3} >

                {selectedProduct &&
                    <>
                        <ProductModal
                            selectedProduct={selectedProduct}
                            open={productOpen}
                            closeModal={closeModal}
                            note={itemNote}
                            setNote={setItemNote}
                        />
                        <TextInputBottomModal
                            open={openProductNoteModal}
                            setOpen={() => {
                                setOpenProductNoteModal(false)
                            }}
                            titleId="product_modal.add_product_note"
                            placeHolderId="Order.cart.customer_notes.dialog.placeholder"
                            text={itemNote}
                            setText={setItemNote}
                            desktop
                        />
                    </>
                }

                {/* <ProductsGridComponent
                    openModal={openModal}
                    deals={filteredDeals}
                    products={filteredProducts()}
                    categoryIcon={props.categoryIcon}
                /> */}

            </Box>
        );
    }

    log.debug(`Display category in line`);

    return (

        <Box
            mb={20}
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={theme.spacing(0, 1, 0, 1)}
        >

            {selectedProduct &&

                <>
                    <ProductModal
                        selectedProduct={selectedProduct}
                        open={productOpen}
                        closeModal={closeModal}
                        note={itemNote}
                        setNote={setItemNote}
                    />
                    <TextInputBottomModal
                        open={openProductNoteModal}
                        setOpen={() => {
                            setOpenProductNoteModal(false)
                        }}
                        titleId="product_modal.add_product_note"
                        placeHolderId="Order.cart.customer_notes.dialog.placeholder"
                        text={itemNote}
                        setText={setItemNote}
                        desktop
                    />
                </>
            }

            {selectedLocation && filteredDeals.map((deal) =>

                <ProductsItemListComponent
                    openModal={openModal}
                    key={deal.ref}
                    categoryIcon={props.categoryIcon}
                    item={deal}
                    languages={supportedLanguages}
                    currentLang={currentLang}
                    catalogLang={selectedCatalog?.language}
                    isWithoutImage={false}  // from deprecated component, don't care
                />
            )}

            {selectedLocation && filteredProducts().map((product) =>

                <ProductsItemListComponent
                    openModal={openModal}
                    key={product.ref}
                    categoryIcon={props.categoryIcon}
                    item={product}
                    languages={supportedLanguages}
                    currentLang={currentLang}
                    catalogLang={selectedCatalog?.language}
                    isWithoutImage={false}  // from deprecated component, don't care
                />
            )}

        </Box>
    );
}

export default DisplayProducts;
