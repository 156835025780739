import { PaymentType } from "../../../model/Order";

export const PAYMENTS_SUPPORTING_FULL_REFUND: PaymentType[] = [
    PaymentType.EDENRED,
    PaymentType.LYRA,
    PaymentType.LYRA_MARKETPLACE,
    PaymentType.STRIPE,
    PaymentType.ADYEN,
    PaymentType.LYF_CB,
    PaymentType.STRIPE_CONNECT,
    PaymentType.SWILE,
    PaymentType.WORLDLINE,
]

export const PAYMENTS_SUPPORTING_PARTIAL_REFUND: PaymentType[] = [
    PaymentType.LYRA,
    PaymentType.LYRA_MARKETPLACE,
    PaymentType.WORLDLINE
];