import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import log from '../../Common/services/LogService';
import { locationActions } from "../../Locations/redux/LocationActions";
import * as ROUTES from '../../config/routes';
import { RouteParams } from '../../config/routes';
import { OrderItem as OrderItemType } from '../../my-lemonade-library/model/Order';
import { OrderDealError, OrderItemError } from '../../my-lemonade-library/src/orders/models/OrderItemError';
import { OrderRefusalReason } from '../../my-lemonade-library/src/orders/models/OrderRefusalReason';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import OrderDealDisplay from './OrderDealDisplay';
import OrderItemDisplay from './OrderItemDisplay';


interface OrderErrorDetailProps {
    errorValue: { [key: string]: any[] | any }
    errorCode: string
}

/**
 * Specific content for order with invalid content (disable, out of restrictions etc...)
 * Two actions 
 *  => re-submit without replace unavailable product(s) => redirect to OrderSummaryPage
 *  => replace unavailable porduct(s) => redirect to CategoriesPage
 * Reload catalog action on each button => catalog reloading clear order with draft mecanic but to lose other information like expected_time
 * @param props 
 * @returns 
 */
const OrderErrorDetail: React.FC<OrderErrorDetailProps> = (props) => {

    const { order } = useTypedSelector((state: RootState) => state.order)
    const { errorCode, errorValue } = props

    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch<RouteParams>();
    const theme = useTheme();
    const intl = useIntl();

    const displayProductList = (errorValue: { [key: string]: any[] }) => {
        if (errorValue.product) {
            const orderItems = errorValue.product as OrderItemError[]
            return (
                <>
                    {orderItems.filter(item => item.refusal_reason !== OrderRefusalReason.IN_DEAL_WITH_ITEM_INVALID && item.refusal_reason !== OrderRefusalReason.IN_INVALID_DEAL).map((item: OrderItemType, index: number) => (
                        <OrderItemDisplay readOnly={true} orderItem={item} key={index} moreDetails={false} />
                    ))}
                </>
            )
        } else {
            log.debug("No invalid product in order")
            return null
        }
    }
    const displayDealList = (errorValue: { [key: string]: any[] }) => {
        if (errorValue.deal && errorValue.deal) {
            const orderItems = errorValue.product as OrderItemError[]
            const deals = errorValue.deal as OrderDealError[]
            return (
                <>
                    {deals.map((deal: OrderDealError, index: number) => {
                        const dealKey = deal.deal_key;
                        const itemsInDeal = orderItems.filter(item => item.deal_line?.deal_key === dealKey);
                        return (
                            <OrderDealDisplay readOnly={true} dealItems={itemsInDeal} key={index} moreDetails={false} dealKey={dealKey} />
                        )
                    })}
                </>
            )
        } else {
            log.debug("No invalid deals in order")
            return null
        }
    }

    const reloadCatalog = () => {
        dispatch(locationActions.reloadCatalog())
    }

    const onReOrderClick = () => {
        reloadCatalog();
        history.push(generatePath(ROUTES.LocationHome + ROUTES.OrderSummaryPage, { tableLinkKey: match.params.tableLinkKey }))
    }
    const onReplaceClick = () => {
        reloadCatalog();
        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
    }

    if (order) {

        return (
            <Box flex="1" display={"flex"} width={1} flexDirection="column" alignItems={"center"}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems="center"
                    width={1}
                    data-test="error.unavailable_products.title"
                >
                    <Typography variant="h3" color="textSecondary" style={{ fontWeight: "bold", textAlign: "center" }}>
                        {intl.formatMessage({ id: "oops" })}
                    </Typography>
                    <Typography variant="h3" color="textSecondary" style={{ fontWeight: "bold", textAlign: "center" }}>
                        {intl.formatMessage({ id: `error.${errorCode}.descriptions` })}
                    </Typography>
                </Box>
                <Box py={3} width={1} flex={1}>
                    {displayProductList(errorValue)}
                    {displayDealList(errorValue)}
                </Box>
                <Box display={"flex"} flexDirection="column" width={"80%"}>
                    <Button
                        onClick={onReOrderClick}
                        variant='outlined'
                        color='primary'
                        style={{
                            textTransform: 'capitalize',
                            flex: 1,
                            margin: theme.spacing(1, 0, 1, 0)
                        }}
                        data-test="error.unavailable_entities.reorder"
                    >
                        {intl.formatMessage({ id: `error.${errorCode}.button.reorder` })}
                    </Button>
                    <Button
                        color='primary'
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'capitalize',
                            flex: 1,
                            margin: theme.spacing(1, 0, 1, 0)
                        }}
                        onClick={onReplaceClick}
                        data-test="error.unavailable_entities.replace"
                    >
                        {intl.formatMessage({ id: `error.${errorCode}.button.replace` })}
                    </Button>
                </Box>
            </Box>
        )
    }
    return null
}

export default OrderErrorDetail