import { Catalog, DealLine, Sku } from "../../../model/Catalog";
import { log } from "../../common/services/LogService";
import { Discount } from "../../discounts/models/Discount";
import { checkInvalidRestrictions, getRestrictionsArray } from "../../restrictions/services/RestrictionsService";
import CatalogAnomaly from "../models/anomalies/CatalogAnomaly";
import CatalogAnomalyType from "../models/anomalies/CatalogAnomalyType";
import { FixCatalogAnomalyFunctions } from "../models/anomalies/FixCatalogAnomalyFunctions";
import InvalidPercentage from "../models/anomalies/InvalidPercentage";
import InvalidPrice from "../models/anomalies/InvalidPrice";
import MinimumOneRefInArray from "../models/anomalies/MinimumOneRefInArray";
import { CatalogEntityWithRestriction, InvalidRestriction } from "../models/anomalies/RestrictionAnomaly";
import { CatalogEntity, CatalogEntityType } from "../models/CatalogEntity";

// TODO: test it
const fixInvalidPrice = (anomaly: InvalidPrice, catalogEntity: CatalogEntity | undefined, catalog: Catalog): void => {

    const entity = catalogEntity ?? anomaly.entity;

    if (anomaly.type === CatalogEntityType.SKU) {
        const sku = entity as Sku;
        if (anomaly.price_property === "price") {
            sku.price = anomaly.fixed_price;
            anomaly.fixed = true;
        }
    }

    else if (anomaly.type === CatalogEntityType.DISCOUNT) {
        const discount = entity as Discount;
        discount.pricing_value = anomaly.fixed_price;
        anomaly.fixed = true;
    }

    else if (anomaly.type === CatalogEntityType.DEAL_LINE) {
        const dealLine = entity as DealLine;
        dealLine.pricing_value = anomaly.fixed_price;
        anomaly.fixed = true;
    }
}

// TODO: test it
const fixInvalidPercentage = (anomaly: InvalidPercentage, catalogEntity: CatalogEntity | undefined, catalog: Catalog): void => {

    const entity = catalogEntity ?? anomaly.entity;

    if (anomaly.type === CatalogEntityType.DISCOUNT) {
        const discount = entity as Discount;
        discount.pricing_value = anomaly.fixed_percentage;
        anomaly.fixed = true;
    }

    else if (anomaly.type === CatalogEntityType.DEAL_LINE) {
        const dealLine = entity as DealLine;
        dealLine.pricing_value = anomaly.fixed_percentage;
        anomaly.fixed = true;
    }
}

// TODO: test it
const fixMinimumOneRefInArray = (anomaly: MinimumOneRefInArray, catalogEntity: CatalogEntity | undefined, catalog: Catalog): void => {
    if (anomaly.type === CatalogEntityType.DEAL_LINE) {
        // TODO save deal index ?
        log.debug(`Removing deal ${anomaly.parent_ref} because the line ${anomaly.entity.ref} has no valid sku`);
        const invalidDealIndex = catalog.data?.deals?.findIndex((deal) => deal.ref === anomaly.parent_ref)
        if (invalidDealIndex > -1) {
            log.debug(`Removing deal ${anomaly.parent_ref} at index ${invalidDealIndex}`);
            catalog.data?.deals?.splice(invalidDealIndex, 1);
            anomaly.fixed = true;
        }
    } else if (anomaly.type === CatalogEntityType.PRODUCT) {
        // TODO: generalize
        log.debug(`Removing product ${anomaly.entity.ref} because has no valid sku`);
        const invalidProductIndex = catalog.data?.products?.findIndex((product) => product.ref === anomaly.entity.ref)
        if (invalidProductIndex > -1) {
            log.debug(`Removing product ${anomaly.entity.ref} at index ${invalidProductIndex}`);
            catalog.data?.products?.splice(invalidProductIndex, 1);
            anomaly.fixed = true;
        }
    } else if (anomaly.type === CatalogEntityType.DEAL) {
        log.debug(`Removing deal ${anomaly.entity.ref} because no line`);
        const invalidDealIndex = catalog.data?.deals?.findIndex((deal) => deal.ref === anomaly.entity.ref)
        if (invalidDealIndex > -1) {
            log.debug(`Removing deal ${anomaly.entity.ref} at index ${invalidDealIndex}`);
            catalog.data?.deals?.splice(invalidDealIndex, 1);
            anomaly.fixed = true;
        }
    } else if (anomaly.type === CatalogEntityType.OPTION_LIST) {
        log.debug(`Removing option list ${anomaly.entity.ref} because no options`);
        const invalidOptionListIndex = catalog.data?.option_lists?.findIndex(ol => ol.ref === anomaly.entity.ref)
        if (invalidOptionListIndex > -1) {
            log.debug(`Removing option list ${anomaly.entity.ref} at index ${invalidOptionListIndex}`);
            catalog.data?.option_lists?.splice(invalidOptionListIndex, 1);
            anomaly.fixed = true;
        }
    }
}

// TODO: test it
const fixInvalidRestriction = (anomaly: InvalidRestriction, catalogEntity: CatalogEntity | undefined, catalog: Catalog): void => {

    const globalEntity = catalogEntity ?? anomaly.entity;
    if (globalEntity.hasOwnProperty("restrictions")) {
        const entity = globalEntity as CatalogEntityWithRestriction;
        checkInvalidRestrictions(
            getRestrictionsArray(entity.restrictions),
            entity,
            CatalogEntityType.TAX, // We don't care as we don't use the result anomalies
            [],
            true,
        );
    }
}

// TODO: test it
export const getAnomalyEntityFromCatalog = (anomaly: CatalogAnomaly, catalog: Catalog): CatalogEntity | undefined => {

    const entity = anomaly.entity;

    switch (anomaly.type) {

        case CatalogEntityType.CATALOG: return catalog;

        case CatalogEntityType.CATEGORY:
        case CatalogEntityType.SUB_CATEGORY:
            return (
                catalog.data.categories?.find(c => c.ref === entity.ref)
            );

        case CatalogEntityType.DEAL: return (
            catalog.data.deals?.find(d => d.ref === entity.ref)
        );

        case CatalogEntityType.DEAL_LINE: return (
            catalog.data.deals?.find(d => d.lines.some(l => l.ref === entity.ref))?.lines.find(l => l.ref === entity.ref)
        );

        case CatalogEntityType.DISCOUNT: return (
            catalog.data.discounts?.find(d => d.ref === entity.ref)
        );

        case CatalogEntityType.OPTION: return (
            catalog.data.options?.find(o => o.ref === entity.ref)
        );

        case CatalogEntityType.OPTION_LIST: return (
            catalog.data.option_lists?.find(ol => ol.ref === entity.ref)
        );

        case CatalogEntityType.PRODUCT: return (
            catalog.data.products?.find(p => p.ref === entity.ref)
        );

        case CatalogEntityType.SKU: return (
            catalog.data.products?.find(p => p.ref === (entity as Sku).product_ref)?.skus.find(s => s.ref === entity.ref)
        );

        case CatalogEntityType.SUGGESTION: return (
            catalog.data.suggestions?.find(s => s.ref === entity.ref)
        );

        default: return undefined;
    }
}

/**
 * Give an anomaly, this function should fix it in the catalog if not already fixed.
 * // WARN: at the moment, some anomalies are fixed in the checkCatalog function.
 * // TODO: move all anomalies fixes in this function.
 * @param anomaly 
 * @param catalog 
 */
export const fixCatalogAnomaly = (anomaly: CatalogAnomaly, catalog: Catalog): void => {

    if (anomaly.fixed) {
        log.debug(`Anomaly ${anomaly.anomaly_type} already fixed`, anomaly);
        return;
    }

    const entity = getAnomalyEntityFromCatalog(anomaly, catalog);
    const fixFunction = fixCatalogAnomalyFunctions[anomaly.anomaly_type];

    if (fixFunction) {
        fixFunction(anomaly, entity, catalog);
    }
    else {
        log.info(`Cannot fix anomaly ${anomaly.anomaly_type}: no fix function found`, anomaly);
    }
}

export const fixCatalogAnomalyFunctions: FixCatalogAnomalyFunctions = {
    [CatalogAnomalyType.INVALID_PRICE]: fixInvalidPrice,
    [CatalogAnomalyType.INVALID_PERCENTAGE]: fixInvalidPercentage,
    [CatalogAnomalyType.MINIMUM_ONE_REF_IN_ARRAY]: fixMinimumOneRefInArray,
    [CatalogAnomalyType.INVALID_RESTRICTION]: fixInvalidRestriction,
}