import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { cursor, mobilePaperProps } from '../../Common/StyledComponents';
import { desktopDisplayBreakpoint } from '../../config/theme';
import { OptionList } from '../../my-lemonade-library/model/Catalog';
import { OrderOption } from '../../my-lemonade-library/model/Order';
import { DEFAULT_CURRENCY, Money, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import PlusMinusQuantity from '../../orders/components/PlusMinusQuantity';
import ProductOptions from '../../Products/component/ProductOptions';
import productService from '../../Products/services/ProductService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import FabSuggestions from '../components/FabSuggestions';
import { WIDTH_PAPER_DESKTOP } from '../configs/styleVariable';
import { SuggestionListToAdd } from '../models/SuggestionsListToAdd';

interface SuggestionModalProps {
    suggestionListToAdd: SuggestionListToAdd[]
    open: boolean,
    onClose: () => void,
    onSuggestionAdd: (skuRef: string, suggestionListToAdd: SuggestionListToAdd[]) => void
    onSuggestionMinus: (skuRef: string, suggestionListToAdd: SuggestionListToAdd[]) => void
    onSuggestionOptionChange: (skuRef: string, suggestionListToAdd: SuggestionListToAdd[], orderOptions: OrderOption[]) => void
    onOrderValidation: (suggestionListToAdd: SuggestionListToAdd[]) => void
}

const SuggestionModal: React.FC<SuggestionModalProps> = (props) => {

    const {
        suggestionListToAdd,
        onClose,
        open,
        onSuggestionAdd,
        onSuggestionMinus,
        onSuggestionOptionChange,
        onOrderValidation
    } = props

    const intl = useIntl();
    const theme = useTheme();

    const { selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    const [displayOptions, setDisplayOption] = useState<boolean>(false)
    const [suggestionIndex, setSuggestionIndex] = useState<number>(0)
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint))


    const handleFinalAction = () => {
        if (displayOptions) {
            onSuggestionAdd(suggestionListToAdd[suggestionIndex].suggestion.ref, suggestionListToAdd)
            setDisplayOption(false)
        } else {
            onOrderValidation(suggestionListToAdd)
        }
    }

    const isDisabled = (): boolean => {
        return suggestionListToAdd.every(sug => sug.quantity === 0)
    }

    const listTotal = (): Money => {
        const numTotal: number = suggestionListToAdd.reduce((total, suggestion) => {
            if (!suggestion.suggestion.sku) {
                return total
            }

            const itemPrice = moneyToNumber(suggestion.suggestion.sku.price)
            const itemTotal = itemPrice * suggestion.quantity

            total += itemTotal
            return total
        }, 0)

        const currency = selectedCatalog?.currency ?? selectedLocation?.currency ?? DEFAULT_CURRENCY
        return numberToMoney(numTotal, currency)
    }

    if (!selectedCatalog) {
        return null
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll={'paper'}
            PaperProps={{
                style: isDesktopRender
                    ? { width: WIDTH_PAPER_DESKTOP }
                    : { ...mobilePaperProps, height: 'auto' }
            }}
        >

            <DialogTitle
                sx={{
                    bgcolor: theme.palette.background.default,
                }}
            >
                <IconButton
                    onClick={onClose}
                    style={{
                        ...cursor,
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        color: theme.palette.text.disabled
                    }}
                    size="large">
                    <CloseRoundedIcon />
                </IconButton>

                <Typography variant="h2">
                    {intl.formatMessage({ id: "suggestion.suggestionModal.title" })}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ bgcolor: theme.palette.background.default, padding: 0 }}>
                {!displayOptions
                    ? <Box
                        display='flex'
                        flexDirection='column'
                        gap={2}
                    >
                        {suggestionListToAdd.map((suggestion: SuggestionListToAdd, index: number) => (
                            suggestion.suggestion.product && suggestion.suggestion.sku
                                ? <Box
                                    key={index}
                                    display='flex'
                                    bgcolor={theme.palette.background.paper}
                                    px={3}
                                    py={1}
                                >
                                    <img
                                        src={productService.getProdImage(suggestion.suggestion.product, selectedCatalog)}
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            borderRadius: '10px',
                                            objectFit: 'cover'
                                        }}
                                        alt={suggestion.suggestion.product.name}
                                    />

                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        alignItems='start'
                                        flex={1}
                                        pl={2}
                                        py={1}
                                    >
                                        <Typography variant="h5" color="textSecondary">
                                            {intl.formatMessage({
                                                id: translationService.getProductNameTranslationKeyFromRef(suggestion.suggestion.product_ref),
                                                defaultMessage: suggestion.suggestion.product!.name
                                            })}
                                        </Typography>

                                        {suggestion.suggestion.product?.skus && suggestion.suggestion.product.skus.length > 1 &&
                                            <Typography fontSize={'14px'}>
                                                {suggestion.suggestion.sku.name}
                                            </Typography>
                                        }

                                        <Typography variant="h5" color="textSecondary">
                                            {MoneyToStringWithSymbol(suggestion.suggestion.sku.price)}
                                        </Typography>

                                        <Box alignSelf='flex-end' mt='auto'>
                                            <PlusMinusQuantity
                                                value={suggestion.quantity}
                                                onAdd={() => {
                                                    if (
                                                        suggestion.suggestion.sku!.option_list_refs
                                                        && suggestion.suggestion.sku!.option_list_refs.length > 0
                                                        && suggestion.quantity === 0
                                                    ) {
                                                        setDisplayOption(true)
                                                        setSuggestionIndex(index)
                                                    } else {
                                                        onSuggestionAdd(suggestion.suggestion.ref, suggestionListToAdd)
                                                    }
                                                }}
                                                onMinus={() =>
                                                    onSuggestionMinus(suggestion.suggestion.ref, suggestionListToAdd)
                                                }
                                                min={0}
                                            />
                                        </Box>


                                    </Box>
                                </Box>

                                : null
                        ))}
                    </Box>

                    : (
                        <ProductOptions
                            selectedProduct={suggestionListToAdd[suggestionIndex].suggestion.product!}
                            selectedSku={suggestionListToAdd[suggestionIndex].suggestion.sku!}
                            selectedOptions={suggestionListToAdd[suggestionIndex].orderOptions ? suggestionListToAdd[suggestionIndex].orderOptions : []}
                            onSelectedOptionsChange={
                                (orderOptions: OrderOption[], invalidOptionLists: OptionList[] | null) => {
                                    onSuggestionOptionChange(suggestionListToAdd[suggestionIndex].suggestion.sku!.ref, suggestionListToAdd, orderOptions)
                                }
                            }
                            hideSkuSelection
                        />
                    )}

            </DialogContent>

            <DialogActions
                sx={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: "center",
                    gap: theme.spacing(1),
                    paddingBottom: theme.spacing(2),
                    paddingTop: theme.spacing(2),
                    bgcolor: theme.palette.background.default
                }}>

                <Box width={1} display="flex"
                    flexDirection="column"
                    alignItems="center">
                    <FabSuggestions
                        onClick={handleFinalAction}
                        disabled={isDisabled()}
                        listTotal={listTotal()}
                    />

                    <Button
                        data-test="no-thanks-button"
                        onClick={() => onOrderValidation([])}
                    >
                        {intl.formatMessage({ id: 'suggestion.no_thanks' })}
                    </Button>
                </Box>

            </DialogActions>
        </Dialog>
    );

}

export default SuggestionModal;

