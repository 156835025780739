import { Box, CircularProgress, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import moment from "moment";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import FormikSelectInput from "../../Common/components/Formik/FormikSelectInput";
import { getTimezoneName } from "../../my-lemonade-library/model/Catalog";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { COLLECTION_DATE_FORMAT } from '../../my-lemonade-library/src/orders/services/CollectionService';
import { Restriction } from "../../my-lemonade-library/src/restrictions/model/Restriction";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import orderActions from "../redux/OrderActions";

interface TakeoutDateTimeFormProps {
    deliveryZoneRef?: string;
    allRestrictions: Restriction[] | undefined; // Controlled by the upper component (local state)
    date: string; // The date value (controlled in the upper component by Formik)
    serviceType: SupportedServiceType;
    allowedDays: moment.Moment[]; // Controlled by the upper component (local state)
}

/**
 * This component renders the Date & Time fields. It has to be integrated into a Formik form.
 * It manages most of the specific data in its local state, but unfortunately some data
 * have to be passed in props.
 * @param props 
 * @returns 
 */
const TakeoutDateTimeForm: React.FC<TakeoutDateTimeFormProps> = (props) => {

    const {
        allRestrictions,
        deliveryZoneRef,
        date,
        serviceType,
        allowedDays
    } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { selectedLocation, selectedCatalog } = useTypedSelector((state: RootState) => state.locations);

    const { loading: timeslotsLoading, data: timeslotsData } = useTypedSelector((state: RootState) => state.order.availableTimeslots);

    /**
     * Triggered when the date (i.e the current day displayed) or a restriction changed. 
     * This useEffect computes the timeslots available for the current day, and set them to
     * the local state "allowedTimeSlots".
     * If no timeslots are available, it jumps to the next day
     */
    useEffect(() => {

        if (date) {

            // Spread the changes
            if (selectedLocation && selectedCatalog) {
                let dateTime = DateTime.fromISO(date, { zone: getTimezoneName(selectedCatalog) });

                dispatch(orderActions.loadAvailableTimeSlots(
                    serviceType,
                    dateTime,
                    deliveryZoneRef))
            }
        }

    }, [date, allRestrictions, deliveryZoneRef]);


    /**
     * Render the JSX elements to fill the Date Select component (in the form).
     */
    const renderDateOptions = () => {

        if (allowedDays && allowedDays.length > 0) {
            return allowedDays.map((allowedDay, index) => {
                const formattedDay = intl.formatDate(allowedDay.toDate(), { weekday: 'short', day: 'numeric', month: 'short' });
                return (
                    <option
                        key={index}
                        value={allowedDay.format(COLLECTION_DATE_FORMAT)}
                    >
                        {formattedDay}
                    </option>
                )
            });

            // TODO: What does this comment mean?
            // Empty allowed days array: location closed
        }
        else {
            return (
                <option disabled>
                    {intl.formatMessage({ id: "orders.servicePickupInfo.noPickupOption" })}
                </option>
            );
        }
    }

    /**
     * Render the JSX elements to fill the Time (or Hour) Select component (in the form).
     */
    const renderTimeOptions = () => {

        if (timeslotsData && timeslotsData.timeslots && timeslotsData.timeslots.length) {
            return timeslotsData.timeslots.map((timeslot, i) => (

                <option
                    key={i}
                    value={timeslot.start_hour}
                    disabled={timeslot.full}
                >
                    {/* TODO: internationalize */}
                    {timeslot.start_hour}
                </option>

            ));

            // TODO: What does this comment mean?
            // Empty restrictions array: location closed

        }
        else {
            return (
                <option disabled>
                    {intl.formatMessage({ id: "orders.servicePickupInfo.noPickupOption" })}
                </option>
            );
        }
    }

    return (

        <Box
            width={1}
            display='flex'
        >

            {
                ///////////////
                // Date picker
                ///////////////
            }

            <Box
                width={1}
                marginBottom={2}
                mr={1}
            >
                <FormikSelectInput
                    name="date"
                    placeHolderId="orders.servicePickupInfo.date.placeholder"
                    renderOptions={renderDateOptions}
                />

            </Box>

            {
                ///////////////
                // Time picker
                ///////////////
            }

            <Box
                width={1}
                ml={1}
                display="flex"
                flexDirection={"row"}
            >
                {!timeslotsLoading ?

                    <FormikSelectInput
                        name="time"
                        placeHolderId="orders.servicePickupInfo.hour.placeholder"
                        renderOptions={renderTimeOptions}

                    />
                    :
                    <CircularProgress
                        data-test='loading-time-select'
                        size="1em"
                        style={{
                            color: theme.palette.primary.main,
                            margin: theme.spacing(1, 0, 0, 0)
                        }}
                    />}

            </Box>
        </Box>
    );
}

export default TakeoutDateTimeForm;