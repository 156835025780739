import { LoadingButton } from "@mui/lab";
import { Box, Dialog, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import ThemeSvg from "../../Common/components/ThemeSvg";
import DeliveryUnavailableReason from "../../my-lemonade-library/src/delivery/models/DeliveryUnavailableReason";

interface DeliveryErrorModalProps {
    open: boolean,
    isCheckingDelivery: boolean,
    undeliverableReason: DeliveryUnavailableReason,
    retryCheckAvailable: () => void
}

const DeliveryErrorModal = (props: DeliveryErrorModalProps) => {
    const { open, retryCheckAvailable, isCheckingDelivery, undeliverableReason } = props;
    const intl = useIntl();
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            fullWidth
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={4}
            >
                <Box maxWidth="80%">
                    <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/error.svg`} />
                </Box>

                <Box
                    mt={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                >
                    <Typography variant="h2" color="textSecondary">
                        {intl.formatMessage({ id: "oops" })}
                    </Typography>

                    <Typography variant="h5">
                        {intl.formatMessage({
                            id: undeliverableReason === DeliveryUnavailableReason.NO_MORE_COURIERS_AVAILABLE
                                ? "delivery.error.no_courier_availaible"
                                : "delivery.error.unavailable_delivery"
                        })}
                    </Typography>
                </Box>
            </Box>

            <LoadingButton
                loading={isCheckingDelivery}
                variant="contained"
                color="primary"
                style={{
                    marginTop: theme.spacing(3),
                    textTransform: "none",
                    width: "50%",
                    alignSelf: "center",
                    marginBottom: theme.spacing(3)
                }}
                onClick={retryCheckAvailable}
            >
                <Typography> {intl.formatMessage({ id: "retry" })} </Typography>
            </LoadingButton>
        </Dialog>
    )
}

export default DeliveryErrorModal;