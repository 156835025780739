import { Close } from '@mui/icons-material'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from 'react-window'
import { OrderSummaryPage } from '../../config/routes'
import { ORDER_ID_QUERY_PARAM_NAME } from '../../orders/configs/OrdersRouterConfig'
import { RootState, useTypedSelector } from '../../redux/root-reducer'
import InfoOrderDisplay from './InfoOrderDisplay'

const AccountOrderHistory = () => {

    const intl = useIntl()
    const history = useHistory()
    const theme = useTheme()

    const { initOrders } = useTypedSelector((state: RootState) => state.order);

    return (
        <Box
            bgcolor='background.default'
            height={1}
        >
            <Box
                px={4}
                py={3}
                mb={2}
                display="flex"
                justifyContent="space-between"
                bgcolor='background.paper'
            >

                {
                    // This icon is invisible but needed to center the text
                }
                <IconButton
                    style={{ padding: 0 }}
                    size="large"
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIosRoundedIcon sx={{ color: theme.palette.secondary.main }} />
                </IconButton>

                <Typography variant="h2" color="textSecondary">
                    {intl.formatMessage({ id: "order.history" })}
                </Typography>

                <Close sx={{ opacity: 0 }} />
            </Box>

            <AutoSizer>
                {({ height, width }) => (
                    <List
                        height={height}
                        itemCount={initOrders['latestUserOrders'].length}
                        itemSize={112}
                        width={width}
                    >
                        {({ index, style }) => (
                            <Box
                                pb={2}
                                sx={{ ...style }}
                            >
                                <Box
                                    bgcolor='background.paper'
                                    p={3}
                                    onClick={() => history.push(`/${initOrders['latestUserOrders'][index].table_link_id}${OrderSummaryPage}?${ORDER_ID_QUERY_PARAM_NAME}=${initOrders['latestUserOrders'][index].id}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <InfoOrderDisplay
                                        selectedOrder={initOrders['latestUserOrders'][index]}
                                    />
                                </Box>
                            </Box>
                        )}
                    </List>
                )}
            </AutoSizer>
        </Box>
    )
}

export default AccountOrderHistory