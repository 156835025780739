import { FirebaseError } from 'firebase';
import { DateTime } from 'luxon';
import { extractDateFromTimestampOrString } from '../../Common/helper/DateHelper';
import log, { setRemoteLogsConfig } from '../../Common/services/LogService';
import { auth } from '../../config/firebase';
import { getApiEndpoint, isProdEnv } from '../../config/variables';
import authenticationsApiRoutes, { AUTHENTICATION_PROVIDER_PARAM } from '../../my-lemonade-library/src/authentications/configs/AuthenticationApiRoutes';
import { getRecoveryPasswordCloudFunctionsUrl } from '../../my-lemonade-library/src/authentications/configs/AuthenticationCloudFunctionsRoute';
import AuthenticateRequest from '../../my-lemonade-library/src/authentications/models/AuthenticateRequest';
import AuthenticateResponse from '../../my-lemonade-library/src/authentications/models/AuthenticateResponse';
import AuthorizeCodeType from '../../my-lemonade-library/src/authentications/models/AuthorizeCodeType';
import AuthorizeRequest from '../../my-lemonade-library/src/authentications/models/AuthorizeRequest';
import AuthorizeResponse from '../../my-lemonade-library/src/authentications/models/AuthorizeResponse';
import { SignInProviders } from '../../my-lemonade-library/src/authentications/models/BaseUser';
import { Customer } from '../../my-lemonade-library/src/authentications/models/Customer';
import { RecoveryPasswordRequest } from '../../my-lemonade-library/src/authentications/models/RecoveryPasswordRequest';
import SigninRequest from '../../my-lemonade-library/src/authentications/models/SigninRequest';
import SigninResponse from '../../my-lemonade-library/src/authentications/models/SigninResponse';
import SigninWebAppAcknowledgeRequest from '../../my-lemonade-library/src/authentications/models/SigninWebAppAcknowledgeRequest';
import SigninWithEmailRequest from '../../my-lemonade-library/src/authentications/models/SigninWithEmailRequest';
import SigninWithEmailResponse from '../../my-lemonade-library/src/authentications/models/SigninWithEmailResponse';
import { getOauth2RedirectUrl, getSigninWithEmailRedirectUrl } from '../../my-lemonade-library/src/authentications/service/AuthenticationService';
import { getErrorStack } from '../../my-lemonade-library/src/common/services/LogService';
import { SESSION_ID_HEADER } from '../../my-lemonade-library/src/sessions/services/SessionService';
import { ThemeList } from '../../my-lemonade-library/src/theme/models/ThemesList';
import usersApiRoutes from '../../my-lemonade-library/src/users/configs/UsersApiRoutes';
import { CreateUserByEmailResponse } from '../../my-lemonade-library/src/users/models/GetUserByEmailResponse';
import { FirebaseAuthenticationErrorCodes } from '../models/FirebaseAuthenticationErrorCodes';
import { FirebaseErrorAuth } from '../models/FirebaseErrorAuth';

export const getIdToken = async (): Promise<firebase.auth.IdTokenResult | null> => {
    const user = auth.currentUser;
    if (user) {
        const tokenResult = await user.getIdTokenResult();

        log.debug(`user email verified: ${user.emailVerified}`)
        log.debug(`user is anonymous: ${user.isAnonymous}`)

        setRemoteLogsConfig(tokenResult.token);
        return tokenResult;
    }
    return null;
}

export const getFirebaseErrorId = (firebaseErrorCode: FirebaseAuthenticationErrorCodes): string | null => {
    switch (firebaseErrorCode) {
        case FirebaseAuthenticationErrorCodes.EMAIL_ALREADY_IN_USE:
            return "authentication.authenticationError.email_already_in_use"
        case FirebaseAuthenticationErrorCodes.INVALID_EMAIL:
            return "authentication.authenticationError.invalid_email_or_password"
        case FirebaseAuthenticationErrorCodes.OPERATION_NOT_ALLOWED:
            return "authentication.authenticationError.operation_not_allowed"
        case FirebaseAuthenticationErrorCodes.USER_DISABLE:
            return 'authentication.authenticationError.user_disable'
        case FirebaseAuthenticationErrorCodes.USER_NOT_FOUND:
            return "authentication.authenticationError.user_not_found"
        case FirebaseAuthenticationErrorCodes.WEAK_PASSWORD:
            return "authentication.authenticationError.weak_password"
        case FirebaseAuthenticationErrorCodes.WRONG_PASSWORD:
            return "authentication.authenticationError.invalid_email_or_password"
        default:
            return null
    }
}

export const authenticateWithToken = async (provider: SignInProviders, accessToken: string, tablelinkId: string): Promise<AuthenticateResponse> => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const authenticateApiUrl = authenticationsApiRoutes.PROVIDER_AUTHENTICATE_ROUTE.replace(AUTHENTICATION_PROVIDER_PARAM, provider)
    const authenticateFullUrl = `${getApiEndpoint()}${authenticateApiUrl}`;
    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    const authenticateRequest: AuthenticateRequest = {
        access_token: accessToken,
        table_link_id: tablelinkId
    };

    const raw = JSON.stringify(authenticateRequest);
    log.debug(`Authenticate request with token ${authenticateRequest.access_token}`);
    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: raw,
    };
    const response = await fetch(authenticateFullUrl, requestOptions);
    if (!response.ok) {
        log.error(response.json());
        throw new Error(`Error while getting signin url for provider ${provider}`);
    }
    // Response ok, get the order creation response
    const result: AuthenticateResponse = await response.json() as AuthenticateResponse;
    return result;
}

export const getSigninUrl = async (provider: SignInProviders, tableLinkId: string, themeName: ThemeList, orderId?: string, redirectPath?: string): Promise<SigninResponse> => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const signinApiUrl = authenticationsApiRoutes.PROVIDER_SIGNIN_ROUTE.replace(AUTHENTICATION_PROVIDER_PARAM, provider)
    const signinFullUrl = `${getApiEndpoint()}${signinApiUrl}`;
    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    const redirectUrl = getOauth2RedirectUrl(window.location.origin, provider, themeName);
    const signinRequest: SigninRequest = {
        table_link_id: tableLinkId,
        redirect_url: redirectUrl, // Authorize redirect uri for oauth flow
        redirect_path: redirectPath // path to be redirected after full signin
    };
    if (orderId) {
        signinRequest.order_id = orderId;
    }
    const raw = JSON.stringify(signinRequest);
    log.debug(`Signin request`, raw);
    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: raw,
    };
    const response = await fetch(signinFullUrl, requestOptions);
    if (!response.ok) {
        log.error(response.json());
        throw new Error(`Error while getting signin url for provider ${provider}`);
    }
    // Response ok, get the order creation response
    const result: SigninResponse = await response.json() as SigninResponse;
    return result;
}



export const signinWithEmail = async (provider: SignInProviders, email: string, tableLinkId: string, themeName: ThemeList, orderId?: string): Promise<SigninWithEmailResponse> => {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const signinApiUrl = authenticationsApiRoutes.PROVIDER_SIGNIN_WITH_EMAIL_ROUTE.replace(AUTHENTICATION_PROVIDER_PARAM, provider)
    const signinFullUrl = `${getApiEndpoint()}${signinApiUrl}`;
    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    const redirectUrl = getSigninWithEmailRedirectUrl(window.location.href, provider, themeName, orderId);
    const signinRequest: SigninWithEmailRequest = {
        email: email,
        table_link_id: tableLinkId,
        redirect_url: redirectUrl, // Authorize redirect uri for oauth flow
    };
    if (orderId) {
        signinRequest.order_id = orderId;
    }
    const raw = JSON.stringify(signinRequest);
    log.debug(`Signin with email request`, raw);
    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: raw,
    };
    const response = await fetch(signinFullUrl, requestOptions);
    if (!response.ok) {
        log.error(response.json());
        throw new Error(`Error while getting signin url for provider ${provider}`);
    }
    // Response ok, get the order creation response
    const result: SigninWithEmailResponse = await response.json() as SigninWithEmailResponse;
    return result;
}

export const authorizeCode = async (
    provider: SignInProviders,
    authorizeCode: string,
    codeType: AuthorizeCodeType,
    sessionId: string | undefined,
    state?: string,
    uuid?: string,
    tablelinkId?: string
): Promise<AuthorizeResponse | null> => {

    try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const authorizepiUrl = authenticationsApiRoutes.PROVIDER_AUTHORIZE_ROUTE.replace(AUTHENTICATION_PROVIDER_PARAM, provider)
        const authorizeFullUrl = `${getApiEndpoint()}${authorizepiUrl}`;
        const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

        headers.append("Authorization", `Bearer ${tokenResult?.token}`);
        sessionId && headers.append(SESSION_ID_HEADER, sessionId);

        const authorizeRequest: AuthorizeRequest = {
            code: authorizeCode,
            code_type: codeType,
            state: state,
            uuid: uuid,
            tablelink_id: tablelinkId,
        };
        const raw = JSON.stringify(authorizeRequest);
        log.debug(`Authorize request`, raw);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: raw,
        };
        const response = await fetch(authorizeFullUrl, requestOptions);
        if (!response.ok) {
            log.error(response.json());
            throw new Error(`Error while getting token from authorization code for provider ${provider}`);
        }
        // Response ok, get the order creation response
        const result: AuthorizeResponse = await response.json() as AuthorizeResponse;
        return result;
    } catch (error) {
        log.error(error);
    }
    return null;
}

export const acknowledgeAuthentication = async (
    provider: SignInProviders,
    state: string,
): Promise<void> => {

    try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const ackowledgeEndpoint = authenticationsApiRoutes.PROVIDER_ACKNOWLEDGE_ROUTE.replace(AUTHENTICATION_PROVIDER_PARAM, provider)
        const ackowledgeFullUrl = `${getApiEndpoint()}${ackowledgeEndpoint}`;
        const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

        headers.append("Authorization", `Bearer ${tokenResult?.token}`);

        const ackowledgeRequest: SigninWebAppAcknowledgeRequest = {
            state: state,
        };
        const raw = JSON.stringify(ackowledgeRequest);
        log.debug(`Acknowledge request`, raw);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: raw,
        };
        const response = await fetch(ackowledgeFullUrl, requestOptions);
        if (!response.ok) {
            log.error(response.json());
            throw new Error(`Error while getting token from authorization code for provider ${provider}`);
        }
        // Response ok the server has been notified
    } catch (error) {
        log.error(getErrorStack(error));
    }
}

/**
 * Compare if last order was made the same day than current day
 * Usefull for anonymous user cause states clicked choice for guest user will be reset to false
 * if last order is older than one day
 * @param customer 
 * @returns 
 */
export const keepOrResetCustomerConfig = (customer: Customer, timezone?: string): Customer => {
    const { last_order_date } = customer

    let isToday = false
    if (last_order_date && timezone) {
        const dateToCompare = extractDateFromTimestampOrString(last_order_date, timezone)
        if (dateToCompare) {
            isToday = DateTime.now().hasSame(DateTime.fromJSDate(dateToCompare), "day")
            log.debug(`>-auth-service-> last_order was made today => keep user config`)
        }
    }

    if (isToday) {
        return customer
    } else {
        return {
            ...customer,
            has_clicked_guest_beginning: false,
            has_clicked_guest_end: false,
        }
    }

}

export const recoveryPassword = async (payload: RecoveryPasswordRequest, isProdEnvironment: boolean) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const url = getRecoveryPasswordCloudFunctionsUrl(isProdEnvironment);
    const body = JSON.stringify(payload)

    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: body,
    };

    const response = await fetch(url, requestOptions);
    const res = await response.json()
    return {
        ...res,
        status: response.status
    }
}

/**
 * Prima functions to be authenticate by firebase signin or signup
 * 
 * useFull to catch firebase error before calling redux and save credentials or informations
 */

export async function signInToFirebase(email: string, password: string): Promise<FirebaseErrorAuth | firebase.auth.UserCredential> {
    try {
        return await auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
        const firebaseError = error as FirebaseError
        const errorCode = firebaseError.code as FirebaseAuthenticationErrorCodes;
        const errorMessage = firebaseError.message;
        return {
            errorCode: errorCode,
            errorMessage: errorMessage
        }
    }
}

export async function signUpToFirebase(email: string, password: string): Promise<FirebaseErrorAuth | string | null> {
    try {

        // Getting the user id from the user if it already exists
        const createUserApiUrl = `${getApiEndpoint()}${usersApiRoutes.CREATE_USER_WITH_EMAIL}`;

        const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${tokenResult?.token}`);
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({ email: email, password: password });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: raw,
        };
        const response = await fetch(createUserApiUrl, requestOptions);

        // If the user is already in the database because he once ordered with this email
        // we sign in
        if (response.ok) {
            const responseJson: CreateUserByEmailResponse = await response.json()
            if (responseJson.already_exists) {
                throw {
                    code: FirebaseAuthenticationErrorCodes.EMAIL_ALREADY_IN_USE,
                    message: "Email already exists",
                    name: "FirebaseError"
                }
            }
            await auth.signInWithEmailAndPassword(email, password)
            return responseJson.uid
        }

        // The user never ordered with this email, we create a new user
        const newUser = await auth.createUserWithEmailAndPassword(email, password)
        if (!newUser.user?.uid) {
            return null
        }
        return newUser.user?.uid
    } catch (error) {
        const firebaseError = error as FirebaseError
        const errorCode = firebaseError.code as FirebaseAuthenticationErrorCodes;
        const errorMessage = firebaseError.message;
        return {
            errorCode,
            errorMessage
        }
    }
}

export async function resetPassword(email: string, account_id: string, location_id: string, deployment_name: string) {
    try {
        const payload: RecoveryPasswordRequest = {
            account_id: account_id,
            location_id: location_id,
            deployment: deployment_name,
            email: email
        }
        const res = await recoveryPassword(payload, isProdEnv())
        if (res.status > 200) {
            return {
                code: res.code,
                messsage: res.message
            }
        }
    } catch (error) {
        const firebaseError = error as FirebaseError
        const errorCode = firebaseError.code as FirebaseAuthenticationErrorCodes;
        const errorMessage = firebaseError.message;
        return {
            code: errorCode,
            message: errorMessage
        }
    }
}
