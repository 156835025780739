import log from "loglevel";
import { Location } from "../../my-lemonade-library/model/Location";

/**
 * Get the location's full address as a string
 * @param location 
 * @returns location's full address
 */
export const getLocationFullAddress = (location: Location): string => {
    if (
        location.address
        && location.city
        && location.postal_code
    ) {
        const street: string = location.address
        const city: string = location.city
        const postalCode: string = location.postal_code

        return (`${street}, ${postalCode} ${city}`)
    } else {
        log.error('Uncomplete location address')
        return ''
    }

}

// Check if the color is correctly formatted (hex)
// using a regex from https://stackoverflow.com/questions/1636350/how-to-identify-a-given-string-is-hex-color-format
export const testThemePaletteValueHexadecimal = (value: string): boolean => {

    const hexRegex: RegExp = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    return hexRegex.test(value);
}