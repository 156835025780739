import { SupportedServiceType } from "../../../model/Location";
import { Tax } from "../models/Tax";

export const standardTaxRate: Tax = {
    name: "Taxe 20%",
    percentage: 20,
    ref: "tva20"
}

export const intermediateTaxRate: Tax = {
    name: "Taxe 10%",
    percentage: 10,
    ref: "tva10",
    default: true
}

export const reducedTaxRate: Tax = {
    name: "Taxe 5.5%",
    percentage: 5.5,
    ref: "tva5.5"
}

export const intermediateWithDeliveryTaxRate: Tax = {
    name: "Taxe 10% (5.5% livraison)",
    percentage: 10,
    ref: "tva10-5.5delivery",
    percentage_overrides: [
        {
            percentage: 5.5,
            service_types: [SupportedServiceType.DELIVERY]
        }
    ]
}