import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Button, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { getManualPaymentAmounts } from '../../Payment/helpers/PaymentHelpers';
import * as ROUTES from '../../config/routes';
import { Order, OrderItem } from '../../my-lemonade-library/model/Order';
import { Money, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import OrderDealDisplay from './OrderDealDisplay';
import OrderItemDisplay from './OrderItemDisplay';

/**
 * Component in charge to display Items of order
 * Able to define what type of item it is.
 * 
 */
interface OrderListProps {
    desktop?: boolean;
    orderToDisplay?: Order | null;
    readOnly: boolean;
    productList: OrderItem[][] | undefined;
    dealList: [OrderItem[]][] | undefined;
    atLeastOneNewItem?: boolean;
    atLeastOneOldItem?: boolean;
    isSharePayment?: boolean;
    selectedItems?: OrderItemToPay[],
    onSelectedItems?: (val: OrderItemToPay[]) => void
    selectAllItems?: (bool: boolean) => void
    amountRemaining?: Money
}

const OrderList: React.FC<OrderListProps> = (props) => {

    const { atLeastOneNewItem, dealList, productList, readOnly, desktop, atLeastOneOldItem, isSharePayment,
        selectedItems, onSelectedItems, selectAllItems, amountRemaining, orderToDisplay } = props;

    const history = useHistory();
    const params = useParams<ROUTES.RouteParams>();

    const { order } = useTypedSelector((state: RootState) => state.order)

    const [moreDetails, setMoreDetails] = useState<boolean>(false)

    const intl = useIntl();
    const theme = useTheme();

    const separatedItems = useMemo(() => paymentHelper.separatePaidAndRemainingItems(order), [order]);

    /**
     * Reprents the sum of the amounts of the items which are not already sent to the
     * API
     */
    const totalItemsToAddAndPay = useMemo(() => {
        let totalItems: number = 0
        if (productList && productList[0] && productList[0].length > 0) {
            totalItems += productList[0].reduce((total: number, item) => total + item.quantity, 0)
        }
        if (dealList && dealList[0] && dealList[0].length > 0) {
            totalItems += dealList[0].flat().reduce((total: number, item) => total + item.quantity, 0)
        }
        return totalItems;
    }, [productList, dealList]);

    const allItemsSelected = useMemo(() => {
        if (selectedItems) {
            const totalSelectedQuantity: number = selectedItems.reduce((total: number, item) => total + item.quantity, 0)
            return Boolean(totalItemsToAddAndPay === totalSelectedQuantity && totalItemsToAddAndPay !== 0);
        }
        return false;
    }, [selectedItems, totalItemsToAddAndPay]);

    const manualPaymentAmounts = useMemo(() => getManualPaymentAmounts(order.payments), [order.payments]);

    const backToCatalog = () => {
        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: params.tableLinkKey }))
    }

    const handleAllSelected = () => {
        if (selectAllItems) {
            selectAllItems(!allItemsSelected)
        }
    }

    const renderTitle = (id: string): JSX.Element => {
        return (
            <Box
                width={1}
                px={3}
                py={2}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
            >
                <Typography variant='h5' color="textSecondary">
                    {intl.formatMessage({ id })}
                </Typography>
                {id === 'order.summary.new_items'
                    ? <Box
                        onClick={() => setMoreDetails(!moreDetails)}
                        style={{ cursor: 'pointer' }}
                    >
                        <Typography
                            variant='body1'
                            color='primary'
                        >
                            {moreDetails
                                ? intl.formatMessage({ id: 'Summary.lessDetails' })
                                : intl.formatMessage({ id: 'Summary.moreDetails' })
                            }
                        </Typography>
                    </Box>

                    : (isSharePayment && amountRemaining && id !== 'orders.sharePayment.paid'

                        ? <Typography>
                            {MoneyToStringWithSymbol(amountRemaining)}
                        </Typography>
                        : <Box />
                    )
                }
            </Box>
        )
    }

    return (

        <Box
            display="flex"
            overflow="auto"
            flexDirection="column"
            alignItems="center"
            minHeight="100px"
            style={{ backgroundColor: theme.palette.background.paper }}
            pb={2}
        >

            {renderTitle(isSharePayment
                ? 'orders.sharePayment.remaining'
                : atLeastOneOldItem && atLeastOneNewItem
                    ? 'order.summary.new_items'
                    : 'Summary.myCart'
            )}

            {selectAllItems &&

                <Box
                    alignSelf='start'
                    px={3}
                    border="solid"
                    width={1}
                    style={{
                        borderWidth: "0em 0em .1em 0em",
                        borderColor: theme.palette.grey[100]
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-test="orderList_allSelected_button"
                                icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                checkedIcon={<CheckCircleIcon color='primary' />}
                                checked={allItemsSelected}
                                onChange={handleAllSelected}
                            />}
                        label={
                            <Typography variant='h5'>
                                {intl.formatMessage({
                                    id: order.payments && order.payments.length
                                        ? 'orders.sharePayment.pay_remaining'
                                        : 'orders.sharePayment.select_all'
                                })}
                            </Typography>
                        }
                    />
                </Box>
            }

            {// NEW PRODUCTS
                productList?.[0]?.map((orderItem: OrderItem, index: number) => (

                    <OrderItemDisplay
                        key={index}
                        orderItem={orderItem}
                        readOnly={readOnly || (orderItem.update_id && !isSharePayment ? true : false)}
                        moreDetails={moreDetails}
                        selectedItems={selectedItems}
                        onSelect={onSelectedItems}
                        isSharePayment={isSharePayment}
                    />
                ))
            }

            {// NEW DEALS
                dealList?.[0]?.map((deal, index) => (

                    <OrderDealDisplay
                        key={index}
                        dealItems={deal}
                        dealKey={deal[0]?.deal_line?.deal_key ?? ""}
                        readOnly={readOnly}
                        moreDetails={moreDetails}
                        selectedItems={selectedItems}
                        onSelect={onSelectedItems}
                        isSharePayment={isSharePayment}
                    />
                ))
            }


            {
                ((atLeastOneOldItem && atLeastOneNewItem)
                    || (isSharePayment && manualPaymentAmounts.length > 0))
                &&
                <Box width={1}>
                    <Box
                        width={1}
                        height={theme.spacing(2)}
                        bgcolor={theme.palette.background.default}
                    />
                    {renderTitle(isSharePayment
                        ? 'orders.sharePayment.paid'
                        : 'order.summary.old_items'
                    )}
                </Box>
            }

            {// OLD PRODUCTS
                productList?.[1]?.map((orderItem: OrderItem, index: number) => (

                    <OrderItemDisplay
                        key={index}
                        orderItem={orderItem}
                        readOnly={readOnly || (orderItem.update_id ? true : false)}
                        readOnlyDisabled={separatedItems.alreadyPaidItems.map(i => i.ref ?? i.sku_ref).includes(orderItem.ref ?? orderItem.sku_ref)}
                        readOnlyPendingPayment={paymentHelper.isItemPendingPayment(orderItem, orderToDisplay?.payments)}
                        moreDetails={moreDetails}
                        isSharePayment={isSharePayment}
                        alignWithCheckBoxes
                    />
                ))
            }

            {// OLD DEALS
                dealList?.[1]?.map((deal, index) => (

                    <OrderDealDisplay
                        key={index}
                        dealItems={deal}
                        dealKey={deal[0]?.deal_line?.deal_key ?? ""}
                        readOnly={readOnly}
                        readOnlyDisabled={Object.keys(separatedItems.alreadyPaidDealItems).includes(deal[0]?.deal_line?.deal_key ?? "")}
                        readOnlyPendingPayment={paymentHelper.isItemPendingPayment(deal[0], orderToDisplay?.payments)}
                        moreDetails={moreDetails}
                        alignWithCheckBoxes
                    />
                ))
            }

            {// Manual Amount
                isSharePayment && manualPaymentAmounts.map(amount => (
                    <Box
                        width={1}
                        pl={7}
                        pr={3}
                        py={2}
                        display='flex'
                        justifyContent='space-between'
                        border="solid"
                        style={{
                            borderWidth: "0em 0em .1em 0em",
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Typography variant='h5' align='left'>
                            {intl.formatMessage({ id: 'orders.sharePayment.manual_amount' })}
                        </Typography>

                        <Typography color="textSecondary" variant="subtitle1">
                            {MoneyToStringWithSymbol(amount)}
                        </Typography>

                    </Box>
                ))
            }

            {// NO ITEMS BUTTON

                !atLeastOneNewItem &&
                !atLeastOneOldItem &&
                !isSharePayment &&

                <Box
                    my={4}
                    p={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    flex={desktop ? "" : 1}
                >
                    <Typography>
                        {intl.formatMessage({
                            id: "Summary.noOrder",
                            defaultMessage: "No order."
                        })}
                    </Typography>

                    {
                        !desktop &&
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                marginTop: theme.spacing(2),
                            }}
                            onClick={backToCatalog}
                        >
                            <Typography variant="h5">
                                {intl.formatMessage({
                                    id: "Summary.backToCatalog",
                                    defaultMessage: "Back to catalog."
                                })}
                            </Typography>
                        </Button>
                    }
                </Box>
            }
        </Box >
    );
}

export default OrderList;
