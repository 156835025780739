import { Catalog } from "../../my-lemonade-library/model/Catalog"

export interface LoadTranslationsAction {
    type: typeof actions.LOAD_TRANSLATIONS,
    payload: {
        catalog: Catalog;
    }
}

const actions = {
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    LOAD_TRANSLATIONS: 'LOAD_TRANSLATIONS',
    LOAD_TRANSLATIONS_SUCCESS: 'LOAD_TRANSLATIONS_SUCCESS',
    LOAD_TRANSLATIONS_COMPLETE: 'LOAD_TRANSLATIONS_COMPLETE',
    LOAD_TRANSLATIONS_ERROR: 'LOAD_TRANSLATIONS_ERROR',

    changeLanguage: (selectedLanguage: string) => ({
        type: actions.CHANGE_LANGUAGE,
        payload: { selectedLanguage },
    }),

    loadTranslations: (catalog: Catalog): LoadTranslationsAction => ({
        type: actions.LOAD_TRANSLATIONS,
        payload: { catalog }
    }),

    loadTranslationsSuccess: (catalogTranslations: { [key: string]: { [key: string]: string } }, supportedLanguages: string[]) => ({
        type: actions.LOAD_TRANSLATIONS_SUCCESS,
        payload: { catalogTranslations, supportedLanguages }
    }),

    loadTranslationsComplete: () => ({
        type: actions.LOAD_TRANSLATIONS_COMPLETE,
        payload: {}
    }),

    loadError: (error: any) => ({
        type: actions.LOAD_TRANSLATIONS_ERROR,
        payload: { error }
    })
}

export default actions