import { Box, Typography, useTheme } from '@mui/material';
import PendingPaymentChip from '../../Payment/components/PendingPaymentChip';

interface ReadOnlyProductDisplayProps {
    name: string;
    price: string;
    productRef: string;
    quantity?: number;
    details?: string;
    disabled?: boolean;
    pendingPayment?: boolean;
}

const ReadOnlyProductDisplay = (props: ReadOnlyProductDisplayProps) => {

    const { name, price, productRef, quantity, details, disabled, pendingPayment } = props;

    const theme = useTheme();


    return (

        <Box
            display='flex'
            flexDirection='column'
            data-test={`orderItem-readOnly.ref.${productRef}`}
        >
            <Box
                display='flex'
                alignItems="center"
            >
                <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    gap={2}
                >
                    <Typography
                        variant='subtitle1'
                        data-test="read-only-product-display.name"

                        color={disabled ? theme.palette.text.disabled : undefined}
                    >
                        {`${quantity || '1'}x ${name}`}
                    </Typography>

                    {pendingPayment && <PendingPaymentChip />}
                </Box>

                <Typography
                    variant='subtitle1'
                    data-test="read-only-product-display.price"
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                    color={disabled ? theme.palette.text.disabled : undefined}
                >
                    {price}
                </Typography>
            </Box>

            {details &&
                <Typography
                    variant='caption'
                    color={theme.palette.text.disabled}
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1
                    }}
                    data-test="read-only-product-display.details"
                >
                    {details}
                </Typography>
            }

        </Box >
    )
}

export default ReadOnlyProductDisplay