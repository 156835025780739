const dataTestConst = {
    OPTION_PREFIX: "option_",
    SKU_PREFIX: "sku_",
    DEAL_LINE_PREFIX: "deal_line_",
    CATEGORY_PREFIX: "category_",
    DECREASE_QUANTITY: "decrease-quantity",
    INCREASE_QUANTITY: "increase-quantity",
    PAYMENT_CHOICE_BUTTON_: "payment-choice-button-"
}

export default dataTestConst;