import HttpError from '../../common/models/HttpError';
import MylemonadeScopes from '../../common/models/MyLemonadeScopes';

export default class CatalogErrors {
    static CATALOG_NOT_FOUND: HttpError = new HttpError(
        'CATALOG_NOT_FOUND',
        'Catalog not found',
        404,
        MylemonadeScopes.CATALOGS,
    );

    static CATALOG_CATEGORY_NOT_FOUND: HttpError = new HttpError(
        'CATALOG_CATEGORY_NOT_FOUND',
        'Catalog category not found',
        404,
        MylemonadeScopes.CATALOGS,
    );

    static CATALOG_CATEGORY_PRODUCTS_NOT_FOUND: HttpError = new HttpError(
        'CATALOG_CATEGORY_PRODUCTS_NOT_FOUND',
        'Catalog category products not found',
        404,
        MylemonadeScopes.CATALOGS,
    );

    static CATALOG_CATEGORY_REFERENTIAL_NOT_FOUND: HttpError = new HttpError(
        'CATALOG_CATEGORY_REFERENTIAL_NOT_FOUND',
        'Catalog category has no referential',
        404,
        MylemonadeScopes.CATALOGS,
    );

    static CHILD_ROOT_CATEGORY_NOT_FOUND: HttpError = new HttpError(
        'CHILD_ROOT_CATEGORY_NOT_FOUND',
        'The root category associated with the child catalog is not found',
        500,
        MylemonadeScopes.CATALOGS,
    );

    static CATALOG_AUTO_MATCHING_FAILED: HttpError = new HttpError(
        'CATALOG_AUTO_MATCHING_FAILED',
        'TCatalog auto matching failed',
        500,
        MylemonadeScopes.CATALOGS,
    );
}
