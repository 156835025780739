export enum CategoryReferenceLevel1 {
    // Beverage
    ADULT_BEVERAGES = "Adult Beverages",
    NON_ALCOHOLIC_BEVERAGES = "Non-Alcoholic Beverages",
    HOT_BEVERAGES = "Hot Beverages",

    // Food
    BREAKFAST = "Breakfast",
    DESSERTS = "Desserts",
    MAIN_COURSES = "Main Courses",
    SAUCES_AND_CONDIMENTS = "Sauces & Condiments",
    SIDES = "Sides",
    SNACKS = "Snacks",
    STARTERS = "Starters"
}

export const categoryReferenceLevel1Keywords: { [key in CategoryReferenceLevel1]: string[] } = {
    [CategoryReferenceLevel1.ADULT_BEVERAGES]: ["adult"],
    [CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES]: ["non alcoholic", "non-alcoholic", "non-alcohol"],
    [CategoryReferenceLevel1.HOT_BEVERAGES]: ["hot"],
    [CategoryReferenceLevel1.BREAKFAST]: [],
    [CategoryReferenceLevel1.DESSERTS]: [],
    [CategoryReferenceLevel1.MAIN_COURSES]: [],
    [CategoryReferenceLevel1.SAUCES_AND_CONDIMENTS]: [],
    [CategoryReferenceLevel1.SIDES]: [],
    [CategoryReferenceLevel1.SNACKS]: [],
    [CategoryReferenceLevel1.STARTERS]: []
}

export const category1_options = Object.values(CategoryReferenceLevel1);
