export enum DeploymentName {
    MY_LEMONADE = "mylemonade",
    GET_RESTO = "getresto",
    ALL_EAT_ONE = "alleatone",
    LYF = "lyf",
    EASYCOM = "easycom",
    MY_BAGUETTE = "mybaguette",
}

export default interface DeploymentInfo {
    name: DeploymentName;
    webapp_url?: string;
}