import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useIntl } from "react-intl";
import * as yup from 'yup';
import FormikPlusMinus from "../../Common/components/Formik/FormikPlusMinus";
import FormikSwitch from "../../Common/components/Formik/FormikSwitch";
import FormikTextInput from "../../Common/components/Formik/FormikTextInput";
import { downloadFile } from "../../Common/services/CommonServices";
import { desktopDisplayBreakpoint } from "../../config/theme";
import { getApiEndpoint } from "../../config/variables";
import { OrderInBase } from "../../my-lemonade-library/model/Order";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import invoiceHelper from "../../my-lemonade-library/src/invoices/services/InvoiceHelper";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { loadOrderLinkData } from "../helpers/OrderHelpers";

interface OrderInvoiceDialogProps {
    closeModal: () => void;
    order: OrderInBase;
    paymentIntentId: string | undefined;
}

const OrderInvoiceDialog: React.FC<OrderInvoiceDialogProps> = (props) => {

    const { closeModal, order, paymentIntentId } = props;

    const authenticationState = useTypedSelector((state: RootState) => state.authentication);
    const user = authenticationState.data.user_authentication_state.user;

    const intl = useIntl();
    const theme = useTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));

    const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);

    const getFullName = (user: Customer | null): string | undefined => {
        if (user?.first_name || user?.last_name) {
            return `${user.first_name} ${user.last_name}`;
        }
        return undefined;
    }

    interface FormFields {

        email: string;
        meals: number;
        fullName: string;

        includeServiceFees: boolean;
        includeMeal: boolean;
        includeDetails: boolean;
    }

    const validationSchema: yup.SchemaOf<FormFields> = yup.object({

        email: yup.string()
            .required("orders.servicePickupInfo.email.required")
            .email("orders.servicePickupInfo.email.format"),

        meals: yup.number()
            .required("")
            .min(1),

        fullName: yup.string()
            .required("orders.preparation.invoice.fullName.required"),

        includeServiceFees: yup.boolean().required(),
        includeMeal: yup.boolean().required(),
        includeDetails: yup.boolean().required(),
    });

    const initialValues: FormFields = {

        email: user?.email ?? "",
        meals: 1,
        fullName: getFullName(user) ?? "",

        includeServiceFees: false,
        includeMeal: false,
        includeDetails: false,
    }

    const onSubmit = async (values: FormFields) => {

        setLoadingInvoice(true);

        const invoiceUrl = invoiceHelper.getInvoiceUrl(
            getApiEndpoint() ?? "",
            order.id,
            true,
            paymentIntentId,
            true,
            values.email,
            values.meals?.toString(),
            values.fullName,
        );

        await loadOrderLinkData(invoiceUrl, (data: string) => downloadFile(
            data,
            undefined,
            undefined,
            invoiceHelper.getInvoiceFileName(
                intl.formatMessage({ id: "invoices.file.name_prefix" }),
                order.collection_code ?? order.display_id
            ),
        ));

        setLoadingInvoice(false);
        closeModal();
    }

    return (

        <Dialog
            open
            onClose={closeModal}
            data-test="preparation_page.invoice_modal.root"
            fullWidth={!isDesktopRender}
            maxWidth={isDesktopRender ? "xs" : false}
            PaperProps={{
                sx: {
                    paddingX: 2,
                    paddingY: 4,
                    borderRadius: 5,
                    margin: 2,
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={4}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Box justifyContent="flex-end" display="flex">
                        <IconButton
                            style={{ padding: 0 }}
                            aria-label="Close box"
                            onClick={closeModal}
                            size="large">
                            <Close />
                        </IconButton>
                    </Box>

                    <Box textAlign="center">
                        <Typography variant="h2" color="textSecondary">
                            {intl.formatMessage({ id: "orders.preparation.invoice.title" })}
                        </Typography>
                    </Box>

                </Box>

                <Formik<FormFields>
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <Form>

                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <Typography>
                                {intl.formatMessage({ id: "orders.preparation.invoice.explaination" })}
                            </Typography>

                            <Box
                                display="flex"
                                flexDirection="column"
                                marginTop={2}
                                gap={1}
                            >
                                <FormikTextInput
                                    name="email"
                                    label={intl.formatMessage({ id: "orders.preparation.invoice.email" })}
                                    placeholder={intl.formatMessage({ id: "orders.preparation.invoice.email.placeholder" })}
                                />

                                <FormikTextInput
                                    name="fullName"
                                    label={intl.formatMessage({ id: "orders.preparation.invoice.fullName" })}
                                    placeholder={intl.formatMessage({ id: "orders.preparation.invoice.fullName.placeholder" })}
                                />

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    marginTop={1}
                                >

                                    <Typography>
                                        {intl.formatMessage({ id: "orders.preparation.invoice.meals" })}
                                    </Typography>

                                    <FormikPlusMinus
                                        name="meals"
                                        min={1}
                                    />
                                </Box>
                            </Box>

                            {false &&
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                >

                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            variant="caption"
                                            color={theme.palette.text.disabled}
                                            style={{ height: "fit-content" }}
                                        >
                                            {intl.formatMessage({ id: "orders.preparation.invoice.include.service_fees" })}
                                        </Typography>
                                        <FormikSwitch
                                            name="includeServiceFees"
                                            inputProps={{
                                                style: {
                                                    padding: 0,
                                                }
                                            }}
                                        />
                                    </Box>

                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            variant="caption"
                                            color={theme.palette.text.disabled}
                                            style={{ height: "fit-content" }}
                                        >
                                            {intl.formatMessage({ id: "orders.preparation.invoice.include.meal" })}
                                        </Typography>
                                        <FormikSwitch
                                            name="includeMeal"
                                            inputProps={{
                                                style: {
                                                    padding: 0,
                                                }
                                            }}
                                        />
                                    </Box>

                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            variant="caption"
                                            color={theme.palette.text.disabled}
                                            style={{ height: "fit-content" }}
                                        >
                                            {intl.formatMessage({ id: "orders.preparation.invoice.include.details" })}
                                        </Typography>
                                        <FormikSwitch
                                            name="includeDetails"
                                            inputProps={{
                                                style: {
                                                    padding: 0,
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            }

                            <Box
                                display="flex"
                                width={1}
                                justifyContent="center"
                                marginTop={5}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        padding: theme.spacing(1, 0),
                                        width: "65%",
                                        textTransform: "none",
                                    }}
                                    disabled={loadingInvoice}
                                    data-test="preparation_page.invoice_modal.submit"
                                >

                                    {loadingInvoice &&
                                        <CircularProgress
                                            size={15}
                                            sx={{
                                                marginRight: 2.5,
                                                color: theme.palette.text.disabled,
                                            }}
                                        />
                                    }

                                    {intl.formatMessage({ id: "orders.preparation.invoice.submit" })}
                                </Button>
                            </Box>
                        </Box>
                    </Form>
                </Formik>
            </Box>
        </Dialog>
    );
}

export default OrderInvoiceDialog;