import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getCategoriesFullRoute, isHomePage } from "../../config/routes";
import { CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y } from "../../customers/configs/CustomerInformationModalConfig";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { getAuthenticationProviderButtonColor } from "../helpers/AuthenticationHelpers";
import { AuthenticationStateData } from "../models/AuthenticationState";
import AuthenticationActions from "../redux/AuthenticationActions";
import SigninPassword from "./SigninPassword";
import SigninWithGetResto, { getRestoButtonIconUrl } from "./SigninWithGetResto";


export const getSigninButtonTitleId = (signinProvider: SignInProviders, mandatory: boolean, multipleProviders: boolean) => {
    if (multipleProviders) {
        if (mandatory) {
            return `authentication.signin.${signinProvider}_mandatory`
        } else {
            return `authentication.signin.${signinProvider}`
        }
    } else {
        if (mandatory) {
            return `authentication.signin.one_provider_mandatory`;
        } else {
            return `authentication.signin.one_provider`;
        }
    }
}

export const shouldDisplayGuestButton = (authenticationState: AuthenticationStateData, forceMandatory?: boolean | null) => {

    if (
        (authenticationState && !authenticationState.location_authentication_config.authentication_mandatory && !forceMandatory)
    ) {
        return true
    } else {
        return false
    }
}

interface SigninOrGuestButtonsProps {
    shouldOpenAnotherFormAfter?: (formToDisplay: CustomerInformationModalFormToDisplay) => boolean;
    forceMandatory?: boolean | undefined | null;
}

const SigninOrGuestButtons: React.FC<SigninOrGuestButtonsProps> = (props) => {

    const {
        forceMandatory,
    } = props;

    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { tableLinkId, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);

    const [selectedAuthenticationProvider, setSelectedAuthenticationProvider] = useState<SignInProviders | null>(null);

    const authenticationProviders = data.location_authentication_config.authentication_providers;

    useEffect(() => {
        if (!selectedAuthenticationProvider) {
            if (authenticationProviders?.length) {
                setSelectedAuthenticationProvider(authenticationProviders[0]);
            } else {
                // Password by default
                setSelectedAuthenticationProvider(SignInProviders.PASSWORD);
            }
        }
    }, [authenticationProviders]);

    const onSignInSuccess = (userCreated?: boolean) => {
        if (!userCreated) {
            dispatch(CustomerInformationModalActions.setCustomerInformationModal(CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION));
        }
    }

    const onWantedBeGuest = () => {

        const isEndAuthentication = !isHomePage(history.location.pathname, tableLinkId);
        dispatch(AuthenticationActions.continueAsGuest(isEndAuthentication));
        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES));

        // If no other forms to display after this one and we are not on the home page, go to the menu.
        // This will avoid being on the home page without any modal
        // TODO: move in CustomerInformationModal
        if (
            isView()
            || (
                !isEndAuthentication
                && props.shouldOpenAnotherFormAfter
                && !props.shouldOpenAnotherFormAfter(CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES)
            )
        ) {
            history.push(getCategoriesFullRoute(tableLinkId));
        }
    }

    const isView = (): boolean => {
        return selectedTable.service_type === SupportedServiceType.VIEW;
    }

    const renderSigninButton = (signinProvider: SignInProviders, index: number) => {

        const color = getAuthenticationProviderButtonColor(signinProvider);

        if (isView()) {
            return null
        }

        const titleId = getSigninButtonTitleId(signinProvider, !shouldDisplayGuestButton(data, forceMandatory), (authenticationProviders?.length && authenticationProviders?.length > 1) ? true : false);
        if (signinProvider !== selectedAuthenticationProvider) {
            return (
                <>
                    <Button
                        data-test={`signin_button_${signinProvider}`}
                        variant='contained'
                        onClick={() => setSelectedAuthenticationProvider(signinProvider)}
                        color={"primary"}
                        style={{
                            textTransform: "none",
                            width: "100%",
                            background: color,
                            padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                            marginTop: theme.spacing(1.5),
                        }}
                        key={signinProvider}
                    >
                        {signinProvider === SignInProviders.GETRESTO &&

                            <img
                                src={getRestoButtonIconUrl()}
                                alt=""
                                style={{
                                    maxHeight: "60%",
                                    marginRight: theme.spacing(1),
                                }}
                            />
                        }

                        <Typography variant="subtitle1">
                            {intl.formatMessage({ id: titleId })}
                        </Typography>

                    </Button>
                </>
            )
        } else {
            switch (signinProvider) {
                case SignInProviders.GETRESTO:
                    return (
                        <SigninWithGetResto
                            onSignInSuccess={onSignInSuccess}
                            buttonTitleId={titleId}
                        />
                    )
                case SignInProviders.PASSWORD:
                    return (
                        <SigninPassword
                            onSignInSuccess={onSignInSuccess}
                            buttonTitleId={titleId}
                        />
                    )
                default:
                    return null
            }
        }
    }

    return (

        <Box
            display="flex"
            flexDirection='column'
            alignItems="center"
            width={1}
            data-test="signin_or_guest_buttons"
        >

            {
                // Render all signin button if defined, otherwise only password
                authenticationProviders
                    ? authenticationProviders.map((authenticationProvider, index) => {
                        return renderSigninButton(authenticationProvider, index);
                    })
                    : renderSigninButton(SignInProviders.PASSWORD, 0)
            }

            {
                shouldDisplayGuestButton(data, forceMandatory)
                &&

                <Box
                    width={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Box
                        width={1}
                        my={1.5}
                        px={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems={"center"}
                        color={theme.palette.text.disabled}
                    >
                        <Divider sx={{ width: '40%' }} />
                        <Typography variant="subtitle1">
                            {intl.formatMessage({ id: "common.or.label" })}
                        </Typography>
                        <Divider sx={{ width: '40%' }} />
                    </Box>
                    <Button
                        variant="outlined"
                        onClick={onWantedBeGuest}
                        color="primary"
                        data-test={`guest_button`}
                        style={{
                            textTransform: "none",
                            width: "100%",
                            padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                        }}
                    >

                        <Typography variant="subtitle1">
                            {intl.formatMessage({
                                id: isView()
                                    ? 'orders.order_awaiting_payment.see_menu'
                                    : "authentication.authentication_form.welcomAuthentication.continue_as_guest"
                            })}
                        </Typography>
                    </Button>
                </Box>
            }
        </Box>
    );
}

export default SigninOrGuestButtons;