import log from "loglevel";
import { DateTime } from "luxon";
import moment from "moment";
import { IntlShape } from "react-intl";

export const getTwoNumbersStrFromNumber = (nbr: number): string => {

    return ('0' + nbr).slice(-2);
}

/**
 * As the "as" keyword does not work when getting a firebase timestamp, this function
 * ensures that we have a timestamp correctly typed.
 * @param date 
 * @returns 
 */
export function getFirebaseTimeStamp(date: Date): firebase.firestore.Timestamp {

    const dateUnknow: unknown = date;
    const dateTimestamp = dateUnknow as firebase.firestore.Timestamp;
    return dateTimestamp;
}

export function extractDateFromTimestampOrString(date: Date, timezoneName: string): Date | null {

    const timeStamp = date as unknown as firebase.firestore.Timestamp;
    const dateJS = new Date(date);

    if (timeStamp && typeof timeStamp.toDate === "function") {

        return DateTime.fromJSDate(timeStamp.toDate()).setZone(timezoneName).toJSDate();
    }
    else if (dateJS.toString() !== "Invalid Date") {

        return dateJS;
    }
    else {
        log.debug(`Invalid timestamp or string date`, date)
        return null;
    }
}

/**
 * Get a given date in a local string
 * @param date the date to display
 * @param intl 
 * @returns [date, time]
 * Example: ["today", "6:14 AM"]
 * Example: ["02/05/2021", "16:30"]
 */
export const getDateStr = (date: Date, intl: IntlShape): string[] => {

    let finalstr1: string = "";

    const dateMoment = moment(date);
    const todayMoment = moment(new Date());

    const dateArray = dateMoment.format("lll").split(" ");

    if (dateMoment.isSame(todayMoment, "day")) {

        finalstr1 = intl.formatMessage({ id: "today" });
    }
    else {

        finalstr1 = dateArray.slice(0, 3).join(" ");
    }

    return [finalstr1, dateArray.slice(3).join(" ")]
}