import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CONNECTORS_COMPATIBLE_CONNECTOR_WALLET } from "../../my-lemonade-library/src/connectors/models/ConnectorWallet";
import { useTypedSelector } from "../../redux/root-reducer";
import paymentsActions from "../redux/PaymentsActions";

const useCustomerEmailForWallet = () => {

    const { order } = useTypedSelector((state) => state.order);
    const { selectedLocation } = useTypedSelector((state) => state.locations);
    const { connector_wallet } = useTypedSelector((state) => state.payment);

    const dispatch = useDispatch();

    useEffect(() => {

        const connectorType = selectedLocation?.connector?.type;

        if (
            (order.customer?.email || order.customer?.phone)
            && connectorType
            && CONNECTORS_COMPATIBLE_CONNECTOR_WALLET.includes(connectorType)
            && connector_wallet[connectorType] === undefined
        ) {
            dispatch(paymentsActions.getCustomerConnectorWalletBalance(order.customer.email, order.customer.phone));
        }
    }, [
        order.customer?.email,
        order.customer?.phone,
        selectedLocation?.connector?.type,
    ])

}

export default useCustomerEmailForWallet;