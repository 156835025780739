import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import log from 'loglevel';
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import DefaultImage from '../../assets/alcool.svg';
import dataTestConst from '../../config/DataTestConst';
import { getProductsFullRoute, getSubcategoriesFullRoute, RouteParams } from '../../config/routes';
import { Categorie, CategoryDisplayMode } from '../../my-lemonade-library/model/Catalog';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import '../pages/CategoriesPage.css';

type Props = {
    category: Categorie
}
/**
 * Component in charge a square icon associate to one category
 * @param props 
 */
const CatalogsCardsComponent = (props: Props) => {

    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams<RouteParams>();
    const intl = useIntl();

    let url = match.url;
    if (url[url.length - 1] === "/") {
        url = url.slice(0, url.length - 1)
    }

    /**
     * Function in charge to generate the route using the category ref props
     */
    const handleCategoryClick = () => {
        let route: string;
        if (props.category.display === CategoryDisplayMode.SUBCATEGORIES) {
            route = getSubcategoriesFullRoute(params.tableLinkKey, props.category.ref);
        } else {
            route = getProductsFullRoute(params.tableLinkKey, props.category.ref);
        }
        history.push(route);
    };

    const getCategoryTranslated = (): string => {

        const translationId = translationService.getCategorytNameTranslationKey(props.category);

        if (translationId && intl.messages[translationId]) {

            return intl.formatMessage({ id: translationId })
        }

        log.debug(`Missing translation for category name ${props.category.name}`);
        return props.category.name;
    }

    return (

        // TODO: use MUI Box instead of div (tested, requires debug)

        <div id="element" data-test={`${dataTestConst.CATEGORY_PREFIX}${props.category.ref}`}>
            <div id="container" onClick={handleCategoryClick}>
                <div id="dummy"></div>
                <Paper elevation={0} id="card">
                    {props.category.icon ?
                        props.category.icon.includes("svg") ?  // Check if svg
                            <img width="50%" height="50%" src={props.category.icon} alt='' /> :
                            <img width="100%" height="100%" style={imgStyle} src={props.category.icon} alt='' /> :
                        <img width="50%" height="50%" src={DefaultImage} alt='' />}
                </Paper>
            </div>
            <div style={{ textAlign: "center" }}>
                <Typography variant="subtitle1">
                    {getCategoryTranslated()}
                </Typography>
            </div>
        </div>
    )

}

export default CatalogsCardsComponent

const imgStyle: CSSProperties = {
    objectFit: "cover",
    objectPosition: "center" /* Center the image within the element */
}