import _ from "lodash";
import { Catalog } from "../../../model/Catalog";
import { Order, OrderInBase, PaymentType } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import { getChildCatalogRef } from "../../catalogs/services/CatalogService";
import { addMoney, getCurrency, moneyToNumber, numberToMoney } from "../../common/models/Money";
import { log } from "../../common/services/LogService";
import discountService from "../../discounts/service/DiscountService";
import { OrderPaymentStatus } from "../../payments/models/OrderPaymentStatus";
import { PaymentStatus } from "../../payments/models/PaymentStatus";
import marketplacePaymentHelper from "../../payments/services/MarketplacePaymentHelper";
import { paymentHelper } from "../../payments/services/PaymentHelper";
import { Tax } from "../../taxes/models/Tax";
import ChildOrderItemState from "../models/ChildOrderItemState";
import ChildOrderState from "../models/ChildOrderState";
import { CHILD_ORDER_PAYMENT_INTENT_ID } from "./OrderService";
import { computeOrderTotals } from "./OrderTotals";

const PERCENTAGE_ROUND_FACTOR = 10000;

export const childOrderHelper = {
    getChildOrderId(orderId: string, childLocationId: string, childCatalogId: string): string {
        // If the order id is not yet defined, return empty string
        if (orderId) {
            const childOrderId = `${orderId}|${childLocationId.substring(0, 3)}|${childCatalogId.substring(0, 3)}`;
            return childOrderId
        }
        return "";
    },

    needChildOrders(parentOrder: OrderInBase): boolean {
        let needChildOrders: boolean = false;
        parentOrder.items.forEach((orderItem) => {
            if (orderItem.child_orders_item && orderItem.child_orders_item.location_id) {
                needChildOrders = true;
            }
        })
        return needChildOrders;
    },

    /**
     * Compute & set child order parts of the order.
     * Supposed to be called when subtotal have been computed
     * @param parentOrder 
     */
    computeChildOrderParts(parentOrder: Order) {
        if (parentOrder.id) {
            const childOrderStates: { [key: string]: ChildOrderState } = {};
            if (!parentOrder.subtotal) {
                throw OrderError.CHILD_ORDER_PARTS_COMPUTED_WITHOUT_SUBTOTAL;
            }
            const currency: string = getCurrency(parentOrder.subtotal);
            parentOrder.items.forEach((orderItem) => {
                if (orderItem.child_orders_item && orderItem.child_orders_item.order_id) {
                    if (!childOrderStates[orderItem.child_orders_item.order_id]) {
                        if (!orderItem.subtotal) {
                            throw OrderError.CHILD_ORDER_PARTS_COMPUTED_WITHOUT_SUBTOTAL;
                        }
                        childOrderStates[orderItem.child_orders_item.order_id] = {
                            order_id: orderItem.child_orders_item.order_id,
                            subtotal: orderItem.subtotal,
                            location_id: orderItem.child_orders_item.location_id,
                            status: parentOrder.status,
                        }
                        if (orderItem.child_orders_item.payment_seller_id) {
                            childOrderStates[orderItem.child_orders_item.order_id]!.payment_seller_id = orderItem.child_orders_item.payment_seller_id;
                        }
                    } else {
                        childOrderStates[orderItem.child_orders_item.order_id].subtotal = addMoney(childOrderStates[orderItem.child_orders_item.order_id].subtotal!, orderItem.subtotal!);
                    }
                }
            })

            const childOrderStatesList = Object.values(childOrderStates);
            const totalDiscounts = discountService.getOrderTotalDiscount(parentOrder);
            childOrderStatesList.forEach((childOrderState) => {
                const childOrderSubtotal = childOrderState.subtotal ? moneyToNumber(childOrderState.subtotal) : 0;
                childOrderState.order_subtotal_percentage = Math.round(PERCENTAGE_ROUND_FACTOR * childOrderSubtotal * 100.0 / moneyToNumber(parentOrder.subtotal!)) / PERCENTAGE_ROUND_FACTOR;
                const discountPart = totalDiscounts ? Math.ceil(childOrderState.order_subtotal_percentage * totalDiscounts) / 100 : 0;
                childOrderState.discount_part = numberToMoney(discountPart, currency);
                // TODO: charge part must be done
                childOrderState.charge_part = numberToMoney(0, currency);

                // Compute from money to deal with rounding
                const childOrderTotalPart = childOrderSubtotal + moneyToNumber(childOrderState.charge_part) - moneyToNumber(childOrderState.discount_part);
                childOrderState.total = numberToMoney(childOrderTotalPart, currency);
                childOrderState.order_percentage = Math.round(PERCENTAGE_ROUND_FACTOR * childOrderTotalPart * 100.0 / moneyToNumber(parentOrder.total!)) / PERCENTAGE_ROUND_FACTOR;
            });

            parentOrder.child_orders = childOrderStates;
        }
    },

    getChildOrders(parentOrder: OrderInBase, parentCatalog: Catalog): OrderInBase[] {
        const childOrders: OrderInBase[] = [];
        const childCatalogLinks: { [key: string]: ChildOrderItemState } = {};
        parentOrder.items.forEach((orderItem) => {
            if (orderItem.child_orders_item && !childCatalogLinks[orderItem.child_orders_item.order_id]) {
                childCatalogLinks[orderItem.child_orders_item.order_id] = orderItem.child_orders_item;
            }
        })
        const childCatalogLinksList = Object.values(childCatalogLinks);
        log.info(`Found ${childCatalogLinksList.length} child orders to build`)
        childCatalogLinksList.forEach((childCatalogLink) => {
            const childOrder = childOrderHelper.getChildOrder(parentOrder, parentCatalog, childCatalogLink.location_id, childCatalogLink.catalog_id);
            childOrders.push(childOrder);
        })

        return childOrders;
    },

    getChildOrder(parentOrder: OrderInBase,
        dataWithTax: {
            currency: string;
            taxes?: Tax[]
        },
        childLocationId: string,
        childCatalogId: string): OrderInBase {

        const childOrder: OrderInBase = _.cloneDeep(parentOrder);
        const childOrderState = childOrder.child_orders ? Object.values(childOrder.child_orders)?.find((childOrderState) => childOrderState.location_id === childLocationId) : undefined;
        delete childOrder.child_orders;
        childOrder.id = this.getChildOrderId(parentOrder.id, childLocationId, childCatalogId);
        childOrder.location_id = childLocationId;
        childOrder.catalog_id = childCatalogId;
        childOrder.parent_order_id = parentOrder.id;
        childOrder.parent_location_id = parentOrder.location_id;

        // For now, remove discount & charges ?
        delete childOrder.discounts;
        delete childOrder.charges;

        childOrder.items = [];
        parentOrder.items?.forEach((orderItem) => {
            if (orderItem.child_orders_item &&
                orderItem.child_orders_item.location_id === childLocationId &&
                orderItem.child_orders_item.catalog_id === childCatalogId) {
                const clonedItem = _.cloneDeep(orderItem);
                delete clonedItem.child_orders_item;
                // Remove prefix used to avoid ref collpasing
                clonedItem.product_ref = getChildCatalogRef(clonedItem.product_ref, childCatalogId);
                clonedItem.sku_ref = getChildCatalogRef(clonedItem.sku_ref, childCatalogId);
                clonedItem.categories_refs = clonedItem.categories_refs?.map((categoryRef) => {
                    return getChildCatalogRef(categoryRef, childCatalogId);
                })
                clonedItem.options?.forEach((option) => {
                    option.ref = getChildCatalogRef(option.ref, childCatalogId);
                    option.option_list_ref = getChildCatalogRef(option.option_list_ref, childCatalogId);
                })
                if (clonedItem.deal_line) {
                    clonedItem.deal_line.deal_line_ref = getChildCatalogRef(clonedItem.deal_line.deal_line_ref, childCatalogId);
                }
                childOrder.items.push(clonedItem)
            }
        })

        // Keep only full deals$
        childOrder.deals = {};
        if (parentOrder.deals) {
            for (let dealKey in parentOrder.deals) {
                const deal = parentOrder.deals[dealKey];
                const parentProducts = parentOrder.items?.filter((orderItem) => orderItem.deal_line?.deal_key === dealKey);
                const childProducts = childOrder.items?.filter((orderItem) => orderItem.deal_line?.deal_key === dealKey);
                if (childProducts.length === parentProducts.length) {
                    const clonedDeal = _.cloneDeep(deal);
                    clonedDeal.ref = getChildCatalogRef(clonedDeal.ref, childCatalogId);
                    childOrder.deals[dealKey] = clonedDeal;
                } else {
                    // Remove the deal to prevent side effects
                    childProducts.forEach((childProduct) => {
                        delete childProduct.deal_line;
                    })
                }
            }
        }

        // Recompute total & taxes
        computeOrderTotals(childOrder, dataWithTax);

        // getSellerPayments
        if (childOrderState && childOrderState.payment_seller_id && parentOrder.payments) {
            childOrder.payments = marketplacePaymentHelper.getSellerChildOrderPayments(parentOrder.payments, childOrderState.payment_seller_id);
            if (childOrder.payments.length === 0) {
                delete childOrder.payments;
            }
        } else {
            // In case we are not in marketplace mode, we send the payment only if the parent order is fully paid
            // Otherwise, we don't now what is linked to the child order
            const paymentStatus = paymentHelper.getOrderPaymentStatus(parentOrder);
            if (paymentStatus === OrderPaymentStatus.PAID) {
                const firstPayment = parentOrder.payments?.find((payment) => payment.status === PaymentStatus.PAID);
                const paymentType = (firstPayment && firstPayment.payment_type) ? firstPayment.payment_type : PaymentType.NONE;

                childOrder.payments = [{
                    amount: childOrder.total,
                    status: PaymentStatus.PAID,
                    ref: firstPayment?.ref,
                    payment_type: paymentType,
                    payment_intent_id: CHILD_ORDER_PAYMENT_INTENT_ID
                }];
            } else {
                delete childOrder.payments;
            }
        }

        return childOrder;
    },
}
export default childOrderHelper;