import 'firebase/firestore';
import 'firebase/storage';
import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import log from '../../Common/services/LogService';
import { firebaseApp, rsf } from '../../config/firebase';
import { Terms, TermsTarget } from '../../my-lemonade-library/src/authentications/models/Terms';
import { getTermsFirestoreCollectionPath } from '../../my-lemonade-library/src/authentications/service/AuthenticationService';
import DeploymentInfo from '../../my-lemonade-library/src/common/models/DeploymentInfo';
import { extractDateFromTimestampOrString } from '../../my-lemonade-library/src/common/services/DateHelper';
import { appActions, FETCH_DEPLOYMENT } from './AppActions';

const db = firebaseApp.firestore();

const fetchDeployment = async (): Promise<DeploymentInfo | null> => {
    const deploymentResponse = await fetch(process.env.PUBLIC_URL + "/deployment.json")
    if (deploymentResponse.ok) {
        const deploymentData = await deploymentResponse.json() as DeploymentInfo;
        return deploymentData;
    }
    return null;
};


export function* getTerms(deployment: DeploymentInfo) {

    try {
        // Get the latest (by application date) terms 
        const collectionQuery: any = db.collection(getTermsFirestoreCollectionPath())
            .where("deployment_name", "==", deployment.name)
            .where("target", "==", TermsTarget.CUSTOMERS)
            .orderBy("application_date", "desc").limit(1);
        const termsSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield call(rsf.firestore.getCollection, collectionQuery)

        let latestTermsAvailable: Terms | undefined = undefined;

        // The for is for TS reasons only, because we know there is only one element
        for (const termsSnap of termsSnapshot.docs) {

            latestTermsAvailable = termsSnap.data() as Terms;
            latestTermsAvailable.id = termsSnap.id;

            // If possible, convert the dates to Date objects
            latestTermsAvailable.created_at = extractDateFromTimestampOrString(latestTermsAvailable.created_at) ?? latestTermsAvailable.created_at;
            latestTermsAvailable.application_date = extractDateFromTimestampOrString(latestTermsAvailable.application_date) ?? latestTermsAvailable.application_date;
        }

        if (latestTermsAvailable) {
            return latestTermsAvailable;
        }
        else {
            log.error(`No terms available (deployment: ${deployment.name}, target: ${TermsTarget.CUSTOMERS}). Not asking to accept.`);
        }
    }
    catch (error) {
        log.error(`Error while getting terms`, error);
    }
    return null;
}

function* fetchDeploymentGenerator() {
    try {
        const deploymentInfo: DeploymentInfo | null = yield call(fetchDeployment);
        if (deploymentInfo) {
            const terms: Terms | null = yield call(getTerms, deploymentInfo);
            yield put(appActions.fetchDeploymentSuccess(deploymentInfo, terms))
        }
    } catch (error) {
        if (error instanceof Error) {
            log.error(`Error while fetching deployment info: ${error.message}`)
        } else {
            log.error(`Unexpected error while fetching deployment info`, error)
        }
    }
}

export function* appSaga() {
    yield all([
        takeLatest(FETCH_DEPLOYMENT, fetchDeploymentGenerator),
    ]);
}
