import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Button, Checkbox, Dialog, Divider, FormControlLabel, InputAdornment, TextField, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OrderCharge } from '../../my-lemonade-library/model/Order';
import { addMoney, Money, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { Tips } from '../../my-lemonade-library/src/tips/models/Tips';
import { TipsPricingEffect } from '../../my-lemonade-library/src/tips/models/TipsPricingEffect';

const useStyle = makeStyles((theme: Theme) => {
    return {
        selected: {
            borderColor: theme.palette.primary.main
        },
        unSelected: {
            borderColor: theme.palette.secondary.main
        }
    }
})

interface TipModalProps {
    open: boolean;
    onClose?: () => void;
    tip: Tips;
    onFinalAction: (charge?: OrderCharge) => void;
    currency: string;
    paymentAmount: Money;
    charge?: OrderCharge;
    onChargeChange: (charge: OrderCharge) => void;
}

const TipModal: React.FC<TipModalProps> = (props) => {

    const { open, onClose, tip, onFinalAction, currency, paymentAmount, charge, onChargeChange } = props

    const intl = useIntl();
    const theme = useTheme()
    const classes = useStyle()

    /**
     * Tip Amount
     * index 0 represent the always the round tip
     * index 1 represent the custom tip (suggestion choice or custom amount)
     * It's not possible to select two custom tip different but you can round and add more tip
     */
    const DEFAULT_TIP_AMOUNT: Money[] = [numberToMoney(0, currency), numberToMoney(0, currency)]
    const [tipAmount, setTipAmount] = useState<Money[]>(DEFAULT_TIP_AMOUNT)
    /**
     * This state is use to identify wich tip options are selected to highlight boxes
     */
    const [tipSelected, setTipSelected] = useState<{ [key: string]: boolean | number | null }>({
        round: false,
        index: null
    })
    /**
     * This state handle the custom tip amount
     */
    const [customAmount, setCustomAmount] = useState("")
    const [customAmountError, setCustomAmountError] = useState<boolean>(false)


    /**
     * This useEffect keep the charge price updated by computing sum of tipAmount
     */
    useEffect(() => {
        if (charge && tipAmount && tipAmount.length) {
            let totalPrice = addMoney(tipAmount[0], tipAmount[1])

            onChargeChange({
                ...charge,
                price: totalPrice
            })
        }
    }, [tipAmount])


    const getSuggestionBoxes = (tip: Tips) => {
        let suggestions = tip.suggestion_choices
        const values: string[] = []
        if (tip.pricing_effect === TipsPricingEffect.FIXED_PRICE) {
            suggestions = tip.suggestion_choices as Money[]
            suggestions.forEach(suggestion => {
                values.push(
                    intl.formatMessage({ id: "payment.tips.suggestion.box" }, { value: MoneyToStringWithSymbol(suggestion), symbol: "" })
                )
            })
        } else {
            suggestions = tip.suggestion_choices as number[]
            suggestions.forEach(suggestion => {
                values.push(
                    intl.formatMessage({ id: "payment.tips.suggestion.box" }, { value: suggestion * 100, symbol: "%" })
                )
            })

        }
        return values
    }

    const handleCustomAmount = (val: string) => {
        if (tipAmount) {
            if (val === '') {
                setCustomAmount('')
                setTipAmount([
                    tipAmount[0],
                    numberToMoney(0, currency)
                ])
                setCustomAmountError(false)
            } else {
                const replaceComa = val.replace(',', '.')
                const parsedValue = parseFloat(replaceComa)
                if (!isNaN(parsedValue) && parsedValue >= 0) {
                    setCustomAmount(val)
                    setTipAmount([
                        tipAmount[0],
                        numberToMoney(parsedValue, currency)
                    ])
                    setCustomAmountError(false)
                } else {
                    setCustomAmountError(true)
                }
            }


            if (tipSelected.index === tip.suggestion_choices.length) {
                setTipSelected({
                    ...tipSelected,
                    index: null
                })
            } else {
                setTipSelected({
                    ...tipSelected,
                    index: tip.suggestion_choices.length
                })
            }
        }
    }

    const getRoundAmount = () => {

        const paymentAmountNumber = moneyToNumber(paymentAmount)
        const roundedMax = Math.ceil(paymentAmountNumber)
        return roundedMax - paymentAmountNumber
    }

    if (!charge) {
        return null
    }


    return (
        <Dialog
            PaperProps={{
                style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: theme.spacing(2),
                    margin: theme.spacing(1)
                }
            }}
            open={open}
            onClose={onClose}
        >
            <Box my={1}>
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "payment.tips.modal.title" })}
                </Typography>
            </Box>
            <Box my={1} style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <Typography style={{ margin: theme.spacing(0, 0, 2, 0) }}>
                    {intl.formatMessage({ id: tip.round_option && getRoundAmount() ? "payment.tips.modal.text_with_round" : "payment.tips.modal.text_without_round" })}
                </Typography>
                {tip.round_option && getRoundAmount() ?
                    <>
                        <Box display={"flex"} alignSelf="flex-start" alignItems={"center"} width={1} my={1}>

                            <FormControlLabel
                                style={{
                                    flex: 1
                                }}
                                control={
                                    <Checkbox
                                        data-test="payment.tips.modal.checkbox.round"
                                        checked={tipSelected.round as boolean}
                                        onClick={() => {
                                            const isSelected = !tipSelected.round
                                            setTipSelected({
                                                ...tipSelected,
                                                round: isSelected
                                            })
                                            if (isSelected) {
                                                setTipAmount([
                                                    numberToMoney(getRoundAmount(), currency),
                                                    tipAmount[1]
                                                ])
                                            } else {
                                                setTipAmount([
                                                    numberToMoney(0, currency),
                                                    tipAmount[1]
                                                ])
                                            }
                                        }}
                                        icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                        checkedIcon={<CheckCircleIcon color='primary' />}
                                    />}
                                label={
                                    <Typography>
                                        {intl.formatMessage({
                                            id: "payment.tips.modal.checkbox.round.text"
                                        })}
                                    </Typography>
                                }
                            />
                            <Typography>
                                {"+ " + MoneyToStringWithSymbol(numberToMoney(getRoundAmount(), currency))}
                            </Typography>
                        </Box>
                        <Divider style={{ width: "100%", margin: theme.spacing(0, 0, 2, 0) }} />
                    </>
                    : null}
                <Typography style={{ alignSelf: "flex-start", marginTop: theme.spacing(1) }}>
                    {intl.formatMessage({ id: "payment.tips.modal.tip_subtitle" })}
                </Typography>
                <Box display={"flex"} alignSelf="flex-start" width={1} flexWrap={"wrap"} my={1}>
                    {getSuggestionBoxes(tip).map((value: string, index: number) => (
                        <Box key={index} flex={1} style={{
                            margin: index !== 0 ? theme.spacing(0.5, 0.5, 0.5, 0.5) : theme.spacing(0.5, 0.5, 0.5, 0),
                            borderRadius: "5px",
                            height: 50,
                            //minWidth: 70
                        }}>
                            <Button
                                data-test={`payment.tips.suggestion_choices.${index}`}
                                variant="outlined"
                                color={tipSelected.index === index ? "primary" : "secondary"}
                                onClick={() => {

                                    let moneyValue = numberToMoney(0, currency)
                                    if (tip.pricing_effect === TipsPricingEffect.FIXED_PRICE) {
                                        moneyValue = tip.suggestion_choices[index] as Money
                                    } else {
                                        const tipPercent = tip.suggestion_choices[index] as number
                                        moneyValue = numberToMoney(moneyToNumber(paymentAmount) * tipPercent, currency)
                                    }
                                    if (tipSelected.index === index) {
                                        setTipSelected({
                                            ...tipSelected,
                                            index: null
                                        })
                                        setTipAmount([
                                            tipAmount[0],
                                            numberToMoney(0, currency)
                                        ])
                                    } else {
                                        setTipSelected({
                                            ...tipSelected,
                                            index: index
                                        })
                                        setTipAmount([
                                            tipAmount[0],
                                            moneyValue
                                        ])
                                    }
                                }}
                                style={{
                                    // margin: index !== 0 ? theme.spacing(0, 1, 0, 1) : 0,
                                    borderRadius: "5px",
                                    height: "100%",
                                    width: "100%",
                                    padding: theme.spacing(0.5)
                                }}>
                                <Typography>
                                    {value}
                                </Typography>
                            </Button>
                        </Box>
                    ))}
                    <TextField
                        variant='outlined'
                        label={intl.formatMessage({ id: "common.other" })}
                        style={{
                            flex: 1,
                            //height: 50,
                            //minWidth: 70,
                            margin: theme.spacing(0.5, 0, 0.5, 0.5),
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: tipSelected.index === tip.suggestion_choices.length ? theme.palette.primary.main : theme.palette.secondary.main
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end' style={{ padding: 0 }}>
                                    {getSymbolFromCurrency(currency)}
                                </InputAdornment>
                            ),
                            style: {
                                height: 50,
                                color: tipSelected.index === tip.suggestion_choices.length ? theme.palette.primary.main : theme.palette.secondary.main,
                            },

                            classes: {
                                notchedOutline: tipSelected.index === tip.suggestion_choices.length ? classes.selected : classes.unSelected
                            }
                        }}
                        value={customAmount}
                        placeholder={"0.00"}
                        error={customAmountError}
                        onClick={() => handleCustomAmount(customAmount)}
                        onChange={(e) => handleCustomAmount(e.target.value)}
                    />
                </Box>
                <Box display="flex" width={1} my={2}>
                    <Typography style={{ flex: 1, fontWeight: "bold" }}>
                        {intl.formatMessage({ id: "payment.tips.modal.total_to_pay" })}
                    </Typography>
                    <Typography data-test="payment.tips.total_with_tips" style={{ fontWeight: "bold" }}>
                        {
                            MoneyToStringWithSymbol(addMoney(paymentAmount, charge.price))
                        }
                    </Typography>
                </Box>

            </Box>
            <Box my={1} display={"flex"} flexDirection="column" width={1} alignItems="center">
                <Button
                    data-test="payment.tips.button.add_tips"
                    style={{
                        flex: 1,
                        width: "80%",
                        textTransform: "none"
                    }}
                    onClick={() =>
                        onFinalAction()
                    }
                    variant='contained'
                    color='primary'
                >
                    {intl.formatMessage({ id: "payment.tips.modal.button.valid" })}
                </Button>
                <Typography
                    data-test="payment.tips.button.no_tips"
                    color="textSecondary"
                    onClick={() => {
                        setTipAmount(DEFAULT_TIP_AMOUNT)
                        const emptyCharge = {
                            ...charge,
                            price: numberToMoney(0, currency)
                        }
                        onChargeChange(emptyCharge)
                        onFinalAction(emptyCharge)
                    }}
                    style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: theme.spacing(2)
                    }}>
                    {intl.formatMessage({ id: "payment.tips.modal.button.no_tips" })}
                </Typography>
            </Box>

        </Dialog >
    )
}

export default TipModal;