import BgLang from './entries/bg_BG';
import CsLang from './entries/cs_CZ';
import DaLang from './entries/da_DK';
import DeLang from './entries/de_DE';
import Enlang from './entries/en_US';
import Eslang from './entries/es_ES';
import Etlang from './entries/et_EE';
import FiLang from './entries/fi_FI';
import Frlang from './entries/fr_FR';
import Hulang from './entries/hu_HU';
import Itlang from './entries/it_IT';
import JaLang from './entries/ja_JP';
import LtLang from './entries/lt_LT';
import LvLang from './entries/lv_LV';
import NlLang from './entries/nl_NL';
import PlLang from './entries/pl_PL';
import PtLang from './entries/pt_PT';
import RoLang from './entries/ro_RO';
import RuLang from './entries/ru_RU';
import SkLang from './entries/sk_SK';
import SlLang from './entries/sl_SI';
import SvLang from './entries/sv_SE';
import ZhLang from './entries/zh_Hans_CN';



export interface LocaleTranslations {
    messages: { [key: string]: string };
    locale: string;
}

const AppLocale: { [lang: string]: LocaleTranslations } = {
    en: Enlang,
    it: Itlang,
    es: Eslang,
    fr: Frlang,
    bg: BgLang,
    cs: CsLang,
    da: DaLang,
    de: DeLang,
    et: Etlang,
    fi: FiLang,
    hu: Hulang,
    ja: JaLang,
    lt: LtLang,
    lv: LvLang,
    nl: NlLang,
    pl: PlLang,
    pt: PtLang,
    ro: RoLang,
    ru: RuLang,
    sk: SkLang,
    sl: SlLang,
    sv: SvLang,
    zh: ZhLang
};

export default AppLocale;
