import { Box } from '@mui/material';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useAvailableCategories from '../../Categories/hooks/useAvailableCategories';
import FabButtonOrder from '../../Common/components/FabButtonOrder';
import * as ROUTES from '../../config/routes';
import { Categorie, CategoryDisplayMode } from '../../my-lemonade-library/model/Catalog';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { DisplayProductsScroll } from '../component/DisplayProductsScroll';
import ProductsGridComponent from '../component/ProductsGridComponent';

const ProductsPage = () => {

    const params = useParams<ROUTES.RouteParams>();
    const initRef = params.categoryRef;
    const { selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const subCategoryList = useAvailableCategories(initRef);

    const [displayRef, setDisplayRef] = useState<string | null>((subCategoryList && subCategoryList.length && subCategoryList[0]) ? subCategoryList[0].ref : null);
    const [categorySelected, setCategorySelected] = useState<Categorie>();

    /**
     * define the category Selected
     */
    useEffect(() => {
        log.debug('effect categorySelected')

        if (selectedCatalog && params) {
            const category = selectedCatalog.data.categories.find((elem: { ref: string; }) => elem.ref === params.categoryRef)
            if (category) {
                setCategorySelected(category)
            }
        }
    }, [selectedCatalog, params])

    /**
     * Check if initRef is subCategory and setInit as subCate ans displayRef or keep the parentRef
     */
    useEffect(() => {
        if (selectedCatalog) {
            if (subCategoryList && subCategoryList.length) {
                const initDisplayRef = subCategoryList.length ? subCategoryList[0].ref : initRef;
                setDisplayRef(initDisplayRef);
            } else {
                // No subcategories, set the parent ref
                setDisplayRef(initRef);
            }
        }
    }, [selectedCatalog, subCategoryList])

    /**
     * Force the scroll to the top of the page
     * TODO: find the real cause of this auto-scroll at the begining
     */
    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);


    if (selectedCatalog && categorySelected && selectedTable && displayRef) {
        let products = selectedCatalog.data.productsByCategory ? selectedCatalog.data.productsByCategory[displayRef] : undefined;
        if (!products) {
            products = [];
        }
        let deals = selectedCatalog.data.dealsByCategory ? selectedCatalog.data.dealsByCategory[displayRef] : undefined;
        if (!deals) {
            deals = [];
        }
        return (

            <Box
                p={categorySelected.display === CategoryDisplayMode.GRID ? 1 : 0}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="stretch"
                height="100%"
            >
                {categorySelected.display === CategoryDisplayMode.GRID
                    ? <ProductsGridComponent rootCatRef={initRef} />
                    : <DisplayProductsScroll rootCatRef={initRef} />
                }

                <Box position="sticky" bottom="0px">
                    <FabButtonOrder />
                </Box>
            </Box>

        )
    } else {
        return null
    }
}


export default ProductsPage

