import { DateTime } from "luxon";
import { Catalog, Categorie, Deal, Product } from "../../my-lemonade-library/model/Catalog";
import { Location, SupportedServiceType, Table } from "../../my-lemonade-library/model/Location";
import { canOrderEntityNow, canPlaceOrderNow } from "../../my-lemonade-library/src/restrictions/services/RestrictionsService";

export const isEntityOrderableNow = (
    catalog: Catalog | undefined,
    location: Location | undefined,
    table: Table,
    entity: Product | Categorie | Deal,
): boolean => {

    return Boolean(
        canPlaceOrderNow(location, catalog, table, DateTime.now()).canPlaceOrderNow
        && canOrderEntityNow(location, catalog, entity, table.service_type, DateTime.now()).canOrderEntityNow
        && table.service_type !== SupportedServiceType.VIEW
    );
}