import { EnvNames } from "../my-lemonade-library/src/common/configs/EnvNames";
import { getApiHost } from "../my-lemonade-library/src/common/configs/MylemonadeApplicationsUrlConfig";

export const isProdEnv = () => {
    return process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "preprod";
}

export const isDevEnv = () => {
    return process.env.REACT_APP_ENV === EnvNames.DEV;
}
// TODO: use the firebase project instead ?
export const getApiEndpoint = () => {

    if (process.env.REACT_APP_API_ENDPOINT) {
        return process.env.REACT_APP_API_ENDPOINT;
    }
    const appEnv = process.env.REACT_APP_ENV as EnvNames;
    // TODO: deployment
    return getApiHost(appEnv, null);
}

export const getApiRootUrl = () => {
    return `${getApiEndpoint()}/api/v1`;
}

export const IOS_OPERATING_SYSTEM = 'iOS'
export const SAFARI_BROWSER = 'safari'