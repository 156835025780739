import { Box, Button, Typography, useTheme } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import TermsAndConditionsTextWithModal from '../../authentication/components/TermsAndConditionsTextWithModal';
import { isUserLoggedIn } from '../../authentication/helpers/AuthenticationHelpers';
import { useAcceptedTerms } from '../../authentication/hooks/useAcceptedTerms';
import AuthenticationActions from '../../authentication/redux/AuthenticationActions';
import FormikCheckBox from '../../Common/components/Formik/FormikCheckBox';
import FormikPhoneInput from '../../Common/components/Formik/FormikPhoneInput';
import FormikTextInput from '../../Common/components/Formik/FormikTextInput';
import LoaderComponent from '../../Common/components/LoaderComponent';
import { checkEmailRegex } from '../../Common/helper/EmailHelper';
import log from '../../Common/services/LogService';
import * as ROUTES from '../../config/routes';
import { CustomerInfo, DEFAULT_COUNTRY, SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { Customer } from '../../my-lemonade-library/src/authentications/models/Customer';
import { getSameSubRegionCountryCodes } from '../../my-lemonade-library/src/common/services/AddressService';
import { RequireCustomerInfoFields } from '../../my-lemonade-library/src/customers/models/RequireCustomerInfoFields';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import OrderHeader from '../components/OrderHeader';
import orderActions from '../redux/OrderActions';
import { getCustomerInfoFieldsToAsk } from '../services/CustomerService';
import { choosePaymentModeOrSendPayOrder } from '../services/OrderService';


/**
 * This screen is used to collect customer information to put into the order.
 * The fields displayed are controlled by the Location information. In the back-office, go to
 * Settings > Customers to set them. 
 * Each service_type has its set of information to ask.
 */
interface ServicePickupInfoProps {
    onClearIconClick?: () => void;
    forceLoadOnValidation?: boolean;
    isDisplayedInDialog?: boolean;
}

const ServicePickUpInfo: React.FC<ServicePickupInfoProps> = (props) => {

    const { onClearIconClick, isDisplayedInDialog } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();

    const orderState = useTypedSelector((state: RootState) => state.order);
    const { order } = orderState;
    const { data } = useTypedSelector(state => state.authentication);
    const { user_authentication_state } = data;
    const { user } = user_authentication_state;
    const { selectedLocation, selectedCatalog, selectedTable, tableLinkId, fromApp } = useTypedSelector(state => state.locations);
    const { use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);
    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);

    const hasAcceptedTerms = useAcceptedTerms();

    const serviceTypeForRequiredInfo = selectedTable.service_type ?? order.service_type;

    /**
     * List of all fields required in location config and missing in order.
     * Monitor if a field has to be displayed or not in isFieldDisplayed.
     * Not taking into account terms.
     */
    const missingFields = useMemo(() => {
        if (selectedLocation && order) {
            const requiredInfo = selectedLocation.require_customer_info?.[serviceTypeForRequiredInfo];
            const newMissingFields = getCustomerInfoFieldsToAsk(order, requiredInfo ?? null, true, user_authentication_state);
            return newMissingFields;
        }
    }, [selectedLocation, serviceTypeForRequiredInfo, order, user_authentication_state]);


    /**
     * If no items in the order, go back to category page
     */
    useEffect(() => {
        if (!order.items.length) {
            history.push(ROUTES.LocationHome.replace(':tableLinkKey', tableLinkId) + ROUTES.CategoriesPage)
        }
    }, [orderState, history, tableLinkId])

    /**
     * Check if a field has to be displayed (and asked) to the user.
     * WARNING: it does not mean that the field is unskipable (most of the time yes),
     * for example marketing can be displayed (this function returns true) but we will never
     * force the user to check it.
     * @param field the name(string) of the field
     * @returns true if we have to display it, false otherwise
     */
    const isFieldDisplayed = (field: RequireCustomerInfoFields): boolean => {

        // For marketing or terms of service, 2 fields can be true or false so we
        // have to make a separate case
        if (field === "accept_terms") {
            return !hasAcceptedTerms;
        }

        // For the other fields, just check if the settings for the current location &
        // service_type have the field name set to true
        return Boolean(
            selectedLocation?.require_customer_info?.[serviceTypeForRequiredInfo]?.[field as keyof CustomerInfo]
            && missingFields?.includes(field)
        );

    }

    /**
     * The first part are the fields which CAN be rendered in the
     * form. They are optional as we don't know yet which fields will
     * be asked.
     * 
     * The second part is tricky: these are not displayed fields: they are
     * simple booleans indicating whether or not a field is displayed.
     * We have to put them in this interface as form values in order to validate
     * properly using yup (see below)
     */
    interface PickupInfoFormFields {

        // Fields which can be displayed
        first_name?: string;
        company_name?: string;
        email?: string;
        phone?: string;
        phone_country?: string;
        marketing?: boolean;
        accept_terms?: boolean;

        // Whether or not to display the above fields
        isFirstNameDisplayed?: boolean;
        isCompanyNameDisplayed?: boolean;
        isEmailDisplayed?: boolean;
        isPhoneDisplayed?: boolean;
        isAcceptTermsNeeded?: boolean;
    }

    /**
     * This function is called when all the fields are filled and valid, and
     * the user clicks on "confirm".
     * WARNING: We already know that the values are valid and filled if required thanks
     * to the yup validation. We don't have to do any validation here, except for the API
     * fetch (last check to see if the timeslot is still available)
     * @param values
     * @param formik
     */
    const onSubmit = async (values: PickupInfoFormFields, formik: FormikHelpers<PickupInfoFormFields>) => {

        if (!selectedCatalog || !selectedLocation || !selectedTable) {
            log.error("ServicePickupInfo: onSubmit: missing selectedCatalog or selectedLocation or selectedTable");
            return;
        }

        if (props.forceLoadOnValidation) {
            dispatch(orderActions.forceSendToApiLoader())
        }

        // We set the customer info to the one from redux state, and we
        // will then fill it with the new info
        const customerInfo: Customer = order.customer ?? user ?? {};

        // Fill the customer info with the values taken from the Formik form
        if (isFieldDisplayed("first_name")) {
            customerInfo.first_name = values.first_name;
        }
        if (isFieldDisplayed("company_name")) {
            customerInfo.company_name = values.company_name;
        }
        if (isFieldDisplayed("email")) {
            customerInfo.email = values.email;
        }
        if (isFieldDisplayed("phone")) {
            customerInfo.phone = values.phone;
        }
        if (isFieldDisplayed("accept_terms")) {
            dispatch(AuthenticationActions.acceptTerms(isUserLoggedIn(user_authentication_state)));
        }

        dispatch(orderActions.setInfoPickUp(customerInfo));

        // Go to the next page
        choosePaymentModeOrSendPayOrder(
            order,
            selectedLocation,
            user?.uid ?? "",
            selectedTable.restrictions,
            dispatch,
            history,
            tableLinkId,
            fromApp,
            localLoyaltyUsePoints,
            isTherePaymentData,
        );
    }

    /**
     * The validation schema of the form. These conditions tell whether or not
     * a field is valid, or required. This is where the 2nd part of the interface
     * is useful: we set fields to mandatory and check for validity only if they are diplayed.
     * The error messages have to be intl message IDs
     */
    const validationSchema: yup.SchemaOf<PickupInfoFormFields> = yup.object({

        first_name: yup.string()
            .when("isFirstNameDisplayed", {
                is: true,
                then: yup.string()
                    .required("orders.servicePickupInfo.name.required"),
            }),
        company_name: yup.string()
            .when("isCompanyNameDisplayed", {
                is: true,
                then: yup.string()
            }),

        email: yup.string()
            .when("isEmailDisplayed", {
                is: true,
                then: yup.string()
                    .required("orders.servicePickupInfo.email.required")
                    .email("orders.servicePickupInfo.email.format"),
            }),

        phone: yup.string()
            .when("isPhoneDisplayed", {
                is: true,
                then: yup.string()
                    .required("orders.servicePickupInfo.phone.required")
                    .test("Test phone number format", "orders.servicePickupInfo.phone.format", (value, context) => {
                        const country = (context.parent.phone_country as string | undefined) ?? selectedLocation?.country ?? DEFAULT_COUNTRY;
                        return Boolean(value && isValidPhoneNumber(value, country.toUpperCase() as CountryCode));
                    }),
            }),

        phone_country: yup.string().optional(),

        // Marketing is always optional, shown or not
        marketing: yup.boolean().optional(),

        accept_terms: yup.boolean()
            .when("isAcceptTermsNeeded", {
                is: true,
                then: yup.boolean()
                    .required()  // Terms are always required if displayed
                    .isTrue("orders.servicePickupInfo.accept_terms.required"),
            }),

        // The validation fields (not shown)
        isFirstNameDisplayed: yup.boolean().optional(),
        isCompanyNameDisplayed: yup.boolean().optional(),
        isEmailDisplayed: yup.boolean().optional(),
        isPhoneDisplayed: yup.boolean().optional(),
        isAcceptTermsNeeded: yup.boolean().optional(),
    });

    /**
     * The initial values for the form.
     * We get the date&time initial values from TakeoutDateTimeForm and
     * then add the others.
     * WARNING: setting a string to "" equals setting it to undefined
     */
    const initialValues: PickupInfoFormFields = {

        first_name: user && user.first_name ? user.first_name : "",
        company_name: user?.company_name ?? "",
        email: user?.email ?? "",
        phone: user?.phone ?? "",
        marketing: false,
        accept_terms: false,

        // Very important: set the display conditions
        isFirstNameDisplayed: isFieldDisplayed("first_name"),
        isCompanyNameDisplayed: isFieldDisplayed("company_name"),
        isEmailDisplayed: isFieldDisplayed("email"),
        isPhoneDisplayed: isFieldDisplayed("phone"),
        isAcceptTermsNeeded: isFieldDisplayed("accept_terms"),
    }

    if (!selectedLocation || !selectedCatalog || !orderState || !missingFields) {
        return (
            <LoaderComponent />
        );
    }

    return (

        <Box height={1}>

            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >

                {(formik) => {

                    return (

                        <Form
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        >

                            <Box
                                height={1}
                                justifyContent="center"
                                overflow="auto"
                            >

                                <Box height={1} display="flex" flexDirection="column" alignItems="stretch">

                                    <Box>
                                        <OrderHeader
                                            titleId={
                                                intl.formatMessage({
                                                    id: (serviceTypeForRequiredInfo === SupportedServiceType.EAT_IN && isFieldDisplayed("email")) ?
                                                        "Summary.customer_info.eat_in_title"
                                                        : "Summary.last_step"
                                                })}
                                            closeIcon={!onClearIconClick}
                                            onClearIconClick={onClearIconClick}
                                            hideTable
                                            readOnly
                                        />
                                    </Box>

                                    <Box
                                        style={{ padding: theme.spacing(2, 3) }}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        flex={1}
                                    >

                                        <Box
                                            width={1}
                                            flex={1}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                        >

                                            { // "Please fill in the following fields" message
                                                serviceTypeForRequiredInfo !== SupportedServiceType.EAT_IN &&
                                                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                                                    {intl.formatMessage({ id: "orders.servicePickupInfo.confirmMessage" })}
                                                </Typography>
                                            }

                                            {
                                                /////////////////
                                                // First name
                                                /////////////////
                                            }
                                            {isFieldDisplayed("first_name") &&
                                                <Box
                                                    width={1}
                                                    marginY={1}
                                                >
                                                    <FormikTextInput
                                                        inputProps={{
                                                            "data-test": "service-pickup-info.first_name",
                                                        }}
                                                        name="first_name"
                                                        label={intl.formatMessage({ id: "orders.servicePickupInfo.name.placeholder" })}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            }

                                            {
                                                /////////////////
                                                // Company name
                                                /////////////////
                                            }

                                            {
                                                isFieldDisplayed("company_name") &&
                                                // serviceTypeForRequiredInfo === SupportedServiceType.DELIVERY &&
                                                <Box
                                                    width={1}
                                                    marginY={1}
                                                >
                                                    <FormikTextInput
                                                        inputProps={{
                                                            "data-test": "service-pickup-info.company_name",
                                                        }}
                                                        name="company_name"
                                                        label={intl.formatMessage({ id: "orders.servicePickupInfo.company_name.placeholder" })}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            }

                                            {
                                                /////////////////
                                                // Email
                                                /////////////////
                                            }
                                            {
                                                (
                                                    isFieldDisplayed("email")
                                                    || (
                                                        serviceTypeForRequiredInfo === SupportedServiceType.EAT_IN
                                                        && isUserLoggedIn(data.user_authentication_state)
                                                    )
                                                ) &&

                                                <Box
                                                    width={1}
                                                    marginY={1}
                                                >
                                                    <FormikTextInput
                                                        inputProps={{
                                                            "data-test": "service-pickup-info.email",
                                                        }}
                                                        name="email"
                                                        label={intl.formatMessage({ id: "orders.servicePickupInfo.email.placeholder" })}
                                                        variant="outlined"
                                                        type="email"
                                                        disabled={
                                                            // The email input is disabled if the user is already logged in, and
                                                            // if its email exists and is valid. It prevents them to type
                                                            // a wrong email address.
                                                            isUserLoggedIn(data.user_authentication_state)
                                                            && user !== null
                                                            && user.email !== undefined
                                                            && user.email !== null
                                                            && checkEmailRegex(user.email)
                                                        }
                                                    />
                                                </Box>
                                            }

                                            {
                                                /////////////////
                                                // Phone
                                                /////////////////
                                            }
                                            {isFieldDisplayed("phone") &&
                                                <Box
                                                    width={1}
                                                    marginY={1}
                                                >
                                                    <FormikPhoneInput
                                                        inputProps={{
                                                            "data-test": "service-pickup-info.phone",
                                                        }}
                                                        phoneFieldName="phone"
                                                        countryFieldName="phone_country"
                                                        initialCountry={selectedLocation.country ?? DEFAULT_COUNTRY}
                                                        preferredCountries={selectedLocation.customer_phone_countries ?? getSameSubRegionCountryCodes(selectedLocation.country ?? DEFAULT_COUNTRY)}
                                                        placeholder={intl.formatMessage({ id: "orders.servicePickupInfo.phone.placeholder" })}
                                                    />
                                                </Box>
                                            }

                                            {
                                                /////////////////
                                                // Checkboxes
                                                /////////////////
                                            }
                                            {isFieldDisplayed("accept_terms") &&
                                                <Box
                                                    width={1}
                                                    marginTop={isDisplayedInDialog ? 3 : 4}
                                                >
                                                    {isFieldDisplayed("accept_terms") &&

                                                        <Box marginTop={1}>
                                                            <FormikCheckBox
                                                                name="accept_terms"
                                                                formikSetFieldValue={formik.setFieldValue}
                                                                textComponent={<TermsAndConditionsTextWithModal />}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            {
                                //////////////////
                                // Final button
                                //////////////////
                            }

                            <Box
                                position="sticky"
                                bottom="0px"
                                display="flex"
                                alignContent="center"
                                alignItems="center"
                                justifyItems="center"
                                justifyContent="center"
                                style={{ padding: theme.spacing(4, 3) }}
                            >

                                <Button
                                    color="primary"
                                    variant="contained"
                                    data-test="order_info_confirm"
                                    type="submit"
                                    style={{
                                        textTransform: "none",
                                        position: 'sticky',
                                        width: '100%',
                                        bottom: theme.spacing(3),
                                        left: theme.spacing(2),
                                        right: theme.spacing(2),
                                        padding: theme.spacing(1.5, 2)
                                    }}
                                >
                                    <Typography variant='h5'>
                                        {intl.formatMessage({ id: "Payment.confirm" })}
                                    </Typography>

                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
}

export default ServicePickUpInfo;

