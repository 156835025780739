import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Rating, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { Review } from '../../my-lemonade-library/src/reviews/models/Review';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { reviewActions } from '../redux/ReviewActions';

interface ReviewRatingDialogProps {
    open: boolean,
    onClose: () => void,
    ratingValue: number,
}


const ReviewRatingDialog: React.FC<ReviewRatingDialogProps> = (props) => {

    const { open, onClose, ratingValue } = props;

    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { user_authentication_state } = useTypedSelector((state: RootState) => state.authentication.data);

    const [isTextfieldFocused, setIsTextfieldFocused] = useState(false);
    const [newRatingValue, setNewRatingValue] = useState<number>(ratingValue);
    const [textReview, setTextReview] = useState<string>("");

    const handleRatingChange = (event: React.ChangeEvent<{}>, newValue: number | null) => {
        if (newValue) {
            setNewRatingValue(newValue);
        }
    }

    const handleSubmitRating = () => {
        if (user_authentication_state
            && user_authentication_state.user
            && user_authentication_state.user.uid
            && selectedLocation
        ) {
            const review: Review = {
                user_id: user_authentication_state.user.uid,
                location_id: selectedLocation.id,
                account_id: selectedLocation.account_id,
                created_at: new Date(),
                last: {
                    rating: newRatingValue,
                    created_at: new Date(),
                    text_review: textReview || undefined
                }
            }
            dispatch(reviewActions.createLocationReview(review))
            onClose();
        }
    }

    return (
        <Dialog
            fullWidth
            PaperProps={{
                sx: {
                    paddingX: 2,
                    paddingY: 4,
                    borderRadius: 5,
                    margin: 2,
                }
            }}
            open={open}
            onClose={onClose}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.dark
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                data-test="order-rating-dialog"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    data-test="order-rating-stars-box"
                >
                    <Rating
                        data-test="order-rating-stars"
                        size="large"
                        style={{
                            color: theme.palette.primary.main,
                        }}
                        value={newRatingValue}
                        onChange={handleRatingChange}
                    />
                </Box>
                <Box>
                    <Typography
                        variant="subtitle1"
                        align="center"
                    >
                        {intl.formatMessage({ id: "order.rating.title.modal" })}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                            color: theme.palette.grey[500]
                        }}
                    >
                        {intl.formatMessage({ id: "order.rating.comment" })}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 1,
                    }}
                    data-test="order-rating-comment-box"
                >
                    <TextField
                        label={
                            intl.formatMessage({ id: "order.rating.title" })
                        }
                        id="order-rating-comment"
                        rows={4}
                        multiline
                        variant="outlined"
                        fullWidth
                        style={{
                            minHeight: 100,
                        }}
                        placeholder={
                            intl.formatMessage({ id: "order.rating.comment.placeholder" })
                        }
                        onClick={() => setIsTextfieldFocused(true)}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: isTextfieldFocused ? theme.palette.primary.main : theme.palette.grey[500],
                            }
                        }}
                        onBlur={() => setIsTextfieldFocused(false)}
                        onChange={(e) => setTextReview(e.target.value)}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            variant="contained"
                            data-test="send-order-rating-button"
                            color="primary"
                            sx={{
                                mt: 3,
                                paddingX: 6,
                            }}
                            type="submit"
                            onClick={handleSubmitRating}
                        >
                            {intl.formatMessage({ id: "send" })}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default ReviewRatingDialog;