
import getSymbolFromCurrency from 'currency-symbol-map'
import { log } from "./LogService";

/**
 * ! DEPRECATED !
 * Use MoneyToStringWithSymbol in Money.ts instead.
 */
export const getPriceWithSymbol = (price: string): string => {
    let currency: string = price.slice(price.length - 3);
    let currencySymbol = getSymbolFromCurrency(currency);
    if (!currencySymbol) {
        log.error(`Cannot extract valid currency from price ${price} (${currency})`);
        return price;
    } else {
        const replacedPrice = price.replace(currency, currencySymbol);
        return replacedPrice;
    }
}