import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as Corn } from '../assets/LYF.svg';


const LyfIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} component={Corn} viewBox="0 0 950.51 627.77" />
    );
};

export default LyfIcon;