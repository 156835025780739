import { Box, Link, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { cursor } from "../../Common/StyledComponents";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { isUserLoggedIn } from "../helpers/AuthenticationHelpers";
import AuthenticationFormParameters from "../models/AuthenticationFormParameters";
import AuthenticationActions from "../redux/AuthenticationActions";

export enum AuthenticationBottomMessage {
    CREATE_ACCOUNT_AND_GET_OFFERS = "create_account_and_get_offers",
    ALREADY_CUSTOMER = "already_customer",
    NO_ACCOUNT_SIGN_UP = "no_account_sign_up",
    NOT_YOU_LOGOUT = "not_you_logout",
}

interface AuthenticationBottomMessagesProps {
    messagesToDisplay: AuthenticationBottomMessage[],
    customMargin?: number,
}

export const handleLogout = (dispatch: Dispatch, closeCurrentModal: boolean) => {
    // Reset the has_chosen_to_be_anonymous state
    dispatch(AuthenticationActions.resetGuestState());

    dispatch(AuthenticationActions.signoutUser(true));

    if (closeCurrentModal) {
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(null));
    }
}

export const handleSignIn = (dispatch: Dispatch) => {

    // Reset the has_chosen_to_be_anonymous state
    dispatch(AuthenticationActions.resetGuestState());

    dispatch(CustomerInformationModalActions.setCustomerInformationModal(
        CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
    ));
}

const AuthenticationBottomMessages: React.FC<AuthenticationBottomMessagesProps> = (props) => {

    const { data } = useTypedSelector((state: RootState) => state.authentication)
    const { formToDisplay, formParameters } = useTypedSelector((state: RootState) => state.customerInformationModal)
    const { messagesToDisplay, customMargin } = props;

    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleSignUp = () => {

        const authenticationFormParameters: AuthenticationFormParameters = {
            previousForm: formToDisplay ?? undefined,
            previousFormParameters: formParameters,
        }

        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            CustomerInformationModalFormToDisplay.SIGNUP_PASSWORD,
            authenticationFormParameters
        ));
    }

    const getAuthenticationLink = (
        intlMessageId: string,
        onClick: () => void,
        dataTest: string
    ) => {

        return (

            <Link
                data-test={dataTest}
                style={{
                    color: theme.palette.text.disabled,
                    textDecoration: 'underline',
                    ...cursor,
                }}
                onClick={onClick}
                key={0}
            >
                {intl.formatMessage({ id: intlMessageId })}
            </Link>
        );
    }

    return (

        <Box
            marginTop={customMargin ?? 3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            color={theme.palette.text.disabled}
            textAlign="center"
        >

            { // Do not show the login message if the user is already logged in 
                !isUserLoggedIn(data.user_authentication_state) &&

                <>

                    {messagesToDisplay.includes(AuthenticationBottomMessage.CREATE_ACCOUNT_AND_GET_OFFERS) &&

                        <Typography variant="caption">

                            {intl.formatMessage({
                                id: "authentication.authentication_form.welcomAuthentication.signup_invitation",
                            }, {
                                a: getAuthenticationLink(
                                    "authentication.authentication_form.welcomAuthentication.signup",
                                    () => handleSignUp(),
                                    "signin.link_to_signup",
                                ),
                            })}

                        </Typography>

                    }

                    {messagesToDisplay.includes(AuthenticationBottomMessage.ALREADY_CUSTOMER) &&
                        <Typography>
                            {intl.formatMessage({
                                id: "homeForm.signin.question1",
                            }, {
                                a: getAuthenticationLink(
                                    "homeForm.signin.question2",
                                    () => handleSignIn(dispatch),
                                    "signin.link_to_signin",
                                ),
                            })}
                        </Typography>
                    }

                    {
                        messagesToDisplay.includes(AuthenticationBottomMessage.NO_ACCOUNT_SIGN_UP)
                        &&

                        <Typography>
                            {intl.formatMessage({
                                id: "auth.no_account.question1",
                            }, {
                                a: getAuthenticationLink(
                                    "auth.no_account.question2",
                                    () => handleSignUp(),
                                    "signin.link_to_signup",
                                ),
                            })}
                        </Typography>
                    }
                </>
            }

            {messagesToDisplay.includes(AuthenticationBottomMessage.NOT_YOU_LOGOUT) &&
                <Typography>
                    {intl.formatMessage({
                        id: "auth.no_account.question3",
                    }, {
                        a: getAuthenticationLink("auth.headerMenu.logoutTitle",
                            () => handleLogout(dispatch, true),
                            "authentication.link_to_signout"),
                    })}
                </Typography>
            }
        </Box>
    );
}

export default AuthenticationBottomMessages;