import { Product, Sku } from "../../../model/Catalog";

export interface Suggestion {

    ref: string;

    name: string;

    // Items that will be suggested
    skus: SuggestionSku[];

    disable?: boolean;

    suggestion_mode: SuggestionMode;

    // Categories and Skus triggering the suggestion
    tiggered_by?: SuggestionTriggers;

}

export interface SuggestionSku {
    /**
     * Sku ref
     */
    ref: string;

    product_ref: string;
}

export interface SuggestionSkuExtended extends SuggestionSku {
    product?: Product;
    sku?: Sku;
}

export enum SuggestionMode {
    // Suggestion will only display the items not in the order
    ALWAYS_SHOW_NOT_IN_ORDER = "always_show_not_in_order",

    // Suggestion will not open if one od the item is in the order
    ONLY_SHOW_NONE_IN_ORDER = "only_show_none_in_order",

    // Will always show
    ALWAYS_SHOW = 'always_show'
}

export interface SuggestionTriggers {
    category_refs: string[];

    skus: SuggestionSku[];
}

