import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import FabButtonOrder from '../../Common/components/FabButtonOrder';
import * as ROUTES from '../../config/routes';
import Deals from '../../deals/pages/Deals';
import DisplayProductsScroll from '../../Products/component/DisplayProductsScroll';
import BestSeller from '../../Products/pages/BestSeller';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import CatalogsGridComponent from '../components/CatalogsGridComponent';
import { isDisplayedAsGrid } from '../services/categoriesServices';

/**
 * Catalog home page on smartphone with root categories
 */
const CategoriesPage = () => {

    const params = useParams<ROUTES.RouteParams>();
    const { selectedCatalog } = useTypedSelector((state: RootState) => state.locations)
    const intl = useIntl();

    return (

        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
            height="100%"
        >

            <Box justifyContent="flex-start" height="100%">



                {selectedCatalog && isDisplayedAsGrid(selectedCatalog)
                    ? (
                        <Box>
                            <Deals />
                            <BestSeller />
                            <Box
                                fontWeight={'bold'}
                                ml={4}
                                mt={2}
                                mb={1}
                                style={{
                                    overflow: "auto"
                                }}
                            >
                                <Typography variant="h5" color="textSecondary">
                                    {intl.formatMessage({ id: "CategoriesPage.categories" })}
                                </Typography>

                            </Box>
                            <CatalogsGridComponent parentCategoryRef={params.categoryRef} />
                        </Box>
                    )
                    : <DisplayProductsScroll />
                }

                <Box p={1} position="sticky" bottom="0px">
                    <FabButtonOrder />
                </Box>

            </Box>


        </Box>
    )
}

export default CategoriesPage;
