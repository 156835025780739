import { Box, Button, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';

const UnavailableButton: React.FC = () => {

    const theme = useTheme()
    const intl = useIntl();

    return (
        <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80%"
            width="100%"
            zIndex={100}
        >
            <Button
                disabled
                variant="contained"
                // style={{ backgroundColor: theme.palette.secondary.main }}
                data-test="unavailable_button"
            >
                <Typography
                    style={{ color: theme.palette.secondary.contrastText }}
                >
                    {intl.formatMessage({ id: "product.disable" })}
                </Typography>
            </Button>
        </Box>
    )
}

export default UnavailableButton;