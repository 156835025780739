import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point, polygon, Position as TurfPosition } from '@turf/helpers';
import log from "loglevel";
import { getIdToken } from '../../authentication/services/AuthenticationService';
import { getApiEndpoint } from '../../config/variables';
import { Location, Table } from '../../my-lemonade-library/model/Location';
import { ACCOUNT_PARAM } from '../../my-lemonade-library/src/accounts/configs/AccountsApiRoutes';
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import Address from '../../my-lemonade-library/src/common/models/Address';
import { moneyToNumber } from "../../my-lemonade-library/src/common/models/Money";
import { extractDateFromTimestampOrString } from '../../my-lemonade-library/src/common/services/DateHelper';
import { getFullAddressFromAddress } from '../../my-lemonade-library/src/customers/services/CustomerHelper';
import { DELIVERY_LOCATION_CHECK_AVAILABLE } from '../../my-lemonade-library/src/delivery/configs/DeliveryApiRoutes';
import CheckDeliveryAvailableRequest from '../../my-lemonade-library/src/delivery/models/CheckDeliveryAvailableRequest';
import CheckDeliveryAvailableResponse from '../../my-lemonade-library/src/delivery/models/CheckDeliveryAvailableResponse';
import DeliveryUnavailableReason from '../../my-lemonade-library/src/delivery/models/DeliveryUnavailableReason';
import DeliveryZone from "../../my-lemonade-library/src/delivery/models/DeliveryZone";
import deliveryHelper from '../../my-lemonade-library/src/delivery/services/DeliveryHelper';
import { LOCATION_PARAM } from '../../my-lemonade-library/src/locations/configs/LocationsApiRoutes';
import { OrderState } from '../../orders/redux/models/OrderState';
import CheckedDeliveryZone from '../models/CheckedDeliveryZone';

/**
 * Check if a point is in the zones and return the best zone (by price). If the point is not in any zone or if
 * there are no zones, return null
 * @param coordinates a Position object: [longitude, latitude]
 * @param zones
 * @param locationId
 * @returns
 */
export const checkPointAndGetZone = (coordinates: TurfPosition | undefined, zones: DeliveryZone[] | undefined, locationId: string): DeliveryZone | null => {

    if (coordinates && coordinates.length === 2) {

        if (!zones || zones.length === 0) {
            log.error("No delivery zone for the location (id " + locationId + ")");
        }

        /**
         * If it stays null, that means that the address is out of the deliver zones
         * or that there is no delivery zone
         */
        let zoneFound: DeliveryZone | null = null;
        let minPriceFound: number = Infinity;

        if (zones) {

            /**
             * For each zone, we start by checking if it's enabled. Then we check if we're in.
             * At the end of the loop, we check if the zonFound variable has changed or if it's still null. 
             */
            for (const zone of zones) {

                if (zone.geometryObject) {

                    if (!zone.disable) {

                        const turfPoint = point(coordinates);
                        const turfPolygon = polygon(zone.geometryObject.coordinates);

                        if (booleanPointInPolygon(turfPoint, turfPolygon)) {

                            if (moneyToNumber(zone.default_delivery_fee, false, zone) < minPriceFound) {

                                zoneFound = zone;
                                minPriceFound = moneyToNumber(zoneFound.default_delivery_fee, false, zoneFound);
                            }
                        }
                    }
                    else {

                        log.debug("The zone " + zone.name + " is disabled. Skipped");
                    }
                }
                else {

                    log.debug("The zone " + zone.name + " does not have a geometry object. Skipped");
                }
            }

            return zoneFound;
        }
    }

    return null;
}

/**
 * Check if the order has a delivery address set
 * @param order 
 * @returns 
 */
export const isAddressSet = (orderCustomer: Customer | undefined): boolean => {

    // We check the country because we're 100% sure that this field
    // is filled when the address is set
    if (orderCustomer && orderCustomer.country) {

        return true;
    }

    return false;
}

/**
 * Get the delivery delay associated with the order in state.
 * If no delivery delay, the result will be 0.
 */
export const getDeliveryDelay = (location: Location | undefined, order: OrderState): number => {

    if (location?.delivery) {

        const delay = deliveryHelper.getDeliveryDelayFromOrder(
            order.order, location?.delivery, true
        );

        if (delay) {

            return delay;
        }
    }

    return 0;
}

/**
 * Get the delivery zone associated with an address given in props (lat, long and text address).
 * It is async as it will also fetch the API to get an answer: can we make a delivery?
 * If the delivery is impossible, the function returns null.
 * @param coordinates 
 * @param fullAddress 
 * @param selectedLocation 
 * @returns 
 */
export const checkDeliveryAvailableAPI = async (
    coordinates: TurfPosition,
    address: Address | undefined,
    selectedLocation: Location,
    selectedTable: Table,
    endPreparationTime?: Date
): Promise<{ checked_delivery_zone: CheckedDeliveryZone | null; unavailable_reason?: DeliveryUnavailableReason }> => {

    const zoneFound = checkPointAndGetZone(coordinates, selectedLocation.delivery, selectedLocation.id);

    if (zoneFound) {

        const apiDeliveryCheckUrl =
            `${getApiEndpoint()}${DELIVERY_LOCATION_CHECK_AVAILABLE}`
                .replace(`${ACCOUNT_PARAM}`, selectedLocation.account_id)
                .replace(`${LOCATION_PARAM}`, selectedLocation.id);

        const token = await getIdToken();

        // Calling the API with the address and zoneRef fields.
        // We don't give an expected time as we're not sure to have it at this point.
        const body: CheckDeliveryAvailableRequest = {
            address,
            full_address: address ? getFullAddressFromAddress(address) : undefined,
            zone_ref: zoneFound.ref,
            table_id: selectedTable.id,
        }
        if (endPreparationTime) {
            body.end_preparation_time = endPreparationTime
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${token?.token}`);

        const request = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }

        try {

            const response = await fetch(apiDeliveryCheckUrl, request).then(res => { return res })
            const result = await response.json() as CheckDeliveryAvailableResponse;

            if (response.ok) {
                if (result.available) {
                    const deliveryZone: CheckedDeliveryZone = {
                        name: result.provider_type,
                        ref: zoneFound.ref,
                        default_delivery_fee: result.price,
                        price: result.price,
                        provider_type: result.provider_type,
                        distance: result.distance,
                        disable: false,
                        default_delivery_delay: zoneFound.default_delivery_delay,
                        geometry: zoneFound.geometry,
                        estimated_delivery_delay: result.estimated_delivery_delay,
                        estimated_expected_time: result.estimated_expected_time ? (extractDateFromTimestampOrString(result.estimated_expected_time) ?? undefined) : undefined,
                    };

                    return { checked_delivery_zone: deliveryZone };
                } else {
                    return { checked_delivery_zone: null, unavailable_reason: result.unavailable_reason ?? DeliveryUnavailableReason.AREA_NOT_COVERED };
                }
            }
        }
        catch (error) {

            log.error(error);
            return { checked_delivery_zone: null, unavailable_reason: DeliveryUnavailableReason.DELIVERY_SERVICE_ERROR };
        }
    }

    return { checked_delivery_zone: null, unavailable_reason: DeliveryUnavailableReason.AREA_NOT_COVERED };;
}