import { Box, Button, Tab, Tabs, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import log from "../../Common/services/LogService";
import LocationLogo from "../../Locations/components/LocationLogo";
import { getCategoriesFullRoute } from "../../config/routes";
import DeliveryFullForm from "../../delivery/components/DeliveryFullForm";
import CheckedDeliveryZone from "../../delivery/models/CheckedDeliveryZone";
import { setCustomerAndChargesFromAddress } from "../../delivery/services/DeliveryServices";
import { DEFAULT_TIMEZONE_NAME } from "../../my-lemonade-library/model/Catalog";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import Address from "../../my-lemonade-library/src/common/models/Address";
import { DeliveryAddressTypeChoice } from "../../my-lemonade-library/src/delivery/models/DeliveryAddressTypeChoice";
import deliveryHelper from "../../my-lemonade-library/src/delivery/services/DeliveryHelper";
import { isMatchingTemporalRestrictionWithReason, sortServiceTypesAccordingToReferenceArray } from "../../my-lemonade-library/src/restrictions/services/RestrictionsService";
import { getTableServiceTypes } from "../../my-lemonade-library/src/tables/services/TableService";
import themeHelper from "../../my-lemonade-library/src/theme/services/ThemeHelper";
import orderActions from "../../orders/redux/OrderActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import tableActions from "../../tables/redux/TableActions";
import { OrderExpectation } from "../models/OrderExpectation";
import CollectionFullForm from "./CollectionFullForm";

interface ServiceTypeOrTakeoutFormProps {
    setFullScreen: (param: boolean) => void;
    isGetRestoTheme?: boolean
}

/**
 * This form shows tabs to select the service_type, and/or delivery/collection form(s)
 */
const ServiceTypeOrTakeoutForm: React.FC<ServiceTypeOrTakeoutFormProps> = (props) => {

    /**
     * We keep the functions out of this spread operator for a better understanding:
     * all the upper component functions have to be called using props.<<function>>
     */
    const { setFullScreen, isGetRestoTheme = false } = props;

    const { tableLinkId, selectedTable, selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state: RootState) => state.order);

    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();


    /**
     * This function dispatches the information given by the form and closes the modal
     * (forcing it to not open again because we know it's complete and the dispatch takes time)
     * WARNING: this function does not check if the form is fully completed. This job
     * is ensured inside the ServiceTypeOrTakeoutForm component.
     * NOTE: all fields are optional. Indeed, 
     * @param serviceType 
     */
    const onSubmitAndClose = async (
        serviceType: SupportedServiceType | undefined,
        address: Address | undefined,
        chosenDeliveryAddressType: DeliveryAddressTypeChoice | undefined,
        deliveryZone: CheckedDeliveryZone | undefined,
        expectedTime: Date | undefined,
        deliveryAsap: boolean,
        delivery_note: string | undefined
    ) => {

        if (serviceType) {
            if (selectedCatalog) {
                dispatch(tableActions.applySelectServiceType(selectedCatalog, serviceType, selectedTable.area_ref));
            }
            else {
                log.error("Could not apply the new service_type from the CustomerInformationModal: catalog is missing");
            }
        }

        if (address || deliveryZone) {
            if (selectedCatalog && selectedLocation && address && deliveryZone) {
                // TODO: group actions
                setCustomerAndChargesFromAddress(
                    address,
                    deliveryZone,
                    intl,
                    dispatch,
                    order,
                    selectedCatalog,
                    selectedLocation,
                    selectedTable,
                    delivery_note,
                    chosenDeliveryAddressType,
                );
            }
            else {
                log.error("Could not apply address to the order: address , deliveryZone, location or catalog is/are missing");
            }
        }

        if (expectedTime || deliveryAsap) {
            log.debug(`Set expected time to ${expectedTime?.toISOString()}, asap ${deliveryAsap}`)
            let endPreparationTime: Date | undefined = undefined;
            if (expectedTime) {
                endPreparationTime = new Date(expectedTime);
                if (serviceType === SupportedServiceType.DELIVERY) {
                    endPreparationTime = DateTime.fromJSDate(expectedTime).minus({ minutes: deliveryZone?.default_delivery_delay ?? 0 }).toJSDate();
                }
            }
            dispatch(orderActions.setExpectedTime(expectedTime, deliveryAsap, endPreparationTime));
        }

        // Redirect to categories
        history.push(getCategoriesFullRoute(tableLinkId));

        // Do not close the modal, it will be automatically closed if the order is complete
    }

    /////////////
    // DELIVERY
    const [showAddressTextField, setShowAddressTextField] = useState<DeliveryAddressTypeChoice>();

    /////////////
    // DELIVERY & COLLECTION
    const [showTimeSelectScreen, setShowTimeSelectScreen] = useState<boolean>(false);
    const [orderExpectation, setOrderExpectation] = useState<OrderExpectation>({})
    const [formComplete, setFormComplete] = useState<boolean>(false);


    useEffect(() => {
        const isFormCompleteResult = isFormComplete();
        setFormComplete(isFormCompleteResult)
    }, [orderExpectation])

    /////////////
    // OTHER
    /////////////

    // The current service_type tab displayed

    // The available service types for this table. Can be only one item in the array
    const availableServiceTypes: SupportedServiceType[] | undefined = getTableServiceTypes(selectedTable);

    const sortedServiceTypes = sortServiceTypesAccordingToReferenceArray(availableServiceTypes ?? [], selectedLocation?.supported_service_types ?? []);

    /**
     * This useEffect sets the expected time (dateTimeExpected) after
     * loading the order, only if there is already one defined
     */
    useEffect(() => {
        if (order.expected_time && !orderExpectation.dateTimeSelected) {
            setOrderExpectation({
                ...orderExpectation,
                dateTimeSelected: order.expected_time
            })
        }
    }, [order, orderExpectation]);

    /**
     * When rendering the component or when there is a change on the table
     * service_type(s), set the current tab position to the first element
     * of the array
     */
    useEffect(() => {
        if (availableServiceTypes && availableServiceTypes.length > 0 && !orderExpectation.service_type) {
            setOrderExpectation({
                ...orderExpectation,
                service_type: availableServiceTypes[0]
            })
        }
    }, [selectedTable?.service_type, selectedTable?.restrictions?.service_types, orderExpectation.service_type]);


    const delivery_delay_restriction = useMemo(() => {
        if (orderExpectation.service_type !== SupportedServiceType.DELIVERY) return;

        if (!selectedLocation || !selectedLocation.restriction) return;

        const now = moment()
        const timezone = selectedLocation.timezone?.name || DEFAULT_TIMEZONE_NAME;

        return selectedLocation.restriction.find((restriction) => {
            if (!restriction.delivery_after_time) {
                return false
            }

            const { isMatching } = isMatchingTemporalRestrictionWithReason(now, timezone, { end_time: restriction.end_time, start_time: restriction.start_time, service_types: restriction.service_types }, SupportedServiceType.DELIVERY)

            if (!isMatching) {
                return false
            }

            return true
        });
    }, [orderExpectation.service_type, selectedLocation])

    /**
     * Change the tab when click on it
     * @param event 
     * @param newValue 
     */
    const handleTabClick = (event: ChangeEvent<{}>, newValue: SupportedServiceType) => {
        setOrderExpectation({
            service_type: newValue
        })
    }

    /**
     * Render all the "simple" tabs, meaning everything but delivery and collection.
     * Those tabs will be really simple: a button to chose the service_type
     */
    const renderSimpleTabs = () => {
        const finalJSXArray: JSX.Element[] = [];
        if (availableServiceTypes) {
            for (const serviceType of availableServiceTypes) {
                // Only the other service_types
                if (
                    serviceType !== SupportedServiceType.DELIVERY
                    && serviceType !== SupportedServiceType.COLLECTION
                ) {
                    finalJSXArray.push(
                        <>
                            {orderExpectation.service_type === serviceType &&
                                <Box my={2}></Box>
                            }
                        </>
                    );
                }
            }
        }
        return finalJSXArray;
    }

    /**
     * Check if the form is complete using the local state variables.
     * Do not check in the redux state yet.
     * @returns 
     */
    const isFormComplete = (): boolean => {

        log.debug(`Checking form for service type ${orderExpectation.service_type}, address ${orderExpectation.address?.address_1}, delivery zone ${orderExpectation.delivery_zone?.ref}, expected time ${orderExpectation.dateTimeSelected?.toISOString()}, asap ${orderExpectation.expected_asap}`);

        switch (orderExpectation.service_type) {

            case SupportedServiceType.DELIVERY: {
                const hasAddress = Boolean(orderExpectation.address && orderExpectation.delivery_zone);

                if (delivery_delay_restriction) {
                    return hasAddress
                }

                return Boolean(
                    hasAddress
                    && orderExpectation.dateTimeSelected
                    && !_.isNil(orderExpectation.expected_asap)
                );
            }

            case SupportedServiceType.COLLECTION:
                return Boolean(
                    orderExpectation.dateTimeSelected
                    && !_.isNil(orderExpectation.expected_asap)
                );

            // If not delivery nor collection, no information is required, so the form is complete
            default:
                return true;
        }
    }

    /**
     * When the user clicks on the "confirm" button
     */
    const handleConfirmClick = () => {

        if (isFormComplete()) {

            //TODO : Overkill => check validity before dispatch in submitAndClose according to servicetype
            // Based on the tab chosen, the number of tabs, we set the correct values to send.
            // All the undefined values won't be taken into account for the dispatches

            let serviceType: SupportedServiceType | undefined = selectedTable?.service_type;
            let address: Address | undefined = undefined;
            let deliveryZone: CheckedDeliveryZone | undefined = undefined;
            let dateTime: Date | undefined = undefined;
            const asap: boolean = orderExpectation.expected_asap === undefined ? false : orderExpectation.expected_asap

            if (availableServiceTypes && availableServiceTypes.length > 0) {
                serviceType = orderExpectation.service_type;
            }

            if (orderExpectation.service_type === SupportedServiceType.DELIVERY) {
                address = orderExpectation.address ?? undefined;
                deliveryZone = orderExpectation.delivery_zone ?? undefined;
            }

            if (
                orderExpectation.service_type === SupportedServiceType.DELIVERY
                || orderExpectation.service_type === SupportedServiceType.COLLECTION
            ) {
                dateTime = orderExpectation.dateTimeSelected;
            }

            if (delivery_delay_restriction) {
                let delivery_delay = 0;


                if (orderExpectation?.delivery_zone?.ref) {
                    delivery_delay = deliveryHelper.getDeliveryZoneFromRef(orderExpectation.delivery_zone.ref, selectedLocation?.delivery ?? [])?.default_delivery_delay ?? 0;
                }

                dateTime = moment(delivery_delay_restriction.delivery_after_time, 'HH:mm', true).add({ minutes: delivery_delay }).toDate()
            }

            // Sending the values to the parent component for dispatch
            onSubmitAndClose(
                serviceType,
                address,
                orderExpectation.addressType,
                deliveryZone,
                dateTime,
                asap,
                orderExpectation.delivery_note,
            );
        }
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={1}
            height={1}
            data-test="customer.modal.service_type_or_takeout"
            paddingX={2}
        >

            {
                // -------------
                // HEADER
                // -------------
            }

            {
                !showAddressTextField
                && !showTimeSelectScreen
                &&

                <Box >
                    {!isGetRestoTheme
                        ? <LocationLogo
                            titleId={themeHelper.shouldDisplayWelcomeText(selectedLocation) ? "homeForm.welcome1" : ""}
                        />

                        : <Box
                            my={3.5}
                        >
                            <Typography variant='h2'>
                                {selectedTable.service_type ?
                                    intl.formatMessage({ id: `service_type.${selectedTable.service_type}` }) :
                                    intl.formatMessage({ id: 'homeForm.serviceType' })
                                }
                            </Typography>
                        </Box>
                    }
                </Box>
            }

            {
                // -------------
                // TABS AND TAB CONTENT
                // -------------

                // Hide if 0 service type
            }

            {
                sortedServiceTypes
                    && orderExpectation.service_type ? (

                    <Box
                        width={1}
                        height={1}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt={0}
                        mb={isGetRestoTheme ? 0 : 2}
                    >

                        {
                            // -------------
                            // TABS HEADER
                            // -------------

                            // Hide if only 1 service type
                        }

                        {
                            !showAddressTextField
                            && !showTimeSelectScreen
                            && sortedServiceTypes.length > 1 &&

                            <Tabs
                                value={orderExpectation.service_type}
                                onChange={handleTabClick}
                                variant={'fullWidth'}
                                scrollButtons="auto"
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="service_type tabs"
                                style={{
                                    width: '100%'
                                }}
                            >
                                {
                                    sortedServiceTypes.map(serviceType => {

                                        return (

                                            <Tab
                                                data-test={`service_type_modal_tab_${serviceType}`}
                                                label={
                                                    <Typography
                                                        style={{ textTransform: "none" }}
                                                        variant="subtitle1"
                                                    >
                                                        {intl.formatMessage({
                                                            id: `service_type.${serviceType}`
                                                        })}
                                                    </Typography>
                                                }
                                                id={serviceType}
                                                aria-controls={`Tab-${serviceType}`}
                                                value={serviceType}
                                                key={serviceType}
                                            />
                                        );
                                    })
                                }
                            </Tabs>
                        }

                        {
                            // -------------
                            // TABS CONTENT
                            // WARNING: do not use TabPanel otherwise focus issue
                            // -------------
                        }

                        {orderExpectation.service_type === SupportedServiceType.DELIVERY &&

                            <DeliveryFullForm
                                setFullScreen={setFullScreen}
                                showAddressTextField={showAddressTextField}
                                setShowAddressTextField={setShowAddressTextField}
                                showTimeSelectScreen={showTimeSelectScreen}
                                setShowTimeSelectScreen={setShowTimeSelectScreen}
                                orderExpectation={orderExpectation}
                                setOrderExpectation={setOrderExpectation}
                                isGetRestoTheme={isGetRestoTheme}
                                restriction={delivery_delay_restriction}
                            />
                        }

                        {orderExpectation.service_type === SupportedServiceType.COLLECTION &&

                            <CollectionFullForm
                                showTimeSelectScreen={showTimeSelectScreen}
                                setShowTimeSelectScreen={setShowTimeSelectScreen}
                                orderExpectation={orderExpectation}
                                onOrderExpectationChange={setOrderExpectation}
                                isGetRestoTheme={isGetRestoTheme}
                            />
                        }

                        {
                            renderSimpleTabs()
                        }

                    </Box>
                ) : ""
            }

            {
                // -------------
                // CONFIRM BUTTON
                // -------------
            }

            {!showAddressTextField && !showTimeSelectScreen &&

                <Button
                    variant="contained"
                    color="primary"
                    data-test="service-type-or-takeout-button-confirm"
                    onClick={handleConfirmClick}
                    style={{
                        textTransform: "none",
                        width: "100%",
                        padding: isGetRestoTheme ? 12 : 6,
                        margin: isGetRestoTheme ? theme.spacing(2, 0) : 0,
                    }}
                    disabled={!formComplete}
                >
                    <Typography>
                        {intl.formatMessage({ id: "customer_information_modal.form.confirm" })}
                    </Typography>
                </Button>
            }
        </Box >
    );
}

export default ServiceTypeOrTakeoutForm;

