import { FIRESTORE_USERS_COLLECTION } from "../../authentications/configs/AuthenticationConfig";
import { FIRESTORE_ACCOUNTS_COLLECTION } from "../configs/AccountConfig";

export const getAccountFirestoreDocPath = (accountId: string): string => {
    return `${FIRESTORE_ACCOUNTS_COLLECTION}/${accountId}`;
}

/**
 * Get the storage path for backup for instance
 * @param accountId 
 * @param suffix 
 * @returns 
 */
export const getAccountBackupStoragePath = (accountId: string, suffixWithExtension: string): string => {
    return `${getAccountFirestoreDocPath(accountId)}${suffixWithExtension}`;
}

export const getAccountUsersFirestoreCollectionPath = (accountId: string): string => {
    return `${getAccountFirestoreDocPath(accountId)}/${FIRESTORE_USERS_COLLECTION}`;
}

export const getAccountUserFirestoreDocPath = (accountId: string, userId: string): string => {
    return `${getAccountUsersFirestoreCollectionPath(accountId)}/${userId}`;
}