import { Box, SvgIcon, Typography } from "@mui/material";
import log from "loglevel";
import { FormattedMessage } from "react-intl";
import theme from "../../config/theme";
import { Tags } from "../../my-lemonade-library/model/Catalog";
import { ReactComponent as AlcoholFreeTag } from "../assets/AlcoholFreeTag.svg";
import { ReactComponent as AlcoholTag } from "../assets/AlcoholTag.svg";
import { ReactComponent as BeefTag } from "../assets/BeefTag.svg";
import { ReactComponent as BioTag } from "../assets/BioTag.svg";
import { ReactComponent as DairyTag } from "../assets/DairyTag.svg";
import { ReactComponent as EggTag } from "../assets/EggTag.svg";
import { ReactComponent as FishTag } from "../assets/FishTag.svg";
import { ReactComponent as GlutenFreeTag } from "../assets/GlutenFreeTag.svg";
import { ReactComponent as GlutenTag } from "../assets/GlutenTag.svg";
import { ReactComponent as HomeMadeTag } from "../assets/HomeMadeTag.svg";
import { ReactComponent as PeanutTag } from "../assets/PeanutTag.svg";
import { ReactComponent as PorkTag } from "../assets/PorkTag.svg";
import { ReactComponent as SellFishTag } from "../assets/SellFishTag.svg";
import { ReactComponent as SpicyTag } from "../assets/SpicyTag.svg";
import { ReactComponent as SugarTag } from "../assets/SugarTag.svg";
import { ReactComponent as TreeNutsTag } from "../assets/TreeNutsTag.svg";
import { ReactComponent as VeganTag } from "../assets/VeganTag.svg";
import { ReactComponent as VegetarianTag } from "../assets/VegetarianTag.svg";


export const MAX_TAGS_DISPLAY = 5;

class TagService {

    /**
     * 
     * @param tag 
     * @param iconColor CSS color (for example: theme.palette.primary.main)
     * @returns 
     */
    tagSwitch(tag: Tags, iconColor: string): JSX.Element | null {

        let tagIconComponent: (React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>) | null = null;

        switch (tag) {
            case Tags.ALCOHOL:
                tagIconComponent = AlcoholTag;
                break;
            case Tags.ALOCOHOL_FREE:
                tagIconComponent = AlcoholFreeTag;
                break;
            case Tags.BEEF:
                tagIconComponent = BeefTag;
                break;
            case Tags.BIO:
                tagIconComponent = BioTag;
                break;
            case Tags.DAIRY:
                tagIconComponent = DairyTag;
                break;
            case Tags.EGG:
                tagIconComponent = EggTag;
                break;
            case Tags.FISH:
                tagIconComponent = FishTag;
                break;
            case Tags.GLUTEN:
                tagIconComponent = GlutenTag;
                break;
            case Tags.GLUTEN_FREE:
                tagIconComponent = GlutenFreeTag;
                break;
            case Tags.HOMEMADE:
                tagIconComponent = HomeMadeTag;
                break;
            case Tags.PEANUT:
                tagIconComponent = PeanutTag;
                break;
            case Tags.PORK:
                tagIconComponent = PorkTag;
                break;
            case Tags.SHELLFISH:
                tagIconComponent = SellFishTag;
                break;
            case Tags.SPICY:
                tagIconComponent = SpicyTag;
                break;
            case Tags.SUGAR:
                tagIconComponent = SugarTag;
                break;
            case Tags.TREENUTS:
                tagIconComponent = TreeNutsTag;
                break;
            case Tags.VEGAN:
                tagIconComponent = VeganTag;
                break;
            case Tags.VEGETARIAN:
                tagIconComponent = VegetarianTag;
                break;
        }

        if (tagIconComponent) {
            return (
                <SvgIcon
                    component={tagIconComponent}
                    style={{
                        color: iconColor,
                    }}
                    viewBox="0 0 212.6 212.6"
                    fontSize="inherit"
                />
            );
        }
        else {
            return null;
        }
    }

    renderTitleTag(tag: Tags) {
        return (
            <Typography variant="caption" color="textSecondary" style={{ padding: theme.spacing(0, 0.5, 0, 0.5) }}>
                <FormattedMessage id={tag} />
            </Typography>
        )
    }
    /**
     * 
     * @param tag 
     * @param color CSS color (for example: theme.palette.primary.main)
     * @param title if true tag name will be displayed with the icon 
     * @returns 
     */
    renderTag(
        tag: Tags,
        color: string,
        title: boolean,
    ): JSX.Element | null {

        const tagIcon: JSX.Element | null = this.tagSwitch(tag, color);

        if (tagIcon) {
            return (
                <Box display="flex" alignItems="center">
                    {tagIcon}
                    {title ? this.renderTitleTag(tag) : ""}
                </Box>
            )
        } else {
            log.error(`Cannot find icon for tag : ${tag}`)
            if (title) {
                return this.renderTitleTag(tag)
            } else {
                return null
            }
        }
    }
}

const tagService = new TagService();

export default tagService;