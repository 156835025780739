import _ from 'lodash';
import { LocationTheme } from "../models/LocationTheme";

export const DEFAULT_MYLEMONADE_PRIMARY_COLOR = "#f46524";

// breakpoint in px wich render desktop version (accessible with large tablet or landscape orientation)
export const desktopDisplayBreakpoint: number = 800;
const desktopBreakpointMediaQuery: string = `@media (min-width: ${desktopDisplayBreakpoint}px)`;

// These fonts are always available and will be appended each time a font family is set 
const fallbackFonts: string = ",\"Arial\",\"sans-serif\"";

// Default My Lemonade font family
const defaulMyLemonadeFont: string = "\"Poppins\""

// Default My Lemonade button border radius
export const defaultMyLemonadeButtonBorderRadius: number = 30;

// Default font unset properties like lineHeight and letterSpacing
// TODO: type it?
const defaultFontUnsetProperties: any = {
    lineHeight: "normal",
    letterSpacing: "normal",
}

/** const ADOBE_XD_UNIT_TO_REM: number = 0.0643; */

/**
 * The list of typography elements that we use. This list must be updated if a new element is
 * added by the design team.
 */
const listOfAllowedTypographies = ["h1", "h2", "h3", "h4", "h5", "body1", "subtitle1", "caption"]

/**
 * This object represents the values of the My Lemonade default theme.
 * If no theme is defined for the location, this one will be used. It is
 * mainly orange-black with the Google sans font.
 * The values come from this design made by Anaïs:
 * https://xd.adobe.com/view/ea6d07f7-fe53-4086-8090-53291e30151a-bf3e/specs/
 */

export const defaultMyLemonadeThemeOptions = {
    typography: {
        fontFamily: defaulMyLemonadeFont + fallbackFonts,
        h1: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.7rem",  // 35 xd_unit
            fontWeight: 600,        // semibold
            ...defaultFontUnsetProperties,
        },
        h2: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.286rem",   // 20 xd_unit 
            [desktopBreakpointMediaQuery]: {
                fontSize: '1.1rem',
            },
            fontWeight: 600,        // semibold
            ...defaultFontUnsetProperties,
        },
        h3: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.286rem",   // 20 xd_unit
            fontWeight: 400,        // normal
            ...defaultFontUnsetProperties,
        },
        h4: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.0288rem",  // 16 xd_unit
            fontWeight: 400,        // normal
            ...defaultFontUnsetProperties,
        },
        h5: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "0.9002rem",  // 14 xd_unit
            [desktopBreakpointMediaQuery]: {
                fontSize: '.82rem',
            },
            fontWeight: 600,        // bold
            ...defaultFontUnsetProperties,
        },
        body1: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: ".9002rem",   // 14 xd_unit
            fontWeight: 400,        // regular
            ...defaultFontUnsetProperties,
        },
        caption: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: ".7716rem",   // 12 xd_unit
            fontWeight: 400,        // regular
            ...defaultFontUnsetProperties,
        },
        subtitle1: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: ".9002rem",   // 14 xd_unit
            fontWeight: 500,        // medium
            ...defaultFontUnsetProperties,
        }
    },
    palette: {
        primary: {
            main: DEFAULT_MYLEMONADE_PRIMARY_COLOR,        // orange
            contrastText: "#FFFFFF" // white
        },
        secondary: {
            main: "#2A2A2A",
            contrastText: "#FFFFFF",
        },
        text: {
            primary: "#2A2A2A",     // black-grey
            secondary: "#2A2A2A",   // black-grey
            hint: "#949494"         // light grey
        },
        background: {
            default: "#f6f6f6",
            paper: "white"
        },
        action: {
            disabledBackground: "#dfdfdf"
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: defaultMyLemonadeButtonBorderRadius,
            }
        }
    }
}


/**
 * If a theme is defined in the location object (from firebase), then we will
 * override the existing theme with the custom values.
 * @param locationTheme 
 * @returns 
 */
export function createLocationTheme(locationTheme?: LocationTheme): any {

    if (locationTheme) {

        /**
         * This will be the final properties that we will override on the default
         * ML theme.
         */
        let finalLocationThemeOptions: any = defaultMyLemonadeThemeOptions;

        /**
         * First, we get the MUI basic options.
         * We also check each typography and add the fallback fonts
         * to them
         */
        if (locationTheme.mui_theme_options) {

            // Some properties requiere modifications. We will do them and use the new object later on.
            // TODO: see why I can't access some properties (like fontFamily) when the object has type ThemeOptions
            let newLocationThemeMUIOptions: any = locationTheme.mui_theme_options as any;

            if (newLocationThemeMUIOptions.typography) {

                // Iteration trough all the typography parameters
                for (const [key] of Object.entries(newLocationThemeMUIOptions.typography)) {

                    // Check if the key is a typography element (ex: h1, body1, ...)
                    if (listOfAllowedTypographies.includes(key)) {

                        // Add the fallback fonts
                        newLocationThemeMUIOptions.typography[key].fontFamily = "\""
                            + newLocationThemeMUIOptions.typography[key].fontFamily + "\"" + fallbackFonts;

                        /**
                         * If there is a desktop font size defined: "convert" it to the
                         * MUI format (ex: @media (min-width: 800px): {fontSize: "1.1rem"})
                         * and then delete the desktopFontSize property as it does not make
                         * any sense in CSS
                         */
                        if (newLocationThemeMUIOptions.typography[key].desktopFontSize) {

                            newLocationThemeMUIOptions.typography[key][desktopBreakpointMediaQuery] = {
                                fontSize: newLocationThemeMUIOptions.typography[key].desktopFontSize,
                            };

                            delete newLocationThemeMUIOptions.typography[key].desktopFontSize;
                        }
                    }
                }
            }

            /**
             * We merge the 2 theme options. The newLocationThemeMUIOptions properties
             * will override the finalLocationThemeOptions (which at this point is still
             * the defaultMyLemonadeThemeOptions)
             */
            _.merge(finalLocationThemeOptions, newLocationThemeMUIOptions);
        }

        /**
         * Now we get the extended theme options
         */
        if (locationTheme.mui_extended_theme_options) {

            let newLocationThemeExtendedOptions: any = {};

            if (locationTheme.mui_extended_theme_options.button_radius) {

                newLocationThemeExtendedOptions = {
                    ...newLocationThemeExtendedOptions,
                    overrides: {
                        MuiButton: {
                            root: {
                                borderRadius: locationTheme.mui_extended_theme_options.button_radius
                            }
                        }
                    }
                }
            }

            if (Object.keys(newLocationThemeExtendedOptions).length > 0) {

                /**
                 * We merge the 2 theme options. The newLocationThemeExtendedOptions properties
                 * will override the finalLocationThemeOptions (which now has its MUI properties
                 * overriden by the location MUI properties).
                */
                _.merge(finalLocationThemeOptions, newLocationThemeExtendedOptions)
            }
        }

        /**
         * Now we return the final theme created on the options we just
         * set and merged.
         */
        return finalLocationThemeOptions;
    }
    else {
        return defaultMyLemonadeThemeOptions;
    }
}
