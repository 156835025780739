import { Money } from "../../common/models/Money";

export interface OrderDiscount {
    name: string,
    ref: string,
    user_id?: string,
    /**
     * The discount value.
     */
    price_off: Money,
    type?: DiscountType,
    tax_ref?: string,
    tax_rate?: number,
    tax_price?: Money,
    update_id?: string,

    /**
     * If type is loyalty, the loyalty operation resulting in the discount
     */
    loyalty_operation_id?: string;

    // These fields are useful if we want to associate the discount with an entity.
    // For example, we use them with Zelty where deals & items can have their own discounts
    item_index?: number,
    deal_key?: string,
}


export enum DiscountType {
    STANDARD_DISCOUNT = "standard_discount",
    LOYALTY = "loyalty",
}