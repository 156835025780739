import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import moment from "moment";
import { useEffect, useState } from "react";
import * as yup from 'yup';
import { getTimezoneName } from "../../my-lemonade-library/model/Catalog";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import collectionService, { DEFAULT_MAX_COLLECTION_DAYS } from "../../my-lemonade-library/src/orders/services/CollectionService";
import { Restriction } from "../../my-lemonade-library/src/restrictions/model/Restriction";
import { intersectCatalogLocationTableRestrictions } from "../../my-lemonade-library/src/restrictions/services/RestrictionsService";
import TakeoutDateTimeForm from "../../orders/components/TakeoutDateTimeForm";
import { getDateTimeInitialValues } from "../../orders/helpers/OrderHelpers";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

interface TimeSelectFormProps {
    deliveryZoneRef?: string;
    dateTimeSelected: Date | undefined;
    onDateTimeSelectedChange: (asap: boolean, value?: Date) => void
    isGetRestoTheme?: boolean;
    serviceType: SupportedServiceType;
}

/**
 * This component allows the user to select the time for
 * delivery or collection.
 * TODO: after talking with Thibaut, it seems that we don't need the multiple
 * rows to select. We only need to display the date and time as done is ServicePickupInfo.
 */
const TimeSelectForm: React.FC<TimeSelectFormProps> = (props) => {

    const {
        dateTimeSelected,
        onDateTimeSelectedChange,
        serviceType,
        isGetRestoTheme = false
    } = props;

    const { selectedLocation, selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    // The current Date
    const today = moment();

    /**
     * This list contains all the days (from today and for a specific number of days) 
     * for which there is a possibility to order
     */
    const [allowedDays, setAllowedDays] = useState<moment.Moment[]>([]);

    /**
     * This list contains all the restrictions which can be applied for the
     * current location and catalog. It is in fact an intersection of restrictions.
     */
    const [allRestrictions, setAllRestrictions] = useState<Restriction[]>();

    /**
     * Refresh the "allRestrictions" on location or catalog change.
     * Set "allowedDays" based on the catalog, (location) and table restrictions
     */
    useEffect(() => {

        // Setting the final list of restrictions we'll use
        const restrictionsFinal: Restriction[] | undefined = intersectCatalogLocationTableRestrictions(
            selectedCatalog, selectedLocation, selectedTable
        ) ?? undefined;
        setAllRestrictions(restrictionsFinal);

        // Setting the list of days available for the user to order
        const foundAllowedDays = collectionService.getAllowedDays(restrictionsFinal, today.toDate(), getTimezoneName(selectedCatalog), serviceType, DEFAULT_MAX_COLLECTION_DAYS, 0);
        setAllowedDays(foundAllowedDays);

    }, [selectedCatalog, selectedLocation])

    interface TimeSelectorFormFields {

        // Fields which can be displayed
        date: string;
        time: string;
    }

    /**
     * This function is called when all the fields are filled and valid, and
     * the user clicks on "confirm".
     * WARNING: We already know that the values are valid and filled if required thanks
     * to the yup validation. We don't have to do any validation here, except for the API
     * fetch (last check to see if the timeslot is still available)
     * @param values
     * @param formik
     */
    const onSubmit = (values: TimeSelectorFormFields) => {
        let expectedTime: Date;

        if (values.date && values.time) {
            const hourTab = values.time.split(':');
            expectedTime = DateTime.fromISO(values.date, { zone: getTimezoneName(selectedCatalog) }).plus({ hours: +hourTab[0], minutes: +hourTab[1] }).toJSDate();
            onDateTimeSelectedChange(false, expectedTime);
        }
    }

    const validationSchema: yup.SchemaOf<TimeSelectorFormFields> = yup.object({

        date: yup.string()
            .required("orders.servicePickupInfo.date.required"),


        time: yup.string()
            .required(""),
    });

    /**
     * The initial values for the form.
     * 
     * BIG BIG WARNING: see ServicePickupInfo > initialValues
     */
    const initialValues: TimeSelectorFormFields = {

        date: getDateTimeInitialValues(selectedCatalog, dateTimeSelected, allowedDays)[0],
        time: getDateTimeInitialValues(selectedCatalog, dateTimeSelected, allowedDays)[1],
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={1}
            height={1}
            paddingTop={isGetRestoTheme ? 2 : 0}
            px={isGetRestoTheme ? 3 : 0}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(formik) => {

                    return (
                        <Form
                            style={{
                                width: "100%",
                            }}
                            onChange={() => setTimeout(formik.submitForm, 0)}
                        >
                            <TakeoutDateTimeForm
                                deliveryZoneRef={props.deliveryZoneRef}
                                serviceType={props.serviceType}
                                allRestrictions={allRestrictions}
                                date={formik.values.date}
                                allowedDays={allowedDays}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}

export default TimeSelectForm;