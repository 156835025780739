import { Alert } from '@mui/lab';
import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import AuthenticationBottomMessages, { AuthenticationBottomMessage } from '../../authentication/components/AuthenticationBottomMessages';
import log from '../../Common/services/LogService';
import * as ROUTES from '../../config/routes';
import { getCategoriesFullRoute } from '../../config/routes';
import { CUSTOMER_INFORMATION_MODAL_BUTTONS_MARGIN_Y, CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y } from '../../customers/configs/CustomerInformationModalConfig';
import { CustomerInformationModalFormToDisplay } from '../../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../../customers/redux/CustomerInformationModalActions';
import { shouldDisplayLinks } from '../../linktree/components/LocationLinksDisplay';
import { getTimezoneName } from '../../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderInBase } from '../../my-lemonade-library/model/Order';
import { getOrderRefFromBase64IfNeeded } from '../../my-lemonade-library/src/connectors/services/ConnectorHelper';
import linktreeHelper from '../../my-lemonade-library/src/linktree/services/LinktreeHelper';
import LocationLink from '../../my-lemonade-library/src/locations/models/LocationLink';
import LocationLinkRelationType from '../../my-lemonade-library/src/locations/models/LocationLinkRelationType';
import { canEarnLoyalty } from '../../my-lemonade-library/src/loyalties/services/LoyaltyHelper';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { OrderPaymentStatus } from '../../my-lemonade-library/src/payments/models/OrderPaymentStatus';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { canPlaceOrderNow, getFirstMatchingRestriction, intersectCatalogLocationTableRestrictions } from '../../my-lemonade-library/src/restrictions/services/RestrictionsService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { ORDER_ID_QUERY_PARAM_NAME, ORDER_REF_QUERY_PARAM_NAME } from '../configs/OrdersRouterConfig';
import { loadingOrdersIsErrored, needsToCreateDraftAfterReset } from '../helpers/OrderHelpers';
import orderActions from '../redux/OrderActions';
import { canPaySpecificOrder } from '../services/OrderLoadedPayOrUseLoyaltyFormService';

interface ButtonsLinks {
    pay?: LocationLink
    menu?: LocationLink
    loyalty?: LocationLink
}

interface OrderLoadedPayOrUseLoyaltyProps {
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
    isSelectedTableServiceRestricted?: boolean
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OrderLoadedPayOrUseLoyalty: React.FC<OrderLoadedPayOrUseLoyaltyProps> = (props) => {

    const { setOnModalCloseAction, isSelectedTableServiceRestricted = false } = props;

    const { tableLinkId, selectedCatalog, selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { order, initOrders: initOrdersState } = useTypedSelector((state: RootState) => state.order);
    const [payButtonDisplayed, setPayButtonDisplayed] = useState<boolean>(true);
    const [buttonsLinks, setButtonsLinks] = useState<ButtonsLinks>({})
    const serviceType = selectedTable.service_type ?? SupportedServiceType.EAT_IN;

    const canPlaceOrderNowResult = canPlaceOrderNow(selectedLocation, selectedCatalog, selectedTable, DateTime.now());

    const query = useQuery();
    const orderRefURL = query.get(ORDER_REF_QUERY_PARAM_NAME);
    const orderIdURL = query.get(ORDER_ID_QUERY_PARAM_NAME);

    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        // If checkout not suppose to close manually the modal, otherwise closing the modal goes to categories
        if (selectedTable.service_type !== SupportedServiceType.CHECKOUT) {
            history.push(getCategoriesFullRoute(tableLinkId));
        }
    }, [history, selectedTable.service_type, tableLinkId]);

    useEffect(() => {
        // Set the action to do when the modal is closed
        // Forced to be done at the upper level in order to be connected to the modal component
        setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY, onClose: onClose })
    }, [onClose])

    const onWantedToPay = (
        orderId: string | null,
        orderRef: string | null,
    ) => {

        const canPayOrder = canPaySpecificOrder(order, selectedLocation, selectedTable.id);

        // The order fetched by its REF or ID is already in the state -> go on summary page
        if (
            (orderId && orderId === order.id)
            || (orderRef && order.private_ref && getOrderRefFromBase64IfNeeded(orderRef) === order.private_ref)
            || (orderRef && order.receipt_ref && getOrderRefFromBase64IfNeeded(orderRef) === order.receipt_ref)
        ) {
            log.info(`Wanting to pay order ${orderId} ${orderRef}: going to summary page`);
            history.push(`/${tableLinkId}${ROUTES.OrderSummaryPage}?${query.toString()}`);
        }
        else if (canPayOrder && order && selectedCatalog && selectedLocation) {

            // Is there an order to load in latestOrders? If yes, go to summary page with the order ID
            dispatch(orderActions.setOrder(order, selectedCatalog, selectedLocation, selectedTable));
            history.push(`/${tableLinkId}${ROUTES.OrderSummaryPage}?${ORDER_ID_QUERY_PARAM_NAME}=${order.id}`);
        }

        // TODO:  WTF?
        //dispatch(orderActions.openModal());
        //onModalClose();
        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY));
    }

    const onWantedSeeMenu = () => {

        if (selectedTable.service_type !== SupportedServiceType.CHECKOUT) {
            // If there's an order in state
            if (order && order.id && order.id !== "" && selectedLocation) {

                // If we can not add items, the order is not usable, let's start a new one.
                if (!orderService.canAddItems(selectedLocation, order)) {

                    log.info(`Cannot pay or add items on order ${order.display_id}: creating a new order`);
                    dispatch(orderActions.resetOrder());

                    if (needsToCreateDraftAfterReset(selectedLocation?.orders?.webapp_table_orders, selectedTable.service_type)) {

                        log.info("Creating a new draft order and writing it to firestore");
                        dispatch(orderActions.createDraftOrder());
                    }
                }
            }

            history.push(getCategoriesFullRoute(tableLinkId));
            dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY));
        }
    }

    const onWantedActivateLoyalty = () => {

        let loyaltyRoute = `/${tableLinkId}${ROUTES.LoyaltyPage}`;
        if (orderIdURL || orderRefURL) {
            loyaltyRoute += `?${query.toString()}`;
        }

        dispatch(orderActions.openModal());
        history.push(loyaltyRoute);
        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY));
    }

    useEffect(() => {
        if (selectedLocation && selectedTable) {
            const tempButtonsLinks: ButtonsLinks = {}
            selectedTable.links?.forEach(l => {
                if (!l.disable) {
                    switch (l.rel) {
                        case LocationLinkRelationType.PAY:
                            tempButtonsLinks.pay = l
                            break;

                        case LocationLinkRelationType.VIEW_MENU:
                            tempButtonsLinks.menu = l
                            break;

                        case LocationLinkRelationType.LOYALTY:
                            tempButtonsLinks.loyalty = l
                            break;

                        default:
                            break;
                    }
                }
            })

            setButtonsLinks(tempButtonsLinks)
        }
    }, [selectedTable, selectedLocation, order]);


    useEffect(() => {
        let payButtonWillDisplay = false;
        if (buttonsLinks && buttonsLinks.pay) {
            payButtonWillDisplay = true;
        } else if (selectedLocation && paymentHelper.canPay(selectedLocation, order)) {
            payButtonWillDisplay = true;
        }
        setPayButtonDisplayed(payButtonWillDisplay)

    }, [buttonsLinks, selectedLocation, order]);

    const displayPayButton = (): boolean => {

        const orderPaymentStatus: OrderPaymentStatus = paymentHelper.getOrderPaymentStatus(order);

        if (
            selectedLocation
            && orderPaymentStatus !== OrderPaymentStatus.PAID
            && paymentHelper.canPay(selectedLocation, order)
        ) {
            return true;
        }
        return false;
    }


    const renderPayButtonOrText = (): React.ReactNode => {

        const restrictionsFinal = intersectCatalogLocationTableRestrictions(selectedCatalog, selectedLocation, selectedTable);
        const foundRestriction = restrictionsFinal
            ? getFirstMatchingRestriction(moment(), getTimezoneName(selectedCatalog), restrictionsFinal)
            : undefined;

        if (buttonsLinks.pay) {
            return (
                <Link
                    href={linktreeHelper.replacePlaceholdersInLink(buttonsLinks.pay.href, selectedLocation, selectedTable, order)}
                    style={{
                        width: "100%",
                        textDecoration: 'none'
                    }}
                >
                    <Button
                        data-test="orderLoaded.pay_button"
                        variant="contained"
                        color="primary"
                        style={{
                            textTransform: "none",
                            width: "100%",
                            padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                        }}
                    >
                        <Typography variant="subtitle1">
                            {intl.formatMessage({ id: "Summary.sharePayment.pay" })}
                        </Typography>

                    </Button>
                </Link>
            )
        }

        if (loadingOrdersIsErrored(initOrdersState)) {
            return (
                <Alert
                    severity="error"
                    style={{
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Typography>
                        {intl.formatMessage({ id: "order.load.error" })}
                    </Typography>
                </Alert>
            );
        } else if (paymentHelper.getOrderPaidAmount(order) > 0 && paymentHelper.getOrderPaymentStatus(order) === OrderPaymentStatus.PAID) {
            return (
                <Typography
                    style={{
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {intl.formatMessage({ id: "customer_information_modal.pay.already_paid" })}
                </Typography>
            );
        } else if (
            selectedLocation
            && paymentHelper.canPay(selectedLocation, order)
            && (
                !foundRestriction
                || !foundRestriction.service_types
                || !foundRestriction.service_types.includes(SupportedServiceType.VIEW)
            )
        ) {
            return (
                <Button
                    data-test="orderLoaded.pay_button"
                    variant="contained"
                    onClick={() => {
                        if (orderIdURL) {
                            onWantedToPay(orderIdURL, null);
                        }
                        else if (orderRefURL) {
                            onWantedToPay(null, orderRefURL);
                        }
                        else {
                            onWantedToPay(null, null);
                        }
                    }}
                    color="primary"
                    style={{
                        textTransform: "none",
                        width: "100%",
                        padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                    }}
                >

                    <Typography variant="subtitle1">
                        {intl.formatMessage({ id: "Summary.sharePayment.pay" })}
                    </Typography>

                </Button>
            );
        } else if (paymentHelper.getOrderPaidAmount(order) > 0 && selectedLocation?.orders?.allow_online_payment_for_opened_orders) {
            return (
                <Typography
                    style={{
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {intl.formatMessage({ id: "customer_information_modal.pay.cant_paid" })}
                </Typography>
            );
        } else if (selectedTable.service_type === SupportedServiceType.CHECKOUT) {
            return (
                <Typography
                    align="center"
                    style={{
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {
                        intl.formatMessage(
                            initOrdersState.connectorGetTableOpenedOrdersError
                                ? { id: `homeForm.checkout_service_type.connector_get_table_error.${initOrdersState.connectorGetTableOpenedOrdersError}` }
                                : { id: "homeForm.checkout_service_type.no_order_to_pay" }
                        )
                    }
                </Typography>
            );
        }

        return null;
    }

    const renderCantOrderNowBanner = () => {
        if (isSelectedTableServiceRestricted) return;

        const now = DateTime.local();
        let message = "";

        if (!canPlaceOrderNowResult.nextAvailbleTime || !canPlaceOrderNowResult.nextAvailbleTime.hasSame(now, "day")) {
            message = intl.formatMessage({ id: "orders.order_awaiting_payment.cant_order" });
        }
        else {
            message = intl.formatMessage({ id: "orders.order_awaiting_payment.cant_order.withTime" }, {
                time: canPlaceOrderNowResult.nextAvailbleTime.toLocaleString(DateTime.TIME_SIMPLE)
            });
        }

        return (
            <Alert severity="info">
                <Typography>
                    {message}
                </Typography>
            </Alert>
        );
    }

    const renderOrderOrViewMenuButton = (
        dataTest: string,
        onClick: MouseEventHandler<HTMLButtonElement> | undefined,
    ): JSX.Element => {

        return (

            <Box
                display="flex"
                flexDirection="column"
                width={1}
                gap={2}
            >
                <Button
                    data-test={dataTest}
                    variant={payButtonDisplayed ? 'outlined' : 'contained'}
                    color="primary"
                    disabled={isSelectedTableServiceRestricted}
                    style={{
                        margin: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_MARGIN_Y, 0, 0, 0),
                        textTransform: "none",
                        width: "100%",
                        padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                    }}
                    onClick={onClick}
                >
                    <Typography variant="subtitle1">
                        {intl.formatMessage({
                            id: (serviceType === SupportedServiceType.VIEW
                                || !canPlaceOrderNowResult.canPlaceOrderNow
                                || (
                                    serviceType === SupportedServiceType.CHECKOUT
                                    && selectedLocation?.allow_to_browse_catalog_for_checkout_service_type
                                    && !selectedTable.do_not_allow_to_browse_catalog_for_checkout_service_type
                                ))
                                ? "orders.order_awaiting_payment.see_menu"
                                : "orders.order_awaiting_payment.order"
                        }, {
                            br: <br />
                        })}
                    </Typography>

                </Button>

                {!canPlaceOrderNowResult.canPlaceOrderNow && renderCantOrderNowBanner()}

            </Box>
        );
    }

    if (!selectedLocation) {
        return null;
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={1}
            data-test="customer.modal.order_loaded_pay_or_use_loyalty"
            paddingX={2}
        >

            <Box display="flex" flexDirection='column' alignItems="center" width={1}>

                {renderPayButtonOrText()}

                {
                    !loadingOrdersIsErrored(initOrdersState)
                    && selectedLocation
                    && selectedLocation.loyalty
                    && !selectedLocation.loyalty.disabled
                    && selectedLocation.loyalty.earning_rule.allow_earning_all_points_on_receipts
                    &&

                    ((buttonsLinks.loyalty && buttonsLinks.loyalty.href)
                        ? <Link
                            href={linktreeHelper.replacePlaceholdersInLink(buttonsLinks.loyalty.href, selectedLocation, selectedTable, order)}
                            style={{
                                width: "100%",
                                textDecoration: 'none'
                            }}
                        >
                            <Button
                                data-test="orderLoaded.loyalty_button"
                                variant={displayPayButton() ? "outlined" : "contained"}
                                color="primary"
                                style={{
                                    margin: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_MARGIN_Y, 0, 0, 0),
                                    textTransform: "none",
                                    width: "100%",
                                    padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                                }}
                            >

                                <Typography variant="subtitle1">
                                    {intl.formatMessage({
                                        id: `orders.order_awaiting_payment.loyalty${canEarnLoyalty(order as OrderInBase, selectedLocation.loyalty, true, true) ? "" : "_view"}`
                                    })}
                                </Typography>

                            </Button>
                        </Link>

                        : (!orderService.isFullyEditable(order) ? <Button
                            data-test="orderLoaded.loyalty_button"
                            variant={displayPayButton() ? "outlined" : "contained"}
                            onClick={onWantedActivateLoyalty}
                            color="primary"
                            style={{
                                margin: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_MARGIN_Y, 0, 0, 0),
                                textTransform: "none",
                                width: "100%",
                                padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                            }}
                        >

                            <Typography variant="subtitle1">
                                {intl.formatMessage({
                                    id: `orders.order_awaiting_payment.loyalty${(selectedLocation.loyalty && canEarnLoyalty(order, selectedLocation.loyalty, true, true)) ? "" : "_view"}`
                                })}
                            </Typography>

                        </Button> : <></>)
                    )
                }

                {serviceType !== SupportedServiceType.CHECKOUT &&

                    (
                        buttonsLinks.menu
                            ? <Link
                                href={linktreeHelper.replacePlaceholdersInLink(buttonsLinks.menu.href, selectedLocation, selectedTable, order)}
                                style={{
                                    width: "100%",
                                    textDecoration: 'none'
                                }}
                            >
                                {renderOrderOrViewMenuButton(
                                    "orderLoaded.see_menu_button",
                                    () => selectedTable.tablelink_id && history.push(getCategoriesFullRoute(selectedTable.tablelink_id))
                                )}
                            </Link>
                            : renderOrderOrViewMenuButton("locationhome_orderbutton", onWantedSeeMenu)
                    )
                }

                {
                    serviceType === SupportedServiceType.CHECKOUT
                    && selectedLocation.allow_to_browse_catalog_for_checkout_service_type
                    && !selectedTable.do_not_allow_to_browse_catalog_for_checkout_service_type
                    &&
                    renderOrderOrViewMenuButton(
                        "order.checkout.see_menu_button",
                        () => selectedTable.tablelink_id && history.push(getCategoriesFullRoute(selectedTable.tablelink_id))
                    )
                }


            </Box>

            {!shouldDisplayLinks(selectedTable, order) &&
                <AuthenticationBottomMessages
                    messagesToDisplay={[
                        AuthenticationBottomMessage.CREATE_ACCOUNT_AND_GET_OFFERS,
                        AuthenticationBottomMessage.ALREADY_CUSTOMER,
                    ]}
                />
            }

        </Box>
    )
}

export default OrderLoadedPayOrUseLoyalty;

