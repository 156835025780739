import { Box } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import PlusMinusQuantity, { PlusMinusQuantityProps } from '../../../orders/components/PlusMinusQuantity'
import FormikErrorMessage from './FormikErrorMessage'

type FormikPlusMinusProps = {
    name: string
} & Omit<PlusMinusQuantityProps, "value" | "onAdd" | "onMinus">;

const FormikPlusMinus: React.FC<FormikPlusMinusProps> = (props) => {

    const { name, ...rest } = props;
    const [field,] = useField<number | undefined>(name);

    const onAdd = () => {
        field.onChange({
            target: {
                name: field.name,
                value: (field.value ?? 1) + 1,
            }
        });
    }

    const onMinus = () => {
        field.onChange({
            target: {
                name: field.name,
                value: (field.value ?? 1) - 1,
            }
        });
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            gap={.6}
        >
            <PlusMinusQuantity
                {...rest}
                value={field.value ?? 1}
                onAdd={onAdd}
                onMinus={onMinus}
            />

            <FormikErrorMessage name={name} />
        </Box>
    )
}

export default FormikPlusMinus;