import { CustomerInformationFormParameters } from "../models/CustomerInformationFormParameters";
import { CustomerInformationModalFormToDisplay } from "../models/CustomerInformationModalFormToDisplay";
import { CLOSE_CUSTOMER_INFORMATION_MODAL_IF_DISPLAYED, CustomerInformationModalActionTypes, SET_CUSTOMER_INFORMATION_MODAL } from "./CustomerInformationModalActions";

export interface CustomerInformationModalState {
    formToDisplay: CustomerInformationModalFormToDisplay | null;
    /**
     * Specific form parameters depending on form type
     */
    formParameters: CustomerInformationFormParameters;
}

const customerInformationModalInitialState: CustomerInformationModalState = {
    formToDisplay: null,
    formParameters: null,
}

export default function reducer(
    state = customerInformationModalInitialState,  // Default state
    action: CustomerInformationModalActionTypes,
): CustomerInformationModalState {

    switch (action.type) {

        case SET_CUSTOMER_INFORMATION_MODAL:
            return {
                formToDisplay: action.payload.formToDisplay,
                formParameters: action.payload.formParameters
            }

        case CLOSE_CUSTOMER_INFORMATION_MODAL_IF_DISPLAYED: {
            if (state.formToDisplay === action.payload.formToClose) {
                return {
                    ...state,
                    formToDisplay: null,
                }
            }
            else {
                return state;
            }
        }

        default:
            return state;
    }
}