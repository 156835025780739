import { Box, Button, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import logo from "../../authentication/assets/logo.svg";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { MAX_LOGO_WIDTH } from "../../Locations/configs/LocationConfig";
import themeHelper from "../../my-lemonade-library/src/theme/services/ThemeHelper";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import tableActions from "../redux/TableActions";
import TableSelector from "./TableSelector";

interface SelectTableFormDialogProps {
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}
const SelectTableFormDialog: React.FC<SelectTableFormDialogProps> = (props) => {

    const { setOnModalCloseAction } = props;
    const { selectedTable, selectedLocation } = useTypedSelector((state: RootState) => state.locations);

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [tableIdSelected, setTableIdSelected] = useState<string | null>(null);
    const [formIncompleteError, setFormIncompleteError] = useState<string>("");
    const [isTableNeeded, setIstableNeeded] = useState<boolean>(false);

    const onClose = useCallback(() => {
        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(
            CustomerInformationModalFormToDisplay.SELECT_TABLE
        ));
    }, []);

    useEffect(() => {
        setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.SELECT_TABLE, onClose: onClose })
    }, [onClose])

    // Setup the table form
    useEffect(() => {

        if (!selectedTable || !selectedTable.id) {

            setIstableNeeded(true);
        }
        else {

            setTableIdSelected(selectedTable.id)
        }
    }, [selectedTable]);

    /**
     * Are all the information fields filled properly?
     * @returns 
     */
    const isFormComplete = (): boolean => {

        if (isTableNeeded && !tableIdSelected) {

            return false;
        }

        return true;
    }

    /**
     * This function is triggered when the user clicks on the "validate" button
     */
    const handleClick = () => {

        // It doesn't matter if the form is complete, if we have this info we can set it
        // TODO: WTF?
        if (isTableNeeded && tableIdSelected) {
            dispatch(tableActions.setTableSelected(tableIdSelected))
        }

        // Now we check if the form is complete
        if (isFormComplete()) {

            setFormIncompleteError("");

            // No need to close the modal, will autoclose when the table is filled
        }
        else {
            setFormIncompleteError(intl.formatMessage({ id: "customer_information_modal.form.error" }));
        }
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            data-test="customer.modal.required_information_form"
            paddingX={2}
        >

            {/** Hide title when address is currently entered */}
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box maxWidth={MAX_LOGO_WIDTH}>
                    {selectedLocation?.logo ? (
                        <img width="100%" src={selectedLocation?.logo} alt="" />
                    ) : (
                        <img src={logo} alt="" />
                    )}
                </Box>
                {themeHelper.shouldDisplayWelcomeText(selectedLocation) &&
                    <Box textAlign="center">
                        <Typography variant="h1">
                            {intl.formatMessage({ id: "homeForm.welcome1" })}
                        </Typography>
                    </Box>
                }
                <Box textAlign="center">
                    <Typography variant="h3">
                        {selectedLocation?.name}
                    </Typography>
                </Box>
            </Box>

            <Box px={3} width={1}>

                {isTableNeeded &&
                    <TableSelector
                        tableIdSelected={tableIdSelected}
                        setTableIdSelected={setTableIdSelected}
                    />
                }

            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                style={{
                    textTransform: "none",
                    width: "100%",
                }}
                disabled={!isFormComplete()}
            >
                <Typography>
                    {intl.formatMessage({ id: "table.tableSelector.button" }, { br: <br /> })}
                </Typography>

            </Button>

            {formIncompleteError !== "" &&
                <Typography variant="caption" style={{ color: theme.palette.primary.main, marginTop: theme.spacing(2) }}>
                    {formIncompleteError}
                </Typography>
            }
        </Box>
    );
}

export default SelectTableFormDialog;