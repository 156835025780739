import { createStyles, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getRadiusFromTheme } from '../../Common/helper/ThemeHelper';

/**
 * See: https://docs.lyra.com/fr/rest/V4.0/javascript/features/themes.html
 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@global': {
            '.kr-embedded .kr-payment-button': {
                backgroundColor: `${theme.palette.primary.main} !important;`,
                borderRadius: `${getRadiusFromTheme(theme)}px !important;`,
            },
            '.kr-embedded .kr-payment-button span': {
                fontFamily: `${theme.typography.fontFamily} !important;`,
                fontSize: `${theme.typography.body1.fontSize} !important;`
            }
        }
    })
);

const PaymentLyraGlobalStyle = () => {
    useStyles();

    return null;
};

export default PaymentLyraGlobalStyle;