export class AutoId {
    static LENGTH: number = 20
    static SEPARATOR: string = "-"
    /**
     * DANGEROUS! Only to stub for test
     */
    static FORCE_VALUE: string = "";
    static newId(name: string, length: number = AutoId.LENGTH, onlyNumbers?: boolean): string {
        if (AutoId.FORCE_VALUE) {
            return AutoId.FORCE_VALUE;
        }
        // Alphanumeric characters

        // Take only first 10 characters, 
        // replace accented characters by ascii equivalent, 
        // replace special char ans spaces by separator
        // convert to lowaer case
        let workingName = name
            .substring(0, 10)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\W/g, AutoId.SEPARATOR)
            .toLowerCase()
        if (workingName) {
            workingName += AutoId.SEPARATOR;
        }

        let stringSize: number = workingName.length

        const chars =
            onlyNumbers ? '0123456789' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        let finalSize = length - stringSize

        for (let i = 0; i < finalSize; i++) {
            workingName += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return workingName;
    }
}
