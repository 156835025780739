import { Catalog, Product, Sku } from "../../../model/Catalog";
import { CategoryReferenceLevel1 } from "../../referential/models/CategoryReferenceLevel1";

export const atLeastOneSkuEnabledInProduct = (product: Product): boolean => {

    for (const sku of product.skus) {
        if (!sku.disable) {
            return true;
        }
    }

    return false;
}

export const isProductAlcohol = (product: Product): boolean => {
    if (product.is_alcohol) {
        return true;
    }

    if (
        product.referential
        && product.referential.category1 === CategoryReferenceLevel1.ADULT_BEVERAGES
    ) {
        return true;
    }

    return false;
}

export const getProductByProductRef = (catalog: Catalog | null, productRef?: string): Product | undefined => {
    if (!catalog || !productRef) return;
    return catalog?.data.products.find((product: Product) => {
        return product.ref === productRef
    })
}

export const getSkuBySkuRef = (product?: Product, skuRef?: string): Sku | undefined => {
    if (!product || !skuRef) return;
    return product?.skus.find((sku: Sku) => {
        return sku.ref === skuRef
    })
}