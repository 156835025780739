import { Close } from "@mui/icons-material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import AuthenticationActions from '../../authentication/redux/AuthenticationActions';
import { checkEmailRegex } from "../../Common/helper/EmailHelper";
import * as ROUTES from '../../config/routes';
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { OrderStatus, PaymentType } from "../../my-lemonade-library/model/Order";
import { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import { DEFAULT_CURRENCY, MoneyToStringWithSymbol, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { CONNECTORS_COMPATIBLE_CONNECTOR_WALLET } from "../../my-lemonade-library/src/connectors/models/ConnectorWallet";
import LoyaltyExplaination from "../../orders/components/LoyaltyExplaination";
import { ORDER_ID_QUERY_PARAM_NAME } from "../../orders/configs/OrdersRouterConfig";
import { getLoyaltyCurrentBalance } from "../../orders/helpers/LoyaltyHelpers";
import OrderAction, { orderActions } from '../../orders/redux/OrderActions';
import PaymentWalletBottomButtons from "../../Payment/components/PaymentWalletBottomButtons";
import paymentsActions from "../../Payment/redux/PaymentsActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import InfoOrderDisplay from "../components/InfoOrderDisplay";
import RestaurantTicketButton from "../components/RestaurantTicketButton";
import UpdateUserInfos from "../components/UpdateUserInfos";

export type LatestOrderType = "latestUserOrders" | "latestTableOrders";

export interface NumberOfOrdersToDisplay {
    latestUserOrders: number;
    latestTableOrders: number;
}

const MyInformations: React.FC = () => {

    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { initOrders: initOrdersState, order } = useTypedSelector((state: RootState) => state.order);
    const { selectedLocation, tableLinkId } = useTypedSelector((state: RootState) => state.locations);
    const { data, login } = useTypedSelector((state: RootState) => state.authentication);
    const { estimatedLoyaltyConfigContent } = useTypedSelector((state: RootState) => state.loyalty);
    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);
    const { connector_wallet } = useTypedSelector((state: RootState) => state.payment);
    const thisConnectorWallet = selectedLocation?.connector?.type ? connector_wallet[selectedLocation.connector.type] : undefined;
    const walletBalance = thisConnectorWallet?.wallet_balance ?? undefined;


    const userId = data.user_authentication_state.user?.uid;
    const edenredIsLogged = isTherePaymentData[PaymentType.EDENRED]?.[userId ?? ""];
    const swileIsLogged = isTherePaymentData[PaymentType.SWILE]?.[userId ?? ""];

    const [wantedToLogoutEdenred, setWantedToLogoutEdenred] = useState<boolean>(false);

    // This box contains a helper text and 2 buttons to reset password
    const [showPasswordResetBox, setShowPasswordResetBox] = useState<boolean>(false);

    // This message displays for x seconds before the logout
    const [showPasswordResetSuccess, setShowPasswordResetSuccess] = useState<boolean>(false);
    const PASSWORD_SUCCESS_MESSAGE_TIMEOUT = 5;  // seconds

    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [userParameterToEdit, setUserParameterToEdit] = useState<string>('');

    const isEdenredEnabled = selectedLocation?.supported_payment_types?.some(p => p.type === PaymentType.EDENRED);
    const isSwileEnabled = selectedLocation?.supported_payment_types?.some(p => p.type === PaymentType.SWILE);

    /**
     * When the page is closed, reset the edenred isLoggedIn state
     */
    useEffect(() => {
        return () => {
            if (isEdenredEnabled && userId) {
                dispatch(paymentsActions.isTherePaymentDataSuccess(PaymentType.EDENRED, userId, undefined));
            }
            if (isSwileEnabled && userId) {
                dispatch(paymentsActions.isTherePaymentDataSuccess(PaymentType.SWILE, userId, undefined));
            }
        }
    }, []);

    /**
     * When we load this page, we check in the latestOrders if the current
     * draft order is in. If not, we ask firebase to refresh them
     */
    useEffect(() => {

        if (order.id && order.status === OrderStatus.DRAFT) {

            let found: boolean = false;

            // First search in latestTableOrders
            for (const elem of initOrdersState.latestTableOrders) {
                if (elem.id === order.id) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                // Then search in latestUserOrders
                for (const elem of initOrdersState.latestUserOrders) {
                    if (elem.id === order.id) {
                        found = true;
                        break;
                    }
                }
            }

            // No matching id: we have to refresh
            if (!found) {
                dispatch(orderActions.loadOrders());
            }
        }

    }, [order.id, order.status]);

    /**
     * Get to know if the user has an Edenred or Swile account linked
     */
    useEffect(() => {

        if (selectedLocation && userId && isUserLoggedIn(data.user_authentication_state)) {

            const paymentTypes: PaymentType[] = [];

            if (
                isEdenredEnabled
                && edenredIsLogged === undefined  // will be null when the fetch is in progress
                && !wantedToLogoutEdenred
            ) {
                paymentTypes.push(PaymentType.EDENRED);
            }

            if (
                isSwileEnabled
                && swileIsLogged === undefined  // will be null when the fetch is in progress
            ) {
                paymentTypes.push(PaymentType.SWILE);
            }

            if (paymentTypes.length > 0) {
                dispatch(paymentsActions.isTherePaymentData(selectedLocation.account_id, selectedLocation.id, paymentTypes, userId));
            }
        }
    }, [isEdenredEnabled, selectedLocation, edenredIsLogged]);

    /**
     * Reset the password
     */
    const resetPassword = () => {

        const email: string | undefined = data?.user_authentication_state?.user?.email;

        if (email) {

            // Send the email
            dispatch(AuthenticationActions.resetPassword(email));

            // Display the success message for x seconds
            setShowPasswordResetSuccess(true);
            setTimeout(() => {
                logoutFunction();
            }, PASSWORD_SUCCESS_MESSAGE_TIMEOUT * 1000);
        }
        else {
            setShowPasswordResetBox(false);
        }
    }

    /**
     * Log out, return to catalog page
     */
    const logoutFunction = () => {

        dispatch(AuthenticationActions.signoutUser());
        history.goBack();
        // TODO: WTF, another close modal action
        //dispatch(OrderAction.closeModal());
    }

    /**
     * Open the login popup, return to catalog page
     */
    const loginFunction = () => {

        history.goBack();
        dispatch(OrderAction.closeModal());

        // Reset the has_chosen_to_be_anonymous state
        dispatch(AuthenticationActions.resetGuestState());

        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
            { forceMandatory: true }
        ));
    }

    const handleIsEditOpen = (bool: boolean, userParameter?: string) => {
        if (userParameter) {
            setUserParameterToEdit(userParameter)
        } else {
            setUserParameterToEdit('')
        }
        setIsEditOpen(bool)
    }

    const saveUpdatedUser = (updatedUser: Customer) => {
        dispatch(AuthenticationActions.updateUser(updatedUser))
        setIsEditOpen(false)
        setUserParameterToEdit('')
    }

    const isConnectorWalletEnabled = (): Boolean => {
        if (selectedLocation && selectedLocation.connector && selectedLocation.connector.type) {
            if (CONNECTORS_COMPATIBLE_CONNECTOR_WALLET.includes(selectedLocation.connector.type)) {
                return true
            }
        }
        return false
    }

    if (!selectedLocation) {
        return null;
    }

    return (
        <Box bgcolor="background.default" height={1} width={1}>

            {isEditOpen
                && userParameterToEdit
                && data.user_authentication_state.user
                &&
                <UpdateUserInfos
                    open={isEditOpen}
                    handleOpen={handleIsEditOpen}
                    userParameter={userParameterToEdit}
                    customer={data.user_authentication_state.user}
                    onSave={saveUpdatedUser}
                />
            }

            <Box
                px={4}
                py={3}
                display="flex"
                justifyContent="space-between"
                bgcolor='background.paper'
            >

                {
                    // This icon is invisble but needed to center the text
                }
                <Close style={{ opacity: 0 }} />

                <Typography variant="h2" color="textSecondary">
                    {intl.formatMessage({ id: "auth.my_account.my_account" })}
                </Typography>

                <Box display="flex" alignItems="center">
                    <IconButton
                        style={{ padding: 0 }}
                        aria-label="Close box"
                        data-test="close-page"
                        onClick={() => {

                            // This check tells us if there is something in the history, an URL
                            // to goBack. Actually, this condition is false if we loaded directly
                            // the page with the URL /myinformations or if we just hit refresh
                            if (history.action !== 'POP') {

                                history.goBack();
                            }
                            else {

                                history.push(ROUTES.getCategoriesFullRoute(tableLinkId))
                            }

                            dispatch(OrderAction.closeModal());
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </Box>

            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent='center'
            >

                { // Loyalty

                    isUserLoggedIn(data.user_authentication_state)
                    && selectedLocation?.loyalty
                    && !selectedLocation?.loyalty.disabled
                    &&

                    <Box
                        bgcolor='background.paper'
                        mt={3}
                        p={3}
                    >

                        <Typography color="secondary" variant="subtitle1">
                            {intl.formatMessage({ id: "loyalty.type.loyalty" })}
                        </Typography>

                        <Box
                            marginTop={1}
                            marginBottom={.5}
                            display="flex"
                            alignItems="center"
                            gap={2}
                            bgcolor="primary.main"
                            borderRadius="10px"
                            p={1}
                            pl={2}
                            color='primary.contrastText'
                            height='100px'
                        >

                            <LocalActivityOutlinedIcon
                                sx={{
                                    height: '45px',
                                    width: '45px',
                                }} />

                            <Box flex={1}>
                                <Typography variant='caption'>
                                    {intl.formatMessage({
                                        id: "loyalty_page.card.title"
                                    }, {
                                        a: estimatedLoyaltyConfigContent?.isCashback ?
                                            intl.formatMessage({ id: "loyalty.type.cashback" }).toLowerCase()
                                            :
                                            intl.formatMessage({ id: "loyalty.type.loyalty" }).toLowerCase()
                                    })}
                                </Typography>
                                <Typography variant='h5'>
                                    {getLoyaltyCurrentBalance(
                                        data.user_authentication_state.user?.loyalty_balance,
                                        selectedLocation.loyalty,
                                        estimatedLoyaltyConfigContent,
                                        intl,
                                    )}
                                </Typography>
                            </Box>

                            <Box alignSelf='start'>
                                <LoyaltyExplaination
                                    useIcon
                                />
                            </Box>

                        </Box>
                    </Box>
                }

                {
                    // WALLET
                    isConnectorWalletEnabled()
                    && isUserLoggedIn(data.user_authentication_state) &&
                    <Box
                        bgcolor='background.paper'
                        mt={3}
                        p={3}
                    >
                        <Typography color="secondary" variant="subtitle1">
                            {intl.formatMessage({ id: "wallet.title" })}
                        </Typography>

                        <Box
                            marginTop={1}
                            display="flex"
                            alignItems="center"
                            gap={2}
                            bgcolor="primary.main"
                            borderRadius="10px"
                            pl={2}
                            color='primary.contrastText'
                            height='100px'
                        >

                            <AccountBalanceWalletIcon
                                sx={{
                                    height: '45px',
                                    width: '45px',
                                }}
                            />

                            <Box flex={1} data-test="wallet_balance_information">
                                <Typography variant='caption'>
                                    {intl.formatMessage({
                                        id: "wallet.page.title.explanation"
                                    })}
                                </Typography>
                                <Typography variant="h5" data-test="wallet_balance_amount">
                                    {MoneyToStringWithSymbol(walletBalance ?? numberToMoney(0, selectedLocation?.currency ?? DEFAULT_CURRENCY))}
                                </Typography>

                            </Box>
                        </Box>
                        <Box
                        >
                            <PaymentWalletBottomButtons />
                        </Box>
                    </ Box>
                }

                {
                    // Account orders
                    login &&
                    initOrdersState['latestUserOrders'][0] &&
                    <Box
                        mt={3}
                        p={3}
                        bgcolor='background.paper'
                    >

                        <Box
                            display='flex'
                            justifyContent='space-between'
                            mb={2}
                        >
                            <Typography variant="subtitle1" >
                                {intl.formatMessage({ id: "auth.my_account.my_last_order" })}
                            </Typography>
                            <Button
                                onClick={() => history.push(generatePath(ROUTES.LocationHome + ROUTES.OrderHistory, { tableLinkKey: tableLinkId }))}
                                sx={{
                                    padding: 0
                                }}
                            >
                                <Typography variant="caption" color="primary">
                                    {intl.formatMessage({ id: 'common.see_more' })}
                                </Typography>
                            </Button>
                        </Box>

                        <Box
                            onClick={() => {
                                const goToTableLinkId = initOrdersState['latestUserOrders'][0].table_link_id || tableLinkId;
                                history.push(`/${goToTableLinkId}${ROUTES.OrderSummaryPage}?${ORDER_ID_QUERY_PARAM_NAME}=${initOrdersState['latestUserOrders'][0].id}`)
                            }}
                            sx={{ cursor: 'pointer' }}
                        >
                            <InfoOrderDisplay
                                selectedOrder={initOrdersState['latestUserOrders'][0]}
                            />
                        </Box>
                    </Box>
                }

                {isEdenredEnabled &&
                    <RestaurantTicketButton
                        paymentType={PaymentType.EDENRED}
                        edenredIsLogged={edenredIsLogged}
                        isEdenredEnabled={isEdenredEnabled}
                        isSwileEnabled={isSwileEnabled}
                        setWantedToLogoutEdenred={setWantedToLogoutEdenred}
                        swileIsLogged={swileIsLogged}
                    />
                }
                {isSwileEnabled &&
                    <RestaurantTicketButton
                        paymentType={PaymentType.SWILE}
                        edenredIsLogged={edenredIsLogged}
                        isEdenredEnabled={isEdenredEnabled}
                        isSwileEnabled={isSwileEnabled}
                        setWantedToLogoutEdenred={setWantedToLogoutEdenred}
                        swileIsLogged={swileIsLogged}
                    />
                }


                { // Account info

                    <Box
                        mt={3}
                        p={3}
                        bgcolor={theme.palette.background.paper}
                    >

                        <Typography color="secondary" variant="subtitle1">
                            {intl.formatMessage({ id: "auth.my_account.my_informations" })}
                        </Typography>

                        {
                            login && !data.user_authentication_state.is_anonymous &&

                            <Box>

                                {// Last name
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        mt={2}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                        >
                                            <Typography variant="subtitle1">
                                                {intl.formatMessage({ id: 'common.last_name' })}
                                            </Typography>
                                            {data.user_authentication_state.user?.last_name &&
                                                <Typography>
                                                    {data.user_authentication_state.user.last_name}
                                                </Typography>
                                            }
                                        </Box>
                                        <IconButton
                                            onClick={() => handleIsEditOpen(true, 'last_name')}
                                        >
                                            <EditRoundedIcon color='primary' />
                                        </IconButton>
                                    </Box>
                                }

                                {// First name
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        mt={2}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                        >
                                            <Typography variant="subtitle1">
                                                {intl.formatMessage({ id: 'common.first_name' })}
                                            </Typography>
                                            {data.user_authentication_state.user?.first_name &&
                                                <Typography>
                                                    {data.user_authentication_state.user.first_name}
                                                </Typography>
                                            }
                                        </Box>
                                        <IconButton
                                            onClick={() => handleIsEditOpen(true, 'first_name')}
                                        >
                                            <EditRoundedIcon color='primary' />
                                        </IconButton>
                                    </Box>
                                }

                                { // email
                                    data.user_authentication_state.user !== null
                                        && data.user_authentication_state.user.email
                                        && checkEmailRegex(data.user_authentication_state.user.email) ? (

                                        <Box mt={2}>

                                            <Typography variant="subtitle1" color="textSecondary">
                                                {intl.formatMessage({ id: "auth.my_account.email.inputplaceholder" })}
                                            </Typography>

                                            <Typography data-test="auth.my_account.email">
                                                {data?.user_authentication_state?.user?.email}
                                            </Typography>
                                        </Box>

                                    ) : ""
                                }

                                {// Phone
                                    data.user_authentication_state.user?.phone &&
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        mt={2}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                        >
                                            <Typography variant="subtitle1">
                                                {intl.formatMessage({ id: 'common.phone' })}
                                            </Typography>
                                            {data.user_authentication_state.user?.phone &&
                                                <Typography>
                                                    {data.user_authentication_state.user.phone}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                }

                                {
                                    // password
                                }
                                {data.user_authentication_state.provider === SignInProviders.PASSWORD &&
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        mt={2}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {intl.formatMessage({ id: "auth.my_account.password.inputplaceholder" })}
                                        </Typography>

                                        {!showPasswordResetSuccess &&
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => { setShowPasswordResetBox(true) }}
                                                disabled={showPasswordResetBox}
                                            >
                                                <Typography>
                                                    {intl.formatMessage({ id: "auth.my_account.password.reset" })}
                                                </Typography>
                                            </Button>

                                        }

                                        {showPasswordResetSuccess &&

                                            <Box mt={2}>
                                                <Typography variant="body1" color="primary">
                                                    {intl.formatMessage({ id: "auth.my_account.password.success" })}
                                                </Typography>
                                            </Box>

                                        }

                                        {!showPasswordResetSuccess && showPasswordResetBox &&

                                            <Box mt={1}>
                                                <Typography>
                                                    {intl.formatMessage({ id: "auth.my_account.password.helper" })}
                                                </Typography>

                                                <Box mt={1} display="flex">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => { setShowPasswordResetBox(false) }}
                                                        style={{ marginRight: theme.spacing(1) }}
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            style={{ textTransform: "none" }}
                                                        >
                                                            {intl.formatMessage({ id: "cancel" })}
                                                        </Typography>
                                                    </Button>

                                                    <Button variant="outlined" color="primary">
                                                        <Typography
                                                            variant="caption"
                                                            style={{ textTransform: "none" }}
                                                            onClick={resetPassword}
                                                        >
                                                            {intl.formatMessage({ id: "send" })}
                                                        </Typography>
                                                    </Button>

                                                </Box>

                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                        }

                        <Box
                            width={1}
                            mx='auto'
                            mt={(login && !data.user_authentication_state.is_anonymous) ? 3 : 1}
                        >

                            {
                                // Logout button
                                login && !data.user_authentication_state.is_anonymous &&

                                <Button
                                    variant="text"
                                    style={{
                                        textTransform: "none",
                                        color: theme.palette.text.disabled,
                                        width: "100%",
                                    }}
                                    onClick={logoutFunction}
                                    data-test="myinformations_logout_button"
                                >

                                    <ExitToAppIcon style={{ marginRight: theme.spacing(1) }} />

                                    <Typography>
                                        {intl.formatMessage({ id: "auth.my_account.logout" })}
                                    </Typography>

                                </Button>
                            }

                            {
                                // Login button

                                // Checking if the user is not logged in AND if there is a login process activated
                                // from the backoffice
                                !(login && !data.user_authentication_state.is_anonymous)
                                &&

                                <Box display="flex" flexDirection="column">

                                    <Box color={theme.palette.text.disabled} marginBottom={1}>
                                        <Typography variant="caption" >
                                            {intl.formatMessage({
                                                id: "authentication.authentication_form.welcomAuthentication.signup_invitation"
                                            }, {
                                                a: intl.formatMessage({ id: "authentication.authentication_form.welcomAuthentication.signin" })
                                            })}
                                        </Typography>
                                    </Box>

                                    <Box width={1} display="flex" justifyContent="center">
                                        <Button
                                            data-test={"myinformations_signin_button"}
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                textTransform: "none",
                                                width: "80%",
                                                padding: theme.spacing(1, 0),
                                            }}
                                            onClick={loginFunction}
                                        >

                                            <Typography variant="subtitle1">
                                                {intl.formatMessage({ id: "authentication.signin.title" })}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                }
            </Box>
        </Box >
    );
};
export default MyInformations;
