import { ACCOUNT_ROUTE } from "../../accounts/configs/AccountsApiRoutes";
import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

export const USER_ID_KEY = "userId";
export const USER_ID_PARAM = `:${USER_ID_KEY}`
export const EMAIL_KEY = "email";
export const EMAIL_PARAM = `:${EMAIL_KEY}`


export const ACCOUNT_USERS_ROUTE = `${ACCOUNT_ROUTE}/${MylemonadeScopes.USERS}`;
export const LOCATION_USERS_ROUTE = `${LOCATION_ROUTE}/${MylemonadeScopes.USERS}`;
export const LOCATION_USER_BY_ID_ROUTE = `${LOCATION_USERS_ROUTE}/${USER_ID_PARAM}`;
export const USER_ROUTE_ROOT = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.USERS}`;
export const CREATE_USER_WITH_EMAIL = `${USER_ROUTE_ROOT}/email`;
export const GET_USER_ROLES = `${USER_ROUTE_ROOT}/roles`;
export const GET_USER_LOCATIONS = `${USER_ROUTE_ROOT}/${MylemonadeScopes.LOCATIONS}`;
export const GET_USER_ACCOUNTS = `${USER_ROUTE_ROOT}/${MylemonadeScopes.ACCOUNTS}`;
export const GET_USER_ACCESS = `${USER_ROUTE_ROOT}/access`;
export const GET_CREATED_ACCOUNTS = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.USERS}/created-accounts`;

// Test routes
export const USER_ROUTE_ROOT_TEST = `${USER_ROUTE_ROOT}/test`;
export const GET_IS_CUSTOMER_CREATED = `${USER_ROUTE_ROOT_TEST}/is-customer-created`;
export const GET_IS_USER_CREATED = `${USER_ROUTE_ROOT_TEST}/is-user-created`;
export const GET_TEST_USER_TOKEN = `${USER_ROUTE_ROOT_TEST}/user-token/${USER_ID_PARAM}`;

const usersApiRoutes = {
    USER_ROUTE_ROOT,
    ACCOUNT_USERS_ROUTE,
    LOCATION_USERS_ROUTE,
    LOCATION_USER_BY_ID_ROUTE,
    CREATE_USER_WITH_EMAIL,
    GET_USER_ROLES,
    GET_USER_LOCATIONS,
    GET_USER_ACCOUNTS,
    GET_USER_ACCESS,
    GET_CREATED_ACCOUNTS,
    GET_IS_CUSTOMER_CREATED,
    GET_IS_USER_CREATED,
    GET_TEST_USER_TOKEN,
};
export default usersApiRoutes;