import { Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { addMoney, getCurrency, Money, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { getFabPrice } from '../../orders/helpers/FabOrderHelpers';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

interface FabSuggestionsProps {
    onClick: () => void,
    disabled?: boolean,
    listTotal: Money
}

const FabSuggestions: React.FC<FabSuggestionsProps> = (props) => {

    const {
        onClick,
        disabled,
        listTotal
    } = props

    const { order } = useTypedSelector((state: RootState) => state.order)
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    const theme = useTheme();
    const intl = useIntl();

    const totalPrice = (): Money | undefined => {
        const currency = getCurrency(order.total)
        const currentOrderTotal = getFabPrice(selectedLocation, order, currency)

        if (getCurrency(listTotal) === currency) {
            const newTotal = addMoney(listTotal, currentOrderTotal)
            return MoneyToStringWithSymbol(newTotal)
        }

        return undefined
    }

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            data-test="order_orderButton"
            disableElevation
            onClick={() => onClick()}
            style={{
                textTransform: "none",
                padding: theme.spacing(1.5, 2),
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
            }}
        >
            <Typography variant='h5' style={{ marginLeft: theme.spacing(1) }}>
                {intl.formatMessage({ id: 'suggestion.add_to_order' })}
            </Typography>

            {totalPrice() &&
                <Typography sx={{ marginLeft: theme.spacing(2) }}>
                    {totalPrice()}
                </Typography>
            }
        </Button>
    )
}

export default FabSuggestions

