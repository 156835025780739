import { firestore } from "firebase/app";
import log from "loglevel";
import { all, call, select, takeLatest } from "redux-saga/effects";
import { firebaseApp } from "../../config/firebase";
import LocationState from "../../Locations/models/LocationState";
import { getSessionFirestoreDocPath } from "../../my-lemonade-library/src/sessions/services/SessionService";
import { RootState } from "../../redux/root-reducer";
import { addUrlToNavigationHistoryAction, ADD_URL_TO_NAVIGATION_HISTORY } from "./CommonActions";

const db = firebaseApp.firestore();

function* addUrlToNavigationHistory(action: addUrlToNavigationHistoryAction) {

    const { selectedLocation, sessionId }: LocationState = yield select((state: RootState) => state.locations);
    if (!selectedLocation || !sessionId) {
        log.error(`SessionId or location not found, cannot add URL ${action.payload.url} to navigation history in DB.`);
        return;
    }

    yield call(addUrlToSessionDocument, selectedLocation.account_id, selectedLocation.id, sessionId, action.payload.url);
    log.debug(`URL ${action.payload.url} added to navigation history in DB.`);
}

const addUrlToSessionDocument = async (accountId: string, locationId: string, sessionId: string, url: string) => {

    const sessionDoc = db.doc(getSessionFirestoreDocPath(accountId, locationId, sessionId));
    await sessionDoc.update({
        navigation_history_urls: firestore.FieldValue.arrayUnion({ url: url, date: new Date() }),
    });
}

export default function* commonSaga() {
    yield all([
        takeLatest(ADD_URL_TO_NAVIGATION_HISTORY, addUrlToNavigationHistory),
    ]);
}