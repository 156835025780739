import { all } from 'redux-saga/effects';
import { authenticationSaga } from '../authentication/redux/AuthenticationSaga';
import commonSaga from '../Common/redux/CommonSaga';
import locationsSaga from '../Locations/redux/LocationSagas';
import orderSaga from '../orders/redux/OrderSagas';
import paymentsSaga from '../Payment/redux/PaymentsSaga';
import { reviewSaga } from '../reviews/redux/ReviewSaga';
import { tablesSaga } from '../tables/redux/TableSaga';
import translationsSaga from '../translations/redux/sagas';
import { appSaga } from './app/AppSaga';

export default function* rootSaga(getState) {
    yield all([
        locationsSaga(),
        orderSaga(),
        translationsSaga(),
        authenticationSaga(),
        tablesSaga(),
        paymentsSaga(),
        appSaga(),
        reviewSaga(),
        commonSaga(),
    ]);
}
