import log from "../../Common/services/LogService";
import { extractDateFromTimestamp } from "../../config/firebase";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";

/**
 * Update the customer date fields (last order date, etc) when a customer is retrieved from firestore
 * Indeed, fields are retrieved in firestore timestamp format
 * @param customerInfo 
 * @param location 
 */
export const updateDateFieldsInFirestoreCustomer = (customer: Customer, timezoneName: string): Customer => {
    if (customer.first_order_date) {
        const extractedDate = extractDateFromTimestamp(customer.first_order_date, timezoneName);
        if (extractedDate) {
            customer.first_order_date = extractedDate;
        } else {
            log.debug(`Invalid first order date for location customer ${customer.uid}`);
            delete customer.first_order_date;
        }
    }
    if (customer.last_order_date) {
        const extractedDate = extractDateFromTimestamp(customer.last_order_date, timezoneName);
        if (extractedDate) {
            customer.last_order_date = extractedDate;
        } else {
            log.debug(`Invalid last order date for location customer ${customer.uid}`);
            delete customer.last_order_date;
        }
    }
    return customer;
}