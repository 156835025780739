import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ThemeSvg from '../../Common/components/ThemeSvg';
import { cursor } from '../../Common/StyledComponents';
import * as ROUTES from '../../config/routes';
import { desktopDisplayBreakpoint } from '../../config/theme';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import orderActions from '../redux/OrderActions';

interface PaymentInProgressPageProps {
}

const PaymentInProgressPage: React.FC<PaymentInProgressPageProps> = (props) => {

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const { tableLinkId } = useTypedSelector((state: RootState) => state.locations);

    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));

    return (
        <Box
            bgcolor="background.paper"
            height={1}
            width={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            py={isDesktopRender ? 5 : 4}
            px={isDesktopRender ? 15 : 4}
        >

            {
                // CENTER CONTENT (GLASSES, MAIN TEXT, SECONDARY TEXT)
            }
            < Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
                px={4}
            >
                <Box mt={5} display="flex" justifyContent="center">
                    <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/valid_order.svg`}
                        maxWidth={"60%"}
                    />
                </Box>

                <Box mt={5}>

                    <Box mx={3}>

                        <Typography variant="h2" style={{ textAlign: "center" }}>

                            {intl.formatMessage({ id: "orders.payment_in_progress.title" })}

                        </Typography>

                    </Box>

                </Box>

                <Box mt={4} px={2}>

                    <Typography
                        style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "center"
                        }}
                    >

                        {intl.formatMessage({ id: "orders.payment_in_progress.description" })}

                    </Typography>

                </Box>

            </Box>

            {
                // BOTTOM CONTENT: BUTTON & LINK
            }
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                px={2}
                mt={isDesktopRender ? 6 : 0}
            >

                <Typography
                    variant="subtitle1"
                    style={{
                        textDecoration: "underline",
                        ...cursor,
                        marginBottom: theme.spacing(1.5),
                        color: theme.palette.text.disabled,
                    }}
                    onClick={() => {

                        // Reset the order, create a new one to firestore and go back to the catalog
                        dispatch(orderActions.resetOrder());
                        dispatch(orderActions.createDraftOrder());
                        history.push(ROUTES.getCategoriesFullRoute(tableLinkId))
                    }}
                >

                    {intl.formatMessage({ id: "orders.multi_users.landing.new_order" })}

                </Typography>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        padding: theme.spacing(1.45, 0),
                        width: "65%",
                        textTransform: "none",
                    }}
                    data-test="preparation_backToCatalog"
                    onClick={() => {

                        // Go to pickup info page
                        history.push(`/${tableLinkId}${ROUTES.PaymentCollectionInfo}`);
                    }}
                >

                    <Typography>

                        {intl.formatMessage({ id: "order.summary.button.continue" })}

                    </Typography>

                </Button>

            </Box>

        </Box>
    );
}

export default PaymentInProgressPage;