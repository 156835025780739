import _ from 'lodash';
import log from '../../Common/services/LogService';
import { Catalog } from '../../my-lemonade-library/model/Catalog';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { Location, Table } from '../../my-lemonade-library/model/Location';
import { Order, OrderInBase, OrderItem, OrderStatus } from '../../my-lemonade-library/model/Order';
import { getCurrency, moneyToNumber, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { getDealLineRef } from '../../my-lemonade-library/src/deals/services/DealHelper';
import { OrderPriceArg } from '../../my-lemonade-library/src/orders/models/OrderPriceArg';
import { orderPrice } from '../../my-lemonade-library/src/orders/services/OrderPricing';
import productService from '../../my-lemonade-library/src/products/services/ProductService';
import { ADD_DEAL_ITEM, DealsActionTypes, INIT_DEAL, RESET_DEAL_LIST_ITEMS } from './actions';

interface DealItemState {
    listItems: OrderItem[];
    dealPrice: number;
    dealRef: string;
}
const dealListItemsDefault: DealItemState = {
    listItems: [],
    dealPrice: 0,
    dealRef: "",
};

export default function reducer(
    state = dealListItemsDefault,
    action: DealsActionTypes,
): DealItemState {

    switch (action.type) {

        case INIT_DEAL:
            const initListItems: OrderItem[] = [];
            const deal = action.payload.deal;
            deal?.lines.forEach((line, index) => {
                // If only one choice, select it by default
                if (line.skus.length === 1) {
                    const onlySku = line.skus[0];
                    const product = productService.getProductBasedOnSkuRef(onlySku.ref, action.payload.catalog);
                    if (product) {
                        const orderItem: OrderItem = {
                            product_ref: product.ref,
                            product_name: product.name,
                            sku_ref: onlySku.ref,
                            quantity: 1,
                            options: [],  // Don't prefill any options here as the user wants to add them step by step
                            price: line.pricing_value as string,
                            deal_line: {
                                deal_key: deal.ref,
                                deal_line_ref: getDealLineRef(deal, index),
                                deal_line_index: index
                            }
                        }
                        initListItems.push(orderItem);
                    } else {
                        log.error(`Product not found for deal sku ${onlySku.ref}`);
                    }
                }
            });

            const finalPrice = action.payload.deal ? computeDealMinimumPrice(
                action.payload.deal,
                initListItems,
                action.payload.order,
                action.payload.location,
                action.payload.catalog,
                action.payload.table,
            ) : 0;

            return {
                ...state,
                dealPrice: finalPrice,
                listItems: initListItems,
                dealRef: action.payload.deal ? action.payload.deal.ref : ""
            }

        case ADD_DEAL_ITEM:
            const { payload } = action;
            const itemsList = state.listItems;
            const nItemsList = itemsList.filter(
                (item) =>
                    item.deal_line?.deal_line_ref !== payload.orderItem.deal_line?.deal_line_ref,
            );

            nItemsList.push(payload.orderItem);

            const dealPrice = computeDealMinimumPrice(
                action.payload.deal,
                nItemsList,
                action.payload.order,
                action.payload.location,
                action.payload.catalog,
                action.payload.table,
            );

            return {
                ...state,
                listItems: nItemsList,
                dealPrice: dealPrice,
                dealRef: payload.deal.ref,
            };

        case RESET_DEAL_LIST_ITEMS:
            return dealListItemsDefault;

        default:
            return state;
    }
}

// TODO: test it!
/**
 * Launch an orderPrice on an empty order with only the deal provided to
 * get its final price.
 * // TODO: test it!
 * @param deal 
 * @param dealItems 
 * @param order 
 * @param location 
 * @param catalog 
 * @param table 
 * @returns 
 */
export const computeDealMinimumPrice = (
    deal: DealExt,
    dealItems: OrderItem[],
    order: OrderInBase,
    location: Location,
    catalog: Catalog,
    table: Table,
): number => {

    // Clone it to be sure not to modify existing items, we just want to compute a price
    const clonedItemsList = _.cloneDeep(dealItems);

    const TEMP_DEAL_KEY = "0";

    // Make sure that the deal is complete before launching orderPrice. It means that if there are 4
    // lines in the deal and we have only 3 items provided at this step, we'll add a 4th one based on
    // the minimum price that we can reach for this line.
    deal.lines.forEach((line, lineIndex) => {

        let foundItem = false;

        line.skus.forEach((sku) => {
            const foundInProvidedList = clonedItemsList.find(oi => oi.sku_ref === sku.ref);
            if (foundInProvidedList) {
                foundItem = true;
                foundInProvidedList.deal_line = {
                    deal_key: TEMP_DEAL_KEY,
                    deal_line_index: lineIndex,
                    deal_line_ref: getDealLineRef(deal, lineIndex),
                }
            }
        })

        // If we couldn't find the order item, add one with minimum price
        if (!foundItem && deal.min_price_skus) {
            const itemToPush = _.cloneDeep(deal.min_price_skus[lineIndex]);
            itemToPush.deal_line = {
                deal_key: TEMP_DEAL_KEY,
                deal_line_index: lineIndex,
                deal_line_ref: getDealLineRef(deal, lineIndex),
            }
            clonedItemsList.push(itemToPush);
        }
    });

    // At this step our deal should be complete and ready to be priced.
    log.debug(`computeDealMinimumPrice: ${clonedItemsList.length} items to price`, { deal, clonedItemsList });

    if (clonedItemsList.length !== deal.lines.length) {
        log.error(`computeDealMinimumPrice: ${clonedItemsList.length} items to price but deal is composed of ${deal.lines.length} lines. Cannot compute, return 0`, { deal, clonedItemsList });
        return 0;
    }

    const tempOrder: Order = {
        id: '',
        display_id: '',
        collection_code: '',
        total: numberToMoney(0, getCurrency(order.total)),
        status: OrderStatus.NEW,
        service_type: order.service_type,
        deals: {
            [TEMP_DEAL_KEY]: {
                name: deal.name,
                ref: deal.ref,
                price: numberToMoney(0, getCurrency(order.total)),  // Will be computed later
            },
        },
        items: clonedItemsList,
        expected_time: order.expected_time ?? new Date(),
        end_preparation_time: order.end_preparation_time ?? new Date(),
    };

    let finalPrice = 0;
    try {

        const orderPriceArg: OrderPriceArg = {
            order: tempOrder,
            catalog,
            location,
            table,
            disableExpectedTimeResetForEatin: false,
            checkMinAmount: false,
            patchOrder: true,
            doNotThrow: true,
        }

        const result = orderPrice(orderPriceArg);
        finalPrice = result.price;

        const foundDealInOrder = tempOrder.deals[TEMP_DEAL_KEY];
        if (foundDealInOrder?.price_with_options) {
            finalPrice = moneyToNumber(foundDealInOrder.price_with_options, false, foundDealInOrder);
        }

    } catch (error) {
        log.error(`computeDealMinimumPrice: cannot price deal`, { deal, error });
    }

    return finalPrice;
}
