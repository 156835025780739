import { Terms } from "../../my-lemonade-library/src/authentications/models/Terms";
import DeploymentInfo from "../../my-lemonade-library/src/common/models/DeploymentInfo";
import { AppActionTypes, FETCH_DEPLOYMENT_SUCCESS } from "./AppActions";

export interface AppState {
    version: string | undefined;
    commitShortSha: string | undefined;
    deployment: DeploymentInfo | null;
    terms: Terms | null;
}
const initialAppState: AppState = {
    version: process.env.REACT_APP_VERSION,
    commitShortSha: process.env.REACT_APP_COMMIT_SHORT_SHA,
    deployment: null,
    terms: null
};

export const appReducer = function (
    state = initialAppState,
    action: AppActionTypes,
): AppState {
    switch (action.type) {
        case FETCH_DEPLOYMENT_SUCCESS: {

            return {
                ...state,
                ...action.payload
            };
        }

        default:
            return state
    }
}
