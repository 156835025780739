import { Box, Typography, useTheme } from "@mui/material";
import log from "loglevel";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import RoundedCheckBox from "../../Common/components/CustomComponents/RoundedCheckBox";
import { loyaltyActions } from "../../loyalty/redux/LoyaltyActions";
import { OrderInBase } from "../../my-lemonade-library/model/Order";
import { moneyToNumber, MoneyToStringWithSymbol } from "../../my-lemonade-library/src/common/models/Money";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { isLocalLoyaltyOnly } from "../helpers/LoyaltyHelpers";
import { getLoyaltyChoiceForOrderAndCustomer } from "../helpers/OrderHelpers";
import { OrderSummaryButtonActionTypes } from "../models/OrderButtonsModels";
import orderActions from "../redux/OrderActions";
import LoyaltyExplaination from "./LoyaltyExplaination";

interface LoyaltySummaryProps {
    selectedOrder: OrderInBase | null | undefined,
    readOnly?: boolean,
    orderButtonActionType: OrderSummaryButtonActionTypes,
    localLoyaltyEnabled?: boolean,  // Set to true if you only want the "use points" checkbox
}

const LoyaltySummary: React.FC<LoyaltySummaryProps> = (props) => {

    const { selectedOrder, readOnly, localLoyaltyEnabled } = props;

    const { selectedLocation, selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { user_authentication_state } = data;
    const { user } = user_authentication_state;
    const { estimatedLoyaltyConfigContent, estimatedLoyaltyOrderContent, use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    /**
     * Apply the loyalty discount
     * @param checked pay'
     */
    const onChangeLoyaltyCheckbox = (checked: boolean): void => {

        if (!selectedLocation || !selectedCatalog) {
            return;
        }

        if (!user?.uid) {
            log.error("Cannot set loyalty usage: user UID not found in state");
            return;
        }

        if (selectedOrder && isLocalLoyaltyOnly(selectedOrder, selectedLocation.enable_share_payment)) {
            dispatch(loyaltyActions.toggleLocalLoyaltyUsePoints(checked));
        }
        else {
            dispatch(orderActions.updateLoyaltyPointsUsage(
                user.uid,
                checked,
                selectedCatalog,
                selectedLocation,
                selectedTable,
            ));
        }
    }

    /**
     * Become the loyalty master user
     * @param checked 
     */
    const onChangeLoyaltyUserIdCheckbox = (checked: boolean) => {

        if (user?.uid && checked && isUserLoggedIn(user_authentication_state)) {

            dispatch(orderActions.setLoyaltyUserId(user.uid));
        }
    }

    if (
        !selectedOrder
        || !selectedLocation
        || !selectedCatalog
        || !selectedOrder.loyalty_config
        || selectedOrder.loyalty_config.disabled
    ) {
        return null;
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            marginTop={localLoyaltyEnabled ? 0 : 2}
            paddingY={localLoyaltyEnabled ? 0 : 1}
            paddingX={localLoyaltyEnabled ? 0 : 3}
            bgcolor={theme.palette.background.paper}
            width={1}
            data-test="loyalty-summary.root"
        >
            {!localLoyaltyEnabled &&
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="subtitle1" color="textPrimary">
                            {intl.formatMessage({ id: "summary.loyalty.earned_advantages" })}
                        </Typography>

                        <Typography data-test="loyalty-summary.how_much_earned">
                            {estimatedLoyaltyOrderContent?.howMuchEarned ?? "--"}
                        </Typography>
                    </Box>

                    <LoyaltyExplaination />
                </>
            }

            {
                !readOnly
                && !localLoyaltyEnabled
                && selectedOrder.contributors
                && selectedOrder.contributors.length > 1
                &&

                <Box
                    display="flex"
                    marginTop={1.2}
                >

                    <RoundedCheckBox
                        style={{
                            paddingLeft: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: "fit-content",
                        }}
                        color="primary"
                        onChange={(_, checked) => onChangeLoyaltyUserIdCheckbox(checked)}
                        checked={user !== undefined && user !== null && (user.uid === selectedOrder.loyalty_user_id)}
                        disabled={readOnly}
                    />

                    <Box
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                    >

                        <Typography variant="subtitle1" color="textPrimary">
                            {intl.formatMessage({ id: "summary.loyalty.master_user" })}
                        </Typography>

                        <Typography
                            variant="caption"
                            style={{
                                color: theme.palette.text.disabled,
                                lineHeight: "1rem",
                            }}
                        >
                            {intl.formatMessage({ id: "summary.loyalty.master_user.explaination" })}
                        </Typography>

                    </Box>

                </Box>
            }

            {
                (user?.uid && !user_authentication_state.is_anonymous)
                && estimatedLoyaltyOrderContent?.priceOff
                && moneyToNumber(estimatedLoyaltyOrderContent.priceOff) > 0
                && (
                    estimatedLoyaltyConfigContent?.isPaymentsOnly
                    || (// Not in a earning payment only mode: display only if user is loyalty master user
                        !readOnly
                        && user.uid === selectedOrder.loyalty_user_id
                    )
                )
                // Do not display on summary page if we're managing the loyalty locally (for a payment for ex)
                && (localLoyaltyEnabled || !isLocalLoyaltyOnly(selectedOrder, selectedLocation.enable_share_payment))
                &&

                <Box
                    display="flex"
                    marginTop={1.2}
                    data-test="loyalty-summary.use_points.root"
                >

                    <RoundedCheckBox
                        style={{
                            paddingLeft: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: "fit-content",
                        }}
                        color="primary"
                        onChange={(_, checked) => onChangeLoyaltyCheckbox(checked)}
                        checked={getLoyaltyChoiceForOrderAndCustomer(selectedOrder, user.uid as string, selectedLocation.enable_share_payment, localLoyaltyUsePoints)}
                    />

                    <Box flexGrow={1}>
                        <Typography variant="subtitle1" color="textPrimary">
                            {intl.formatMessage({ id: "summary.loyalty.apply_the_discount" })}
                        </Typography>
                    </Box>

                    <Typography variant="subtitle1" color="textPrimary" data-test="loyalty-summary.use_points.amount">
                        {MoneyToStringWithSymbol(estimatedLoyaltyOrderContent.priceOff)}
                    </Typography>
                </Box>
            }

        </Box>
    );
}

export default LoyaltySummary;