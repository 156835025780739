import { CloudDownload } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import log from "loglevel";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import LoaderComponent from "../../Common/components/LoaderComponent";
import { getDateStr } from "../../Common/helper/DateHelper";
import * as ROUTES from '../../config/routes';
import { desktopDisplayBreakpoint } from "../../config/theme";
import { OrderInBase, OrderStatus } from "../../my-lemonade-library/model/Order";
import { MoneyToStringWithSymbol } from "../../my-lemonade-library/src/common/models/Money";
import { qrcodeLinkKey } from "../../my-lemonade-library/src/qrcodes/models/OrderQrCodes";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { ORDER_ID_QUERY_PARAM_NAME } from "../configs/OrdersRouterConfig";
import { loadOrderLinkData } from "../helpers/OrderHelpers";
import orderActions from "../redux/OrderActions";

interface QrCodePageProps {

}

const QrCodePage: React.FC<QrCodePageProps> = (props) => {

    const history = useHistory();
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();

    const params = useParams<ROUTES.RouteParams>();
    const orderId = params[ORDER_ID_QUERY_PARAM_NAME];

    const [orderToDisplay, setOrderToDisplay] = useState<OrderInBase>();
    const [qrCode, setQrCode] = useState<string>();

    const { tableLinkId } = useTypedSelector((state: RootState) => state.locations);
    const { initOrders: latestOrders } = useTypedSelector((state: RootState) => state.order);

    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint))

    /**
     * Set the order to display and load the QR code for the order
     */
    useEffect(() => {

        // The order that will be displayed
        let finalOrder: OrderInBase | undefined = undefined;

        log.debug(`orderId: ${orderId}`);

        if (orderId) {

            /**
             * We have to search in the user past orders to retrieve the one 
             * with a matching ID
             */
            for (const elem of latestOrders.latestUserOrders) {

                if (elem.id === orderId) {

                    finalOrder = elem;
                    break;
                }
            }

            //TODO: if not found, try making a request to firebase to get the order by its ID
        }

        if (finalOrder) {

            setOrderToDisplay(finalOrder);

            // Load the QR Code
            if (finalOrder && finalOrder.items && finalOrder.items.length) {
                if (finalOrder.links && !qrCode) {
                    const qrCodeLink = finalOrder.links[qrcodeLinkKey];
                    if (qrCodeLink && qrCodeLink.href) {
                        log.debug(`Loading qr code: ${qrCodeLink.href}`);
                        loadOrderLinkData(qrCodeLink.href, setQrCode);
                    }
                }
            }
        }


    }, [latestOrders]);

    const handleQrCodeDownload = () => {

        if (qrCode && orderToDisplay) {
            const a = document.createElement("a");
            a.href = qrCode;
            a.download = `QrCode_${orderToDisplay.display_id}`;
            a.click();
        }
    }

    return (
        <Box
            bgcolor="background.paper"
            height={1}
            width={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
        >

            {
                // HEADER
            }
            <Box
                bgcolor="background.default"
                px={4}
                py={3}
                display="flex"
                justifyContent="space-between"
            >

                <Box display="flex" alignItems="center">
                    <IconButton
                        style={{ padding: 0 }}
                        aria-label="Close box"
                        onClick={() => {
                            history.goBack();
                        }}
                        size="large">
                        <ArrowBackIosIcon sx={{ color: theme.palette.secondary.main }} />
                    </IconButton>
                </Box>

                <Typography variant="h2" color="textSecondary">
                    {intl.formatMessage({ id: "orders.qrcode" })}
                </Typography>

                {
                    // This icon is invisble but needed to center the text
                }
                <ArrowBackIosIcon style={{ opacity: 0 }} />

            </Box>

            {
                // CONTENT
                orderToDisplay &&

                <Box
                    p={3}
                    pt={0}
                    display="flex"
                    flexDirection="column"
                    flex={1}
                >

                    {
                        // TEXT & QR CODE
                    }
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        flex={1}
                        px={5}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                        >

                            <Typography variant="h5" color="textSecondary">
                                {intl.formatMessage({ id: "orders.qrcode.showHint" })}
                            </Typography>

                            <Box mt={3} width="100%">

                                {
                                    qrCode &&

                                    <img
                                        src={qrCode}
                                        alt=""
                                        width={isDesktopRender ? "40%" : "80%"}
                                    />
                                }

                                {
                                    !qrCode &&
                                    <LoaderComponent />
                                }

                            </Box>
                        </Box>
                    </Box>

                    {
                        // ORDER INFOS, BUTTON
                    }

                    <Box display="flex" flexDirection="column" >

                        {
                            // ORDER INFOS
                        }
                        <Box display="flex" flexDirection="column" px={5} mb={3}>

                            {
                                // WARNING IF ORDER COMPLETED
                                orderToDisplay.status === OrderStatus.COMPLETED &&

                                <Box mb={3}>

                                    <Typography variant="body1" color="primary">

                                        {intl.formatMessage({ id: "orders.qrcode.completedWarning" })}

                                    </Typography>

                                </Box>
                            }

                            <Box display="flex" justifyContent="space-between">

                                <Typography variant="body1">
                                    {orderToDisplay.expected_time ? getDateStr(orderToDisplay.expected_time, intl)[0] : ""}
                                </Typography>

                                <Typography variant="body1">
                                    {orderToDisplay.expected_time ? getDateStr(orderToDisplay.expected_time, intl)[1] : ""}
                                </Typography>

                            </Box>

                            <Box display="flex" justifyContent="space-between" mt={1}>

                                <Typography variant="h5" color="textSecondary">
                                    {intl.formatMessage({ id: "Summary.total" })}
                                </Typography>

                                <Typography variant="h5" color="textSecondary">
                                    {MoneyToStringWithSymbol(orderToDisplay.total)}
                                </Typography>

                            </Box>

                        </Box>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleQrCodeDownload}
                            disabled={!qrCode}
                            style={{
                                padding: theme.spacing(1.45, 0),
                                width: "100%",
                            }}
                        >

                            <CloudDownload style={{ marginRight: theme.spacing(1) }} />

                            <Typography variant="h2">
                                {intl.formatMessage({ id: "orders.qrcode.download" })}
                            </Typography>

                        </Button>

                    </Box>

                </Box>
            }

            {
                !orderToDisplay &&

                <Box
                    flex={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    m={5}
                >
                    <Typography>
                        {intl.formatMessage({ id: "Payment.error" })}
                    </Typography>

                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            marginTop: theme.spacing(2),
                        }}
                        onClick={() => {

                            dispatch(orderActions.closeModal());
                            history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: tableLinkId }));
                        }}
                    >

                        <Typography variant="h5">

                            {intl.formatMessage({
                                id: "Summary.backToCatalog",
                                defaultMessage: "Back to catalog."
                            })}

                        </Typography>

                    </Button>

                </Box>
            }

        </Box>
    );
}

export default QrCodePage;