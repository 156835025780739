import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import * as ROUTES from '../../config/routes';
import LyfPaymentIntentInfos from '../../my-lemonade-library/src/payments/models/lyf/LyfPaymentIntentInfos';
import orderAction from '../../orders/redux/OrderActions';
import { useTypedSelector } from '../../redux/root-reducer';

interface PaymentLyfProps { }

const PaymentLyf: React.FC<PaymentLyfProps> = (props) => {

    const { order, payment_infos } = useTypedSelector(state => state.order);
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const match = useRouteMatch<ROUTES.RouteParams>();

    const returnHomePage = () => {

        dispatch(orderAction.closeModal());
        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
    }

    //Change page if order secret is not defined
    useEffect(() => {

        if (payment_infos && order) {
            const lyfPaymentInfo = payment_infos as LyfPaymentIntentInfos;
            if (lyfPaymentInfo.payment_url && window.top) {
                window.top.location.href = lyfPaymentInfo.payment_url;
            } else {
                returnHomePage();
            }
        } else {
            returnHomePage();
        }
    }, [payment_infos, order])


    return <Box
        py={3}
        px={5}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
    >

        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >

            <Box
                mt={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
            >

                <Typography variant="h2" color="textSecondary">

                    {intl.formatMessage({ id: "payments.page.redirect_payment" })}

                </Typography>

            </Box>

        </Box>


        <Button
            variant="contained"
            color="primary"
            style={{
                marginTop: theme.spacing(3),
                textTransform: "none",
            }}
            onClick={returnHomePage}
        >

            <Typography>
                {intl.formatMessage({ id: "returnHomePage" }, { br: <br /> })}
            </Typography>

        </Button>

    </Box>

}

export default PaymentLyf
