
export class OrderDiscountError implements Error {

    static DISCOUNT_WITHOUT_REF = new OrderDiscountError("DISCOUNT ERROR 001", "Discount can not be treated without ref", 500)
    static DISCOUNT_NOT_FOUND = new OrderDiscountError("DISCOUNT_NOT_FOUND", "Discount not found in catalog", 404)
    static DISCOUNT_NOT_AVAILABLE = new OrderDiscountError("DISCOUNT_NOT_AVAILABLE", "Discount not available", 400)
    static PRICE_OFF_DIFFERENT = new OrderDiscountError("PRICE_OFF_DIFFERENT", "Price off compute is different", 500)
    static VALUE_MISSING = new OrderDiscountError("VALUE_MISSING", "Values are missing to compute price off", 500)
    static TOO_MANY_DISCOUNT = new OrderDiscountError("TOO_MANY_DISCOUNT", "Only one discount is supported", 403)

    private value: any;
    message: string;
    name: string;

    private constructor(public code: string, message: string, public httpErrorCode: number) {
        this.name = code
        this.message = message;
        this.value = ""
        Object.setPrototypeOf(this, OrderDiscountError.prototype);
    }

    withValue(value: any): OrderDiscountError {
        this.value = value
        return this
    }

    getErrorMessage() {
        return this.value
    }

}