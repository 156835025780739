import * as H from 'history';
import { CustomerInformationFormParameters } from '../../customers/models/CustomerInformationFormParameters';
import { CustomerInformationModalFormToDisplay } from '../../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInfoFlowChoice } from "../../my-lemonade-library/model/Location";
import BaseUser, { SignInProviders } from '../../my-lemonade-library/src/authentications/models/BaseUser';
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import { AuthenticationErrorType } from "../models/AuthenticationErrorType";
import SignInAuthorizeParams from '../models/SignInAuthorizeParams';
import SignUpModel from "../models/SignUpModel";


export const CREATE_USER = "CREATE_USER"
export interface CreateUserAction {
    type: typeof CREATE_USER,
    payload: {
        userData: SignUpModel
        history?: H.History
    }
}

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export interface CreateUserSuccessAction {
    type: typeof CREATE_USER_SUCCESS,
}

export const CREATE_USER_SUCCESS_RESET = "CREATE_USER_SUCCESS_RESET"
export interface CreateUserSuccessResetAction {
    type: typeof CREATE_USER_SUCCESS_RESET,
}

export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export interface AuthenticateUserAction {
    type: typeof AUTHENTICATE_USER,
    payload: {
        authenticationOpensShareOrderModal?: boolean,
    }
}


export const SIGNIN_REDIRECT = "SIGNIN_REDIRECT"
export interface SignInRedirectAction {
    type: typeof SIGNIN_REDIRECT,
    payload: {
        signinProvider: SignInProviders;
        redirectPath: string | undefined;
    }
}

export const SIGNIN_AUTHORIZE_USER = "SIGNIN_AUTHORIZE_USER"
export interface SignInAuthorizeAction {
    type: typeof SIGNIN_AUTHORIZE_USER,
    payload: SignInAuthorizeParams
}

export const SIGNIN_WITH_CUSTOM_TOKEN = "SIGNIN_WITH_CUSTOM_TOKEN"
export interface SignInWithCustomTokenAction {
    type: typeof SIGNIN_WITH_CUSTOM_TOKEN,
    payload: {
        state: string;
        provider: SignInProviders;
        custom_token: string;
        customer: Customer;

        /**
         * Allow to redirect after oauth flow to a page different than the home page:
         * e.g. cart page
         */
        redirect_path?: string;

        /**
         * Null to close form
         */
        formToDisplay?: CustomerInformationModalFormToDisplay | null,

        formParameters?: CustomerInformationFormParameters
    }
}


export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS"
export interface AuthenticateUserSuccessAction {
    type: typeof AUTHENTICATE_USER_SUCCESS
    payload: {
        customer: Customer | null,
        registerOnLocation: boolean,
        user_email_verified: boolean | null,
        is_anonymous: boolean,
        provider?: string,
        withCustomToken?: boolean,
    }
}

export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR"
export interface AuthenticationErrorAction {
    type: typeof AUTHENTICATION_ERROR
    payload: {
        errorMessage: string,
        errorType?: AuthenticationErrorType
    }
}

export const LOAD_USER_INFO = "LOAD_USER_INFO"
export interface LoadUserInfoAction {
    type: typeof LOAD_USER_INFO
    payload?: H.History
}

export const SIGNOUT_USER = "SIGNOUT_USER"
export interface SignoutUserAction {
    type: typeof SIGNOUT_USER;
    payload: {
        doNotResetOrder?: boolean;
    }
}

export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS"
export interface SignoutUserSuccessAction {
    type: typeof SIGNOUT_USER_SUCCESS
}

export const AUTHENTICATION_LOCATION_PROCESS = "AUTHENTICATION_LOCATION_PROCESS"
export interface AuthenticationLocationProcessAction {
    type: typeof AUTHENTICATION_LOCATION_PROCESS
    payload: {
        authentication_process: CustomerInfoFlowChoice | null,
        authentication_mandatory: boolean | null,
        authentication_providers: SignInProviders[] | undefined,
        email_domains_allowed: string[] | null,
        customer_email_verified: boolean
    }
}

export const SEND_VERIFICATION_EMAIL = "SEND_VERIFICATION_EMAIL"
export interface SendVerificationEmailAction {
    type: typeof SEND_VERIFICATION_EMAIL
}

export const RESET_PASSWORD = 'RESET_PASSWORD'
export interface ResetPasswordAction {
    type: typeof RESET_PASSWORD
    payload: string
}
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export interface ResetPasswordSuccessAction {
    type: typeof RESET_PASSWORD_SUCCESS
}
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export interface ResetPasswordFailAction {
    type: typeof RESET_PASSWORD_FAIL
    payload: string
}

export const COMPLETE_USER_INFORMATION = 'COMPLETE_USER_INFORMATION'
export interface CompleteUserInformationAction {
    type: typeof COMPLETE_USER_INFORMATION
    payload: SignUpModel
}

export const CONTINUE_AS_GUEST = 'CONTINUE_AS_GUEST'
export interface ContinueAsGuestAction {
    type: typeof CONTINUE_AS_GUEST;
    payload: boolean;
}

export const RESET_GUEST_STATE = 'RESET_GUEST_STATE'
export interface ResetGuestStateAction {
    type: typeof RESET_GUEST_STATE;
}

export const SET_USER_LOYALTY_BALANCE = "SET_USER_LOYALTY_BALANCE"
export interface SetUserLoyaltyBalanceAction {
    type: typeof SET_USER_LOYALTY_BALANCE;
    payload: {
        newBalance: number | undefined,
    }
}

export const UPDATE_USER = 'UPDATE_USER'
export interface UpdateUserAction {
    type: typeof UPDATE_USER
    payload: {
        customer: Customer
    }
}

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export interface UpdateUserSuccessAction {
    type: typeof UPDATE_USER_SUCCESS
    payload: {
        customer: Customer
    }
}

export const FIREBASE_SIGNIN_SUCCESS = "FIREBASE_SIGNIN_SUCCESS";
export interface FirebaseSigninSuccessAction {
    type: typeof FIREBASE_SIGNIN_SUCCESS;
}

export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS";
export const ACCEPT_TERMS_ERROR = "ACCEPT_TERMS_ERROR";

export interface AcceptTermsAction {
    type: typeof ACCEPT_TERMS;
    payload: {
        isLoggedIn: boolean;
    }
}

export interface AcceptTermsSuccessAction {
    type: typeof ACCEPT_TERMS_SUCCESS;
    payload: {
        lastAcceptedTerms: BaseUser["last_accepted_terms"];
    }
}

export interface AcceptTermsErrorAction {
    type: typeof ACCEPT_TERMS_ERROR;
}

export type AuthenticationActionsType =
    CreateUserAction
    | AuthenticateUserAction
    | AuthenticateUserSuccessAction
    | SignInWithCustomTokenAction
    | SignInAuthorizeAction
    | SignoutUserAction
    | SignoutUserSuccessAction
    | AuthenticationErrorAction
    | LoadUserInfoAction
    | AuthenticationLocationProcessAction
    | SendVerificationEmailAction
    | ResetPasswordAction
    | CompleteUserInformationAction
    | CreateUserSuccessAction
    | CreateUserSuccessResetAction
    | ContinueAsGuestAction
    | ResetGuestStateAction
    | SetUserLoyaltyBalanceAction
    | ResetPasswordSuccessAction
    | ResetPasswordFailAction
    | UpdateUserAction
    | UpdateUserSuccessAction
    | FirebaseSigninSuccessAction
    | AcceptTermsAction
    | AcceptTermsSuccessAction
    | AcceptTermsErrorAction;

export const AuthenticationActions = {

    completeUserInformations: (userData: SignUpModel): CompleteUserInformationAction => {
        return {
            type: COMPLETE_USER_INFORMATION,
            payload: userData
        }
    },
    resetPassword: (email: string): ResetPasswordAction => {
        return {
            type: RESET_PASSWORD,
            payload: email
        }
    },
    resetPasswordSuccess: (): ResetPasswordSuccessAction => {
        return {
            type: RESET_PASSWORD_SUCCESS,
        }
    },
    resetPasswordFail: (msg: string): ResetPasswordFailAction => {
        return {
            type: RESET_PASSWORD_FAIL,
            payload: msg
        }
    },

    createUser: (userData: SignUpModel, history?: H.History): CreateUserAction => {
        return {
            type: CREATE_USER,
            payload: {
                userData: userData,
                history: history
            }
        }
    },
    createUserSuccess: (): CreateUserSuccessAction => {
        return {
            type: CREATE_USER_SUCCESS,
        }
    },
    createUserSuccessReset: (): CreateUserSuccessResetAction => {
        return {
            type: CREATE_USER_SUCCESS_RESET,
        }
    },

    /**
     * @param authenticationOpensShareOrderModal If true, closing the "welcome back" modal will open the share order modal
     * @returns 
     */
    authenticateUser: (authenticationOpensShareOrderModal?: boolean): AuthenticateUserAction => {
        return {
            type: AUTHENTICATE_USER,
            payload: {
                authenticationOpensShareOrderModal,
            }
        }
    },

    signInRedirect: (signinProvider: SignInProviders, redirectPath?: string | undefined): SignInRedirectAction => {
        return {
            type: SIGNIN_REDIRECT,
            payload: {
                signinProvider: signinProvider,
                redirectPath: redirectPath
            }
        }
    },
    signInAuthorize: (signinAuthorizeParams: SignInAuthorizeParams): SignInAuthorizeAction => {
        return {
            type: SIGNIN_AUTHORIZE_USER,
            payload: signinAuthorizeParams
        }
    },

    signInWithCustomToken: (provider: SignInProviders, state: string, customToken: string, customer: Customer, redirect_path?: string, formToDisplay?: CustomerInformationModalFormToDisplay | null, formParameters?: CustomerInformationFormParameters): SignInWithCustomTokenAction => {
        return {
            type: SIGNIN_WITH_CUSTOM_TOKEN,
            payload: {
                provider,
                state,
                custom_token: customToken,
                customer,
                redirect_path,
                formToDisplay,
                formParameters,
            }
        }
    },

    authenticateUserSuccess: (customer: Customer, registerOnLocation: boolean, user_email_verified: boolean, isAnonymous: boolean, provider?: string, withCustomToken?: boolean): AuthenticateUserSuccessAction => {
        return {
            type: AUTHENTICATE_USER_SUCCESS,
            payload: {
                customer: customer,
                registerOnLocation: registerOnLocation,
                user_email_verified: user_email_verified,
                is_anonymous: isAnonymous,
                provider: provider,
                withCustomToken: withCustomToken,
            }
        }
    },
    signoutUser: (doNotResetOrder: boolean = false): SignoutUserAction => {
        return {
            type: SIGNOUT_USER,
            payload: {
                doNotResetOrder: doNotResetOrder,
            }
        }
    },
    signoutUserSuccess: (): SignoutUserSuccessAction => {
        return {
            type: SIGNOUT_USER_SUCCESS
        }
    },
    authenticationError: (message: string, type?: AuthenticationErrorType): AuthenticationErrorAction => {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                errorMessage: message,
                errorType: type
            }
        }
    },
    loadUserInfo: (): LoadUserInfoAction => {
        return {
            type: LOAD_USER_INFO,
        }
    },
    authenticationLocationProcess: (authentication_process: CustomerInfoFlowChoice | null, authentication_mandatory: boolean | null, authentication_providers: SignInProviders[] | undefined, email_domains_allowed: string[] | null, customer_email_verified: boolean): AuthenticationLocationProcessAction => {
        return {
            type: AUTHENTICATION_LOCATION_PROCESS,
            payload: { authentication_process, authentication_mandatory, authentication_providers, email_domains_allowed, customer_email_verified }
        }
    },
    sendVerificationEmail: (): SendVerificationEmailAction => {
        return {
            type: SEND_VERIFICATION_EMAIL
        }
    },

    /**
     * When the user clicks on "continue as guest", set a variable in the 
     * state to memorize this choice.
     * @param end set to true if the action is called from the "END" modal, i.e
     * between Summarypage and Pickupinfo
     * @returns 
     */
    continueAsGuest: (end: boolean = false): ContinueAsGuestAction => {
        return {
            type: CONTINUE_AS_GUEST,
            payload: end,
        }
    },

    /**
     * This action will reset the has_chosen_to_be_anonymous variable. It is useful
     * if we have to ask again for authentication
     * @returns 
     */
    resetGuestState: (): ResetGuestStateAction => {
        return {
            type: RESET_GUEST_STATE
        }
    },

    /**
     * Override the user.loyalty_balance with the given value
     * @param newBalance 
     * @returns 
     */
    setUserLoyaltyBalance: (
        newBalance: number | undefined,
    ): SetUserLoyaltyBalanceAction => {
        return {
            type: SET_USER_LOYALTY_BALANCE,
            payload: {
                newBalance,
            }
        }
    },

    updateUser: (customer: Customer): UpdateUserAction => {
        return {
            type: UPDATE_USER,
            payload: {
                customer
            }
        }
    },

    updateUserSuccess: (customer: Customer): UpdateUserSuccessAction => {
        return {
            type: UPDATE_USER_SUCCESS,
            payload: {
                customer
            }
        }
    },

    firebaseSigninSuccess: (): FirebaseSigninSuccessAction => {
        return {
            type: FIREBASE_SIGNIN_SUCCESS,
        }
    },

    acceptTerms: (isLoggedIn: boolean): AcceptTermsAction => ({
        type: ACCEPT_TERMS,
        payload: {
            isLoggedIn,
        },
    }),

    acceptTermsSuccess: (lastAcceptedTerms: BaseUser["last_accepted_terms"]): AcceptTermsSuccessAction => ({
        type: ACCEPT_TERMS_SUCCESS,
        payload: {
            lastAcceptedTerms,
        }
    }),

    acceptTermsError: (): AcceptTermsErrorAction => ({
        type: ACCEPT_TERMS_ERROR,
    }),
}

export default AuthenticationActions;
