import _ from "lodash";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import reduce from "lodash/reduce";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { isTemporallyAvailable } from "../../../functions/Helpers";
import { BaseCatalogEntity, Catalog, Categorie, Deal, DEFAULT_TIMEZONE_NAME, getTimezoneName as getTimezoneNameFromCatalog, MIN_DEFAULT_PREPARATION_TIME, Product } from "../../../model/Catalog";
import { CatalogExtended } from "../../../model/catalogExtended/CatalogExtended";
import { getTimezoneName as getTimezoneNameFromLocation, Location, SupportedServiceType, Table } from "../../../model/Location";
import { Order, OrderItem } from "../../../model/Order";
import BaseCatalogAnomaly from "../../catalogs/models/anomalies/BaseCatalogAnomaly";
import CatalogAnomaly from "../../catalogs/models/anomalies/CatalogAnomaly";
import CatalogAnomalyType from "../../catalogs/models/anomalies/CatalogAnomalyType";
import { CatalogEntityWithTableAreaRestriction } from "../../catalogs/models/anomalies/InvalidTableAreaRef";
import { CatalogEntityWithRestriction, InconsistentRestriction, InvalidRestriction, TopLevelRestrictionEntity } from "../../catalogs/models/anomalies/RestrictionAnomaly";
import { CatalogEntityType } from "../../catalogs/models/CatalogEntity";
import { setTimeFromString } from "../../common/helpers/setTimeFromString";
import { Money, moneyToNumber } from "../../common/models/Money";
import { extractDateFromTimestampOrString, getMostRecentDate } from "../../common/services/DateHelper";
import OrderTimeSlot from "../../orders/models/OrderTimeSlot";
import { COLLECTION_DATE_FORMAT, DEFAULT_COLLECTION_SLOT_MINUTES } from "../../orders/services/CollectionService";
import { getNextAvailableTime, isValidDow, isValidTime } from "../helpers/RestrictionsHelpers";
import { Restriction } from "../model/Restriction";
import { RestrictionInvalidityReason } from "../model/RestrictionInvalidityReason";
import { WithinRestrictionValues } from "../model/WithinRestrictionValues";

const STARTING_CREATE_MOMENT: string = "2017-08-30T" // For build correct hour moment
const ENDING_CREATE_MOMENT: string = ":00" // For build correct hour moment
export const STARTING_HOUR: string = "00:00"
export const ENDING_HOUR: string = "23:59"
export const DOW_ALL_DAYS: string = "1234567"
export const DOW_NO_DAYS: string = "-------"
export const TIME_FORMAT = "HH:mm";


/**
 * Check is this restriction is allowing the service type
 * @param restriction 
 * @param serviceType 
 * @returns 
 */
export function isServiceTypeAllowed(restriction: Restriction, serviceType: SupportedServiceType): boolean {

    // No service type means all service types
    if (!restriction.service_types) {
        return true;
    }

    return restriction.service_types.includes(serviceType);
}

export function getRestrictionsForServiceType(restrictions: Restriction[], serviceType?: SupportedServiceType) {
    if (serviceType && restrictions) {
        const allowedRestrictions: Restriction[] = [];
        restrictions.forEach((restriction) => {
            if (isServiceTypeAllowed(restriction, serviceType)) {
                allowedRestrictions.push(restriction);
            }
        })
        return allowedRestrictions;
    }
    return restrictions;
}

/**
 * Get the intersection of 2 restrictions. If the restrictions don't
 * overlap, the function returns null.
 * @param restriction1Param
 * @param restriction2Param
 * @param serviceType: only consider restrictions for this service type
 */
export function intersectRestrictions(restriction1Param: Restriction, restriction2Param: Restriction, serviceType?: SupportedServiceType): Restriction | null {

    const restriction1 = _.cloneDeep(restriction1Param);
    const restriction2 = _.cloneDeep(restriction2Param);

    // First of all, we'll start a "lazy" picking of the fields. It means that we'll take
    // for each field one from res1 or res2 if it exists. This is useful for newly added fields. Before,
    // restrictionResult was set to {} and we intersected each field one by one. The problem is that, if
    // we add a field (ex: authentication_providers) in the model but forget to add it here, the field will
    // be emptied each time we intersect the restrictions!
    // So this first step is a "guardrail" and obviously we'll intersect the fields below.
    const restrictionResult: Restriction = _.cloneDeep(restriction1);
    _.merge(restrictionResult, restriction2);

    /////////////////
    // SERVICE TYPES
    /////////////////

    delete restrictionResult.service_types;
    if (serviceType) {
        if (!isServiceTypeAllowed(restriction1, serviceType)) {
            return null;
        }
        if (!isServiceTypeAllowed(restriction2, serviceType)) {
            return null;
        }
        restrictionResult.service_types = [serviceType];
    } else {
        restrictionResult.service_types = intersectServiceTypeLists(restriction1.service_types, restriction2.service_types);
        if (restrictionResult.service_types === undefined) {
            delete restrictionResult.service_types;
        }
    }
    if (restrictionResult.service_types && restrictionResult.service_types.length === 0) {
        return null;
    }


    /////////////////
    // DOW
    /////////////////

    delete restrictionResult.dow;
    if (!restriction1.dow) {
        restriction1.dow = DOW_ALL_DAYS;
    }
    if (!restriction2.dow) {
        restriction2.dow = DOW_ALL_DAYS;
    }

    let intersectDow = "";
    for (let iDays = 1; iDays < 8; iDays++) {
        const iDayStr = iDays.toString(10);
        // Null = no restrictions
        if ((!restriction1.dow && !restriction2.dow) ||
            (!restriction1.dow && restriction2.dow.includes(iDayStr)) ||
            (restriction1.dow.includes(iDayStr) && !restriction2.dow) ||
            (restriction1.dow.includes(iDayStr) && restriction2.dow.includes(iDayStr))
        ) {
            intersectDow = intersectDow + iDayStr;
        } else {
            intersectDow = intersectDow + '-';
        }
    }

    if (intersectDow === DOW_NO_DAYS) {
        return null
    }

    restrictionResult.dow = intersectDow;

    /////////////////
    // START TIME, END TIME
    /////////////////
    delete restrictionResult.start_time;
    delete restrictionResult.end_time;

    let startTime1: moment.Moment;
    let endTime1: moment.Moment;
    let startTime2: moment.Moment;
    let endTime2: moment.Moment;
    let finalStartTime: moment.Moment;
    let finalEndTime: moment.Moment;

    if (!restriction1.start_time) {
        restriction1.start_time = STARTING_HOUR
    }

    if (!restriction1.end_time) {
        restriction1.end_time = ENDING_HOUR
    }

    if (!restriction2.start_time) {
        restriction2.start_time = STARTING_HOUR
    }

    if (!restriction2.end_time) {
        restriction2.end_time = ENDING_HOUR
    }

    // Create moment to compare hours
    startTime1 = moment(`${STARTING_CREATE_MOMENT}${restriction1.start_time}${ENDING_CREATE_MOMENT}`);
    endTime1 = moment(`${STARTING_CREATE_MOMENT}${restriction1.end_time}${ENDING_CREATE_MOMENT}`);
    startTime2 = moment(`${STARTING_CREATE_MOMENT}${restriction2.start_time}${ENDING_CREATE_MOMENT}`);
    endTime2 = moment(`${STARTING_CREATE_MOMENT}${restriction2.end_time}${ENDING_CREATE_MOMENT}`);

    if (startTime1 >= startTime2) {
        finalStartTime = startTime1;
    } else {
        finalStartTime = startTime2;
    }

    if (endTime1 >= endTime2) {
        finalEndTime = endTime2;
    } else {
        finalEndTime = endTime1;
    }

    if (finalStartTime >= finalEndTime) { return null; }

    restrictionResult.start_time = finalStartTime.format(TIME_FORMAT);
    restrictionResult.end_time = finalEndTime.format(TIME_FORMAT);


    // -- Intersect the extended props (prep time, max per slot, ...)

    /////////////////
    // MIN PREP TIME
    /////////////////

    delete restrictionResult.min_preparation_time;
    if (restriction1.min_preparation_time && restriction2.min_preparation_time) {

        // We keep the highest one
        if (restriction1.min_preparation_time > restriction2.min_preparation_time) {

            restrictionResult.min_preparation_time = restriction1.min_preparation_time;
        }
        else {

            restrictionResult.min_preparation_time = restriction2.min_preparation_time;
        }
    }
    // One or zero are defined
    else if (restriction1.min_preparation_time) {

        restrictionResult.min_preparation_time = restriction1.min_preparation_time;
    }
    else if (restriction2.min_preparation_time) {

        restrictionResult.min_preparation_time = restriction2.min_preparation_time;
    }

    /////////////////
    // ORDER SLOT TIME
    /////////////////

    delete restrictionResult.order_slot_time;

    if (restriction1.order_slot_time && restriction2.order_slot_time) {

        // We keep the highest one
        if (restriction1.order_slot_time > restriction2.order_slot_time) {

            restrictionResult.order_slot_time = restriction1.order_slot_time;
        }
        else {

            restrictionResult.order_slot_time = restriction2.order_slot_time;
        }
    }
    // One or zero are defined
    else if (restriction1.order_slot_time) {

        restrictionResult.order_slot_time = restriction1.order_slot_time;
    }
    else if (restriction2.order_slot_time) {

        restrictionResult.order_slot_time = restriction2.order_slot_time;
    }

    /////////////////
    // MAX PER SLOT
    /////////////////

    delete restrictionResult.max_orders_per_slot;
    if (restriction1.max_orders_per_slot && restriction2.max_orders_per_slot) {

        // We keep the lowest one
        if (restriction1.max_orders_per_slot < restriction2.max_orders_per_slot) {

            restrictionResult.max_orders_per_slot = restriction1.max_orders_per_slot;
        }
        else {

            restrictionResult.max_orders_per_slot = restriction2.max_orders_per_slot;
        }
    }
    // One or zero are defined
    else if (restriction1.max_orders_per_slot) {

        restrictionResult.max_orders_per_slot = restriction1.max_orders_per_slot;
    }
    else if (restriction2.max_orders_per_slot) {

        restrictionResult.max_orders_per_slot = restriction2.max_orders_per_slot;
    }

    /////////////////
    // TIMESLOTS DISPLAY TIME LIMIT
    /////////////////
    delete restrictionResult.timeslots_display_time_limit;

    if (restriction1.timeslots_display_time_limit && restriction2.timeslots_display_time_limit) {

        // We keep the lowest one
        if (restriction1.timeslots_display_time_limit < restriction2.timeslots_display_time_limit) {

            restrictionResult.timeslots_display_time_limit = restriction1.timeslots_display_time_limit;
        }
        else {

            restrictionResult.timeslots_display_time_limit = restriction2.timeslots_display_time_limit;
        }
    }
    // One or zero are defined
    else if (restriction1.timeslots_display_time_limit) {

        restrictionResult.timeslots_display_time_limit = restriction1.timeslots_display_time_limit;
    }
    else if (restriction2.timeslots_display_time_limit) {

        restrictionResult.timeslots_display_time_limit = restriction2.timeslots_display_time_limit;
    }


    /////////////////
    // TABLE AREAS
    /////////////////
    if (restriction1.table_areas_refs && restriction2.table_areas_refs) {

        restrictionResult.table_areas_refs = [];

        restriction1.table_areas_refs.forEach((area1) => {
            if (restriction2.table_areas_refs?.includes(area1) && !restrictionResult.table_areas_refs?.includes(area1)) {
                restrictionResult.table_areas_refs!.push(area1);
            }
        });

        restriction2.table_areas_refs.forEach((area2) => {
            if (restriction1.table_areas_refs?.includes(area2) && !restrictionResult.table_areas_refs?.includes(area2)) {
                restrictionResult.table_areas_refs!.push(area2);
            }
        });
    }
    else if (restriction1.table_areas_refs) {
        restrictionResult.table_areas_refs = restriction1.table_areas_refs;
    }
    else if (restriction2.table_areas_refs) {
        restrictionResult.table_areas_refs = restriction2.table_areas_refs;
    }



    /////////////////
    // DELVIERY AFTER TIME
    /////////////////
    delete restrictionResult.delivery_after_time;

    const delivery_after_time1 = moment(restriction1?.delivery_after_time, "HH:mm", true);
    const delivery_after_time2 = moment(restriction2?.delivery_after_time, "HH:mm", true);

    if (delivery_after_time1.isValid() && delivery_after_time2.isValid()) {
        restrictionResult.delivery_after_time = (delivery_after_time1.isBefore(delivery_after_time2) ? delivery_after_time1 : delivery_after_time2).format("HH:mm");
    } else if (delivery_after_time1.isValid()) {
        restrictionResult.delivery_after_time = delivery_after_time1.format("HH:mm");
    } else if (delivery_after_time2.isValid()) {
        restrictionResult.delivery_after_time = delivery_after_time2.format("HH:mm");
    }

    return restrictionResult;
}

/**
 * 
 * @param restriction Check if the given restriction (resulting from an intersection for instance) has some slot available
 */
export function isRestrictionWithSlotAvailable(restriction: Restriction) {

    if (restriction) {
        let daysAvailable: boolean = false;
        // Loop on characters and check if every character is -
        // Dow = null, every day available => return true
        if (restriction.dow) {
            for (let i = 0; i < restriction.dow.length; i++) {
                const dowChar = restriction.dow.charAt(i);
                if (dowChar !== "-") {
                    daysAvailable = true;
                    break;
                }
            }
            if (!daysAvailable) {
                return false;
            }
        }

        // Create moment and compare hour, return false if startTime > end time
        if (restriction.start_time && restriction.end_time) {
            let restrictionStartTime: moment.Moment = moment(`${STARTING_CREATE_MOMENT}${restriction.start_time}${ENDING_CREATE_MOMENT}`)
            let restrictionEndTime: moment.Moment = moment(`${STARTING_CREATE_MOMENT}${restriction.end_time}${ENDING_CREATE_MOMENT}`)
            if (restrictionStartTime >= restrictionEndTime) {
                return false
            }
        }

    }
    return true;
}

export function intersectRestrictionLists(
    restrictions1?: Restriction | Restriction[] | null,
    restrictions2?: Restriction | Restriction[] | null,
    serviceType?: SupportedServiceType,
    returnNullIfNoIntersection?: boolean
): Restriction[] | undefined | null {

    const restrictionsArray1 = getRestrictionsArray(restrictions1);
    const restrictionsArray2 = getRestrictionsArray(restrictions2);

    let intersectionResult: Restriction[] | undefined | null = undefined;
    if (restrictionsArray1 && restrictionsArray2) {
        const restrictionsFinal: Restriction[] = [];
        restrictionsArray1.forEach((restriction1) => {
            restrictionsArray2.forEach((restriction2, index) => {
                const restrictionIntersected = intersectRestrictions(restriction1, restriction2, serviceType);
                if (restrictionIntersected) {
                    restrictionsFinal.push(restrictionIntersected);
                }
            })
        });
        intersectionResult = removeDuplicates(restrictionsFinal);

    } else if (restrictionsArray1) {
        intersectionResult = getRestrictionsForServiceType(restrictionsArray1, serviceType);
    } else if (restrictionsArray2) {
        intersectionResult = getRestrictionsForServiceType(restrictionsArray2, serviceType);
    }

    if (intersectionResult && intersectionResult.length === 0 && returnNullIfNoIntersection) {
        return null;
    }
    return intersectionResult;
}

/**
 * @param restrictions 
 * @returns The resulting restriction or null if no restriction
 */
export function intersectAllRestrictions(restrictions: Restriction[] | undefined | null): Restriction | null {

    if (restrictions && restrictions.length > 0) {

        // We start by using the first restriction.
        let finalRestrictions: Restriction = restrictions[0];

        /**
         * Now we make the intersections, element by element
         */
        for (let iRestrictions = 1; iRestrictions < restrictions.length; iRestrictions++) {

            let restrictionIntersected = intersectRestrictions(finalRestrictions, restrictions[iRestrictions]);

            if (restrictionIntersected) {
                finalRestrictions = restrictionIntersected;
            }
            else {
                // If there is one no-overlapping, it's finished the intersection does not exist
                return null;
            }

        }
        return finalRestrictions;
    }

    return null
}

// Extract all restrictions from order
export function getRestrictionsOnOrder(order: Order, selectedCatalog: CatalogExtended | undefined): Restriction[] {
    const allRestrictionsCombined: Restriction[] = []

    // Get all product restrictions
    if (order) {
        const orderItemList: Array<OrderItem> = order.items;
        for (let i: number = 0; i < orderItemList.length; i++) {
            const productRef = orderItemList[i].product_ref;
            const product = selectedCatalog?.data.products.find((product) => { return product.ref === productRef; });

            if (product) {
                getRestrictionsArray(product.restrictions)?.forEach((restriction) => allRestrictionsCombined.push(restriction));
            } else {
                // TODO: Log error
            }
        }

        // Retrieve ref of order deal. Find & push respective restrictions in selectedCatalog if exist
        let orderDeals = order.deals
        for (const deal in orderDeals) {
            const orderDealRef = orderDeals[deal].ref
            if (orderDealRef) {
                const foundDeal = selectedCatalog?.data.deals.find((res) => {
                    return res.ref === orderDealRef
                })
                getRestrictionsArray(foundDeal?.restrictions)?.forEach((restriction) => allRestrictionsCombined.push(restriction))
            } else {
                // log.warn("orderDealRef is null")
            }
        }
    }

    return allRestrictionsCombined
}

/**
 * 
 * @param startDate 
 * @param intersectRestiction 
 * @param maxDays e.g : last 15 days after today 
 */
export function listCollectionPossibleDays(startDate: moment.Moment, intersectRestiction: Restriction | null, maxDays: number): moment.Moment[] {
    const propositionsListRestrictions: moment.Moment[] = []

    for (let i = 0; i <= maxDays; i++) {
        let propositionMoment: moment.Moment = moment(startDate).add(i, "d")
        let momentPropositionDay: string = propositionMoment.day().toString()
        momentPropositionDay === "0" && (momentPropositionDay = "7")

        if (!intersectRestiction || !intersectRestiction.dow || intersectRestiction.dow?.includes(momentPropositionDay)) {
            propositionsListRestrictions.push(propositionMoment)
        }
    }
    return propositionsListRestrictions
}

/**
 * Get the intersection of the restrictions from the catalog and the table.
 * @param catalog 
 * @param location 
 * @param table 
 * @param serviceType: only consider restrictions applicable to this service type
 * @returns a list (can be empty) of the new combined restrictions
 */
export function intersectCatalogLocationTableRestrictions(
    catalog: Pick<Catalog, "restrictions"> | undefined,
    location: Pick<Location, "restriction" | "supported_service_types" | "orders"> | undefined,
    table: Pick<Table, "restrictions" | "service_type"> | undefined,
    serviceType?: SupportedServiceType
): Restriction[] | undefined | null {

    if (location && !location.restriction) {
        location.restriction = [{
            service_types: location.supported_service_types
        }]
    }
    // Set the timeslots_display_time_limit with the default value if not set
    if (location?.orders?.default_timeslots_display_time_limit) {
        location.restriction?.forEach((restriction) => {
            if (!restriction.timeslots_display_time_limit) {
                restriction.timeslots_display_time_limit = location.orders!.default_timeslots_display_time_limit!;
            }
        })
    }

    if (catalog && !catalog.restrictions) {
        catalog.restrictions = [{
            service_types: location?.supported_service_types
        }]
    }

    const restrictionsIntersectLocationCatalog = intersectRestrictionLists(location?.restriction, catalog?.restrictions, serviceType);

    let tableRestriction = (table && table.restrictions) ? [table.restrictions] : undefined;
    // Handle legacy tables with service_type 
    if (table && !tableRestriction && table.service_type && location?.supported_service_types.includes(table.service_type)) {

        tableRestriction = [{
            service_types: [table.service_type]
        }]
    }

    const restrictionsIntersectLocationCatalogTable = intersectRestrictionLists(restrictionsIntersectLocationCatalog, tableRestriction, serviceType);
    return restrictionsIntersectLocationCatalogTable;
}

/**
 * Intersect two restrictions list
 * @param list1 
 * @param list2 
 * @returns 
 */
export function intersectServiceTypeLists(list1: SupportedServiceType[] | undefined, list2: SupportedServiceType[] | undefined): SupportedServiceType[] | undefined {
    if (list1 && list2) {
        return list1.filter(value => list2.includes(value));
    } else if (list1) {
        return [...list1];
    } else if (list2) {
        return [...list2];
    }
    // Usefull for restrictions to return undefined
    return undefined;
}

/**
 * Get the supported service type by taking account the restrictions
 * can return null wich mean that all servcices types are allowed
 * @param catalog 
 * @param location 
 * @param table 
 * @returns 
 */
export function getSupportedServiceTypesWithRestrictions(
    catalog: Catalog,
    location: Location,
    table: Table | undefined,
): SupportedServiceType[] {
    let supportedServiceTypes: SupportedServiceType[] = [];
    // Removing the service types manualy disabled in the Location
    const allRestrictions = intersectCatalogLocationTableRestrictions(catalog, location, table);
    if (allRestrictions) {
        // This conditions means that no servicetypes are applied to any restriction so all services types are supported
        if (allRestrictions.every(res => res.service_types === null || res.service_types === undefined)) {
            supportedServiceTypes = location.supported_service_types
        } else {

            for (let iRestriction in allRestrictions) {
                const restriction = allRestrictions[iRestriction];

                restriction.service_types?.forEach((serviceType) => {
                    if (
                        !supportedServiceTypes.includes(serviceType)
                        && (!location.disabled_service_types
                            || !location.disabled_service_types.includes(serviceType))
                    ) {
                        supportedServiceTypes.push(serviceType);
                    }
                })
            }
        }
    }
    return supportedServiceTypes;
}

/**
 * Find the first matching restriction for the date
 */
export function getFirstMatchingRestriction(
    momentDate: moment.Moment,
    timezone: string,
    restrictions: Restriction[],
    serviceType?: SupportedServiceType): Restriction | undefined {
    if (restrictions) {
        return restrictions.find((restriction) => {
            return isMatchingTemporalRestriction(momentDate, timezone, restriction, serviceType);
        });
    }

    return undefined;
}

/**
 * 
 * @param forEndPreparationTime 
 * @param restriction 
 * @returns 
 */
export function getMatchingEndPreparationTimeSlots(forEndPreparationTime: DateTime, restriction: Restriction): OrderTimeSlot | null {

    const dayString = forEndPreparationTime.weekday.toString();


    if (restriction.dow && !restriction.dow.includes(dayString)) {
        return null;
    }

    let startTime = restriction.start_time;
    if (!startTime) {
        startTime = STARTING_HOUR;
    }
    let endTime = restriction.end_time;
    if (!endTime) {
        endTime = ENDING_HOUR;
    }

    const slotTime = restriction.order_slot_time ?? DEFAULT_COLLECTION_SLOT_MINUTES;

    const extractedStartTime = extractRestrictionTime(startTime);
    const startRestriction = forEndPreparationTime.set({ hour: extractedStartTime.hour, minute: extractedStartTime.minute });

    const extractedEndTime = extractRestrictionTime(endTime);
    const endRestriction = forEndPreparationTime.set({ hour: extractedEndTime.hour, minute: extractedEndTime.minute });

    // TODO: warning with not rounded start hour
    // TODO: add preparation time to startRestriction?
    const diffTime = Math.abs(Math.floor((forEndPreparationTime.diff(startRestriction, "minute").minutes * 1.0 / slotTime)) * slotTime);

    const startSlot = startRestriction.plus({ minutes: diffTime });
    const endSlot = startSlot.plus({ minutes: slotTime });

    // If after closing hour
    //console.log(`Start slot ${startSlot.toISOString()}, end restriction ${endRestriction.toISOString()}`)
    if (startSlot > endRestriction) {
        return null;
    }
    return {
        start_hour: startSlot.toFormat(TIME_FORMAT),
        start_time: startSlot.startOf("minute").toJSDate(),
        end_hour: endSlot.toFormat(TIME_FORMAT),
        end_time: endSlot.startOf("minute").toJSDate(),
        max_orders_count: restriction.max_orders_per_slot,
    }
}

export enum CheckTimeMode {
    FULL = "full",
    NONE = "none",
    MAX_ONLY = "max_only"
}

/**
 * Check if the restriction is matching
 * By default, check if the time is between min time & max time
 * But if validIfSlotLaterInTheDay is set to true only check if there are some slots later (no check for min)
 * @param forDate 
 * @param timezone 
 * @param restriction 
 * @param serviceType 
 * @param validIfSlotLaterInTheDay 
 * @returns 
 */
export function isMatchingTemporalRestriction(
    forDate: moment.Moment,
    timezone: string,
    restriction: Restriction,
    serviceType?: SupportedServiceType,
    checkTimeMode?: CheckTimeMode,
    subtotalToCheck?: Money,
    tableAreaRef?: string | undefined
): boolean {
    const isMatchingWithReason = isMatchingTemporalRestrictionWithReason(forDate, timezone, restriction, serviceType, checkTimeMode, subtotalToCheck, tableAreaRef);
    return isMatchingWithReason.isMatching;
}

export function isMatchingTemporalRestrictionWithReason(
    forDate: moment.Moment,
    timezone: string,
    restriction: Restriction,
    serviceType?: SupportedServiceType,
    checkTimeMode?: CheckTimeMode,
    subtotalToCheck?: Money,
    tableAreaRef?: string
): { isMatching: boolean, reason?: RestrictionInvalidityReason } {

    if (serviceType) {
        if (!isServiceTypeAllowed(restriction, serviceType)) {
            return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_SERVICE_TYPE_NOT_RESPECTED };
        }
    }

    const momentDate = forDate.tz(timezone).startOf("minute");
    const momentDow = getDayNumberFromMoment(momentDate).toString();
    if (restriction.dow && !restriction.dow.includes(momentDow)) {
        //console.log(`dow not matching ${momentDow} not included in ${restriction.dow}`)
        return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_DOW_NOT_RESPECTED };
    }

    if (restriction.start_time) {

        const startTimeMoment = getTimeMoment(momentDate, restriction.start_time);

        if (startTimeMoment > momentDate && (!checkTimeMode || checkTimeMode === CheckTimeMode.FULL)) {
            //console.log(`Restriction start time ${startTimeMoment} after time ${momentDate}`);
            return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_START_TIME_NOT_RESPECTED };
        }
    }

    // Warning: the end time is inclusive to handle 00:00 -> 23:59 case (see tests)
    if (restriction.end_time) {
        const endTimeMoment = getTimeMoment(momentDate, restriction.end_time);
        //console.log(`Restriction end time ${endTimeMoment} (${restriction.end_time})`)
        if (endTimeMoment < momentDate && (!checkTimeMode || checkTimeMode === CheckTimeMode.FULL || checkTimeMode === CheckTimeMode.MAX_ONLY)) {
            //console.log(`Restriction end time ${endTimeMoment} before time ${momentDate}`);
            return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_END_TIME_NOT_RESPECTED };
        }
    }

    if (restriction.start_date) {
        const startDateMoment = moment(restriction.start_date);
        if (startDateMoment > momentDate) {
            //console.log(`Restriction start date ${startDateMoment} above time ${momentDate}`);
            return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_START_DATE_NOT_RESPECTED };
        }
    }

    if (restriction.end_date) {
        const endDateMoment = moment(restriction.end_date);
        // Warning: the end date is exclusive to ease the construction of consecutive slots
        if (endDateMoment <= momentDate) {
            //console.log(`Restriction end date ${endDateMoment} before time ${momentDate}`);
            return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_END_DATE_NOT_RESPECTED };
        }
    }

    if (subtotalToCheck && restriction.min_order_amount) {
        const minAmount = moneyToNumber(restriction.min_order_amount);
        const subtotal = moneyToNumber(subtotalToCheck);
        if (minAmount > subtotal) {
            return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_MIN_ORDER_AMOUNT_NOT_REACHED };
        }
    }
    if (
        (
            restriction.table_areas_refs
            && tableAreaRef
            && !restriction.table_areas_refs.includes(tableAreaRef)
        )
        || (
            restriction.table_areas_refs
            && !tableAreaRef
        )
    ) {
        return { isMatching: false, reason: RestrictionInvalidityReason.RESTRICTION_TABLE_AREA_NOT_RESPECTED };
    }
    return { isMatching: true };
}

export function getNextSlotStart(forDate: moment.Moment, timezone: string, minPreparationTime?: number, slotDurationInMinutes?: number, startTime?: string, endTime?: string): moment.Moment | null {

    forDate = forDate.tz(timezone);
    if (!startTime) {
        startTime = STARTING_HOUR;
    }
    if (!endTime) {
        endTime = ENDING_HOUR;
    }
    const startRestriction = getTimeMoment(forDate, startTime).tz(timezone);
    const endRestriction = getTimeMoment(forDate, endTime).tz(timezone);

    if (!slotDurationInMinutes || slotDurationInMinutes <= 0) {
        slotDurationInMinutes = DEFAULT_COLLECTION_SLOT_MINUTES;
    }

    let minOrderTime = moment(forDate).add(minPreparationTime, "m")

    if (minOrderTime < startRestriction) {
        minOrderTime = moment(startRestriction);
    } else {
        // Get the next slot available
        // TODO: it suppose that the startMoment it for "plain hour", no working for a start hour such as 12:05
        const diffTime = Math.ceil((minOrderTime.diff(startRestriction, "minutes") * 1.0 / slotDurationInMinutes)) * slotDurationInMinutes;
        minOrderTime = startRestriction.add(diffTime, "minutes").set("s", 0).set("ms", 0);
    }

    if (endRestriction <= minOrderTime) {
        return null;
    } else {
        return minOrderTime;
    }
}

/**
 * Get allowed time slots given a start time,
 * @param forDate 
 * @param currentTime 
 * @param start_time 
 * @param end_time 
 * @param slotDurationInMinutes 
 * @param minPreparationTime 
 * @returns 
 */
export function getAllowedOrderTimeSlots(
    forDate: moment.Moment,
    timezone: string,
    currentTime: moment.Moment,
    start_time?: string,
    end_time?: string,
    slotDurationInMinutes: number = DEFAULT_COLLECTION_SLOT_MINUTES,
    minPreparationTime: number = MIN_DEFAULT_PREPARATION_TIME,
    max_orders_per_slot?: number
): OrderTimeSlot[] {

    const timeSlots: OrderTimeSlot[] = []
    if (!start_time) {
        start_time = STARTING_HOUR;
    }
    if (!end_time) {
        end_time = ENDING_HOUR;
    }
    forDate = forDate.tz(timezone);
    currentTime = currentTime.tz(timezone);
    const startMoment = getTimeMoment(forDate, start_time).tz(timezone);
    let endMoment = getTimeMoment(forDate, end_time).tz(timezone);

    if (endMoment < startMoment) {
        // Handle specific cas for opening hours after midnight
        endMoment = endMoment.add(1, "d");
    }


    if (!slotDurationInMinutes || slotDurationInMinutes <= 0) {
        throw new Error("Slot must be superior to 0")
    }

    const forDateFormatted = forDate.format(COLLECTION_DATE_FORMAT);

    const currentMoment = moment(currentTime)
    const currentTimeDate = currentMoment.format(COLLECTION_DATE_FORMAT)
    let minOrderTime: moment.Moment | null;
    let maxOrderTime: moment.Moment;

    if (!minPreparationTime) {
        minPreparationTime = MIN_DEFAULT_PREPARATION_TIME;
    }

    if (currentTimeDate === forDateFormatted) {
        minOrderTime = getNextSlotStart(currentMoment, timezone, minPreparationTime, slotDurationInMinutes, start_time, end_time);
    } else {
        minOrderTime = moment(startMoment);
    }

    if (!minOrderTime) {
        // No slot available
        return [];
    } else {
        maxOrderTime = endMoment
    }

    let time = minOrderTime
    while (time < maxOrderTime) {

        const startSlotTime = moment(time);
        const endSlotTime = moment(time).add(slotDurationInMinutes, 'm')

        const timeSlot: OrderTimeSlot = {
            start_hour: startSlotTime.format(TIME_FORMAT),
            start_time: startSlotTime.toDate(),
            end_hour: endSlotTime.format(TIME_FORMAT),
            end_time: endSlotTime.toDate(),
            max_orders_count: max_orders_per_slot,
            full: false
        }
        timeSlots.push(timeSlot)
        time = endSlotTime
    }

    return timeSlots;
}

export const getTimeMoment = (day: moment.Moment, time: string): moment.Moment => {
    const startTimeMoment = moment(day).startOf('day');
    const startTimeParts = time.split(':');

    if (startTimeParts.length !== 2) {
        throw new Error(`Invalid start time (${time}), can't get moment`)
    }
    return startTimeMoment.add(+startTimeParts[0], 'h').add(+startTimeParts[1], 'm')
}

export const extractRestrictionTime = (time: string): { hour: number, minute: number } => {
    const timeParts = time.split(':');
    if (timeParts.length !== 2) {
        throw new Error(`Invalid time (${time}), can't extract`);
    }
    return {
        hour: parseInt(timeParts[0]),
        minute: parseInt(timeParts[1]),
    }
}

export const getDayNumber = (date: string) => {
    const momentDay = moment(date);
    return getDayNumberFromMoment(momentDay);
}

export const getDayNumberFromMoment = (date: moment.Moment) => {
    let day = date.day();
    if (day === 0) {
        day = 7
    }
    return day
}

/**
 * Handle date typed as Date | string in model
 * @param date 
 * @returns 
 */
export const getValidDate = (date: Date | string): Date => {
    const anyDate = date as any
    if (DateTime.fromJSDate(anyDate).isValid) {
        return anyDate as Date
    } else {
        return new Date(date)
    }
}
export const applyIntersectLocationServiceTypesToRestrictionList = (locationSupportedServiceType: SupportedServiceType[], restrictions: Restriction | Restriction[] | null | undefined) => {
    getRestrictionsArray(restrictions)?.forEach(restriction => {
        restriction.service_types = intersectServiceTypeLists(locationSupportedServiceType, restriction.service_types)
    });
}
export const applyIntersectLocationServiceTypesToAll = (locationSupportedServiceType: SupportedServiceType[], catalogs: Catalog[], locationRestrictionList: Restriction[] | null | undefined) => {
    if (locationRestrictionList) {
        applyIntersectLocationServiceTypesToRestrictionList(locationSupportedServiceType, locationRestrictionList)
    }
    catalogs.forEach(catalog => {
        applyIntersectLocationServiceTypesToRestrictionList(locationSupportedServiceType, catalog.restrictions)

        const catalogData = catalog.data;
        // Products
        catalogData.products.forEach((product) => {
            if (product.restrictions) {
                applyIntersectLocationServiceTypesToRestrictionList(locationSupportedServiceType, product.restrictions)
            }
        });

        // Categories
        catalogData.categories.forEach((category) => {
            if (category.restrictions) {
                applyIntersectLocationServiceTypesToRestrictionList(locationSupportedServiceType, category.restrictions)
            }

        });

        // Deals
        catalogData.deals.forEach((deal) => {
            if (deal.restrictions) {
                applyIntersectLocationServiceTypesToRestrictionList(locationSupportedServiceType, deal.restrictions)
            }

        });

        // Discounts
        catalogData.discounts?.forEach((discount) => {
            if (discount.restrictions) {
                applyIntersectLocationServiceTypesToRestrictionList(locationSupportedServiceType, discount.restrictions)
            }

        });

    })
}
/**
 * The purpose of this function is to "spread" a restrictions change to all the entities below (
 * in a lower level). Example: the location was opened from 10:00 to 21:00 and a product
 * had a restriction 10:00 -> 15:00. Then we want to change the location opening hours to 11:00 -> 21:00,
 * this function will be called with newRestrictionList=[{11:00 -> 21:00}]. The function will iterate trough
 * all the elements of the location (catalogs, products, deals, categories, discounts) and will intersect the
 * restrictions. The product will have a new restriction: 11:00 -> 15:00
 * If called by a location change, set applyToCatalogItself to true ; if called by a catalog change, set it to false
 * @param givenCatalog 
 * @param topLevelRestrictions typically the location or catalog restrictions
 * @param applyToCatalogItself if true, we'll also intersect the topLevelRestrictions with the catalog restrictions
 * @param doNotModifyCatalog if true, the catalog object won't be modified. Useful to get a list of anomalies
 * @param catalogAnomalies
*/
export const spreadRestrictionsToLowerCatalogEntities = (
    givenCatalog: Catalog,
    topLevelRestrictions: Restriction[] | null | undefined,
    applyToCatalogItself: boolean,
    doNotModifyCatalog?: boolean,
    catalogAnomalies?: BaseCatalogAnomaly[],
) => {

    // Getting a copy to work on if necessary
    const catalog = doNotModifyCatalog ? _.cloneDeep(givenCatalog) : givenCatalog;
    const catalogData = catalog.data;

    const anomalies: BaseCatalogAnomaly[] = catalogAnomalies ?? [];  // Fill a temporary array if not provided

    // Useful to build the anomaly objects
    const anomaly_type = CatalogAnomalyType.INCONSISTENT_RESTRICTION;
    const top_level_restriction_entity = applyToCatalogItself ? TopLevelRestrictionEntity.LOCATION : TopLevelRestrictionEntity.CATALOG;

    if (applyToCatalogItself && catalog.restrictions && topLevelRestrictions) {

        // We intersect the restrictions with themselves because the intersection can add default fields (which will make the comparaison fail)
        const beforeIntersection = intersectRestrictionLists(catalog.restrictions, catalog.restrictions);

        // WARNING: Set to null if no intersection
        catalog.restrictions = intersectRestrictionLists(catalog.restrictions, topLevelRestrictions, undefined, true)

        // The intersection changed the restrictions -> there is an inconsistency
        if (!_.isEqual(catalog.restrictions, beforeIntersection)) {
            const anomaly: InconsistentRestriction = {
                entity: catalog,
                type: CatalogEntityType.CATALOG,
                top_level_restriction_entity,
                anomaly_type,
            }
            anomalies.push(anomaly);
        }
    }

    const restrictionToCompareWithEntities = catalog.restrictions ?? topLevelRestrictions

    if (restrictionToCompareWithEntities) {

        // Products
        catalogData.products.forEach((product) => {

            // Do not try to intersect if the restriction is invalid
            if (
                product.restrictions
                //&& !anomalies.some(a => a.entity.ref === product.ref && a.anomaly_type === CatalogAnomalyType.INVALID_RESTRICTION)
            ) {

                // We intersect the restrictions with themselves because the intersection can add default fields (which will make the comparaison fail)
                const beforeIntersection = intersectRestrictionLists(product.restrictions, product.restrictions);
                // WARNING: Set to null if no intersection
                product.restrictions = intersectRestrictionLists(product.restrictions, topLevelRestrictions, undefined, true);

                // The intersection changed the restrictions -> there is an inconsistency
                if (!_.isEqual(product.restrictions, beforeIntersection)) {
                    const anomaly: InconsistentRestriction = {
                        entity: product,
                        type: CatalogEntityType.PRODUCT,
                        top_level_restriction_entity,
                        anomaly_type,
                    }
                    anomalies.push(anomaly);
                }
            }
        });

        // Categories
        catalogData.categories.forEach((category) => {
            if (category.restrictions && !isThereAlreadyARestrictionAnomalyForThisEntity(anomalies, category)) {

                // We intersect the restrictions with themselves because the intersection can add default fields (which will make the comparaison fail)
                const beforeIntersection = intersectRestrictionLists(category.restrictions, category.restrictions);

                // WARNING: Set to null if no intersection
                category.restrictions = intersectRestrictionLists(category.restrictions, topLevelRestrictions, undefined, true);

                // The intersection changed the restrictions -> there is an inconsistency
                if (!_.isEqual(category.restrictions, beforeIntersection)) {
                    const anomaly: InconsistentRestriction = {
                        entity: category,
                        type: CatalogEntityType.CATEGORY,
                        top_level_restriction_entity,
                        anomaly_type,
                    }
                    anomalies.push(anomaly);
                }
            }
        });

        // Deals
        catalogData.deals.forEach((deal) => {
            if (deal.restrictions && !isThereAlreadyARestrictionAnomalyForThisEntity(anomalies, deal)) {

                // We intersect the restrictions with themselves because the intersection can add default fields (which will make the comparaison fail)
                const beforeIntersection = intersectRestrictionLists(deal.restrictions, deal.restrictions);

                // WARNING: Set to null if no intersection
                deal.restrictions = intersectRestrictionLists(deal.restrictions, topLevelRestrictions, undefined, true);

                // The intersection changed the restrictions -> there is an inconsistency
                if (!_.isEqual(deal.restrictions, beforeIntersection)) {
                    const anomaly: InconsistentRestriction = {
                        entity: deal,
                        type: CatalogEntityType.DEAL,
                        top_level_restriction_entity,
                        anomaly_type,
                    }
                    anomalies.push(anomaly);
                }
            }
        });

        // Discounts
        catalogData.discounts?.forEach((discount) => {
            if (discount.restrictions && !isThereAlreadyARestrictionAnomalyForThisEntity(anomalies, discount)) {

                // We intersect the restrictions with themselves because the intersection can add default fields (which will make the comparaison fail)
                const beforeIntersection = intersectRestrictionLists(discount.restrictions, discount.restrictions);

                // WARNING: Set to null if no intersection
                discount.restrictions = intersectRestrictionLists(discount.restrictions, topLevelRestrictions, undefined, true);

                // The intersection changed the restrictions -> there is an inconsistency
                if (!_.isEqual(discount.restrictions, beforeIntersection)) {
                    const anomaly: InconsistentRestriction = {
                        entity: discount,
                        type: CatalogEntityType.DISCOUNT,
                        top_level_restriction_entity,
                        anomaly_type,
                    }
                    anomalies.push(anomaly);
                }
            }
        });
    }
}

const isThereAlreadyARestrictionAnomalyForThisEntity = (anomalies: BaseCatalogAnomaly[], entity: BaseCatalogEntity) => {

    return (
        anomalies.some(anomaly => (
            anomaly.entity.ref === entity.ref
            && (
                anomaly.anomaly_type === CatalogAnomalyType.INCONSISTENT_RESTRICTION
                || anomaly.anomaly_type === CatalogAnomalyType.INVALID_RESTRICTION
            )
        ))
    );
}

export function removeDuplicates<Type>(array: Type[]): Type[] {
    return array.filter((value, index, self) =>
        index === self.findIndex((other) => (
            _.isEqual(value, other)
        ))
    )
}

export const getRestrictionsArray = (restrictions: Restriction | Restriction[] | null | undefined): Restriction[] | null | undefined => {
    if (restrictions) {
        if (isArray(restrictions)) {
            return restrictions as Restriction[]
        } else {
            return [restrictions as Restriction]
        }
    }
    return restrictions as null | undefined;
}

/**
 * Based on one or several restrictions, this function will tell if the entity may be available (meaning:
 * it can be available but later on when the time restrictions will be checked, it won't be anymore).
 * WARN: This function also filters the restrictions and removes all the ones which are not compatible with the
 * tableAreaRef given in parameters.
 * @param restrictions 
 * @param tableAreaRef 
 * @param time 
 * @param timezone 
 */
export const isEntityAvailableForTableArea = (
    entity: CatalogEntityWithTableAreaRestriction,
    tableAreaRef: string,
): boolean => {

    const restrictionsArray = getRestrictionsArray(entity.restrictions);
    if (restrictionsArray) {

        if (restrictionsArray.length === 0) {
            return false;
        }

        if (restrictionsArray.find((r) => r.table_areas_refs)) {

            /**
             * Examples: the areaRef in parameters is 1
             * [] -> FALSE ; new array: []
             * [{undefined}, {undefined}, {undefined}] -> TRUE ; new array: [{undefined}, {undefined}, {undefined}]
             * [{undefined}, {[2,3]}, {[4,5]}] -> TRUE ; new array: [{undefined}]
             * [{undefined}, {[1, 2]}, {[3, 4]}] -> TRUE ; new array: [{undefined}, {[1, 2]}]
             * [{[1, 2]}, {[3, 4]}] -> TRUE ; new array: [{[1, 2]}]
             * [{[5, 2]}, {[3, 4]}] -> FALSE ; new array (actually we don't care): []
             * [{[5, 2]}, {[3, 4]}, {undefined}] -> TRUE ; new array: [{undefined}]
             * 
             * The condition is then that each one of the restrictions must have a table_area_refs, and not include the tableAreaRef.
             */
            const isUnavailable = restrictionsArray.every((r) => (r.table_areas_refs && !r.table_areas_refs.includes(tableAreaRef)));

            // Now deleting all the restrictions with a table_areas_refs array defined which does not include the tableAreaRef
            entity.restrictions = restrictionsArray.filter((r) => !r.table_areas_refs || r.table_areas_refs.includes(tableAreaRef));

            return !isUnavailable;
        }
    }

    return true;
}

/**
 * Merge 2 lists of restrictions. Usage example: when reimporting a catalog, there could be 
 * restrictions changes in the new catalog. (ex: Untill table areas changed)
 * @param oldRestrictions 
 * @param newRestrictions The restrictions from the POS for example
 * @returns 
 */
export const getMergedRestrictions = (
    oldRestrictions: Restriction[] | null | undefined,
    newRestrictions: Restriction[] | null | undefined,
): Restriction[] | undefined => {

    if (oldRestrictions && newRestrictions) {

        const restrictionsToReturn: Restriction[] = [];
        newRestrictions.forEach((newRestriction) => {

            const newRestrictionsMerged: Restriction[] = [];
            oldRestrictions.forEach((oldRestriction) => {

                const intersection = intersectRestrictions(oldRestriction, newRestriction);

                // The 2 are intersecting, let's merge
                if (intersection) {

                    // Fields from newRestriction have the priority
                    const mergedRestriction: Restriction = { ...oldRestriction };
                    Object.entries(newRestriction).forEach(([key, value]) => {
                        if (!_.isNil(value)) {
                            // TODO: check why ts compilation is complaining
                            // @ts-ignore
                            mergedRestriction[key as keyof Restriction] = value;
                        }
                    });

                    newRestrictionsMerged.push(mergedRestriction);
                }
            });

            if (newRestrictionsMerged.length > 0) {
                restrictionsToReturn.push(...newRestrictionsMerged);
            }
            // No intersection at all -> we keep the new restriction
            else {
                restrictionsToReturn.push(newRestriction);
            }
        });

        return restrictionsToReturn;
    }

    else if (newRestrictions) {
        return newRestrictions;
    }
    else if (oldRestrictions) {
        return oldRestrictions;
    }
    else {
        return undefined;
    }
}

export const checkInvalidRestrictions = (
    restrictions: Restriction[] | null | undefined,
    entity: CatalogEntityWithRestriction,
    entityType: CatalogEntityType,
    anomalies: CatalogAnomaly[],
    fix?: boolean,
): void => {

    let indexOffset = 0;
    restrictions?.forEach((restriction, index) => {

        checkInvalidRestriction(restriction, index - indexOffset, entity, entityType, anomalies, fix);

        // Remove if empty
        if (Object.keys(restriction).length === 0) {
            restrictions.splice(index, 1);
            indexOffset++;
        }
    });
}

export const checkInvalidRestriction = (
    restriction: Restriction,
    index: number,
    entity: CatalogEntityWithRestriction,
    entityType: CatalogEntityType,
    anomalies: CatalogAnomaly[],
    fix?: boolean,
): void => {

    let anomalyFound = false;
    const anomaly: InvalidRestriction = {
        anomaly_type: CatalogAnomalyType.INVALID_RESTRICTION,
        entity,
        type: entityType,
        restriction_index: index,
    }

    if (fix) {
        anomaly.fixed = true;
    }

    // Dow: format
    if (!isValidDow(restriction.dow)) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.dow; }
    }

    // Times (HH:MM): format
    if (!isValidTime(restriction.start_time)) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.start_time; }
    }
    if (!isValidTime(restriction.end_time)) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.end_time; }
    }

    // Dates: format
    if (restriction.start_date && !DateTime.fromISO(restriction.start_date).isValid) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.start_date; }
    }
    if (restriction.end_date && !DateTime.fromISO(restriction.end_date).isValid) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.end_date; }
    }

    // Service_types: at least one
    if (restriction.service_types && restriction.service_types.length === 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.service_types; }
    }

    // Min order amount: must be >= 0€
    if (restriction.min_order_amount && moneyToNumber(restriction.min_order_amount) < 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.min_order_amount; }
    }

    // Min preparation time: must be >= 0
    if (!_.isNil(restriction.min_preparation_time) && restriction.min_preparation_time <= 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.min_preparation_time; }
    }

    // Order slot time: must be > 0
    if (!_.isNil(restriction.order_slot_time) && restriction.order_slot_time <= 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.order_slot_time; }
    }

    // Max orders per slot
    if (!_.isNil(restriction.max_orders_per_slot) && restriction.max_orders_per_slot <= 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.max_orders_per_slot; }
    }

    // Timeslot display time limit: must be > 0
    if (!_.isNil(restriction.timeslots_display_time_limit) && restriction.timeslots_display_time_limit <= 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.timeslots_display_time_limit; }
    }

    // Payment type refs: at least one
    if (restriction.supported_payment_types_refs && restriction.supported_payment_types_refs.length === 0) {
        if (!anomalyFound) { anomalies.push(anomaly); anomalyFound = true; }
        if (fix) { delete restriction.supported_payment_types_refs; }
    }
}

/**
 * Tells if there is a possibility to place an order at the given time and service type
 * according to the catalog, location and table restrictions.
 * @param catalog 
 * @param location 
 * @param table 
 * @returns 
 */
export const canPlaceOrderNow = (
    location: Pick<Location, "id" | "account_id" | "restriction" | "supported_service_types" | "timezone"> | undefined,
    catalog: Pick<Catalog, "restrictions" | "timezone"> | undefined,
    table: Pick<Table, "restrictions" | "service_type">,
    now: DateTime,
): {
    canPlaceOrderNow: boolean,
    nextAvailbleTime?: DateTime,
} => {

    const intersectedRestrictions = intersectCatalogLocationTableRestrictions(
        catalog,
        location,
        table,
        table.service_type,
    );

    if (intersectedRestrictions) {

        if (intersectedRestrictions.length === 0) {
            return {
                canPlaceOrderNow: false,
                // Cannot calculate the next available time because there's no restriction available
            }
        }

        const timezoneName = getTimezoneNameFromLocation(location) ?? getTimezoneNameFromCatalog(catalog) ?? DEFAULT_TIMEZONE_NAME;
        const isAvailable = isTemporallyAvailable(moment(now.toJSDate()), timezoneName, intersectedRestrictions, table.service_type);

        if (!isAvailable) {
            return {
                canPlaceOrderNow: false,
                nextAvailbleTime: getNextAvailableTime(intersectedRestrictions, now, table.service_type) ?? undefined,
            }
        }
    }

    return {
        canPlaceOrderNow: true,
    }
}

interface GetCategoryAndParentRestrictionsIntersectionAndDisableStateResult {
    restrictions: Restriction[] | undefined;
    disableState: Pick<BaseCatalogEntity, "disable" | "disable_up_to_time">;
}

const getCategoryAndParentRestrictionsIntersectionAndDisableState = (
    category: Categorie,
    catalogCategories: Categorie[] | undefined
): GetCategoryAndParentRestrictionsIntersectionAndDisableStateResult => {

    if (category.parent_ref) {

        const foundParentCategory = catalogCategories?.find(c => c.ref === category.parent_ref);
        const parentCategoryRestrictions = getRestrictionsArray(foundParentCategory?.restrictions);

        return {
            restrictions: intersectRestrictionLists(getRestrictionsArray(category.restrictions), parentCategoryRestrictions) ?? undefined,
            disableState: {
                disable: category.disable || foundParentCategory?.disable,
                disable_up_to_time: getMostRecentDate([category.disable_up_to_time, foundParentCategory?.disable_up_to_time]),
            }
        }
    }
    // Simple category
    else {
        return {
            restrictions: getRestrictionsArray(category.restrictions) ?? undefined,
            disableState: {
                disable: category.disable,
                disable_up_to_time: category.disable_up_to_time,
            }
        }
    }
}

/**
 * Tells if there is a possibility to order the given entity at the given time and service type
 * according to its restrictions, but also its parent category/ies restrictions.
 * @param location 
 * @param catalog 
 * @param entity 
 * @param serviceType 
 * @param now 
 * @returns 
 */
export const canOrderEntityNow = (
    location: Pick<Location, "id" | "account_id" | "restriction" | "timezone"> | undefined,
    catalog: Pick<Catalog, "data" | "restrictions" | "timezone"> | undefined,
    entity: Product | Categorie | Deal,
    serviceType: SupportedServiceType | undefined,
    now: DateTime,
): {
    canOrderEntityNow: boolean,
    nextAvailbleTime?: DateTime,
} => {

    const timezoneName = getTimezoneNameFromLocation(location) ?? getTimezoneNameFromCatalog(catalog) ?? DEFAULT_TIMEZONE_NAME;

    // Getting the restrictions intersection & filling the disable state
    let intersectedRestrictions: Restriction[] | undefined;
    const disableState: Pick<BaseCatalogEntity, "disable" | "disable_up_to_time"> = {};
    if ("category_ref" in entity) {

        const product = entity as Product;

        const foundCategory = catalog?.data.categories.find(c => c.ref === product.category_ref);
        const restrictionsAndDisableState = foundCategory ? getCategoryAndParentRestrictionsIntersectionAndDisableState(foundCategory, catalog?.data.categories) : undefined;

        intersectedRestrictions = intersectRestrictionLists(getRestrictionsArray(product.restrictions), restrictionsAndDisableState?.restrictions) ?? undefined;
        disableState.disable = product.disable || disableState.disable || restrictionsAndDisableState?.disableState.disable;
        disableState.disable_up_to_time = getMostRecentDate([product.disable_up_to_time, disableState.disable_up_to_time, restrictionsAndDisableState?.disableState.disable_up_to_time]);
    }
    else {

        const category = entity as Categorie;

        const restrictionsAndDisableState = getCategoryAndParentRestrictionsIntersectionAndDisableState(category, catalog?.data.categories);
        intersectedRestrictions = restrictionsAndDisableState.restrictions;
        disableState.disable = restrictionsAndDisableState.disableState.disable;
        disableState.disable_up_to_time = restrictionsAndDisableState.disableState.disable_up_to_time;
    }

    // Checking the disable flag
    if (disableState.disable) {

        let nextTime: DateTime | undefined;

        if (disableState.disable_up_to_time) {
            const extractedDate = extractDateFromTimestampOrString(disableState.disable_up_to_time);
            if (extractedDate) {
                nextTime = DateTime.fromJSDate(extractedDate).setZone(timezoneName);
            }
        }

        return {
            canOrderEntityNow: false,
            nextAvailbleTime: nextTime,
        }
    }

    // Now that we have the restrictions, we can check if the entity matches them
    if (intersectedRestrictions) {

        if (intersectedRestrictions.length === 0) {
            return {
                canOrderEntityNow: false,
                // Cannot calculate the next available time because there's no restriction available
            }
        }

        const isAvailable = isTemporallyAvailable(moment(now.toJSDate()), timezoneName, intersectedRestrictions, serviceType, true);

        if (!isAvailable) {
            return {
                canOrderEntityNow: false,
                nextAvailbleTime: getNextAvailableTime(intersectedRestrictions, now, serviceType) ?? undefined,
            }
        }
    }

    return {
        canOrderEntityNow: true,
    }
}

export const sortServiceTypesAccordingToReferenceArray = (serviceTypes: SupportedServiceType[], referenceArray: SupportedServiceType[]): SupportedServiceType[] => {
    return serviceTypes.sort((a, b) => {
        const indexA = referenceArray.indexOf(a);
        const indexB = referenceArray.indexOf(b);

        if (indexA === -1 || indexA === undefined) {
            return 1;
        }
        if (indexB === -1 || indexB === undefined) {
            return -1;
        }

        return indexA - indexB;
    });
}

export const moveViewServicePosition = (sortedServiceTypesToMutate: SupportedServiceType[]): SupportedServiceType[] => {
    if (sortedServiceTypesToMutate.length < 3) return sortedServiceTypesToMutate;
    const viewServiceTypeIndex = sortedServiceTypesToMutate.findIndex((serviceType: SupportedServiceType) => {
        return serviceType === SupportedServiceType.VIEW
    })
    if (viewServiceTypeIndex < 0) return sortedServiceTypesToMutate;
    sortedServiceTypesToMutate.splice(viewServiceTypeIndex, 1);
    sortedServiceTypesToMutate.push(SupportedServiceType.VIEW);
    return sortedServiceTypesToMutate;
}

interface WithinRestrictionOptions {
    /**
     * Specifies if validated value is an array and each of its items must be validated.
     */
    each?: boolean;
}

interface RestrictionResult {
    restricted: boolean

    constraints: RestrictionInvalidityReason[]
}

export const isWithinRestrictionRestrictions = (restriction: Restriction, values: WithinRestrictionValues, options: WithinRestrictionOptions = { each: true }): RestrictionResult => {
    const { date, timezone, service_type, table_area_ref } = values;

    const constraints: RestrictionInvalidityReason[] = [];

    function returnResult() {
        return {
            restricted: constraints.length > 0,
            constraints
        }
    }

    if (service_type && !isEmpty(restriction.service_types)) {
        const allowed_service_types = includes(restriction.service_types, service_type);

        if (!allowed_service_types) {
            constraints.push(RestrictionInvalidityReason.RESTRICTION_SERVICE_TYPE_NOT_RESPECTED);

            if (!options.each) {
                return returnResult()
            }
        }
    }

    if (table_area_ref && !isEmpty(restriction.table_areas_refs)) {
        const allowed_table_areas = includes(restriction.table_areas_refs, table_area_ref);

        if (!allowed_table_areas) {
            constraints.push(RestrictionInvalidityReason.RESTRICTION_TABLE_AREA_NOT_RESPECTED);

            if (!options.each) {
                return returnResult()
            }
        }
    }

    if (date && date instanceof Date) {
        const reference = DateTime.fromJSDate(date).setZone(timezone);

        if (restriction.start_date) {
            const start_date = DateTime
                .fromISO(restriction.start_date)
                .startOf('day')
                .setLocale(reference.locale);

            if (!(start_date.isValid && reference >= start_date)) {
                constraints.push(RestrictionInvalidityReason.RESTRICTION_START_DATE_NOT_RESPECTED);

                if (!options.each) {
                    return returnResult()
                }
            }
        }

        if (restriction.end_date) {
            const end_date = DateTime
                .fromISO(restriction.end_date)
                .endOf('day')
                .setLocale(reference.locale);

            if (!(end_date.isValid && reference <= end_date)) {
                constraints.push(RestrictionInvalidityReason.RESTRICTION_END_DATE_NOT_RESPECTED);

                if (!options.each) {
                    return returnResult()
                }
            }
        }

        if (typeof restriction.dow === "string") {
            const dayOfWeek = reference.weekday; // 1 (Monday) to 7 (Sunday)
            const isDayWithinRestriction = restriction.dow.includes(dayOfWeek.toString());

            if (!isDayWithinRestriction) {
                constraints.push(RestrictionInvalidityReason.RESTRICTION_DOW_NOT_RESPECTED);
            }
        }

        if (restriction.start_time) {
            const start_time = setTimeFromString(reference, restriction.start_time);

            if (!(start_time.isValid && reference >= start_time)) {
                constraints.push(RestrictionInvalidityReason.RESTRICTION_START_TIME_NOT_RESPECTED);

                if (!options.each) {
                    return returnResult()
                }
            }
        }

        if (restriction.end_time) {
            const end_time = setTimeFromString(reference, restriction.end_time);

            if (!(end_time.isValid && reference <= end_time)) {
                constraints.push(RestrictionInvalidityReason.RESTRICTION_END_TIME_NOT_RESPECTED);

                if (!options.each) {
                    return returnResult()
                }
            }
        }
    }

    return returnResult();
}

export const isWithinRestrictions = (restriction: Restriction | Restriction[] | null | undefined, values: WithinRestrictionValues, options: WithinRestrictionOptions = { each: true }): RestrictionResult => {
    if (!restriction) {
        return {
            constraints: [],
            restricted: false
        }
    }

    const restriction_list = Array.isArray(restriction) ? restriction : [restriction];

    const restriction_results = restriction_list.map((restriction) => isWithinRestrictionRestrictions(restriction, values, options));

    const combined_constraints = reduce<RestrictionResult, RestrictionInvalidityReason[]>(
        restriction_results,
        (acc, result) => {
            if (result.constraints.length > 0) {
                acc = acc.concat(result.constraints);
            }

            return acc
        },
        []
    );

    return {
        restricted: combined_constraints.length > 0,
        constraints: combined_constraints,
    }
}