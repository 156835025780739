export enum FabType {
    ORDER = "order",
    ADD_ITEMS = "add_items",
    PAY = "pay",
}

export enum OrderSummaryButtonActionTypes {
    CONFIRM = "confirm",                    // order is draft, go to paymentChoice
    SEND_ADDED_ITEMS = "send_added_items",  // order has been already sent but new items can be added
    REORDER = "reorder",                    // order is complete, create a new draft and load the items of the order
    PAY = "pay",                            // order is not fully paid yet, go to paymentChoice
    LOAD = "load",                          // order is draft, load it to the state
    BACK_TO_CATALOG = "back_to_catalog",    // when no action is available, go back to the catalog
    VERIFY_COUPON = 'verify_coupon'          // verify the coupon
}