import { useContext, useEffect, useState } from 'react';
import { DeviceContext } from '../../App';
import { ThemeList } from '../../my-lemonade-library/src/theme/models/ThemesList';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

function useGetRestoTheme() {
    const [isGetRestoTheme, setIsGetRestoTheme] = useState<boolean>(false)
    const { themeName } = useTypedSelector((state: RootState) => state.theme)
    const { mobile_device }: { mobile_device: boolean } = useContext(DeviceContext)

    useEffect(() => {
        if (
            themeName
            && themeName === ThemeList.GET_RESTO
            && mobile_device
        ) {
            setIsGetRestoTheme(true)
        }
    }, [themeName, mobile_device])

    return isGetRestoTheme

}

export default useGetRestoTheme;
