import { Restriction } from "../../restrictions/model/Restriction";

export default interface TableArea {
    ref: string;
    name?: string;
    disabled?: boolean;
    restrictions?: Restriction[] | null;
}

export const DEFAULT_TABLE_AREA: TableArea = {
    ref: "default",
}