import lookup from 'country-code-lookup';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Catalog } from '../../../model/Catalog';
import { CustomerInfo, CustomerInfoFlowChoice, DEFAULT_COUNTRY, Location, RequireCustomerInfo, SupportedServiceType } from '../../../model/Location';
import { getAccountFirestoreDocPath } from '../../accounts/services/AccountService';
import { FIRESTORE_USERS_COLLECTION } from '../../authentications/configs/AuthenticationConfig';
import { SignInProviders } from '../../authentications/models/BaseUser';
import { FIRESTORE_BACKUPS_COLLECTION } from '../../common/configs/CommonFirestoreConfig';
import { updateCurrencyOfMoney } from '../../common/models/Money';
import { MylemonadeContext } from '../../common/models/MyLemonadeContext';
import Position from '../../common/models/Position';
import { log } from '../../common/services/LogService';
import { getStoragePublicUrl } from '../../common/services/StorageHelper';
import { TipsPricingEffect } from '../../tips/models/TipsPricingEffect';
import { FIRESTORE_LOCATIONS_COLLECTION } from '../configs/LocationConfig';
import { BaseBackup } from '../models/BaseBackup';
import LocationDiffsResult, { LocationDiffsResultData } from '../models/LocationDiffsResult';
import LocationLink from '../models/LocationLink';
import LocationLinkRelationType from '../models/LocationLinkRelationType';
import ParentCatalogCategoryServiceTypeUpdate from '../models/ParentCatalogCategoryServiceTypeUpdate';
import { getConnectorTypeFromAnonymousId, getConnectorTypeFromLocation } from './LocationIdService';

export const getLocationsFirestoreCollectionPath = (
    accountId: string,
): string => {
    return `${getAccountFirestoreDocPath(
        accountId,
    )}/${FIRESTORE_LOCATIONS_COLLECTION}`;
};

export const getLocationFirestoreDocPath = (
    accountId: string,
    locationId: string,
): string => {
    return `${getLocationsFirestoreCollectionPath(accountId)}/${locationId}`;
};

export const getLocationLogsLink = (
    projectId: string,
    accountId: string,
    locationId: string,
    date: Date,
    orderId?: string,
) => {
    const orderIdCriteria = orderId
        ? `%0AjsonPayload.order_id%3D%22${orderId}%22`
        : '';
    const link = `https://console.cloud.google.com/logs/query;query=jsonPayload.account_id%3D%22${accountId}%22%0AjsonPayload.location_id%3D%22${locationId}%22${orderIdCriteria};cursorTimestamp=${date.toISOString()}?project=${projectId}`;
    return link;
};

/**
 * Get the storage path for backup
 */
export const getLocationBackupStoragePath = (
    accountId: string,
    locationId: string,
    suffixWithExtension: string,
): string => {
    return `${getLocationFirestoreDocPath(
        accountId,
        locationId,
    )}${suffixWithExtension}`;
};

export const getLocationUsersFirestoreCollectionPath = (
    accountId: string,
    locationId: string,
): string => {
    return `${getLocationFirestoreDocPath(
        accountId,
        locationId,
    )}/${FIRESTORE_USERS_COLLECTION}`;
};

export const getLocationUserFirestoreDocPath = (
    accountId: string,
    locationId: string,
    userId: string,
): string => {
    return `${getLocationUsersFirestoreCollectionPath(
        accountId,
        locationId,
    )}/${userId}`;
};

export const getLocationBackupsFirestoreCollectionPath = (
    accountId: string,
    locationId: string,
): string => {
    return `${getLocationFirestoreDocPath(
        accountId,
        locationId,
    )}/${FIRESTORE_BACKUPS_COLLECTION}`;
};

export const getLocationBackupFirestoreDocPath = (
    accountId: string,
    locationId: string,
    backupId: string,
): string => {
    return `${getLocationBackupsFirestoreCollectionPath(
        accountId,
        locationId,
    )}/${backupId}`;
};

/**
 * Get a CustomerInfo object with the authentication fields filled in,
 * depending on the service_type(s) given.
 * Concretely, the function will iterate trough all the service_types and
 * select for each customerInfo (mandatory, process, ..) the "max" one.
 * For example, if we give: delivery->{auth_process: end, mandatory: true} and
 * collection->{auth_process: beginning, mandatory: false} it will return an object
 * with {auth_process: BOTH, mandatory: true}
 * @param locationRequireCustomerInfo
 * @param serviceTypes
 */
export const getAuthenticationCustomerInfoFromServiceTypes = (
    locationRequireCustomerInfo: RequireCustomerInfo | undefined,
    serviceTypes: SupportedServiceType[] | undefined,
): CustomerInfo | undefined => {
    // No service type, we return nothing
    if (
        !serviceTypes ||
        serviceTypes.length === 0 ||
        !locationRequireCustomerInfo
    ) {
        return undefined;
    }

    // One service_type, we just return the customer info associated
    else if (serviceTypes.length === 1) {
        return locationRequireCustomerInfo[serviceTypes[0]];
    }

    // Random data to create the object
    const finalCustomerInfo: CustomerInfo = {
        expected_time: false,
        first_name: false,
        phone: false,
        email: false,
    };

    // If one is true, we set to true
    let isMandatory: boolean | undefined = undefined;
    let isEmailVerifiedMandatory: boolean | undefined = undefined;

    // We make an union of these arrays
    let authenticationProviders: SignInProviders[] | undefined = undefined;
    let emailDomainsAllowed: string[] | undefined = undefined;

    // We set it to "none" for the moment
    let authenticationProcess: CustomerInfoFlowChoice | undefined = undefined;

    /**
     * Iterating and merging the customer info
     */
    for (const serviceType of serviceTypes) {

        const currentCustomerInfo = locationRequireCustomerInfo[serviceType];

        if (currentCustomerInfo) {
            /***************************
             * Authentication mandatory
             **************************/

            // If undefined, we set it to the current value
            if (
                isMandatory === undefined &&
                currentCustomerInfo.authentication_mandatory !== undefined
            ) {
                isMandatory = currentCustomerInfo.authentication_mandatory;
            }

            // If true, set to to true
            if (currentCustomerInfo.authentication_mandatory) {
                isMandatory = true;
            }

            /**************************
             * Customer email verified
             ***************************/

            // If undefined, we set it to the current value
            if (
                isEmailVerifiedMandatory === undefined &&
                currentCustomerInfo.customer_email_verified !== undefined
            ) {
                isEmailVerifiedMandatory =
                    currentCustomerInfo.customer_email_verified;
            }

            // If true, set to to true
            if (currentCustomerInfo.customer_email_verified) {
                isEmailVerifiedMandatory = true;
            }

            /**************************
             * Authentication providers
             ***************************/

            // Only appending if there is at least one
            if (
                currentCustomerInfo.authentication_providers &&
                currentCustomerInfo.authentication_providers.length > 0
            ) {
                // Adding only the non-existing ones
                for (const provider of currentCustomerInfo.authentication_providers) {
                    if (!authenticationProviders) {
                        authenticationProviders = [provider];
                    } else if (
                        authenticationProviders.indexOf(provider) === -1
                    ) {
                        authenticationProviders.push(provider);
                    }
                }
            }

            /************************
             * Email domains allowed
             ************************/

            // Only appending if there is at least one
            if (
                currentCustomerInfo.email_domains_allowed &&
                currentCustomerInfo.email_domains_allowed.length > 0
            ) {
                // Adding only the non-existing ones
                for (const emailDomain of currentCustomerInfo.email_domains_allowed) {
                    if (!emailDomainsAllowed) {
                        emailDomainsAllowed = [emailDomain];
                    } else if (
                        emailDomainsAllowed.indexOf(emailDomain) === -1
                    ) {
                        emailDomainsAllowed.push(emailDomain);
                    }
                }
            }

            /*************************
             * Authentication process
             *************************/

            const newAuthenticationProcess: CustomerInfoFlowChoice | undefined =
                currentCustomerInfo.authentication_process;

            if (newAuthenticationProcess) {
                // If undefined, we set it to the new one
                if (!authenticationProcess) {
                    authenticationProcess = newAuthenticationProcess;
                }

                // If the authenticationProcess is already to BOTH, we do nothing
                else if (
                    newAuthenticationProcess !== CustomerInfoFlowChoice.NONE &&
                    authenticationProcess !== CustomerInfoFlowChoice.BOTH
                ) {
                    // new one is BOTH : we set authenticationProcess to BOTH as it's the stronger one
                    if (
                        newAuthenticationProcess === CustomerInfoFlowChoice.BOTH
                    ) {
                        authenticationProcess = CustomerInfoFlowChoice.BOTH;
                    }

                    // Old one is NONE: we set it to the new one value
                    else if (
                        authenticationProcess &&
                        authenticationProcess === CustomerInfoFlowChoice.NONE
                    ) {
                        authenticationProcess = newAuthenticationProcess;
                    }

                    // If we meet another one which is higher or the opposite, we set to both
                    else if (
                        (authenticationProcess ===
                            CustomerInfoFlowChoice.START &&
                            newAuthenticationProcess !==
                            CustomerInfoFlowChoice.START) ||
                        (authenticationProcess === CustomerInfoFlowChoice.END &&
                            newAuthenticationProcess !==
                            CustomerInfoFlowChoice.END)
                    ) {
                        authenticationProcess = CustomerInfoFlowChoice.BOTH;
                    }
                }
            }
        }
    }

    if (isMandatory !== undefined) {
        finalCustomerInfo.authentication_mandatory = isMandatory;
    }

    if (isEmailVerifiedMandatory !== undefined) {
        finalCustomerInfo.customer_email_verified = isEmailVerifiedMandatory;
    }

    if (authenticationProcess) {
        finalCustomerInfo.authentication_process = authenticationProcess;
    }

    if (authenticationProviders && authenticationProviders.length > 0) {
        finalCustomerInfo.authentication_providers = authenticationProviders;
    }

    if (emailDomainsAllowed && emailDomainsAllowed.length > 0) {
        finalCustomerInfo.email_domains_allowed = emailDomainsAllowed;
    }

    return finalCustomerInfo;
};

export const getLocationDiffs = (
    previousLocation: Location,
    currentLocation: Location,
): LocationDiffsResult => {
    const changes: { [key: string]: string } = {};
    const locationDiffRes: LocationDiffsResult = {
        data: {},
    };

    // Detecting differences between previous and current location
    // Snippet found here => https://gist.github.com/Yimiprod/7ee176597fef230d1451?permalink_comment_id=3267525#gistcomment-3267525
    const walkObject = (
        base: Record<string, any>,
        object: Record<string, any>,
        path = '',
    ) => {
        for (const key of Object.keys(base)) {
            const currentPath = path === '' ? key : `${path}.${key}`;

            if (object[key] === undefined) {
                changes[currentPath] = '-';
            }
        }

        for (const [key, value] of Object.entries(object)) {
            const currentPath = Array.isArray(object)
                ? path + `[${key}]`
                : path === ''
                    ? key
                    : `${path}.${key}`;

            if (base[key] === undefined) {
                changes[currentPath] = '+';
            } else if (value !== base[key]) {
                if (
                    typeof value === 'object' &&
                    typeof base[key] === 'object'
                ) {
                    walkObject(base[key], value, currentPath);
                } else {
                    changes[currentPath] = object[key];
                }
            }
        }
    };

    walkObject(previousLocation, currentLocation);

    const addModifiedPathToDiffs = (
        locationDiffsKey: keyof LocationDiffsResultData,
        changedPath: string,
    ) => {
        if (locationDiffRes.data[locationDiffsKey]) {
            locationDiffRes.data[locationDiffsKey]?.push(changedPath);
        } else {
            locationDiffRes.data[locationDiffsKey] = [changedPath];
        }
    };

    // Order diffs in the correct array, based on the first path name
    for (const key of Object.keys(changes)) {
        const rootPath = key.split('.')[0].split('[')[0];

        if (['orders', 'order_qr_code', 'discounts'].includes(rootPath)) {
            addModifiedPathToDiffs('orders', rootPath);
        } else if (['restriction'].includes(rootPath)) {
            addModifiedPathToDiffs('restrictions', rootPath);
        } else if (
            [
                'delivery_providers',
                'delivery',
                'delivery_tax',
                'position',
                'custom_fields',
            ].includes(rootPath)
        ) {
            addModifiedPathToDiffs('delivery', rootPath);
        } else if (['loyalty'].includes(rootPath)) {
            addModifiedPathToDiffs('loyalty', rootPath);
        } else if (
            ['supported_payment_types', 'service_fees'].includes(rootPath)
        ) {
            addModifiedPathToDiffs('payments', rootPath);
        } else if (['supported_service_types'].includes(rootPath)) {
            addModifiedPathToDiffs('service_types', rootPath);
        } else if (['require_customer_info'].includes(rootPath)) {
            addModifiedPathToDiffs('customers', rootPath);
        } else if (rootPath !== 'updated_at') {
            addModifiedPathToDiffs('location_infos', rootPath);
        }
    }

    return locationDiffRes;
};

/**
 * Return an array of LocationLinks
 * If for the same relation (eg: Intagram), there are two links, table link is taken
 * @param locationLinks
 * @param tableLinks
 * @returns LocationLink[]
 */
export const mergeLocationLinksAndTableLinks = (
    locationLinks?: LocationLink[],
    tableLinks?: LocationLink[],
): LocationLink[] => {
    if (locationLinks) {
        const mergedLocationLinks: LocationLink[] = locationLinks.reduce(
            (merged: LocationLink[], locLink): LocationLink[] => {
                const tabLink = tableLinks?.find((link) => {
                    if (link.rel === LocationLinkRelationType.CUSTOM) {
                        return link.title === locLink.title;
                    }
                    return link.rel === locLink.rel;
                });
                if (tabLink) {
                    merged.push(tabLink);
                } else {
                    merged.push(locLink);
                }
                return merged;
            },
            [] as LocationLink[],
        );

        const tableLinksWithoutDuplicates =
            tableLinks?.filter((link) => {
                return !mergedLocationLinks.find((locLink) => {
                    if (link.rel === LocationLinkRelationType.CUSTOM) {
                        return link.title === locLink.title;
                    }
                    return link.rel === locLink.rel;
                });
            }) ?? [];

        return [...mergedLocationLinks, ...tableLinksWithoutDuplicates];
    }
    return tableLinks ?? [];
};

/**
 * Check if location is disabled or if the current service type is disabled in the location
 * @param location
 * @param serviceType
 * @returns
 */
export const checkLocationServiceTypeDisabled = (
    location: Location,
    serviceType: SupportedServiceType,
): boolean => {
    return Boolean(location.disabled_service_types?.includes(serviceType));
};

/**
 * Get active service types
 * @param location
 * @returns
 */
export const getActiveServiceTypes = (
    location: Pick<
        Location,
        'supported_service_types' | 'disabled_service_types'
    >,
): SupportedServiceType[] => {
    const activeServiceTypes = location.supported_service_types.filter(
        (supportedServiceType) =>
            !location.disabled_service_types?.includes(supportedServiceType),
    );

    return activeServiceTypes;
};

export const updateLocationCurrency = (location: Location): Location => {
    const currencyCode = location.currency;
    const updatedLocation: Location = _.cloneDeep(location);

    updatedLocation.supported_payment_types.forEach((payment) => {
        if (payment.min_amount) {
            payment.min_amount = updateCurrencyOfMoney(
                payment.min_amount,
                currencyCode,
            );
        }

        if (payment.max_amount) {
            payment.max_amount = updateCurrencyOfMoney(
                payment.max_amount,
                currencyCode,
            );
        }
    });

    if (
        updatedLocation.delivery_providers &&
        updatedLocation.delivery_providers.length > 0
    ) {
        updatedLocation.delivery_providers.forEach((provider) => {
            if (
                provider.customer_prices &&
                provider.customer_prices.length > 0
            ) {
                provider.customer_prices.forEach((customerPrice) => {
                    customerPrice.customer_price = updateCurrencyOfMoney(
                        customerPrice.customer_price,
                        currencyCode,
                    );

                    if (customerPrice.min_provider_price) {
                        customerPrice.min_provider_price =
                            updateCurrencyOfMoney(
                                customerPrice.min_provider_price,
                                currencyCode,
                            );
                    }

                    if (customerPrice.max_provider_price) {
                        customerPrice.max_provider_price =
                            updateCurrencyOfMoney(
                                customerPrice.max_provider_price,
                                currencyCode,
                            );
                    }
                });
            }
        });
    }

    if (updatedLocation.delivery && updatedLocation.delivery.length > 0) {
        updatedLocation.delivery.forEach((delivery) => {
            delivery.default_delivery_fee = updateCurrencyOfMoney(
                delivery.default_delivery_fee,
                currencyCode,
            );

            if (delivery.amount_to_reach_for_free_delivery) {
                delivery.amount_to_reach_for_free_delivery =
                    updateCurrencyOfMoney(
                        delivery.amount_to_reach_for_free_delivery,
                        currencyCode,
                    );
            }
        });
    }

    if (
        updatedLocation.restriction &&
        Object.values(updatedLocation.restriction).length > 0
    ) {
        Object.values(updatedLocation.restriction).forEach((res) => {
            if (res.min_order_amount) {
                res.min_order_amount = updateCurrencyOfMoney(
                    res.min_order_amount,
                    currencyCode,
                );
            }
        });
    }

    if (
        updatedLocation.orders?.tips &&
        updatedLocation.orders.tips.pricing_effect ===
        TipsPricingEffect.FIXED_PRICE
    ) {
        updatedLocation.orders.tips.suggestion_choices =
            updatedLocation.orders.tips.suggestion_choices.map(
                (suggestion: string | number) => {
                    return updateCurrencyOfMoney(
                        suggestion as string,
                        currencyCode,
                    );
                },
            );
    }

    if (updatedLocation.loyalty) {
        if (_.isString(updatedLocation.loyalty.spending_rule.amount)) {
            updatedLocation.loyalty.spending_rule.amount =
                updateCurrencyOfMoney(
                    updatedLocation.loyalty.spending_rule.amount as string,
                    currencyCode,
                );
        }

        if (_.isString(updatedLocation.loyalty.earning_rule.amount)) {
            updatedLocation.loyalty.earning_rule.amount = updateCurrencyOfMoney(
                updatedLocation.loyalty.earning_rule.amount as string,
                currencyCode,
            );
        }
    }

    if (
        updatedLocation.service_fees &&
        updatedLocation.service_fees.length > 0
    ) {
        updatedLocation.service_fees.forEach((serviceFee) => {
            serviceFee.fixed_fee = updateCurrencyOfMoney(
                serviceFee.fixed_fee,
                currencyCode,
            );

            if (serviceFee.min_fee) {
                serviceFee.min_fee = updateCurrencyOfMoney(
                    serviceFee.min_fee,
                    currencyCode,
                );
            }

            if (serviceFee.max_fee) {
                serviceFee.max_fee = updateCurrencyOfMoney(
                    serviceFee.max_fee,
                    currencyCode,
                );
            }

            if (serviceFee.min_percentage_fee) {
                serviceFee.min_percentage_fee = updateCurrencyOfMoney(
                    serviceFee.min_percentage_fee,
                    currencyCode,
                );
            }

            if (serviceFee.max_percentage_fee) {
                serviceFee.max_percentage_fee = updateCurrencyOfMoney(
                    serviceFee.max_percentage_fee,
                    currencyCode,
                );
            }

            if (serviceFee.delivery_range) {
                if (serviceFee.delivery_range.max_provider_price) {
                    serviceFee.delivery_range.max_provider_price =
                        updateCurrencyOfMoney(
                            serviceFee.delivery_range.max_provider_price,
                            currencyCode,
                        );
                }

                if (serviceFee.delivery_range.min_provider_price) {
                    serviceFee.delivery_range.min_provider_price =
                        updateCurrencyOfMoney(
                            serviceFee.delivery_range.min_provider_price,
                            currencyCode,
                        );
                }
            }
        });
    }

    if (updatedLocation.table_areas && updatedLocation.table_areas.length > 0) {
        updatedLocation.table_areas.forEach((tableArea) => {
            if (
                tableArea.restrictions &&
                Object.values(tableArea.restrictions).length > 0
            ) {
                Object.values(tableArea.restrictions).forEach((res) => {
                    if (res.min_order_amount) {
                        res.min_order_amount = updateCurrencyOfMoney(
                            res.min_order_amount,
                            currencyCode,
                        );
                    }
                });
            }
        });
    }

    return updatedLocation;
};

export const getParentCatalogCategoryServiceTypeUpdates = (
    beforeChildLocation: Location,
    afterChildLocation: Pick<
        Location,
        'supported_service_types' | 'disabled_service_types' | 'id'
    >,
    childCatalogs: Catalog[],
): ParentCatalogCategoryServiceTypeUpdate[] => {
    const parentCatalogCategoryUpdates: ParentCatalogCategoryServiceTypeUpdate[] =
        [];
    if (
        !_.isEqual(
            beforeChildLocation.disabled_service_types,
            afterChildLocation.disabled_service_types,
        )
    ) {
        const activeServiceType = getActiveServiceTypes(afterChildLocation);
        log.info(
            `Disabled service types have changed, check if a parent must be updated`,
            {
                active_service_types: activeServiceType,
            },
        );
        childCatalogs?.forEach((childCatalog) => {
            if (
                childCatalog.parent_catalog &&
                childCatalog.parent_catalog.catalog_id &&
                childCatalog.parent_catalog.location_id
            ) {
                log.info(
                    `Child catalog ${childCatalog.id} has a parent catalog which needs to be updated`,
                    {
                        parent_catalog: childCatalog.parent_catalog,
                    },
                );

                parentCatalogCategoryUpdates.push({
                    parent_catalog_id: childCatalog.parent_catalog.catalog_id,
                    parent_location_id: childCatalog.parent_catalog.location_id,
                    child_catalog_id: childCatalog.id!,
                    child_location_id: afterChildLocation.id,
                    active_service_types: activeServiceType,
                });
            }
        });
    }

    return parentCatalogCategoryUpdates;
};

export const setContextFromLocation = (
    context: MylemonadeContext,
    location: Location,
) => {
    if (location) {
        if (!context.location_id) {
            context.location_id = location.id;
        }
        if (!context.account_id) {
            context.account_id = location.account_id;
        }
    }
};

export const getLocationStorageRelativePath = (
    accountId: string,
    locationId: string,
): string => {
    return `accounts/${accountId}/locations/${locationId}`;
};

export const getLocationImagesStoragePath = (
    gcpProjectId: string,
    bucketPath: string,
    token?: string,
): string => {
    return getStoragePublicUrl(gcpProjectId, bucketPath, token);
};

export const mapBackupsByDay = (
    backupsMap: { [dayISO: string]: BaseBackup[] },
    backupsList: BaseBackup[],
    timezoneName: string,
): void => {
    backupsList.forEach((backup) => {
        const dayISO = DateTime.fromJSDate(backup.diff_previous_updated_at)
            .setZone(timezoneName)
            .startOf('day')
            .toISODate();
        if (!backupsMap[dayISO]) {
            backupsMap[dayISO] = [backup];
        } else {
            backupsMap[dayISO].push(backup);
        }
    });
};

export const getLocationPosition = (
    location: Location,
): Position | undefined => {
    if (
        location &&
        location.position &&
        location.position.latitude &&
        location.position.longitude
    ) {
        return location.position;
    }

    return undefined;
};

export const getLocationCountryCode = (location: Location): string => {
    if (location && location.country) {
        if (location.country.length === 2) {
            return location.country;
        } else {
            const foundCountry = lookup.byCountry(location.country);
            if (foundCountry) {
                return foundCountry.iso2;
            } else {
                log.error(`Country ${location.country} seems to be invalid`);
                return DEFAULT_COUNTRY;
            }
        }
    }

    log.error(`No country for this location`);
    return DEFAULT_COUNTRY;
};

export const locationHelper = {
    setContextFromLocation,
    getAuthenticationCustomerInfoFromServiceTypes,
    getLocationDiffs,
    mergeLocationLinksAndTableLinks,
    checkLocationServiceTypeDisabled,
    updateLocationCurrency,
    getActiveServiceTypes,
    getParentCatalogCategoryServiceTypeUpdates,
    getLocationCountryCode,
    getLocationPosition,
    getConnectorTypeFromAnonymousId,
    getConnectorTypeFromLocation
};
export default locationHelper;
