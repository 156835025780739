import { SelectProps, Typography } from "@mui/material";
import { ErrorMessage } from "formik";
import { useIntl } from "react-intl";

type FormikErrorMessageProps = {

    name: string;

} & Omit<SelectProps, 'value'>

const FormikErrorMessage: React.FC<FormikErrorMessageProps> = (props) => {

    const intl = useIntl();

    const { name, ...rest } = props;

    return (

        <ErrorMessage
            name={name}
            {...rest}
        >
            {(messageId) => {

                return (

                    <Typography color="error">

                        {intl.formatMessage({ id: messageId })}

                    </Typography>
                );
            }}

        </ErrorMessage>
    )
}

export default FormikErrorMessage;