import { CatalogExtended } from '../../my-lemonade-library/model/catalogExtended/CatalogExtended';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { Location, SupportedServiceType, Table } from '../../my-lemonade-library/model/Location';
import { OrderInBase, OrderItem } from '../../my-lemonade-library/model/Order';

export const INIT_DEAL = "INIT_DEAL";
export interface InitDealAction {
    type: typeof INIT_DEAL;
    payload: {
        deal: DealExt | undefined;
        catalog: CatalogExtended;
        location: Location;
        table: Table;
        order: OrderInBase;
    }
}

export const ADD_DEAL_ITEM = "ADD_DEAL_ITEM";
export interface AddDealItemAction {
    type: typeof ADD_DEAL_ITEM;
    payload: {
        orderItem: OrderItem,
        deal: DealExt,
        catalog: CatalogExtended,
        location: Location,
        table: Table,
        serviceType: SupportedServiceType,
        order: OrderInBase
    };
}

export const RESET_DEAL_LIST_ITEMS = "RESET_DEAL_LIST_ITEMS";
export interface ResetDealListItemsAction {
    type: typeof RESET_DEAL_LIST_ITEMS;
}

export type DealsActionTypes =
    | InitDealAction
    | AddDealItemAction
    | ResetDealListItemsAction;

const actions = {

    initDeal: (
        deal: DealExt | undefined,
        catalog: CatalogExtended,
        location: Location,
        order: OrderInBase,
        table: Table,
    ): InitDealAction => ({
        type: INIT_DEAL,
        payload: {
            deal,
            catalog,
            location,
            table,
            order,
        }
    }),

    addDealItem: (
        orderItem: OrderItem,
        deal: DealExt,
        catalog: CatalogExtended,
        location: Location,
        serviceType: SupportedServiceType,
        order: OrderInBase,
        table: Table,
    ): AddDealItemAction => ({
        type: ADD_DEAL_ITEM,
        payload: { orderItem, deal, catalog, location, serviceType, order, table },
    }),

    resetDealListItems: (): ResetDealListItemsAction => ({
        type: RESET_DEAL_LIST_ITEMS,
    }),
};

export default actions;
