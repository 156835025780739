import _ from "lodash";
import log from "loglevel";
import { IntlShape } from "react-intl";
import { Order, OrderInBase } from "../../my-lemonade-library/model/Order";
import { addMoney, getCurrency, Money, moneyToNumber, MoneyToStringWithSymbol, multiplyMoney, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { OrderItemToPay } from "../../my-lemonade-library/src/payments/models/OrderItemToPay";
import { PaymentAmountType } from "../../my-lemonade-library/src/payments/models/PaymentAmountType";
import { PaymentStatus } from "../../my-lemonade-library/src/payments/models/PaymentStatus";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";

/**
 * Display the total amount of pending payments if it exists
 */
export const displayAmountPending = (order: Order | undefined | null, intl: IntlShape) => {

    if (order) {

        // All payments with pending - all payments without pending
        const amount = paymentHelper.getOrderPaidAmount(order, true) - paymentHelper.getOrderPaidAmount(order, false);
        const paid = paymentHelper.isPaid(order);
        const amountStr = MoneyToStringWithSymbol(numberToMoney(amount, getCurrency(order.total)));

        if (amount !== 0 && !paid) {

            return (
                intl.formatMessage(
                    {
                        id: "orders.sharePayment.pendingAmount"
                    },
                    {
                        amount: amountStr
                    }
                )
            );
        }

    }
}

export const getItemTotal = (order: Pick<Order, "items">, item: OrderItemToPay, currency: string): Money => {

    const foundItemInOrder = order.items[item.index];
    if (!foundItemInOrder) {
        log.error("Could not find item in order", { item, order });
        return numberToMoney(0, currency);
    }

    let price: Money = foundItemInOrder.price;
    if (foundItemInOrder.options_price) {
        price = addMoney(price, foundItemInOrder.options_price);
    }

    const result = multiplyMoney(price, item.quantity);
    return result;
}

/**
 * Necessary condition to display the "items only" configuration on the
 * share payment page, i.e. masking all the other choices (split by, manual amount, ...)
 * To do this, we have to make sure that:
 *  - all payments are linked to at least one item
 *  - the sum of the remaining items is equal to the order left to pay amount
 * @param order 
 * @param currency 
 * @returns 
 */
export const canDisplayItemsOnlyInSharePayment = (
    order: OrderInBase,
    currency: string,
    settingInLocation: boolean,
): boolean => {

    const allPaymentsLinked = paymentHelper.areAllPaymentsLinkedToItems(order);
    const orderLeftTopay = paymentHelper.getOrderRemainingAmount(order);
    const separatedItems = paymentHelper.separatePaidAndRemainingItems(order);

    let itemsLeftToPay = numberToMoney(0, currency);

    separatedItems.remainingItems.forEach((item) => {
        itemsLeftToPay = addMoney(itemsLeftToPay, getItemTotal(order, item, currency));
    });

    Object.keys(separatedItems.remainingDealItems).forEach((dealKey) => {
        const foundOrderDeal = order.deals[dealKey];
        if (foundOrderDeal) {
            itemsLeftToPay = addMoney(itemsLeftToPay, (foundOrderDeal.price_with_options ?? foundOrderDeal.price));
        }
    });

    const result = Boolean(
        settingInLocation
        && allPaymentsLinked
        && orderLeftTopay === _.round(moneyToNumber(itemsLeftToPay), 2)
    );

    log.debug(`Share payment: can display items only?`, {
        result,
        settingInLocation,
        allPaymentsLinked,
        orderLeftTopay,
        itemsLeftToPay,
    });

    return result;
}

export const getManualPaymentAmounts = (orderPayments: Order["payments"]): Money[] => {

    if (!orderPayments) {
        return [];
    }

    // TODO: simplify using filter & map
    return orderPayments.reduce((amountsArr: string[], payment) => {
        if (payment.status === PaymentStatus.PAID) {
            if (payment.manual_additional_amount && moneyToNumber(payment.manual_additional_amount) > 0) {
                amountsArr.push(payment.manual_additional_amount)
            } else if (!payment.amount_type || payment.amount_type === PaymentAmountType.MANUAL) {
                amountsArr.push(payment.amount)
            }
        }
        return amountsArr
    }, []);
}