export enum CategoryReferenceLevel0 {
    FOOD = 'Food',

    BEVERAGES = 'Beverage',

    /**
     * Items we can't categorize
     *
     * e.g. DIVERS 10%
     */
    OTHER = 'Other',

    /**
     * e.g. BUFFET COCKTAIL: both food and beverage
     */
    FORMULA = 'Formula',

    /**
     * Items that we want to ignore for purposes of brands platform
     *
     * e.g. PHOTOBOOTH: product_ref: 219481229781631189
     */
    EXCLUDE = 'Exclude',
}

export const category0_options = Object.values(CategoryReferenceLevel0);