import { SupportedPayementType } from "../../../model/Location";
import { PaymentType } from "../../../model/Order";


export type PaymentTypeExtended = PaymentType | SpecificPaymentType;

export enum SpecificPaymentType {
    LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA = "lyra_marketplace_do_not_use_saved_data",
    LYRA_MARKETPLACE_FORCE_CONECS = "lyra_marketplace_force_conecs",
}

export type SupportedPaymentTypeExtended = Omit<SupportedPayementType, "type"> & {
    type: PaymentTypeExtended;
}