import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, useTheme } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { cursor } from '../../Common/StyledComponents';
import DataTestConst from '../../config/DataTestConst';

export interface PlusMinusQuantityProps {
    value: string | number;
    onAdd: () => void;
    onMinus: () => void;
    min?: number
    max?: number
    optionnalText?: string
}

/**
 * Selector of quantity when one product is selected
 * TODO: what's the difference with the other one
 */
const PlusMinusQuantity: React.FC<PlusMinusQuantityProps> = (props) => {

    const theme = useTheme();
    const intl = useIntl()

    const isMinQuantity = (): boolean => {
        if (!_.isNil(props.min)) {
            return props.value <= props.min
        }
        return false
    }

    const isMaxQuantity = (): boolean => {
        if (!_.isNil(props.max)) {
            return props.value >= props.max
        }
        return false
    }

    return (

        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1.5}
        >

            <IconButton
                data-test={DataTestConst.DECREASE_QUANTITY}
                disabled={isMinQuantity()}
                onClick={props.onMinus}
                style={{
                    width: "32px",
                    height: "32px",
                    border: `1px ${theme.palette.secondary.light} solid`,
                    ...cursor
                }}
                size="large">
                <RemoveIcon style={{
                    color: isMinQuantity() ? theme.palette.secondary.light : theme.palette.secondary.main,
                    fontSize: "19px"
                }} />
            </IconButton>

            <Box
                style={{ color: theme.palette.text.secondary }}
            >
                {props.optionnalText
                    ? `${intl.formatMessage({ id: props.optionnalText })} ${props.value}`
                    : props.value
                }
            </Box>

            <IconButton
                data-test={DataTestConst.INCREASE_QUANTITY}
                disabled={isMaxQuantity()}
                onClick={props.onAdd}
                style={{
                    height: "32px",
                    width: "32px",
                    border: `1px ${theme.palette.secondary.light} solid`,
                    ...cursor
                }}
                size="large">
                <AddIcon style={{
                    color: isMaxQuantity() ? theme.palette.secondary.light : theme.palette.secondary.main,
                    fontSize: "19px"
                }} />

            </IconButton>

        </Box>
    );
}

export default PlusMinusQuantity