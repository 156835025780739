import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import { DeviceContext } from "../../App";
import { cursor, mobilePaperProps } from "../../Common/StyledComponents";
import { getTimezoneName } from "../../my-lemonade-library/model/Catalog";
import { log } from "../../my-lemonade-library/src/common/services/LogService";
import DiscountConfig from "../../my-lemonade-library/src/discounts/models/DiscountConfig";
import discountService from "../../my-lemonade-library/src/discounts/service/DiscountService";
import { useTypedSelector } from "../../redux/root-reducer";
import orderActions from "../redux/OrderActions";

interface TextInputBottomModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    desktop: boolean | undefined;
    text: string;
    setText: (text: string) => void;
    titleId: string;
    placeHolderId: string;
}

const TextInputBottomModal: React.FC<TextInputBottomModalProps> = (props) => {

    const { desktop, open, setOpen, setText, text, titleId, placeHolderId } = props;

    const { selectedCatalog, selectedLocation, selectedTable } = useTypedSelector(state => state.locations)
    const { order } = useTypedSelector(state => state.order)
    const authenticationState = useTypedSelector(state => state.authentication);
    const userId = authenticationState.data.user_authentication_state.user?.uid;

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch()
    const [textField, setTextField] = useState<string>("");
    const { mobile_device } = useContext(DeviceContext)

    /**
     * Method use to check and price the order with the discount if coupon code is valid
     * @param couponCode 
     */
    const onTextFieldChange = (couponCode: string) => {
        if (selectedCatalog && order && selectedLocation && userId) {
            const discounts = selectedCatalog.data.discounts
            if (discounts && discounts.length) {
                const foundDiscount = discounts.find(discount => discount.coupon_codes?.includes(couponCode))
                if (foundDiscount) {
                    const uid = order.user_id || ""
                    const discountConfig: DiscountConfig = selectedCatalog.discounts ? selectedCatalog.discounts : {};
                    const catalogTimeZone = getTimezoneName(selectedCatalog);
                    const isDiscountAvailable = discountService.checkDiscountRestrictions(DateTime.now(), order, uid, foundDiscount, catalogTimeZone, discountConfig, selectedCatalog.id!, false, selectedTable.area_ref ?? undefined, order.discounts);
                    if (isDiscountAvailable) {
                        dispatch(orderActions.addDiscountToOrder(foundDiscount, selectedCatalog, selectedLocation, selectedTable, userId))
                    } else {
                        log.error(`Coupon code not valid`)
                        //TODO: create action to display custom message here
                    }

                }
            } else {
                log.debug(`No discounts in this catalog`)
            }
        } else {
            log.debug(`Catolog or Order is missing, cannot apply discount`)
        }
    }

    const MAX_NUMBER_OF_CHARACTERS = 60;

    /**
     * Set the field when we load the component and when we open it
     */
    useEffect(() => {

        setTextField(text);
    }, [open]);

    return (

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={"paper"}
            fullScreen={false}
            // If on mobile, this makes the dialog sticky to the bottom of the screen
            PaperProps={{
                style: !mobile_device ? { width: '400px' } : { ...mobilePaperProps }
            }}
        >

            <DialogTitle style={{
                fontWeight: "bold",
                flexGrow: 1
            }}
            >

                <IconButton
                    size={desktop ? 'small' : 'medium'}
                    style={{
                        ...cursor,
                        position: 'absolute',
                        top: theme.spacing(1),
                        right: theme.spacing(1),
                        color: theme.palette.text.primary,
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Close />

                </IconButton>

                <Typography
                    variant="h2"
                    color="textSecondary"
                    style={{
                        flexGrow: 1,
                        textAlign: "center",
                        whiteSpace: "nowrap",
                    }}
                >

                    {intl.formatMessage({ id: titleId })}

                </Typography>

            </DialogTitle>

            <DialogContent style={{ padding: 0 }}>

                <Box mx={4} my={2}>

                    <TextField
                        placeholder={intl.formatMessage({ id: placeHolderId })}
                        fullWidth={true}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        value={textField}
                        onChange={(e) => {

                            if (e.target.value.length <= MAX_NUMBER_OF_CHARACTERS) {
                                setTextField(e.target.value)
                            }

                        }}
                    />
                </Box>

            </DialogContent>

            <DialogActions
                disableSpacing
                style={{
                    padding: theme.spacing(2),
                    backgroundColor: 'transparent',
                    justifyContent: "center"
                }}>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setText(textField); setOpen(false); onTextFieldChange(textField) }}
                    style={{
                        padding: theme.spacing(1, 5),
                    }}
                >
                    <Typography style={{ color: theme.palette.primary.contrastText }}>
                        {intl.formatMessage({ id: "Order.cart.customer_notes.dialog.save" })}
                    </Typography>

                </Button>

            </DialogActions>

        </Dialog>
    );
}

export default TextInputBottomModal;