import { Box } from "@mui/material";
import { useField, useFormikContext } from "formik";
import PhoneInput, { CountryData, PhoneInputProps } from "react-phone-input-2";
import FormikErrorMessage from "./FormikErrorMessage";

type FormikPhoneInputProps = {
    initialCountry: string;
    preferredCountries: string[];
    phoneFieldName: string;
    countryFieldName: string;
    disableDropdown?: boolean;
} & Omit<PhoneInputProps, 'value'>

const FormikPhoneInput: React.FC<FormikPhoneInputProps> = (props) => {

    const formik = useFormikContext();

    const { phoneFieldName, countryFieldName, initialCountry, preferredCountries, disableDropdown, ...rest } = props;

    const [phoneField, phoneMeta] = useField<string | undefined>(phoneFieldName);
    const [countryField] = useField<string | undefined>(countryFieldName);

    const getBorderColor = (): string => {
        if (phoneMeta.touched && Boolean(phoneMeta.error)) {
            return "#f44336";
        }
        return "rgba(0, 0, 0, 0.23)";
    }

    return (

        <Box width={1}>

            <PhoneInput
                {...phoneField}
                onChange={(value, country) => {

                    delete phoneMeta.error;

                    const countryCode = (country as CountryData)?.countryCode as string | undefined; // country is CountryData | {}

                    formik.setFieldValue(phoneField.name, value);
                    if (countryCode) {
                        formik.setFieldValue(countryField.name, countryCode);
                    }
                }}
                country={initialCountry.toLowerCase()}
                preferredCountries={preferredCountries.map((country) => country.toLowerCase())}
                specialLabel=""
                {...rest}
                disableDropdown={disableDropdown}

                // Because it is not a MUI component, we have to override some
                // properties to make it look like other textFields.
                // WARNING: if we update MUI, we'll probably have to change some properties
                // TODO: try to replace the outline color from blue to theme.palette.primary.main
                inputStyle={{
                    borderColor: getBorderColor(),
                    width: "100%",
                }}
            />

            <Box marginTop={.6} marginLeft={1}>
                <FormikErrorMessage name={phoneFieldName} />
            </Box>
        </Box>
    )
}

export default FormikPhoneInput;