import { Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PaymentType } from '../../my-lemonade-library/model/Order';
import OrderHeader from '../../orders/components/OrderHeader';
import OrderAction from '../../orders/redux/OrderActions';

//Seems Deprecated because pin_enable not used

const PaymentTable = (props: any) => {

    const [pin, setPin] = useState("")
    const dispatch = useDispatch()

    const theme = useTheme();
    const intl = useIntl();

    return (

        <Box justifyContent="flex-start" style={{ backgroundColor: theme.palette.background.default }}>

            <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="stretch"
                flexDirection="column"
            >


                <OrderHeader
                    titleId={intl.formatMessage({ id: "Summary.myOrder" })}
                    closeIcon
                    resetOrderOnBack
                />

                <Box display="flex" flex={1} p={4} textAlign="center" flexDirection="column">

                    <Typography>
                        {intl.formatMessage({ id: "Payment.confirmMessage" })}
                    </Typography>

                    <input
                        onChange={(e) => setPin(e.target.value)}
                        style={{ margin: 30, height: 50, textAlign: "center" }}
                    />

                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(OrderAction.send(PaymentType.TABLE, pin))}
                >
                    <Typography variant="h4">
                        {intl.formatMessage({ id: "Payment.confirm" })}
                    </Typography>
                </Button>
            </Box>

        </Box>
    );
}

export default PaymentTable
