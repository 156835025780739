import { Location, Table } from "../../../model/Location";
import { Order, OrderInBase } from "../../../model/Order";
import { ACCOUNT_PARAM } from "../../accounts/configs/AccountsApiRoutes";
import { ONEBOX_BOXID_PARAM } from "../../connectors/models/ConnectorsParams";
import { LOCATION_PARAM, LOCATION_REF_PARAM } from "../../locations/configs/LocationsApiRoutes";
import LocationLink from "../../locations/models/LocationLink";
import LocationLinkRelationType from "../../locations/models/LocationLinkRelationType";
import { ORDER_ID_PARAM, ORDER_REF_PARAM } from "../../orders/configs/OrdersApiRoutes";
import { orderService } from "../../orders/services/OrderService";
import { PRINTER_ID_PARAM, RECEIPT_REF_PARAM } from "../../printers/configs/PrintersApiRoutes";
import { TABLE_REF_PARAM } from "../../tables/configs/TableApiRoutes";

const LINKS_NOT_DISPLAYED_IN_LIST: LocationLinkRelationType[] = [LocationLinkRelationType.LOYALTY, LocationLinkRelationType.ORDER, LocationLinkRelationType.PAY, LocationLinkRelationType.VIEW_MENU];
class LinktreeHelper {

    isReceiptLink(link: LocationLink): boolean {
        if (link.rel === LocationLinkRelationType.RECEIPT) {
            return true;
        }
        // Temporary
        if (link.href?.startsWith("https://storage.googleapis.com/my-lemonade-receipts")) {
            return true;
        }

        return false;
    }

    replacePlaceholdersInLink(linkUrl: string, location?: Location, table?: Table, order?: Order): string {
        if (location) {
            linkUrl = linkUrl.replace(LOCATION_PARAM, location.id);
            linkUrl = linkUrl.replace(LOCATION_REF_PARAM, location.ref ?? "");
            linkUrl = linkUrl.replace(ACCOUNT_PARAM, location.account_id);
        }
        if (table) {
            linkUrl = linkUrl.replace(TABLE_REF_PARAM, table.ref);
        }
        if (order) {
            linkUrl = linkUrl.replace(ONEBOX_BOXID_PARAM, order.printer_ref ?? "")
            linkUrl = linkUrl.replace(ORDER_REF_PARAM, order.receipt_ref ?? "")
            linkUrl = linkUrl.replace(PRINTER_ID_PARAM, order.printer_ref ?? "")
            linkUrl = linkUrl.replace(RECEIPT_REF_PARAM, order.receipt_ref ?? "")
            linkUrl = linkUrl.replace(ORDER_ID_PARAM, order.id)
        }
        return linkUrl;
    }

    /**
     * 
     * @param link 
     * @param order 
     * @returns 
     */
    shouldDisplayLinkInLinktree(link: LocationLink, order?: OrderInBase): boolean {
        if (link.disable) {
            return false;
        }
        if (LINKS_NOT_DISPLAYED_IN_LIST.includes(link.rel)) {
            return false;
        }

        // If it's an order link, check if the order
        if (link.href.includes(ORDER_REF_PARAM) || link.href.includes(ORDER_ID_PARAM) || link.href.includes(RECEIPT_REF_PARAM)) {
            // If it's a draft order, not suppose to open link
            if (!order || orderService.isFullyEditable(order)) {
                return false;
            }
        }

        return true;
    }
}
const linktreeHelper = new LinktreeHelper();
export default linktreeHelper;