import { Catalog, Categorie, Product, Sku } from "../../../model/Catalog";
import { SupportedServiceType } from "../../../model/Location";
import { OrderInBase, OrderItem } from "../../../model/Order";
import productService from "../../products/services/ProductService";
import { Tax } from "../../taxes/models/Tax";
import TaxError from "../../taxes/models/TaxError";
import taxHelper from "../../taxes/services/TaxHelper";
import childOrderHelper from "./ChildOrderHelper";

/**
 * 300g by default
 */
export const DEFAULT_ITEM_WEIGHT = 300;

class OrderCatalogHelper {

    getProductCategoryRef(category_ref: string, categories: Categorie[], result: string[]): string[] {
        const category = categories.find(category => category.ref === category_ref);

        if (!category) {
            return result;
        }

        result.push(category.ref);

        if (category?.parent_ref) {
            return this.getProductCategoryRef(category.parent_ref, categories, result);
        }

        return result;
    }

    affectCatalogInfo(order: OrderInBase, catalog: Catalog, doNotThrow?: boolean): void {
        order.items.forEach(item => {
            this.affectCatalogInfoToItem(order.id, order.service_type, item, catalog, doNotThrow)
        });
    }

    /**
     * Affect catalog info such as categories, product name, child location, taxes
     * @param orderId 
     * @param serviceType 
     * @param item 
     * @param catalog 
     * @param doNotThrow 
     * @returns 
     */
    affectCatalogInfoToItem(orderId: string, serviceType: SupportedServiceType, item: OrderItem, catalog: Catalog, doNotThrow?: boolean): {
        hasChildLocationItems?: boolean;
        product?: Product;
        sku?: Sku;
        tax?: Tax;

    } {
        let hasChildLocationItems = false;
        const categories = catalog.data.categories;
        const products = catalog.data.products;
        const usedProduct: Product | undefined = productService.getProductBasedOnProductRef(item.product_ref, products, catalog.location_id, catalog.ref, doNotThrow);
        let foundTax: Tax | undefined;
        let usedSku: Sku | undefined;

        if (usedProduct) {
            if (!item.product_name) {
                item.product_name = usedProduct.name;
            }

            usedSku = productService.getSkuBasedOnSkuRefAndProduct(item.sku_ref, usedProduct, catalog.location_id, catalog.ref, doNotThrow);

            if (!usedSku?.weight) {
                item.weight = item.quantity * DEFAULT_ITEM_WEIGHT;
            } else {
                item.weight = item.quantity * usedSku.weight;
            }

            // Affect categories
            if (!item.categories_refs?.length) {
                item.categories_refs = this.getProductCategoryRef(usedProduct.category_ref, categories, [])
            }

            if (usedProduct.child_location) {
                hasChildLocationItems = true;
                // Fill the child order infos, the order_id is not yet known
                item.child_orders_item = {
                    location_id: usedProduct.child_location.location_id,
                    location_name: usedProduct.child_location.location_name ?? "",
                    catalog_id: usedProduct.child_location.catalog_id,
                    order_id: childOrderHelper.getChildOrderId(orderId, usedProduct.child_location.location_id, usedProduct.child_location.catalog_id)
                }
                if (usedProduct.child_location.payment_seller_id) {
                    item.child_orders_item!.payment_seller_id = usedProduct.child_location.payment_seller_id;
                }
            }

            if (usedProduct.tax_ref) {
                foundTax = catalog.taxes?.find((tax) => tax.ref === usedProduct.tax_ref);
                if (!foundTax) {
                    if (!doNotThrow) {
                        throw TaxError.TAX_NOT_FOUND.withValue({
                            tax_ref: usedProduct.tax_ref
                        })
                    }
                } else {
                    if (!item.tax_ref) {
                        item.tax_ref = foundTax.ref;
                    }
                    if (!item.tax_rate) {
                        item.tax_rate = taxHelper.getTaxRate(foundTax, serviceType);
                    }
                }
            } else {
                // TODO: default tax rate ?
            }
        }

        return {
            product: usedProduct,
            sku: usedSku,
            tax: foundTax,
            hasChildLocationItems
        }
    }
}
const orderCatalogHelper = new OrderCatalogHelper();
export default orderCatalogHelper;