import log from 'loglevel';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DeviceContext } from '../../App';
import * as ROUTES from '../../config/routes';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

/**
 * Custom hook in charge to define if the header should be render or not
 * Base on current application page and condition register in catalog.
 * The header is render by default
 * @returns 
 */
function useHeaderDisplay(): boolean {

    const [displayHeader, setDisplayHeader] = useState<boolean>(true);
    const { tableLinkId, selectedCatalog } = useTypedSelector((state: RootState) => state.locations);

    const { mobile_device } = useContext(DeviceContext)
    const history = useHistory();

    useEffect(() => {

        if (!mobile_device) {
            setDisplayHeader(true);  // Always display on desktop
            return;
        }

        if (
            (history && tableLinkId && history.location.pathname === `/${tableLinkId}`)
            || (history && tableLinkId && history.location.pathname === `/${tableLinkId}/`)
            || (history && tableLinkId && history.location.pathname === "/" + tableLinkId + ROUTES.Menu)
            || (history && tableLinkId && history.location.pathname === "/" + tableLinkId + ROUTES.OrderSummary)
            || (history && tableLinkId && history.location.pathname === "/" + tableLinkId + ROUTES.PaymentShareItems)
        ) {

            log.debug(`Currently at home page, hide the header`)
            setDisplayHeader(false)
            return;
        }
        else if (selectedCatalog) {

            if (selectedCatalog.display && selectedCatalog.display.hide_header) {

                log.debug(`Condition hide_header set to ${selectedCatalog.display.hide_header} in selectedCatalog => hide the header`)
                setDisplayHeader(false)
                return

            }
            else {

                log.debug(`No condition hide_header found in selectedCatalog => render the header`)
                setDisplayHeader(true)
            }
        }
        else {

            log.debug(`No match condition, default situation => render the header`)
            setDisplayHeader(true)
        }

    }, [history, history.location.pathname, tableLinkId, selectedCatalog])

    return displayHeader

}

export default useHeaderDisplay;