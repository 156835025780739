import log from "../../Common/services/LogService";
import * as ROUTES from '../../config/routes';
import CustomerInformationModalFormOpenArgs from "../../customers/models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "../../customers/services/BaseFormModalService";
import { Location, SupportedServiceType, Table } from "../../my-lemonade-library/model/Location";
import { OrderInBase } from "../../my-lemonade-library/model/Order";
import { orderHasNewItems } from "../../my-lemonade-library/src/orders/helpers/OrderHelpers";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";

export const shouldDisplayOrderPayModalOnHome = (table: Table | undefined): boolean => {
    return (
        (table?.service_type === SupportedServiceType.VIEW && tableHasLinks(table))
        || table?.service_type === SupportedServiceType.EAT_IN
        || table?.service_type === SupportedServiceType.CHECKOUT
    );
    // Other service types have specific modals
}

export const tableHasLinks = (table: Table | undefined): boolean => {
    if (table && table.links && table.links.length) {
        return true;
    }
    return false
}

/**
 * Check in the latest orders if there's an order to pay
 * @param initOrdersState 
 * @returns 
 */
export const canPaySpecificOrder = (
    orderFromState: OrderInBase,
    selectedLocation: Location | undefined,
    selectedTableId: string,
): boolean => {

    return Boolean(
        selectedLocation
        && paymentHelper.canPay(selectedLocation, orderFromState)
        // TODO: maybe check also if catalog and service_type are matching
        && orderFromState.table_id === selectedTableId
    );
}

export default class OrderLoadedPayOrUseLoyaltyFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY);
    }

    shouldDisplayLogo() {
        return true;
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: null } | null {

        const { selectedTable, orderIdQueryParam, orderRefQueryParam, order, currentPathname, tableLinkId, selectedLocation } = args;

        if (
            shouldDisplayOrderPayModalOnHome(selectedTable)
            && ROUTES.isHomePage(currentPathname, tableLinkId)
        ) {
            log.debug(`>-C-> Showing the ${this.form} form on the home page`);
            return { form: this.form, parameters: null };
        }

        // Open order awaiting payment
        const canPayOrder = canPaySpecificOrder(order, selectedLocation, selectedTable.id);
        if (
            (canPayOrder || orderRefQueryParam || orderIdQueryParam)
            && ROUTES.isHomePage(currentPathname, tableLinkId)
        ) {

            if (canPayOrder && !orderHasNewItems(order)) {

                log.debug(`>-C-> An order (${order.display_id}) from latest orders is waiting to be paid -> showing the ORDER_AWAITING_PAYMENT form`);
                return { form: this.form, parameters: null };
            }
            else if (orderRefQueryParam || orderIdQueryParam) {

                log.debug(`>-C-> The order (${order.display_id}) has been loaded from by its ID or REF -> showing the ORDER_AWAITING_PAYMENT form`);
                return { form: this.form, parameters: null };
            }
            else {

                log.debug(`>-C-> No order waiting to be paid -> no need to show the modal`);
            }
        }

        /** Check autoclose if need to be added
         * if (
            (
                !isThereAnOrderToPay(initOrdersState, selectedLocation, selectedTable.id)
                && !orderRefURL
                && !orderIdURL
                && !
                (shouldDisplayOrderPayModalOnHome(selectedTable)
                    && ROUTES.isHomePage(history.location.pathname, tableLinkId))
            )
            || history.location.pathname.includes(ROUTES.OrderSummaryPage)
            || history.location.pathname.includes(ROUTES.ConfirmationWord)
            || history.location.pathname.includes(ROUTES.ConfirmPayment)
            || history.location.pathname.includes(ROUTES.SharePayment)
            || history.location.pathname.includes(ROUTES.MyInformations)
        ) {
            message = "(no order to pay & no order fetched by ref), or user made a choice on the modal, or wrong page"
            closeModal = true;
        }
         */

        return null;
    }
};