import { Box, Button, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DefaultBackground from '../../assets/bg_home.png';
import { CaroussellContainer, ImageContainer } from '../../Common/StyledComponents';
import { Product } from '../../my-lemonade-library/model/Catalog';
import { SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import Price from '../../Products/component/Price';
import ProductModal from '../../Products/pages/ProductModal';
import { useTypedSelector } from '../../redux/root-reducer';

interface BestSellerCarouselProps {
    item: Product,
    interactif: any,
    autoPlay: boolean,
    setAutoPlay: any
}

/**
 * Component Card of the Best-seller Caroussell component
 * render by BestSeller.tsx
 * @param props 
 */

const BestSellerCarousel: React.FC<BestSellerCarouselProps> = (props) => {

    const [open, setOpen] = useState<boolean>(false);
    const intl = useIntl();
    const theme = useTheme();

    const { selectedLocation } = useTypedSelector(state => state.locations);

    const openModal = () => {
        setOpen(true);
        props.setAutoPlay(false)
    }

    useEffect(() => {

        if (open === false && props.autoPlay === false) {
            props.setAutoPlay(true)
        }
    })

    let sku: SkuExtended | null = null;
    let reduced: boolean = false;
    let price: string | undefined = ""
    if (props.item) {
        if (props.item.skus && props.item.skus.length > 0 && props.item.skus[0]) {
            sku = props.item.skus[0];
            reduced = sku.reduced_price ? true : false;
            price = reduced ? sku.reduced_price : sku.price;
        } else {
            log.error(`No sku for product ${props.item.ref}`);
        }
    }

    return (

        <CaroussellContainer>

            {price ? (<Price price={price} reduced={reduced} />) : ""}

            <ImageContainer
                image={props.item.image ? props.item.image : selectedLocation?.background_image ? selectedLocation?.background_image : DefaultBackground}
                style={{
                    height: "100%",
                }}
            >

                <Box
                    style={{
                        color: theme.palette.common.white
                    }}
                    textAlign="center"
                >
                    <Typography variant="h2">
                        {intl.formatMessage({
                            id: translationService.getProductNameTranslationKey(props.item),
                            defaultMessage: props.item.name
                        })}
                    </Typography>
                </Box>

                <Box
                    mx={2}
                    style={{
                        color: theme.palette.common.white,
                        opacity: .8,
                    }}
                >
                    <Typography>
                        {intl.formatMessage({
                            id: translationService.getProductDescriptionTranslationKey(props.item),
                            defaultMessage: props.item.description
                        })}
                    </Typography>
                </Box>

                {props.interactif &&

                    <Box height={45}>

                        <ProductModal
                            open={open}
                            closeModal={() => setOpen(false)}
                            selectedProduct={props.item}
                        />

                        <Button
                            onClick={openModal}
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: "none",
                            }}
                        >

                            <Typography>
                                {intl.formatMessage({
                                    id: "BestSeller.Command",
                                })}
                            </Typography>

                        </Button>

                    </Box>
                }

            </ImageContainer>
        </CaroussellContainer>
    );
}

export default BestSellerCarousel;
