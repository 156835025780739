import { CloudDownload } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Icon, Typography, useMediaQuery, useTheme } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DeviceContext } from '../../App';
import ShareOrderButton from "../../Common/components/ShareOrderButton";
import { defaultMyLemonadeSummaryBottomShadow, desktopDisplayBreakpoint } from "../../config/theme";
import { OrderCharge, OrderInBase } from "../../my-lemonade-library/model/Order";
// TODO : replace the first letter of this function in the library
import { DEFAULT_CURRENCY, getCurrency, Money, moneyToNumber, MoneyToStringWithSymbol as moneyToStringWithSymbol, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { getPriceWithSymbol } from "../../my-lemonade-library/src/common/services/Price";
import { DiscountType, OrderDiscount } from "../../my-lemonade-library/src/discounts/models/OrderDiscount";
import discountService from "../../my-lemonade-library/src/discounts/service/DiscountService";
import { displayAmountPending } from "../../Payment/helpers/PaymentHelpers";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { getFabOrderPriceWithoutDiscountCharges } from "../helpers/FabOrderHelpers";
import { getNewLoyaltyDiscountsTotalAmount, isThereALoyaltyOperation } from "../helpers/LoyaltyHelpers";
import { getNumberOfContributors } from "../helpers/OrderHelpers";
import { OrderSummaryButtonActionTypes } from '../models/OrderButtonsModels';
import OrderInvoiceDialog from './OrderInvoiceDialog';

/**
 * Bottom of orderSummaryPage
 * Hold a finalAction on click and the total price of the Order
 */
interface OrderBottomProps {
    onPress: () => void,
    selectedOrder?: OrderInBase | null,
    orderButtonActionType: OrderSummaryButtonActionTypes,
    setAllowMarketing: (allowMarketing: boolean) => void,
    allowMarketing: boolean,
    remainingAmount?: Money,
    hideShareButtonOnDesktop?: boolean,
    disableButton?: boolean,
    warningMessage?: string,
    atLeastOneNewItem: boolean | undefined;
    atLeastOneOldItem: boolean | undefined;
    readOnly?: boolean;
    orderBottomRef: React.RefObject<HTMLDivElement>;
    updateOrderBottomHeight: (height: number) => void;
    addItemAndGoBack: () => void;
    shouldGoToSharePaymentPage: boolean;
}

const OrderBottom: React.FC<OrderBottomProps> = (props) => {

    const {
        selectedOrder,
        onPress,
        orderButtonActionType,
        atLeastOneOldItem,
        remainingAmount,
        disableButton,
        warningMessage,
        hideShareButtonOnDesktop,
        atLeastOneNewItem,
        readOnly,
        orderBottomRef,
        updateOrderBottomHeight,
        addItemAndGoBack,
        shouldGoToSharePaymentPage,
    } = props

    const intl = useIntl();
    const theme = useTheme();

    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));
    const { mobile_device } = useContext(DeviceContext);

    const { selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { order: orderInState, currentOrder: currentOrderState } = useTypedSelector((state: RootState) => state.order);
    const { estimatedLoyaltyConfigContent } = useTypedSelector((state: RootState) => state.loyalty);
    const authenticationState = useTypedSelector((state: RootState) => state.authentication);
    const user = authenticationState.data.user_authentication_state.user;

    const [openInvoiceModal, setOpenInvoiceModal] = useState<boolean>(false);

    /**
     * This useEffect is triggered each time anything is changed in this component.
     * It sends the component height to the summary page so that an empty div can be inserted at the bottom
     * for this container not to overlap on the rest
     */
    useEffect(() => {

        updateOrderBottomHeight(orderBottomRef.current?.clientHeight ?? 50);  // px
    });

    /**
     * This enum lists all the fields that could possibly be displayed
     * in the rectangle that we see under the items in summary page
     */
    enum fieldsToDisplayInSummaryPage {

        SUBTOTAL = "subtotal",
        LOYALTY = "loyalty",
        DISCOUNTS = "discounts",
        CHARGES = "charges",
        NEW_ITEMS_SUBTOTAL = "new_items_subtotal",
        TOTAL = "total"
    }

    const renderPendingPaymentAmountWarning = () => {

        const getAmountPendingText = displayAmountPending(selectedOrder, intl);

        if (getAmountPendingText) {

            return (

                <Typography
                    variant="caption"
                    style={{
                        color: theme.palette.text.disabled,
                    }}
                >
                    {getAmountPendingText}
                </Typography>
            )
        }
    }

    const hideChargesDiscountsAndSubTotal = () => {

        return (atLeastOneNewItem && atLeastOneOldItem);
    }

    /**
     * True if a field (given its name) should be displayed in the rectangle
     * under the items list in summaryPage
     * @param fieldName 
     */
    const isFieldDisplayed = (fieldName: fieldsToDisplayInSummaryPage): boolean => {

        switch (fieldName) {

            case fieldsToDisplayInSummaryPage.SUBTOTAL:

                return (
                    !(
                        (!selectedOrder || !selectedOrder.discounts || selectedOrder.discounts.length === 0)
                        &&
                        (!selectedOrder || !selectedOrder.charges || selectedOrder.charges.length === 0)
                    )
                    && !hideChargesDiscountsAndSubTotal()
                );

            case fieldsToDisplayInSummaryPage.LOYALTY:

                if (readOnly) {

                    if (
                        isThereALoyaltyOperation(orderInState.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        || estimatedLoyaltyConfigContent
                    ) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {

                    if (
                        selectedLocation?.loyalty
                        && selectedOrder?.contributors
                        && selectedOrder.loyalty_user_id
                        && selectedOrder.contributors[selectedOrder.loyalty_user_id]?.can_use_points
                    ) {
                        return true;
                    }

                    return false;
                }


            case fieldsToDisplayInSummaryPage.DISCOUNTS:

                if (
                    selectedOrder?.discounts
                    && selectedOrder.discounts.length > 0
                    && selectedOrder.items.length > 0
                    && !hideChargesDiscountsAndSubTotal()
                ) {
                    return true;
                }

                return false;

            case fieldsToDisplayInSummaryPage.CHARGES:

                if (
                    (
                        selectedOrder?.charges
                        && selectedOrder.charges.length > 0
                        && moneyToNumber(selectedOrder.total) > 0
                    )
                    && !hideChargesDiscountsAndSubTotal()
                ) {
                    return true;
                }

                return false;

            case fieldsToDisplayInSummaryPage.NEW_ITEMS_SUBTOTAL:

                if (selectedOrder && hideChargesDiscountsAndSubTotal()) {
                    return true;
                }

                return false;

            default:
                return false;
        }
    }

    /**
     * Get the title of the button depending on a lot of things: is it a draft? Are
     * we in share payment? Did we already order it?
     * The return undefined will display validate order
     */
    const getTitleButtonId = (): string | undefined => {

        switch (orderButtonActionType) {

            case OrderSummaryButtonActionTypes.VERIFY_COUPON:
                return 'Summary.verify_coupon'

            case OrderSummaryButtonActionTypes.LOAD: {
                return "Summary.continue";
            }

            case OrderSummaryButtonActionTypes.PAY:
                return shouldGoToSharePaymentPage ? "Summary.next" : "Summary.sharePayment.pay";

            case OrderSummaryButtonActionTypes.REORDER:
                return "Summary.reOrder";

            case OrderSummaryButtonActionTypes.SEND_ADDED_ITEMS:
                return "Summary.addItems.label";

            case OrderSummaryButtonActionTypes.BACK_TO_CATALOG:
                return "Summary.backToCatalog";

            default:  // Confirm is undefined
                return undefined;
        }
    }

    return (

        <Box
            ref={orderBottomRef}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            position={mobile_device ? 'fixed' : 'sticky'}
            left={0}
            right={0}
            bottom={0}
            width={1}
            style={{
                backgroundColor: theme.palette.background.paper,
                padding: theme.spacing(2, 3),
            }}
            boxShadow={mobile_device ? defaultMyLemonadeSummaryBottomShadow : "none"}
        >

            { // SUBTOTAL

                isFieldDisplayed(fieldsToDisplayInSummaryPage.SUBTOTAL) &&

                <Box
                    display="flex"
                    alignItems="stretch"
                >

                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        style={{ display: "flex", flex: 1, }}
                    >
                        {intl.formatMessage({ id: "Summary.subtotal" })}
                    </Typography>


                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                    >
                        {
                            moneyToStringWithSymbol(
                                selectedOrder && selectedCatalog ?
                                    getFabOrderPriceWithoutDiscountCharges(selectedOrder, selectedCatalog.currency)
                                    :
                                    numberToMoney(0, selectedCatalog?.currency ?? DEFAULT_CURRENCY)
                            )
                        }
                    </Typography>

                </Box>
            }

            { // LOYALTY

                selectedOrder?.discounts
                && selectedOrder.discounts.length > 0
                && selectedOrder.discounts.find((discount) => discount.type === DiscountType.LOYALTY)
                && selectedOrder.items.length > 0
                && selectedCatalog
                && selectedOrder
                && !hideChargesDiscountsAndSubTotal()
                &&

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    data-test="order-bottom.loyalty_discount.root"
                >
                    <Typography variant="body1" style={{ color: theme.palette.text.disabled }}>
                        {intl.formatMessage({ id: "loyalty.discount" })}
                    </Typography>

                    <Typography variant="body1" color="primary" data-test="order-bottom.loyalty_discount.amount">
                        {
                            moneyToStringWithSymbol(getNewLoyaltyDiscountsTotalAmount(
                                selectedOrder.discounts,
                                selectedCatalog.currency,
                            ))
                        }
                    </Typography>
                </Box>
            }

            { // ORDER DISCOUNTS

                isFieldDisplayed(fieldsToDisplayInSummaryPage.DISCOUNTS)
                && selectedCatalog
                && selectedLocation
                && selectedOrder?.discounts
                &&

                selectedOrder.discounts.map((orderDiscount: OrderDiscount, index: number) => {

                    if (
                        orderDiscount.type !== DiscountType.LOYALTY
                        || orderDiscount.update_id
                    ) {

                        return (

                            <Box
                                key={index}
                                display="flex"
                                flexDirection="row"
                                alignItems="stretch"
                            >

                                <Box flex={1}>
                                    <Typography variant="body1" style={{ color: theme.palette.text.disabled }}>
                                        {orderDiscount.name}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        data-test={`dicount-${orderDiscount.ref}-price`}
                                        variant="body2"
                                        color='primary'>
                                        {discountService.getOrderDiscountPricingValue(
                                            orderDiscount,
                                            selectedCatalog,
                                            true,
                                            moneyToNumber(getFabOrderPriceWithoutDiscountCharges(selectedOrder, selectedLocation.currency)),
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    }

                    return null
                })
            }

            { // ORDER CHARGES

                isFieldDisplayed(fieldsToDisplayInSummaryPage.CHARGES)
                && selectedOrder?.charges
                &&

                selectedOrder.charges.map((charge: OrderCharge, index: number) => (
                    (moneyToNumber(charge.price, true) !== 0)
                        ? <Box key={index} display="flex" flexDirection="row" alignItems="stretch">

                            <Box flex={1}>
                                <Typography variant="body1" style={{ color: theme.palette.text.disabled }}>
                                    {intl.messages[`charge.${charge.type}`] ? intl.formatMessage({ id: `charge.${charge.type}` }) : charge.name}
                                </Typography>
                            </Box>

                            <Typography
                                variant="body1"
                                style={{ color: theme.palette.text.disabled }}
                                data-test={`charge-${charge.type}-price`}
                            >
                                {getPriceWithSymbol(charge.price)}
                            </Typography>

                        </Box>
                        : <Box key={index}></Box>
                ))
            }

            { // NEW ITEMS SUBTOTAL

                isFieldDisplayed(fieldsToDisplayInSummaryPage.NEW_ITEMS_SUBTOTAL)
                && selectedOrder
                &&

                <Box display="flex" flexDirection="row" alignItems="stretch">

                    <Box flex={1}>
                        <Typography variant="subtitle1">
                            {intl.formatMessage({ id: "order.summary.new_items" })}
                        </Typography>
                    </Box>

                    <Typography
                        variant="subtitle1"
                        data-test="price-new_items_subtotal"
                    >
                        {getPriceWithSymbol(
                            getFabOrderPriceWithoutDiscountCharges(selectedOrder, getCurrency(selectedOrder.total))
                        )}
                    </Typography>
                </Box>
            }

            <Box
                width={1}
                display='flex'
                flexDirection='column'
                bgcolor={theme.palette.background.paper}
                marginTop={1}
            >

                {/* TOTAL */}
                <Box
                    display="flex"
                    alignItems="stretch"
                >

                    <Typography
                        variant="h5"
                        color={remainingAmount ? theme.palette.text.disabled : "textSecondary"}
                        style={{ display: "flex", flex: 1, }}
                    >
                        {intl.formatMessage({ id: "Summary.total" })}
                    </Typography>

                    <Typography
                        variant="h5"
                        color={remainingAmount ? theme.palette.text.disabled : "textSecondary"}
                        data-test='price-total'
                    >
                        {moneyToStringWithSymbol(selectedOrder
                            ? selectedOrder.total
                            : `0.00 ${selectedCatalog?.currency ?? DEFAULT_CURRENCY}`)}
                    </Typography>

                </Box>

                {   // LEFT TO PAY (shared payment)
                    remainingAmount &&

                    <Box
                        display="flex"
                        alignItems="stretch"
                    >

                        <Typography
                            variant="h5"
                            color="textSecondary"
                            style={{ display: "flex", flex: 1, }}>
                            {intl.formatMessage({
                                id: "auth.my_account.left_to_pay",
                            })}
                        </Typography>

                        <Typography
                            variant="h5"
                            color="textSecondary"
                            data-test='price-left_to_pay'
                        >
                            {moneyToStringWithSymbol(selectedOrder
                                ? remainingAmount
                                : `0.00 ${selectedCatalog?.currency ?? DEFAULT_CURRENCY}`)}
                        </Typography>

                    </Box>
                }

                {
                    /**
                     * Display a message if the minimum order amount is not reached
                     */
                    warningMessage ? (

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            mt={1.5}
                        >

                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <Icon
                                    component={'span'}
                                    style={{
                                        color: theme.palette.primary.main,
                                        padding: 0,
                                        width: 'fit-content',
                                        margin: theme.spacing(0, 0.6, 0, 0)
                                    }}
                                >
                                    <WarningIcon />
                                </Icon>
                            </Box>

                            <Typography
                                variant="caption"
                                style={{
                                    color: theme.palette.primary.main,
                                    textAlign: "center"
                                }}
                            >
                                {warningMessage}

                            </Typography>

                        </Box>

                    ) : ""
                }

                {renderPendingPaymentAmountWarning()}

                {
                    <Box
                        display="flex"
                        mt={1}
                        width={1}
                        style={{ gap: theme.spacing(1) }}
                    >

                        { // Displaying the share icon
                            isDesktopRender
                            && selectedLocation?.orders?.allow_share_order
                            && !hideShareButtonOnDesktop
                            && orderInState.id !== ""
                            && orderButtonActionType !== OrderSummaryButtonActionTypes.BACK_TO_CATALOG
                            && orderButtonActionType !== OrderSummaryButtonActionTypes.REORDER
                            &&

                            <ShareOrderButton smallMargin noShadows />
                        }

                        { // Invoice button (only for ended orders)
                            orderButtonActionType === OrderSummaryButtonActionTypes.REORDER
                            && user?.uid
                            && selectedOrder?.payments?.some(p => p.payment_user_id === user?.uid)
                            &&

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setOpenInvoiceModal(true)}
                                style={{
                                    padding: theme.spacing(1.45, 0),
                                    width: "65%",
                                    textTransform: "none",
                                    flex: 1,
                                }}
                            >

                                <CloudDownload style={{ marginRight: theme.spacing(1) }} />

                                <Typography>
                                    {intl.formatMessage({ id: "orders.preparation.invoice.button" })}
                                </Typography>

                            </Button>
                        }

                        { /* Main button */}
                        <Button
                            disabled={disableButton || currentOrderState.refreshing}
                            variant="contained"
                            data-test="summary_validateOrder"
                            color={orderButtonActionType === OrderSummaryButtonActionTypes.BACK_TO_CATALOG ? "secondary" : "primary"}
                            disableElevation
                            onClick={onPress}
                            style={{
                                textTransform: "none",
                                width: '100%',
                                padding: theme.spacing(1.5, 2),
                                flex: 1,
                            }}
                        >

                            <Box
                                display="flex"
                                alignItems="center"
                                gap={2}
                            >
                                <Typography variant='h5'>
                                    {
                                        intl.formatMessage({
                                            id: getTitleButtonId()
                                                ? getTitleButtonId()
                                                : getNumberOfContributors(orderInState.contributors) > 1
                                                    ? "Summary.validateTheOrder"
                                                    : "Summary.validateMyOrder"
                                        })
                                    }
                                </Typography>
                                {currentOrderState.refreshing &&
                                    <CircularProgress
                                        data-test='summary_validateOrder.loading'
                                        disableShrink
                                        size="1rem"
                                        style={{ color: theme.palette.action.disabled }}
                                    />
                                }
                            </Box>
                        </Button>

                    </Box>
                }
                {
                    orderButtonActionType === OrderSummaryButtonActionTypes.SEND_ADDED_ITEMS &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        mt={2}
                        px={2}
                        sx={{ cursor: "pointer" }}
                        onClick={addItemAndGoBack}
                        data-test='summary.add_and_pay_later'
                    >
                        <Typography
                            style={{ textDecoration: 'underline' }}
                            variant='subtitle1'
                            color='secondary'
                        >
                            {intl.formatMessage({ id: "summary.add.and.pay.later" })}
                        </Typography>
                    </Box>
                }
            </Box>

            {selectedOrder && openInvoiceModal &&
                <OrderInvoiceDialog
                    closeModal={() => setOpenInvoiceModal(false)}
                    order={selectedOrder}
                    paymentIntentId={undefined}
                />
            }

        </Box >
    )
}

export default OrderBottom;
