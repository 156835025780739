/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgress, Link, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PinField from "react-pin-field";
import { useDispatch } from "react-redux";
import log from "../../Common/services/LogService";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import AuthorizeCodeType from "../../my-lemonade-library/src/authentications/models/AuthorizeCodeType";
import SigninConfirmatinMode from "../../my-lemonade-library/src/authentications/models/SigninConfirmationMode";
import { getErrorMessage } from "../../my-lemonade-library/src/common/services/LogService";
import { ThemeList } from "../../my-lemonade-library/src/theme/models/ThemesList";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import AuthenticationPinConfirmationFormParameters from "../models/AuthenticationPinConfirmationFormParameters";
import AuthenticationActions from "../redux/AuthenticationActions";
import { authorizeCode, signinWithEmail } from "../services/AuthenticationService";
import "./AuthenticationPinConfirmation.scss";

interface AuthenticationPinConfirmationProps {
    parameters: AuthenticationPinConfirmationFormParameters
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}

const AuthenticationPinConfirmation: React.FC<AuthenticationPinConfirmationProps> = (props) => {

    const { parameters, setOnModalCloseAction } = props;
    const theme = useTheme();
    const intl = useIntl();
    // @ts-ignore
    const dispatch = useDispatch();
    const [signupLoading, setSignupLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const { tableLinkId, sessionId } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state: RootState) => state.order);

    const onClose = useCallback(() => {
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            parameters.previousForm ?? CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
            parameters.previousFormParameters,
        ));
    }, [dispatch, parameters]);

    useEffect(() => {
        setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.AUTHENTICATION_PIN_CONFIRMATION, onClose: onClose })
    }, [onClose])

    const onPinEntered = async (code: string) => {
        setSignupLoading(true);
        try {

            const authorizeResponse = await authorizeCode(
                parameters.provider,
                code,
                AuthorizeCodeType.EMAIL_CONFIRMATION_PIN,
                sessionId,
                parameters.state,
                parameters.uuid ?? "",
            );

            if (authorizeResponse?.custom_token) {
                dispatch(AuthenticationActions.signInWithCustomToken(parameters.provider, parameters.state, authorizeResponse.custom_token, authorizeResponse.customer, undefined, CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION));
                // no need for setSignupLoading(false), the popup will be closed by the action above;
            } else {
                setErrorMessage(intl.formatMessage({ id: "authentication.pin_confirmation.authorize_error" }));
                setSignupLoading(false);
            }

        } catch (error) {
            setSignupLoading(false);
            log.error(error);
            setErrorMessage(intl.formatMessage({ id: "authentication.pin_confirmation.authorize_error" }));
        }
    }

    const onResendCode = async () => {
        setSignupLoading(true);
        try {
            const response = await signinWithEmail(parameters.provider, parameters.email, tableLinkId, ThemeList.DEFAULT, order.id);
            if (response.need_confirmation !== SigninConfirmatinMode.PIN_OR_EMAIL) {
                setErrorMessage(intl.formatMessage({ id: "authentication.pin_confirmation.resend_code_error" }));
            }
            setSignupLoading(false);

        } catch (error) {
            setSignupLoading(false);
            log.error(`Error sending authentication code: ${getErrorMessage(error)}`)
            setErrorMessage(intl.formatMessage({ id: "authentication.pin_confirmation.resend_code_error" }));
        }
    }

    return (

        <Box
            display="flex"
            flexDirection='column'
            alignItems="center"
            width={1}
            p={2}
            data-test="authentication.pin_confirmation.container"
        >
            <Box textAlign="center">
                <Typography variant="h2" color="textSecondary">
                    {intl.formatMessage({ id: "authentication.pin_confirmation.title" })}
                </Typography>
            </Box>
            <Box textAlign="center" mt={4}>
                <Typography color="GrayText">
                    {intl.formatMessage({ id: "authentication.pin_confirmation.subtitle" }, { email: parameters.email })}
                </Typography>
            </Box>
            <Box width={1} mt={3} display="flex" flexDirection={"row"} justifyContent="center">
                {!signupLoading ? <PinField
                    type="tel"
                    className="pinfield"
                    length={6}
                    validate={"0123456789"}
                    onComplete={(code) => onPinEntered(code)} /> :
                    <CircularProgress
                        data-test='authentication.pin_confirmation.loader'
                        disableShrink
                        size="67px"
                        style={{
                            color: theme.palette.primary.main,
                        }}
                    />
                }
            </Box>
            <Box textAlign="center" height={theme.spacing(4)} display="flex" flexDirection={"column"} justifyContent="flex-start">
                {errorMessage ? <Typography color="error" variant="caption" >
                    {errorMessage}
                </Typography> : null}
            </Box>

            <Box textAlign="center" display="flex" flexDirection="column" alignItems={"center"}>
                <Typography color="GrayText">
                    {intl.formatMessage({ id: "authentication.pin_confirmation.not_received" })}
                </Typography>
                <Typography>
                    <Link
                        data-test={"authentication.pin_confirmation.resend"}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={() => onResendCode()}
                        key={0}
                    >
                        {intl.formatMessage({ id: "authentication.pin_confirmation.resend" })}
                    </Link>
                </Typography>
            </Box>
        </Box >
    );
}

export default AuthenticationPinConfirmation;