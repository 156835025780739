import { UserAccessPermissions } from "../../users/models/UserAccessPermissions";
import { UserAccessRole } from "../../users/models/UserAccessRole";
import Permissions from "./Permissions";

export type UserRolePermissions = {
    [role in UserAccessRole]?: UserAccessPermissions
}

/**
 * ANCHOR[id=user-role-permissions] User Role Permissions 
 */
export const USER_ROLE_PERMISSIONS: UserRolePermissions = {
    [UserAccessRole.ACCOUNT_MANAGER]: {
        [Permissions.ORDERS_CREATE]: true,
        [Permissions.ORDERS_READ]: true,
        [Permissions.ORDERS_PAY]: true,
        [Permissions.LOCATIONS_CREATE]: true,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.LOCATIONS_UPDATE]: true,
        [Permissions.LOCATIONS_DELETE]: true,
        [Permissions.CATALOGS_CREATE]: true,
        [Permissions.CATALOGS_READ]: true,
        [Permissions.CATALOGS_UPDATE]: true,
        [Permissions.CATALOGS_DELETE]: true,
        [Permissions.USERS_READ]: true,
        [Permissions.USERS_UPDATE]: true,
        [Permissions.USERS_DELETE]: true,
        [Permissions.CUSTOMERS_CREATE]: true,
        [Permissions.CUSTOMERS_READ]: true,
        [Permissions.CUSTOMERS_UPDATE]: true,
        [Permissions.CUSTOMERS_DELETE]: true,
        [Permissions.ACCOUNTS_CREATE]: true,
        [Permissions.ACCOUNTS_READ]: true,
        [Permissions.ACCOUNTS_UPDATE]: true,
        [Permissions.ACCOUNTS_DELETE]: true,
        [Permissions.PRODUCTS_CREATE]: true,
        [Permissions.PRODUCTS_READ]: true,
        [Permissions.PRODUCTS_UPDATE]: true,
        [Permissions.PRODUCTS_DELETE]: true,
        [Permissions.DEALS_CREATE]: true,
        [Permissions.DEALS_READ]: true,
        [Permissions.DEALS_UPDATE]: true,
        [Permissions.DEALS_DELETE]: true,
        [Permissions.OPTIONS_CREATE]: true,
        [Permissions.OPTIONS_READ]: true,
        [Permissions.OPTIONS_UPDATE]: true,
        [Permissions.OPTIONS_DELETE]: true,
        [Permissions.DISCOUNTS_CREATE]: true,
        [Permissions.DISCOUNTS_READ]: true,
        [Permissions.DISCOUNTS_UPDATE]: true,
        [Permissions.DISCOUNTS_DELETE]: true,
        [Permissions.CONNECTORS_CREATE]: true,
        [Permissions.CONNECTORS_READ]: true,
        [Permissions.CONNECTORS_UPDATE]: true,
        [Permissions.CONNECTORS_DELETE]: true,
        [Permissions.PAYMENTS_CREATE]: true,
        [Permissions.PAYMENTS_READ]: true,
        [Permissions.PAYMENTS_UPDATE]: true,
        [Permissions.PAYMENTS_DELETE]: true,
        [Permissions.QRCODES_CREATE]: true,
        [Permissions.QRCODES_READ]: true,
        [Permissions.QRCODES_UPDATE]: true,
        [Permissions.QRCODES_DELETE]: true,
        [Permissions.TABLES_CREATE]: true,
        [Permissions.TABLES_READ]: true,
        [Permissions.TABLES_UPDATE]: true,
        [Permissions.TABLES_DELETE]: true,
        [Permissions.TABLES_DISABLE]: true,
        [Permissions.TABLELINKS_CREATE]: true,
        [Permissions.TABLELINKS_READ]: true,
        [Permissions.TABLELINKS_UPDATE]: true,
        [Permissions.TABLELINKS_DELETE]: true,
        [Permissions.PRINTERS_CREATE]: true,
        [Permissions.PRINTERS_READ]: true,
        [Permissions.PRINTERS_UPDATE]: true,
        [Permissions.PRINTERS_DELETE]: true,
        [Permissions.DELIVERY_CREATE]: true,
        [Permissions.DELIVERY_READ]: true,
        [Permissions.DELIVERY_UPDATE]: true,
        [Permissions.DELIVERY_DELETE]: true,
        [Permissions.STATS_CREATE]: true,
        [Permissions.STATS_READ]: true,
        [Permissions.STATS_UPDATE]: true,
        [Permissions.STATS_DELETE]: true,
        [Permissions.SUGGESTIONS_CREATE]: true,
        [Permissions.SUGGESTIONS_READ]: true,
        [Permissions.SUGGESTIONS_UPDATE]: true,
        [Permissions.SUGGESTIONS_DELETE]: true,
        [Permissions.THEME_CREATE]: true,
        [Permissions.THEME_READ]: true,
        [Permissions.THEME_UPDATE]: true,
        [Permissions.THEME_DELETE]: true,
        [Permissions.TRANSLATIONS_CREATE]: true,
        [Permissions.TRANSLATIONS_READ]: true,
        [Permissions.TRANSLATIONS_UPDATE]: true,
        [Permissions.TRANSLATIONS_DELETE]: true,
        [Permissions.TAXES_CREATE]: true,
        [Permissions.TAXES_READ]: true,
        [Permissions.TAXES_UPDATE]: true,
        [Permissions.TAXES_DELETE]: true,
        [Permissions.LOYALTIES_CREATE]: true,
        [Permissions.LOYALTIES_READ]: true,
        [Permissions.LOYALTIES_UPDATE]: true,
        [Permissions.LOYALTIES_DELETE]: true,
        [Permissions.NOTIFICATIONS_CREATE]: true,
        [Permissions.NOTIFICATIONS_READ]: true,
        [Permissions.NOTIFICATIONS_UPDATE]: true,
        [Permissions.NOTIFICATIONS_DELETE]: true,
        [Permissions.INVOICES_CREATE]: true,
        [Permissions.INVOICES_READ]: true,
        [Permissions.INVOICES_UPDATE]: true,
        [Permissions.INVOICES_DELETE]: true,
        [Permissions.INVENTORIES_CREATE]: true,
        [Permissions.INVENTORIES_READ]: true,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.INVENTORIES_DELETE]: true,
        [Permissions.PIN_CREATE]: true,
        [Permissions.PIN_READ]: true,
        [Permissions.PIN_UPDATE]: true,
        [Permissions.PIN_DELETE]: true,
        [Permissions.REFUND]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    },
    [UserAccessRole.LOCATION_MANAGER]: {
        [Permissions.ORDERS_CREATE]: true,
        [Permissions.ORDERS_READ]: true,
        [Permissions.ORDERS_PAY]: true,
        [Permissions.LOCATIONS_CREATE]: true,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.LOCATIONS_UPDATE]: true,
        [Permissions.LOCATIONS_DELETE]: true,
        [Permissions.CATALOGS_CREATE]: true,
        [Permissions.CATALOGS_READ]: true,
        [Permissions.CATALOGS_UPDATE]: true,
        [Permissions.CATALOGS_DELETE]: true,
        [Permissions.USERS_READ]: true,
        [Permissions.USERS_UPDATE]: true,
        [Permissions.USERS_DELETE]: true,
        [Permissions.CUSTOMERS_CREATE]: true,
        [Permissions.CUSTOMERS_READ]: true,
        [Permissions.CUSTOMERS_UPDATE]: true,
        [Permissions.CUSTOMERS_DELETE]: true,
        [Permissions.ACCOUNTS_CREATE]: true,
        [Permissions.ACCOUNTS_READ]: true,
        [Permissions.ACCOUNTS_UPDATE]: true,
        [Permissions.ACCOUNTS_DELETE]: true,
        [Permissions.PRODUCTS_CREATE]: true,
        [Permissions.PRODUCTS_READ]: true,
        [Permissions.PRODUCTS_UPDATE]: true,
        [Permissions.PRODUCTS_DELETE]: true,
        [Permissions.DEALS_CREATE]: true,
        [Permissions.DEALS_READ]: true,
        [Permissions.DEALS_UPDATE]: true,
        [Permissions.DEALS_DELETE]: true,
        [Permissions.OPTIONS_CREATE]: true,
        [Permissions.OPTIONS_READ]: true,
        [Permissions.OPTIONS_UPDATE]: true,
        [Permissions.OPTIONS_DELETE]: true,
        [Permissions.DISCOUNTS_CREATE]: true,
        [Permissions.DISCOUNTS_READ]: true,
        [Permissions.DISCOUNTS_UPDATE]: true,
        [Permissions.DISCOUNTS_DELETE]: true,
        [Permissions.CONNECTORS_CREATE]: true,
        [Permissions.CONNECTORS_READ]: true,
        [Permissions.CONNECTORS_UPDATE]: true,
        [Permissions.CONNECTORS_DELETE]: true,
        [Permissions.PAYMENTS_CREATE]: true,
        [Permissions.PAYMENTS_READ]: true,
        [Permissions.PAYMENTS_UPDATE]: true,
        [Permissions.PAYMENTS_DELETE]: true,
        [Permissions.QRCODES_CREATE]: true,
        [Permissions.QRCODES_READ]: true,
        [Permissions.QRCODES_UPDATE]: true,
        [Permissions.QRCODES_DELETE]: true,
        [Permissions.TABLES_CREATE]: true,
        [Permissions.TABLES_READ]: true,
        [Permissions.TABLES_UPDATE]: true,
        [Permissions.TABLES_DELETE]: true,
        [Permissions.TABLES_DISABLE]: true,
        [Permissions.TABLELINKS_CREATE]: true,
        [Permissions.TABLELINKS_READ]: true,
        [Permissions.TABLELINKS_UPDATE]: true,
        [Permissions.TABLELINKS_DELETE]: true,
        [Permissions.PRINTERS_CREATE]: true,
        [Permissions.PRINTERS_READ]: true,
        [Permissions.PRINTERS_UPDATE]: true,
        [Permissions.PRINTERS_DELETE]: true,
        [Permissions.DELIVERY_CREATE]: true,
        [Permissions.DELIVERY_READ]: true,
        [Permissions.DELIVERY_UPDATE]: true,
        [Permissions.DELIVERY_DELETE]: true,
        [Permissions.STATS_CREATE]: true,
        [Permissions.STATS_READ]: true,
        [Permissions.STATS_UPDATE]: true,
        [Permissions.STATS_DELETE]: true,
        [Permissions.SUGGESTIONS_CREATE]: true,
        [Permissions.SUGGESTIONS_READ]: true,
        [Permissions.SUGGESTIONS_UPDATE]: true,
        [Permissions.SUGGESTIONS_DELETE]: true,
        [Permissions.THEME_CREATE]: true,
        [Permissions.THEME_READ]: true,
        [Permissions.THEME_UPDATE]: true,
        [Permissions.THEME_DELETE]: true,
        [Permissions.TRANSLATIONS_CREATE]: true,
        [Permissions.TRANSLATIONS_READ]: true,
        [Permissions.TRANSLATIONS_UPDATE]: true,
        [Permissions.TRANSLATIONS_DELETE]: true,
        [Permissions.TAXES_CREATE]: true,
        [Permissions.TAXES_READ]: true,
        [Permissions.TAXES_UPDATE]: true,
        [Permissions.TAXES_DELETE]: true,
        [Permissions.LOYALTIES_CREATE]: true,
        [Permissions.LOYALTIES_READ]: true,
        [Permissions.LOYALTIES_UPDATE]: true,
        [Permissions.LOYALTIES_DELETE]: true,
        [Permissions.NOTIFICATIONS_CREATE]: true,
        [Permissions.NOTIFICATIONS_READ]: true,
        [Permissions.NOTIFICATIONS_UPDATE]: true,
        [Permissions.NOTIFICATIONS_DELETE]: true,
        [Permissions.INVOICES_CREATE]: true,
        [Permissions.INVOICES_READ]: true,
        [Permissions.INVOICES_UPDATE]: true,
        [Permissions.INVOICES_DELETE]: true,
        [Permissions.INVENTORIES_CREATE]: true,
        [Permissions.INVENTORIES_READ]: true,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.INVENTORIES_DELETE]: true,
        [Permissions.PIN_CREATE]: true,
        [Permissions.PIN_READ]: true,
        [Permissions.PIN_UPDATE]: true,
        [Permissions.PIN_DELETE]: true,
        [Permissions.REFUND]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    },
    [UserAccessRole.LOCATION_ADMIN]: {
        [Permissions.ORDERS_CREATE]: true,
        [Permissions.ORDERS_READ]: true,
        [Permissions.ORDERS_PAY]: true,
        [Permissions.LOCATIONS_CREATE]: true,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.LOCATIONS_UPDATE]: true,
        [Permissions.LOCATIONS_DELETE]: true,
        [Permissions.CATALOGS_CREATE]: true,
        [Permissions.CATALOGS_READ]: true,
        [Permissions.CATALOGS_UPDATE]: true,
        [Permissions.CATALOGS_DELETE]: true,
        [Permissions.USERS_READ]: true,
        [Permissions.USERS_UPDATE]: true,
        [Permissions.USERS_DELETE]: true,
        [Permissions.CUSTOMERS_CREATE]: true,
        [Permissions.CUSTOMERS_READ]: true,
        [Permissions.CUSTOMERS_UPDATE]: true,
        [Permissions.CUSTOMERS_DELETE]: true,
        [Permissions.ACCOUNTS_CREATE]: true,
        [Permissions.ACCOUNTS_READ]: true,
        [Permissions.ACCOUNTS_UPDATE]: true,
        [Permissions.ACCOUNTS_DELETE]: true,
        [Permissions.PRODUCTS_CREATE]: true,
        [Permissions.PRODUCTS_READ]: true,
        [Permissions.PRODUCTS_UPDATE]: true,
        [Permissions.PRODUCTS_DELETE]: true,
        [Permissions.DEALS_CREATE]: true,
        [Permissions.DEALS_READ]: true,
        [Permissions.DEALS_UPDATE]: true,
        [Permissions.DEALS_DELETE]: true,
        [Permissions.OPTIONS_CREATE]: true,
        [Permissions.OPTIONS_READ]: true,
        [Permissions.OPTIONS_UPDATE]: true,
        [Permissions.OPTIONS_DELETE]: true,
        [Permissions.DISCOUNTS_CREATE]: true,
        [Permissions.DISCOUNTS_READ]: true,
        [Permissions.DISCOUNTS_UPDATE]: true,
        [Permissions.DISCOUNTS_DELETE]: true,
        [Permissions.CONNECTORS_CREATE]: true,
        [Permissions.CONNECTORS_READ]: true,
        [Permissions.CONNECTORS_UPDATE]: true,
        [Permissions.CONNECTORS_DELETE]: true,
        [Permissions.PAYMENTS_CREATE]: true,
        [Permissions.PAYMENTS_READ]: true,
        [Permissions.PAYMENTS_UPDATE]: true,
        [Permissions.PAYMENTS_DELETE]: true,
        [Permissions.QRCODES_CREATE]: true,
        [Permissions.QRCODES_READ]: true,
        [Permissions.QRCODES_UPDATE]: true,
        [Permissions.QRCODES_DELETE]: true,
        [Permissions.TABLES_CREATE]: true,
        [Permissions.TABLES_READ]: true,
        [Permissions.TABLES_UPDATE]: true,
        [Permissions.TABLES_DELETE]: true,
        [Permissions.TABLES_DISABLE]: true,
        [Permissions.TABLELINKS_CREATE]: true,
        [Permissions.TABLELINKS_READ]: true,
        [Permissions.TABLELINKS_UPDATE]: true,
        [Permissions.TABLELINKS_DELETE]: true,
        [Permissions.PRINTERS_CREATE]: true,
        [Permissions.PRINTERS_READ]: true,
        [Permissions.PRINTERS_UPDATE]: true,
        [Permissions.PRINTERS_DELETE]: true,
        [Permissions.DELIVERY_CREATE]: true,
        [Permissions.DELIVERY_READ]: true,
        [Permissions.DELIVERY_UPDATE]: true,
        [Permissions.DELIVERY_DELETE]: true,
        [Permissions.STATS_CREATE]: true,
        [Permissions.STATS_READ]: true,
        [Permissions.STATS_UPDATE]: true,
        [Permissions.STATS_DELETE]: true,
        [Permissions.SUGGESTIONS_CREATE]: true,
        [Permissions.SUGGESTIONS_READ]: true,
        [Permissions.SUGGESTIONS_UPDATE]: true,
        [Permissions.SUGGESTIONS_DELETE]: true,
        [Permissions.THEME_CREATE]: true,
        [Permissions.THEME_READ]: true,
        [Permissions.THEME_UPDATE]: true,
        [Permissions.THEME_DELETE]: true,
        [Permissions.TRANSLATIONS_CREATE]: true,
        [Permissions.TRANSLATIONS_READ]: true,
        [Permissions.TRANSLATIONS_UPDATE]: true,
        [Permissions.TRANSLATIONS_DELETE]: true,
        [Permissions.TAXES_CREATE]: true,
        [Permissions.TAXES_READ]: true,
        [Permissions.TAXES_UPDATE]: true,
        [Permissions.TAXES_DELETE]: true,
        [Permissions.LOYALTIES_CREATE]: true,
        [Permissions.LOYALTIES_READ]: true,
        [Permissions.LOYALTIES_UPDATE]: true,
        [Permissions.LOYALTIES_DELETE]: true,
        [Permissions.NOTIFICATIONS_CREATE]: true,
        [Permissions.NOTIFICATIONS_READ]: true,
        [Permissions.NOTIFICATIONS_UPDATE]: true,
        [Permissions.NOTIFICATIONS_DELETE]: true,
        [Permissions.INVOICES_CREATE]: true,
        [Permissions.INVOICES_READ]: true,
        [Permissions.INVOICES_UPDATE]: true,
        [Permissions.INVOICES_DELETE]: true,
        [Permissions.INVENTORIES_CREATE]: true,
        [Permissions.INVENTORIES_READ]: true,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.INVENTORIES_DELETE]: true,
        [Permissions.PIN_CREATE]: true,
        [Permissions.PIN_READ]: true,
        [Permissions.PIN_UPDATE]: true,
        [Permissions.PIN_DELETE]: true,
        [Permissions.REFUND]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    },
    [UserAccessRole.LOCATION_USER]: {
        [Permissions.ORDERS_CREATE]: true,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.ORDERS_READ]: true,
        [Permissions.INVENTORIES_READ]: true,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.TABLES_DISABLE]: true,
        [Permissions.REFUND]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    },
    [UserAccessRole.LOCATION_ENHANCED_USER]: {
        [Permissions.ORDERS_CREATE]: true,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.LOCATIONS_UPDATE]: true,
        [Permissions.CATALOGS_READ]: true,
        [Permissions.CATALOGS_UPDATE]: true,
        [Permissions.PRODUCTS_READ]: true,
        [Permissions.PRODUCTS_UPDATE]: true,
        [Permissions.PRODUCTS_DELETE]: true,
        [Permissions.DEALS_CREATE]: true,
        [Permissions.DEALS_READ]: true,
        [Permissions.DEALS_UPDATE]: true,
        [Permissions.DEALS_DELETE]: true,
        [Permissions.OPTIONS_CREATE]: true,
        [Permissions.OPTIONS_READ]: true,
        [Permissions.OPTIONS_UPDATE]: true,
        [Permissions.OPTIONS_DELETE]: true,
        [Permissions.DISCOUNTS_CREATE]: true,
        [Permissions.DISCOUNTS_READ]: true,
        [Permissions.DISCOUNTS_UPDATE]: true,
        [Permissions.DISCOUNTS_DELETE]: true,
        [Permissions.QRCODES_CREATE]: true,
        [Permissions.QRCODES_READ]: true,
        [Permissions.QRCODES_UPDATE]: true,
        [Permissions.QRCODES_DELETE]: true,
        [Permissions.TABLES_CREATE]: true,
        [Permissions.TABLES_READ]: true,
        [Permissions.TABLES_UPDATE]: true,
        [Permissions.TABLES_DELETE]: true,
        [Permissions.TABLES_DISABLE]: true,
        [Permissions.TABLELINKS_CREATE]: true,
        [Permissions.TABLELINKS_READ]: true,
        [Permissions.TABLELINKS_UPDATE]: true,
        [Permissions.TABLELINKS_DELETE]: true,
        [Permissions.SUGGESTIONS_CREATE]: true,
        [Permissions.SUGGESTIONS_READ]: true,
        [Permissions.SUGGESTIONS_UPDATE]: true,
        [Permissions.SUGGESTIONS_DELETE]: true,
        [Permissions.TRANSLATIONS_CREATE]: true,
        [Permissions.TRANSLATIONS_READ]: true,
        [Permissions.TRANSLATIONS_UPDATE]: true,
        [Permissions.TRANSLATIONS_DELETE]: true,
        [Permissions.TAXES_CREATE]: true,
        [Permissions.TAXES_READ]: true,
        [Permissions.TAXES_UPDATE]: true,
        [Permissions.TAXES_DELETE]: true,
        [Permissions.LOYALTIES_CREATE]: true,
        [Permissions.LOYALTIES_READ]: true,
        [Permissions.LOYALTIES_UPDATE]: true,
        [Permissions.LOYALTIES_DELETE]: true,
        [Permissions.INVENTORIES_CREATE]: true,
        [Permissions.INVENTORIES_READ]: true,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.INVENTORIES_DELETE]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    },
    [UserAccessRole.ACTIVATION_USER]: {
        [Permissions.ORDERS_CREATE]: false,
        [Permissions.ORDERS_READ]: true,
        [Permissions.LOCATIONS_CREATE]: true,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.LOCATIONS_UPDATE]: true,
        [Permissions.LOCATIONS_DELETE]: true,
        [Permissions.CATALOGS_CREATE]: true,
        [Permissions.CATALOGS_READ]: true,
        [Permissions.CATALOGS_UPDATE]: true,
        [Permissions.CATALOGS_DELETE]: true,
        [Permissions.PRODUCTS_CREATE]: true,
        [Permissions.PRODUCTS_READ]: true,
        [Permissions.PRODUCTS_UPDATE]: true,
        [Permissions.PRODUCTS_DELETE]: true,
        [Permissions.DEALS_CREATE]: true,
        [Permissions.DEALS_READ]: true,
        [Permissions.DEALS_UPDATE]: true,
        [Permissions.DEALS_DELETE]: true,
        [Permissions.OPTIONS_CREATE]: true,
        [Permissions.OPTIONS_READ]: true,
        [Permissions.OPTIONS_UPDATE]: true,
        [Permissions.OPTIONS_DELETE]: true,
        [Permissions.STATS_CREATE]: true,
        [Permissions.STATS_READ]: true,
        [Permissions.STATS_UPDATE]: true,
        [Permissions.STATS_DELETE]: true,
        [Permissions.SUGGESTIONS_CREATE]: true,
        [Permissions.SUGGESTIONS_READ]: true,
        [Permissions.SUGGESTIONS_UPDATE]: true,
        [Permissions.SUGGESTIONS_DELETE]: true,
        [Permissions.THEME_CREATE]: true,
        [Permissions.THEME_READ]: true,
        [Permissions.THEME_UPDATE]: true,
        [Permissions.THEME_DELETE]: true,
        [Permissions.TRANSLATIONS_CREATE]: true,
        [Permissions.TRANSLATIONS_READ]: true,
        [Permissions.TRANSLATIONS_UPDATE]: true,
        [Permissions.TRANSLATIONS_DELETE]: true,
        [Permissions.LOYALTIES_CREATE]: true,
        [Permissions.LOYALTIES_READ]: true,
        [Permissions.LOYALTIES_UPDATE]: true,
        [Permissions.LOYALTIES_DELETE]: true,
        [Permissions.INVENTORIES_CREATE]: true,
        [Permissions.INVENTORIES_READ]: false,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.INVENTORIES_DELETE]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    },
    [UserAccessRole.WAITER]: {
        [Permissions.ORDERS_CREATE]: true,
        [Permissions.ORDERS_READ]: true,
        [Permissions.ORDERS_PAY]: true,
        [Permissions.LOCATIONS_CREATE]: false,
        [Permissions.LOCATIONS_READ]: true,
        [Permissions.LOCATIONS_UPDATE]: true,
        [Permissions.LOCATIONS_DELETE]: false,
        [Permissions.CATALOGS_CREATE]: false,
        [Permissions.CATALOGS_READ]: true,
        [Permissions.CATALOGS_UPDATE]: true,
        [Permissions.CATALOGS_DELETE]: false,
        [Permissions.USERS_READ]: true,
        [Permissions.USERS_UPDATE]: false,
        [Permissions.USERS_DELETE]: false,
        [Permissions.CUSTOMERS_CREATE]: true,
        [Permissions.CUSTOMERS_READ]: true,
        [Permissions.CUSTOMERS_UPDATE]: true,
        [Permissions.CUSTOMERS_DELETE]: false,
        [Permissions.ACCOUNTS_CREATE]: false,
        [Permissions.ACCOUNTS_READ]: false,
        [Permissions.ACCOUNTS_UPDATE]: false,
        [Permissions.ACCOUNTS_DELETE]: false,
        [Permissions.PRODUCTS_CREATE]: true,
        [Permissions.PRODUCTS_READ]: true,
        [Permissions.PRODUCTS_UPDATE]: true,
        [Permissions.PRODUCTS_DELETE]: false,
        [Permissions.DEALS_CREATE]: true,
        [Permissions.DEALS_READ]: true,
        [Permissions.DEALS_UPDATE]: true,
        [Permissions.DEALS_DELETE]: false,
        [Permissions.OPTIONS_CREATE]: true,
        [Permissions.OPTIONS_READ]: true,
        [Permissions.OPTIONS_UPDATE]: true,
        [Permissions.OPTIONS_DELETE]: false,
        [Permissions.DISCOUNTS_CREATE]: true,
        [Permissions.DISCOUNTS_READ]: true,
        [Permissions.DISCOUNTS_UPDATE]: true,
        [Permissions.DISCOUNTS_DELETE]: false,
        [Permissions.CONNECTORS_CREATE]: false,
        [Permissions.CONNECTORS_READ]: true,
        [Permissions.CONNECTORS_UPDATE]: false,
        [Permissions.CONNECTORS_DELETE]: false,
        [Permissions.PAYMENTS_CREATE]: false,
        [Permissions.PAYMENTS_READ]: true,
        [Permissions.PAYMENTS_UPDATE]: false,
        [Permissions.PAYMENTS_DELETE]: false,
        [Permissions.QRCODES_CREATE]: false,
        [Permissions.QRCODES_READ]: true,
        [Permissions.QRCODES_UPDATE]: false,
        [Permissions.QRCODES_DELETE]: false,
        [Permissions.TABLES_CREATE]: false,
        [Permissions.TABLES_READ]: true,
        [Permissions.TABLES_UPDATE]: true,
        [Permissions.TABLES_DELETE]: false,
        [Permissions.TABLES_DISABLE]: true,
        [Permissions.TABLELINKS_CREATE]: false,
        [Permissions.TABLELINKS_READ]: true,
        [Permissions.TABLELINKS_UPDATE]: true,
        [Permissions.TABLELINKS_DELETE]: false,
        [Permissions.PRINTERS_CREATE]: false,
        [Permissions.PRINTERS_READ]: true,
        [Permissions.PRINTERS_UPDATE]: false,
        [Permissions.PRINTERS_DELETE]: false,
        [Permissions.DELIVERY_CREATE]: false,
        [Permissions.DELIVERY_READ]: true,
        [Permissions.DELIVERY_UPDATE]: true,
        [Permissions.DELIVERY_DELETE]: false,
        [Permissions.STATS_CREATE]: false,
        [Permissions.STATS_READ]: true,
        [Permissions.STATS_UPDATE]: false,
        [Permissions.STATS_DELETE]: false,
        [Permissions.SUGGESTIONS_CREATE]: true,
        [Permissions.SUGGESTIONS_READ]: true,
        [Permissions.SUGGESTIONS_UPDATE]: true,
        [Permissions.SUGGESTIONS_DELETE]: false,
        [Permissions.THEME_CREATE]: true,
        [Permissions.THEME_READ]: true,
        [Permissions.THEME_UPDATE]: true,
        [Permissions.THEME_DELETE]: false,
        [Permissions.TRANSLATIONS_CREATE]: true,
        [Permissions.TRANSLATIONS_READ]: true,
        [Permissions.TRANSLATIONS_UPDATE]: true,
        [Permissions.TRANSLATIONS_DELETE]: false,
        [Permissions.TAXES_CREATE]: true,
        [Permissions.TAXES_READ]: true,
        [Permissions.TAXES_UPDATE]: true,
        [Permissions.TAXES_DELETE]: false,
        [Permissions.LOYALTIES_CREATE]: false,
        [Permissions.LOYALTIES_READ]: true,
        [Permissions.LOYALTIES_UPDATE]: false,
        [Permissions.LOYALTIES_DELETE]: false,
        [Permissions.NOTIFICATIONS_CREATE]: true,
        [Permissions.NOTIFICATIONS_READ]: true,
        [Permissions.NOTIFICATIONS_UPDATE]: false,
        [Permissions.NOTIFICATIONS_DELETE]: false,
        [Permissions.INVOICES_CREATE]: true,
        [Permissions.INVOICES_READ]: true,
        [Permissions.INVOICES_UPDATE]: false,
        [Permissions.INVOICES_DELETE]: false,
        [Permissions.INVENTORIES_CREATE]: true,
        [Permissions.INVENTORIES_READ]: true,
        [Permissions.INVENTORIES_UPDATE]: true,
        [Permissions.INVENTORIES_DELETE]: false,
        [Permissions.PIN_CREATE]: false,
        [Permissions.PIN_READ]: true,
        [Permissions.PIN_UPDATE]: false,
        [Permissions.PIN_DELETE]: false,
        [Permissions.REFUND]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,

    },
    [UserAccessRole.PARTNER_USER]: {
        [Permissions.ORDERS_CREATE]: false,
        [Permissions.STATS_READ]: true,
        [Permissions.CHALLENGES_LIST]: true,
        [Permissions.CHALLENGES_READ]: true,
        [Permissions.CHALLENGES_ACCEPT]: true,
        [Permissions.TRENDS_LIST]: true,
        [Permissions.TRENDS_READ]: true,
    }
}

export default USER_ROLE_PERMISSIONS;