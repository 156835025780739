import { SignInAuthenticated } from "../../authentication/models/SignInAuthenticated";
import { Catalog } from "../../my-lemonade-library/model/Catalog";
import { Location, SupportedServiceType, Table } from "../../my-lemonade-library/model/Location";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import OrderContributor from "../../my-lemonade-library/src/orders/models/OrderContributor";
import { ThemeList } from "../../my-lemonade-library/src/theme/models/ThemesList";

export const RELOAD_CATALOG = "RELOAD_CATALOG"

export const RELOAD_CATALOG_WHEN_CHANGES = "RELOAD_CATALOG_WHEN_CHANGES"

export const SETUP_SYNC_CATALOG = "SETUP_SYNC_CATALOG"

export interface ReloadCatalogAction {
    type: typeof RELOAD_CATALOG
}

export interface ReloadCatalogWhenChangesAction {
    type: typeof RELOAD_CATALOG_WHEN_CHANGES;
}
export interface SetupSyncCatalogAction {
    type: typeof SETUP_SYNC_CATALOG
    payload: Catalog
}

export const RELOAD_CATALOG_SUCCESS = "RELOAD_CATALOG_SUCCESS"

export interface ReloadCatalogSuccessAction {
    type: typeof RELOAD_CATALOG_SUCCESS,
    payload: CatalogExtended
}

export const LOAD_LOCATION = 'LOAD_LOCATION';
export interface LoadLocationAction {
    type: typeof LOAD_LOCATION,
    payload: {
        tableLinkKey: string,
        webappQuery: URLSearchParams,
        signinProvider: SignInProviders | null,
        authenticated: SignInAuthenticated | null,
        themeName: ThemeList,
    }
}

export const LOAD_LOCATION_SUCCESS = 'LOAD_LOCATION_SUCCESS';
export interface LoadLocationSuccessAction {
    type: typeof LOAD_LOCATION_SUCCESS,
    payload: {
        fromApp: SignInProviders | null;
        token: string;
        tableLinkId: string;
        location: Location;
        catalog: CatalogExtended;
        selectedTable: Table | null;
    }
}

export const LOAD_LOCATION_ERROR = 'LOAD_LOCATION_ERROR';
export interface LoadLocationErrorAction {
    type: typeof LOAD_LOCATION_ERROR,
    payload: { error: string }
}

export const REDIRECT = 'REDIRECT';
export interface RedirectAction {
    type: typeof REDIRECT,
    payload: { url: string }
}

export const AUTHENTICATION = 'AUTHENTICATION';
export interface AuthenticationAction {
    type: typeof AUTHENTICATION
}

export const SET_TABLE = "SET_TABLE"
export interface SetTableAction {
    type: typeof SET_TABLE
    payload: Table
}

export const SET_AUTHENTICATION_LOCATION_PROCESS = "SET_AUTHENTICATION_LOCATION_PROCESS"
export interface SetAuthenticationLocationProcessAction {
    type: typeof SET_AUTHENTICATION_LOCATION_PROCESS
    payload: {
        location: Location,
        serviceTypes: SupportedServiceType[] | undefined
    }
}

export const OPEN_MODAL_MULTI_USERS_ORDER = 'OPEN_MODAL_MULTI_USERS_ORDER';
export interface OpenModalMultiUsersOrder {
    type: typeof OPEN_MODAL_MULTI_USERS_ORDER,
    payload: {
        open: boolean,
    },
}

export const SET_TABLE_SERVICE_TYPE = "SET_TABLE_SERVICE_TYPE_";
export interface SetServiceTypeAction {
    type: typeof SET_TABLE_SERVICE_TYPE;
    payload: {
        serviceType: SupportedServiceType | null;
    }
}

export const SET_INVALID_SERVICE_TYPE = 'SET_INVALID_SERVICE_TYPE'
export interface SetInvalidServiceTypeAction {
    type: typeof SET_INVALID_SERVICE_TYPE;
    payload: boolean;
}

export const SET_CATALOG = 'SET_CATALOG'
export interface SetCatalogAction {
    type: typeof SET_CATALOG
    payload: {
        catalog: CatalogExtended,
    }
}

export const UPDATE_CATALOG_DEALS = 'UPDATE_CATALOG_DEALS'
export interface UpdateCatalogDealsAction {
    type: typeof UPDATE_CATALOG_DEALS
    payload: {
        catalog: CatalogExtended,
        service_type: SupportedServiceType | null,
        sign_in_provider: SignInProviders | null,
        customer: Customer | OrderContributor | null,
        table_area: string | undefined
    }
}

export const SET_SESSION_ID = 'SET_SESSION_ID'
export interface SetSessionIdAction {
    type: typeof SET_SESSION_ID;
    payload: {
        session_id: string;
    }
}

export const GET_DISCOUNT_REF_FROM_URL = 'GET_DISCOUNT_REF_FROM_URL';

export interface GetDiscountRefFromUrlAction {
    type: typeof GET_DISCOUNT_REF_FROM_URL;
    payload: {
        discountRef: string;
    }
}

export type LocationActionType =
    LoadLocationAction
    | LoadLocationSuccessAction
    | LoadLocationErrorAction
    | RedirectAction
    | AuthenticationAction
    | SetTableAction
    | SetAuthenticationLocationProcessAction
    | OpenModalMultiUsersOrder
    | SetServiceTypeAction
    | ReloadCatalogAction
    | ReloadCatalogWhenChangesAction
    | SetupSyncCatalogAction
    | ReloadCatalogSuccessAction
    | SetInvalidServiceTypeAction
    | SetCatalogAction
    | UpdateCatalogDealsAction
    | SetSessionIdAction
    | GetDiscountRefFromUrlAction;

export const locationActions = {

    reloadCatalogSuccess: (catalog: CatalogExtended): ReloadCatalogSuccessAction => {
        return {
            type: RELOAD_CATALOG_SUCCESS,
            payload: catalog
        }
    },

    reloadCatalog: (): ReloadCatalogAction => {
        return {
            type: RELOAD_CATALOG
        }
    },

    reloadCatalogWhenChanges: (): ReloadCatalogWhenChangesAction => {
        return {
            type: RELOAD_CATALOG_WHEN_CHANGES
        }
    },

    setupSyncCatalog: (payload: Catalog): SetupSyncCatalogAction => {
        return {
            type: SETUP_SYNC_CATALOG,
            payload
        }
    },

    loadLocation: ((
        tableLinkKey: string,
        webappQuery: URLSearchParams,
        signinProvider: SignInProviders | null,
        authenticated: SignInAuthenticated | null,
        themeName: ThemeList,
    ): LoadLocationAction => {
        return {
            type: LOAD_LOCATION,
            payload: {
                tableLinkKey,
                webappQuery,
                signinProvider,
                authenticated,
                themeName,
            },
        }
    }),

    /**
     * TODO: tableId, tableRef and selected table ???
     */
    loadLocationSuccess: ((
        fromApp: SignInProviders | null,
        token: string,
        tableLinkId: string,
        location: Location,
        catalog: CatalogExtended,
        selectedTable: Table | null
    ): LoadLocationSuccessAction => {
        return {
            type: LOAD_LOCATION_SUCCESS,
            payload: {
                fromApp: fromApp,
                token: token,
                tableLinkId: tableLinkId,
                location: location,
                catalog: catalog,
                selectedTable: selectedTable,
            }
        }
    }),

    redirect: ((url: string): RedirectAction => {
        return {
            type: REDIRECT,
            payload: { url: url }
        }
    }),

    loadError: ((error: string): LoadLocationErrorAction => {
        return {
            type: LOAD_LOCATION_ERROR,
            payload: { error: error }
        }
    }),

    auth: ((): AuthenticationAction => {
        return {
            type: AUTHENTICATION
        }
    }),

    setTable: (table: Table): SetTableAction => {
        return {
            type: SET_TABLE,
            payload: table
        }
    },

    /**
     * Given one or multiple service types, decide what to do with the user regarding authentication:
     * mandatory? Beginning, end?
     * @param location 
     * @param serviceTypes 
     * @returns 
     */
    setAuthenticationLocationProcess: (location: Location, serviceTypes: SupportedServiceType[] | undefined) => {
        return {
            type: SET_AUTHENTICATION_LOCATION_PROCESS,
            payload: {
                location: location,
                serviceTypes: serviceTypes
            }
        }
    },

    setOpenModalMultiUsersOrder: (open: boolean) => {
        return {
            type: OPEN_MODAL_MULTI_USERS_ORDER,
            payload: {
                open,
            },
        }
    },

    /**
     * Set the service_type of the table
     * @param serviceType 
     * @returns 
     */
    setServiceType: (serviceType: SupportedServiceType | null): SetServiceTypeAction => {
        return {
            type: SET_TABLE_SERVICE_TYPE,
            payload: {
                serviceType
            },
        }
    },

    setInvalidServiceType: (bool: boolean): SetInvalidServiceTypeAction => {
        return {
            type: SET_INVALID_SERVICE_TYPE,
            payload: bool,
        }
    },

    setCatalog: (catalog: CatalogExtended): SetCatalogAction => {
        return {
            type: SET_CATALOG,
            payload: {
                catalog,
            }
        }
    },

    updateCatalogDealsAfterLoadingUserInfo: (
        catalog: CatalogExtended,
        service_type: SupportedServiceType | null,
        sign_in_provider: SignInProviders | null,
        customer: Customer | OrderContributor | null,
        table_area: string | undefined
    ): UpdateCatalogDealsAction => {
        return {
            type: UPDATE_CATALOG_DEALS,
            payload: {
                catalog,
                service_type,
                sign_in_provider,
                customer,
                table_area,
            }
        }
    },

    setSessionId: (session_id: string): SetSessionIdAction => {
        return {
            type: SET_SESSION_ID,
            payload: {
                session_id,
            }
        }
    },

    getDiscountRefFromUrl: (discountRef: string): GetDiscountRefFromUrlAction => ({
        type: GET_DISCOUNT_REF_FROM_URL,
        payload: { discountRef }
    }),
}

export default locationActions;
