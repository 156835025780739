import _ from "lodash";
import { IntlShape } from "react-intl";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { Discount } from "../../my-lemonade-library/src/discounts/models/Discount";
import { DiscountInvalidity } from "../../my-lemonade-library/src/discounts/models/DiscountInvalidity";
import discountService from "../../my-lemonade-library/src/discounts/service/DiscountService";
import { getRestrictionsArray } from "../../my-lemonade-library/src/restrictions/services/RestrictionsService";


const getDiscountServiceTypeIntlValues = (discount: Discount, intl: IntlShape | undefined): string => {
    const discountRestrictions = getRestrictionsArray(discount.restrictions);

    let allServiceTypes: SupportedServiceType[] | undefined = undefined;
    discountRestrictions?.forEach((restriction) => {
        if (restriction.service_types) {
            if (!allServiceTypes) {
                allServiceTypes = restriction.service_types;
            }
            else {
                allServiceTypes.push(...restriction.service_types);
            }
        }
    });

    const serviceTypesString = (allServiceTypes as SupportedServiceType[] | undefined)?.map((serviceType) =>
        intl
            ? intl.formatMessage({ id: `authentication.signin_provider_discount.service_type_${serviceType}` })
            : serviceType
    ).join(intl ? ` ${intl.formatMessage({ id: "common.or.label" })} ` : ",")
        ?? "";

    return serviceTypesString;
}

interface GetDiscountDescriptionIntlValuesReturn {
    id: string,
    values: { [key: string]: string | number | undefined | Function },
}

export const getDiscountDescriptionIntlValues = (
    discount: Discount, intl: IntlShape | undefined
): GetDiscountDescriptionIntlValuesReturn => {

    let id = `authentication.authentication_form.welcomAuthentication.signin_provider_discount.description`;
    let values: GetDiscountDescriptionIntlValuesReturn["values"] = {
        "value": discountService.getDiscountPricingValueString(discount),
        "number": !_.isNil(discount.usage_restriction?.max_per_customer)
            ? discount.usage_restriction?.max_per_customer
            : 1,
        "service_types": getDiscountServiceTypeIntlValues(discount, intl)
    }

    return {
        id,
        values,
    }

}


export const getDiscountInvalidityIntlValues = (invalidityReason: DiscountInvalidity, intl: IntlShape | undefined) => {
    let id = `unavailable.discount`;
    let value = intl ? intl.formatMessage({ id: invalidityReason }) : "";
    return {
        id,
        value
    }

}