enum MapboxPlaceType {
    COUNTRY = 'country',
    REGION = 'region',
    PLACE = 'place',
    POSTCODE = 'postcode',
    DISTRICT = 'district',
    LOCALITY = 'locality',
    NEIGHBORHOOD = 'neighborhood',
    ADDRESS = 'address',
    POI = 'poi',
}

export default MapboxPlaceType;