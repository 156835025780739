import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from "react-intl";
import { generatePath, useHistory, useParams } from 'react-router';
import { DeviceContext } from "../../App";
import * as ROUTES from '../../config/routes';
import { DeviceContextModel } from '../../config/useDeviceContext';

const EmptyCart: React.FC = () => {

    const params = useParams<ROUTES.RouteParams>()
    const { desktop_device } = React.useContext<DeviceContextModel>(DeviceContext)

    const intl = useIntl()
    const history = useHistory()
    const theme = useTheme()

    const backToCatalog = () => {
        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: params.tableLinkKey }))
    }

    return (
        <Box
            height={1}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            paddingLeft={3}
            paddingRight={3}
            paddingBottom={3}
        >
            <Box
                flex={1}
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
            >
                <Typography>
                    {intl.formatMessage({
                        id: "Summary.noOrder",
                        defaultMessage: "No order."
                    })}
                </Typography>
            </Box>
            {
                !desktop_device &&

                <Box
                    width={1}
                    p={3}
                    style={{ backgroundColor: theme.palette.background.paper }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            height: '60px',
                            width: '100%'
                        }}
                        onClick={backToCatalog}
                    >
                        <Typography variant="h5">
                            {intl.formatMessage({
                                id: "Summary.backToCatalog",
                                defaultMessage: "Back to catalog."
                            })}
                        </Typography>
                    </Button>
                </Box>

            }
        </Box>

    )
}

export default EmptyCart