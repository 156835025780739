import log from '../../Common/services/LogService';
import * as ROUTES from '../../config/routes';
import CustomerInformationModalFormOpenArgs from "../../customers/models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "../../customers/services/BaseFormModalService";
import { CustomerInfoFlowChoice } from "../../my-lemonade-library/model/Location";
import { isUserLoggedIn } from "../helpers/AuthenticationHelpers";
import AuthenticationFormParameters from "../models/AuthenticationFormParameters";

export default class AuthenticationAdvantagesFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES);
    }

    shouldDisplayLogo() {
        return false;
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: AuthenticationFormParameters } | null {

        const { currentPathname, tableLinkId } = args;
        const { location_authentication_config, user_authentication_state } = args.authenticationData;
        const { authentication_process, authentication_mandatory } = location_authentication_config;
        const { user } = user_authentication_state;

        // If already logged in, do not open the form
        if (isUserLoggedIn(user_authentication_state)) {
            args.message = `User ${user_authentication_state.user?.uid} is already logged in (${user_authentication_state.provider})`;
            return null;
        }

        if (forAutoclosing) {
            // Should keep the form open except if the user is not logged in
            // Or if displayed on wrong pages
            if (
                (
                    currentPathname.includes(ROUTES.ConfirmationWord)
                    || currentPathname.includes(ROUTES.ConfirmPayment)
                )
                && !authentication_mandatory
            ) {
                // Close when wrong pages and not mandatory
                args.message = `Auth is not mandatory and we're on a confirmation page (${currentPathname})`;
                return null;
            }
            else {
                const authenticationFormParameters: AuthenticationFormParameters = {
                    forceMandatory: authentication_mandatory ?? false,
                }
                args.message = `Auth is not mandatory, or we're not on a confirmation page (${currentPathname})`;
                return { form: this.form, parameters: authenticationFormParameters };
            }
        }

        if (
            (
                authentication_process === CustomerInfoFlowChoice.START
                || authentication_process === CustomerInfoFlowChoice.BOTH
            )
            // Not on those pages IF AND ONLY IF auth is not mandatory
            // Used to avoid reopening the modal after payment redirection
            && ROUTES.isHomePage(currentPathname, tableLinkId)
            && !user?.has_clicked_guest_beginning
        ) {

            log.info(">-C-> User is not authentified and location requires it, show the WELCOME_AUTHENTICATION form");
            const authenticationFormParameters: AuthenticationFormParameters = {
                forceMandatory: authentication_mandatory ?? false,
            }
            args.message = `User ${user_authentication_state.user?.uid} is not authentified and location requires it`
            return { form: this.form, parameters: authenticationFormParameters };
        }

        return null;
    }
};