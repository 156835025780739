import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";

/**
 * Key used in route or query params
 */
export const DISCOUNTS_COUPON_CODE_PARAM_KEY = "couponCode";
export const DISCOUNTS_COUPON_CODE_PARAM = `:${DISCOUNTS_COUPON_CODE_PARAM_KEY}`;

export const DISCOUNTS_BASE_ROUTE = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.DISCOUNTS}`;
export const DISCOUNTS_CAN_USE_ROUTE = `${DISCOUNTS_BASE_ROUTE}/can-use`;

export const DISCOUNT_REF = "discountRef";


const discountsApiRoutes = {
    CAN_USE_ROUTE: DISCOUNTS_CAN_USE_ROUTE,
};
export default discountsApiRoutes;