import * as H from 'history';
import { Dispatch } from 'react';
import { isUserLoggedIn } from '../../authentication/helpers/AuthenticationHelpers';
import { AuthenticationStateData } from '../../authentication/models/AuthenticationState';
import { isEmailVerifiedIfMandatory } from '../../authentication/services/VerifyEmailFormService';
import { extractDateFromTimestampOrString } from '../../Common/helper/DateHelper';
import { CommonActions } from '../../Common/redux/CommonActions';
import log from '../../Common/services/LogService';
import * as ROUTES from '../../config/routes';
import { getPaymentCollectionInfoFullRoute } from '../../config/routes';
import { Catalog } from '../../my-lemonade-library/model/Catalog';
import { getTimezoneName, Location, SupportedPayementType, SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { Order, OrderCharge, OrderInBase, PaymentType } from '../../my-lemonade-library/model/Order';
import { SignInProviders } from '../../my-lemonade-library/src/authentications/models/BaseUser';
import { getCurrency, moneyToNumber, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { RESTAURANT_TICKET_PAYMENT_TYPES } from '../../my-lemonade-library/src/payments/configs/RestaurantTicketPaymentConfig';
import { MIN_ITEM_QUANTITY_FOR_SHARE_PAYMENT } from '../../my-lemonade-library/src/payments/configs/SharePaymentConfigs';
import { LyraMarketplaceSupportedPaymentType } from '../../my-lemonade-library/src/payments/models/lyramarketplace/LyraMarketplaceSupportedPaymentType';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { PaymentAmountType } from '../../my-lemonade-library/src/payments/models/PaymentAmountType';
import { PaymentTypeExtended, SpecificPaymentType, SupportedPaymentTypeExtended } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { getMinAmountSharePayment } from '../../my-lemonade-library/src/payments/services/SharePaymentService';
import { Restriction } from '../../my-lemonade-library/src/restrictions/model/Restriction';
import paymentsActions from '../../Payment/redux/PaymentsActions';
import { PaymentState } from '../../Payment/redux/PaymentsReducer';
import { isLocalLoyaltyOnly } from '../helpers/LoyaltyHelpers';
import OrderAction, { orderActions } from '../redux/OrderActions';
import { getCustomerInfoFieldsToAsk } from './CustomerService';


export const updateDateFieldsInFirestoreOrder = (order: OrderInBase, location: Location): OrderInBase => {
    const timezoneName = getTimezoneName(location);
    if (order.expected_time) {
        const extractedDate = extractDateFromTimestampOrString(order.expected_time, timezoneName);
        if (extractedDate) {
            order.expected_time = extractedDate;
        } else {
            log.debug(`Invalid expected time for order ${order.id}`);
            delete order.expected_time;
        }
    }
    if (order.confirmed_time) {
        const extractedDate = extractDateFromTimestampOrString(order.confirmed_time, timezoneName);
        if (extractedDate) {
            order.confirmed_time = extractedDate;
        } else {
            log.debug(`Invalid confirmed time for order ${order.id}`);
            delete order.confirmed_time;
        }
    }
    if (order.end_preparation_time) {
        const extractedDate = extractDateFromTimestampOrString(order.end_preparation_time, timezoneName);
        if (extractedDate) {
            order.end_preparation_time = extractedDate;
        } else {
            log.debug(`Invalid preparation time for order ${order.id}`);
            delete order.end_preparation_time;
        }
    }
    if (order.confirmed_preparation_time) {
        const extractedDate = extractDateFromTimestampOrString(order.confirmed_preparation_time, timezoneName);
        if (extractedDate) {
            order.confirmed_preparation_time = extractedDate;
        } else {
            log.debug(`Invalid confirmed preparation time for order ${order.id}`);
            delete order.confirmed_preparation_time;
        }
    }
    if (order.draft_created_at) {
        const extractedDate = extractDateFromTimestampOrString(order.draft_created_at, timezoneName);
        if (extractedDate) {
            order.draft_created_at = extractedDate;
        } else {
            log.debug(`Invalid draft created at for order ${order.id}`);
            delete order.draft_created_at;
        }
    }
    if (order.created_at) {
        const extractedDate = extractDateFromTimestampOrString(order.created_at, timezoneName);
        if (extractedDate) {
            order.created_at = extractedDate;
        } else {
            log.debug(`Invalid draft created at for order ${order.id}`);
            delete order.created_at;
        }
    }
    if (order.updated_at) {
        const extractedDate = extractDateFromTimestampOrString(order.updated_at, timezoneName);
        if (extractedDate) {
            order.updated_at = extractedDate;
        } else {
            log.debug(`Invalid draft created at for order ${order.id}`);
            delete order.updated_at;
        }
    }
    return order;
}

/**
 * Finalise the order : either redirect to last information pages or send the order in db
 * @param selectedLocation
 * @param order
 * @param userId
 * @param tableLinkKey
 * @param tableRestrictions
 * @param history
 * @param dispatch
 * @param catalog
 * @param fromApp
 * @param isTherePaymentData
 * @param hasAcceptedTerms
 * @param data
 * @param localLoyaltyUsePoints
 */
export const finalizeOrderInfo = (
    selectedLocation: Location,
    order: Order,
    userId: string,
    tableLinkKey: string,
    tableRestrictions: Restriction | undefined | null,
    history: H.History,
    dispatch: Dispatch<any>,
    catalog: Catalog,
    fromApp: SignInProviders | null | undefined,
    isTherePaymentData: PaymentState["isTherePaymentData"],
    hasAcceptedTerms: boolean,
    data?: AuthenticationStateData,
    localLoyaltyUsePoints?: boolean,
) => {

    let infosRequire = selectedLocation.require_customer_info?.[order.service_type] ?? null;
    let infoNeeded: string[] = [];

    if (data) {
        const { location_authentication_config, user_authentication_state } = data
        const { authentication_mandatory, only_email_verified } = location_authentication_config
        const { is_email_verified } = user_authentication_state

        if (!isUserLoggedIn(user_authentication_state) && authentication_mandatory) {

            log.error("User is not logged in and authentication is mandatory: cannot go further");
            return;
        }

        if (
            isUserLoggedIn(user_authentication_state)
            && !isEmailVerifiedIfMandatory(is_email_verified, only_email_verified)
        ) {

            log.error("User email is not verified, mandatory condition: cannot go further");
            return;
        }


    }
    if (infosRequire) {
        infoNeeded = getCustomerInfoFieldsToAsk(order, infosRequire)
    }

    log.debug('Customer info needed', { infoNeeded, hasAcceptedTerms })
    log.debug(`Table ${tableLinkKey}, Current service : ${order.service_type}`)

    // TODO: before || order.service_type === SupportedServiceType.EAT_IN
    // Useful to suggest to leave an email > think about it when needed
    if ((infoNeeded && infoNeeded.length) || !hasAcceptedTerms) {
        dispatch(OrderAction.openModal())
        history.push(getPaymentCollectionInfoFullRoute(tableLinkKey));
        return;
    }

    choosePaymentModeOrSendPayOrder(
        order,
        selectedLocation,
        userId,
        tableRestrictions,
        dispatch,
        history,
        tableLinkKey,
        fromApp,
        localLoyaltyUsePoints,
        isTherePaymentData,
    );
};

/**
 * Either display window to choose payment method 
 * Or send the order to the db with the right payment configuration
 * @param total 
 * @param supported_payment_types 
 * @param dispatch 
 * @param history 
 * @param tableLinkKey 
 */
export const choosePaymentModeOrSendPayOrder = (
    order: Order,
    location: Location,
    userId: string,
    tableRestriction: Restriction | undefined | null,
    dispatch: Dispatch<any>,
    history: any,
    tableLinkKey: string,
    fromApp: SignInProviders | null | undefined,
    localLoyaltyUsePoints: boolean | undefined,
    isTherePaymentData: PaymentState["isTherePaymentData"],
) => {

    const { supported_payment_types, enable_share_payment, country } = location;

    const alreadySent = orderService.alreadySent(order)
    const availablePaymentTypes = getAvailablePaymentTypes(
        supported_payment_types,
        tableRestriction,
        isTherePaymentData,
        userId,
        alreadySent,
        fromApp
    );
    dispatch(OrderAction.openModal());

    // Remove unavailable payment types
    const filteredPaymentTypes = availablePaymentTypes.filter(paymentType => {
        if (!paymentType.min_amount) {
            return true
        }
        return moneyToNumber(paymentType.min_amount) <= moneyToNumber(order.total)
    });

    if (filteredPaymentTypes.length === 0) {
        log.error("No payment type available");
        return;
    }

    if (
        filteredPaymentTypes.length === 1
        // For restaurant-ticket payment types, always go to payment choice because it needs auth.
        && !RESTAURANT_TICKET_PAYMENT_TYPES.includes(filteredPaymentTypes[0].type)
    ) {

        const paymentType = filteredPaymentTypes[0];

        if (shouldRedirectToSharePayment(order, paymentType.type, country, enable_share_payment)) {
            dispatch(orderActions.setPaymentType(paymentType.type))
            history.push(ROUTES.getPaymentShareItemsFullRoute(tableLinkKey))
        } else {
            sendOrPayOrder(
                dispatch,
                order,
                userId,
                paymentType.type,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                enable_share_payment,
                localLoyaltyUsePoints,
            );
        }

    } else { // Go to choice page
        dispatch(CommonActions.setRedirectURL(ROUTES.getPaymentChoiceFullRoute(tableLinkKey)));
        // history.push(ROUTES.getPaymentChoiceFullRoute(tableLinkKey));
    }
};

/**
 * Send the order
 * @param dispatch 
 * @param tableLinkId 
 * @param paymentType 
 */
export const sendOrPayOrder = (
    dispatch: Dispatch<any>,
    order: Order,
    userId: string,
    paymentType: PaymentTypeExtended,
    paymentAmount?: string,
    paymentData?: any,
    payment_items?: OrderItemToPay[],
    payment_amount_type?: PaymentAmountType,
    tipCharge?: OrderCharge,
    enableSharePayment?: boolean,
    localLoyaltyUsePoints?: boolean,
) => {

    if (!orderService.alreadySent(order)) {
        // Send the order to the API
        dispatch(OrderAction.send(paymentType, paymentAmount, paymentData, '', payment_items, payment_amount_type, tipCharge?.price));
    }
    else {

        const currency = getCurrency(order.total);
        let useLoyaltyPoints: boolean | undefined = undefined;

        if (isLocalLoyaltyOnly(order, enableSharePayment)) {
            useLoyaltyPoints = Boolean(localLoyaltyUsePoints);
        }
        else if (order.contributors) {
            const contributor = order.contributors[userId];
            useLoyaltyPoints = contributor?.use_points;
        }

        dispatch(paymentsActions.createOrderPayment(
            paymentType,
            paymentAmount ?? numberToMoney(paymentHelper.getOrderRemainingAmount(order), currency),
            order.id,
            payment_items,
            payment_amount_type,
            tipCharge?.price,
            undefined,
            undefined,
            useLoyaltyPoints,
        ));
    }
}

/**
 * Get the available payment types. The function makes the intersection of
 * the location supported payment types and the table payment types. WARNING: this
 * function does not check if the min_amount or max_amount are respected, to do
 * this use "getAllowedPaymentType"
 * @param supportedPaymentTypes 
 * @param tableRestriction 
 * @returns 
 */
export const getAvailablePaymentTypes = (
    supportedPaymentTypes: SupportedPayementType[],
    tableRestriction: Restriction | undefined | null,
    isTherePaymentData: PaymentState["isTherePaymentData"],
    userId: string,
    alreadySent?: boolean,
    fromApp?: SignInProviders | null,
): SupportedPaymentTypeExtended[] => {

    let availablePaymentTypes: SupportedPaymentTypeExtended[] = [];

    // lyf in app only if in app (and only this one)
    const lyfInAppPayment = supportedPaymentTypes?.find((paymentType) => paymentType.type === PaymentType.LYF_IN_APP);
    if (lyfInAppPayment) {
        log.debug(`Lyf in app payment supported, check if in the app: fromApp = ${fromApp}`);
        if (fromApp === SignInProviders.LYF) {
            return [lyfInAppPayment];
        }
    }

    supportedPaymentTypes.forEach((paymentType) => {
        if(paymentType.type === PaymentType.WORLDLINE_SMART_POS) {
            return;
        }

        // If already sent, table not available anymore
        if ((!alreadySent || paymentType.type !== PaymentType.TABLE) && paymentType.type !== PaymentType.LYF_IN_APP) {
            if (tableRestriction && tableRestriction.supported_payment_types_refs) {

                for (const tableRestrictionRef of tableRestriction.supported_payment_types_refs) {

                    // The ref is matching, we can push the payment type and exit the loop
                    if (tableRestrictionRef === paymentType.ref) {

                        availablePaymentTypes.push(paymentType);
                        break;
                    }
                }
            }
            else {
                // No payment type restriction for the table. We push the payment type
                availablePaymentTypes.push(paymentType);
            }
        }
    });

    const foundLyramarketplace = availablePaymentTypes.find(pt => pt.type === PaymentType.LYRA_MARKETPLACE) as LyraMarketplaceSupportedPaymentType | undefined;

    // Is there saved payment data for Lyra Marketplace?
    if (
        foundLyramarketplace
        && isTherePaymentData[PaymentType.LYRA_MARKETPLACE]?.[userId]
    ) {
        availablePaymentTypes.push({
            type: SpecificPaymentType.LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA,
            ref: SpecificPaymentType.LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA,
        });
    }

    // If conecs is enabled, add it as a specific "restaurant tickets" type
    if (foundLyramarketplace?.allow_connecs) {
        availablePaymentTypes.push({
            type: SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS,
            ref: SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS,
        });
    }

    /**
     * Fallback: if there are no payment types available, we return the full list.
     * This could happen with old locations as legacy
     */
    if (availablePaymentTypes.length === 0) {
        return supportedPaymentTypes;
    }

    return availablePaymentTypes;
}

export const shouldRedirectToSharePayment = (
    order: Order,
    paymentType: PaymentTypeExtended,
    country: string | undefined,
    sharePaymentEnabled: boolean | undefined,
): boolean => {
    return (
        paymentType !== PaymentType.TABLE
        && paymentType !== PaymentType.CONNECTOR_WALLET
        && sharePaymentEnabled === true
        && order.service_type !== SupportedServiceType.COLLECTION
        && order.service_type !== SupportedServiceType.DELIVERY
        && (
            moneyToNumber(order.total) >= getMinAmountSharePayment(country)
            || getItemQuantityForOrder(order) >= MIN_ITEM_QUANTITY_FOR_SHARE_PAYMENT
        )
    )
}

const getItemQuantityForOrder = (order: Order): number => {
    return order.items.reduce((acc, item) => acc + item.quantity, 0);
}



