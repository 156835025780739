import { DateTime } from "luxon";
import moment from "moment";
import { IntlShape } from 'react-intl';
import { appIntl } from '../../Common/components/IntlGlobalProvider';
import { MIN_DEFAULT_PREPARATION_TIME } from "../../my-lemonade-library/model/Catalog";
import collectionService, { DEFAULT_COLLECTION_SLOT_MINUTES } from "../../my-lemonade-library/src/orders/services/CollectionService";
import { Restriction } from "../../my-lemonade-library/src/restrictions/model/Restriction";
import { getDayNumber, TIME_FORMAT } from "../../my-lemonade-library/src/restrictions/services/RestrictionsService";
import log from "../services/LogService";

export function convertDayOfWeekToStringId(char: string): string | null {
    switch (char) {
        case '1':
            return "monday";
        case '2':
            return "tuesday";
        case '3':
            return "wednesday";
        case '4':
            return "thursday";
        case '5':
            return "friday";
        case '6':
            return "saturday";
        case '7':
            return "sunday";
        default:
            log.error(`Invalid Day of week: ${char}`);
            return null;
    }
}

export function convertDayOfWeekToLocalizedString(char: string): string | null {
    const intl = appIntl()
    const stringId = convertDayOfWeekToStringId(char);
    if (stringId) {
        return intl.formatMessage({ id: stringId });
    } else {
        return null;
    }
}

export const retrieveFirstDayLastDayFromDow = (dow: string | undefined): { firstDay: number, lastDay: number } | null => {

    if (dow) {

        let firstDowNumber: number | null = null;
        let currentDowNumber: number | null = null;

        for (var i = 0; i < dow.length; i++) {

            const char = dow.charAt(i);
            if (char === '-') {
                continue;
            } else {
                const nextDowNumber = parseInt(char);
                if (isNaN(nextDowNumber)) {
                    log.error(`Invalid dow: ${dow}`);
                    return null;
                } else {
                    if (currentDowNumber) {
                        if ((currentDowNumber + 1) !== nextDowNumber) {
                            return null;
                        } else {
                            currentDowNumber = nextDowNumber;
                        }
                    } else {
                        firstDowNumber = nextDowNumber;
                        currentDowNumber = nextDowNumber;
                    }
                }
            }
        }

        if (firstDowNumber && currentDowNumber) {
            return { firstDay: firstDowNumber, lastDay: currentDowNumber };
        }

    }

    // if dow null, we give all day 
    return { firstDay: 1, lastDay: 7 };
}

export const getRestrictionsDayHourDescription = (restriction: Restriction | undefined | null) => {

    const intl = appIntl()

    if (restriction) {

        let dow: string | undefined = restriction?.dow;

        let firstDayLastDay: { firstDay: number, lastDay: number } | null = retrieveFirstDayLastDayFromDow(dow)

        let startTime: string | undefined = restriction?.start_time;
        let endTime: string | undefined = restriction?.end_time;
        let intervalDay: string = "";
        let intervalHour: string = "";
        let firstDay: number = 0;
        let lastDay: number = 0;

        // Retrieve (number) value of deal day
        // Case continuous day
        if (firstDayLastDay) {
            firstDay = firstDayLastDay["firstDay"]
            lastDay = firstDayLastDay["lastDay"]

            // build intervalDay string 
            if ((firstDay !== 0 && lastDay !== 0) && (firstDay !== lastDay)) {
                if (firstDay === 1 && lastDay === 7) {
                    intervalDay = `${intl.formatMessage({ id: "Restrictions.everyDays" })}`
                } else {
                    intervalDay = `${intl.formatMessage(
                        {
                            id: "Restrictions.start_end_days"
                        },
                        {
                            firstDay: `${convertDayOfWeekToLocalizedString(firstDay.toString())}`,
                            lastDay: `${convertDayOfWeekToLocalizedString(lastDay.toString())}`
                        }
                    )}`
                }
            } else if ((firstDay !== 0 && lastDay !== 0) && firstDay === lastDay) {
                const day = convertDayOfWeekToLocalizedString(firstDay.toString())
                intervalDay = `${intl.formatMessage(
                    {
                        id: "Restrictions.start_one_day"
                    },
                    {
                        day: day
                    }
                )}`
            } else {
                log.info("no intervalDay string ")
            }
        } else {
            // case who days not continuous
            for (let i = 1; i < 8; i++) {
                const dayNumber = i.toString(10)
                if (dow?.includes(dayNumber)) {
                    intervalDay = `${intervalDay ? intl.formatMessage({ id: intervalDay }) : ""}
              ${convertDayOfWeekToLocalizedString(dayNumber)}`
                }
            }
        }

        if (startTime && endTime) {
            intervalHour = `${intl.formatMessage(
                {
                    id: "Restrictions.start_end_time.hour"
                },
                {
                    startTime: startTime,
                    endTime: endTime
                }
            )}`
        } else {
            intervalHour = ""
        }

        // build intervalDayAndHour
        if (intervalDay && intervalHour) {
            return `${intervalDay} ${intervalHour}`
        } else if (intervalDay && !intervalHour) {
            return intervalDay
        }
    }

    return ""
}

/**
 * Get all the timeslots available for a specific date (day)
 * @param allRestrictions 
 * @param date 
 * @returns the list of timeslots if restrictions exist for the given day; null if no restrictions matching
 */
export const getTimeslotsSpecificDate = (
    allRestrictions: Restriction[] | undefined,
    date: string,
    timezone: string,
    currentDay: number,
    deliveryDelay: number,
    asDate?: boolean
): string[][] | null => {

    if (allRestrictions && allRestrictions.length) {

        // Get the restrictions for today
        const res = allRestrictions?.filter(
            rest => (!rest.dow || rest.dow?.includes(`${currentDay}`))
        );

        log.debug(`Res with day : ${currentDay} & date : ${date} are`, res)

        // If there is no restriction for the selected day, return null
        if (!res || !res.length) {

            return null;
        }
        else {

            let allowedTimeSlots: string[][] = [];

            res.forEach((restriction) => {
                const timeSlots = collectionService.getAllowedTimeSlots(
                    moment(date),
                    timezone,
                    moment(),
                    restriction.start_time,
                    restriction.end_time,
                    restriction.order_slot_time,
                    restriction.min_preparation_time,
                    deliveryDelay
                );

                if (timeSlots.length) {

                    allowedTimeSlots.push(timeSlots);
                }
            });


            allowedTimeSlots = allowedTimeSlots.sort((firstTimeSlots, secondTimeSlots) => {
                if (!secondTimeSlots || !secondTimeSlots.length) {
                    return -1;
                }
                if (!firstTimeSlots || !firstTimeSlots.length) {
                    return 1;
                }
                if (secondTimeSlots[0] > firstTimeSlots[0]) {
                    return -1;
                } else if (secondTimeSlots[0] < firstTimeSlots[0]) {
                    return 1;
                } else {
                    return 0;
                }
            })

            log.debug("Allowed time slots", allowedTimeSlots);

            return allowedTimeSlots;
        }
    }
    // No restrictions, we return all the timeslots from 00:00 to 23:59
    else {

        const allowedTimeSlots = collectionService.getAllowedTimeSlots(
            moment(date), timezone, moment(), "00:00", "24:00",
            DEFAULT_COLLECTION_SLOT_MINUTES, MIN_DEFAULT_PREPARATION_TIME, deliveryDelay);

        log.debug("Allowed time slots (no restriction)", allowedTimeSlots);

        return [allowedTimeSlots];
    }
}

/**
 * Get a message to display which helps the user understand the list of timeslots available.
 * For example, it will say why there is no slot today, or it will tell that you have to order before
 * a specific time
 * @param restriction 
 * @returns 
 */
export const getRestrictionHelpMessage = (
    allowedDays: moment.Moment[], allRestrictions: Restriction[] | undefined,
    selectedDate: string, today: moment.Moment, timezone: string, intl: IntlShape,
    deliveryDelay: number
) => {

    // Only if today is selected
    if (selectedDate === today.format("YYYY-MM-DD")) {

        /**
         * The program iterates for each day available. It will see if there are
         * slots for the selected day. If yes, a message will be returned saying that the user has until
         * a specific time to order. If not, it will tell that there are no slots
         */
        for (const day of allowedDays) {

            const date = day.format('YYYY-MM-DD');

            const timeSlots: string[][] | null = getTimeslotsSpecificDate(
                allRestrictions, date, timezone, getDayNumber(date), deliveryDelay);

            // Again, only if today
            if (date === selectedDate) {

                // There is a slot available today
                if (timeSlots !== null && timeSlots.length) {

                    const latestIndex1 = timeSlots.length - 1;
                    const latestIndex2 = timeSlots[latestIndex1].length - 1;

                    const timeSlotWithoutPreparationTime: DateTime = DateTime.fromFormat(
                        timeSlots[latestIndex1][latestIndex2], TIME_FORMAT);

                    const restr: Restriction | null = getRestrictionFromDate(timeSlotWithoutPreparationTime.toFormat(TIME_FORMAT),
                        allRestrictions, getDayNumber(date));

                    let timeslotPickup: string = timeSlotWithoutPreparationTime.toFormat(TIME_FORMAT);
                    let timeSlot = timeslotPickup;
                    if (restr && restr.min_preparation_time) {

                        timeSlot = timeSlotWithoutPreparationTime.minus({ minutes: restr.min_preparation_time }).toFormat(TIME_FORMAT);
                    }

                    return intl.formatMessage(
                        { id: "orders.servicePickupInfo.slotAvailableToday" },
                        { timeslot: timeSlot, timeslotPickup: timeslotPickup }
                    );

                }
                else {

                    return intl.formatMessage({ id: "orders.servicePickupInfo.noSlotAvailableToday" })
                }
            }
        }
    }

    return "";
}

/**
 * Returns a restriction matching with a given date (dayNumber and time)
 * @param time formatted in HH:mm
 * @param allRestrictions 
 * @param currentDay the number of the day (dow)
 * @returns 
 */
export const getRestrictionFromDate = (time: string, allRestrictions: Restriction[] | undefined,
    currentDay: number): Restriction | null => {

    if (allRestrictions && allRestrictions.length) {

        // Get the restrictions for today
        const res = allRestrictions?.filter(
            rest => (!rest.dow || rest.dow?.includes(`${currentDay}`))
        );

        // If there is no restriction for the selected day, return null
        if (!res || !res.length) {

            return null;
        }
        else {

            const searchTime: DateTime = DateTime.fromFormat(time, TIME_FORMAT);

            // Iteration trough the restrictions
            for (const restriction of res) {

                let conditionsOk: boolean = true;

                if (restriction.start_time) {

                    const restrStartTime: DateTime = DateTime.fromFormat(restriction.start_time, TIME_FORMAT);

                    if (restrStartTime > searchTime) { conditionsOk = false; }
                }

                if (restriction.end_time) {

                    const restrEndTime: DateTime = DateTime.fromFormat(restriction.end_time, TIME_FORMAT);

                    if (restrEndTime < searchTime) { conditionsOk = false; }
                }

                if (conditionsOk) {

                    return restriction;
                }
            }

            return null;
        }
    }
    // No restrictions
    else {

        return null;
    }
}