import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";

export const FIRESTORE_REVIEWS_COLLECTION = "reviews";

export const getLocationReviewsFirestoreCollectionPath = (accountId: string, locationId: string): string => {
    return `${getLocationFirestoreDocPath(accountId, locationId)}/${FIRESTORE_REVIEWS_COLLECTION}`;
}

export const getLocationReviewFirestoreDocPath = (accountId: string, locationId: string, reviewId: string): string => {
    return `${getLocationReviewsFirestoreCollectionPath(accountId, locationId)}/${reviewId}`;
}