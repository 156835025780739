import { Box, Button, Snackbar, Typography, useTheme } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import * as ROUTES from '../../config/routes';
import StripePaymentInfos from '../../my-lemonade-library/src/payments/models/stripe/StripePaymentIntentInfos';
import { useTypedSelector } from '../../redux/root-reducer';

const PaymentStripe = (props: any) => {

    const params = useParams<ROUTES.RouteParams>();
    const stripePaymentInfos: StripePaymentInfos = useTypedSelector(state => state.order.payment_infos) as StripePaymentInfos;
    let [error, setError] = useState(false)
    let [open, setOpen] = useState(false)

    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();

    //Change page if order secret is not defined
    useEffect(() => {

        async function stripe() {
            const stripe: any = await loadStripe(stripePaymentInfos.public_key);
            const { error } = await stripe.redirectToCheckout({ sessionId: stripePaymentInfos.session_id });
            if (error.message) {
                setError(true)
                setOpen(true)
            }
        }

        if (stripePaymentInfos && stripePaymentInfos.session_id) {
            stripe()
        } else {
            history.push(ROUTES.getCategoriesFullRoute(params.tableLinkKey))
        }

    }, [stripePaymentInfos])


    const handleClose = (event?: Event | React.SyntheticEvent<any, Event>, reason?: string) => {

        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    return (

        <Box
            justifyContent="flex-start"
            style={{ backgroundColor: theme.palette.background.default }}
            height={{ xs: '100%', sm: 'unset' }}
        >

            <Box height={'100%'}>

                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >

                    <Snackbar open={open} autoHideDuration={6000} onClose={(e, m) => handleClose(e, m)}>

                        <Box
                            height="60px"
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            p={1}
                            mb={3}
                        >
                            <Typography color="error">
                                {intl.formatMessage({ id: "Payment.error" })}
                            </Typography>
                        </Box>

                    </Snackbar>

                    {
                        error ?

                            <Box display="flex" justifyContent="center">

                                <Button
                                    variant="contained"
                                    color="primary"
                                >
                                    <Typography variant="h4">
                                        {intl.formatMessage({ id: "authentication.authentication_error.button" })}
                                    </Typography>
                                </Button>

                            </Box>

                            : null
                    }

                    {
                        /*<Elements stripe={stripePromise}>
                        <OrderHeader text={intl.formatMessage({id: "Payment.pay"})}/>
                        <CheckoutForm />
                        </Elements>*/
                    }

                </Box>

            </Box>
        </Box>
    );
}

export default PaymentStripe
