import _ from "lodash";
import { SupportedServiceType } from "../../../model/Location";
import { Order, OrderItem, OrderPayment, PaymentType } from "../../../model/Order";
import { SignInProviders } from "../../authentications/models/BaseUser";
import { getCurrency, Money, moneyToNumber, numberToMoney, substractMoney } from "../../common/models/Money";
import { AutoId } from "../../common/services/AutoId";
import { log } from "../../common/services/LogService";
import { DiscountType, OrderDiscount } from "../../discounts/models/OrderDiscount";
import OrderContributor from "../../orders/models/OrderContributor";
import { computeOrderItemTotals, itemUnitPriceWithOptions } from "../../orders/services/OrderPricing";
import { orderService } from "../../orders/services/OrderService";
import { computeOrderTotalsWithoutTaxUpdate } from "../../orders/services/OrderTotals";
import { PaymentStatus } from "../../payments/models/PaymentStatus";
import { paymentHelper } from "../../payments/services/PaymentHelper";
import { ComputeLoyaltyResult } from "../models/ComputeLoyaltyResult";
import LoyaltyEarningRule from "../models/LoyaltyEarningRule";
import LoyaltyError from "../models/LoyaltyError";
import { LoyaltyLocationConfig } from "../models/LoyaltyLocationConfig";
import { OrderLoyaltyOperations } from "../models/OrderLoyaltyOperations";

/**
 * Precision for rounding
 */
export const LOYALTY_PONTS_PRECISION = 1000;

/**
 * this function checks the items for user and also check if 
 * there are category restrictions in the rules
 * @param item 
 * @param userId 
 * @param ruleCategoriesRefs of the earning_rule or spending_rule 
 * @returns 
 */
const checkItemCategoryAndUpdateId = (item: OrderItem, includeItemsWithUpdateId: boolean, ruleCategoriesRefs?: string[]): boolean => {

    // If the item has been already sent to the api do not recompute points again
    if (item.update_id && !includeItemsWithUpdateId) {
        return false;
    }
    // does the rule has categories references ?
    if (ruleCategoriesRefs?.length) {
        // does the item has categories references ?
        if (item.categories_refs?.length) {
            // check if the categories refs of the item includes the categories refs of the rule
            return item.categories_refs.filter((ref: string) =>
                ruleCategoriesRefs.includes(ref)).length > 0
        }
        // the item doesn't have a category ref, by precaution we avoid it
        else return false;
    } else return true;
};


/**
 * takes the service types in the loyalty config and check if it matchs the order
 * @param service_types
 * @param order 
 * @returns 
 */
const checkOrderServiceType = (service_types: SupportedServiceType[] | undefined, order: Order): boolean => {
    if (service_types) {
        return (order.service_type && service_types.includes(order.service_type))
    } else return true;
};


/**
* return the point balance (difference between the earned points and used points) for the user with the order, according to the loyalty configuration rules
 * TODO: add LocationOrderConfig ?
 * @param order 
 * @param userId 
 * @param loyaltyConfig 
 * @param userBalance
 * @param doNotUsePoint true by default
 * @returns null if the order does not compute the loyalty, or a number of points
 */
export const getPointsBalance = (order: Order, userId: string, loyaltyConfig: LoyaltyLocationConfig, userBalance: number, doNotUsePoint: boolean = true): number => {

    // Point balance only for the loyalty user
    if (order.loyalty_user_id === userId) {
        const computedLoyalty = computeOrderLoyalty(order, userBalance, userId, loyaltyConfig, doNotUsePoint);
        //console.log("Computed loyalty", computedLoyalty)
        //console.log("order after loyalty", order)
        return (computedLoyalty && computedLoyalty.order_points_balance) ? computedLoyalty.order_points_balance : 0;
    }
    return 0.
};


export const getItemEarnedPoints = (earningRule: LoyaltyEarningRule, itemUnitPrice: number, quantity: number): number | undefined => {

    switch (earningRule.earning_mode) {
        case ('item_count'):
            return Math.floor(quantity / Number(earningRule.amount)) * earningRule.points;
        case ('item_price'):
            const earningRulePrice = moneyToNumber(earningRule.amount as Money);
            return (quantity * itemUnitPrice) / earningRulePrice * earningRule.points;
    }
    return undefined;
}

/**
 * takes the point balance, the fidelity configuration and return if the
 * customer can use his points to get a discount or not
 * @param balance the customer balance point
 * @param loyaltyConfig 
 * @param order
 * @returns 
 */
export const canUsePoint = (balance: number, loyaltyConfig: LoyaltyLocationConfig, order: Order, userId: string): boolean => {

    // Only the loyalty user can use points
    if (order.loyalty_user_id === userId) {
        const computedLoyalty = computeOrderLoyalty(order, balance, userId, loyaltyConfig);
        //console.log("Computed loyalty", computedLoyalty)
        return (computedLoyalty && computedLoyalty.can_use_point) ? computedLoyalty.can_use_point : false;
    }
    return false;
};


/**
 * Spend the loyalty
 * Return the updated points earned: it may be the case that after updating the number of points is not enough anymore
 */
const spendLoyalty = (order: Order, userId: string, spendingOperationId: string, loyaltyConfig: LoyaltyLocationConfig, loyaltiesApplicationCount: number, earningIncludeItemsWithUpdateId: boolean, spendingIncludeItemsWithUpdateId: boolean): { pointsSpent: number, priceOff: number, updatedPointsEarned: number } => {

    //console.log("### SPEND LOYALTY", spendingOperationId, loyaltiesApplicationCount);

    // The amount which will be spent for each loyalty application (if we go to the end of process)
    // It's a number but it can represent points or money depending on the spending mode
    let amountToSpendByProcess = 0;

    // Count all what's been spent in the whole process
    let totalSpentPoints = 0;
    let totalPriceOff = 0;

    // Do the process even when loyaltiesApplicationCount is équal to 0 in order to reset points

    // Temp variable which can be number or money 
    let loyaltySpentAmountPerApplication = 1;

    if (loyaltyConfig.spending_rule.spending_mode === "free_item") {
        loyaltySpentAmountPerApplication = (loyaltyConfig.spending_rule.amount as number);
    }
    else if (loyaltyConfig.spending_rule.spending_mode === "order_discount") {
        loyaltySpentAmountPerApplication = moneyToNumber(loyaltyConfig.spending_rule.amount as Money);
    }
    amountToSpendByProcess = loyaltiesApplicationCount * loyaltySpentAmountPerApplication;

    //console.log("### tempLoyaltySpentAmount", amountToSpendByProcess);

    order.items
        .filter((item: OrderItem) => checkItemCategoryAndUpdateId(item, spendingIncludeItemsWithUpdateId, loyaltyConfig.spending_rule.categories?.refs))
        .forEach((item: OrderItem) => {

            // TODO: Check if item apply to spending rule (example can only spend for drinks)
            let itemPriceOff = 0;

            switch (loyaltyConfig.spending_rule.spending_mode) {
                case ('free_item'):
                    const itemUnitPrice = itemUnitPriceWithOptions(item);

                    if (item.quantity < amountToSpendByProcess) {
                        itemPriceOff = item.quantity * itemUnitPrice;
                        amountToSpendByProcess -= item.quantity;
                        item.points_used = item.quantity * loyaltyConfig.spending_rule.points / loyaltySpentAmountPerApplication;
                        item.points_earned = 0;
                    }
                    else {
                        itemPriceOff = amountToSpendByProcess * itemUnitPrice;
                        item.points_used = amountToSpendByProcess * loyaltyConfig.spending_rule.points / loyaltySpentAmountPerApplication;
                        if (item.points_earned) {
                            item.points_earned = getItemEarnedPoints(loyaltyConfig.earning_rule, itemUnitPrice, item.quantity - amountToSpendByProcess)
                        }
                        amountToSpendByProcess = 0;
                    }
                    break;

                case ('order_discount'):
                    const itemPrices = computeOrderItemTotals(item, getCurrency(order.total));

                    if (amountToSpendByProcess >= itemPrices) {

                        itemPriceOff = Math.floor(itemPrices / loyaltySpentAmountPerApplication) * loyaltySpentAmountPerApplication
                        amountToSpendByProcess -= itemPrices;

                        item.points_used = Math.floor((itemPriceOff / loyaltySpentAmountPerApplication) * loyaltyConfig.spending_rule.points);
                    }
                    else {

                        itemPriceOff = amountToSpendByProcess;
                        item.points_used = amountToSpendByProcess * loyaltyConfig.spending_rule.points / loyaltySpentAmountPerApplication;
                        amountToSpendByProcess = 0;
                    }
                    break;
            }

            if (item.points_used) {

                item.loyalty_operation_spending_id = spendingOperationId;
                totalPriceOff += itemPriceOff;
                totalSpentPoints += item.points_used;
            }
            else {
                delete item.loyalty_operation_spending_id
            }
        })


    const updatedPointsEarned = order.items
        .filter((item: OrderItem) => checkItemCategoryAndUpdateId(item, earningIncludeItemsWithUpdateId, loyaltyConfig.earning_rule.categories?.refs))
        .filter((item: OrderItem) => item.points_earned !== undefined)
        .map((item: OrderItem) => item.points_earned)
        .reduce((val: number, cur: number | undefined) => val + cur!, 0);
    //console.log(`Updated points earned: ${updatedPointsEarned}`, order.items);

    return {
        pointsSpent: totalSpentPoints,
        priceOff: totalPriceOff,
        updatedPointsEarned: updatedPointsEarned ? updatedPointsEarned : 0
    };
}

/**
 * Check that the loyalty can be earnt
 * Otherwise throw an error for now
 * @param order 
 * @returns 
 */
export const canEarnLoyalty = (order: Order, loyaltyConfig: LoyaltyLocationConfig, doNotThrowIfError: boolean = false, manualWithReceipt: boolean = false): boolean => {

    if (loyaltyConfig.disabled) {
        if (doNotThrowIfError) {
            return false;
        }
        throw LoyaltyError.DISABLED_LOYALTY_CONFIG;
    }

    if (manualWithReceipt && !loyaltyConfig.earning_rule.allow_earning_all_points_on_receipts) {
        if (doNotThrowIfError) {
            return false;
        }
        throw LoyaltyError.EARNING_ON_RECEIPT_NOT_ALLOWED;
    }

    if ((loyaltyConfig.earning_rule.earning_mode !== "payments" && (order.loyalty_user_id || order.loyalty_operations?.length))
        ||
        (loyaltyConfig.earning_rule.earning_mode === "payments" && paymentHelper.isPaid(order))
    ) {
        if (doNotThrowIfError) {
            return false;
        }
        throw LoyaltyError.ALREADY_EARNT_POINTS.withValue({
            loyalty_user_id: order.loyalty_user_id,
            loyalty_operations_count: order.loyalty_operations?.length
        });
    }

    if (orderService.isFullyEditable(order)) {
        if (doNotThrowIfError) {
            return false;
        }
        throw LoyaltyError.EARN_POINTS_INVALID_ORDER_STATUS.withValue({
            order_status: order.status
        });
    }

    // Maybe can be improved later but for now only for orders without draft items
    if (orderService.hasItemToBeAdded(order)) {
        if (doNotThrowIfError) {
            return false;
        }
        throw LoyaltyError.EARN_POINTS_INVALID_ORDER_STATUS;
    }

    return true;
}

export const roundEarnedPoints = (earnedPoints: number): number => {
    // Using round because of the JS float imprecisions (for ex: 5.6/.1 = 55.9999999)
    return Math.floor(Math.round(earnedPoints * LOYALTY_PONTS_PRECISION) / LOYALTY_PONTS_PRECISION); //
}

/**
 * takes an order in params, modify the order directly and apply the loyalty
 * the balance of the user's loyalty points isn't modified in this function
 * applyDiscountToOrder
 * @param order 
 * @param customer 
 * @param loyaltyConfig 
 * @returns the amount of points spent by the user for the loyalty
*/
export const computeOrderLoyalty = (
    order: Order,
    userBalance: number,
    userId: string,
    loyaltyConfig: LoyaltyLocationConfig,
    doNotUsePoint?: boolean,
    doNotEarnPoint?: boolean,
    earningIncludeItemsWithUpdateId: boolean = false,
    spendingIncludeItemsWithUpdateId: boolean = false,
    earnPaymentLoyaltyForWholeOrder: boolean = false,
): ComputeLoyaltyResult | null => {

    if (!loyaltyConfig || loyaltyConfig.disabled) {
        return null;
    }

    if (!orderService.isContributorAuthenticated(order, userId)) {
        return null;
    }

    // Remove all the not-already-processed loyalty discounts & operations
    if (order.discounts) {
        // Keep the discounts that are not loyalty discounts, or already-sent loyalty discounts,
        // or discounts created by another user (we don't want to erase the discounts created by another user
        // when computing loyalty for the current user)
        order.discounts = order.discounts.filter((d) => Boolean(
            d.type !== DiscountType.LOYALTY
            || d.update_id
            || d.user_id !== userId
        ));
    }
    if (order.loyalty_operations) {
        // Keep the already-sent operations, or the operations created by other users (we don't want to erase
        // the operations created by another user when computing loyalty for the current user)
        order.loyalty_operations = order.loyalty_operations.filter((o) => Boolean(
            o.update_id
            || o.user_id !== userId
        ));
        if (!doNotEarnPoint) {
            // Do not keep the earning operations that are not sent yet to the API.
            // Use-case: someone else wants to earn the points on this order: we can't keep the
            // loyalty_operation for the current user because if we did, there would be 2 earning operations
            order.loyalty_operations = order.loyalty_operations.filter((o) => !Boolean(
                !o.update_id
                && o.earning_mode
            ));
        }
    } else {
        order.loyalty_operations = [];
    }

    // exception => LoyaltyError
    if (!loyaltyConfig?.earning_rule?.amount) throw LoyaltyError.EARNING_RULE_AMOUNT_ERROR;
    if (!loyaltyConfig?.earning_rule?.points) throw LoyaltyError.EARNING_RULE_POINTS_ERROR;
    if (!loyaltyConfig?.spending_rule?.amount) throw LoyaltyError.SPENDING_RULE_AMOUNT_ERROR;
    if (!loyaltyConfig?.spending_rule?.points) throw LoyaltyError.SPENDING_RULE_POINTS_ERROR;

    // We check also if payment type and service type matchs the loyalty configuration
    // Otherwise return {}
    let orderPointsEarned = 0;
    const idSpendingRef = AutoId.newId(loyaltyConfig.spending_rule.spending_mode);
    const idEarningRef = AutoId.newId(loyaltyConfig.earning_rule.earning_mode);
    let updatedUserBalance = userBalance;
    let orderPointsSpent = 0;
    let priceOff = 0;

    if (
        !doNotEarnPoint &&
        checkOrderServiceType(loyaltyConfig.earning_rule.service_type, order)
    ) {
        if (loyaltyConfig.earning_rule.earning_mode !== "payments") {
            if (loyaltyConfig.earning_rule.earning_mode === 'item_count' || loyaltyConfig.earning_rule.earning_mode === 'item_price') {
                order.items
                    .filter((item: OrderItem) => checkItemCategoryAndUpdateId(item, earningIncludeItemsWithUpdateId, loyaltyConfig.earning_rule.categories?.refs))
                    .map((item: OrderItem) => {

                        const points = getItemEarnedPoints(loyaltyConfig.earning_rule, itemUnitPriceWithOptions(item), item.quantity)
                        //console.log(`Points for item ${item.sku_name ? item.sku_name : item.sku_ref}: ${points}`);
                        if (points) {
                            item.loyalty_operation_earning_id = idEarningRef;
                            item.points_earned = points;
                            orderPointsEarned += points;
                        }
                        return item;
                    });

            }
            else if (loyaltyConfig.earning_rule.earning_mode === 'order_count') {
                orderPointsEarned =
                    order.items.filter((item: OrderItem) => checkItemCategoryAndUpdateId(item, earningIncludeItemsWithUpdateId, loyaltyConfig.earning_rule.categories?.refs)).length
                        ? loyaltyConfig.earning_rule.points
                        : 0;

            }
            orderPointsEarned = roundEarnedPoints(orderPointsEarned);
        } else if (earnPaymentLoyaltyForWholeOrder) {
            log.info(`Computing payment loyalty for all order`)
            const paymentLoyaltyForWholeOrder = computePaymentLoyaltyForWholeOrder(loyaltyConfig, order, userBalance, userId, true);
            if (paymentLoyaltyForWholeOrder) {
                orderPointsEarned = roundEarnedPoints(paymentLoyaltyForWholeOrder.earned_points ?? 0);
            }
        } else {
            log.info(`Not possible to earn payments points on this order`)
        }
    } else {
        log.info(`Not possible to earn points on this order`, { order, loyaltyConfig })
    }


    //console.log("### deltaPointsEarned", orderPointsEarned);

    // number of times you can use loyaltyPoints
    const pointsToSpend = loyaltyConfig.spending_rule.only_future_order ? updatedUserBalance : orderPointsEarned + updatedUserBalance;
    let loyaltiesApplicationCount = Math.floor(pointsToSpend / loyaltyConfig.spending_rule.points);

    //console.log(`### Loyalty application count ${loyaltiesApplicationCount} with ${pointsToSpend} to spend and spending rule points ${loyaltyConfig.spending_rule.points}`);
    if (
        checkOrderServiceType(loyaltyConfig.spending_rule.service_type, order)
    ) {
        // Let's use the points if possible
        if (!doNotUsePoint) {

            let spendLoyaltyResult = spendLoyalty(order, userId, idSpendingRef, loyaltyConfig, loyaltiesApplicationCount, earningIncludeItemsWithUpdateId, spendingIncludeItemsWithUpdateId);
            if (!loyaltyConfig.spending_rule.only_future_order) {
                orderPointsEarned = spendLoyaltyResult.updatedPointsEarned;
            }

            //console.log("### updatedPointsEarned", orderPointsEarned);
            //console.log("### spendLoyaltyResult", spendLoyaltyResult);

            // Check that after spending in free item mode we can still have enough points earned to have the initial number of applications
            if (!loyaltyConfig.spending_rule.only_future_order &&
                loyaltyConfig.spending_rule.spending_mode === 'free_item') {
                const loyaltiesApplicationCountAfterSpend = Math.floor((orderPointsEarned + updatedUserBalance) / loyaltyConfig.spending_rule.points);
                if (loyaltiesApplicationCountAfterSpend < loyaltiesApplicationCount) {

                    loyaltiesApplicationCount = loyaltiesApplicationCountAfterSpend;
                    spendLoyaltyResult = spendLoyalty(order, userId, idSpendingRef, loyaltyConfig, loyaltiesApplicationCount, earningIncludeItemsWithUpdateId, spendingIncludeItemsWithUpdateId);
                    if (!loyaltyConfig.spending_rule.only_future_order) {
                        orderPointsEarned = spendLoyaltyResult.updatedPointsEarned;
                    }
                    //console.log("### spendLoyaltyResult 2nd", spendLoyaltyResult);
                    //console.log(`Points earned: ${orderPointsEarned}`);
                }
            }

            orderPointsSpent = spendLoyaltyResult.pointsSpent;
            priceOff = spendLoyaltyResult.priceOff;
        }
    } else {
        log.info(`Not possible to spend points on this order`, { order, loyaltyConfig })
    }

    const orderPointsBalance = orderPointsEarned - orderPointsSpent;
    const canUsePoint = loyaltiesApplicationCount > 0;
    //console.log(`### orderPointsBalance ${orderPointsBalance}, loyaltiesApplicationCount ${loyaltiesApplicationCount}, can use ${canUsePoint}`);
    //console.log(`Order points earned ${orderPointsEarned}, Order points spent ${orderPointsSpent}, Order points balance ${orderPointsBalance}, User balance ${userBalance}, Only future orders ${loyaltyConfig.spending_rule.only_future_order}`)
    updatedUserBalance = orderPointsBalance + userBalance;

    if (orderPointsEarned > 0) {

        const loyaltyOperation: OrderLoyaltyOperations = {
            id: idEarningRef,
            delta: orderPointsEarned,
            earning_mode: loyaltyConfig.earning_rule.earning_mode,
            user_id: userId,
            reason: "earn_points_by_order",
            new_balance: userBalance + orderPointsEarned
        }

        if (order.loyalty_operations) {
            order.loyalty_operations.push(loyaltyOperation);
        }
        else {
            order.loyalty_operations = [loyaltyOperation];
        }
    }

    if (orderPointsSpent > 0) {
        let discount: OrderDiscount = {
            name: loyaltyConfig.name,
            user_id: userId,
            ref: "loyalty",
            type: DiscountType.LOYALTY,
            price_off: numberToMoney(priceOff, getCurrency(order.total)),
            loyalty_operation_id: idSpendingRef
        };
        if (!order.discounts) {
            order.discounts = [];
        }
        order.discounts.push(discount);

        const loyaltyOperation: OrderLoyaltyOperations = {
            id: idSpendingRef,
            delta: -1 * orderPointsSpent,
            spending_mode: loyaltyConfig.spending_rule.spending_mode,
            user_id: userId,
            reason: "spend_points_by_order",
            new_balance: updatedUserBalance
        }

        if (order.loyalty_operations) {
            order.loyalty_operations.push(loyaltyOperation);
        }
        else {
            order.loyalty_operations = [loyaltyOperation];
        }
    }

    // Always recompute total because the discounts may have been removed
    computeOrderTotalsWithoutTaxUpdate(order, getCurrency(order.total), true)

    if (orderPointsEarned || orderPointsSpent) {
        const computeLoyaltyResponse: ComputeLoyaltyResult = {
            user_id: userId,
            order_points_balance: orderPointsBalance,
            earned_points: orderPointsEarned,
            can_use_point: canUsePoint,
            old_balance: userBalance,
            new_balance: updatedUserBalance,
            used_points: orderPointsSpent,
            price_off: numberToMoney(priceOff, getCurrency(order.total)),
        }
        if (orderPointsEarned) {
            computeLoyaltyResponse.loyalty_operation_earning_id = idEarningRef;
        }
        if (orderPointsSpent) {
            computeLoyaltyResponse.loyalty_operation_spending_id = idSpendingRef;
        }
        return computeLoyaltyResponse;
    } else {
        return null;
    }

};

// TODO: Remove, as we now use computeLoyaltyForAllUsers
export const computeLoyaltyForLoyaltyUser = (
    order: Order,
    loyaltyConfig: LoyaltyLocationConfig,
    earningIncludeItemsWithUpdateId?: boolean,
    spendingIncludeItemsWithUpdateId?: boolean,
): ComputeLoyaltyResult | null => {

    const userId = order.loyalty_user_id;
    if (userId) {

        const contributor: OrderContributor | undefined = order.contributors && order.contributors[userId];
        if (contributor) {

            const doNotUsePoints = contributor.use_points === undefined
                ? undefined
                : !contributor.use_points;

            const loyaltyResponse = computeOrderLoyalty(
                order,
                (contributor.loyalty_balance === undefined ? 0 : contributor.loyalty_balance),
                userId,
                loyaltyConfig,
                doNotUsePoints,
                undefined,
                earningIncludeItemsWithUpdateId,
                spendingIncludeItemsWithUpdateId,
            );

            if (loyaltyResponse) {
                order.contributors![userId].can_use_points = loyaltyResponse.can_use_point;
                return loyaltyResponse;
            }
        }
    }

    return null;
}

export const computeLoyaltyForAllUsers = (
    order: Order,
    loyaltyConfig: LoyaltyLocationConfig,
    earningIncludeItemsWithUpdateId?: boolean,
    spendingIncludeItemsWithUpdateId?: boolean,
): ComputeLoyaltyResult[] | null => {

    const loyaltyResponses: ComputeLoyaltyResult[] = [];

    if (order.contributors) {
        for (const userId in order.contributors) {

            const contributor: OrderContributor = order.contributors[userId];
            if (!orderService.isContributorAuthenticated(order, userId)) {
                // Force setting can use points to false
                contributor.can_use_points = false;
            } else {

                // Earn only if the current user is the order loyalty_user
                const doNotEarnPoints = contributor.uid !== order.loyalty_user_id;

                const doNotUsePoints = contributor.use_points === undefined
                    ? undefined
                    : !contributor.use_points;

                const loyaltyResponse = computeOrderLoyalty(
                    order,
                    (contributor.loyalty_balance === undefined ? 0 : contributor.loyalty_balance),
                    userId,
                    loyaltyConfig,
                    doNotUsePoints,
                    doNotEarnPoints,
                    earningIncludeItemsWithUpdateId,
                    spendingIncludeItemsWithUpdateId,
                );

                if (loyaltyResponse) {
                    order.contributors![userId].can_use_points = loyaltyResponse.can_use_point;
                    loyaltyResponses.push(loyaltyResponse);
                }
            }
        }
    }

    if (loyaltyResponses.length > 0) {
        return loyaltyResponses;
    }
    else {
        return null;
    }
}

/**
 * Check the loyalty config: is it consistent for a "payments_only" config?
 */
const canUsePaymentLoyalty = (loyaltyConfig: LoyaltyLocationConfig): boolean => {

    return Boolean(
        loyaltyConfig.earning_rule.earning_mode === "payments"
        && loyaltyConfig.spending_rule.spending_mode === "order_discount" // TODO: to be removed
    );
}

export const getAlreadyEarntPoints = (order: Order): number => {
    let alreadyEarntPoints = 0;
    order.loyalty_operations?.forEach((loyaltyOperation) => {
        if (loyaltyOperation.reason === "earn_points_by_order" || loyaltyOperation.reason === "earn_points_by_payment") {
            alreadyEarntPoints += loyaltyOperation.delta;
        }
    })
    return alreadyEarntPoints;
}

const getPaymentLoyaltyPoints = (
    amount: Money,
    loyaltyConfig: LoyaltyLocationConfig
): number => {

    const earningRuleAmountNumber = moneyToNumber(loyaltyConfig.earning_rule.amount as Money);

    if (earningRuleAmountNumber === 0) {
        throw LoyaltyError.INCORRECT_LOYALTY_CONFIG.withValue({ message: "earning rule amount is 0, cannot divide.", loyaltyConfig });
    }

    let pointsEarned: number = moneyToNumber(amount)
        * loyaltyConfig.earning_rule.points
        / earningRuleAmountNumber;

    pointsEarned = roundEarnedPoints(pointsEarned);
    return pointsEarned;
}

export const computePaymentLoyalty = (
    loyaltyConfig: LoyaltyLocationConfig,
    orderPayment: OrderPayment,
    order: Order,
    userBalance: number,
    userId: string,
    doNotAddLoyaltyOperation: boolean = false,
): ComputeLoyaltyResult | null => {

    if (loyaltyConfig.disabled) {
        // TODO : if (!canEarnLoyalty(order, loyaltyConfig, true)) {
        return null;
    }

    if (!orderService.isContributorAuthenticated(order, userId)) {

        return null;
    }

    // Check if order points have not been retrieved manually yet
    const alreadyEarnedPoints = getAlreadyEarntPoints(order);
    const orderTotalPoints = getPaymentLoyaltyPoints(orderService.getOrderTotalWithoutTips(order), loyaltyConfig);
    const pointsLeft = orderTotalPoints - alreadyEarnedPoints;
    if (pointsLeft <= 0) {
        // Not possible to earn points anymore
        return null;
    }

    if (!loyaltyConfig?.earning_rule?.amount) throw LoyaltyError.EARNING_RULE_AMOUNT_ERROR.withValue({ loyaltyConfig });
    if (!loyaltyConfig?.earning_rule?.points) throw LoyaltyError.EARNING_RULE_POINTS_ERROR.withValue({ loyaltyConfig });
    if (!canUsePaymentLoyalty(loyaltyConfig)) throw LoyaltyError.INCORRECT_LOYALTY_CONFIG.withValue({ loyaltyConfig });

    // Substract the tips amount
    let amountForPointsCount = orderPayment.amount;
    if (orderPayment.tip_charge_amount) {
        amountForPointsCount = substractMoney(amountForPointsCount, orderPayment.tip_charge_amount);
    }

    const pointsEarned = Math.min(getPaymentLoyaltyPoints(amountForPointsCount, loyaltyConfig), pointsLeft);

    // JS falsy: we also check that it's > 0
    if (pointsEarned) {

        const earningId = AutoId.newId(loyaltyConfig.earning_rule.earning_mode);
        if (!doNotAddLoyaltyOperation) {
            const loyaltyOperation: OrderLoyaltyOperations = {
                id: earningId,
                delta: pointsEarned,
                earning_mode: loyaltyConfig.earning_rule.earning_mode,
                user_id: userId,
                reason: "earn_points_by_payment",
                new_balance: userBalance + pointsEarned,
                order_id: order.id,
                payment_intent_id: orderPayment.payment_intent_id,
            }

            if (order.loyalty_operations) {
                order.loyalty_operations.push(loyaltyOperation);
            }
            else {
                order.loyalty_operations = [loyaltyOperation];
            }
        }

        const computeLoyaltyResponse: ComputeLoyaltyResult = {
            user_id: userId,
            order_points_balance: pointsEarned,
            earned_points: pointsEarned,
            loyalty_operation_earning_id: earningId,
            old_balance: userBalance,
            new_balance: userBalance + pointsEarned,
        }

        return computeLoyaltyResponse;
    }

    return null;
}

/**
 * Create a copy of the order,
 * simulate that the user is authenticated and is the only one with use_points set to true
 * @param order 
 * @param userId 
 */
export const simulateCanUseLoyaltyForUser = (
    order: Order,
    userId: string,
): Order => {
    const orderCopy: Order = _.cloneDeep(order);
    if (!orderCopy.contributors) orderCopy.contributors = {};
    if (orderCopy.contributors && userId) {
        if (!orderCopy.contributors[userId]) {
            orderCopy.contributors[userId] = {
                uid: userId,
            }
        }
        for (const contributorId in orderCopy.contributors) {
            const contributor = orderCopy.contributors[contributorId];
            if (contributorId === userId) {
                contributor.use_points = true;
                // Force beeing authenticated
                // TODO: change signin provider if loyalty config depends on provider
                contributor.sign_in_provider = SignInProviders.PASSWORD;
            }
            else {
                contributor.use_points = false;
            }
        }
    }
    return orderCopy;
}

export const computePaymentLoyaltyForWholeOrder = (
    loyaltyConfig: LoyaltyLocationConfig,
    order: Order,
    userBalance: number,
    userId: string,
    doNotAddLoyaltyOperation: boolean = false,
): ComputeLoyaltyResult | null => {

    // Only count what is remaining to pay
    const remainingAmount = paymentHelper.getOrderRemainingAmount(order);
    const currency = getCurrency(order.total);

    const fullOrderPayment: OrderPayment = {
        payment_intent_id: "full_payment",
        amount: numberToMoney(remainingAmount, currency),
        payment_type: PaymentType.POS,
        status: PaymentStatus.PAID,
    }

    return computePaymentLoyalty(
        loyaltyConfig,
        fullOrderPayment,
        order,
        userBalance,
        userId,
        doNotAddLoyaltyOperation
    );
}

const loyaltyHelper = {
    canUsePoint,
    computeOrderLoyalty,
    getPointsBalance,
    computeLoyaltyForLoyaltyUser,
    canEarnLoyalty,
    computePaymentLoyalty,
    computePaymentLoyaltyForWholeOrder,
    simulateCanUseLoyaltyForUser,
    getAlreadyEarntPoints
};
export default loyaltyHelper;
