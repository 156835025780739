enum MylemonadeScopes {

    /**
     * Common models/functions shared by several scopes
     * 
     */
    COMMON = "common",

    /**
     * Authentication
     * See: https://www.hubrise.com/developers/api/account-management#account
     */
    AUTHENTICATION = "authentication",

    /**
     * An integration: api access for a partner
     */
    INTEGRATIONS = "integrations",

    /**
     * Users access to location and account
     */
    USERS = "users",

    /**
     * Customers
     * See: https://www.hubrise.com/developers/api/customer-management
     */
    CUSTOMERS = "customers",

    /**
     * Account
     * See: https://www.hubrise.com/developers/api/account-management#account
     */
    ACCOUNTS = "accounts",

    /**
     * Locations
     * An account can have several locations (ex: account SushiShop with a location for every place)
     * See: https://www.hubrise.com/developers/api/account-management#location
     */
    LOCATIONS = "locations",

    /**
     * Catalogs: restaurant catalog 
     * with cataegories, products, deals, options, discounts, etc.
     * See: https://www.hubrise.com/developers/api/catalog-management#catalogs
     */
    CATALOGS = "catalogs",

    /**
     * Catalog products
     * See: https://www.hubrise.com/developers/api/catalog-management#products
     */
    PRODUCTS = "products",

    /**
     * Catalog deals
     * See: https://www.hubrise.com/developers/api/catalog-management#deals
     */
    DEALS = "deals",

    /**
     * Catalog options
     * See: https://www.hubrise.com/developers/api/catalog-management#options
     */
    OPTIONS = "options",

    /**
     * Catalog discounts
     * See: https://www.hubrise.com/developers/api/catalog-management#catalogs
     */
    DISCOUNTS = "discounts",

    /**
     * Location orders
     * See: https://www.hubrise.com/developers/api/order-management#orders
     */
    ORDERS = "orders",

    /**
     * A session tracks all the actions of a user, from the QR code scan to the
     * payments, loyalty operaitons, etc.
     */
    SESSIONS = "sessions",

    /**
     * POS connectors (hubrise, carre pos, etc.).
     * Does not exist in hubrise
     */
    CONNECTORS = "connectors",

    /**
     * Payment solutions (stripe, lyra, etc)
     */
    PAYMENTS = "payments",

    /**
     * Qr codes to retrieve orders
     * Does not exist in hubrise
     */
    QRCODES = "qrcodes",

    /**
     * Location tables
     * Does not exist in hubrise
     * 
     */
    TABLES = "tables",

    TABLELINKS = "tablelinks",

    /**
     * Printers
     * Does not exist in hubrise
     */
    PRINTERS = "printers",

    /**
     * Delivery solution
     * Does not exist in hubrise (except for charges)
     */
    DELIVERY = "delivery",

    /**
     * Statistics (orders, customers, etc.)
     * Does not exist in hubrise
     */
    STATS = "stats",

    /**
     * Product suggestions
     * Does not exist in hubrise
     */
    SUGGESTIONS = "suggestions",

    /**
     * Location theming (colors, etc.)
     */
    THEME = "theme",

    /**
     * Catalog translations
     */
    TRANSLATIONS = "translations",

    /**
     * Order taxes
     */
    TAXES = "taxes",

    /**
     * location loyalties
     */
    LOYALTIES = "loyalties",

    /**
     * Notifications: sms, email, webhooks
     */
    NOTIFICATIONS = "notifications",

    INVOICES = "invoices",

    /**
     * Catalog inventories
     */
    INVENTORIES = "inventories",

    /**
     * Brands referential of products
     */
    REFERENTIAL = "referential",


    BRANDS = "brands",

    /**
     * Menu scraping
     */
    SCRAPING = "scraping",

    /**
     * Marketing activations for customer or location
     * Send promotions using the printer
     */
    MARKETING_ACTIVATIONS = "marketing_activations",

    /**
     * Brand users
     */

    BRAND_USERS = "brand_users",

    /**
     * Reviews
     */

    REVIEWS = "reviews",

    /**
     * Locations Groups used to create a group of locations
     */

    LOCATIONS_GROUPS = "locations-groups",

    /**
     * Marketing platforms keep a list of all the users and attributes
     * related to create marketing campaigns.
     * Ex: Sendinblue "contact"
     */
    MARKETING_PLATFORMS = "marketing_platforms",

    /**
     * Extract load transform to put data in BigQuery
     */
    ELT = "elt",

    /**
    * Scripts to reset test data for instance
    */
    SCRIPTS = "scripts",

    /**
     * Challenges
     */
    CHALLENGES = 'challenges',

    /**
     * Analytics
     */
    ANALYTICS = 'analytics',

    /**
     * Analytics
     */
    ANALYTICS_GROUPS = 'analytics-groups',

    /**
     * Posts
     */
    POSTS = 'posts',

    /**
     * Schedules
     */
    SCHEDULES = 'schedules',

    /**
     * Schedules
     */
    PARTNERS = 'partners',
}

export default MylemonadeScopes;