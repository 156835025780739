/**
 * Money interface based on the Hubrise Money model:
 * https://www.hubrise.com/developers/api/general-concepts/#monetary-values
 */
import getSymbolFromCurrency from "currency-symbol-map";
import { OrderError } from "../../../model/OrderError";
import { log } from "../services/LogService";

export const DEFAULT_CURRENCY = "EUR";

/**
 * A number with 2 decimal digits, followed by a space and the ISO 4217 currency name.
 * Can be preceded by a - sign for negative amounts.
 * ex: 8.90 EUR | -0.05 GBP
 */
export type Money = string;

/**
 * Converts a Money object to a number. It removes the currency
 * and gives the actual value
 * @param money
 * @param inCents
 * @param entityToLog provide here any entity to include in the error logs
 * @returns (number) 0 if not valid and emit an error
 */
export function moneyToNumber(
    money: Money,
    inCents?: boolean,
    entityToLog?: any,
): number {
    let priceValue = moneyToNumberCheck(money, inCents, true, undefined, entityToLog);
    if (!priceValue) {
        priceValue = 0;
    }
    return priceValue;
}

/**
 * Converts a Money object to a number. It removes the currency
 * and gives the actual value
 * @param money
 * @param inCents
 * @param disableLengthCheck
 * @param doNotLogError
 * @param entityToLog provide here any entity to include in the error logs
 * @returns number or null if invalid
 */
export function moneyToNumberCheck(
    money: Money,
    inCents?: boolean,
    disableLengthCheck?: boolean,
    doNotLogError?: boolean,
    entityToLog?: any,
): number | null {
    let priceValue = 0;
    if (money && typeof money === "string" && money !== "0") {
        if (disableLengthCheck || money.length >= 8) {
            const currency = money.slice(money.length - 3);
            // use the currency symbol map to check if the currency code is valid
            const currencySymbol = getSymbolFromCurrency(currency);
            if (!currencySymbol) {
                if (!doNotLogError) {
                    log.error(`Price ${money} seems to have invalid currency`, { entity: entityToLog });
                }
                return null;
            }
            const slicedMoneyNumber = money.slice(0, -4)
            priceValue = parseFloat(slicedMoneyNumber);
            if (isNaN(priceValue)) {
                if (!doNotLogError) {
                    log.error(`Price ${money} seems to be wrongly formatted: invalid money number ${slicedMoneyNumber} (${priceValue}).`, { entity: entityToLog });
                }
                return null;
            }
        } else {
            if (!doNotLogError) {
                log.error(`Price ${money} seems to be wrongly formatted: invalid length`, { entity: entityToLog });
            }
            return null;
        }
    }
    if (!inCents) {
        return priceValue;
    } else {
        return Math.round(priceValue * 100);
    }
}

/**
 * takes a number and return it with two first digits
 * @param number 
 * @returns 
 */
export const numberToStringWithTwoDigits = (number: number): string => {
    return number.toFixed(2);
}
/**
 * takes a string and a currency, check if the string can be transformed in a number
 * and return a number with currency.
 * @param numberString
 * @param currency
 * @returns
 */
export const numberStringToMoney = (
    numberString: string,
    currency: string
): Money | null => {
    const stringToNumber = parseFloat(numberString);
    if (isNaN(stringToNumber)) {
        return null;
    } else {
        return numberToMoney(stringToNumber, currency);
    }
};

/**
 * Converts a number and a currency to a Money type. It adds the
 * currency at the end of the number
 * @param price (number)
 * @param currency (string)
 * @returns (Money)
 */
export function numberToMoney(price: number, currency: string, inCents?: boolean): Money {

    if (typeof price === "string") {
        price = parseFloat(price)
        if (isNaN(price)) {
            price = 0;
        }
    }
    let priceString: string;
    if (inCents) {
        priceString = (price / 100).toFixed(2);
    } else {
        priceString = price.toFixed(2);
    }
    // TODO Check currency
    return `${priceString} ${currency.toUpperCase()}`;// ex: 1.00 EUR
}

/**
 * Get the currency of a money
 * @param money 
 * @returns (string)
 */
export function getCurrency(money: Money): string {
    return money.slice(money.length - 3);
}

export function isCurrencySymbolBeforePrice(currency: string): boolean {
    if (currency) {
        const upperCurrency = currency.toUpperCase();
        // TODO: find a better way 
        return upperCurrency === 'USD' || upperCurrency === 'GBP'
    }
    return false;
}


/**
 * Converts a money to a string with the symbol at the end.
 * For example, 5.00 EUR will become 5.00 €
 * @param money 
 * @returns 
 */
export function MoneyToStringWithSymbol(money: Money): string {

    let currency = getCurrency(money);
    let currencySymbol = getSymbolFromCurrency(currency);

    if (!currencySymbol) {
        log.error(`Cannot extract valid currency from price ${money} (${currency})`);
        return money;
    }

    if (isCurrencySymbolBeforePrice(currency)) {
        if (money.includes('-')) {
            return `- ${currencySymbol} ${money.replace(` ${currency}`, '').replace('-', '')}`
        }
        return `${currencySymbol} ${money.replace(` ${currency}`, '')}`
    }
    return money.replace(currency, currencySymbol);
}

/**
 * Add 2 money objects
 * @param amount1 (money)
 * @param amount2 (money)
 * @returns (money)
 */
export function addMoney(amount1: Money, amount2: Money): Money {

    return addOrSubstract(amount1, amount2, false);
}

/**
 * Substract 2 money objects
 * @param amount1 (money)
 * @param amount2 (money)
 * @returns (money)
 */
export function substractMoney(amount1: Money, amount2: Money): Money {

    return addOrSubstract(amount1, amount2, true);
}

/**
 * Add or substract 2 money objects. used internally by addMoney and substractMoney
 * @param amount1
 * @param amount2
 * @param substract if false: add ; if true: substract
 * @param entityToLog provide here any entity to include in the error logs
 */
function addOrSubstract(
    amount1: Money,
    amount2: Money,
    substract: boolean,
    entityToLog?: any
): Money {

    if (getCurrency(amount1) !== getCurrency(amount2)) {

        throw OrderError.CURRENCIES_DIFFERENT.withValue({
            amount1: amount1,
            amount2: amount2
        });
    }

    if (substract) {

        return numberToMoney(
            moneyToNumber(amount1, false, entityToLog) - moneyToNumber(amount2, false, entityToLog),
            getCurrency(amount1)
        );
    }
    else {
        return numberToMoney(
            moneyToNumber(amount1, false, entityToLog) + moneyToNumber(amount2, false, entityToLog),
            getCurrency(amount1)
        );
    }
}

/**
 * Multiply a money by a number
 * @param amount (money)
 * @param multiplier (number)
 * @param entityToLog provide here any entity to include in the error logs
 * @returns (money)`
 */
export function multiplyMoney(amount: Money, multiplier: number, entityToLog?: any): Money {

    return numberToMoney(
        moneyToNumber(amount, false, entityToLog) * multiplier,
        getCurrency(amount)
    );
}

/**
 * Divide a money by a number
 * @param amount (money)
 * @param divider (number)
 * @param entityToLog provide here any entity to include in the error logs
 * @returns (money)
 */
export function divideMoney(amount: Money, divider: number, entityToLog?: any): Money {

    return numberToMoney(
        moneyToNumber(amount, false, entityToLog) / divider,
        getCurrency(amount)
    );
}

/**
 * 
 * @param price 
 * @param newCurrency 
 * @param entityToLog provide here any entity to include in the error logs
 * @returns 
 */
export const updateCurrencyOfMoney = (price: Money, newCurrency: string, entityToLog?: any): Money => {
    const numCurrency = moneyToNumber(price, false, entityToLog);
    return numberToMoney(numCurrency, newCurrency);
}

/**
 * Check if string is a valid ISO 4217 currency
 */
export function isValidMoney(str: Money): boolean {
    const regex = /^-?\d+(\.\d{1,2})?\s[A-Z]{3}$/;

    return regex.test(str);
}