import { ArrowBackIosRounded } from '@mui/icons-material'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import log from 'loglevel'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import UserHeaderButton from '../../../authentication/components/UserHeaderButton'
import * as ROUTES from '../../../config/routes'
import { SupportedServiceType } from '../../../my-lemonade-library/model/Location'
import translationService from '../../../my-lemonade-library/src/translations/services/TranslationService'
import { RootState, useTypedSelector } from '../../../redux/root-reducer'
import FlagComponent from '../../../translations/components/FlagComponent'
import HideOnScroll from '../HideOnScroll'
import DeliveryAndCheckoutDelay from './DeliveryAndCheckoutDelay'

interface AlternateToolbarComponentProps { }

const AlternateToolbarComponent: React.FC<AlternateToolbarComponentProps> = (props) => {

    const history = useHistory();
    const theme = useTheme();
    const intl = useIntl();

    const { selectedLocation, selectedCatalog, tableLinkId } = useTypedSelector((state: RootState) => state.locations)
    const { order } = useTypedSelector((state: RootState) => state.order)

    const [title, setTitle] = useState<string>('')

    const isDeliveryOrCollection: boolean = order?.service_type === SupportedServiceType.DELIVERY || order?.service_type === SupportedServiceType.COLLECTION
    const shouldDisplayFlag = Boolean(!selectedLocation?.theme?.extended_theme_options?.hide_flag_button);

    // Change the title of the header if the category is supplied
    useEffect(() => {
        if (selectedLocation) {
            const regex = /categories\/([^/]+)/g
            const match: RegExpExecArray | null = regex.exec(history.location.pathname)

            if (match) {
                const categoryRef = match[1]
                const category = selectedCatalog?.data.categories.find(category => category.ref === categoryRef)
                if (category) {
                    const translationId = translationService.getCategorytNameTranslationKey(category);
                    if (translationId && intl.messages[translationId]) {
                        setTitle(intl.formatMessage({ id: translationId }));
                    }
                    else {
                        log.debug(`Missing translation for category ${category.name}`);
                        setTitle(category.name);
                    }
                }
            } else {
                setTitle(selectedLocation.name);
            }
        }
    }, [selectedLocation, history, history.location.pathname, selectedCatalog])

    return (
        <HideOnScroll>

            <Box
                p={theme.spacing(1, 2)}
                width={1}
                height='60px'
                zIndex={100}
                position='fixed'
                top={0}
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                bgcolor={theme.palette.background.default}
            >

                <Box
                    display="flex"
                    alignItems="center"
                >

                    <IconButton
                        style={{ paddingLeft: 0 }}
                        onClick={() => {
                            history.goBack()
                        }}
                        data-test='go-back-button'
                        size="large">
                        <ArrowBackIosRounded sx={{ color: theme.palette.secondary.main }} />
                    </IconButton>

                    {shouldDisplayFlag &&
                        <Box
                            visibility="hidden"
                        >
                            <FlagComponent />
                        </Box>
                    }
                </Box>

                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    onClick={() => history.push(ROUTES.getCategoriesFullRoute(tableLinkId))}
                    style={{ cursor: 'pointer' }}
                >
                    <Typography variant={isDeliveryOrCollection ? 'h5' : 'h2'}>
                        {title}
                    </Typography>

                    {isDeliveryOrCollection
                        && order.expected_time
                        && <DeliveryAndCheckoutDelay />
                    }
                </Box>

                <Box
                    right={theme.spacing(2)}
                    display="flex"
                    alignItems="center"
                >
                    {shouldDisplayFlag &&
                        <FlagComponent />
                    }

                    <UserHeaderButton />
                </Box>

            </Box>
        </HideOnScroll >
    );
}

export default AlternateToolbarComponent