import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Dialog, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import _ from 'lodash';
import log from 'loglevel';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import OrderContributor from "../../my-lemonade-library/src/orders/models/OrderContributor";
import orderActions from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

interface AlcoholDisclaimerModalProps {
    open: boolean
    onClose: () => void,
    onAccept: () => void
}

const AlcoholDisclaimerModal = (props: AlcoholDisclaimerModalProps) => {

    const { open, onClose, onAccept } = props

    const theme = useTheme()
    const intl = useIntl()
    const dispatch = useDispatch()

    const { user } = useTypedSelector((state: RootState) => state.authentication.data.user_authentication_state)
    const { order } = useTypedSelector((state: RootState) => state.order)

    const handleAccept = () => {

        if (!user?.uid || !order.contributors) {
            log.error(`Missing user iD or contributors, cannot accept alcohol disclaimer`);
            return;
        }

        const contributors = _.cloneDeep(order.contributors);
        const contributor = contributors[user.uid] as OrderContributor | undefined;
        if (contributor) {
            contributor.has_accepted_alcohol = true;
            dispatch(orderActions.updateContributors(user.uid, contributors));
        }
        else {
            dispatch(orderActions.addContributor(user.uid, user, undefined, undefined, undefined, { has_accepted_alcohol: true }));
        }

        onAccept();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle>
                <IconButton
                    data-test="close-alcohol-disclaimer-modal"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    <CloseRoundedIcon
                        fontSize='large'
                        sx={{ color: theme.palette.text.disabled }}
                    />
                </IconButton>
            </DialogTitle>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                p={theme.spacing(2, 2, 4)}
            >
                <BlockRoundedIcon
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: '70px'
                    }}
                />

                <Typography variant='h2' textAlign='center'>
                    {intl.formatMessage({ id: 'alcohol_disclaimer' })}
                </Typography>

                <Typography variant='body1' textAlign='center' sx={{ color: theme.palette.text.disabled }} >
                    {intl.formatMessage({ id: 'alcohol_disclaimer.description' })}
                </Typography>

                <Button
                    data-test="accept-alcohol-disclaimer"
                    variant='contained'
                    sx={{
                        width: '80%',
                        marginTop: theme.spacing(2)
                    }}
                    onClick={handleAccept}
                >
                    {intl.formatMessage({ id: 'alcohol_disclaimer.button' })}
                </Button>
            </Box>
        </Dialog>
    )
}

export default AlcoholDisclaimerModal