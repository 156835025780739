import "firebase/firestore";
import { Polygon } from "geojson";
import _ from "lodash";
import { Task } from "redux-saga";
import { all, call, cancel, put, select, spawn, takeEvery, takeLatest } from "redux-saga/effects";
import { AuthenticationState } from "../../authentication/models/AuthenticationState";
import { SignInAuthenticated } from "../../authentication/models/SignInAuthenticated";
import { AuthenticationActions } from "../../authentication/redux/AuthenticationActions";
import { authenticateWithToken, authorizeCode, getIdToken, getSigninUrl } from "../../authentication/services/AuthenticationService";
import { CommonActions } from "../../Common/redux/CommonActions";
import log, { getMylemonadeContext, setLogLevel } from "../../Common/services/LogService";
import firebase, { auth, db, rsf, setAnalyticsFirebase } from "../../config/firebase";
import { getApiEndpoint, isDevEnv } from "../../config/variables";
import { Catalog } from "../../my-lemonade-library/model/Catalog";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { CustomerInfoFlowChoice, Location, Table, TableLink } from "../../my-lemonade-library/model/Location";
import { OrderInBase } from "../../my-lemonade-library/model/Order";
import { ACCOUNT_PARAM } from "../../my-lemonade-library/src/accounts/configs/AccountsApiRoutes";
import { AUTHENTICATE_JWT_QUERY_PARAM_NAME, AUTHENTICATE_STATE_QUERY_PARAM_NAME, AUTHENTICATE_USER_REF_QUERY_PARAM_NAME } from "../../my-lemonade-library/src/authentications/configs/AuthenticationApiRoutes";
import AuthenticateResponse from "../../my-lemonade-library/src/authentications/models/AuthenticateResponse";
import AuthorizeCodeType from "../../my-lemonade-library/src/authentications/models/AuthorizeCodeType";
import AuthorizeResponse from "../../my-lemonade-library/src/authentications/models/AuthorizeResponse";
import { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import SigninResponse from "../../my-lemonade-library/src/authentications/models/SigninResponse";
import catalogsApiRoutes, { CATALOG_ID_PARAM, DEFAULT_TO_FIRST_CATALOG_KEY, IGNORE_AUTH_IF_FIREBASE_USER_KEY } from "../../my-lemonade-library/src/catalogs/configs/CatalogApiRoutes";
import { catalogHelper, checkCatalog, getCatalogFirestoreDocPath, processCatalogExtendedData } from "../../my-lemonade-library/src/catalogs/services/CatalogService";
import { ERROR_QUERY_PARAM_NAME } from "../../my-lemonade-library/src/common/configs/CommonApiRoutes";
import { updateServiceTypePricesInCatalog } from "../../my-lemonade-library/src/deals/services/DealHelper";
import DeliveryZone from "../../my-lemonade-library/src/delivery/models/DeliveryZone";
import { LOCATION_PARAM } from "../../my-lemonade-library/src/locations/configs/LocationsApiRoutes";
import { getAuthenticationCustomerInfoFromServiceTypes, mergeLocationLinksAndTableLinks } from "../../my-lemonade-library/src/locations/services/LocationService";
import { PAYMENT_ID_PARAM_KEY } from "../../my-lemonade-library/src/payments/configs/PaymentsApiRoutes";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import sessionsApiRoutes, { DO_NOT_INCREASE_KEY } from "../../my-lemonade-library/src/sessions/configs/SessionsApiRoutes";
import { GetSessionResponse } from "../../my-lemonade-library/src/sessions/models/GetSessionResponse";
import { SESSION_ID_HEADER } from "../../my-lemonade-library/src/sessions/services/SessionService";
import { getTableFirestoreDocPath, getTableServiceTypes, mergeTableServiceTypes } from "../../my-lemonade-library/src/tables/services/TableService";
import { LocationPaletteOptions } from "../../my-lemonade-library/src/theme/models/LocationPaletteOptions";
import orderAction, { LATEST_ORDERS_DEFAULT_LIMIT, LOAD_ORDERS, LoadOrdersAction, orderActions } from "../../orders/redux/OrderActions";
import { loadOrders } from "../../orders/redux/OrderSagas";
import { RootState } from "../../redux/root-reducer";
import { SESSION_ID_QUERY_PARAM_NAME } from "../../sessions/configs/SessionsRouterConfig";
import TableActions, { APPLY_SELECT_SERVICE_TYPE, ApplySelectServiceTypeAction } from "../../tables/redux/TableActions";
import { applySelectServiceType } from "../../tables/redux/TableSaga";
import translationsActions from "../../translations/redux/actions";
import { testThemePaletteValueHexadecimal } from "../helpers/LocationHelpers";
import actions, { LOAD_LOCATION, LoadLocationAction, locationActions, RELOAD_CATALOG, RELOAD_CATALOG_WHEN_CHANGES, SET_AUTHENTICATION_LOCATION_PROCESS, SetAuthenticationLocationProcessAction, SETUP_SYNC_CATALOG, SetupSyncCatalogAction, UPDATE_CATALOG_DEALS, UpdateCatalogDealsAction } from "./LocationActions";

export default function* rootSaga() {
    yield all([
        takeEvery(LOAD_LOCATION, loadLocation),
        takeLatest(SET_AUTHENTICATION_LOCATION_PROCESS, setAuthenticationProcess),
        takeLatest(RELOAD_CATALOG, reloadCatalog),
        takeLatest(RELOAD_CATALOG_WHEN_CHANGES, reloadCatalogWhenChanges),
        takeLatest(SETUP_SYNC_CATALOG, setupSyncCatalog),
        takeLatest(UPDATE_CATALOG_DEALS, updateCatalogDealsAfterLoadingUserInfo),
    ]);
}

export const getCurrentUserToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                unsubscribe();
                const tokenResult = await getIdToken();
                resolve(tokenResult ? tokenResult.token : "");
            }
        }, reject);
    });
};

export const getCurrentUser = (): Promise<firebase.User | null> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                unsubscribe();
                resolve(userAuth);
            } else {
                resolve(null);
            }
        }, reject);
    });
};

let syncCatalogTask: Task;

function* setupSyncCatalog(action: SetupSyncCatalogAction) {
    const { payload: { id: catalog_id, location_id, account_id } } = action;

    if (!(location_id && account_id && catalog_id)) {
        return
    }

    if (syncCatalogTask) {
        log.info("Cancelling previous customer listening task");
        yield cancel(syncCatalogTask);
    }

    const syncSuccessAction: any = {
        successActionCreator: () => {
            return actions.reloadCatalogWhenChanges();
        }
    }

    const document_path = getCatalogFirestoreDocPath(account_id, location_id, catalog_id);
    const catalog_ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = db.doc(document_path);

    syncCatalogTask = yield spawn(rsf.firestore.syncDocument, catalog_ref, syncSuccessAction);
}

function* reloadCatalogWhenChanges() {
    try {
        const { selectedLocation, tableLinkId }: { selectedLocation: Location, tableLinkId: string, selectedTable: Table } = yield select((state: RootState) => state.locations);
        const tablelink: TableLink = yield call(getTableLinkDoc, tableLinkId);
        const { catalogExt }: { catalogExt: CatalogExtended } = yield call(getCatalogDoc, selectedLocation, tablelink);

        yield put(actions.reloadCatalogSuccess(catalogExt));

    } catch (error) {
        log.error("Error while reloading catalog after changes")
        yield put(actions.loadError((error as Error).message));
    }
}

function* reloadCatalog() {
    try {
        const { selectedLocation, tableLinkId, selectedTable }: { selectedLocation: Location, tableLinkId: string, selectedTable: Table } = yield select((state: RootState) => state.locations);
        const { data } = (yield select((state: RootState) => state.authentication)) as AuthenticationState
        const tablelink: TableLink = yield call(getTableLinkDoc, tableLinkId);
        const { catalogExt }: { catalogExt: CatalogExtended } = yield call(getCatalogDoc, selectedLocation, tablelink);

        yield put(translationsActions.loadTranslations(catalogExt));

        updateServiceTypePricesInCatalog(
            catalogExt,
            selectedTable.service_type || null,
            data.user_authentication_state.provider ? data.user_authentication_state.provider as SignInProviders : null,
            data.user_authentication_state.user,
            selectedTable.area_ref
        )
        yield put(actions.reloadCatalogSuccess(catalogExt))

    } catch (error) {
        log.error("Error while reloading catalog")
        yield put(actions.loadError((error as Error).message));
    }
}
function* getTableLinkDoc(tableLinkId: string) {
    //TODO: call librairy path
    const tableLinkSnapshot: firebase.firestore.DocumentSnapshot = yield call(rsf.firestore.getDocument, `tablelinks/${tableLinkId}`);

    if (!tableLinkSnapshot.exists) {
        throw new Error(`Table link ${tableLinkId} does not exist`);
    } else {
        log.debug(`Found table link ${tableLinkId}`);
    }
    let tablelink = tableLinkSnapshot.data() as TableLink;
    return tablelink;
}

function* getLocationDoc(accountId: string, locationId: string) {
    //TODO: call librairy path
    const locationSnapshot: firebase.firestore.DocumentSnapshot = yield call(rsf.firestore.getDocument, `accounts/${accountId}/locations/${locationId}`);
    if (!locationSnapshot.exists) {
        throw new Error(`Location ${locationId} does not exist for account ${accountId}`);
    } else {
        log.debug(`Found location ${locationId} for account ${accountId}`);
    }
    let location = locationSnapshot.data() as Location;
    location.id = locationId;
    location.account_id = accountId;
    // TODO: a bit weird to use this property to compare language
    if (location.country) {
        location.country = location.country.toLowerCase();
    }
    // No opening restrictions
    if (!location.restriction) {
        log.warn(`No opening restrictions defined for location ${location.id} (account: ${location.account_id})`);
    }

    return location;
}

const fetchCatalog = async (accountId: string, locationId: string, catalogId?: string): Promise<Catalog> => {

    // This query param is used to ignore the authentication into the API if the user is already authenticated with Firebase
    let routeUrl = `${getApiEndpoint()}${catalogsApiRoutes.CATALOG_UPDATE_ROUTE}?${IGNORE_AUTH_IF_FIREBASE_USER_KEY}=true&${DEFAULT_TO_FIRST_CATALOG_KEY}=true`;
    routeUrl = routeUrl.replace(LOCATION_PARAM, locationId);
    routeUrl = routeUrl.replace(ACCOUNT_PARAM, accountId);
    routeUrl = routeUrl.replace(CATALOG_ID_PARAM, catalogId ?? "");

    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${tokenResult?.token}`);
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(routeUrl, requestOptions);

    const responseBody = await response.json();
    if (!response.ok) {
        throw new Error(`Error while fetching catalog ${catalogId} for location ${locationId} and account ${accountId}: ${responseBody.error}`);
    }
    const catalog = responseBody as Catalog;
    return catalog;

}

function* getCatalogDoc(location: Location, tablelink: TableLink) {

    const { account_id, id: locationId } = location;

    const catalogFetched: Catalog = yield call(fetchCatalog, account_id, locationId, tablelink.catalog_id);

    //TODO: call librairy path
    let catalogs: CatalogMap = {
        [catalogFetched.id!]: catalogFetched
    }; // type init at the end of this file

    if (catalogFetched.language) {
        if (!catalogFetched.supported_languages) {
            catalogFetched.supported_languages = [];
        }
        if (!catalogFetched.supported_languages.includes(catalogFetched.language)) {
            log.warn(`Default language not in supported languages for catalog ${catalogFetched.id} (account_id: ${catalogFetched.account_id}, locationId: ${catalogFetched.location_id})`);
            catalogFetched.supported_languages.push(catalogFetched.language);
        }
    } else {
        log.error(`Language not defined for catalog ${catalogFetched.id} (account_id: ${catalogFetched.account_id}, locationId: ${catalogFetched.location_id})`);
    }

    log.debug(`Found ${Object.keys(catalogs).length} catalogs for location ${locationId} and account ${account_id}`);

    const { catalog, catalogId } = defineCatalog(tablelink, catalogs, location);

    const checkedCatalog = checkCatalog(catalog, location, true);
    if (checkedCatalog.check_result && checkedCatalog.check_result.anomalies && checkedCatalog.check_result.anomalies.length) {
        log.warn(`${checkedCatalog.check_result.anomalies.length} catalog anomalies found for catalog ${catalog.id}, location ${locationId} and account ${account_id}`, checkedCatalog.check_result.anomalies)
    } else {
        log.debug(`No catalog anomalies found for catalog ${catalog.id}, location ${locationId} and account ${account_id}`)
    }

    // Extend the catalog with maps to sort products & deals by categories
    const catalogExt = catalog as CatalogExtended;
    processCatalogExtendedData(catalogExt);

    // Sending "any" because it's a generator function. Be careful and type them right after
    // calling the function.
    return {
        catalogExt,
        catalogId,
    };
}

/**
 * Get the table data from firestore.
 * @param accountId
 * @param locationId
 * @param catalogId
 * @param tableLinkKey
 * @param tableId
 * @param locationSupportedServiceTypes
 * @param catalogSupportedServiceTypes
 * @returns the selectedTable that will be put in the state
 */
function* getTableAndMergeServiceTypes(
    accountId: string,
    locationId: string,
    catalogId: string,
    tableLinkKey: string,
    tableId: string,
    location: Location,
    catalog: Catalog,
) {

    let table: Table | null = null

    const tableSnapshot: firebase.firestore.DocumentSnapshot = yield call(
        rsf.firestore.getDocument,
        getTableFirestoreDocPath(accountId, locationId, tableId),
    );

    if (!tableSnapshot.exists) {
        throw new Error(`Table ${tableId} does not exist for location ${locationId} account ${accountId}`);
    } else {
        log.debug(`Table ${tableId} found for location ${locationId} and account ${accountId}`);
    }

    table = tableSnapshot.data() as Table;
    table.id = tableId;
    table.location_id = locationId;
    table.account_id = accountId
    table.tablelink_id = tableLinkKey;

    // Merge location link with table links
    table.links = mergeLocationLinksAndTableLinks(location.links, table.links)

    ////////////////////
    // Service_type merge process
    ////////////////////
    const supportedServiceTypes = mergeTableServiceTypes(table, location, catalog);
    // If no service type found, set the location to disabled in order to go to the location error page
    if (supportedServiceTypes.length === 0) {
        log.error(`No Supported service types found in configuration (or they are disabled). Location: ${locationId}, Account: ${accountId}, Catalog: ${catalogId}, Table: ${tableId}`);
        yield put(actions.setInvalidServiceType(true));
    }

    // TODO: type it create TableExt
    let selectedTable = {
        ...table,
        catalog_id: catalogId,
    } as Table;

    return selectedTable;
}

function* setAuthenticationProcess(action: SetAuthenticationLocationProcessAction) {
    const { location, serviceTypes } = action.payload;

    const authenticationCustomerInfo = getAuthenticationCustomerInfoFromServiceTypes(location.require_customer_info, serviceTypes);

    let authentication_process: CustomerInfoFlowChoice | null = null;
    let authentication_providers: SignInProviders[] | undefined = undefined;
    let authentication_mandatory: boolean | null = null;
    let email_domains_allowed: string[] | null = null;
    let customer_email_verified: boolean = false;

    if (authenticationCustomerInfo) {
        if (authenticationCustomerInfo.authentication_process) {
            authentication_process = authenticationCustomerInfo.authentication_process;
        }
        if (authenticationCustomerInfo.authentication_mandatory) {
            authentication_mandatory = authenticationCustomerInfo.authentication_mandatory;
        }
        if (authenticationCustomerInfo.authentication_providers) {
            authentication_providers = authenticationCustomerInfo.authentication_providers;
        }
        if (authenticationCustomerInfo.email_domains_allowed) {
            email_domains_allowed = authenticationCustomerInfo.email_domains_allowed;
        }
        if (authenticationCustomerInfo.customer_email_verified) {
            customer_email_verified = authenticationCustomerInfo.customer_email_verified;
        }
    } else {
        log.info(`No require customer info found for location ${location.id} and services ${serviceTypes}`);
    }
    log.info(`Authentication system set with process ${authentication_process} (provider: ${authentication_providers}) and mandatory as ${authentication_mandatory} for service types ${serviceTypes}`);
    yield put(AuthenticationActions.authenticationLocationProcess(authentication_process, authentication_mandatory, authentication_providers, email_domains_allowed, customer_email_verified));
}

function* loadLocation(loadLocationAction: LoadLocationAction) {
    try {
        const { tableLinkKey, webappQuery, signinProvider, themeName, } = loadLocationAction.payload;

        let user: firebase.User | null = yield call(getCurrentUser);
        log.info(`current user`, JSON.stringify(user));

        if (!user) {
            log.info("No current user, authentication Anonymous");
            const anonymousUser: firebase.auth.UserCredential = yield call(rsf.auth.signInAnonymously);
            user = anonymousUser.user;
            if (user) {
                const provider = user.providerId;
                const isVerifiedEmail = user.emailVerified;
                const isAnonymous = user.isAnonymous
                yield put(AuthenticationActions.authenticateUserSuccess({ uid: user.uid }, false, isVerifiedEmail, isAnonymous, provider));
                log.debug("anonym-user", user);
            }
        }

        const token: string = yield call(getCurrentUserToken);
        let fromApp: SignInProviders | null = null;

        if (signinProvider) {
            const error = webappQuery.get(ERROR_QUERY_PARAM_NAME);
            if (Object.values(SignInProviders).includes(signinProvider)) {
                // We need to redirect only if already authenticated in the app
                // And if not yet authenticated
                // TODO: or if authentication is mandatory ?
                // TODO: check authenticated user id
                const access_token = webappQuery.get(AUTHENTICATE_JWT_QUERY_PARAM_NAME);
                if ((!user || user.isAnonymous) && loadLocationAction.payload.authenticated === SignInAuthenticated.TRUE) {
                    log.debug(`Retrieving ${signinProvider} authorization url because the user is anonymous but supposed to be authenticated`);
                    const signinResponse: SigninResponse = yield call(getSigninUrl, signinProvider, tableLinkKey, themeName);
                    if (signinResponse.authorization_url) {
                        log.debug(`Redirecting to ${signinResponse.authorization_url} for ${signinProvider} signin`);
                        yield put(CommonActions.setRedirectURL(signinResponse.authorization_url));
                    } else {
                        log.error(`Authorization url not retrieved`);
                    }
                } else if (access_token) {
                    log.debug(`Signin with access token for ${signinProvider}`);
                    let customToken: string | null = null;
                    if (signinProvider === SignInProviders.LYF) {
                        const authenticateResponse: AuthenticateResponse = yield call(authenticateWithToken, signinProvider, access_token, tableLinkKey);
                        customToken = authenticateResponse.custom_token;
                    } else if (signinProvider === SignInProviders.GETRESTO) {
                        const state = webappQuery.get(AUTHENTICATE_STATE_QUERY_PARAM_NAME) ?? undefined;
                        const uuid = webappQuery.get(AUTHENTICATE_USER_REF_QUERY_PARAM_NAME) ?? undefined;
                        const authorizeResponse: AuthorizeResponse = yield call(
                            authorizeCode,
                            signinProvider,
                            access_token,
                            AuthorizeCodeType.ACCESS_TOKEN,
                            undefined,
                            state,
                            uuid
                        );
                        customToken = authorizeResponse.custom_token;
                    }
                    if (customToken) {
                        const loggedUser: firebase.auth.UserCredential = yield call(rsf.auth.signInWithCustomToken, customToken);
                        user = loggedUser.user;
                        // We can now consider that we are within the app (ex: lyf)
                        fromApp = signinProvider;
                    } else {
                        log.error(`Custom token not retrieved`);
                    }
                } else if (error) {
                    log.warn(`Signin but error: ${error}`);
                } else {
                    log.debug(`Signin but nothing to do: user ${user?.uid}, anonymous ${user?.isAnonymous}`);
                }
            } else {
                log.error(`Invalid signin provider ${signinProvider}`)
            }
        }

        if (user) {

            // Set the context for remote logs
            getMylemonadeContext().user_id = user.uid;
            if (fromApp) {
                getMylemonadeContext().signin_provider = fromApp;
            }
            yield put(actions.auth());
            log.info(`User Id: ${user.uid}`);

            if (isDevEnv()) {
                log.info(`Retrieved user token: ${token}`);
            } else {
                log.debug(`Retrieved user token: ${token}`);
            }

            /**
             * Get the tableLink
             */
            const tablelink: TableLink = yield call(getTableLinkDoc, tableLinkKey);

            const { account_id, location_id, table_id, catalog_id } = tablelink;
            // Set the context for remote log
            getMylemonadeContext().tablelink_id = tableLinkKey;
            getMylemonadeContext().account_id = account_id;
            getMylemonadeContext().location_id = location_id;
            getMylemonadeContext().table_id = table_id;
            getMylemonadeContext().catalog_id = catalog_id;

            if (tablelink.log_level) {
                log.warn(`Setting log level to ${tablelink.log_level} for tablelink ${tableLinkKey}`);
                setLogLevel(tablelink.log_level);
            }

            // Get and set the session ID
            try {
                // TODO: Check query for param payment-intent-id -> donotIncrease
                const sessionId: string = yield call(
                    getSessionIdApiCall,
                    account_id,
                    location_id,
                    webappQuery.get(SESSION_ID_QUERY_PARAM_NAME),
                    Boolean(webappQuery.get(PAYMENT_ID_PARAM_KEY)),  // Do not increase if payment intent id is in the URL
                );
                yield put(locationActions.setSessionId(sessionId));
                getMylemonadeContext().session_id = sessionId;
            }
            catch (error) {
                log.error(`Error while getting session ID (account ${account_id}, location ${location_id})`, error);
            }

            /**
             * With the tableLink object, get the location associate with
             */
            const location: Location = yield call(getLocationDoc, account_id, location_id);

            // Parsing geometry objects
            location?.delivery?.forEach((elem: DeliveryZone) => {
                elem.geometryObject = JSON.parse(elem.geometry) as Polygon;
            });

            paymentHelper.setupRestaurantTicketMaxAmounts(location.supported_payment_types, location.currency);
            yield put(orderActions.updateCurrency(location.currency));

            if (location.theme?.mui_theme_options?.palette) {
                const palette: LocationPaletteOptions = location.theme.mui_theme_options.palette;

                Object.values(palette).forEach((subPalette: any) => {
                    Object.keys(subPalette).forEach((key) => {
                        const color: string = subPalette[key];
                        if (!testThemePaletteValueHexadecimal(color)) {
                            log.warn(`Invalid color ${color} for ${key} in palette of location ${location_id}`);
                            delete subPalette[key];
                        }
                    });
                });
            }

            let analyticsFirebase = null;
            if (location.analytics && location.analytics.type === "google-analytics") {
                analyticsFirebase = location.analytics.config;
            }
            setAnalyticsFirebase(analyticsFirebase);

            /**
             * With the location, get the catalogs associate to the location
             * check if tableLink got a reference of a catalog, if not, take the first catalog of location
             */
            const { catalogExt, catalogId }: { catalogExt: CatalogExtended, catalogId: string } = yield call(getCatalogDoc, location, tablelink);

            // Load translations of selected catalog
            yield put(translationsActions.loadTranslations(catalogExt));

            /**
             * Get the right table associate to tableLink
             */
            let selectedTable: Table | null = null;
            if (tablelink.table_id) {
                selectedTable = yield call(getTableAndMergeServiceTypes, account_id, location_id, catalogId, tableLinkKey, tablelink.table_id, location, catalogExt);
            } else {
                yield put(TableActions.loadTables(account_id, location_id));
            }

            // Load latest orders
            const loadLatestOrdersAction: LoadOrdersAction = {
                type: LOAD_ORDERS,
                payload: {
                    allowLoadOrder: true,
                    requestParams: webappQuery,
                    table: selectedTable,
                    catalog: catalogExt,
                    tableId: selectedTable?.id ?? "",
                    acountId: account_id,
                    locationId: location_id,
                    catalogId: catalog_id,
                    location: location,
                    userOrdersLimit: LATEST_ORDERS_DEFAULT_LIMIT,
                    tableOrdersLimit: LATEST_ORDERS_DEFAULT_LIMIT,
                }
            }
            const loadOrdersResult: { table: Table; userOrders: OrderInBase[]; tableOrders: OrderInBase[]; } = yield call(loadOrders, loadLatestOrdersAction);
            // Now that we are sure about the table (can by identified by the receipt), we can filter out products using aread
            if (loadOrdersResult?.table?.area_ref) {
                catalogHelper.filterCatalogWithTableArea(catalogExt, loadOrdersResult.table.area_ref);
                processCatalogExtendedData(catalogExt)
            }

            // Retrieve Authentication Location process for this (those) service type(s)
            const setAuthenticationLocationProcessAction: SetAuthenticationLocationProcessAction = {
                type: SET_AUTHENTICATION_LOCATION_PROCESS,
                payload: {
                    location,
                    serviceTypes: getTableServiceTypes(selectedTable),
                }
            }
            yield call(setAuthenticationProcess, setAuthenticationLocationProcessAction);

            // We are migrating these properties from catalog to locaiton. This is why
            // we have to set here in the catalog if they are found in the location, to avoid
            // having too much changes to do in the webapp.
            if (location.theme?.extended_theme_options && !catalogExt.display) {
                catalogExt.display = location.theme.extended_theme_options;
            }

            if (selectedTable && selectedTable.service_type) {

                const applySelectServiceTypeAction: ApplySelectServiceTypeAction = {
                    type: APPLY_SELECT_SERVICE_TYPE,
                    payload: {
                        catalog: catalogExt,
                        serviceType: selectedTable.service_type,
                        tableArea: selectedTable.area_ref,
                    }
                }
                yield call(applySelectServiceType, applySelectServiceTypeAction);
            }

            yield put(actions.loadLocationSuccess(fromApp, token, tableLinkKey, location, catalogExt, selectedTable));
            
            /**
             * Setup the sync of the catalog
             * 
             * !IMPORTANT: It must be done after the location is loaded because it depends on tableLinkKey
             */
            yield call(setupSyncCatalog, { type: SETUP_SYNC_CATALOG, payload: catalogExt });

            yield put(
                orderAction.setInfo(
                    account_id,
                    location_id,
                    catalogId,
                    selectedTable ? selectedTable.id : undefined,
                    catalogExt.currency,
                    // If we don't know yet the service_type, it will be set when the
                    // user will make a choice (in the CustomerInformationModal)
                    selectedTable?.service_type ? selectedTable.service_type : null
                )
            );
            yield put(AuthenticationActions.loadUserInfo());
        }
    } catch (error) {
        log.error(`Error loading table link ${loadLocationAction.payload.tableLinkKey}`, error);
        yield put(actions.loadError((error as Error).message));
    }
}

function* updateCatalogDealsAfterLoadingUserInfo(action: UpdateCatalogDealsAction) {
    const {
        catalog,
        service_type,
        sign_in_provider,
        customer,
        table_area
    } = action.payload

    const newCatalog = _.cloneDeep(catalog)

    updateServiceTypePricesInCatalog(
        newCatalog,
        service_type,
        sign_in_provider,
        customer,
        table_area
    )

    yield put(actions.setCatalog(newCatalog))
}

/**
 * Function use to define wich catalog and wich service_type are selected priority of tablelink data
 * else take firsts given by location & catalogMap
 * @param tablelink
 * @param catalogs
 * @param location
 */
const defineCatalog = (tablelink: TableLink, catalogs: CatalogMap, location: Location) => {
    let catalogId: string;
    let catalog: Catalog;

    // define catalog
    if (tablelink.catalog_id && catalogs[tablelink.catalog_id]) {
        catalog = catalogs[tablelink.catalog_id];
        catalogId = tablelink.catalog_id;
    } else {
        let keys = Object.keys(catalogs);
        let selectedKey = keys[0];
        catalog = catalogs[selectedKey];
        catalogId = selectedKey;
    }

    return { catalog, catalogId };
};

interface CatalogMap {
    [catalogId: string]: Catalog;
}

const getSessionIdApiCall = async (accountId: string, locationId: string, currentSessionId: string | null, doNotIncrease: boolean): Promise<string> => {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
    headers.append("Authorization", `Bearer ${tokenResult?.token}`);

    currentSessionId && headers.append(SESSION_ID_HEADER, currentSessionId);

    // Building the URL with the params & query
    let fetchUrl = getApiEndpoint() + sessionsApiRoutes.SESSION_GET
        .replace(ACCOUNT_PARAM, accountId)
        .replace(LOCATION_PARAM, locationId);

    if (doNotIncrease) {
        fetchUrl += `?${DO_NOT_INCREASE_KEY}=true`;
    }

    const requestOptions: RequestInit = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(fetchUrl, requestOptions);

    if (!response.ok) {
        log.error(response.json());
        throw new Error(`Error while getting session ID (account ${accountId}, location ${locationId})`);
    }

    // Response ok
    const result: GetSessionResponse = await response.json();
    return result.session_id;
}
