import log from "loglevel";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as ROUTES from '../../config/routes';
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { RedirectUrlFrom, RedirectUrlType } from "../models/RedirectUrlModels";
import { CommonActions } from "../redux/CommonActions";

const useRedirectUrl = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { redirect } = useTypedSelector((state: RootState) => state.common);
    const { tableLinkId } = useTypedSelector((state: RootState) => state.locations);

    /**
     * Insert here all the cases where the redirect should be aborted. Don't forget to set a
     * pertinent message so that we can understand in the logs what's going on.
     * @returns 
     */
    const hasToStopRedirect = (): boolean => {

        const tableLinkHome = ROUTES.LocationHome.replace(":tableLinkKey", tableLinkId);
        if (redirect) {

            let message = "";

            if (
                redirect.type === RedirectUrlType.CONFIRM
                && redirect.from === RedirectUrlFrom.ORDER_SAGA
                && (
                    history.location.pathname.includes(tableLinkHome + ROUTES.PaymentLyra)
                    || history.location.pathname.includes(tableLinkHome + ROUTES.ConfirmPayment)
                )
            ) {
                message = "OrderSaga requires success redirect but PaymentLyra page already does it properly";
            }

            if (message) {
                log.info(`Redirection aborted from page ${history.location.pathname} to page ${redirect.url} (by ${redirect.from} with type ${redirect.type}): ${message}`);
                return true;
            }
        }
        return false;
    }

    useEffect(() => {

        if (redirect && history && dispatch) {

            if (hasToStopRedirect()) {
                dispatch(CommonActions.setRedirectURL(null));
                return;
            }

            if (redirect.url.startsWith("http")) {
                window.location.assign(redirect.url);
            }
            else {
                if (redirect.replaceHistory) {
                    history.replace(redirect.url);
                }
                else {
                    history.push(redirect.url);
                }

                dispatch(CommonActions.setRedirectURL(null));
            }
        }

    }, [redirect, history, dispatch]);
}

export default useRedirectUrl;