import log from 'loglevel';
import { useIntl } from 'react-intl';
import { Categorie, Deal } from '../../my-lemonade-library/model/Catalog';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { getCategoryNameTranslationKeyFromRef } from '../../my-lemonade-library/src/catalogs/services/CatalogService';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';

const useCategoriesAndProductsTranslations = () => {
    const intl = useIntl();

    const getCatTranslation = (category: Categorie): string => {
        const translationKey = getCategoryNameTranslationKeyFromRef(category.ref);
        if (!translationKey || !intl.messages[translationKey]) {
            log.debug(`Missing translation for category (key: ${translationKey})`);
            return category.name ?? "";
        }

        return intl.formatMessage({ id: translationKey });
    };

    const getDealOrProductTranslation = (isDeal: boolean, product: ProductExtended, deal: Deal): string => {
        const translationKey: string | undefined = isDeal ? translationService.getDealNameTranslationKey(deal) : translationService.getProductNameTranslationKey(product);
        if (!translationKey || !intl.messages[translationKey]) {
            log.debug(`Missing translation for ${isDeal ? 'deal' : 'product'} (key: ${translationKey})`);
            return deal.name ?? "";
        }

        return intl.formatMessage({ id: translationKey });
    }

    return {
        getCatTranslation,
        getDealOrProductTranslation
    };
};

export default useCategoriesAndProductsTranslations;
