import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FabButtonOrder from '../../Common/components/FabButtonOrder';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import DisplayProducts from '../../Products/component/DisplayProducts';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

export interface ParamTypes {
    tableLinkKey: string
}

function useQueryItem() {
    return new URLSearchParams(useLocation().search).get('item')
}

/**
 * Component in charge to render deal type HappyHour
 * @deprecated page not reached anywhere. To delete
 */
const HappyHourPage = () => {

    const dealRef = useQueryItem()
    const theme = useTheme();

    const { selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations)

    const [isInteractive, setIsInteractive] = useState<boolean>(false)
    const [productsToDisplay, setProductsToDisplay] = useState<ProductExtended[]>([])

    useEffect(() => {
        if (selectedTable.service_type && selectedTable.service_type !== SupportedServiceType.VIEW) {
            setIsInteractive(true)
        }
    }, [selectedTable.service_type])

    useEffect(() => {
        if (selectedCatalog && selectedCatalog.data) {
            const prodsWithDeal: ProductExtended[] = []
            selectedCatalog.data.products.forEach(product => {
                const skusWithDeal = product.skus.filter(sku => sku.deal_ref === dealRef)
                if (skusWithDeal.length > 0) {
                    prodsWithDeal.push({ ...product, skus: skusWithDeal })
                }
            })
            setProductsToDisplay(prodsWithDeal)
        }
    }, [selectedCatalog, dealRef])

    let categoryIcon: Array<string | null> = []

    return (
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
            height="100%"
        >

            <Box
                bgcolor={theme.palette.background.default}
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >

                <Box mt={4}>

                    <DisplayProducts
                        interactif={isInteractive}
                        products={productsToDisplay}
                        categoryIcon={categoryIcon}
                    />

                </Box>

            </Box>

            <Box position="sticky" bottom="0px">
                <FabButtonOrder />
            </Box>
        </Box>
    )
}

export default HappyHourPage
