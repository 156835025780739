import log from "../../Common/services/LogService";
import CustomerInformationModalFormOpenArgs from "../../customers/models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "../../customers/services/BaseFormModalService";

export default class SelectTableFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.SELECT_TABLE);
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: null } | null {

        // Email verification is mandatory and the email is not verified
        if (!args.selectedTable?.id) {

            log.debug(">-C-> No table is set, showing the SELECT_TABLE form");

            return { form: this.form, parameters: null };
        }

        return null;
    }
};