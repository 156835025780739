import React from 'react';
import { DeviceContext } from '../../App';


export const useHeaderHeight = (): string => {

    const { mobile_device } = React.useContext(DeviceContext)

    const HEIGHT_XS = "60px";
    const HEIGHT_SM = "50px";

    if (mobile_device) {
        return HEIGHT_XS;
    }
    else {
        return HEIGHT_SM;
    }
}