import { Box, Button } from "@mui/material";
import { CSSProperties } from "react";
import styled, { withTheme } from "styled-components";
import theme from '../config/theme';

/**
 * List of custom component
 * TODO : Create a folder Styled component and create one file by type Component => Ask Thibaut
 * 
 */

export const OrderItemDiv = styled(Box)`
border: solid;
border-width: 0px 0px 1px 0px;
padding: 10px;
padding-right: 20px;
padding-left: 20px;
border-color: ${theme.palette.grey[100]};
`;

export const Card = styled(Box)`
  background-color: ${theme.palette.common.white};
  border-radius: 4px;
  overflow: hidden;
`;

// FLEX COLUMN DECLINE

export const List = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items:center;
  width: 100%;
`;

export const ListCenter = styled(List)`
    justify-content: center;
`;

//  TEXT COMPONENTS

export const Title = styled.h1`
  margin: 0;
  text-align: justify;
  font-family: 'Lato';
  font-weight: bold;
  font-size: 16px;
`;


export const ItemTitle = styled.h5`
  margin: 0;
  text-align: justify;
  font-family: 'Lato';
  font-weight: bold;
`;

export const Text = styled.p`
  margin: 0;
`;

export const TitleItem = styled(Text)`
  font-weight: 500;
  color: ${theme.palette.text.primary};
  font-size: ${theme.breakpoints.up('sm') ? '14px' : '11px'};
`;

export const Note = styled(Text)`
  color: ${theme.palette.grey[500]};
  font-size: 0.9rem;
`;

// NEW BUTTON COMPONENT
// DEPRECATED

export const PrimaryButtonRounded = withTheme(styled(Button)`
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    border-radius: 30px;
    cursor: pointer;
`);

export const SecondaryButtonRounded = withTheme(styled(Button)`
    background-color: ${props => props.theme.palette.secondary.main};
    color: ${props => props.theme.palette.secondary.contrastText};
    border-radius: 30px;
    cursor: pointer;

    &:disabled {
        background-color: 
        color: white;
    }
`);

//  BUTTON COMONENTS
interface BProps {
    color: React.CSSProperties['color'],
    backgroundColor: React.CSSProperties['color']
}

export const ButtonAction = styled.button <BProps>`
  display: flex;
  color: ${(props) => props.color};
  height: "80%";
  background-color: ${(props) => props.backgroundColor};
  justify-content: center;
  align-items: center;
  border-radius: 29.9px;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.17);
  font-weight: bold;
  padding: ${theme.spacing(1, 2, 1, 2)};
  border: thin;
`;

//  CSSPROPERTIES

export const cursor: CSSProperties = {
    cursor: 'pointer'
}

//  IMAGE CONTAINER COMONENTS
interface IProps {
    image: string | null,
    default?: boolean
}

export const ImageContainer = styled(ListCenter) <IProps>`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-image : ${(props) =>
        props.image && props.default ? `
      url(${props.image});
      ` : props.image && !props.default ? `
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) , url(${props.image})` : theme.palette.background.default
    };
  `;

export const CaroussellContainer = styled(Box)`
  height: 180px;
  `;

//Modal CSS Properties
export const mobilePaperProps: CSSProperties = {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    margin: 0
}

// Small Modal CSS Properties
export const mobilePaperPropsSM: CSSProperties = {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '100%',
    margin: 0
}

// GetResto
export const customerModalGetResto: CSSProperties = {
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    maxHeight: '100%'
}

