import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, CheckboxProps } from "@mui/material";

type RoundedCheckboxProps = {} & CheckboxProps;

const RoundedCheckBox: React.FC<RoundedCheckboxProps> = ({ ...props }) => {

    return (
        <Checkbox
            {...props}
            icon={< RadioButtonUncheckedIcon />}
            checkedIcon={< CheckCircleIcon />}
        />
    );
}

export default RoundedCheckBox;
