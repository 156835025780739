import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

export const SESSION_GET = `${LOCATION_ROUTE}/${MylemonadeScopes.SESSIONS}`;

export const DO_NOT_INCREASE_KEY = "do_not_increase";

const sessionsApiRoutes = {
    SESSION_GET,
}
export default sessionsApiRoutes;