import { Route, Switch } from "react-router-dom";
import AccountOrderHistory from "../../account/components/AccountOrderHistory";
import MyInformations from '../../account/pages/MyInformations';
import LoaderComponent from '../../Common/components/LoaderComponent';
import PageCloseModal from '../../Common/pages/PageCloseModal';
import PageError from '../../Common/pages/PageError';
import * as ROUTES from '../../config/routes';
import { PaymentType } from '../../my-lemonade-library/model/Order';
import LoyaltyPage from '../../orders/pages/LoyaltyPage';
import OrderPreparationPage from '../../orders/pages/OrderPreparationPage';
import OrderSummaryPage from '../../orders/pages/OrderSummaryPage';
import PaymentInProgressPage from "../../orders/pages/PaymentInProgressPage";
import QrCodePage from "../../orders/pages/QrCodePage";
import PaymentPickUpInfo from '../../orders/pages/ServicePickupInfo';
import SharedOrderLanding from "../../orders/pages/SharedOrderLanding";
import CreditWallet from "../../Payment/pages/CreditWallet";
import PaymentAdyen from '../../Payment/pages/PaymentAdyen';
import PaymentChoice from '../../Payment/pages/PaymentChoice';
import PaymentEdenred from '../../Payment/pages/PaymentEdenred';
import PaymentLyf from '../../Payment/pages/PaymentLyf';
import PaymentLyra from '../../Payment/pages/PaymentLyra';
import PaymentStripe from '../../Payment/pages/PaymentStripe';
import PaymentSwile from "../../Payment/pages/PaymentSwile";
import PaymentTable from '../../Payment/pages/PaymentTable';
import PaymentWorldline from "../../Payment/pages/PaymentWorldline";
import SharePaymentPage from "../../Payment/pages/SharePaymentPage";
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { LocationContact } from "../components/LocationContact";


export const routesTab = [
    ROUTES.PaymentChoice,
    ROUTES.PaymentStripe,
    ROUTES.PaymentLyf,
    ROUTES.PaymentEdenred,
    ROUTES.PaymentSwile,
    ROUTES.ErrorRoutePage,
    ROUTES.PaymentTable,
    ROUTES.OrderConfirmationPage,
    ROUTES.OrderConfirmPaymentPage,
    ROUTES.PaymentCollectionInfo,
    ROUTES.LocationContact,
    ROUTES.OrderHistory,
    ROUTES.MyInformations,
    ROUTES.SharedOrderLanding,
    ROUTES.SharePayment,
]

/*
Component contains some route in order to display in modal on desktop version
*/

export default function Navigation() {

    const orderState = useTypedSelector((state: RootState) => state.order);
    const { order, currentOrder: currentOrderState } = orderState;

    if (currentOrderState.error) {

        return (
            <Switch>
                <Route>
                    <PageError titleId={'Order.error'} returnHomeButton />
                </Route>
            </Switch>
        );
    }

    // When the payment type is adyen, the loading is already included into the page 
    // and we have to keep the page opened
    else if (
        currentOrderState.sending
        && order.payment_type !== PaymentType.ADYEN
        && orderState.payment_type !== PaymentType.ADYEN
    ) {
        /**
         * This component can use LoaderComponent, the loader is only based on order.sendingOrder state
         */
        return <LoaderComponent />
    }

    else {
        return (
            <Switch>
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentChoice} component={PaymentChoice} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentStripe} component={PaymentStripe} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentWorldline} component={PaymentWorldline} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentLyf} component={PaymentLyf} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentEdenred} component={PaymentEdenred} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentSwile} component={PaymentSwile} />
                {true && <Route exact path={ROUTES.LocationHome + ROUTES.PaymentAdyen} component={PaymentAdyen} />}
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentLyra} component={PaymentLyra} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentTable} component={PaymentTable} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentCollectionInfo} component={PaymentPickUpInfo} />
                <Route exact path={ROUTES.LocationHome + ROUTES.OrderConfirmationPage}>
                    <OrderPreparationPage />
                </Route>
                <Route exact path={ROUTES.LocationHome + ROUTES.OrderConfirmPaymentPage}>
                    <OrderPreparationPage isPayment={true} />
                </Route>
                <Route exact path={ROUTES.LocationHome + ROUTES.ErrorRoutePage} >
                    <PageError returnHomeButton />
                </Route>
                <Route exact path={ROUTES.LocationHome + ROUTES.LocationContact} component={LocationContact} />
                <Route exact path={ROUTES.LocationHome + ROUTES.OrderSummaryPage}>
                    <OrderSummaryPage />
                </Route>
                <Route exact path={ROUTES.LocationHome + ROUTES.QrCodePage} component={QrCodePage} />
                <Route exact path={ROUTES.LocationHome + ROUTES.SharedOrderLanding} component={SharedOrderLanding} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentInProgress} component={PaymentInProgressPage} />
                <Route exact path={ROUTES.LocationHome + ROUTES.LoyaltyPage} component={LoyaltyPage} />
                <Route exact path={ROUTES.LocationHome + ROUTES.CreditWallet} component={CreditWallet} />
                <Route exact path={ROUTES.LocationHome + ROUTES.OrderHistory} component={AccountOrderHistory} />
                <Route exact path={ROUTES.LocationHome + ROUTES.MyInformations} component={MyInformations} />
                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentShareItems} >
                    <SharePaymentPage />
                </Route>
                <Route render={(props) => (
                    <PageCloseModal />
                )} />
            </Switch>
        )
    }
}
