import { CategoryReferenceLevel0 } from '../../referential/models/CategoryReferenceLevel0';
import { CategoryReferenceLevel1 } from '../../referential/models/CategoryReferenceLevel1';
import { CategoryReferenceLevel2 } from '../../referential/models/CategoryReferenceLevel2';
import { CategoryReferenceLevel3 } from '../../referential/models/CategoryReferenceLevel3';
import CategoryTemplate from '../models/CategoryTemplate';

const defaultCategoryTemplates: CategoryTemplate[] = [
    {
        ref: 'cocktails',
        name: 'Cocktails',
        likely_names_per_language: {
            fr: ['cocktails'],
            en: ['cocktails'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/cocktail.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.SPIRITS,
            category3: CategoryReferenceLevel3.COCKTAILS,
            category4: null,
        },
    },
    {
        ref: 'spirits',
        name: 'Spirits',
        likely_names_per_language: {
            fr: ['spiritueux', 'liqueurs'],
            en: ['spirits', 'liqueurs'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/spirit.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.SPIRITS,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'starters',
        name: 'Starters',
        likely_names_per_language: {
            fr: ['entrées'],
            en: ['starters'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/salade.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'beers',
        name: 'Beers',
        likely_names_per_language: {
            fr: ['bières'],
            en: ['beers'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/beer.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.BEER_CIDERS,
            category3: CategoryReferenceLevel3.BEER,
            category4: null,
        },
    },
    {
        ref: 'wines',
        name: 'Wines',
        likely_names_per_language: {
            fr: ['vins'],
            en: ['wines'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/wine.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'red_wines',
        name: 'Red Wines',
        likely_names_per_language: {
            fr: ['vins rouges'],
            en: ['red wines'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/wine.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.WINE,
            category3: CategoryReferenceLevel3.RED_WINE,
            // category4: CategoryReferenceLevel4.RED_WINE,
            category4: null,
        },
    },
    {
        ref: 'white_wines',
        name: 'White Wines',
        likely_names_per_language: {
            fr: ['vins blancs'],
            en: ['white wines'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/wine%20white.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.WINE,
            category3: CategoryReferenceLevel3.WHITE_WINE,
            // category4: CategoryReferenceLevel4.WHITE_WINE,
            category4: null,
        },
    },
    {
        ref: 'rose_wines',
        name: 'Rose Wines',
        likely_names_per_language: {
            fr: ['vins rosés'],
            en: ['rose wines'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/wine%20rose.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.WINE,
            category3: CategoryReferenceLevel3.ROSE_WINE,
            // category4: CategoryReferenceLevel4.ROSE_WINE,
            category4: null,
        },
    },
    {
        ref: 'champagnes',
        name: 'Champagnes',
        likely_names_per_language: {
            fr: ['champagnes'],
            en: ['champagnes'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/champagne.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.ADULT_BEVERAGES,
            category2: CategoryReferenceLevel2.CHAMPAGNE_SPARKLING_WINE,
            category3: CategoryReferenceLevel3.CHAMPAGNE,
            // category4: CategoryReferenceLevel4.CHAMPAGNE,
            category4: null,
        },
    },
    {
        ref: 'sandwiches',
        name: 'Sandwiches',
        likely_names_per_language: {
            fr: ['sandwich'],
            en: ['sandwiches'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/snacking.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'fromages',
        name: 'Fromages',
        likely_names_per_language: {
            fr: ['fromage'],
            en: ['cheese'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/snacking.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'tapas',
        name: 'Tapas',
        likely_names_per_language: {
            fr: ['tapas'],
            en: ['tapas'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/tapas.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'other_course',
        name: 'Other Course',
        likely_names_per_language: {
            fr: ['plats'],
            en: ['course'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/plat.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'other_fresh_drinks',
        name: 'Other fresh drinks',
        likely_names_per_language: {
            fr: ['boissons froides'],
            en: ['fresh drinks'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/soft.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'soft_drinks',
        name: 'Soft drinks',
        likely_names_per_language: {
            fr: ['soft drinks', 'softs'],
            en: ['soft drinks', 'softs'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/soft.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'carbonated_soft_drinks',
        name: 'Carbonated soft drinks',
        likely_names_per_language: {
            fr: ['soda', 'boissons gazeuses'],
            en: ['carbonated'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/soft.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK,
            category3: CategoryReferenceLevel3.CARBONATED_SOFT_DRINK,
            category4: null,
        },
    },
    {
        ref: 'juice',
        name: 'Juice',
        likely_names_per_language: {
            fr: ['jus'],
            en: ['juice'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/juices.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK,
            category3: CategoryReferenceLevel3.JUICES_NECTARS,
            category4: null,
        },
    },
    {
        ref: 'coffee',
        name: 'Coffees',
        likely_names_per_language: {
            fr: ['café'],
            en: ['coffee'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/coffee.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.COFFEE,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'tea',
        name: 'tea',
        likely_names_per_language: {
            fr: ['thé'],
            en: ['tea'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/coffee.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.TEA,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'hot_drinks',
        name: 'Boissons chaudes',
        likely_names_per_language: {
            fr: ['boissons chaudes'],
            en: ['hot drinks'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/coffee.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'meats',
        name: 'Meats',
        likely_names_per_language: {
            fr: ['viandes'],
            en: ['meats'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/meat.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'fish',
        name: 'Fish',
        likely_names_per_language: {
            fr: ['poisson'],
            en: ['fish'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/fish.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'water',
        name: 'Water',
        likely_names_per_language: {
            fr: ['eau'],
            en: ['water'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/water.svg',
        referential: {
            category0: CategoryReferenceLevel0.BEVERAGES,
            category1: CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES,
            category2: CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'desserts',
        name: 'Desserts',
        likely_names_per_language: {
            fr: ['Desserts'],
            en: ['desserts'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/dessert.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'pizza',
        name: 'Pizza',
        likely_names_per_language: {
            fr: ['pizza'],
            en: ['pizza'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/pizza.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
    {
        ref: 'pasta',
        name: 'Pasta',
        likely_names_per_language: {
            fr: ['pâtes', 'pastas'],
            en: ['pasta'],
        },
        icon: 'https://storage.googleapis.com/my-lemonade-dev.appspot.com/categories/plat.svg',
        referential: {
            category0: CategoryReferenceLevel0.FOOD,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
        },
    },
];
export default defaultCategoryTemplates;
