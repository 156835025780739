import { Box, Checkbox, CheckboxProps, useTheme } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import FormikErrorMessage from './FormikErrorMessage'

type FormikCheckBoxProps = {

    name: string;
    formikSetFieldValue: (field: string, value: any) => void;
    textComponent: JSX.Element;

} & Omit<CheckboxProps, 'value' | 'onChange'>


const FormikCheckBox: React.FC<FormikCheckBoxProps> = (props) => {

    const { name, formikSetFieldValue, textComponent, ...rest } = props;

    const theme = useTheme();

    const [field] = useField<string | undefined>(name)

    return (

        <Box width={1}>

            <Box
                width={1}
                onClick={() => { formikSetFieldValue(name, !field.value) }}
                display="flex"
                alignItems="center"
            >

                <Checkbox
                    name={name}
                    data-test={name}
                    {...rest}
                    checked={Boolean(field.value) ?? false}
                    style={{
                        padding: 0,
                        marginRight: theme.spacing(2)
                    }}
                    color="primary"
                />

                {textComponent}

            </Box>

            <Box marginTop={.6} marginLeft={1}>

                <FormikErrorMessage name={name} />

            </Box>

        </Box>
    )
}

export default FormikCheckBox;