import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import { cursor } from "../../Common/StyledComponents";
import { ORDER_ID_PARAM_KEY } from "../../my-lemonade-library/src/orders/configs/OrdersApiRoutes";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import locationActions from "../redux/LocationActions";

type DialogShareOrderProps =
    {
        desktop: boolean | undefined,
    }

const DialogShareOrder: React.FC<DialogShareOrderProps> = (props) => {

    const { desktop } = props;

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { tableLinkId, openModalMultiUsersOrder } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state: RootState) => state.order);

    const [copiedMessage, setCopiedMessage] = useState<boolean>(false);

    return (
        <Dialog
            open={openModalMultiUsersOrder}
            onClose={() => dispatch(locationActions.setOpenModalMultiUsersOrder(false))}
            fullScreen={false}
            PaperProps={{
                style: {
                    padding: theme.spacing(3.5, 0, 2.8, 0),
                }
            }}
        >

            <DialogTitle
                style={{
                    fontWeight: "bold",
                    flexGrow: 1
                }}
            >

                <IconButton
                    size={desktop ? 'small' : 'medium'}
                    style={{
                        ...cursor,
                        position: 'absolute',
                        color: theme.palette.text.primary,
                        top: 0,
                        right: 0,
                    }}
                    onClick={() => dispatch(locationActions.setOpenModalMultiUsersOrder(false))}
                >
                    <Close />

                </IconButton>

                <Typography
                    variant="h2"
                    color="textSecondary"
                    style={{
                        flexGrow: 1,
                        textAlign: "center",
                    }}
                >

                    {intl.formatMessage({ id: "orders.multi_users.title" })}

                </Typography>

            </DialogTitle>

            <DialogContent style={{ padding: 0 }}>

                <Box px={3} py={2} textAlign="center">

                    <Typography variant="body1">
                        {intl.formatMessage({ id: "orders.multi_users.hint" })}
                    </Typography>

                </Box>

            </DialogContent>

            <DialogActions
                disableSpacing
                style={{
                    padding: theme.spacing(2),
                    backgroundColor: 'transparent',
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >

                {copiedMessage &&

                    <Typography
                        color="primary"
                        variant="body1"
                        style={{
                            textAlign: "center",
                            marginBottom: theme.spacing(1)
                        }}
                    >

                        {intl.formatMessage({ id: "orders.preparation.copied" })}

                    </Typography>

                }

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {

                        const linkToCopy: string = `${window.location.origin}/${tableLinkId}?${ORDER_ID_PARAM_KEY}=${order.id}`;

                        navigator.clipboard.writeText(linkToCopy)
                            .then(() => {

                                setCopiedMessage(true);
                                setTimeout(() => { setCopiedMessage(false) }, 5000);

                            }, (err) => {

                                console.error('Async: Could not copy text: ', err);
                            });
                    }}
                    style={{
                        padding: theme.spacing(1, 5),
                        textTransform: "none",
                    }}
                >
                    <Typography>
                        {intl.formatMessage({ id: "share_link" })}
                    </Typography>

                </Button>

            </DialogActions>

        </Dialog>
    );
}

export default DialogShareOrder;