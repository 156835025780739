import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import { UserAuthenticationState } from "../../authentication/models/AuthenticationState";
import { OrderInfosRequire } from "../../my-lemonade-library/model/Location";
import { Order } from "../../my-lemonade-library/model/Order";
import { fixPhone } from "../../my-lemonade-library/src/common/services/AddressService";

/**
 * // TODO: turn it into a hook: risk to put different arguments at different moments is high! Ex:
 * table or order service type?
 * Return an array containing all the fields that can be asked to the customer in
 * servicePickupInfo.
 * @param order 
 * @param requireInfoLocationConfig 
 * @param ignoreInOrder if false or undefined, we'll check in the given order if the fields are filled.
 * If true, even if the fields are filled in the order, we'll ask them anyway.
 * @param userAuthenticationState give this parameter if "ignoreInOrder" is true: we can then check if the user is logged in to
 * ask or not for their email
 * @breaks 
 */
export const getCustomerInfoFieldsToAsk = (
    order: Order,
    requireInfoLocationConfig: OrderInfosRequire | null,
    ignoreInOrder: boolean = false,
    userAuthenticationState: UserAuthenticationState | undefined = undefined,
) => {

    // TODO: not string...
    const requiredInfo: string[] = [];

    if (requireInfoLocationConfig) {

        Object.keys(requireInfoLocationConfig).forEach((key: string) => {

            if (requireInfoLocationConfig[key as keyof OrderInfosRequire]) {

                switch (key as keyof OrderInfosRequire) {

                    case "customer_notes":
                        break;

                    case "email":
                        if (ignoreInOrder) {
                            // We check this because if the user is logged in, the email is not editable
                            if (!userAuthenticationState || !isUserLoggedIn(userAuthenticationState)) {
                                requiredInfo.push(key);
                            }
                        }
                        else {
                            if (!order.customer || !order.customer.email) {
                                requiredInfo.push(key);
                            }
                        }
                        break;

                    case "expected_time":
                        if (!order.expected_time) {
                            requiredInfo.push(key)
                        }
                        break;

                    case "company_name":
                        if (ignoreInOrder) {
                            requiredInfo.push(key);
                        }
                        else {
                            if (!order.customer || !order.customer.company_name) {
                                requiredInfo.push(key);
                            }
                        }
                        break;

                    case "first_name":
                        if (ignoreInOrder) {
                            requiredInfo.push(key);
                        }
                        else {
                            if (!order.customer || !order.customer.first_name) {
                                requiredInfo.push(key);
                            }
                        }
                        break;

                    case "phone":
                        if (ignoreInOrder) {
                            requiredInfo.push(key)
                        }
                        else {
                            if (!order.customer || !order.customer.phone || !fixPhone({ phone: order.customer.phone })) {
                                requiredInfo.push(key);
                            }
                        }
                        break;

                    default:
                        break;
                }
            }
        });
    }

    return requiredInfo;
}