
interface ReceipViewerProps {
    receiptUrl: string
}

const ReceiptViewer: React.FC<ReceipViewerProps> = (props) => {

    return (

        <iframe title="receipt" src={props.receiptUrl}
            height="600"
            width={"100%"}
            frameBorder="0"
            style={{
                WebkitTransform: "scale(0.75)"
            }} />

    )
}

export default ReceiptViewer