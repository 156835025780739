import { createTheme, CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import React, { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import './App.css';
import HelmetComponent from './Common/components/HelmetComponent';
import IntlGlobalProvider from './Common/components/IntlGlobalProvider';
import Page404 from './Common/pages/PageError';
import log from './Common/services/LogService';
import * as ROUTES from './config/routes';
import myLemonadeDefaultTheme, { createLocationTheme, getRestoThemeOptions, lyfInAppThemeOptions } from './config/theme';
import AppLocale from './config/translation';
import useDeviceContext, { DEFAULT_DEVICE_CONTEXT, DeviceContextModel } from './config/useDeviceContext';
import LocationHome from './Locations/pages/LocationHome';
import { ThemeList } from './my-lemonade-library/src/theme/models/ThemesList';
import appActions from './redux/app/AppActions';
import { RootState, useTypedSelector } from './redux/root-reducer';
import actions from './translations/redux/actions';
(window as any)['global'] = window; global.Buffer = global.Buffer || require('buffer').Buffer;

export const DeviceContext = React.createContext<DeviceContextModel>(DEFAULT_DEVICE_CONTEXT);

export default function App() {

    const dispatch = useDispatch()

    const { currentLang, catalogTranslations, supportedLanguages, isLoaded } = useTypedSelector((state) => state.lang)
    const { selectedLocation, selectedCatalog } = useTypedSelector((state) => state.locations);
    const { themeName } = useTypedSelector((state: RootState) => state.theme);
    const [translations, setTranslations] = useState<{ [key: string]: string }>(AppLocale['fr'].messages);
    const [currentTheme, setCurrentTheme] = useState<Theme>(myLemonadeDefaultTheme)
    const deviceContext = useDeviceContext(currentTheme)
    let locale: string = navigator.language

    useEffect(() => {

        if (isLoaded && selectedLocation && selectedLocation.hotjar && selectedLocation.hotjar.hjid) {
            log.info(`Setting hotjar with id: ${selectedLocation.hotjar.hjid}`)
            hotjar.initialize(selectedLocation.hotjar.hjid, 6);
        }

    }, [dispatch, isLoaded, selectedLocation])

    useEffect(() => {
        if (themeName && themeName === ThemeList.GET_RESTO) {
            setCurrentTheme(createTheme(getRestoThemeOptions))
        }
        else if (themeName && themeName === ThemeList.LYF) {
            setCurrentTheme(createTheme(lyfInAppThemeOptions))
        }
        else if (isLoaded && selectedLocation) {
            setCurrentTheme(createLocationTheme(selectedLocation.theme))
        }
    }, [dispatch, isLoaded, selectedLocation])

    /**
     * When loading the web , set the theme and fetch the deployment info
     */
    useEffect(() => {
        dispatch(appActions.fetchDeployment())
    }, [])

    /**
     * Define selected locale using navigator locale and supported languages
     */
    useEffect(() => {
        if (isLoaded) {
            // If not found
            if (!supportedLanguages || !supportedLanguages.includes(locale)) {
                // Fall back to first part
                locale = locale.split('-')[0];
                if (!supportedLanguages || !supportedLanguages.includes(locale)) {
                    if (selectedCatalog && selectedCatalog.language) {
                        locale = selectedCatalog.language
                        log.info(`Fallback to catalog language: ${locale}`);
                    } else if (selectedLocation && selectedLocation.country) {
                        locale = selectedLocation.country;
                        log.error(`Fallback to location country: ${locale}`);
                    } else {
                        locale = "en"
                        if (selectedCatalog || selectedLocation) {
                            log.error(`Neither catalog language, nor location country defined, fallback to: ${locale}`);
                        }
                    }
                } else {
                    log.info(`Display supported language: ${locale}`);
                }
            } else {
                log.info(`Display supported language: ${locale}`);
            }
            dispatch(actions.changeLanguage(locale))
        }
    }, [dispatch, locale, selectedLocation, selectedCatalog, isLoaded])


    useEffect(() => {
        let currentTranslations: { [key: string]: string } = {};
        // Get app translations
        if (currentLang && AppLocale[currentLang] && AppLocale[currentLang].messages) {
            currentTranslations = AppLocale[currentLang].messages;
        }
        // Join it with catalog translations
        if (catalogTranslations) {
            log.debug("Merging app & catalog translations")
            currentTranslations = concatTradJson(currentTranslations, catalogTranslations, currentLang)
        }
        setTranslations(currentTranslations);

        // Complete the loading only now in order to avoid translation strings not found ?
        if (catalogTranslations && Object.keys(catalogTranslations).length > 0) {
            dispatch(actions.loadTranslationsComplete())
        }
    }, [dispatch, currentLang, catalogTranslations])



    return (
        <StyledEngineProvider injectFirst>
            <StylesProvider>
                <ThemeProvider theme={currentTheme}>
                    <DeviceContext.Provider value={deviceContext}>
                        <CssBaseline />
                        <IntlProvider
                            locale={currentLang}
                            messages={translations}>
                            <IntlGlobalProvider>
                                <HelmetComponent />
                                <Router>
                                    <Switch>
                                        <Route path={ROUTES.Page404} component={Page404} />
                                        <Route path={ROUTES.LocationHome} component={LocationHome} />
                                        <Route component={Page404} />
                                    </Switch>
                                </Router>
                            </IntlGlobalProvider>
                        </IntlProvider>
                    </DeviceContext.Provider>
                </ThemeProvider>
            </StylesProvider>
        </StyledEngineProvider>
    );
}

/**
 * Concatenate app translations with catalog translations
 * @param appTranslations 
 * @param catalogTranslations 
 * @param locale 
 */
function concatTradJson(appTranslations: { [key: string]: string }, catalogTranslations: { [key: string]: { [key: string]: string } }, locale: string) {

    const catalogTranslationsLocale: any = catalogTranslations[locale]
    const translations: any = { ...appTranslations, ...catalogTranslationsLocale }
    return (translations)
}
