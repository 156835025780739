
import HttpError from '../../common/models/HttpError';
import MylemonadeScopes from '../../common/models/MyLemonadeScopes';

export default class DeliveryErrors {
    static DELIVERY_PROVIDER_TYPE_NOT_SUPPORTED: HttpError = new HttpError("DELIVERY_PROVIDER_TYPE_NOT_SUPPORTED", "The delivery provider type is not supported", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_PROVIDER_TYPE_NOT_CONFIGURED: HttpError = new HttpError("DELIVERY_PROVIDER_TYPE_NOT_CONFIGURED", "The delivery provider type is not configured in location", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_CANNOT_SAVE_SECRET: HttpError = new HttpError("DELIVERY_CANNOT_SAVE_SECRET", "Error saving delivery credentials", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_PROVIDER_REF_NOT_FOUND: HttpError = new HttpError("DELIVERY_PROVIDER_REF_NOT_FOUND", "Delivery provider ref not found", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_SECRET_NOT_ALLOWED_FOR_SHARED_ACCOUNT: HttpError = new HttpError("DELIVERY_SECRET_NOT_ALLOWED_FOR_SHARED_ACCOUNT", "Not allowed to access shared delivery secret", 401, MylemonadeScopes.DELIVERY);
    static INCOMPLETE_LOCATION_ADDRESS: HttpError = new HttpError("INCOMPLETE_LOCATION_ADDRESS", "Incomplete location address", 500, MylemonadeScopes.DELIVERY);
    static INCOMPLETE_CUSTOMER_ADDRESS: HttpError = new HttpError("INCOMPLETE_CUSTOMER_ADDRESS", "Incomplete customer address", 500, MylemonadeScopes.DELIVERY);
    static INVALID_EXPECTED_TIME: HttpError = new HttpError('INVALID_EXPECTED_TIME', "Invalid expected time", 500, MylemonadeScopes.DELIVERY);
    static DELIVERY_ORDER_WITHOUT_ZONE: HttpError = new HttpError("DELIVERY_ORDER_WITHOUT_ZONE", "The order does not have a delivery zone property", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_ORDER_MISSING_CHARGE: HttpError = new HttpError("DELIVERY_ORDER_MISSING_CHARGE", "The order does not have a delivery charge", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_ADDRESS_NOT_AVAILABLE: HttpError = new HttpError("DELIVERY_ADDRESS_NOT_AVAILABLE", "The order address is not available to delivery", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_AVAILABLE_MISSING_ZONE: HttpError = new HttpError("DELIVERY_AVAILABLE_MISSING_ZONE", "Missing zone ref to be able to check availability", 400, MylemonadeScopes.DELIVERY);
    static DELIVERY_ERROR_CREATING_JOB: HttpError = new HttpError('DELIVERY_ERROR_CREATING_JOB', "Error while creating delivery Job", 500, MylemonadeScopes.DELIVERY);
    static DELIVERY_ADDRESS_MISMATCH: HttpError = new HttpError('DELIVERY_ADDRESS_MISMATCH', 'Address mismatch between the one sent and the one forced on the table', 400, MylemonadeScopes.DELIVERY);
    static COULD_NOT_REGISTER_PROVIDER: HttpError = new HttpError('COULD_NOT_REGISTER_PROVIDER', 'Could not register the provider', 400, MylemonadeScopes.DELIVERY);
    static COULD_NOT_SEND_REGISTER_INVITATION: HttpError = new HttpError('COULD_NOT_SEND_REGISTER_INVITATION', 'Could not send the invitation to access the registered account in the provider', 400, MylemonadeScopes.DELIVERY);
}
