import { PaymentType } from "../../../model/Order";
import { SpecificPaymentType } from "../models/PaymentTypeExtended";

/**
 * @key country: ISO Country code (capital letters)
 * @value max amount allowed, without currency. Ex: {"FR": 25} for 25.00 EUR
 * @note Letting the Infinity values in comments as hints, but they are not needed in the code
 */
export const MAX_AMOUNT_ALLOWED_RESTAURANT_TICKET: { [country: string]: number } = {
    "FR": 25,
    // "BE": Infinity,
    // "LU" : Infinity,
}

export const RESTAURANT_TICKET_PAYMENT_TYPES: (PaymentType | SpecificPaymentType)[] = [
    PaymentType.EDENRED,
    PaymentType.SWILE,
    SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS,
];
