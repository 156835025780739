import { useEffect, useState } from 'react';
import { Location, SupportedServiceType, Table } from '../../my-lemonade-library/model/Location';
import { OrderInBase } from '../../my-lemonade-library/model/Order';
import { RESTAURANT_TICKET_PAYMENT_TYPES } from '../../my-lemonade-library/src/payments/configs/RestaurantTicketPaymentConfig';
import { PaymentTypeExtended } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { Tips } from '../../my-lemonade-library/src/tips/models/Tips';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

interface TipConfiguration {
    askForTip: boolean;
    tip: Tips | undefined;
}

export const getTipConfiguration = (
    location: Pick<Location, "orders"> | undefined,
    table: Pick<Table, "service_type">,
    order: Pick<OrderInBase, "service_type">,
    paymentType: PaymentTypeExtended | undefined,
): TipConfiguration => {

    if (location?.orders?.tips) {

        const tips = location.orders.tips;
        let serviceTypeSelected = order.service_type;

        // Take service type from table if checkout
        if (serviceTypeSelected === SupportedServiceType.EAT_IN && table.service_type === SupportedServiceType.CHECKOUT) {
            serviceTypeSelected = SupportedServiceType.CHECKOUT;
        }

        if (
            !tips.disabled
            && tips.services_types.includes(serviceTypeSelected)
            && (
                !paymentType
                || !RESTAURANT_TICKET_PAYMENT_TYPES.includes(paymentType)
            )
        ) {
            return {
                askForTip: true,
                tip: tips,
            }
        }
        else {
            return {
                askForTip: false,
                tip: undefined,
            }
        }
    }

    return {
        askForTip: false,
        tip: undefined,
    }
}

const useTipConfiguration = (paymentType: PaymentTypeExtended | undefined): TipConfiguration => {

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const [askForTip, setAskForTip] = useState<boolean>(false);
    const [tip, setTip] = useState<Tips>();

    useEffect(() => {

        const config = getTipConfiguration(selectedLocation, selectedTable, order, paymentType);
        setAskForTip(config.askForTip);
        setTip(config.tip);

    }, [selectedLocation, order, selectedTable, paymentType]);

    return { askForTip, tip };
}

export default useTipConfiguration;
