export enum ConnectorType {
    HUBRISE = 'hubrise',
    ZELTY = 'zelty',
    CARREPOS = 'carrepos',
    DELIVERECT = 'deliverect',
    SYMBIOZ = 'symbioz',
    TILLER = 'tiller',
    LEO2 = 'leo2',
    LIGHTSPEED = 'lightspeed',
    RUSHOUR = 'rushour',
    DELIVEROO = 'deliveroo',
    KEYTCHENS = 'keytchens',
    UNTILL = 'untill',
    PI = 'pi',
    NO_CONNECTOR = 'no_connector',
    OPOSSUM = 'opossum',
    ALLEATONE = "alleatone",
    SQUARE = "square",
    ORCHESTRA = "orchestra",

    KILLBILLS = "killbills",
    LABWARE = "labware",

    // Not implemented for now in conector but only in printer or to retrieve orders
    JALIA = "jalia",
    ADDITION = "addition",
    CLYO = "clyo",
    TRIVEC = "trivec",
    POPINA = "popina",
    // TODO: check if related to a version instead
    POPINA_TEXT = "popina_text",
    VEGA = "vega",
    PHARE = "phare",
    STARVISION = "starvision",
    CASHMAG = "cashmag",
    VENTAP = "ventap",
    OTHER = "other",
    CASHPAD = "cashpad",
    BOXAPOS = "boxapos",
}

export const connectorIdToType: { [connectorId: string]: ConnectorType } = {
    "02": ConnectorType.ADDITION,
    "03": ConnectorType.TRIVEC,
    "04": ConnectorType.KILLBILLS,
    "05": ConnectorType.LABWARE,
    "06": ConnectorType.JALIA,
    "07": ConnectorType.POPINA,
}
