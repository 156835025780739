import log from "loglevel";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import { UserAuthenticationState } from "../../authentication/models/AuthenticationState";
import { ERROR_QUERY_PARAM_NAME } from "../../my-lemonade-library/src/common/configs/CommonApiRoutes";
import { ORDER_ID_QUERY_PARAM_NAME, ORDER_REF_QUERY_PARAM_NAME } from "../../orders/configs/OrdersRouterConfig";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { CustomerInformationModalActions } from "../redux/CustomerInformationModalActions";
import customerInformationModalService from "../services/CustomerInformationModalService";


/**
 * Return true if the user is logged in or if they decided to be anonymous
 */
export const isUserLoggedInOrGuest = (user_authentication_state: UserAuthenticationState): boolean => {

    if (
        isUserLoggedIn(user_authentication_state)
        || user_authentication_state.user?.has_clicked_guest_beginning
        || user_authentication_state.user?.has_clicked_guest_end
    ) {

        return true;
    }

    return false;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Decide what to do with the customer information modal
 * depending on settings from the location, from the user, etc
 * It is triggered when launching the webapp and each time the 
 * modal is closed
 */
const useCustomerInformationModalDecision = (): void => {

    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();

    const orderRefURL = query.get(ORDER_REF_QUERY_PARAM_NAME);
    const orderIdURL = query.get(ORDER_ID_QUERY_PARAM_NAME);
    const errorQueryParam = query.get(ERROR_QUERY_PARAM_NAME);

    const { selectedTable, isLoaded, selectedLocation, tableLinkId } = useTypedSelector((state: RootState) => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { order, initOrders } = useTypedSelector((state: RootState) => state.order);
    const { formToDisplay } = useTypedSelector((state: RootState) => state.customerInformationModal);

    /**
     * When opening the webapp or when closing the modal, check some settings
     * to display or not the modal, and decide what to display
     */
    useEffect(() => {

        log.debug(">-C-> Entering the useEffect in the customer information modal hook", {
            formToDisplay,
            isLoaded,
            initOrders,
            selectedTable_id: selectedTable.id,
            selectedLocation,
            order_customer: order.customer,
            history_location_pathname: history.location.pathname,
            orderRefURL,
            data,
        });

        // Only do the process if the location is fully loaded
        if (!isLoaded || !selectedLocation) {

            log.debug(">-C-> Location is not fully loaded yet, nothing to do");
            return;
        }

        // Only do the process if the popup is closed (first time entering the webapp or when
        // the user closes it)
        if (formToDisplay) {

            log.debug(`>-C-> The modal is already opened on ${formToDisplay}, nothing to do`);
            return;
        }

        const formToOpen = customerInformationModalService.getFormToOpen({
            tableLinkId,
            query,
            authenticationData: data,
            currentPathname: history.location.pathname,
            selectedTable,
            selectedLocation,
            order,
            initOrders,
            orderIdQueryParam: orderIdURL,
            orderRefQueryParam: orderRefURL,
            errorQueryParam,
            message: "",
        });
        if (formToOpen) {
            log.debug(`>-C-> (useCustomerInformationModalDecision) The modal should be opened on ${formToOpen.form}, with parameters: ${JSON.stringify(formToOpen.parameters)}`);
            dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                formToOpen.form,
                formToOpen.parameters
            ));
            return;
        }

        // Checkout service_type
        // We want to force displaying the modal when checkout (except for pages related to payment)
        // TODO: find a better way to do it
        /*if (
            selectedTable.service_type === SupportedServiceType.CHECKOUT
            && !history.location.pathname.includes(ROUTES.OrderSummaryPage)
            && !history.location.pathname.includes(ROUTES.MyInformations)
            && !history.location.pathname.includes(ROUTES.PaymentChoice)
            && !history.location.pathname.includes(ROUTES.PaymentShareItems)
            && !history.location.pathname.includes(ROUTES.SharePayment)
            && !history.location.pathname.includes(ROUTES.PaymentCollectionInfo)
            && !history.location.pathname.includes(ROUTES.ConfirmationWord)
            && !history.location.pathname.includes(ROUTES.ConfirmPayment)
            && !history.location.pathname.includes(ROUTES.ErrorRoutePage)
            && !history.location.pathname.includes(ROUTES.LoyaltyPage)
            && !history.location.pathname.includes(ROUTES.PaymentLyra)
            && !history.location.pathname.includes(ROUTES.PaymentLyra)
            && !history.location.pathname.includes(ROUTES.PaymentLyf)
            && !history.location.pathname.includes(ROUTES.PaymentStripe)
            && !history.location.pathname.includes(ROUTES.PaymentEdenred)
        ) {

            log.debug(">-C-> The service_type is CHECKOUT -> showing the CHECKOUT_SERVICE_TYPE form");

            dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                CustomerInformationModalFormToDisplay.CHECKOUT_SERVICE_TYPE,
            ))

            return;
        }*/

        log.debug(">-C-> Nothing to show on the modal");

        /**
         * pathname added to dependecies
         * useEffect launch on each pages change
         * => did to handle modal after a payment in order to not display modal on OrderConfirmationPage (case: >-C-> Takeout incomplete)
         * But as we have not expected time when we come back, the modal has to be reopen in order to select a new expected time
         * Quick choice & open to discussion
         */
    }, [
        formToDisplay,
        isLoaded,
        initOrders,
        selectedTable.id,
        selectedLocation,
        order.customer,
        history.location.pathname,
        orderRefURL,
        JSON.stringify(data),
    ]);
}

export default useCustomerInformationModalDecision;