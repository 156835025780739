import log from "../../Common/services/LogService";
import CustomerInformationModalFormOpenArgs from "../../customers/models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "../../customers/services/BaseFormModalService";
import { ERROR_QUERY_PARAM_NAME } from "../../my-lemonade-library/src/common/configs/CommonApiRoutes";
import AuthenticationErrorFormParameters from "../models/AuthenticationErrorFormParameters";

export default class AuthenticationErrorFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.AUTHENTICATION_ERROR);
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: AuthenticationErrorFormParameters } | null {

        const errorCode = args.errorQueryParam ?? args.query.get(ERROR_QUERY_PARAM_NAME);
        if (errorCode) {
            log.debug(">-C-> Error in query params, showing the error modal");
            const formParameters: AuthenticationErrorFormParameters = {
                errorMessageId: `authentication.authentication_error.${errorCode}`,
            };
            return { form: this.form, parameters: formParameters };
        }

        return null;
    }
};