import { Location, SupportedServiceType } from "../../../model/Location";
import { getCatalogFirestoreDocPath } from "../../catalogs/services/CatalogService";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import { getOrderFirestoreDocPath } from "../../orders/services/OrderService";
import { Connector } from "../models/Connector";

export const getConnectorOrderLogFirestoreCollectionPath = (accountId: string, locationId: string, orderId: string): string => {
    return `${getOrderFirestoreDocPath(accountId, locationId, orderId)}/${MylemonadeScopes.CONNECTORS}`;
}
export const getConnectorLocationLogFirestoreCollectionPath = (accountId: string, locationId: string): string => {
    return `${getLocationFirestoreDocPath(accountId, locationId)}/${MylemonadeScopes.CONNECTORS}`;
}

export const getConnectorCatalogLogFirestoreCollectionPath = (accountId: string, locationId: string, catalogId: string): string => {
    return `${getCatalogFirestoreDocPath(accountId, locationId, catalogId)}/${MylemonadeScopes.CONNECTORS}`
}

export const getOrderRefFromBase64IfNeeded = (orderRefBase64: string): string => {

    orderRefBase64 = decodeURIComponent(orderRefBase64);
    const buffer = Buffer.from(orderRefBase64, 'base64');
    const bufString = buffer.toString('hex').toUpperCase();
    if (bufString.length === 32) {
        const parts = bufString.match(/.{1,8}/g);
        return parts!.join("-");
    }

    return orderRefBase64;
}

/**
 * TODO: test it
 * @param location
 * @param service 
 * @returns 
 */
export const getConnectorForServiceType = (location: Location, service: SupportedServiceType): Connector | null => {

    let locationConnectorUse: Connector | null = null

    if (location && location.connector) {

        if (location.connector.export_orders) {
            locationConnectorUse = location.connector
        }

        if (location.connector.sub_connectors) {

            const serviceSubConnector = location.connector.sub_connectors.find(sub => sub.services_type.includes(service))

            if (serviceSubConnector && serviceSubConnector.export_orders) {
                locationConnectorUse = serviceSubConnector
            }
        }
    }

    return locationConnectorUse

}
