import { Catalog, Categorie, Deal, DealLine, Option, OptionList, Product } from "../../../model/Catalog";
import { Discount } from "../../discounts/models/Discount";
import { Suggestion } from "../../suggestions/models/Suggestion";
import { Tax } from "../../taxes/models/Tax";

export type CatalogEntity = Catalog | Product | Categorie | OptionList | Deal | DealLine | Suggestion | Option | Tax | Discount;
export type CatalogEntityWithDisable = Catalog | Product | Categorie | OptionList | Deal | Suggestion | Option;

export enum CatalogEntityType {
    CATALOG = "catalog",
    PRODUCT = "product",
    CATEGORY = "category",
    // TODO: to be remove
    SUB_CATEGORY = 'subCategory',
    SKU = "sku",
    DEAL = "deal",
    DEAL_LINE = "deal_line",
    SUGGESTION = "suggestion",
    OPTION_LIST = "option_list",
    OPTION_LINE = "option_line",
    OPTION = "option",
    TAX = "tax",
    DISCOUNT = "discount",
    RESTRICTION = "restriction"
};
