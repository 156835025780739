export enum MyLemonadeApplications {
    BACKOFFICE = "my-lemonade-backoffice",
    MANAGER_APP = "my-lemonade-manager-app",
    WEBAPP = "my-lemonade-webapp",
    API = "my-lemonade-api",
    CLOUD_FUNCTIONS = "my-lemonade-functions",
    PRINTERS_SERVICE = "printers-service",
    PRINTER_RECEIPT_API = "printer-receipt-api",
    SCRAPING_API = "scraping-api",
    BRANDS_API = "brands-api",
    BIGQUERY_ELT_API = "bigquery-elt-api",
    BRANDS_BACKOFFICE = "brands-backoffice",
    CHALLENGE_UPDATER = "challenge-updater",
    ORDERS_IMPORTER = "orders-importer",
}