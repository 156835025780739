export enum FirebaseAuthenticationErrorCodes {
    //common error
    INVALID_EMAIL = "auth/invalid-email",
    // createUser errors code
    EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
    /**
     * Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
     */
    OPERATION_NOT_ALLOWED = "auth/operation-not-allowed",
    WEAK_PASSWORD = "auth/weak-password",
    // signin errors code
    USER_DISABLE = "auth/user-disabled",
    USER_NOT_FOUND = "auth/user-not-found",
    WRONG_PASSWORD = "auth/wrong-password",
}