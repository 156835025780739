import { call, put, select, takeLatest } from "@redux-saga/core/effects";
import log from "loglevel";
import { all } from "redux-saga/effects";
import LocationState from "../../Locations/models/LocationState";
import { locationActions } from '../../Locations/redux/LocationActions';
import { AuthenticationState } from "../../authentication/models/AuthenticationState";
import { rsf } from "../../config/firebase";
import { Table } from "../../my-lemonade-library/model/Location";
import { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import { updateServiceTypePricesInCatalog } from "../../my-lemonade-library/src/deals/services/DealHelper";
import { getTableFirestoreCollectionPath, getTableServiceTypes, mergeTableServiceTypes } from "../../my-lemonade-library/src/tables/services/TableService";
import actions, { orderActions } from '../../orders/redux/OrderActions';
import { getOrdersCollectionReference, updateServiceType } from "../../orders/redux/OrderSagas";
import { OrderState } from "../../orders/redux/models/OrderState";
import { RootState } from "../../redux/root-reducer";
import tableActions, { APPLY_SELECT_SERVICE_TYPE, ApplySelectServiceTypeAction, LOAD_TABLES, LoadTablesAction, SET_TABLE_SELECTED, SetTableSelectedAction } from './TableActions';


export function* tablesSaga() {
    yield all([
        takeLatest(LOAD_TABLES, loadTablesSaga),
        takeLatest(SET_TABLE_SELECTED, setTableSelected),
        takeLatest(APPLY_SELECT_SERVICE_TYPE, applySelectServiceType),
    ])
}

function* setTableSelected(action: SetTableSelectedAction) {
    try {
        const id = action.payload;
        const { data } = yield select((state: RootState) => state.table);
        const { selectedLocation, selectedCatalog } = yield select((state: RootState) => state.locations);

        const tableSelected: Table = data[id]

        yield put(orderActions.loadOrders());

        // Extract the service type and put it in the table if possible
        mergeTableServiceTypes(tableSelected, selectedLocation, selectedCatalog);

        yield put(locationActions.setTable(tableSelected));
        yield put(actions.setTableId(id));

        const serviceType = tableSelected.service_type;

        if (serviceType) {
            yield put(actions.setServiceType(serviceType));
            //Check authentication_process
            if (selectedLocation) {
                yield put(locationActions.setAuthenticationLocationProcess(
                    selectedLocation,
                    getTableServiceTypes(tableSelected)
                ));
            }

        }


    } catch (error) {
        log.error(error)
        yield put(tableActions.loadTableError(error as string))
    }
}

function* loadTablesSaga(action: LoadTablesAction) {
    try {
        const { accountId, locationId } = action.payload
        const tableSnapShot: firebase.firestore.QuerySnapshot = yield call(rsf.firestore.getCollection, getTableFirestoreCollectionPath(accountId, locationId))
        let tableData: { [key: string]: Table } | null = null
        tableSnapShot.forEach(data => {
            const table = data.data() as Table
            const key = data.id
            table.id = key
            if (!tableData) {
                tableData = {}
            }
            tableData[key] = table
        })
        if (tableData) {
            yield put(tableActions.loadTableSuccess(tableData))
        } else {
            log.info(`No tables found for location ${locationId}`)
            yield put(tableActions.loadTableError(`No tables found for location ${locationId}`))
        }
    } catch (error) {
        log.error(error)
        yield put(tableActions.loadTableError(error as string))
    }
}

export function* applySelectServiceType(action: ApplySelectServiceTypeAction) {

    const { catalog, serviceType, tableArea } = action.payload;
    const { data } = (yield select((state: RootState) => state.authentication)) as AuthenticationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;
    const { selectedLocation } = (yield select((state: RootState) => state.locations)) as LocationState;
    const signinProvider = data.user_authentication_state.provider
        ? data.user_authentication_state.provider as SignInProviders
        : null

    // Extend the catalog with the computed prices (reductions) for deals & products
    updateServiceTypePricesInCatalog(
        catalog,
        serviceType,
        signinProvider,
        data.user_authentication_state.user,
        tableArea
    )

    const collectionReference = getOrdersCollectionReference(selectedLocation);
    yield put(locationActions.setCatalog(catalog));

    // Setting the service_type in the location > selectedTable state
    yield put(locationActions.setServiceType(serviceType));

    // Setting the service_type in the order state
    yield put(orderActions.setServiceType(serviceType));

    if (!collectionReference) {
        log.error(`No collection reference found, could'nt update service type for order ${order.id}`)
        return;
    }
    if (order.id && order.id !== '') {
        yield call(updateServiceType, order.id, serviceType, collectionReference)
    }

}


