import { SupportedServiceType } from "../../../model/Location";
import { OrderCharge, OrderChargeType, OrderInBase } from "../../../model/Order";
import { Money, moneyToNumber, numberToMoney } from "../../common/models/Money";
import DeliveryProviderType from "../../delivery/models/DeliveryProviderType";
import OrderDeliveryInfos from "../../delivery/models/OrderDeliveryInfos";
import deliveryHelper from "../../delivery/services/DeliveryHelper";
import { PaymentFeeConfigPriceParameters } from "../../orders/models/PaymentFeeConfigPriceParameters";
import PaymentErrors from "../models/PaymentErrors";
import PaymentFeeConfig from "../models/PaymentFeeConfig";

class FeesService {

    /**
     * Compute the market place fee
     * @param orderPrice The order price (not in cent, 10.0 for 10€)
     * @param paymentFee The payment fee config
     * @param inCents True if the marketplace fee is returned in cents
     */
    computeMarketplaceFee = (orderPrice: number, paymentFee: PaymentFeeConfigPriceParameters, inCents?: boolean) => {

        if (paymentFee.fee_vat < 0 || paymentFee.fee_vat > 100) {
            throw PaymentErrors.INVALID_MARKETPLACE_FEE_VAT.withValue(paymentFee.fee_vat);
        }

        const marketplaceFeeHT = this.computeServiceFee(orderPrice, paymentFee);
        const marketplaceFee = (1 + paymentFee.fee_vat / 100) * (marketplaceFeeHT);
        if (!inCents) {
            return Math.ceil(marketplaceFee * 100) / 100;
        } else {
            return Math.ceil(marketplaceFee * 100);
        }
    }

    getMatchingFeeConfig = (
        feeConfigs: PaymentFeeConfig[],
        tableAreaRef: string | undefined,
        order: Pick<OrderInBase, "service_type" | "delivery">,
        feeCategory?: string,
    ): PaymentFeeConfig | null => {

        const matchingFeeConfigs = this.filterFeeConfigs(
            feeConfigs,
            tableAreaRef,
            order.service_type,
            order.delivery,
            order.delivery?.delivery_type,
            feeCategory,
        );

        // WARN: configs that have a service type defined are prioritized over configs that don't.
        const firstFeeConfigWithServiceType = matchingFeeConfigs.find((fc) => fc.service_type);
        return firstFeeConfigWithServiceType ?? matchingFeeConfigs[0] ?? null;
    }

    filterFeeConfigs = (
        feeConfigs: PaymentFeeConfig[],
        tableAreaRef: string | undefined,
        serviceType: SupportedServiceType,
        orderDeliveryInfo: OrderDeliveryInfos | undefined,
        deliveryProvider: DeliveryProviderType | undefined,
        feeCategory?: string,
    ): PaymentFeeConfig[] => {

        return feeConfigs.filter((feeConfig) => {

            // Check that the service type is matching if defined
            if (feeConfig.service_type && feeConfig.service_type !== serviceType) {
                return false;
            }

            // Check that the fee category is matching if defined
            if (feeCategory && feeConfig.fee_category !== feeCategory) {
                return false;
            }

            // Check that the delivery range is matching if defined
            if (serviceType === SupportedServiceType.DELIVERY && feeConfig.delivery_range) {
                const deliveryProviderPrice = (orderDeliveryInfo?.provider_price) ? moneyToNumber(orderDeliveryInfo.provider_price) : undefined;
                const matchingDeliveryRange = deliveryHelper.isMatchingDeliveryRange(feeConfig.delivery_range, deliveryProviderPrice, orderDeliveryInfo?.distance);
                if (!matchingDeliveryRange) {
                    return false;
                }
            }

            // Check that the delivery provider is matching if defined
            if (
                serviceType === SupportedServiceType.DELIVERY
                && feeConfig.delivery_type
                && feeConfig.delivery_type !== deliveryProvider
            ) {
                return false;
            }

            // 
            if (
                feeConfig.table_area_refs
                && (
                    !tableAreaRef
                    || !feeConfig.table_area_refs.includes(tableAreaRef)
                )
            ) {
                return false;
            }

            return true;
        });
    }

    checkAndApplyServiceFees = (
        locationServiceFees: PaymentFeeConfig[] | undefined,
        tableAreaRef: string | undefined,
        order: Pick<OrderInBase, "id" | "service_type" | "charges">,
        orderFinalPrice: Money,
        currency: string,
    ) => {

        const paymentFees = this.getMatchingFeeConfig(
            locationServiceFees ?? [],
            tableAreaRef,
            order,
            undefined,
        )

        if (paymentFees) {
            const serviceFee: number = feesService.computeServiceFee(moneyToNumber(orderFinalPrice), paymentFees)
            const serviceFeeCharge: OrderCharge = {
                name: 'service_fees',
                type: OrderChargeType.SERVICE_FEE,
                price: numberToMoney(serviceFee, currency)
            }
            if (order.charges && order.charges.length > 0) {
                const serviceFeesIndex: number = order.charges.findIndex(charge => charge.type === OrderChargeType.SERVICE_FEE)
                if (serviceFeesIndex !== -1) {
                    order.charges[serviceFeesIndex] = serviceFeeCharge
                } else {
                    order.charges.push(serviceFeeCharge)
                }
            } else {
                order.charges = [serviceFeeCharge]
            }
        } else {
            // Removing the service fee if there is no service fee for this order
            if (order.charges && order.charges.length > 0) {
                const serviceFeesIndex: number = order.charges.findIndex(charge => charge.type === OrderChargeType.SERVICE_FEE)
                if (serviceFeesIndex !== -1) {
                    order.charges.splice(serviceFeesIndex, 1)
                }
            }
        }
    }

    /**
     * Compute the service fees.
     * @param orderPrice The order price (not in cent, 10.0 for 10€) with VAT
     * @param paymentFee A PaymentFeeConfig object
     * @returns The service fees in number
     */
    computeServiceFee = (orderPrice: number, paymentFee: PaymentFeeConfigPriceParameters): number => {

        if (paymentFee.percentage_fee && (paymentFee.percentage_fee < 0 || paymentFee.percentage_fee > 100)) {
            throw PaymentErrors.INVALID_SERVICE_PERCENTAGE_FEE.withValue(paymentFee.percentage_fee);
        }

        if (!paymentFee.percentage_fee) {
            paymentFee.percentage_fee = 0;
        }

        let percentageFeePrice = orderPrice * paymentFee.percentage_fee / 100;
        if (paymentFee.min_percentage_fee) {
            const minPercentageFee: number = moneyToNumber(paymentFee.min_percentage_fee)
            if (percentageFeePrice < minPercentageFee) {
                percentageFeePrice = minPercentageFee;
            }
        }
        if (paymentFee.max_percentage_fee) {
            const maxPercentageFee: number = moneyToNumber(paymentFee.max_percentage_fee)
            if (percentageFeePrice > maxPercentageFee) {
                percentageFeePrice = maxPercentageFee;
            }
        }
        // Avoid rounding errors 15 + 40 = 55.00000000000001
        const serviceFeeBeforeRounding = Math.round((percentageFeePrice + moneyToNumber(paymentFee.fixed_fee)) * 1000) / 10
        // Ceil to the next cent 55.3 = 56 cents
        const serviceFees: number = Math.ceil(
            serviceFeeBeforeRounding
        ) / 100

        if (paymentFee.min_fee) {
            const minFee: number = moneyToNumber(paymentFee.min_fee)
            if (serviceFees < minFee) {
                return minFee
            }
        }

        if (paymentFee.max_fee) {
            const maxFee: number = moneyToNumber(paymentFee.max_fee)
            if (serviceFees > maxFee) {
                return maxFee
            }
        }

        return serviceFees
    }

    /**
     * Generates a fee config with 0% fee.
     * @param reason will be appended to the ref, do not use spaces or special characters
     */
    getZeroFeeConfig = (currency: string, reason?: string): PaymentFeeConfig => {
        const result: PaymentFeeConfig = {
            ref: `zeroFee${reason ? `_${reason}` : ""}`,
            fee_category: "standard",
            fixed_fee: numberToMoney(0, currency),
            percentage_fee: 0,
            fee_vat: 0,
        }
        return result;
    }
}

export const feesService = new FeesService();