import log from "loglevel";
import { IntlShape } from "react-intl";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import { UserAuthenticationState } from "../../authentication/models/AuthenticationState";
import { LoyaltyInformationToDisplayConfig, LoyaltyInformationToDisplayOrder } from "../../loyalty/redux/models/LoyaltyState";
import { Catalog } from "../../my-lemonade-library/model/Catalog";
import { Location, Table } from "../../my-lemonade-library/model/Location";
import { Order } from "../../my-lemonade-library/model/Order";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import { addMoney, divideMoney, Money, MoneyToStringWithSymbol, multiplyMoney, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { DiscountType, OrderDiscount } from "../../my-lemonade-library/src/discounts/models/OrderDiscount";
import { ComputeLoyaltyResult } from "../../my-lemonade-library/src/loyalties/models/ComputeLoyaltyResult";
import { LoyaltyLocationConfig } from "../../my-lemonade-library/src/loyalties/models/LoyaltyLocationConfig";
import { OrderLoyaltyOperations } from "../../my-lemonade-library/src/loyalties/models/OrderLoyaltyOperations";
import loyaltyHelper from "../../my-lemonade-library/src/loyalties/services/LoyaltyHelper";
import { OrderPriceArg } from "../../my-lemonade-library/src/orders/models/OrderPriceArg";
import { orderPrice } from "../../my-lemonade-library/src/orders/services/OrderPricing";
import { orderService } from "../../my-lemonade-library/src/orders/services/OrderService";
import translationService from "../../my-lemonade-library/src/translations/services/TranslationService";

/**
 * The loyalty front-end depends on a 2D array:
 *                      |  order.loyalty_operation  | !order.loyalty_operation
 * Anonymous            |                           |
 * Connected master     |                           |
 * Connected not master |                           |
 */
export enum LoyaltyLoginState {
    ANONYMOUS = "anonymous",
    CONNECTED_MASTER = "connected_master",  // Means order.loyalty_user_id === state.user.uid
    CONNECTED_NOT_MASTER = "connected_not_master",
}

export const getLoyaltyLoginState = (
    user_authentication_state: UserAuthenticationState,
    orderLoyaltyMasterUid: string | undefined,
): LoyaltyLoginState => {

    if (!isUserLoggedIn(user_authentication_state)) {

        return LoyaltyLoginState.ANONYMOUS;
    }
    else if (
        user_authentication_state.user?.uid
        && orderLoyaltyMasterUid
        && user_authentication_state.user?.uid === orderLoyaltyMasterUid
    ) {
        return LoyaltyLoginState.CONNECTED_MASTER;
    }
    else {

        return LoyaltyLoginState.CONNECTED_NOT_MASTER;
    }
}

/**
 * We assume that all operations are related to the same user, so we return the first one.
 * Always return false if the loyalty config is payments only
 * @param loyaltyOperations 
 * @returns 
 */
export const isThereALoyaltyOperation = (
    loyaltyOperations: OrderLoyaltyOperations[] | undefined,
    isPaymentsOnly: boolean | undefined,
): boolean => {

    if (!isPaymentsOnly && loyaltyOperations && loyaltyOperations.length > 0) {
        return true;
    }

    return false;
}

/**
 * // TODO: use orderPricing? The logic of earn & spend is already there
 * @param loyaltyConfigParam 
 * @param givenOrder 
 * @param customer 
 * @param intl 
 * @param errorIfNull 
 * @param getEarnedPointsEvenIfPaymentsOnly 
 * @returns 
 */
export const estimateLoyaltyEarningAndSpending = (
    location: Location,
    catalog: Catalog,
    table: Table,
    order: Order,
    loyaltyConfig: LoyaltyLocationConfig,
    customer: Customer | null,
    intl: IntlShape,
): LoyaltyInformationToDisplayOrder | null => {

    // Send the order to orderPrice so that we can get the earning loyalty result.
    // Also setting the customer use_points to true just before, to simulate the operation.
    // Set all the other customers use_points to false so that we only see what the current customer will earn/spend.
    const orderCopy = loyaltyHelper.simulateCanUseLoyaltyForUser(order, customer?.uid ?? "");

    const orderPriceArgs: OrderPriceArg = {
        location,
        catalog,
        table,
        order: orderCopy,
        checkMinAmount: false,
        disableExpectedTimeResetForEatin: true,
        doNotThrow: true,
        patchOrder: false,
        spendingIncludeItemsWithUpdateId: true,
    }
    const { loyaltyResult: spendingLoyaltyResults } = orderPrice(orderPriceArgs);
    const spendingLoyaltyResult = spendingLoyaltyResults?.find((r) => r.user_id === customer?.uid) ?? null;
    let earningLoyaltyResult: ComputeLoyaltyResult | null = null;

    // If the config is payments_only, we'll have to get a different loyaltyResult for the earning part.
    // The spending part stays the same no matter the config.
    if (loyaltyConfig.earning_rule.earning_mode === "payments") {

        // We need to cloneDeep again the order because we cannot use orderCopy as it
        // went trough orderPrice. Indeed, orderPrice will use loyalty points if possible to calculate
        // how much we can earn on an order. But since we're dealing with payments, we only want the max amount
        // we can earn by paying all without spending any loyalty point.
        const orderCopy2 = loyaltyHelper.simulateCanUseLoyaltyForUser(order, customer?.uid ?? "");

        earningLoyaltyResult = loyaltyHelper.computePaymentLoyaltyForWholeOrder(
            loyaltyConfig,
            orderCopy2,
            customer?.loyalty_balance ?? 0,
            customer?.uid ?? "",
        );
    }
    else {
        earningLoyaltyResult = spendingLoyaltyResult;
    }

    log.debug("estimateLoyaltyEarningAndSpending", { spendingLoyaltyResult, earningLoyaltyResult, location, order, loyaltyConfig, customer });

    const final: LoyaltyInformationToDisplayOrder = {

        // Do not use in current order if "payments_only".
        // We are using "loyaltyConfigParam" because we could have removed the boolean from "loyaltyConfig" (see code above)
        // TODO: check if add only paid orders ?
        canUseInCurrentOrder: Boolean(
            spendingLoyaltyResult?.can_use_point
            && loyaltyConfig.earning_rule.earning_mode !== "payments"
            && !loyaltyConfig.earning_rule.only_paid_orders
        ),

        // Will be filled below
        howMuchEarned: null,
        priceOff: null,
    }

    const { spending_rule } = loyaltyConfig;

    /////////////////
    // HOW MUCH EARNED
    /////////////////

    if (earningLoyaltyResult?.earned_points) {

        // Case 1: spending rule is order_discount
        // estimating the values in €
        if (spending_rule.spending_mode === "order_discount") {

            final.howMuchEarned = MoneyToStringWithSymbol(
                divideMoney(
                    multiplyMoney(
                        spending_rule.amount as Money,
                        earningLoyaltyResult.earned_points,
                    ),
                    spending_rule.points
                )
            );
        }
        // Case 2: spending rule is not order_discount
        // no abstraction, everything calculated in points
        else {

            final.howMuchEarned = `+${earningLoyaltyResult.earned_points} ${intl.formatMessage({ id: "loyalty.points.abbreviation" })}`;
        }
    }

    /////////////////
    // PRICE_OFF
    /////////////////

    if (spendingLoyaltyResult?.price_off) {
        final.priceOff = spendingLoyaltyResult.price_off;
    }

    return final;
}

export const getLoyaltyConfigInfo = (
    loyaltyConfig: LoyaltyLocationConfig,
    intl: IntlShape,
): LoyaltyInformationToDisplayConfig | null => {

    const { earning_rule, spending_rule } = loyaltyConfig;

    const final: LoyaltyInformationToDisplayConfig = {
        isCashback: false,
        earnExplaination: "",
        spendExplaination: "",
        isPaymentsOnly: Boolean(loyaltyConfig.earning_rule.earning_mode === "payments"),
        onlyPaidOrders: Boolean(loyaltyConfig.earning_rule.only_paid_orders),
    }

    let earnDescriptionRule = " ";
    if (earning_rule.earning_mode === "item_price") {
        earnDescriptionRule += intl.formatMessage({
            id: "loyalty.earn_description.rule.amount"
        }, {
            price: MoneyToStringWithSymbol(earning_rule.amount as Money)
        });
    }
    else if (earning_rule.earning_mode === "item_count") {
        earnDescriptionRule += intl.formatMessage({
            id: "loyalty.earn_description.rule.items_quantity"
        }, {
            number_of_items: earning_rule.amount
        });
    }
    else {
        earnDescriptionRule += intl.formatMessage({
            id: "loyalty.earn_description.rule.number_of_orders"
        }, {
            number_of_orders: earning_rule.amount
        });
    }


    /////////////////
    // SPENDING: ORDER DISCOUNT
    // abtracting the values in €
    /////////////////

    if (spending_rule.spending_mode === "order_discount") {

        final.isCashback = true;

        final.earnExplaination = intl.formatMessage({
            id: "loyalty.earn_description.how_much.discount"
        }, {
            price: MoneyToStringWithSymbol(
                divideMoney(
                    multiplyMoney(
                        spending_rule.amount as Money,
                        earning_rule.points
                    ),
                    spending_rule.points
                )
            )
        });

        final.spendExplaination = intl.formatMessage({
            id: "loyalty.spend_description.discount"
        }, {
            amount: MoneyToStringWithSymbol(spending_rule.amount as Money)
        });
    }

    /////////////////
    // SPENDING: FREE ITEM
    // no abstraction, everything calculated in points
    /////////////////
    else {

        final.earnExplaination = intl.formatMessage({
            id: "loyalty.earn_description.how_much.free_item"
        }, {
            number_of_points: earning_rule.points
        });

        final.spendExplaination = intl.formatMessage({
            id: "loyalty.spend_description.free_item"
        }, {
            number_of_items: spending_rule.amount,
            number_of_points: spending_rule.points
        });
    }

    /////////////////
    // GENERIC TEXT
    /////////////////

    final.earnExplaination += earnDescriptionRule;

    if (earning_rule.categories) {
        final.earnExplaination += " " + intl.formatMessage({
            id: "loyalty.categories_restriction"
        }, {
            categories: getAllCategoriesTranslated(earning_rule.categories.refs, intl)
        });
    }

    if (spending_rule.categories) {
        final.spendExplaination += " " + intl.formatMessage({
            id: "loyalty.categories_restriction"
        }, {
            categories: getAllCategoriesTranslated(spending_rule.categories.refs, intl)
        });
    }

    return final;
}

const getAllCategoriesTranslated = (
    categoriesRefs: string[],
    intl: IntlShape,
): string => {

    let finalStr = "";
    categoriesRefs.forEach((catRef, index) => {

        const translationId = translationService.getCategoryNameTranslationKeyFromRef(catRef);

        if (translationId && intl.messages[translationId]) {

            finalStr += intl.formatMessage({ id: translationId })
        }
        else {

            log.debug(`Missing translation for category ref ${catRef}`);
            finalStr += catRef;
        }

        if (index !== categoriesRefs.length - 1) {
            finalStr += ", ";
        }
        else {
            finalStr += ".";
        }
    });

    return finalStr;
}

/**
 * Given a discounts array, get the total amount of the
 * loyalty discounts. 
 * WARNING: we only compute the not-already-sent discounts.
 * @param discounts 
 * @returns 
 */
export const getNewLoyaltyDiscountsTotalAmount = (
    discounts: OrderDiscount[] | undefined,
    currency: string,
): Money => {

    if (!discounts || discounts.length < 1) {
        return numberToMoney(0, currency);
    }

    return discounts
        .filter(discount => discount.type === DiscountType.LOYALTY)
        .map(discount => multiplyMoney(discount.price_off, -1))
        .reduce((a, b) => addMoney(a, b), numberToMoney(0, currency));
}

export const getLoyaltyCurrentBalance = (
    pointsBalance: number | undefined,
    locationLoyaltyConfig: LoyaltyLocationConfig | undefined,
    loyaltyConfigContent: LoyaltyInformationToDisplayConfig | undefined,
    intl: IntlShape,
): string => {

    if (!pointsBalance || !locationLoyaltyConfig || !loyaltyConfigContent) {
        return "--";
    }

    if (loyaltyConfigContent.isCashback) {

        return MoneyToStringWithSymbol(
            divideMoney(
                multiplyMoney(
                    locationLoyaltyConfig.spending_rule.amount as Money,
                    pointsBalance
                ),
                locationLoyaltyConfig.spending_rule.points
            )
        );
    }
    else {

        return `${pointsBalance} ${intl.formatMessage({ id: "loyalty.points.abbreviation" })}`
    }
}

/**
 * This functions tells if we have to store the loyalty "use_points" information
 * locally or not.
 * Typically, when we build an order (fullyEditable), we want the checkbox to spread the
 * temporary (no update-id yet) discount to firestore, so that everybody can see that the order total
 * went lower.
 * In contrary, when we are paying an order which has already been sent, we don't want everybody to see live
 * the discounts that we're applying, because it only concerns our own payment. Instead, we'll store the
 * "use_points" information locally.
 */
export const isLocalLoyaltyOnly = (order: Order, allowSharePayment: boolean | undefined): boolean => {

    return (
        !orderService.isFullyEditable(order)
        && Boolean(allowSharePayment)
    );
}