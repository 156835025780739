import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Typography, useTheme } from '@mui/material';


interface CustomerAddressDisplayProps {
    isGetRestoTheme?: boolean;
    address?: string,
    postalCode?: string,
    city?: string,
    name?: string
}

const CustomerAddressDisplay = (props: CustomerAddressDisplayProps) => {

    const {
        isGetRestoTheme = false,
        address = '',
        postalCode = '',
        city = '',
        name = ''
    } = props;

    const theme = useTheme();

    return (
        <Box
            width={1}
            style={{
                backgroundColor: theme.palette.background.paper,
            }}
            px={isGetRestoTheme ? 2 : 0}
            border={isGetRestoTheme ? 1 : 0}
            borderLeft={0}
            borderRight={0}
            borderColor='grey.300'
        >

            <Box
                display="flex"
                flexDirection='column'
                width={1}
                mb={2}
            >

                <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                >

                    <Box mr={2}>
                        <LocationOnIcon style={{ color: theme.palette.grey[500] }} />
                    </Box>
                    <Box display="flex" flexDirection="column">

                        <Typography variant="subtitle1">
                            {name}
                        </Typography>

                        {isGetRestoTheme

                            ? <Typography
                                variant='body1'
                                color='textSecondary'
                                style={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                }}
                            >
                                {`${address}, ${postalCode} ${city}`}
                            </Typography>

                            : <Box>
                                <Typography>
                                    {`${address},`}
                                </Typography>
                                <Typography>
                                    {`${postalCode} ${city}`}
                                </Typography>
                            </Box>
                        }

                    </Box>
                </Box>

            </Box>

        </Box>
    )
}

export default CustomerAddressDisplay