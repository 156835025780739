import TranslationsState from '../models/TranslationsState'
import actions from './actions'

const initialState: TranslationsState = {
    currentLang: "fr",
    catalogTranslations: {},
    isLoading: false,
    isLoaded: false,
    supportedLanguages: []
}

export default function reducer(state = initialState, { type, payload }: any): TranslationsState {
    switch (type) {
        case actions.CHANGE_LANGUAGE:
            return {
                ...state,
                currentLang: payload.selectedLanguage,
            }
        case actions.LOAD_TRANSLATIONS:
            return {
                ...state,
                isLoading: true,
            }
        case actions.LOAD_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                catalogTranslations: payload.catalogTranslations,
                supportedLanguages: payload.supportedLanguages
            }
        case actions.LOAD_TRANSLATIONS_COMPLETE:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
            }
        case actions.LOAD_TRANSLATIONS_ERROR:
            return {
                ...state,
                error: payload.error
            }
        default:
            return state;
    }
}