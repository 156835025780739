import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getIdToken } from "../../authentication/services/AuthenticationService";
import log from "../../Common/services/LogService";
import { getApiRootUrl } from "../../config/variables";
import LocationState from "../../Locations/models/LocationState";
import { removeUndefinedFromObject } from "../../my-lemonade-library/functions/Helpers";
import { getLocationReviewsFirestoreCollectionPath } from "../../my-lemonade-library/src/reviews/configs/ReviewsFirestoreConfig";
import { Review } from "../../my-lemonade-library/src/reviews/models/Review";
import { CreateLocationReviewAction, CREATE_LOCATION_REVIEW, reviewActions } from "./ReviewActions";

export function* reviewSaga() {
    yield all([
        takeLatest(CREATE_LOCATION_REVIEW, createLocationReview)
    ])
}

async function createFirestoreLocationReview(accountId: string, locationId: string, review: Review): Promise<Review> {
    removeUndefinedFromObject(review);

    const fetchUrl = getApiRootUrl() + '/' + getLocationReviewsFirestoreCollectionPath(accountId, locationId)
    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    headers.append('Content-Type', 'application/json');

    const requestOptions: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify(review)
    };

    const response: Response = await fetch(fetchUrl, requestOptions);

    const responseData = await response.json();
    if (!response.ok) {
        throw new Error(`Error creating review: ${response.status}`);
    }

    return responseData as Review;
}

export function* createLocationReview(action: CreateLocationReviewAction) {
    try {
        const { selectedLocation }: LocationState = yield select(state => state.locations);

        if (selectedLocation) {
            const { review } = action.payload;
            yield call(createFirestoreLocationReview, review.account_id, review.location_id, review)
            yield put(reviewActions.createLocationReviewSuccess())
        }

    } catch (error) {
        log.error(error)
    }
}

