import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import ThemeSvg from "../../Common/components/ThemeSvg";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { MAX_HEIGHT_CUSTOMER_FORM } from '../configs/variables';

export const AUTO_CLOSE_DELAY = 1500;

interface AuthenticationConfirmationProps {
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}
const AuthenticationConfirmation: React.FC<AuthenticationConfirmationProps> = (props) => {

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)
    const { user_authentication_state } = useTypedSelector((state: RootState) => state.authentication.data)

    const onClose = useCallback(() => {
        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION))

    }, [dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Automatically close if after few seconds
            onClose();
        }, AUTO_CLOSE_DELAY);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        // Register the on close action
        props.setOnModalCloseAction({
            formToDisplay: CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION,
            onClose: onClose
        })
    }, [onClose])

    if (!selectedLocation) {
        return null
    }

    return (
        <Box
            width={1}
            display='flex'
            flexDirection='column'
            alignItems={"center"}
            maxWidth={MAX_HEIGHT_CUSTOMER_FORM}
            data-test="customer.modal.authentication_confirmation"
            position={"relative"}
        >
            <Box position={"absolute"} top={0} right={0}>
                <IconButton
                    data-test="customer.modal.authentication_confirmation.close_button"
                    style={{ padding: 0 }}
                    aria-label="Close box"
                    onClick={onClose}
                    size="large">
                    <Close />
                </IconButton>

            </Box>

            <Box
                display="flex"
                flexDirection="column"
                paddingX={2}
            >
                <Box pt={5} pb={2} textAlign="center" >
                    {!selectedLocation.logo_header
                        ? <img
                            style={{
                                maxWidth: '100%',
                                maxHeight: '70px'
                            }}
                            src={selectedLocation.logo_header}
                            alt="Location icon"
                        />
                        : <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/valid_order.svg`}
                            maxHeight={"70px"}
                        />
                    }
                </Box>

                <Typography variant="h2" color={theme.palette.primary.main} textAlign="center">
                    {intl.formatMessage({ id: "authentication.authentication_confirmation.authentication_success" }, { firstname: user_authentication_state.user?.first_name })}
                </Typography>

                <Typography sx={{ paddingBottom: 5, marginTop: 2 }} textAlign="center">
                    {intl.formatMessage({ id: "authentication.authentication_confirmation.text" }, { firstname: user_authentication_state.user?.first_name })}
                </Typography>

            </Box>
        </Box>
    )
}

export default AuthenticationConfirmation;
