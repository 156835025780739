import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FormHelperText, IconButton, InputAdornment, Typography, useTheme } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorMessage, Form, Formik, useField, useFormikContext } from 'formik';
import log from "loglevel";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as yup from 'yup';
import { FormikRoundedCheckBox } from "../../Common/components/Formik/FormikRoundedCheckBox";
import FormikTextInput from "../../Common/components/Formik/FormikTextInput";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { DEFAULT_COUNTRY } from "../../my-lemonade-library/model/Location";
import { fixPhone } from "../../my-lemonade-library/src/common/services/AddressService";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import AuthenticationErrorFormParameters from "../models/AuthenticationErrorFormParameters";
import AuthenticationFormParameters from "../models/AuthenticationFormParameters";
import { FirebaseErrorAuth } from "../models/FirebaseErrorAuth";
import SignUpModel from "../models/SignUpModel";
import AuthenticationActions from "../redux/AuthenticationActions";
import { getFirebaseErrorId, signUpToFirebase } from "../services/AuthenticationService";
import TermsAndConditionsTextWithModal from "./TermsAndConditionsTextWithModal";

// TODO: why this custom component? Props are any, it's horrible
const MyPhoneInput = ({ ...props }) => {

    const [field] = useField({ ...props, name: props.name, type: "input" })
    const formik = useFormikContext();
    const theme = useTheme();

    return (
        <PhoneInput
            {...field}
            onChange={(value) => {
                const phoneObject = { phone: value };
                fixPhone(phoneObject, props.countryCode);
                formik.setFieldValue(field.name, phoneObject.phone)
            }}
            country='fr'
            onlyCountries={['fr']}
            specialLabel=""
            disableDropdown
            inputStyle={{
                backgroundColor: 'transparent',
                border: "none",
                borderBottom: `.1em solid ${theme.palette.primary.main}`,
                borderRadius: 0,
                boxShadow: "none",
                margin: "0px",
                width: "100%",
                padding: theme.spacing(2, 2, 1, 5)
            }}
        />
    )
}

interface SignUpProps {
    onlyUserInformation?: boolean,
    parameters?: AuthenticationFormParameters,
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}

/**
 * Signup component
 * Must be used only in an authentication modal
 */
const SignupPassword: React.FC<SignUpProps> = (props) => {

    const { onlyUserInformation, setOnModalCloseAction, parameters } = props

    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { messages } = useIntl();
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const { email_domains_allowed } = useTypedSelector((state: RootState) => state.authentication.data.location_authentication_config)
    const { order } = useTypedSelector((state: RootState) => state.order)
    const { register_success } = useTypedSelector((state: RootState) => state.authentication)
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    const initialValues: SignUpModel = {
        email: (order.customer && order.customer.email) ? order.customer.email : "",
        password: '',
        confirmPassword: '',
        phoneNumber: (order.customer && order.customer.phone) ? order.customer.phone : "",
        firstName: (order.customer && order.customer.first_name) ? order.customer.first_name : "",
        lastName: (order.customer && order.customer.last_name) ? order.customer.last_name : "",
        accept_terms: false,
        allow_marketing: false,
        onlyUserInformations: onlyUserInformation ? onlyUserInformation : false
    }
    const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
    const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

    const onSignUpFormClose = useCallback(() => {
        // TODO: end authentication process
        dispatch(AuthenticationActions.createUserSuccessReset());
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            parameters?.previousForm ?? CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
            parameters?.previousFormParameters
        ));
    }, []);

    useEffect(() => {
        // Register the on close action
        setOnModalCloseAction({
            formToDisplay: onlyUserInformation ? CustomerInformationModalFormToDisplay.MISSING_USER_INFORMATION : CustomerInformationModalFormToDisplay.SIGNUP_PASSWORD,
            onClose: onSignUpFormClose
        })
    }, [onSignUpFormClose, onlyUserInformation])

    const signUp = async (data: SignUpModel) => {
        setError(false)
        setErrorMessage(null)

        const res = await signUpToFirebase(data.email, data.password)

        if (typeof res === "string") { // If res is a string, it's the uid
            const uid = res
            data.uid = uid
            if (onlyUserInformation) {
                dispatch(AuthenticationActions.completeUserInformations(data))
            } else {
                dispatch(AuthenticationActions.createUser(data, history))
            }
        } else {
            const errorSignup = res as FirebaseErrorAuth
            setError(true)
            setErrorMessage(getFirebaseErrorId(errorSignup.errorCode))
        }
    }
    /**
    * check domain require customer info. type string[]
    */
    const validationSchema = yup.object({
        onlyUserInformations: yup
            .boolean(),
        email: yup
            .string()
            .max(70, messages['authentication.signin.email.validation.maxString70'] as string)
            .email(messages['authentication.signin.email.validation.emailFormat'] as string)
            .when('onlyUserInformations', {
                is: false,
                then: yup
                    .string()
                    .required(messages['authentication.signin.email.validation.emailRequired'] as string)
                    .test('domains', 'invalidDomain', function (value) {
                        if (email_domains_allowed && email_domains_allowed.length && value) {
                            const domain = email_domains_allowed.find(domain => value?.includes(domain))
                            if (domain) {
                                return true
                            } else {
                                const formParameters: AuthenticationErrorFormParameters = {
                                    errorMessageId: 'authentication.authentication_error.invalid_email_domain',
                                }
                                dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                                    CustomerInformationModalFormToDisplay.AUTHENTICATION_ERROR,
                                    formParameters,
                                ));
                                return false;
                            }
                        } else {
                            return true
                        }
                    })
            }),
        firstName: yup
            .string()
            .max(30, messages['authentication.signup.name.validation.maxString30'] as string)
            .required(messages['authentication.signup.name.validation.nameRequired'] as string),
        lastName: yup
            .string()
            .max(30, messages['authentication.signup.name.validation.maxString30'] as string)
            .required(messages['authentication.signup.name.validation.nameRequired'] as string),
        password: yup
            .string()
            .max(30, messages['authentication.signup.password.validation.maxString30'] as string)
            .when('onlyUserInformations', {
                is: false,
                then: yup
                    .string()
                    .required(messages['authentication.signup.password.validation.passwordRequired'] as string),
            }),
        confirmPassword: yup
            .string()
            .max(30, messages['authentication.signup.password.validation.maxString30'] as string)
            .when('onlyUserInformations', {
                is: false,
                then: yup
                    .string()
                    .required(messages['authentication.signup.password.validation.reTypePassword'] as string)
                    .oneOf(
                        [yup.ref('password')],
                        messages['authentication.signup.password.validation.passwordMisMatch'] as string,
                    ),
            }),
        phoneNumber: yup
            .string()
            .required(messages['authentication.signup.phone.validation.require'] as string),
        accept_terms: yup
            .bool()
            .required(messages['authentication.signup.phone.validation.accept_terms'] as string)
            .oneOf([true], messages['authentication.signup.phone.validation.accept_terms'] as string),
        allow_marketing: yup
            .boolean()
        //.required('validation.allow_to_receive_sms' as string)

    });
    if (selectedLocation) {

        return (
            <Box
                width={1}
                display='flex'
                flexDirection='column'
                data-test="customer.modal.signup"
            >

                <Box justifyContent="flex-end" display="flex">

                    <IconButton
                        style={{ padding: 0 }}
                        aria-label="close box"
                        onClick={onSignUpFormClose}
                        size="large">
                        <Close />
                    </IconButton>

                </Box>

                <Box textAlign="center">

                    <Typography variant="h2" color="textSecondary">
                        {intl.formatMessage({ id: onlyUserInformation ? "authentication.signup.missing_user_informations" : "signup" }, { br: <br /> })}
                    </Typography>

                </Box>

                <Box
                    paddingTop={{ xs: 1, sm: 3 }}
                    display='flex'
                    flexDirection='column'
                    alignItems="center"
                    justifyContent="center"
                    width={1}
                    paddingX={2}
                >

                    {error && errorMessage &&

                        <FormHelperText
                            style={{
                                alignSelf: 'flex-start',
                                marginBottom: theme.spacing(2)
                            }}
                            error
                        >

                            <Typography data-test={`signup_form.error.${errorMessage}`}>
                                {intl.formatMessage({ id: errorMessage })}
                            </Typography>

                        </FormHelperText>

                    }

                    {
                        // Display the form if not success
                        !register_success &&

                        <Formik<SignUpModel>
                            validateOnBlur={true}
                            validateOnChange={false}
                            validateOnMount={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (data, { setErrors, setSubmitting }) => {
                                log.info(`Submitting`)
                                setSubmitting(true)
                                await signUp(data)
                                //onSubmitSignUp(data)
                                setSubmitting(false)
                            }}
                        >

                            {({ isSubmitting }) => (

                                <Form
                                    noValidate={true}
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    autoComplete="off"
                                >

                                    <FormikTextInput
                                        inputProps={{
                                            "data-test": "signup_form.firstname"
                                        }}
                                        name="firstName"
                                        autoComplete="fname"
                                        label={intl.formatMessage({ id: "auth.first_name.inputplaceholder" })} />

                                    <FormikTextInput
                                        inputProps={{
                                            "data-test": "signup_form.lastname"
                                        }}
                                        name="lastName"
                                        autoComplete="lname"
                                        label={intl.formatMessage({ id: "auth.last_name.inputplaceholder" })} />

                                    {onlyUserInformation ? "" : <FormikTextInput
                                        inputProps={{
                                            "data-test": "signup_form.email"
                                        }}
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        label={intl.formatMessage({ id: "auth.email.inputplaceholder" })}
                                        style={{ marginTop: theme.spacing(1) }}
                                    />}

                                    <Box width="100%" display='flex' alignItems="center" justifyContent="center">
                                        <MyPhoneInput
                                            inputProps={{
                                                "data-test": "signup_form.phone"
                                            }}
                                            name="phoneNumber"
                                            countryCode={selectedLocation.country ?? DEFAULT_COUNTRY}
                                        />
                                    </Box>

                                    {onlyUserInformation ? "" :
                                        <>
                                            <FormikTextInput
                                                inputProps={{
                                                    "data-test": "signup_form.password"
                                                }}

                                                name="password"
                                                label={intl.formatMessage({ id: "auth.password.inputplaceholder" })}
                                                type={showPassword1 ? "text" : "password"}
                                                style={{ marginTop: theme.spacing(1) }}

                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword1}
                                                                size="large">
                                                                {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}

                                            />

                                            <FormikTextInput
                                                inputProps={{
                                                    "data-test": "signup_form.confirmPassword"
                                                }}

                                                name="confirmPassword"
                                                label={intl.formatMessage({ id: "auth.confirm_password.inputplaceholder" })}
                                                type={showPassword2 ? "text" : "password"}
                                                style={{ marginTop: theme.spacing(1) }}

                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword2}
                                                                size="large">
                                                                {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </>}

                                    <Box width="100%" mt={4} display="flex" alignItems="center">

                                        <FormikRoundedCheckBox
                                            inputProps={{
                                                "data-test": "signup_form.allow_marketing"
                                            }}

                                            style={{
                                                paddingLeft: 0,
                                            }}
                                            color="primary"
                                            name="allow_marketing"
                                        />

                                        <Typography style={{ color: theme.palette.text.disabled }}>
                                            {intl.formatMessage({ id: "authentication.signup.allow_marketing" })}
                                        </Typography>

                                    </Box>

                                    <Box width="100%" mt={2} display="flex" alignItems="center">

                                        <FormikRoundedCheckBox
                                            inputProps={{
                                                "data-test": "signup_form.accept_terms"
                                            }}

                                            style={{
                                                paddingLeft: 0,
                                            }}
                                            color="primary"
                                            name="accept_terms"
                                        />

                                        {/* Create link to redirect to terms of use */}
                                        <TermsAndConditionsTextWithModal />

                                    </Box>

                                    <Box>
                                        <ErrorMessage
                                            name='accept_terms'
                                            render={(errorMessage) => (
                                                <Typography variant="caption" color="error">
                                                    {errorMessage}
                                                </Typography>
                                            )}

                                        />
                                    </Box>

                                    <Box mt={4} display="flex" justifyContent="center">

                                        <Button
                                            data-test="signup_form.button.submit"
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                width: "100%",
                                                textTransform: "none",
                                            }}
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            {isSubmitting ?
                                                (
                                                    <CircularProgress
                                                        disableShrink
                                                        size="1rem"
                                                        style={{ color: theme.palette.primary.contrastText }}
                                                    />
                                                )
                                                : (
                                                    <Typography>
                                                        {intl.formatMessage({ id: onlyUserInformation ? "authentication.signup.button.complete_informations" : "signup" }, { br: <br /> })}
                                                    </Typography>
                                                )
                                            }

                                        </Button>

                                    </Box>

                                </Form>
                            )}
                        </Formik>

                    }

                    {
                        register_success &&

                        <Box
                            display="flex"
                            flexDirection="column"
                            textAlign="center"
                        >

                            <Typography style={{ margin: theme.spacing(4, 0) }}>

                                {intl.formatMessage({ id: "signup.success" })}

                            </Typography>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: "none",
                                    width: "100%"
                                }}
                                onClick={onSignUpFormClose}
                            >

                                <Typography>
                                    {intl.formatMessage({ id: "auth.my_account.login" })}
                                </Typography>

                            </Button>

                        </Box>
                    }

                </Box>

            </Box>
        );
    }
    return null
}
export default SignupPassword;
