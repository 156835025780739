import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FormHelperText, IconButton, InputAdornment, Typography, useTheme } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Form, Formik } from 'formik';
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import FormikTextInput from "../../Common/components/Formik/FormikTextInput";
import { cursor } from "../../Common/StyledComponents";
import { CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, CUSTOMER_INFORMATION_MODAL_TEXTINPUT_PADDING_X } from "../../customers/configs/CustomerInformationModalConfig";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import AuthenticationErrorFormParameters from "../models/AuthenticationErrorFormParameters";
import AuthenticationFormParameters from "../models/AuthenticationFormParameters";
import { FirebaseErrorAuth } from "../models/FirebaseErrorAuth";
import SignInModel from "../models/SignInModel";
import AuthenticationActions from "../redux/AuthenticationActions";
import { getFirebaseErrorId, signInToFirebase } from "../services/AuthenticationService";
import AuthenticationBottomMessages, { AuthenticationBottomMessage } from "./AuthenticationBottomMessages";

// TODO: review the styles, maybe convert the form to a simpler one as done in ServicePickupInfo.tsx

interface SignInProps {
    onSignInSuccess?: (userCreated?: boolean) => void;
    buttonTitleId?: string;
}

/**
 * Signin component
 * Must be used only in an authentication modal
 */
const SigninPassword: React.FC<SignInProps> = (props) => {

    const { onSignInSuccess, buttonTitleId } = props

    const intl = useIntl();
    const dispatch = useDispatch();

    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const { data } = useTypedSelector((state: RootState) => state.authentication)
    const { formToDisplay, formParameters } = useTypedSelector((state: RootState) => state.customerInformationModal)
    const { order } = useTypedSelector((state: RootState) => state.order);
    const theme = useTheme();

    const initialValues: SignInModel = {
        email: (order.customer && order.customer.email) ? order.customer.email : "",
        password: ''
    }

    const onForgetPasswordClick = () => {
        const authenticationFormParameters: AuthenticationFormParameters = {
            previousForm: formToDisplay ?? undefined,
            previousFormParameters: formParameters,
        }
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            CustomerInformationModalFormToDisplay.FORGET_PASSWORD,
            authenticationFormParameters
        ));
    }

    const signIn = async (data: SignInModel) => {
        setError(false)
        setErrorMessage(null)
        const res = await signInToFirebase(data.email, data.password)
        if ((res as FirebaseErrorAuth).errorCode) {
            setError(true)
            setErrorMessage(getFirebaseErrorId((res as FirebaseErrorAuth).errorCode))
        } else {
            if (onSignInSuccess) {
                onSignInSuccess()
            }

            dispatch(AuthenticationActions.firebaseSigninSuccess());
            dispatch(AuthenticationActions.loadUserInfo())
        }
    }

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .max(70, "authentication.signin.email.validation.maxString70")
            .email("authentication.signin.email.validation.emailFormat")
            .required("authentication.signin.email.validation.emailRequired")
            .test('domains', 'validation', function (value) {
                if (data.location_authentication_config.email_domains_allowed && data.location_authentication_config.email_domains_allowed.length && value) {
                    const domain = data.location_authentication_config.email_domains_allowed.find(domain => value?.includes(domain))
                    if (domain) {
                        return true
                    } else {
                        const formParameters: AuthenticationErrorFormParameters = {
                            errorMessageId: 'authentication.authentication_error.invalid_email_domain',
                        };
                        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                            CustomerInformationModalFormToDisplay.AUTHENTICATION_ERROR,
                            formParameters,
                        ));
                        return false
                    }
                } else {
                    return true
                }
            }),
        password: yup
            .string()
            .max(30, "authentication.signin.password.validation.maxString30")
            .required("authentication.signin.password.validation.passwordRequired")
    });



    return (
        <Box
            width="100%"
            display='flex'
            flexDirection='column'
            data-test="customer.modal.signin"
        >

            <Box
                display='flex'
                paddingTop={3}
                flexDirection='column'
                alignItems="center"
                justifyContent="center"
                width="100%"
            >
                {error && errorMessage &&

                    <FormHelperText
                        style={{
                            alignSelf: 'flex-start',
                            marginBottom: theme.spacing(2),
                        }}
                        error
                    >

                        <Typography>
                            {intl.formatMessage({ id: errorMessage })}
                        </Typography>

                    </FormHelperText>
                }

                <Formik<SignInModel>
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (data, { setErrors, setSubmitting }) => {
                        setSubmitting(true)
                        await signIn(data)
                        setSubmitting(false)
                    }}
                >

                    {
                        ({ isSubmitting }) => (

                            <Form
                                style={{ display: 'flex', flexDirection: 'column', width: "100%" }}
                                autoComplete="off"
                            >
                                <Box display="flex" flexDirection="column" width={1}
                                    alignItems="center"
                                    paddingX={CUSTOMER_INFORMATION_MODAL_TEXTINPUT_PADDING_X}>
                                    <FormikTextInput
                                        inputProps={{
                                            "data-test": "customer-modal.signin.email"
                                        }}
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        color="primary"
                                        label={intl.formatMessage({ id: "auth.email.inputplaceholder" })}
                                        fullWidth
                                        style={{
                                            marginBottom: theme.spacing(1.5)
                                        }}
                                    />

                                    <FormikTextInput
                                        inputProps={{
                                            "data-test": "customer-modal.signin.password"
                                        }}
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        label={intl.formatMessage({ id: "auth.password.inputplaceholder" })}
                                        fullWidth

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        style={{ color: theme.palette.text.disabled }}
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        size="large">
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>

                                <Box data-test="signin.forget_password.link" textAlign="end" width="100%" mt={1} onClick={onForgetPasswordClick}>

                                    <Typography
                                        variant="caption"
                                        style={{
                                            ...cursor,
                                            color: theme.palette.text.disabled,
                                        }}
                                    >
                                        {intl.formatMessage({ id: "password_forgotten" }, { br: <br /> })}
                                    </Typography>

                                </Box>

                                <Box mt={2} display="flex" justifyContent="center">

                                    <Button
                                        data-test='signin_button'
                                        color="primary"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        style={{
                                            width: "100%",
                                            textTransform: "none",
                                            padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                                        }}
                                        type="submit"
                                    >
                                        {
                                            isSubmitting ?
                                                (
                                                    <CircularProgress
                                                        data-test='signin_loader'
                                                        disableShrink
                                                        size="1rem"
                                                        style={{ color: theme.palette.primary.contrastText }}
                                                    />
                                                ) : (
                                                    <Typography variant="subtitle1">
                                                        {intl.formatMessage({ id: buttonTitleId ? buttonTitleId : "auth.my_account.login" })}
                                                    </Typography>
                                                )
                                        }
                                    </Button>

                                </Box>
                            </Form>
                        )
                    }

                </Formik>

                <AuthenticationBottomMessages
                    messagesToDisplay={[AuthenticationBottomMessage.NO_ACCOUNT_SIGN_UP]}
                />
            </Box>
        </Box>
    );
}

export default SigninPassword;
