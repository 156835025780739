import DefaultBackground from "../../assets/bg_home.png";
import { isTemporallyAvailableForOrder } from "../../my-lemonade-library/functions/Helpers";
import { Catalog, getTimezoneName, Product } from "../../my-lemonade-library/model/Catalog";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import DealExt from "../../my-lemonade-library/model/DealExtended/DealExt";
import { Order, OrderItem, OrderOption } from "../../my-lemonade-library/model/Order";
import { ProductExtended, SkuExtended } from "../../my-lemonade-library/model/ProductExtended/ProductExtended";
import { addMoney, Money, multiplyMoney, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { consolidateOptionsInfo } from "../../my-lemonade-library/src/orders/services/OrderPricing";

class ProductService {
    addOneQuantity(itemQuantity: number): number {
        return itemQuantity + 1
    }

    removeOneQuantity(itemQuantity: number): number {
        return itemQuantity - 1
    }

    getProductPriceWithOptions(selectedCatalog: CatalogExtended, selectedProduct: Product, sku: SkuExtended, options: OrderOption[], quantity: number): Money {
        if (!selectedCatalog || !sku.price) {

            return "";
        }
        const quantityProduct = quantity
        const tempItem: OrderItem = {
            product_ref: selectedProduct.ref,
            product_name: selectedProduct.name,
            options: options,
            price: selectedProduct.skus[0].price,
            quantity: quantityProduct,
            sku_ref: sku.ref
        }

        const optionsPrice: Money = numberToMoney(
            consolidateOptionsInfo(tempItem, selectedProduct, selectedCatalog.data.option_lists, selectedCatalog.data.options),
            selectedCatalog.currency
        );

        const skuPriceMaybeReduced: Money = sku.reduced_price ? sku.reduced_price : sku.price

        const finalPricePerItem: Money = addMoney(
            optionsPrice,
            skuPriceMaybeReduced
        )

        const finalPrice: Money = multiplyMoney(finalPricePerItem, quantityProduct);

        return finalPrice;

    }

    /**
     * Return if the product can be displayed based on the disable field of a product
     * and the restrictions.
     * @param order 
     * @param product 
     * @param selectedCatalog 
     * @returns 
     */
    canDisplayItem(order: Order, product: ProductExtended, selectedCatalog: CatalogExtended): boolean {
        let temporallyAvailable = true
        if (product.restrictions) {
            temporallyAvailable = isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), product.restrictions, false)
        }
        return Boolean(
            (!product.disable || product.display_if_disabled)
            && temporallyAvailable
        );
    }

    getProdImage = (product: Product | DealExt, catalog: Catalog) => {
        if (product.image) {
            return product.image
        } else if (catalog) {
            const productCategory = catalog.data.categories.find(cat => cat.ref === product.category_ref)
            let image = productCategory?.icon
            if (!image && productCategory?.parent_ref) {
                image = catalog.data.categories.find(cat => cat.ref === productCategory.parent_ref)?.icon
            }
            return image ? image : DefaultBackground
        } else {
            return DefaultBackground
        }
    }
}

const productService = new ProductService();

export default productService;