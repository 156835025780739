import { SupportedServiceType } from "../../../model/Location";
import { Order } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import { Money, getCurrency, moneyToNumber, numberToMoney } from "../../common/models/Money";
import { log, removeLogInPreprodOrProd } from "../../common/services/LogService";
import { OrderTax } from "../models/OrderTax";
import { Tax } from "../models/Tax";
import TaxError from "../models/TaxError";
import { intermediateTaxRate } from "../samples/SampleTaxRates";

class TaxHelper {

    getTaxRate = (tax: Tax, serviceType?: SupportedServiceType) => {
        const taxOverrides = serviceType ? tax.percentage_overrides?.find((taxOverride) => taxOverride.service_types?.includes(serviceType)) : undefined;
        if (taxOverrides) {
            return taxOverrides.percentage;
        } else {
            return tax.percentage;
        }
    }

    addTax(dataWithTax: { taxes?: Tax[] }, taxPercentage: number, serviceType?: SupportedServiceType, taxName?: string): string {
        const tax = dataWithTax.taxes?.find((tax) => this.getTaxRate(tax, serviceType) === taxPercentage)
        if (tax) {
            return tax.ref
        } else {
            const taxRef = `tax${taxPercentage}`;
            if (!dataWithTax.taxes) {
                dataWithTax.taxes = [];
            }
            dataWithTax.taxes.push({
                ref: taxRef,
                name: taxName ?? `Tax ${taxPercentage}`,
                percentage: taxPercentage
            })
            return taxRef;
        }
    }

    computeTax(priceTTC: number, tva?: number): number {
        return tva ? tva * priceTTC / (100 + tva) : 0;
    }
    /**
     * Compute the order taxes and affect it
     * WARNING: the order is supposed to have subtotal field
     * @param order
     */
    computeOrderTaxes(order: Order, dataWithTax: {
        currency: string;
        taxes?: Tax[];
    }) {

        let defaultTaxFound = dataWithTax.taxes?.find((tax) => tax.default);
        let defaultTax: Tax;
        if (!defaultTaxFound) {
            if (dataWithTax.taxes && dataWithTax.taxes.length) {
                defaultTax = dataWithTax.taxes[0];
            } else {
                dataWithTax.taxes = [intermediateTaxRate];
                defaultTax = intermediateTaxRate;
            }
        } else {
            defaultTax = defaultTaxFound;
        }

        const taxPerRef: { [key: string]: number } = {};
        // Compute item taxes
        order.items?.forEach((orderItem) => {
            if (orderItem.subtotal) {

                if (orderItem.tax_rate === undefined || orderItem.tax_rate === null) {
                    // Use default tax rate
                    orderItem.tax_rate = taxHelper.getTaxRate(defaultTax, order.service_type);
                    orderItem.tax_ref = defaultTax.ref;
                } else if (!orderItem.tax_ref) {
                    orderItem.tax_ref = this.addTax(dataWithTax, orderItem.tax_rate, order.service_type);
                }
                const taxRef = orderItem.tax_ref!;
                const subtotal = moneyToNumber(orderItem.subtotal);
                const subtotalTax = Math.round(this.computeTax(subtotal, orderItem.tax_rate) * 100);
                if (!taxPerRef[taxRef]) {
                    taxPerRef[taxRef] = subtotalTax;
                } else {
                    taxPerRef[taxRef] = taxPerRef[taxRef] + subtotalTax;
                }
                orderItem.tax_price = numberToMoney(subtotalTax, dataWithTax.currency, true);
            } else {
                throw OrderError.TAX_NULL_ORDER_ITEM_SUBTOTAL.withValue({
                    order_id: order.id,
                    order_item_ref: orderItem.sku_ref
                });
            }
        });
        // Compute charge taxes
        order.charges?.forEach((charge) => {
            if (charge.price) {
                // Warning, we can have 0 tax for tips for instance
                if (charge.tax_rate === undefined || charge.tax_rate === null) {
                    charge.tax_rate = taxHelper.getTaxRate(defaultTax, order.service_type);
                    charge.tax_ref = defaultTax.ref;
                } else if (!charge.tax_ref) {
                    // try to set the tax ref from the catalog
                    charge.tax_ref = this.addTax(dataWithTax, charge.tax_rate, order.service_type);
                }
                const taxRef = charge.tax_ref!;
                const price = moneyToNumber(charge.price);
                const taxPrice = Math.round(this.computeTax(price, charge.tax_rate) * 100);
                charge.tax_price = numberToMoney(taxPrice, dataWithTax.currency, true);
                if (!taxPerRef[taxRef]) {
                    taxPerRef[taxRef] = taxPrice;
                } else {
                    taxPerRef[taxRef] = taxPerRef[taxRef] + taxPrice;
                }
            }
        });

        // Compute discount taxes
        order.discounts?.forEach((discount) => {
            if (discount.price_off) {
                if (discount.tax_rate === undefined || discount.tax_rate === null) {
                    discount.tax_rate = taxHelper.getTaxRate(defaultTax, order.service_type);
                    discount.tax_ref = defaultTax.ref;
                } else if (!discount.tax_ref) {
                    // try to set the tax ref from the catalog
                    discount.tax_ref = this.addTax(dataWithTax, discount.tax_rate, order.service_type);
                }
                const taxRef = discount.tax_ref!;
                const price = moneyToNumber(discount.price_off);
                const taxPrice = Math.round(this.computeTax(price, discount.tax_rate) * 100);
                discount.tax_price = numberToMoney(taxPrice, dataWithTax.currency, true);
                if (!taxPerRef[taxRef]) {
                    taxPerRef[taxRef] = -1 * taxPrice;
                } else {
                    taxPerRef[taxRef] = taxPerRef[taxRef] - taxPrice;
                }
            }
        })

        // Add the taxes total to the order
        order.taxes = [];
        for (let taxRef in taxPerRef) {
            const foundTax = dataWithTax.taxes?.find((tax) => tax.ref === taxRef);
            if (foundTax) {
                const foundTaxRate = taxHelper.getTaxRate(foundTax, order.service_type);
                if (foundTaxRate) { // Do not add if 0
                    const orderTax: OrderTax = {
                        name: (foundTax && foundTax.name) ? foundTax.name : "",
                        ref: taxRef,
                        percentage: this.getTaxRate(foundTax, order.service_type),
                        price: numberToMoney(taxPerRef[taxRef], dataWithTax.currency, true)
                    };
                    order.taxes.push(orderTax);
                }
            } else {
                removeLogInPreprodOrProd(`Tax ${taxRef} not found`);
            }
        }
    }
    /**
     * Log a warning if the tax rate is between 0 and 1
     * https://entreprendre.service-public.fr/simulateur/calcul/convertisseurPrixHTouTTC
     * @param priceTTC 
     * @param taxRate // Has to be between 0 and 100
     * @returns 
     */
    computeProductPriceHT(priceTTC: Money, taxRate: number): Money {
        if (taxRate > 0 && taxRate < 1) {
            log.warn(`Tax rate ${taxRate} is between 0 and 1, did you mean to put ${taxRate * 100} ?`);
        }

        if (taxRate < 0 || taxRate > 100) {
            throw TaxError.INVALID_TAX_RATE.withValue(taxRate);
        }
        const priceHT = moneyToNumber(priceTTC) / (1 + taxRate / 100);
        return numberToMoney(priceHT, getCurrency(priceTTC));

    }

    computePriceTTC(priceHT: Money, taxRate: number): Money {
        if (taxRate > 0 && taxRate < 1) {
            log.warn(`Tax rate ${taxRate} is between 0 and 1, did you mean to put ${taxRate * 100} ?`);
        }
        if (taxRate < 0 || taxRate > 100) {
            throw TaxError.INVALID_TAX_RATE.withValue(taxRate);
        }
        const priceTTC = moneyToNumber(priceHT) * (1 + taxRate / 100);
        return numberToMoney(priceTTC, getCurrency(priceHT));
    }
}
const taxHelper = new TaxHelper();
export default taxHelper;