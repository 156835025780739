import log from "loglevel";
import { deviceDetect } from 'react-device-detect';
import parser from 'ua-parser-js';
import { MyLemonadeApplications } from "../../my-lemonade-library/model/Common/MyLemonadeApplications";
import DeviceInfo from "../../my-lemonade-library/src/authentications/models/DeviceInfo";
import DeviceDetectInfo from "../../my-lemonade-library/src/common/models/DeviceDetectInfo";
const browserInfo: DeviceDetectInfo = deviceDetect() as DeviceDetectInfo;

export const getDeviceDetectInfo = (): DeviceInfo | null => {
    const fullDeviceInfo: DeviceInfo = {
        user_agent: window.navigator.userAgent,
        uuid: window.navigator.userAgent,
        app_id: MyLemonadeApplications.WEBAPP,
        app_version: process.env.REACT_APP_VERSION,
        app_build: process.env.REACT_APP_COMMIT_SHORT_SHA
    };
    if (browserInfo) {
        log.debug(`Detected device info`, browserInfo);
        if (browserInfo.mobileModel || browserInfo.model) {
            fullDeviceInfo.model = browserInfo.mobileModel ? browserInfo.mobileModel : browserInfo.model;
            fullDeviceInfo.uuid = fullDeviceInfo.model;
        }
        if (browserInfo.mobileVendor || browserInfo.vendor) {
            fullDeviceInfo.manufacturer = browserInfo.mobileVendor ? browserInfo.mobileVendor : browserInfo.vendor;
        }
        if (browserInfo.osVersion) {
            fullDeviceInfo.os_version = browserInfo.osVersion;
        }
        if (browserInfo.osName) {
            fullDeviceInfo.operating_system = browserInfo.osName;
        }
        if (browserInfo.os) {
            fullDeviceInfo.operating_system = browserInfo.os;
        }
        if (browserInfo.browserFullVersion) {
            fullDeviceInfo.browser_full_version = browserInfo.browserFullVersion;
        }
        if (browserInfo.fullBrowserVersion) {
            fullDeviceInfo.browser_full_version = browserInfo.fullBrowserVersion;
        }
        if (browserInfo.browserName) {
            fullDeviceInfo.browser_name = browserInfo.browserName;
        }
        if (browserInfo.userAgent || browserInfo.ua) {
            fullDeviceInfo.user_agent = browserInfo.userAgent ? browserInfo.userAgent : browserInfo.ua;
            if (!fullDeviceInfo.uuid) {
                fullDeviceInfo.uuid = fullDeviceInfo.user_agent;
            }
        }
    }
    const parserResult = new parser.UAParser(window.navigator.userAgent);
    if (parserResult) {
        const browser = parserResult.getBrowser();
        if (browser) {
            if (browser.name) {
                fullDeviceInfo.browser_name = browser.name;
            }
            if (browser.version) {
                fullDeviceInfo.browser_full_version = browser.version;
            }
        }
    }


    return fullDeviceInfo;
}
