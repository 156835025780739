import { LoyaltyActionTypes, TOGGLE_LOCAL_LOYALTY_USE_POINTS, UPDATE_LOYALTY_INFORMATION } from "./LoyaltyActions";
import { LoyaltyState } from "./models/LoyaltyState";

const loyaltyInitialState: LoyaltyState = {
    estimatedLoyaltyConfigContent: undefined,
    estimatedLoyaltyOrderContent: undefined,
    use_points: undefined,
}

export default function loyaltyReducer(
    state: LoyaltyState = loyaltyInitialState,  // Default value
    action: LoyaltyActionTypes,
): LoyaltyState {

    switch (action.type) {

        case UPDATE_LOYALTY_INFORMATION: {
            return {
                ...state,
                estimatedLoyaltyConfigContent: action.payload.estimatedLoyaltyConfigContent,
                estimatedLoyaltyOrderContent: action.payload.estimatedLoyaltyOrderContent,
            }
        }

        case TOGGLE_LOCAL_LOYALTY_USE_POINTS: {
            return {
                ...state,
                use_points: action.payload.use_points,
            }
        }

        default: return state;
    }
}