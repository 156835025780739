import _ from 'lodash'
import { useEffect, useState } from 'react'
import { shouldDisplayCategory, subCategoriesList } from '../../Categories/services/categoriesServices'
import { Categorie, CategoriesAndProducts } from '../../my-lemonade-library/model/Catalog'
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt'
import { SignInProviders } from '../../my-lemonade-library/src/authentications/models/BaseUser'
import { CatalogEntityType } from '../../my-lemonade-library/src/catalogs/models/CatalogEntity'
import { checkRestrictionAndGetDealNumberOfUsagesLeft } from '../../my-lemonade-library/src/deals/services/DealHelper'
import { RootState, useTypedSelector } from '../../redux/root-reducer'

/**
 * Update the categoriesAndProducts array from the store.
 * If a category is not availiable with current service type, it won't show
 * @param rootCategories 
 * @returns CategoriesAndProducts[]
 */
const useCategoriesAndProducts = (rootCatRef?: string | undefined): CategoriesAndProducts[] => {

    const { selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations)
    const { data } = useTypedSelector((state: RootState) => state.authentication)
    const { order } = useTypedSelector((state: RootState) => state.order)

    const [categoriesAndProducts, setCategoriesAndProducts] = useState<CategoriesAndProducts[]>([])

    const provider = data.user_authentication_state.provider
    const user = data.user_authentication_state.user


    // Update categories and products to remove categories and products that are not available
    useEffect(() => {
        if (selectedCatalog) {

            let rootCategories: Categorie[] = []
            let subCatsAvailable: Categorie[] = []

            // Getting all the sub categories available
            if (!rootCatRef || rootCatRef === '') {
                const categoriesList = selectedCatalog.data.mainCategories as Categorie[];


                subCatsAvailable = categoriesList.reduce((acc: Categorie[], current) => {

                    const subCats = subCategoriesList(order, current, selectedCatalog, selectedTable)

                    if (subCats.length > 0) {
                        subCats.forEach(cat => acc.push(cat))
                    }
                    return acc
                }, [])

                // Getting only the sub categories related to the rootCatRef available
            } else {
                const subCategoriesList = selectedCatalog?.data.categories as Categorie[];
                subCatsAvailable = subCategoriesList.filter((category) => shouldDisplayCategory(order, category, selectedCatalog, rootCatRef, selectedTable));
            }

            // Getting all the main categories available
            const rootCategoriesList = selectedCatalog?.data.categories as Categorie[];
            rootCategories = rootCategoriesList.filter((category) => shouldDisplayCategory(order, category, selectedCatalog, undefined, selectedTable));


            if (rootCategories && rootCategories.length > 0) {
                const filteredRootCats = rootCatRef
                    ? rootCategories.filter(cat => cat.ref === rootCatRef)
                    : _.cloneDeep(rootCategories)

                const tempCategoriesAndProducts: CategoriesAndProducts[] = _.cloneDeep(selectedCatalog.data.categoriesAndProducts ?? [])

                const finalCategoriesAndProducts: CategoriesAndProducts[] =
                    tempCategoriesAndProducts.reduce((acc: CategoriesAndProducts[], current) => {
                        const mainCat = filteredRootCats.find(cat => cat.ref === current.mainCat) !== undefined


                        if (subCatsAvailable
                            && current.subCat
                            && subCatsAvailable.find(cat => cat.ref === current.subCat) !== undefined
                            && mainCat
                        ) {
                            acc.push(current)

                        } else if (mainCat && !current.subCat) {
                            acc.push(current)
                        }

                        return acc
                    }, [])

                // Filter to remove the unavailable deals
                const catsAndProdsWithDealsFiltered = finalCategoriesAndProducts.filter(catAndProd => {
                    if (catAndProd.type === CatalogEntityType.DEAL) {
                        if ((catAndProd.data as DealExt).usage_restriction?.authentication_providers) {
                            if (!provider) {
                                return false
                            }
                            const isAvailable = (catAndProd.data as DealExt).usage_restriction?.authentication_providers?.includes(provider as SignInProviders)
                            if (!isAvailable) {
                                return false
                            }
                        }

                        if ((catAndProd.data as DealExt).usage_restriction?.max_per_customer) {
                            if (!user) {
                                return false
                            }
                            const usagesLeft: number = checkRestrictionAndGetDealNumberOfUsagesLeft(
                                catAndProd.data as DealExt,
                                user,
                                selectedCatalog.id
                            )
                            if (usagesLeft <= 0) {
                                return false
                            }
                        }
                    }

                    return true
                })

                setCategoriesAndProducts(catsAndProdsWithDealsFiltered)
            }
        }
    }, [selectedCatalog])

    return categoriesAndProducts
}

export default useCategoriesAndProducts