import _ from "lodash";
import { RedirectUrl, RedirectUrlFrom, RedirectUrlType } from "../models/RedirectUrlModels";

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export interface setRedirectURLAction {
    type: typeof SET_REDIRECT_URL;
    payload: {
        redirect: RedirectUrl | null;
    }
}

export const ADD_URL_TO_NAVIGATION_HISTORY = "ADD_URL_TO_NAVIGATION_HISTORY";
export interface addUrlToNavigationHistoryAction {
    type: typeof ADD_URL_TO_NAVIGATION_HISTORY;
    payload: {
        url: string;
    }
}

export type CommonActionsType = setRedirectURLAction | addUrlToNavigationHistoryAction;

export const CommonActions = {

    /**
     * Set the URL to redirect to. Can be null (= no redirect)
     * @param replace if true replace the url in history
     * @returns 
     */
    setRedirectURL: (
        url: string | null,
        replace?: boolean,
        type?: RedirectUrlType,
        from?: RedirectUrlFrom,
    ): setRedirectURLAction => ({
        type: SET_REDIRECT_URL,
        payload: {
            redirect: _.isNil(url) ? null : {
                url,
                replaceHistory: Boolean(replace),
                type,
                from,
            }
        },
    }),

    /**
     * Adds the given URL to the navigation history in state (and in the session in DB)
     * @param url
     * @returns 
     */
    addUrlToNavigationHistory: (url: string): addUrlToNavigationHistoryAction => ({
        type: ADD_URL_TO_NAVIGATION_HISTORY,
        payload: {
            url,
        },
    })
}

