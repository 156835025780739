import _ from "lodash";
import { DateTime } from "luxon";
import { SupportedServiceType } from "../../../model/Location";
import { Restriction } from "../model/Restriction";

export const isValidDow = (dow: string | undefined): boolean => {

    const dowRegex = /^(1|-)(2|-)(3|-)(4|-)(5|-)(6|-)(7|-)$/
    return Boolean(_.isNil(dow) || dowRegex.test(dow));
}

export const isValidTime = (time: string | undefined): boolean => {

    const timeRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
    return Boolean(_.isNil(time) || timeRegex.test(time));
}

/**
 * Given the current time, returns the next available time according to the restrictions.
 * @param time 
 * @param restrictions 
 * @returns 
 */
export const getNextAvailableTime = (
    restrictions: Restriction[],
    time: DateTime,
    serviceType: SupportedServiceType | undefined,
): DateTime | null => {

    const timezoneName = time.zoneName;
    let nextAvailableTime: DateTime | null = null;

    restrictions.forEach((restriction) => {

        const startDate = getNextStartDateOfRestriction(restriction, timezoneName, time);

        if (
            !startDate
            || (
                restriction.service_types
                && serviceType
                && !restriction.service_types?.includes(serviceType)
            )
        ) {
            return;
        }

        if (!nextAvailableTime || startDate < nextAvailableTime) {
            nextAvailableTime = startDate.startOf("minute");
        }
    });

    return nextAvailableTime;
}

/**
 * If the restriction has a start_date and/or a start_time, returns the corresponding DateTime.
 * WARN: if there is a dow, can return multiple dates (the next week start dates)
 * NOTE: if only start_date, the time will be set to 00:00:00.
 * NOTE: if only start_time, the date will be set to today.
 * @param restriction 
 * @param timezoneName 
 * @returns 
 */
export const getNextStartDateOfRestriction = (
    restriction: Restriction,
    timezoneName: string,
    now: DateTime,
): DateTime | null => {

    // Neither start date nor start time nor dow defined, return null
    if (
        !restriction.start_date
        && !restriction.start_time
        && (
            !restriction.dow
            || !isValidDow(restriction.dow)
        )
    ) {
        return null;
    }

    let startDate: DateTime | null = null;

    /////////////////
    // START DATE in restriction
    /////////////////

    if (restriction.start_date) {

        startDate = DateTime.fromISO(restriction.start_date, { zone: timezoneName });

        if (startDate < now) {
            startDate = now.startOf("day");
        }
    }
    // No start date
    else {
        startDate = now.startOf("day");
    }

    /////////////////
    // START TIME
    /////////////////
    if (restriction.start_time) {

        const [hours, minutes] = restriction.start_time.split(':');
        startDate = startDate
            .set({ hour: parseInt(hours), minute: parseInt(minutes) })
            .startOf("minute");
    }

    /////////////////
    // DOW
    /////////////////

    if (restriction.dow && isValidDow(restriction.dow)) {

        if (
            !restriction.dow.includes(startDate.weekday.toString())
            || startDate < now
        ) {

            let foundDow = false;
            for (let i = 1; i <= 7; i++) {
                const nextDay = startDate.plus({ days: i });
                if (restriction.dow.includes(nextDay.weekday.toString())) {
                    startDate = nextDay;
                    foundDow = true;
                    break;
                }
            }

            if (!foundDow) {
                return null;  // Dow was empty?? ("-------")
            }
        }
    }
    else if (startDate < now) {
        startDate = startDate.plus({ days: 1 });
    }

    return startDate;
}