import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router';
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from '../../customers/redux/CustomerInformationModalActions';
import { ERROR_QUERY_PARAM_NAME } from '../../my-lemonade-library/src/common/configs/CommonApiRoutes';
import AuthenticationErrorFormParameters from "../models/AuthenticationErrorFormParameters";

interface AuthenticationErrorProps {
    parameters: AuthenticationErrorFormParameters,
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; }) => void,
}

const AuthenticationError: React.FC<AuthenticationErrorProps> = (props) => {

    const { errorMessageId } = props.parameters;
    const intl = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const onFinalAction = useCallback(() => {

        // Remove error from query parameters
        const queryParams = new URLSearchParams(location.search)
        if (queryParams.has(ERROR_QUERY_PARAM_NAME)) {
            queryParams.delete(ERROR_QUERY_PARAM_NAME)
            history.replace({
                search: queryParams.toString(),
            })
        }

        // TODO: redirect_url and other form parameters
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
        ));
    }, []);

    useEffect(() => {
        props.setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.AUTHENTICATION_ERROR, onClose: onFinalAction });
    }, [onFinalAction])

    return (
        <Box
            display="flex"
            flexDirection='column'
            width="100%"
            data-test="customer.modal.authentication_error"
        >

            <Box>
                <Box textAlign="center" p={2}>
                    <Typography variant="h2" color="textSecondary">
                        {intl.formatMessage({ id: "authentication.authentication_error.title" })}
                    </Typography>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                paddingX={2}
            >

                <Box alignSelf="center">
                    <Typography variant="body1" style={{ textAlign: "center" }}>
                        {errorMessageId ? intl.formatMessage({ id: errorMessageId }) : ""}
                    </Typography>
                </Box>

                <Box mt={4} display="flex" justifyContent="center">

                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            width: "100%",
                            textTransform: "none",
                        }}
                        onClick={onFinalAction}
                    >

                        <Typography variant="h5">
                            {intl.formatMessage({ id: "authentication.authentication_error.button" })}
                        </Typography>

                    </Button>

                </Box>
            </Box>
        </Box>
    );
}

export default AuthenticationError;