import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ReplyIcon from '@mui/icons-material/Reply';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import DefaultBackground from "../../assets/bg_home.png";
import { Deal, Product } from '../../my-lemonade-library/model/Catalog';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { cursor } from '../StyledComponents';

interface DisplayHeaderProps {
    selectedProductOrDeal: Product | Deal,
    closeModal?: () => void, // Used if component is in modal
    routeToGoBack?: string
    backToTitle?: string
}
/**
 * Header of the productModal when user select a product
 * @param param0 
 */
const DisplayHeader: React.FC<DisplayHeaderProps> = (props) => {

    const { selectedProductOrDeal, closeModal, routeToGoBack, backToTitle = "" } = props

    const { selectedLocation, selectedCatalog } = useTypedSelector((state: RootState) => state.locations)

    const theme = useTheme();
    const smView = useMediaQuery(theme.breakpoints.up("sm"))
    const history = useHistory()


    const chooseBackGroundImage = (entity: Product | Deal) => {
        if (smView) {
            return undefined
        }
        if (entity.image) {
            return entity.image
        } else if (selectedCatalog) {
            const image = selectedCatalog.data.categories.find(cat => cat.ref === entity.category_ref)?.icon
            return image ? image : DefaultBackground
        } else if (selectedLocation && selectedLocation.background_image) {
            return selectedLocation.background_image
        } else {
            return DefaultBackground
        }
    }

    const handleClick = (): void => {
        if (closeModal) {
            closeModal()
        } else if (routeToGoBack) {
            history.push(routeToGoBack)
        }
    }

    if (selectedLocation) {

        return (
            <Box>
                <Box my={0.5} display='flex' flexDirection='row' justifyContent='space-between'>
                    <IconButton
                        data-test="header_arrow_back_button"
                        onClick={() => handleClick()}
                        style={{ ...cursor }}
                        size="large">
                        <ArrowBackIosRoundedIcon sx={{ color: theme.palette.secondary.main }} />
                        <Box display='flex' alignItems='center' ml={1}>
                            <Typography variant='h5' color='secondary'>
                                {backToTitle}
                            </Typography>
                        </Box>
                    </IconButton>

                    {   // Reply button is currently hidden because useless
                        // Remove ternary to use
                        false &&
                        <IconButton
                            onClick={() => null}
                            style={{
                                ...cursor,
                                transform: 'scaleX(-1)'
                            }}
                            size="large">
                            <ReplyIcon fontSize='large' />
                        </IconButton>
                    }
                </Box>
                <img
                    src={chooseBackGroundImage(selectedProductOrDeal)}
                    alt={selectedProductOrDeal.name}
                    style={{
                        height: '250px',
                        width: '100%',
                        objectFit: 'cover'
                    }}
                />
            </Box >
        );
    }

    return null
}

export default DisplayHeader;