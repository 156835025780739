import { getDistance } from 'geolib';
import { DateTime } from "luxon";
import { Location, SupportedServiceType } from "../../../model/Location";
import { Order, OrderStatus } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import Address from "../../common/models/Address";
import { Money, moneyToNumber } from "../../common/models/Money";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import Position from "../../common/models/Position";
import OrderTimeSlot from "../../orders/models/OrderTimeSlot";
import { getOrderFirestoreDocPath } from "../../orders/services/OrderService";
import { TIME_FORMAT } from "../../restrictions/services/RestrictionsService";
import { DEFAULT_DELIVERY_DELAY, DEFAULT_DELIVERY_TIME_UNIT } from "../configs/DefaultDeliveryVariable";
import DeliveryErrors from "../models/DeliveryErrors";
import DeliveryProviderRange from "../models/DeliveryProviderRange";
import DeliveryProviderType from "../models/DeliveryProviderType";
import DeliveryStatus from "../models/DeliveryStatus";
import DeliveryZone from "../models/DeliveryZone";
import LocationDeliveryProvider from "../models/LocationDeliveryProvider";


export const getDeliveryLogFirestoreCollectionPath = (accountId: string, locationId: string, orderId: string): string => {
    return `${getOrderFirestoreDocPath(accountId, locationId, orderId)}/${MylemonadeScopes.DELIVERY}`;
}

const deliveryHelper = {

    computeEndPreparationTime(order: Order, timezoneName: string, deliveryZones: DeliveryZone[]): Date | null {

        if (order.service_type === SupportedServiceType.DELIVERY) {

            if (!order.expected_time) {

                throw OrderError.EXPECTED_TIME_REQUIRED
            }

            const expectedTime = new Date(order.expected_time)
            const deliveryDelay = this.getDeliveryDelayFromOrder(order, deliveryZones)

            if (deliveryDelay) {
                if (order.delivery) {
                    order.delivery.delivery_delay = deliveryDelay;
                }
                const dateTimeExpectedTime = DateTime.fromJSDate(expectedTime)
                const dateTimeEndPreparationTime = dateTimeExpectedTime.minus({ [DEFAULT_DELIVERY_TIME_UNIT]: deliveryDelay })
                const endPreparationTime = dateTimeEndPreparationTime.setZone(timezoneName).toJSDate()
                return endPreparationTime
            }
            else {

                throw OrderError.DELIVERY_DELAY_REQUIRE
            }
        }
        else {

            return null
        }
    },

    getDeliveryDelayFromOrder(order: Order, deliveryZones: DeliveryZone[], ignoreError: boolean = false): number {
        if (order.service_type !== SupportedServiceType.DELIVERY) {
            return DEFAULT_DELIVERY_DELAY;
        }

        const zone: DeliveryZone | null = this.getDeliveryZoneFromRef(order.delivery_zone_ref, deliveryZones, ignoreError);

        if (!zone) {
            return DEFAULT_DELIVERY_DELAY;
        }

        return zone.default_delivery_delay;
    },

    /**
     * Get a delivery zone from its ref. If it does not exist
     * in the list of zones, an error is thrown.
     * @param ref 
     * @param deliveryZones 
     * @returns 
     */
    getDeliveryZoneFromRef(
        ref: string | undefined = undefined,
        deliveryZones: DeliveryZone[] | undefined = undefined,
        ignoreError: boolean = false
    ): DeliveryZone | null {

        // Searching for the zone
        let zoneFound: DeliveryZone | undefined = undefined;
        if (ref && deliveryZones && deliveryZones.length > 0) {

            for (const zone of deliveryZones) {
                if (ref === zone.ref) {
                    zoneFound = zone;
                    break;
                }
            }
        }

        if (!zoneFound && !ignoreError) {

            throw OrderError.DELIVERY_ZONE_NOT_FOUND.withValue({
                "zone_ref": ref
            });
        }

        if (zoneFound) {

            return zoneFound;
        }

        return null;
    },

    isMatchingDeliveryRange(deliveryRange: DeliveryProviderRange, deliveryProviderPrice?: number, deliveryDistance?: number): boolean {
        if (deliveryProviderPrice) {
            const deliveryProviderPriceCent = Math.round(deliveryProviderPrice * 100);
            if (deliveryRange.min_provider_price) {
                const minPrice = moneyToNumber(deliveryRange.min_provider_price, true);
                if (deliveryProviderPriceCent < minPrice) {
                    return false;
                }
            }
            if (deliveryRange.max_provider_price) {
                const maxPrice = moneyToNumber(deliveryRange.max_provider_price, true);
                if (deliveryProviderPriceCent > maxPrice) {
                    return false;
                }
            }
        }
        if (deliveryDistance) {
            if (deliveryRange.min_provider_distance && deliveryDistance < deliveryRange.min_provider_distance) {
                return false;
            }
            if (deliveryRange.max_provider_distance && deliveryDistance > deliveryRange.max_provider_distance) {
                return false;
            }
        }
        return true;
    },

    /**
     * Get the price paid by the end customer for the delivery given the price paid to the provider and the distance
     * @param locationDeliveryProviders The location wth customer price config
     * @param providerType 
     * @param deliveryProviderPrice the delivery provider price in the location currency (ex: 9.0 for 9.0 EUR)
     * @param deliveryDistance in meters. MANDATORY: use "vol d'oiseau" if not provided by the delivery provider
     */
    getDeliveryCustomerPrice(
        locationDeliveryProviders: LocationDeliveryProvider[],
        providerType: DeliveryProviderType,
        deliveryProviderPrice: number | undefined,
        deliveryDistance: number | undefined,
    ): Money | undefined {

        const locationDeliveryConfig = locationDeliveryProviders.find((deliveryConfig) => deliveryConfig.type === providerType);
        if (!locationDeliveryConfig) {
            throw DeliveryErrors.DELIVERY_PROVIDER_TYPE_NOT_CONFIGURED.withValue({ deliveryProviderType: providerType });
        }

        const foundCustomerPrice = locationDeliveryConfig.customer_prices?.find((customerPrice) => {
            return this.isMatchingDeliveryRange(customerPrice, deliveryProviderPrice, deliveryDistance);

        });
        return foundCustomerPrice?.customer_price;
    },

    getOrderStatusFromDeliveryStatus(orderStatus: OrderStatus, deliveryStatus: DeliveryStatus): OrderStatus | null {
        //TODO: use getFollowingOrderStatus
        if (deliveryStatus === DeliveryStatus.CANCELLED &&
            orderStatus !== OrderStatus.CANCELLED) {
            return OrderStatus.DELIVERY_FAILED;
        } else if (deliveryStatus === DeliveryStatus.IN_DELIVERY &&
            orderStatus !== OrderStatus.COMPLETED &&
            orderStatus !== OrderStatus.CANCELLED) {
            return OrderStatus.IN_DELIVERY
        }
        else if (deliveryStatus === DeliveryStatus.COMPLETED &&
            orderStatus !== OrderStatus.COMPLETED &&
            orderStatus !== OrderStatus.CANCELLED) {
            return OrderStatus.COMPLETED
        }
        return null;
    },

    getLocationAddress(location: Location): string {
        return `${location.address}, ${location.postal_code} ${location.city}`;
    },

    getCustomerAddress(order: Order): string {
        if (order.customer?.address_1) {
            return order.customer?.address_2
                ? `${order.customer?.address_1} - ${order.customer?.address_2}, ${order.customer?.postal_code} ${order.customer?.city}`
                : `${order.customer?.address_1}, ${order.customer?.postal_code} ${order.customer?.city}`;
        }
        return "";
    },

    getFullAddress(address: Address): string {
        return `${address.address_2 ? `${address.address_1} -- ${address.address_2}` : address.address_1}, ${address.postal_code}, ${address.city}, ${address.country}`;
    },

    /**
     * WARN: this function modifies the timeslots directly. Think about cloning them before using it.
     * @param timeslots 
     * @param deliveryDelay 
     * @returns 
     */
    addDeliveryDelayToTimeslots(timeslots: OrderTimeSlot[], deliveryDelay: number | undefined, timezone: string): void {

        if (!deliveryDelay) {
            return;
        }

        timeslots.forEach((timeslot) => {

            const startDate = DateTime.fromJSDate(timeslot.start_time as Date).setZone(timezone).set({ millisecond: 0 }).plus({ minutes: deliveryDelay });
            const endDate = DateTime.fromJSDate(timeslot.end_time as Date).setZone(timezone).set({ millisecond: 0 }).plus({ minutes: deliveryDelay });

            timeslot.start_time = startDate.toJSDate();
            timeslot.start_hour = startDate.toFormat(TIME_FORMAT);

            timeslot.end_time = endDate.toJSDate();
            timeslot.end_hour = endDate.toFormat(TIME_FORMAT);
        });
    },

    /**
     * This function is used to calculate the delivery distance between the location and the customer location
     * @param locationPosition 
     * @param deliveryPosition 
     */

    getDeliveryDistance(locationPosition: Position | undefined, deliveryPosition: Position | undefined): number | undefined {
        if (!locationPosition || !deliveryPosition) {
            return undefined;
        }

        const distance = getDistance(locationPosition, deliveryPosition);
        return distance;
    }
}

export default deliveryHelper