import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { OrderError } from '../../my-lemonade-library/model/OrderError';
import PaymentErrors from '../../my-lemonade-library/src/payments/models/PaymentErrors';
import OrderErrorDetail from '../../orders/components/OrderErrorDetail';
import OrderErrorTimeSlot from '../../orders/components/OrderErrorTimeSlot';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import CommonOrderErrorPage from '../components/CommonOrderErrorPage';


type PageErrorProps = {
    titleId?: string,
    subtitleId?: string,
    returnHomeButton?: boolean
}

/**
 * Error page, use when an error appear or the service is disabled
 * @param titleId: intl id for custom Message 
 * @param subtitleId: intl id for custom Message 
 * @param returnHomeButton: bool use to display a goback button (routes.categoriePage) 
 */
function PageError({ titleId, subtitleId, returnHomeButton }: PageErrorProps) {

    const { currentOrder: currentOrderState } = useTypedSelector((state: RootState) => state.order);
    const { errorCode, errorValue } = currentOrderState;

    const [errorPageLoaded, setErrorPageLoaded] = useState<boolean>(false);

    /**
     * To avoid seeing the 404 page everytime we load a page in the modal
     * (the little delay produced by the router), we set a timer for half a second.
     * Only after this time, we display the error page.
     * TODO: ahhhhhhhh
     */
    useEffect(() => {

        setTimeout(() => {

            setErrorPageLoaded(true);
        }, 500);

    }, []);

    /**
     * display specific content depends of errorCode.
     * CommonOrderErrorPage is a default choice or unsupported code
     * @param errorCode 
     * @returns 
     */
    const errorPageContent = (errorCode?: string) => {

        if (errorCode === OrderError.INVALID_ORDER_CONTENT.code && errorValue) {
            return (
                <OrderErrorDetail errorValue={errorValue} errorCode={errorCode} />
            )
        } else if (errorCode === OrderError.TOO_MANY_ORDERS_IN_SLOT.code && errorValue) {
            return (
                <OrderErrorTimeSlot errorCode={errorCode} errorValue={errorValue} />
            )
        } else {
            if (errorCode === PaymentErrors.BALANCE_TOO_LOW.code) {
                titleId = 'Payment.error';
                subtitleId = 'payment.error.balance_too_low';
            }
            return (
                <CommonOrderErrorPage
                    titleId={titleId}
                    subtitleId={subtitleId}
                    returnHomeButton={returnHomeButton}
                />
            )
        }
    }

    if (!errorPageLoaded) {

        return <></>
    }

    return (

        <Box
            py={3}
            px={2}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            data-test={`error-page-${errorCode}`}
        >

            {errorPageContent(errorCode)}

        </Box>
    );
}

export default PageError;