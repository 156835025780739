import { Catalog, Product, Sku } from "../../../model/Catalog";
import { Order, OrderItem } from "../../../model/Order";
import { log } from "../../common/services/LogService";
import productService from "../../products/services/ProductService";
import { Suggestion, SuggestionMode, SuggestionSku } from "../models/Suggestion";
import { SuggestionMap, SuggestionSkuExt } from "../models/SuggestionMap";


class SuggestionService {

    getSuggestionProductsByOrder(order: Order, catalog: Catalog): SuggestionMap | null {

        const suggestionMap: SuggestionMap = {}

        if (catalog.data.suggestions) {
            catalog.data.suggestions.forEach(suggestion => {
                if (!suggestion.disable && suggestion.suggestion_mode === SuggestionMode.ALWAYS_SHOW) {
                    this.addSuggestionExtToSuggestionMap(suggestionMap, suggestion, catalog, suggestion.suggestion_mode)
                }
            })
        }

        order.items.forEach((item: OrderItem) => {

            // Do not take into account an item which has already been sent to the POS
            if (item.update_id) {
                return;
            }

            const sku: Sku = this.getSkuByRef(item.sku_ref, item.product_ref, catalog)
            if (sku.suggestions_refs) {

                // all suggestion associate to item sku
                const suggestionsList: { [key: string]: Suggestion } | null = this.getSuggestionsBySku(sku.suggestions_refs, catalog, order)
                if (suggestionsList) {

                    Object.values(suggestionsList).forEach(suggestion => {
                        if (!suggestion.disable) {
                            this.addSuggestionExtToSuggestionMap(suggestionMap, suggestion, catalog, suggestion.suggestion_mode)
                        }
                    })
                }
            }
        })

        if (Object.keys(suggestionMap).length === 0) {
            return null
        }

        return suggestionMap
    }

    addSuggestionExtToSuggestionMap(suggestionMap: (SuggestionMap), suggestion: Suggestion, catalog: Catalog, mode: SuggestionMode) {
        if (!suggestion.disable) {
            // Check if suggestion ref if already in suggestionMap
            if (!suggestionMap[suggestion.ref]) {
                suggestionMap[suggestion.ref] = {}
            }
            suggestion.skus.forEach((suggestionSku) => {
                // Check if the suggestion sku is already in suggestion map
                if (!suggestionMap![suggestion.ref][suggestionSku.ref]) {

                    const suggestionSkuEx: SuggestionSkuExt | null = this.fillProductAndSkuToSuggestionSku(suggestionSku, catalog, mode);

                    if (suggestionSkuEx) {
                        suggestionMap![suggestion.ref][suggestionSku.ref] = suggestionSkuEx;
                    } else {
                        delete suggestionMap![suggestion.ref][suggestionSku.ref];
                    }
                }
            })
        }
    }

    getSkuByRef(skuRef: string, productRef: string, catalog: Catalog): Sku {
        const product: Product = productService.getProductBasedOnProductRef(productRef, catalog.data.products, catalog.location_id, catalog.ref)!
        const sku: Sku = productService.getSkuBasedOnSkuRefAndProduct(skuRef, product, catalog.location_id, catalog.ref)!
        return sku
    }

    getSuggestionsBySku(suggestionListRef: string[], catalog: Catalog, order: Order): { [key: string]: Suggestion } | null {
        let suggestionList: { [key: string]: Suggestion } | null = null
        const suggestionInCatalogList = catalog.data.suggestions;
        if (suggestionInCatalogList) {
            suggestionListRef.forEach((suggestionRef: string) => {
                log.debug(`start for ref ${suggestionRef}`)
                const currentSuggestion = suggestionInCatalogList.find(suggestion => suggestionRef === suggestion.ref)
                if (!suggestionList) {
                    suggestionList = {}
                }
                if (currentSuggestion && !suggestionList[currentSuggestion.ref] && !currentSuggestion.disable) {
                    const suggestionToAdd: Suggestion | null = this.defineSuggestionProductList(currentSuggestion, order)
                    if (suggestionToAdd) {
                        suggestionList[suggestionToAdd.ref] = suggestionToAdd
                        return;
                    } else {
                    }

                } else {
                    // log bad condition
                    if (!currentSuggestion) {
                        log.info(`Suggestion with ref ${suggestionRef} not found`)
                    } else if (currentSuggestion.disable) {
                        log.info(`Suggestion ${currentSuggestion.ref} is disable`)
                    } else if (suggestionList[currentSuggestion.ref]) {
                        log.info(`Suggestion with ref ${suggestionRef} already in list`)
                    } else {
                        throw new Error(`Something bad happened`)
                    }
                }
            })
            return suggestionList
        } else {
            return null;
        }
    }

    defineSuggestionProductList(suggestion: Suggestion, order: Order): Suggestion | null {
        const newSuggestion = { ...suggestion }
        const mode: SuggestionMode = newSuggestion.suggestion_mode
        const productRefsListInOrder: string[] = order.items.map(item => item.product_ref);
        const suggestionSkus: SuggestionSku[] = newSuggestion.skus
        const suggestionSkusNotInOrder: SuggestionSku[] = suggestionSkus.filter(suggestionSku => productRefsListInOrder.indexOf(suggestionSku.product_ref) === -1)

        if (mode === SuggestionMode.ALWAYS_SHOW_NOT_IN_ORDER) {
            newSuggestion.skus = suggestionSkusNotInOrder
            return newSuggestion
        } else if (mode === SuggestionMode.ONLY_SHOW_NONE_IN_ORDER) {
            if (suggestionSkusNotInOrder.length === suggestionSkus.length) {
                newSuggestion.skus = suggestionSkusNotInOrder
                return newSuggestion
            } else {
                log.info(`mode is ${mode} and some prodcuts from suggestion ${suggestion.ref} are already in order `)
                return null
            }
        } else {
            log.error(`mode ${mode} is not supported`)
            return null
        }
    }

    fillProductAndSkuToSuggestionSku(suggestionSku: SuggestionSku, catalog: Catalog, mode: SuggestionMode): SuggestionSkuExt | null {

        const productRef = suggestionSku.product_ref;
        const skuRef = suggestionSku.ref
        const product: Product = productService.getProductBasedOnProductRef(productRef, catalog.data.products, catalog.location_id, catalog.ref)!;
        const sku: Sku = productService.getSkuBasedOnSkuRefAndProduct(skuRef, product, catalog.location_id, catalog.ref)!
        const suggestionSkuExt: SuggestionSkuExt = { ...suggestionSku };

        if (product.disable) {

            return null;
        }

        suggestionSkuExt.product = product;
        suggestionSkuExt.sku = sku
        suggestionSkuExt.suggestion_mode = mode

        return suggestionSkuExt
    }

    // The suggestion map is processed to return an array of a given number of SuggestionSkus 
    suggestionSkuExtendedToDisplay(suggestionsMap: SuggestionMap, quantityProductSuggest: number): SuggestionSkuExt[] {
        const productsToDisplay: SuggestionSkuExt[] = []

        if (suggestionsMap) {
            Object.values(suggestionsMap).forEach(sug => {
                if (Object.keys(sug).length > 0) {
                    Object.values(sug).forEach(suggestionExt => productsToDisplay.push(suggestionExt))
                }
            })

            /** ALWAYS_SHOW suggestions are the most importants and must be displayed 
             * So the array is sorted to put them at the start
            */
            if (productsToDisplay.length > 0) {
                productsToDisplay.sort((a, b) => {
                    if (a.suggestion_mode === SuggestionMode.ALWAYS_SHOW) {
                        return -1
                    }
                    return 1
                })

                if (productsToDisplay.length > quantityProductSuggest) {
                    productsToDisplay.splice(quantityProductSuggest)
                }
            }
        }
        return productsToDisplay
    }
}


const suggestionService = new SuggestionService()

export default suggestionService
