import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { CommonActions } from "../../Common/redux/CommonActions";
import log from "../../Common/services/LogService";
import { getIdToken } from "../../authentication/services/AuthenticationService";
import * as ROUTES from '../../config/routes';
import { getApiEndpoint } from "../../config/variables";
// import actions from "./actions";
import LocationState from "../../Locations/models/LocationState";
import { PaymentType } from "../../my-lemonade-library/model/Order";
import { ACCOUNT_PARAM } from "../../my-lemonade-library/src/accounts/configs/AccountsApiRoutes";
import { numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { getConnectorGetWalletRoute } from "../../my-lemonade-library/src/connectors/configs/ConnectorsApiRoutes";
import { ConnectorWalletRequest, ConnectorWalletResponse } from "../../my-lemonade-library/src/connectors/models/ConnectorWalletRequestResponse";
import { LOCATION_PARAM } from "../../my-lemonade-library/src/locations/configs/LocationsApiRoutes";
import ordersApiRoutes, { ORDER_ID_PARAM } from "../../my-lemonade-library/src/orders/configs/OrdersApiRoutes";
import OrderCreatePaymentRequest from "../../my-lemonade-library/src/orders/models/OrderCreatePaymentRequest";
import OrderCreatePaymentResponse from "../../my-lemonade-library/src/orders/models/OrderCreatePaymentResponse";
import paymentsApiRoutes, { PAYMENT_ID_PARAM_KEY, PAYMENT_TYPES_PARAM_KEY } from "../../my-lemonade-library/src/payments/configs/PaymentsApiRoutes";
import { IsThereSavedPaymentDataResponse } from "../../my-lemonade-library/src/payments/models/IsThereSavedPaymentDataResponse";
import AdyenPaymentIntentInfos from "../../my-lemonade-library/src/payments/models/adyen/AdyenPaymentIntentInfos";
import { SESSION_ID_HEADER } from "../../my-lemonade-library/src/sessions/services/SessionService";
import { ORDER_ID_QUERY_PARAM_NAME } from "../../orders/configs/OrdersRouterConfig";
import { orderActions } from "../../orders/redux/OrderActions";
import { OrderState } from "../../orders/redux/models/OrderState";
import { RootState } from "../../redux/root-reducer";
import paymentService from "../services/PaymentService";
import paymentsActions, { CREATE_ORDER_PAYMENT, CreateOrderPaymentAction, EDENRED_LOGOUT, EdenredLogoutAction, GET_CUSTOMER_CONNECTOR_WALLET_BALANCE, GetCustomerConnectorWalletBalance, IS_THERE_PAYMENT_DATA, IsTherePaymentDataAction, SEND_ADYEN_PAYMENT_DETAILS, SendAdyenPaymentDetailsAction } from "./PaymentsActions";

function* createOrderPayment(action: CreateOrderPaymentAction) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    // RootState
    try {

        yield put(orderActions.forceSendToApiLoader())

        const createPaymentUrl = getApiEndpoint() + ordersApiRoutes.ORDER_PAY.replace(ORDER_ID_PARAM, action.payload.order_id);
        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);
        const { tableLinkId, sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;

        headers.append("Authorization", `Bearer ${tokenResult.token}`);
        sessionId && headers.append(SESSION_ID_HEADER, sessionId);

        const successUrl = window.location.origin + ROUTES.getOrderConfirmationFullRoute(tableLinkId, `:${ORDER_ID_QUERY_PARAM_NAME}`, `:${PAYMENT_ID_PARAM_KEY}`, sessionId);
        // TODO: Cancel url with dedicated page
        const cancelUrl = window.location.origin + ROUTES.getCategoriesFullRoute(tableLinkId, sessionId);
        const errorUrl = window.location.origin + ROUTES.getErrorFullRoute(tableLinkId, `:${ORDER_ID_QUERY_PARAM_NAME}`, undefined, sessionId);

        const createOrderPaymentRequest: OrderCreatePaymentRequest = {
            payment_amount: action.payload.payment_amount,
            payment_type: action.payload.payment_type,
            success_url: successUrl,
            error_url: errorUrl,
            cancel_url: cancelUrl,
            payment_items: action.payload.payment_items,
            payment_amount_type: action.payload.payment_amount_type,
            payment_data: action.payload.payment_data,
            cancel_payment_intent_id: action.payload.cancel_payment_intent_id,
            tip_charge_amount: action.payload.tip_charge_amount,
            use_loyalty_points: action.payload.use_loyalty_points,
        };

        const raw = JSON.stringify(createOrderPaymentRequest);
        log.debug(`Sending order payment`, raw);
        const requestOptions: RequestInit = {
            method: "PUT",
            headers: headers,
            body: raw,
        };

        // error TS7057 'yield' expression implicitly results in an 'any' type
        //@ts-ignore
        // TODO response urls must have session ID
        const response = yield fetch(createPaymentUrl, requestOptions);

        // Response ok, get the order creation response
        const result: OrderCreatePaymentResponse = yield response.json() as OrderCreatePaymentResponse;
        if (result && result.order) {
            log.debug(result, 'result');

            let paymentPage: string | undefined = paymentService.getPaymentPage(tableLinkId, result.payment_infos);
            yield put(orderActions.orderSendSuccess(result.order, result.payment_infos, paymentPage));

            // Redirect to confirmation or payment page.
            // /!\ WARNING: this code is duplicated in orderSaga > postOrder
            if (paymentPage) {
                yield put(CommonActions.setRedirectURL(paymentPage));
            } else {
                yield put(CommonActions.setRedirectURL(ROUTES.getOrderConfirmationFullRoute(tableLinkId, result.order.id)))
            }

        } else {
            log.error("Null order payment creation response");
            yield put(orderActions.orderSendError());
            yield put(CommonActions.setRedirectURL(errorUrl))
        }
    } catch (error) {
        log.error("Error while sending order");
        log.error(error);
        yield put(orderActions.orderSendError());
    }
}

function* sendAdyenPaymentDetails(action: SendAdyenPaymentDetailsAction) {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    // RootState
    try {
        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);
        const { payment_infos } = (yield select((state: RootState) => state.order)) as OrderState;
        const { sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;

        if (payment_infos?.order_payment && payment_infos.payment_type === PaymentType.ADYEN) {
            const adyenPaymentIntentInfo: AdyenPaymentIntentInfos = payment_infos as AdyenPaymentIntentInfos;
            if (adyenPaymentIntentInfo.submit_details_url && payment_infos.order_payment.payment_data) {
                headers.append("Authorization", `Bearer ${tokenResult.token}`);
                sessionId && headers.append(SESSION_ID_HEADER, sessionId);
                const sendAdyenPaymentDetailsRequest: any = { ...action.payload, paymentData: payment_infos.order_payment.payment_data };
                const raw = JSON.stringify(sendAdyenPaymentDetailsRequest);
                log.debug(`Sending adyen payment details payment with url: ${adyenPaymentIntentInfo.submit_details_url}`, raw);
                var requestOptions: any = {
                    method: "POST",
                    headers: headers,
                    body: raw,
                };

                // error TS7057 'yield' expression implicitly results in an 'any' type
                //@ts-ignore
                let response = yield fetch(adyenPaymentIntentInfo.submit_details_url, requestOptions);

                // Response ok, get the order creation response
                const result: OrderCreatePaymentResponse = yield response.json() as OrderCreatePaymentResponse;
                if (result) {
                    yield put(orderActions.orderSendSuccess(result.order, result.payment_infos, undefined));
                } else {
                    log.error("Null order payment creation response");
                    put(orderActions.orderSendError());
                }
            }
        }

    } catch (error) {
        log.error("Error while sending order");
        log.error(error);
        yield put(orderActions.orderSendError());
    }
}

function* isTherePaymentData(action: IsTherePaymentDataAction) {

    try {

        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${tokenResult.token}`);

        const baseUrl = getApiEndpoint() + paymentsApiRoutes.PAYMENT_IS_THERE_SAVED_USER_PAYMENT_DATA
            .replace(ACCOUNT_PARAM, action.payload.accountId)
            .replace(LOCATION_PARAM, action.payload.locationId);

        const urlParams = new URLSearchParams();
        action.payload.paymentTypes.forEach((paymentType) => {
            urlParams.append(PAYMENT_TYPES_PARAM_KEY, paymentType);
        });

        const fetchUrl = `${baseUrl}?${urlParams.toString()}`;

        log.debug(`Asking the API if user has saved data for payment types ${action.payload.paymentTypes.join(",")} (url: ${fetchUrl})`);
        const requestOptions: any = {
            method: "GET",
            headers: headers,
        };

        const response: Response = yield fetch(fetchUrl, requestOptions);
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const result: IsThereSavedPaymentDataResponse = yield response.json();

        for (const [paymentType, isThereSavedPaymentData] of Object.entries(result.isThereSavedPaymentData)) {
            yield put(paymentsActions.isTherePaymentDataSuccess(paymentType as PaymentType, action.payload.userId, isThereSavedPaymentData));
        }
    }
    catch (error) {
        for (const paymentType of action.payload.paymentTypes) {
            yield put(paymentsActions.isTherePaymentDataFailure(paymentType, action.payload.userId));
        }
    }
}

function* edenredLogout(action: EdenredLogoutAction) {

    try {

        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${tokenResult.token}`);

        const fetchUrl = getApiEndpoint() + paymentsApiRoutes.EDENRED_LOGOUT
            .replace(ACCOUNT_PARAM, action.payload.accountId)
            .replace(LOCATION_PARAM, action.payload.locationId);

        log.debug(`Sending API request to logout of Edenred account with url ${fetchUrl}`);
        const requestOptions: any = {
            method: "GET",
            headers: headers,
        };

        const response: Response = yield fetch(fetchUrl, requestOptions);
        if (!response.ok) {
            throw new Error;
        }
        yield put(paymentsActions.edenredLogoutSuccess(action.payload.userId));
    }
    catch (error) {
        log.error(`Could not logout from Edenred account`);
        yield put(paymentsActions.edenredLogoutFailure(action.payload.userId));
    }
}

function* getCustomerConnectorWalletBalance(action: GetCustomerConnectorWalletBalance) {
    try {
        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${tokenResult.token}`);

        const { selectedLocation }: LocationState = (yield select((state: RootState) => state.locations));

        const customer_email = action.payload.customer_email;
        const phone = action.payload.phone;
        const body: ConnectorWalletRequest = {
            email: customer_email,
            phone: phone
        }
        if (selectedLocation && selectedLocation.connector) {
            const fetchUrl = getApiEndpoint() + getConnectorGetWalletRoute(selectedLocation.account_id, selectedLocation?.id, selectedLocation?.connector?.type)

            log.debug(`Sending API request to get customer connector wallet balance with url ${fetchUrl}`);
            const requestOptions: RequestInit = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body)
            };

            const response: Response = yield fetch(fetchUrl, requestOptions);
            if (!response.ok) {
                log.error(response);
                yield put(paymentsActions.getCustomerConnectorWalletBalanceFailure());
                return;
            }
            const result: ConnectorWalletResponse = yield response.json();
            const resultBalanceIfUndefined = numberToMoney(0, selectedLocation.currency);
            yield put(paymentsActions.getCustomerConnectorWalletBalanceSuccess(selectedLocation.connector.type, result.balance ?? resultBalanceIfUndefined));
        }
    }
    catch (error) {
        log.error(`Could not get customer connector wallet balance`);
        yield put(paymentsActions.getCustomerConnectorWalletBalanceFailure());
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(CREATE_ORDER_PAYMENT, createOrderPayment),
        takeLatest(SEND_ADYEN_PAYMENT_DETAILS, sendAdyenPaymentDetails),
        takeLatest(IS_THERE_PAYMENT_DATA, isTherePaymentData),
        takeLatest(EDENRED_LOGOUT, edenredLogout),
        takeLatest(GET_CUSTOMER_CONNECTOR_WALLET_BALANCE, getCustomerConnectorWalletBalance),
    ]);
}