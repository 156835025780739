import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { loyaltyActions } from "../../loyalty/redux/LoyaltyActions";
import { LoyaltyInformationToDisplayConfig, LoyaltyInformationToDisplayOrder } from "../../loyalty/redux/models/LoyaltyState";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { estimateLoyaltyEarningAndSpending, getLoyaltyConfigInfo } from "../helpers/LoyaltyHelpers";

/**
 * Getting an estimation of the loyalty operation that could occur on this order
 * @param loyaltyConfig 
 * @param order 
 * @returns 
 */
export const useLoyaltyInformation = () => {

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { selectedLocation, selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { user_authentication_state } = useTypedSelector((state: RootState) => state.authentication.data);

    const intl = useIntl();
    const dispatch = useDispatch();

    const loyaltyConfig = order.loyalty_config ?? selectedLocation?.loyalty;

    useEffect(() => {

        /**
         * Added (order && !order.end_preparation_time && !order.expected_time) because sometimes order has no order moment  => crash from orderPrice
         */
        if (!loyaltyConfig || !order || (order && !order.end_preparation_time && !order.expected_time) || !selectedLocation || !selectedCatalog) {
            return;
        }

        const orderContent: LoyaltyInformationToDisplayOrder | null = estimateLoyaltyEarningAndSpending(
            selectedLocation,
            selectedCatalog,
            selectedTable,
            order,
            loyaltyConfig,
            user_authentication_state.user,
            intl,
        );

        const configContent: LoyaltyInformationToDisplayConfig | null = getLoyaltyConfigInfo(
            loyaltyConfig,
            intl,
        );

        dispatch(loyaltyActions.updateLoyaltyInformation(configContent ?? undefined, orderContent ?? undefined));

    }, [loyaltyConfig, selectedLocation, selectedCatalog, order, user_authentication_state.user, intl, dispatch]);
}
