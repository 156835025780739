import { ClassConstructor, classToPlain, plainToClass } from "class-transformer";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";
import { Deal, DealLine, PricingEffect } from "../model/Catalog";
import { Location, SupportedServiceType } from "../model/Location";
import { Order, PaymentType } from "../model/Order";
import { Money, moneyToNumber } from "../src/common/models/Money";
import { DealLineApply, SkuAndPrice } from "../src/orders/models/OrderPriceArg";
import { orderService } from "../src/orders/services/OrderService";
import { paymentHelper } from "../src/payments/services/PaymentHelper";
import { Restriction } from "../src/restrictions/model/Restriction";
import { isMatchingTemporalRestriction } from "../src/restrictions/services/RestrictionsService";

//Classes and functions used by Kevin to calculate price

export class Cloner {

    public clone<T>(source: Array<T>, type: ClassConstructor<T>): Array<T> {

        let cloneArray: Array<T> = [];

        source.forEach(val => {
            let copyPlain = classToPlain(val);
            let copy: T = plainToClass(type, copyPlain, { excludeExtraneousValues: true });
            cloneArray.push(copy)
        });

        return cloneArray
    }
}

export class SkuQuantityHelper {

    removeSkuFromQuantityMap(skusQuantity: Map<string, number>, refs: SkuAndPrice[]): void {

        refs.forEach(ref => {
            skusQuantity.set(ref.ref, (skusQuantity.get(ref.ref) || 0) - 1)
            if (skusQuantity.get(ref.ref) === 0) {
                skusQuantity.delete(ref.ref)
            }
        })
    }

}

export function isApplicable(skusQuantity: Map<string, number>, deal: Deal): boolean {
    let result = getDealApply(skusQuantity, deal);
    return result !== undefined && result.length > 0
}

export function getDealApply(skusQuantity: Map<string, number>, deal: Deal): DealLineApply[] | undefined {

    const eachLineOK = deal.lines.every(line => line.skus.map(sku => sku.ref).some(skuRef => skusQuantity.has(skuRef)))
    if (eachLineOK) {

        const newRestingLines: DealLine[] = JSON.parse(JSON.stringify(deal.lines)) as DealLine[];

        if (newRestingLines.length !== 0) {
            // Affect index
            newRestingLines.forEach((dealLine, dealLineIndex) => dealLine.index = dealLineIndex);
            let nextLine = newRestingLines.pop()!;
            return checkLineAvaibilty(nextLine, newRestingLines, new Map(skusQuantity), deal)
        }
    }

    return undefined;
}


export function checkLineAvaibilty(line: DealLine, restinglines: DealLine[], skusQuantity: Map<string, number>, deal: Deal): DealLineApply[] | undefined {

    let allSkusUseableForThisLine = line.skus.filter(sku => skusQuantity.has(sku.ref))

    for (let sku of allSkusUseableForThisLine) {

        if (restinglines.length === 0) {
            let result = new Array<DealLineApply>()
            result.push({
                line,
                skuref: sku.ref,
                extra_charge: sku.extra_charge
            });
            return result;
        }

        let newSkusQuantity = new Map(skusQuantity)
        new SkuQuantityHelper().removeSkuFromQuantityMap(newSkusQuantity, [{ ref: sku.ref, price: "", line_index: -1 }])

        let newRestingLines: Array<DealLine> = JSON.parse(JSON.stringify(restinglines))
        let nextLine = newRestingLines.pop()!;

        let result = checkLineAvaibilty(nextLine, newRestingLines, newSkusQuantity, deal)

        if (result !== undefined) {
            result!.push({
                line,
                skuref: sku.ref,
                extra_charge: sku.extra_charge
            });
            return result;
        }
    }

    return undefined;
}

/**
 * // TODO: test it
 * Checks whether or not the time given is compatible with the restriction and the serviceType.
 * @param time 
 * @param timezone 
 * @param restriction 
 * @param service_type 
 * @param checkForCollectionDelivery 
 * @returns 
 */
export function isTemporallyAvailable(
    time: Moment,
    timezoneName: string,
    restrictions: Restriction | Restriction[],
    service_type: SupportedServiceType | undefined,
    checkForCollectionDelivery?: boolean,  // If undefined, takeaway service types will always be available
): boolean {
    // When using the webapp in collection/delivery mode, the items are always displayed even if the location is currently closed
    if (
        service_type === SupportedServiceType.VIEW
        || (
            (
                service_type === SupportedServiceType.COLLECTION
                || service_type === SupportedServiceType.DELIVERY
            )
            && !checkForCollectionDelivery
        )
    ) {
        return true;
    }
    if (!restrictions) {
        return true;
    }

    if (Array.isArray(restrictions)) {
        if (restrictions.length === 0) {
            return true;
        }
        for (let iRestriction in restrictions) {
            const restriction = restrictions[iRestriction];
            if (isMatchingTemporalRestriction(time, timezoneName, restriction, service_type)) {
                return true;
            }
        }
        return false;
    } else {
        return isMatchingTemporalRestriction(time, timezoneName, restrictions, service_type);
    }
}

/**
 * Give this function one or several restrictions and an order,
 * it will tell you whether or not one of the restriction is matching temporally with the order.
 * @param order 
 * @param timezone 
 * @param restrictions 
 * @param disableExpectedTimeResetForEatin 
 * @returns 
 */
export function isTemporallyAvailableForOrder(
    order: Pick<Order, "service_type" | "end_preparation_time" | "expected_time">,
    timezone: string,
    restrictions: Restriction | Restriction[],
    disableExpectedTimeResetForEatin: boolean,
): boolean {

    let checkForCollectionDelivery: boolean = true;
    // View: always visible
    if (order.service_type === SupportedServiceType.VIEW) {
        return true;
    }
    // Get the moment defined in the order exected time / endpreparation time
    let orderMoment = orderService.getOrderMoment(order, timezone);
    if (order.service_type === SupportedServiceType.EAT_IN && (!orderMoment || !disableExpectedTimeResetForEatin)) {
        orderMoment = moment();
        order.end_preparation_time = orderMoment.toDate()
        order.expected_time = orderMoment.toDate()
    }
    else if (!orderMoment) {
        checkForCollectionDelivery = false;
        orderMoment = moment();
    }

    return isTemporallyAvailable(orderMoment, timezone, restrictions, order.service_type, checkForCollectionDelivery);
}

export function isDealCurrentlyAvailableForOrder(order: Order, timezone: string, deal: Deal, disableExpectedTimeResetForEatin: boolean): boolean {

    if (!deal.restrictions || !isTemporallyAvailableForOrder(order, timezone, deal.restrictions, disableExpectedTimeResetForEatin)) {
        return false;
    }

    return true;
}

/**
 * @deprecated: use paymentHelper instead
 * @param payment_type 
 * @param total 
 * @param location 
 * @returns 
 */
export function isPaymentAvailable(payment_type: PaymentType, total: number, location: Location): boolean {
    return paymentHelper.isPaymentAvailable(payment_type, payment_type, total, location) != null;
}
/**
 * Function in charge to compute a new origin price by applying a pricing effect
 * Provide pricing value if needed => function return null if failed
 * @param price 
 * @param pricingEffect 
 * @param pricing_value 
 * @returns the new discounted price
 */
export function applyPricingEffect(price: number, pricingEffect: PricingEffect, pricing_value?: Money | number): number | null {

    switch (pricingEffect) {
        case PricingEffect.EFFECT_UNCHANGED:
            return price
        case PricingEffect.EFFECT_FIXED_PRICE:
            if (pricing_value) {
                return moneyToNumber(pricing_value as string, false, `applyPricingEffect ${price} ${pricingEffect} ${pricing_value}`)
            } else {
                return null
            }
        case PricingEffect.EFFECT_PRICE_OFF:
            if (pricing_value) {
                return price - moneyToNumber(pricing_value as Money, false, `applyPricingEffect ${price} ${pricingEffect} ${pricing_value}`)
            } else {
                return null
            }
        case PricingEffect.EFFECT_PERCENTAGE_OFF:
            if (!_.isNil(pricing_value)) {
                return (price * (1 - ((pricing_value as number) / 100)))
            } else {
                return null
            }
        default:
            return null;
    }
}

/**
 * Takes an object and removes all the udnefined values inside.
 * WARNING: the object given to the function itself is modified, there
 * is no copy made in the function!
 * WARNING: if the parameter is just an undefined, it won't be deleted!
 * @param object 
 * @returns 
 */
export const removeUndefinedFromObject = <Type>(object: Type, nullAsWell?: boolean): Type => {

    const removeUndefinedRecursive = (thisObj: any, nullAsWell?: boolean) => {

        if (thisObj) {
            // Iterate trough the array if it's an array
            if (Array.isArray(thisObj)) {

                let indexesToDelete: number[] = [];

                thisObj.forEach((elem: any, index: number) => {

                    if (elem === undefined || (nullAsWell && elem === null)) {

                        indexesToDelete.push(index);
                    }
                    else {

                        removeUndefinedRecursive(elem);
                    }
                });

                // Removing the undefined level 1 elements
                for (var i = indexesToDelete.length - 1; i >= 0; i--) {
                    thisObj.splice(indexesToDelete[i], 1);
                }

            }
            // Iterate trough the object if it's an object
            else if (typeof (thisObj) === "object") {

                Object.keys(thisObj).forEach((key) => {

                    if (thisObj[key] === undefined || (nullAsWell && thisObj[key] === null)) {

                        delete thisObj[key];
                    }
                    else {

                        removeUndefinedRecursive(thisObj[key], nullAsWell);
                    }
                });
            }
        }
    }

    // Let's make it any so that we can manipulate the keys easily
    const obj: any = object as any;

    // Only do the action if it's an object or a string.
    // If it's another property, do nothing
    if (typeof (obj) === "object" || Array.isArray(obj)) {

        removeUndefinedRecursive(obj, nullAsWell);
    }
    // else: do nothing if it's not an object*/
    return obj;
}
