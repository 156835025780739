enum LocationLinkRelationType {
    GOOGLE_REVIEW = "google_review",
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    MICHELIN = "michelin",
    TRIPADVISOR = "tripadvisor",
    GETRESTO = "getresto",
    ORDER = "order",
    PAY = "pay",
    VIEW_MENU = "view_menu",
    LOYALTY = "loyalty",
    CUSTOM = "custom",
    RECEIPT = "receipt"
}

export default LocationLinkRelationType;