import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DeviceContext } from '../../../App'
import UserHeaderButton from '../../../authentication/components/UserHeaderButton'
import { useHeaderHeight } from '../../../config/hooks/useHeaderHeight'
import { getHomeFullRoute, RouteParams } from '../../../config/routes'
import { defaultMyLemonadeSummaryBottomShadow } from '../../../config/theme'
import { RootState, useTypedSelector } from '../../../redux/root-reducer'
import FlagComponent from '../../../translations/components/FlagComponent'
import HideOnScroll from '../HideOnScroll'

/**
 * Header component, contain logo, locationContact and flag component
 * Use container HideOnScroll in charge to hide header on down scroll
 */

const ToolbarComponent: React.FC = () => {

    const theme = useTheme();
    const history = useHistory()
    const params = useParams<RouteParams>();
    const headerHeight = useHeaderHeight();

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { tablet_device, mobile_device, desktop_device } = useContext(DeviceContext)

    const handleBackHomeClick = () => {
        const route = getHomeFullRoute(params.tableLinkKey)
        history.push(route);
    }

    /**
     * Render the app's header with the location's name, logo or the default logo
     * @returns JSX.Element
     */
    const renderLogo = (): JSX.Element => {
        if (selectedLocation?.theme?.extended_theme_options?.display_location_name) {
            return <Typography variant='h4'>
                {selectedLocation.name}
            </Typography>
        } else if (selectedLocation?.logo_header) {
            return <img data-test="header_logo" style={{ maxHeight: "85%" }} src={selectedLocation.logo_header} alt='' />
        } else {
            return <img
                data-test="header_logo" style={{
                    maxWidth: !mobile_device ? "35%" : "45%",
                    maxHeight: !mobile_device ? "35%" : "45%",
                    marginTop: ".4em"
                }}
                src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
                alt=''
            />
        }
    }

    return (
        <HideOnScroll>

            <Box
                p={{
                    xs: 0,
                    sm: !mobile_device ? theme.spacing(0, 7) : theme.spacing(0, 4)
                }}
                position='fixed'
                top={0}
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                zIndex={1299}
                justifyContent={!mobile_device ? "flex-start" : "center"}
                height={headerHeight}
                bgcolor={
                    tablet_device ? 'transparent'
                        : desktop_device ? theme.palette.background.paper
                            : theme.palette.background.default}
                className="header"
                boxShadow={desktop_device ? defaultMyLemonadeSummaryBottomShadow : 0}
                data-test="header_div"
            >

                {/* Back to home button */}
                {mobile_device && selectedLocation?.theme?.extended_theme_options?.display_back_to_home_button &&
                    <IconButton
                        onClick={handleBackHomeClick}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            left: theme.spacing(1)
                        }}
                        size="large">
                        <ArrowBackIosRoundedIcon sx={{ color: theme.palette.secondary.main }} />
                    </IconButton>
                }


                {/* Logo */
                    renderLogo()}

                <Box
                    position="absolute"
                    right={theme.spacing(2)}
                    display="flex"
                    alignItems="center"
                >
                    {!selectedLocation?.theme?.extended_theme_options?.hide_flag_button &&
                        <FlagComponent />
                    }

                    {!selectedLocation?.theme?.extended_theme_options?.hide_account_button &&
                        <UserHeaderButton />
                    }
                </Box>
            </Box >
        </HideOnScroll>
    );
}

export default ToolbarComponent
