import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

export const DELIVERY_PROVIDER_TYPE_PARAM_KEY = "deliveryProviderType";
export const DELIVERY_PROVIDER_TYPE_PARAM = `:${DELIVERY_PROVIDER_TYPE_PARAM_KEY}`;
export const DELIVERY_PROVIDER_ACCOUNT_PARAM_KEY = "deliveryAccountId";

export const DELIVERY_LOCATION_ROOT = `${LOCATION_ROUTE}/${MylemonadeScopes.DELIVERY}`;
// Root not specific to a location
export const DELIVERY_ROOT = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.DELIVERY}`
export const DELIVERY_WEBHOOK = `${DELIVERY_ROOT}/${DELIVERY_PROVIDER_TYPE_PARAM}/webhook`;
export const DELIVERY_LOCATION_CHECK_AVAILABLE = `${DELIVERY_LOCATION_ROOT}/check-available`;
export const DELIVERY_LOCATION_CREDENTIALS = `${DELIVERY_LOCATION_ROOT}/${DELIVERY_PROVIDER_TYPE_PARAM}/credentials`;
export const DELIVERY_CONFIGURE_PROVIDER = `${DELIVERY_LOCATION_ROOT}/configure-provider`;

const deliveryApiRoutes = {
    DELIVERY_LOCATION_CREDENTIALS: DELIVERY_LOCATION_CREDENTIALS,
    DELIVERY_LOCATION_CHECK_AVAILABLE: DELIVERY_LOCATION_CHECK_AVAILABLE,
    DELIVERY_WEBHOOK: DELIVERY_WEBHOOK,
    DELIVERY_CONFIGURE_PROVIDER: DELIVERY_CONFIGURE_PROVIDER
};
export default deliveryApiRoutes;