import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import AuthenticationBottomMessages, { AuthenticationBottomMessage } from "../../authentication/components/AuthenticationBottomMessages";
import ThemeSvg from "../../Common/components/ThemeSvg";
import useGetRestoTheme from "../../Common/customHooks/useGetRestoTheme";
import { desktopDisplayBreakpoint } from "../../config/theme";
import ProvidersDiscounts from "../../discount/ProvidersDiscounts";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { getLoyaltyCurrentBalance, getLoyaltyLoginState, isThereALoyaltyOperation, LoyaltyLoginState } from "../helpers/LoyaltyHelpers";
import LoyaltyExplaination from "./LoyaltyExplaination";

interface LoyaltyContentProps {
    endAuthModal?: boolean,
}

const LoyaltyContent: React.FC<LoyaltyContentProps> = (props) => {

    const { endAuthModal } = props;

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state: RootState) => state.order);
    const { user_authentication_state } = useTypedSelector((state: RootState) => state.authentication.data);
    const { estimatedLoyaltyConfigContent, estimatedLoyaltyOrderContent } = useTypedSelector((state: RootState) => state.loyalty);

    const intl = useIntl();
    const theme = useTheme();
    const isGetrestoTheme = useGetRestoTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));

    const [loyaltyLoginState, setLoyaltyLoginState] = useState<LoyaltyLoginState>();

    useEffect(() => {

        setLoyaltyLoginState(getLoyaltyLoginState(
            user_authentication_state,
            order.loyalty_user_id,
        ));

    }, [user_authentication_state, order]);

    // TODO: implement this function
    const getMaskedAddressOfLoyaltyOperationOwner = (): string | null => {

        return null;
        return "cl*****@gmail.com";
    }

    if (!estimatedLoyaltyOrderContent?.howMuchEarned && !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)) {
        return (
            <Box
                height={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h4" style={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "loyalty_page.no_content" })}
                </Typography>
            </Box>
        );
    }

    return (

        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={1}
            data-test="loyalty-content.root"
        >

            {/* A */}
            {
                !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                && estimatedLoyaltyOrderContent
                && Boolean(estimatedLoyaltyOrderContent?.howMuchEarned)
                && estimatedLoyaltyConfigContent
                &&

                <Box
                    style={{
                        position: "relative"
                    }}
                    display="flex"
                    width={1}
                    justifyContent="center"
                    marginBottom={2}
                >

                    <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/loyalty${isGetrestoTheme ? "_getresto" : ""}.svg`}
                        maxWidth={endAuthModal ? "75%" : "90%"}
                    />

                    <Typography
                        variant="h1"
                        style={{
                            color: "white",
                            position: "absolute",
                            top: isDesktopRender ?
                                (isGetrestoTheme ? "45%" : "41%")
                                :
                                "39%",
                            left: (isGetrestoTheme || isDesktopRender) ? '50%' : '49%',
                            transform: "translateX(-50%)",
                            fontSize: "2rem",
                            whiteSpace: "nowrap",
                        }}
                        data-test="loyalty-content.how_much_earned.amount"
                    >
                        {`-${estimatedLoyaltyOrderContent.howMuchEarned}`}
                    </Typography>

                    <Typography
                        variant="h3"
                        style={{
                            color: "white",
                            position: "absolute",
                            top: isDesktopRender ?
                                (isGetrestoTheme ? "83%" : "80%")
                                :
                                (isGetrestoTheme ? "81.5%" : "78%"),
                            left: "49%",
                            transform: "translateX(-50%)",
                            fontSize: "1.1rem",
                        }}
                        data-test="loyalty-content.how_much_earned.type"
                    >
                        {
                            estimatedLoyaltyConfigContent.isCashback ?
                                intl.formatMessage({ id: "loyalty.type.cashback" })
                                :
                                intl.formatMessage({ id: "loyalty.type.loyalty" })
                        }
                    </Typography>

                </Box>
            }

            <Box
                display="flex"
                flexDirection="column"
                textAlign="center"
                alignItems="center"
                width={1}
            >

                <Box
                    display="flex"
                    flexDirection="column"
                    width={.85}
                >

                    {/* B */}
                    {
                        isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        && loyaltyLoginState === LoyaltyLoginState.CONNECTED_MASTER
                        &&

                        <Typography
                            variant="h2"
                            color="textSecondary"
                            style={{
                                lineHeight: "1.5rem",
                            }}
                        >
                            {intl.formatMessage({ id: "loyalty_page.confirmation" })}
                        </Typography>

                    }

                    {/* C */}
                    {
                        !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        && estimatedLoyaltyOrderContent
                        && Boolean(estimatedLoyaltyOrderContent.howMuchEarned)
                        &&

                        <Typography
                            variant="h2"
                            color="textSecondary"
                            style={{
                                lineHeight: "1.5rem",
                                marginTop: theme.spacing(1.5),
                            }}
                        >
                            {intl.formatMessage({
                                id: "loyalty_page.description_1"
                            }, {
                                a: (endAuthModal && estimatedLoyaltyOrderContent.canUseInCurrentOrder) ?
                                    intl.formatMessage({ id: "loyalty_page.description_1.current" })
                                    :
                                    intl.formatMessage({ id: "loyalty_page.description_1.next" })
                            })}
                        </Typography>
                    }

                    {/* D */}
                    {
                        isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        && (
                            loyaltyLoginState === LoyaltyLoginState.ANONYMOUS
                            || loyaltyLoginState === LoyaltyLoginState.CONNECTED_NOT_MASTER
                        )
                        && !endAuthModal
                        &&

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Typography
                                variant="h2"
                                color="textSecondary"
                                style={{
                                    lineHeight: "1.5rem",
                                }}
                            >
                                {intl.formatMessage({
                                    id: "loyalty_page.already_used"
                                }, {
                                    a: getMaskedAddressOfLoyaltyOperationOwner() !== null ?
                                        ":"
                                        :
                                        intl.formatMessage({ id: "loyalty_page.someone_else" })
                                })}
                            </Typography>

                            {getMaskedAddressOfLoyaltyOperationOwner() !== null &&
                                <Typography
                                    variant="h2"
                                    color="textSecondary"
                                    style={{
                                        lineHeight: "1.5rem",
                                    }}
                                >
                                    {getMaskedAddressOfLoyaltyOperationOwner()}
                                </Typography>
                            }
                        </Box>

                    }

                    {/* G */}
                    {
                        (
                            (
                                !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                                && (
                                    loyaltyLoginState === LoyaltyLoginState.ANONYMOUS
                                    || loyaltyLoginState === LoyaltyLoginState.CONNECTED_MASTER
                                )
                            )
                            || (
                                isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                                && loyaltyLoginState === LoyaltyLoginState.CONNECTED_MASTER
                            )
                        ) &&

                        <Box marginTop={1.5}>
                            <LoyaltyExplaination asText />
                        </Box>
                    }
                </Box>

                {/* E */}
                {
                    isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                    && loyaltyLoginState === LoyaltyLoginState.CONNECTED_MASTER
                    &&

                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width={1}
                        marginTop={5}
                        overflow="hidden"
                        style={{
                            border: "1px solid",
                            borderColor: theme.palette.grey[400],
                            borderRadius: "10px",
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                paddingTop={2}
                                paddingBottom={1.5}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: theme.palette.text.disabled,
                                        marginBottom: theme.spacing(1)
                                    }}
                                >
                                    {intl.formatMessage({
                                        id: "loyalty_page.card.title"
                                    }, {
                                        a: estimatedLoyaltyConfigContent?.isCashback ?
                                            intl.formatMessage({ id: "loyalty.type.cashback" })
                                            :
                                            intl.formatMessage({ id: "loyalty.type.loyalty" })
                                    })}
                                </Typography>

                                <Typography
                                    variant="h1"
                                    color="primary"
                                    style={{
                                        fontSize: "3.5rem",
                                    }}
                                >
                                    {getLoyaltyCurrentBalance(
                                        user_authentication_state.user?.loyalty_balance,
                                        selectedLocation?.loyalty,
                                        estimatedLoyaltyConfigContent,
                                        intl,
                                    )}
                                </Typography>
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                bgcolor={theme.palette.background.default}
                                width={1}
                                style={{
                                    padding: theme.spacing(1.2, 0.5)
                                }}
                            >
                                <Typography variant="body1" color="textPrimary">
                                    {user_authentication_state.user?.email}
                                </Typography>

                                <AuthenticationBottomMessages
                                    messagesToDisplay={[AuthenticationBottomMessage.NOT_YOU_LOGOUT]}
                                    customMargin={0}
                                />
                            </Box>

                        </Box>
                    </Box>
                }

                <Box
                    display="flex"
                    flexDirection="column"
                    width={.85}
                >
                    {/* H */}
                    {!(<ProvidersDiscounts />)
                        && !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        && loyaltyLoginState === LoyaltyLoginState.ANONYMOUS
                        &&

                        <Typography
                            variant="body1"
                            color="textPrimary"
                            style={{ marginTop: theme.spacing(2) }}
                        >
                            {intl.formatMessage({ id: "loyalty_page.description_2" })}
                        </Typography>
                    }
                </Box>
            </Box>
        </Box >
    );
}

export default LoyaltyContent