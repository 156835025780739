import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, FormControlLabel, IconButton, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DeviceContext } from '../../App';
import { DeviceContextModel } from '../../config/useDeviceContext';
import { Product } from '../../my-lemonade-library/model/Catalog';
import { OrderItem as OrderItemType, OrderItemExtended, OrderOption } from '../../my-lemonade-library/model/Order';
import { moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import OrderAction, { orderActions } from '../redux/OrderActions';
import PlusMinusQuantity from './PlusMinusQuantity';
import ReadOnlyProductDisplay from './ReadOnlyProductDisplay';
import TextInputBottomModal from './TextInputBottomModal';

type OrderItemProps = {

    orderItem: OrderItemType;

    readOnly: boolean;
    readOnlyDisabled?: boolean;  // grey out the item only if it's readOnly
    readOnlyPendingPayment?: boolean;  // Displays a chip to indicate that the item is pending payment

    selectedItems?: OrderItemToPay[];
    onSelect?: (val: OrderItemToPay[]) => void;

    moreDetails: boolean;
    isSharePayment?: boolean;
    alignWithCheckBoxes?: boolean;
}

/**
 * Component use to display one item like a product or a product from a HappyHour deal
 * contain + & - button to add or remove product
 */
const OrderItemDisplay: React.FC<OrderItemProps> = (props) => {

    const { orderItem, readOnly, readOnlyDisabled, readOnlyPendingPayment, moreDetails, isSharePayment = false,
        selectedItems, onSelect, alignWithCheckBoxes } = props;

    const { selectedCatalog, selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations)
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { desktop_device } = useContext<DeviceContextModel>(DeviceContext)

    const [productSelected, setProductSelected] = useState<Product>()
    const [quantity, setQuantity] = useState<number>(orderItem.quantity)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const dispatch = useDispatch();

    const theme = useTheme();
    const intl = useIntl();

    // TODO: why keep both if the same object ?
    const orderItemExt: OrderItemExtended = orderItem as OrderItemExtended

    // TODO: useMemo
    useEffect(() => {

        if (orderItem && selectedCatalog) {

            const productList: Product[] = selectedCatalog.data.products;
            const product = productList.find(pdt => pdt.ref === orderItem.product_ref);

            if (product) {
                setProductSelected(product)
            }
            // If the product is not found in the catalog but the item
            // has already been sent (or is retrieved from a POS without catalog),
            // create a minimal product based on the item
            else if (orderItem.update_id) {

                const newProduct: Product = {
                    category_ref: "",
                    skus: [{
                        name: orderItem.product_name ?? orderItem.sku_name ?? "",
                        price: orderItem.price,
                        ref: orderItem.product_ref ?? orderItem.sku_ref,
                    }],
                    ref: orderItem.product_ref ?? orderItem.sku_ref,
                    name: orderItem.product_name ?? orderItem.sku_name ?? ""
                }

                setProductSelected(newProduct);
            }
        }
    }, [orderItem, selectedCatalog])

    useEffect(() => {
        if (selectedItems) {
            const index = selectedItems.findIndex(item => item.index === orderItemExt.index)
            if (index !== -1) {
                setIsChecked(true)
                if (selectedItems[index].quantity !== quantity) {
                    setQuantity(selectedItems[index].quantity)
                }
            } else {
                setIsChecked(false)
            }
        }
    }, [selectedItems])


    const handleCheckbox = (item: OrderItemExtended) => {
        if (onSelect) {
            let itemsToPayArray: OrderItemToPay[]
            if (!isChecked) {
                itemsToPayArray = [{ index: item.index, quantity }]
            } else {
                itemsToPayArray = [{ index: item.index, quantity: 0 }]
            }
            onSelect(itemsToPayArray)
        }
    }

    const getTotal = () => {
        if (selectedCatalog) {
            let price: number = moneyToNumber(orderItem.price)

            if (orderItem.options_price) {
                price += moneyToNumber(orderItem.options_price)
            }

            const total = price * (readOnly ? orderItem.quantity : quantity)
            return numberToMoney(total, selectedCatalog.currency)
        }
        return ''
    }

    const skuName = (orderItem: OrderItemType) => {
        if (productSelected) {
            const sku = productSelected.skus.find(sk => sk.ref === orderItem.sku_ref)
            return sku?.name
        } else {
            return ""
        }
    }

    const addItem = () => {
        if (selectedCatalog && selectedLocation) {
            if (isSharePayment) {
                const nQuantity = quantity + 1
                if (isChecked && onSelect) {
                    const itemsToPayArray = [{ index: orderItemExt.index, quantity: nQuantity }]
                    onSelect(itemsToPayArray)
                }
                setQuantity(nQuantity)
            } else {
                dispatch(OrderAction.addItem(
                    orderItem.product_ref,
                    orderItem.sku_ref,
                    orderItem.options,
                    selectedCatalog,
                    selectedLocation,
                    selectedTable,
                    orderItem.product_name,
                    1,
                    orderItem.price,
                    "",
                    data.user_authentication_state.user?.uid ? data.user_authentication_state.user.uid : undefined,
                ));
            }
        }
    }

    const removeItem = () => {
        if (selectedCatalog && selectedLocation) {
            if (isSharePayment) {
                const nQuantity = quantity - 1
                if (isChecked && onSelect) {
                    const itemsToPayArray = [{ index: orderItemExt.index, quantity: nQuantity }]
                    onSelect(itemsToPayArray)
                }
                setQuantity(nQuantity)
            } else {
                dispatch(OrderAction.removeItem(orderItem.sku_ref, orderItem.options, selectedCatalog, selectedLocation, selectedTable));
            }
        }
    }

    const setText = (text: string) => {
        dispatch(orderActions.updateItemNote(orderItem.product_ref, orderItem.sku_ref, text))
    }

    const getProductName = (): string => {
        const messageId: string = translationService.getProductNameTranslationKeyFromRef(orderItem.product_ref) ?? "";

        if (!intl.messages[messageId] && orderItem.update_id) {
            // No translation but update ID? -> ignore the error
            return orderItem.product_name ?? orderItem.sku_name;
        }

        return intl.formatMessage({
            id: messageId,
            defaultMessage: orderItem.product_name
        });
    }

    const displayProductOptions = (): string => {
        return orderItem.options.map((elem: OrderOption) => elem.name).join(" - ")
    }

    if (!productSelected || !orderItem) {
        return null
    }

    return (
        <Box
            width="100%"
            px={3}
            py={1}
            border="solid"
            pl={alignWithCheckBoxes && !readOnly ? 7 : 3}
            style={{
                borderWidth: "0em 0em .1em 0em",
                borderColor: theme.palette.grey[100]
            }}
        >

            {readOnly && orderItem.subtotal
                ? <ReadOnlyProductDisplay
                    name={getProductName()}
                    quantity={orderItem.quantity}
                    productRef={orderItem.product_ref}
                    price={MoneyToStringWithSymbol(
                        isSharePayment
                            ? getTotal()
                            : orderItem.subtotal
                    )}
                    details={displayProductOptions()}
                    disabled={readOnlyDisabled}
                    pendingPayment={readOnlyPendingPayment}
                />
                : <Box>
                    <Box m={theme.spacing(0, 0, 2)}>

                        <Box display="flex" flexDirection="row">
                            <Box
                                display="flex"
                                flex={1}
                                flexDirection="column"
                                pr={3}
                            >
                                {isSharePayment
                                    ? <FormControlLabel
                                        control={
                                            <Checkbox
                                                data-test="orderItem_checkBox_select"
                                                icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                                checkedIcon={<CheckCircleIcon color='primary' />}
                                                checked={isChecked}
                                                onChange={() => handleCheckbox(orderItemExt)}
                                                style={{ height: '30px' }}
                                            />}
                                        label={
                                            <Typography variant='subtitle1' color="textPrimary">
                                                {`${orderItem.quantity}x ${intl.formatMessage({ id: orderItemExt.product_name })}`}
                                            </Typography>
                                        }
                                    />

                                    : <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        data-test={`orderItem.name.ref_${orderItem.product_ref}`}
                                    >
                                        {getProductName()}
                                    </Typography>
                                }

                                <Box
                                    lineHeight="1em"
                                    ml={isSharePayment ? 4 : 0}
                                >
                                    {productSelected.skus.length > 1 &&
                                        <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                                            {skuName(orderItem)}
                                        </Typography>
                                    }


                                    <Box
                                        style={
                                            !moreDetails
                                                ? {
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 2,
                                                }
                                                : {}
                                        }
                                        data-test={`orderItem.options.ref_${orderItem.product_ref}`}
                                    >
                                        <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                                            {displayProductOptions()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <>
                                {
                                    (!isSharePayment || orderItemExt.quantity > 1) ?
                                        <PlusMinusQuantity
                                            value={isSharePayment ? quantity : orderItem.quantity}
                                            onAdd={() => addItem()}
                                            onMinus={() => removeItem()}
                                            min={isSharePayment ? 1 : undefined}
                                            max={isSharePayment ? orderItemExt.quantity : undefined}
                                        />
                                        : <></>
                                }
                            </>
                        </Box>
                        {
                            orderItem.customer_notes &&
                            <Box display="flex" alignItems="center">
                                <Typography variant="caption" style={{ color: theme.palette.text.disabled, flex: 1 }}>
                                    {orderItem.customer_notes}
                                </Typography>
                                <IconButton
                                    style={{ padding: 0, margin: 0 }}
                                    onClick={() => setOpenModal(true)}
                                    size="large">
                                    <CreateIcon style={{ fontSize: "20px" }} />
                                </IconButton>
                            </Box>
                        }
                    </Box>

                    {
                        orderItem.subtotal &&
                        <Box display="flex" flexDirection="row">

                            <Box
                                ml={isSharePayment && !readOnly ? 4 : 0}
                                display="flex"
                                flex={1}
                            >
                                <Typography color="textPrimary" variant="subtitle1">
                                    {intl.formatMessage({ id: "Price" })}
                                </Typography>
                            </Box>

                            <Typography color="textPrimary" variant="subtitle1">
                                {MoneyToStringWithSymbol(
                                    isSharePayment
                                        ? getTotal()
                                        : orderItem.subtotal
                                )}
                            </Typography>

                        </Box>
                    }
                </Box>
            }
            <TextInputBottomModal
                open={openModal}
                setOpen={setOpenModal}
                desktop={desktop_device}
                text={orderItem.customer_notes ? orderItem.customer_notes : ""}
                setText={setText}
                titleId={"product_modal.add_product_note"}
                placeHolderId={"Order.cart.customer_notes.dialog.placeholder"}
            />
        </Box >
    );
}

export default OrderItemDisplay;