import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Categorie } from '../../my-lemonade-library/model/Catalog';
import SwiperItem from './SwiperItem';

export var betweenSpace = 10;
export var height = 30; //If this height has to change, modify the padding prop of GridComponent


interface CategorieSwiperProps {
    currentRef: string,
    onSelect: any,
    categoryList: Categorie[],
    grid?: boolean // TODO Louis : remove if useless
}

/**
 * Component able to list the subCategories in line on a product page
 * @param props 
 */
const CategorieSwiper: React.FC<CategorieSwiperProps> = (props) => {

    const {
        currentRef,
        onSelect,
        categoryList,
    } = props


    const [selectedIndex, setIndex] = useState(categoryList.findIndex(elem => elem.ref === currentRef))

    useEffect(() => {
        setIndex(categoryList.findIndex(elem => elem.ref === currentRef))
    }, [currentRef, categoryList])


    const handleSelect = (index: any, ref: any) => {
        setIndex(index)
        onSelect(ref)
    }

    if (categoryList.length === 0) {
        return <></> //Nothing if empty
    }

    return (

        <Box
            p={1}
            m={0}
            display={'flex'}
            flexWrap={"nowrap"}
            overflow={'scroll'}
            width="100%"
        >

            {categoryList.map((elem: Categorie, index: number) =>

                <SwiperItem
                    selected={selectedIndex === index} //this.
                    key={index}
                    elem={elem}
                    onClick={(ref: any) => handleSelect(index, ref)}
                />

            )}

        </Box>

    )

}

export default CategorieSwiper