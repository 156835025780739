import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { MAX_HEIGHT_CUSTOMER_FORM } from '../configs/variables';
import AuthenticationActions from "../redux/AuthenticationActions";


interface VerifyEmailProps {
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}

const VerifyEmail: React.FC<VerifyEmailProps> = (props) => {

    const { setOnModalCloseAction } = props

    const [displayHelperText, setDisplayHelperText] = useState<boolean>(false)

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const onSendEmail = () => {
        dispatch(AuthenticationActions.sendVerificationEmail());
    }

    const onCloseEmailVerificationForm = useCallback(() => {
        dispatch(AuthenticationActions.signoutUser());
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
        ));
    }, []);

    useEffect(() => {
        setOnModalCloseAction({
            formToDisplay: CustomerInformationModalFormToDisplay.VERIFY_EMAIL,
            onClose: onCloseEmailVerificationForm
        })
    }, [onCloseEmailVerificationForm])

    const helperTimer = () => {
        setDisplayHelperText(true)
        setTimeout(() => {
            setDisplayHelperText(false)
        }, 5000)
    }

    return (
        <Box
            width="100%"
            display='flex'
            flexDirection='column'
            maxWidth={MAX_HEIGHT_CUSTOMER_FORM}
            data-test="customer.modal.verify_email"
        >

            <Box justifyContent="flex-end" display="flex">

                <IconButton
                    data-test="customer.modal.verify_email.close_button"
                    style={{ padding: 0 }}
                    aria-label="Close box"
                    onClick={onCloseEmailVerificationForm}
                    size="large">
                    <Close />
                </IconButton>

            </Box>

            <Box mb={2} textAlign="center">

                <Typography variant="h2">
                    {intl.formatMessage({ id: "authentication.verify_email.title" }, { br: <br /> })}
                </Typography>

            </Box>

            <Box
                display="flex"
                flexDirection="column"
                paddingX={2}
            >

                <Box p={1}>

                    <Typography variant="body1">
                        {intl.formatMessage({ id: "authentication.verify_email.has_been_sent" })}
                    </Typography>

                    <Box mt={2}></Box>

                    <Typography variant="body1" style={{ color: theme.palette.text.disabled }}>
                        {intl.formatMessage({ id: "authentication.verify_email.check_spam" })}
                    </Typography>

                </Box>

                <Box mt={4} display="flex" justifyContent="center">

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            helperTimer();
                            onSendEmail();
                        }}
                        disabled={displayHelperText}
                        style={{
                            width: "100%",
                            textTransform: "none",
                        }}
                    >
                        {intl.formatMessage({
                            id: displayHelperText ?
                                "authentication.verify_email.helper.email_send"
                                : "authentication.verify_email.send_email"
                        })}
                    </Button>

                </Box>
            </Box>
        </Box>
    );
}

export default VerifyEmail;