import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";

/**
 * Key used in route or query params
 */
export const ACCOUNT_KEY = 'accountId';
export const ACCOUNT_PARAM = `:${ACCOUNT_KEY}`;
export const ACCOUNT_ROUTE = `${ROUTES_PREFIX_V1}/accounts/${ACCOUNT_PARAM}`;
export const ACCOUNT_STATUS = `${ACCOUNT_ROUTE}/status`;

export type AccountRequestParams = { [ACCOUNT_KEY]: string }

const accountsApiRoutes = {
    ACCOUNT_ROUTE,
    ACCOUNT_STATUS,
};
export default accountsApiRoutes;