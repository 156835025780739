import { Typography } from '@mui/material';
import { PRICE_TAG_COLOR } from '../../config/color';
import { Money, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';


/**
 * Little component render a product price with background
 * @param price the price to display, formatted in Money
 */
const Price = (props: { price: Money, reduced: boolean, desktop?: boolean }) => {

    return (
        <div style={{
            ...style,
            position: props.desktop ? "unset" : "absolute",
            backgroundColor: PRICE_TAG_COLOR,
            display: "flex",
            alignItems: "center"
        }}
            className="rectanglePrice"
        >
            <Typography variant="subtitle1">
                {MoneyToStringWithSymbol(props.price)}
            </Typography>
        </div>
    );
}

const style: React.CSSProperties = {
    position: "absolute",
    top: "7%",
    right: "0",
    paddingRight: "5px",
    paddingLeft: "10px",
    width: "auto",
    height: "auto",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    textAlign: "center",
    fontFamily: "Lato",
    color: "#ffff",
    fontWeight: "bold",
    whiteSpace: "nowrap"
}

export default Price

