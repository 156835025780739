import { Box, MenuItem, Select, Typography, useTheme } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AUTHENTICATION_MODAL } from '../../Common/configs/Zindex';
import { Table } from '../../my-lemonade-library/model/Location';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

interface TableSelectorProps {
    tableIdSelected: string | null,
    setTableIdSelected: Dispatch<SetStateAction<string | null>>
}

const TableSelector: React.FC<TableSelectorProps> = (props) => {

    const { tableIdSelected, setTableIdSelected } = props;

    const { data } = useTypedSelector((state: RootState) => state.table)
    const intl = useIntl();
    const theme = useTheme();
    const [tables, setTables] = useState<Table[] | null>(null)

    useEffect(() => {
        if (data) {
            let theseTables = Object.values(data);
            theseTables = theseTables.sort((a, b) => {
                if (!a.name) {
                    return -1
                }
                if (!b.name) {
                    return -1
                }
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setTables(theseTables)
        } else {
            setTables(null);
        }
    }, [data]);

    if (data) {

        return (

            <Box display="flex" flexDirection="column" alignItems="center" width="100%">

                <Box pt={2} textAlign="center">

                    <Typography variant="body1">
                        {intl.formatMessage({ id: "table.table_selector.choose_table" })}
                    </Typography>

                </Box>

                <Box width={1} display='flex' justifyContent="stretch" style={{ padding: theme.spacing(2, 0, 2, 0) }}>

                    <Select
                        inputProps={{
                            style: {
                                width: "100%"
                            }
                        }}
                        style={{
                            width: "100%"
                        }}
                        MenuProps={{
                            style: { zIndex: AUTHENTICATION_MODAL }
                        }}
                        value={tableIdSelected ? tableIdSelected : ""}
                        onChange={(e) => setTableIdSelected(e.target.value as string)}
                    >
                        {tables?.map((table: Table, index: number) => (
                            <MenuItem key={index} value={table.id}>{table.name}</MenuItem>
                        ))}
                    </Select>

                </Box>
            </Box>
        )
    }
    return null
}

export default TableSelector;