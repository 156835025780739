import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Carousel from 'react-material-ui-carousel';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { useTypedSelector } from '../../redux/root-reducer';
import BestSellerCarouselItem from '../component/BestSellerCarouselItem';

interface BestSellerProps { }

/*
Component in charge to scan best-seller active in catalog, render them in BestCaroussell component
render by Categories.tsx
*/
const BestSeller: React.FC<BestSellerProps> = (props) => {

    let { selectedCatalog, selectedLocation } = useTypedSelector(state => state.locations)

    // All the bestseller items of the catalog.
    const best_seller = selectedCatalog?.data.products.filter(
        (item: ProductExtended) => {
            return (
                item.best_seller === true
                && !item.disable
            );
        }
    );
    //[{price: "4.50 EUR", nom: "Limonade", description: "Limonade - Citron - Menthe"},{price: "4.50 EUR", nom: "Biere", description: "Menthe a l'eau"}]

    let supportedService = selectedLocation?.supported_service_types
    let interactif = true
    const [autoPlay, setAutoPlay] = useState<boolean>(true)
    const intl = useIntl();

    //Le flag doit etre renseigné, avec un tableau non vide et contenir seulement view pour que l'app ne soit pas interactive
    if (supportedService && supportedService.length === 1 && supportedService[0] === SupportedServiceType.VIEW) {
        interactif = false
    }
    if (!best_seller || best_seller.length === 0) {
        return null
    }

    return (

        <Box>

            <Box m={1} mb={2} ml={4}>

                <Typography variant="h5" color="textSecondary">
                    {intl.formatMessage({ id: "CategoriesPage.bestseller" })}
                </Typography>

            </Box>

            {/* TODO: replace with a more simple carousel module? Like react-carousel? */}
            <Carousel
                autoPlay={autoPlay}
                swipe={false}
            >

                {best_seller.map((elem, index) =>

                    <BestSellerCarouselItem
                        key={index}
                        item={elem}
                        interactif={interactif}
                        autoPlay={autoPlay}
                        setAutoPlay={setAutoPlay}
                    />
                )}

            </Carousel>

        </Box>
    )
}

export default BestSeller;