import { Box, TextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import FormikErrorMessage from './FormikErrorMessage'

type FormikTextInputProps = {

    name: string

} & Omit<TextFieldProps, 'value' | 'onChange'>


const FormikTextInput: React.FC<FormikTextInputProps> = (props) => {

    const { name, ...rest } = props;

    const [field, meta] = useField<string | undefined>(name);
    return (

        <Box width={1}>

            <TextField
                name={name}
                data-test={name}
                value={field.value ?? ''}
                onChange={field.onChange}
                error={meta.touched && Boolean(meta.error)}
                {...rest}
                fullWidth
                margin='none'
            />

            {meta.touched && Boolean(meta.error) &&
                <Box marginTop={.6} marginLeft={1} data-test={`${(props.inputProps && props.inputProps['data-test']) ? props.inputProps['data-test'] : props.name}_error`}>
                    <FormikErrorMessage name={name} />
                </Box>
            }

        </Box>
    )
}

export default FormikTextInput;