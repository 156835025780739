import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Dialog, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Customer } from '../../my-lemonade-library/src/authentications/models/Customer';

interface UpdateUserInfosProps {
    open: boolean
    handleOpen: (bool: boolean) => void
    userParameter: string,
    customer: Customer
    onSave: (customer: Customer) => void
}

const UpdateUserInfos = (props: UpdateUserInfosProps) => {

    const {
        open,
        handleOpen,
        userParameter,
        customer,
        onSave
    } = props

    const intl = useIntl()
    const theme = useTheme()

    const [updatedCustomer, setUpdatedCustomer] = useState<Customer>(customer)

    const handleChange = (str: string) => {
        if (userParameter === 'last_name') {
            setUpdatedCustomer({
                ...updatedCustomer,
                last_name: str
            })
        } else if (userParameter === 'first_name') {
            setUpdatedCustomer({
                ...updatedCustomer,
                first_name: str
            })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleOpen(false)}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent='center'
                alignItems='center'
                textAlign='start'
                marginTop={1}
                p={3}
                gap={2}
            >

                <IconButton
                    style={{
                        position: 'absolute',
                        color: theme.palette.text.disabled,
                        top: 0,
                        right: 0,
                    }}
                    onClick={() => handleOpen(false)}
                >
                    <CloseRoundedIcon />
                </IconButton>
                <Typography variant='subtitle1'>
                    {intl.formatMessage({ id: `auth.update.${userParameter}` })}
                </Typography>

                <TextField
                    value={
                        userParameter === 'first_name'
                            ? updatedCustomer.first_name
                            : updatedCustomer.last_name
                    }
                    onChange={(e) => handleChange(e.target.value)}
                    fullWidth={true}
                    variant='outlined'
                />

                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        textTransform: "none",
                        width: "80%",
                        padding: theme.spacing(1, 0),
                    }}
                    onClick={() => onSave(updatedCustomer)}
                >
                    {intl.formatMessage({ id: 'common.save' })}
                </Button>
            </Box>
        </Dialog>
    )
}

export default UpdateUserInfos