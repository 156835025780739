// This file exports a constant object `appTitles` that maps string keys to string values.
// These keys and values represent different titles used in the application.

export const appTitles: { [key: string]: string } = {
    // These keys represent different deployment names and their corresponding display names.
    "deployment_mylemonade": "My Lemonade",
    "deployment_alleatone": "Fyre",
    "deployment_fyre": "Fyre",
    "deployment_getresto": "GetResto",
    "deployment_easycom": "MAE",
    "deployment_lyf": "Lyf",

    // These keys represent different app titles and their corresponding display names.
    "mylemonade_title": "My Lemonade App",
    "alleatone_title": "Fyre App",
    "fyre_title": "Fyre App",
    "getresto_title": "GetResto App",
    "easycom_title": "MAE App",
    "lyf_title": "Lyf App",

    // These keys represent different 'powered by' messages and their corresponding display names.
    "powered_by_mylemonade": "Powered by My Lemonade",
    "powered_by_alleatone": "Powered by Fyre",
    "powered_by_fyre": "Powered by Fyre",
    "powered_by_getresto": "Powered by GetResto",
    "powered_by_easycom": "Powered by MAE",
    "powered_by_lyf": "Powered by Lyf",
}