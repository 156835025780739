import { MylemonadeContext } from "../../src/common/models/MyLemonadeContext";

export interface Log extends MylemonadeContext {

    message: string;

    level: LogLevels;
}

/**
 * See https://cloud.google.com/logging/docs/structured-logging
 * See https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
 */
export interface GoogleCloudLog extends Log {
    severity: "DEBUG" | "INFO" | "NOTICE" | "WARNING" | "ERROR" | "CRITICAL" | "ALERT" | "EMERGENCY";
}

export interface Logs {
    logs: Log[];
}

export enum LogLevels {
    DEBUG = "debug",
    INFO = "info",
    WARN = "warn",
    ERROR = "error"
}

