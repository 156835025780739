import { Box, Select, SelectProps } from "@mui/material";
import { useField } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import FormikErrorMessage from "./FormikErrorMessage";

type FormikSelectInputProps = {

    name: string;
    placeHolderId: string;
    renderOptions: () => JSX.Element | JSX.Element[];

} & Omit<SelectProps, 'value' | 'onChange'>

const FormikSelectInput: React.FC<FormikSelectInputProps> = (props) => {

    const {
        name,
        placeHolderId,
        renderOptions,
        ...rest
    } = props;

    const intl = useIntl();

    const [field, meta] = useField<string | undefined>(name);

    return (

        <Box width={1}>

            <Select
                fullWidth
                name={name}
                data-test={name}
                value={field.value ?? ''}
                onChange={field.onChange}
                error={meta.touched && Boolean(meta.error)}
                {...rest}
                native
            >
                <option value={""} disabled>
                    {intl.formatMessage({ id: placeHolderId })}
                </option>
                {renderOptions()}
            </Select>

            <Box marginTop={.6} marginLeft={1}>
                <FormikErrorMessage name={name} />
            </Box>
        </Box>
    )
}

export default FormikSelectInput;