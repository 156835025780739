import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getCategoriesFullRoute, isHomePage } from '../../config/routes';
import { CustomerInformationModalFormToDisplay } from '../../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../../customers/redux/CustomerInformationModalActions';
import ProvidersDiscounts from '../../discount/ProvidersDiscounts';
import LocationLogo from '../../Locations/components/LocationLogo';
import { MAX_LOGO_HEIGHT, MAX_LOGO_WIDTH } from '../../Locations/configs/LocationConfig';
import { Location, SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { SignInProviders } from '../../my-lemonade-library/src/authentications/models/BaseUser';
import themeHelper from '../../my-lemonade-library/src/theme/services/ThemeHelper';
import LoyaltyContent from '../../orders/components/LoyaltyContent';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import AuthenticationFormParameters from '../models/AuthenticationFormParameters';
import { LocationAuthenticationConfig } from '../models/AuthenticationState';
import AuthenticationActions from '../redux/AuthenticationActions';
import SigninOrGuestButtons, { shouldDisplayGuestButton } from './SigninOrGuestButtons';

export const getOnlyAuthenticationProvider = (locationAuthenticationConfig: LocationAuthenticationConfig): SignInProviders | null => {
    if (!locationAuthenticationConfig.authentication_providers?.length) {
        return SignInProviders.PASSWORD;
    }
    else if (locationAuthenticationConfig.authentication_providers?.length === 1) {
        return locationAuthenticationConfig.authentication_providers[0];
    }
    return null;
}

export const getSigninTitleId = (mandatory: boolean, onlySigninProvider: SignInProviders | null) => {
    if (onlySigninProvider) {
        if (onlySigninProvider !== SignInProviders.PASSWORD) {
            if (mandatory) {
                return `authentication.signin.${onlySigninProvider}_mandatory`
            } else {
                return `authentication.signin.${onlySigninProvider}`
            }
        } else {
            if (mandatory) {
                return `authentication.signin.one_provider_mandatory`
            } else {
                return `authentication.signin.one_provider`
            }
        }
    } else {
        return "authentication.signin.title";
    }
}

export const getSigninLogo = (location: Location, onlySigninProvider: SignInProviders | null): string | undefined | null => {
    if (onlySigninProvider && onlySigninProvider === SignInProviders.GETRESTO) {
        return `${process.env.PUBLIC_URL}/assets/GetResto_Logo.svg`;
    } else if (location.logo) {
        return location.logo;
    }
    return null
}


interface AuthenticationAdvantagesFormDialogProps {
    shouldOpenAnotherFormAfter: (formToDisplay: CustomerInformationModalFormToDisplay) => boolean;
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}

const AuthenticationAdvantagesFormDialog: React.FC<AuthenticationAdvantagesFormDialogProps> = (props) => {

    const { setOnModalCloseAction } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const { tableLinkId, selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { estimatedLoyaltyOrderContent } = useTypedSelector((state: RootState) => state.loyalty);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { formParameters } = useTypedSelector((state: RootState) => state.customerInformationModal);

    const onlyAuthenticationProvider = getOnlyAuthenticationProvider(data.location_authentication_config);
    const authenticationFormParameters = formParameters as AuthenticationFormParameters | null;
    const shouldDisplayGuestButtonBool = shouldDisplayGuestButton(data, authenticationFormParameters?.forceMandatory);

    const onClose = useCallback(() => {

        if (selectedTable.service_type === SupportedServiceType.VIEW) {
            // If it's the view mode, it does not make sense to force the user to authenticate, go to categories
            history.push(getCategoriesFullRoute(tableLinkId));

        } else {
            // We're displaying the WELCOME_AUTHENTICATION form, it means that the user wants
            // to continue as a guest

            // If the user is not forced to authenticate, we can let him go further. Else, the continueAsGuest will handle
            // this and propose again and again.
            const isEndAuthentication = !isHomePage(history.location.pathname, tableLinkId);
            dispatch(AuthenticationActions.continueAsGuest(isEndAuthentication));
        }
        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES));

    }, [dispatch, selectedTable]);

    useEffect(() => {
        setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES, onClose: onClose })
    }, [onClose])

    if (!selectedLocation) {
        return null;
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            data-test="customer.modal.welcome_authentication"
            width={1}
        >
            {
                (isHomePage(history.location.pathname, tableLinkId)) ?
                    <LocationLogo
                        titleId={themeHelper.shouldDisplayWelcomeText(selectedLocation) ? "homeForm.welcome1" : ""}
                        mb={2}
                    /> :
                    <Box
                        mt={1}
                        mb={1}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center">
                        {/** Signin provider logo */}
                        {getSigninLogo(selectedLocation, onlyAuthenticationProvider) ?
                            <Box mb={2}>
                                <img width="100%"
                                    src={getSigninLogo(selectedLocation, onlyAuthenticationProvider) ?? ""}
                                    style={{
                                        objectFit: 'scale-down',
                                        maxWidth: MAX_LOGO_WIDTH,
                                        maxHeight: MAX_LOGO_HEIGHT
                                    }}
                                    alt="" />
                            </Box> : null}
                        <Typography variant="h1">
                            {intl.formatMessage({ id: getSigninTitleId(!shouldDisplayGuestButtonBool, onlyAuthenticationProvider) })}
                        </Typography>
                    </Box>
            }

            <Box
                display="flex"
                flexDirection="column"
                width={1}
                paddingX={2}
            >
                {shouldDisplayGuestButtonBool
                    ? <>
                        {(
                            selectedLocation.loyalty
                            && !selectedLocation.loyalty.disabled
                            && estimatedLoyaltyOrderContent?.howMuchEarned
                        )

                            ? <Box marginBottom={4}>
                                <LoyaltyContent endAuthModal />
                            </Box>

                            : <></>
                        }

                        {!authenticationFormParameters?.hideIncentives ? <ProvidersDiscounts /> : null}
                    </>
                    : <Box width={1} mt={1}>
                        <Typography variant="body1" align="left">
                            {intl.formatMessage({
                                id: authenticationFormParameters?.forPayment
                                    ? `authentication.authentication_form.welcomAuthentication.mandatory.payment.explanation`
                                    : `authentication.authentication_form.welcomAuthentication.mandatory_explanation_${onlyAuthenticationProvider ?? SignInProviders.PASSWORD}`
                            })}
                        </Typography>
                    </Box>
                }

                <SigninOrGuestButtons
                    shouldOpenAnotherFormAfter={props.shouldOpenAnotherFormAfter}
                    forceMandatory={authenticationFormParameters?.forceMandatory}
                />
            </Box>
        </Box>
    )
}

export default AuthenticationAdvantagesFormDialog;

