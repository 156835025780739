import { Box, Table, TableCell, TableContainer, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { convertDayOfWeekToLocalizedString } from '../../Common/helper/Restriction';
import { List } from "../../Common/StyledComponents";
import { desktopDisplayBreakpoint } from '../../config/theme';
import OrderHeader from '../../orders/components/OrderHeader';
import { useTypedSelector } from '../../redux/root-reducer';

/**
 * Component in charge to display contact information and restriction of a location
 * 
 */
export const LocationContact = () => {

    const { selectedLocation } = useTypedSelector(state => state.locations)
    const dayNum: string[] = ["1", "2", "3", "4", "5", "6", "7"];
    const intl = useIntl();

    const theme = useTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint))

    if (selectedLocation) {

        return (

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'baseline'}
                p={2}
                height={'100%'}
                style={{
                    backgroundColor: isDesktopRender ? theme.palette.background.paper : theme.palette.background.default
                }}
            >

                <OrderHeader titleId={'Information de contact'} />

                <Box
                    display="flex"
                    width={isDesktopRender ? '80%' : '100%'}
                    m={"0 auto"}
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    height="100%"
                >

                    <Box
                        display="flex"
                        flexDirection={isDesktopRender ? 'row' : 'column'}
                        width="100%"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                    >

                        <List style={{ alignItems: 'flex-start' }}>

                            <Box>

                                <Typography style={{ marginBottom: theme.spacing(1) }}>
                                    E-mail :
                                </Typography>

                                <Typography style={{ margin: 0, paddingLeft: theme.spacing(1) }}>
                                    {selectedLocation?.email ? selectedLocation?.email : intl.formatMessage({ id: "locations.email.not_found" })}
                                </Typography>
                            </Box>

                            <Box>

                                <Typography style={{ marginBottom: theme.spacing(1) }}>
                                    Téléphone :
                                </Typography>

                                <Typography style={{ margin: 0, paddingLeft: theme.spacing(1) }}>
                                    {/* TODO: localize */}
                                    {selectedLocation?.phone ? selectedLocation?.phone : intl.formatMessage({ id: "locations.phone.not_found" })}
                                </Typography>

                            </Box>

                        </List>

                        <Box flexGrow={1} width="inherit">

                            <Typography style={{ marginBottom: theme.spacing(1) }}>
                                Adresse :
                            </Typography>

                            <Typography style={{ margin: 0, paddingLeft: theme.spacing(1) }}>
                                {selectedLocation.address ? selectedLocation.address : "Pas d'adresse renseignée"}
                            </Typography>

                        </Box>

                    </Box>

                    <List style={{ alignItems: "flex-start" }}>

                        <Typography style={{ marginBottom: theme.spacing(1) }}>
                            Horaire :
                        </Typography>

                        <TableContainer component={Box}>

                            <Table style={{ width: isDesktopRender ? '70%' : '100%' }}>

                                {
                                    selectedLocation?.restriction ?

                                        dayNum && dayNum.map((day, index) => (

                                            <TableRow key={index} style={{ border: 'none' }}>

                                                <TableCell style={{ padding: theme.spacing(1, 1, 1, 0), border: 'none' }}>

                                                    <Typography>
                                                        {convertDayOfWeekToLocalizedString(day)}
                                                    </Typography>

                                                </TableCell>

                                                {
                                                    selectedLocation?.restriction?.filter(res => res.dow?.includes(day)).length ?
                                                        (
                                                            <>
                                                                {
                                                                    selectedLocation?.restriction?.filter(res => res.dow?.includes(day)).map((res, i) => (

                                                                        <TableCell
                                                                            style={{ border: 'none', padding: theme.spacing(1, 1, 1, 1) }}
                                                                            align="left"
                                                                        >
                                                                            <Typography>
                                                                                {res.start_time?.replace(":", "h")}-{res.end_time?.replace(":", "h")}
                                                                            </Typography>

                                                                        </TableCell>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                        :
                                                        <TableCell
                                                            style={{ border: 'none', padding: theme.spacing(1, 1, 1, 1) }}
                                                            align="left"
                                                        >

                                                            <Typography>
                                                                {"Fermé"}
                                                            </Typography>

                                                        </TableCell>
                                                }
                                            </TableRow>
                                        ))
                                        : "Pas d'horaires renseignées"
                                }
                            </Table>

                        </TableContainer>

                    </List>

                </Box>

            </Box >
        )
    }
    return (
        <Typography>
            No location selected
        </Typography>
    )

}