import MylemonadeScopes from "./MyLemonadeScopes";

export default class HttpError extends Error {

    static SECRET_NOT_FOUND: HttpError = new HttpError("SECRET_NOT_FOUND", "Secret not found", 500);

    value: any

    // Some error types (ex: LocationApiError) still have httpErrorCode instead of statusCode. This is a temporary fix to support both.
    public httpErrorCode: number | undefined;

    constructor(public code: string, message: string, public statusCode: number, public scope?: MylemonadeScopes) {
        super()
        this.message = message
        this.value = ""
        Object.setPrototypeOf(this, HttpError.prototype);
    }

    withCustomMessage(message: string, value: any): HttpError {
        return new HttpError(this.code, message, this.statusCode, this.scope).withValue(value);
    }

    withValue(value: any): HttpError {
        this.value = value
        return this
    }

    getValue(): any {
        return this.value;
    }
}

export interface ErrorWithCode {
    code?: string;
    message: string;
}


export interface HttpErrorResponse extends ErrorWithCode {
    scope?: MylemonadeScopes;
    stack?: string;
    value?: any;
    status: number;
    timestamp?: string;
    path?: string;
}