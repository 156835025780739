import log from "../../Common/services/LogService";
import CustomerInformationModalFormOpenArgs from "../../customers/models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "../../customers/services/BaseFormModalService";
import AuthenticationFormParameters from "../models/AuthenticationFormParameters";

/**
 * Is the email verified if mandatory?
 */
export const isEmailVerifiedIfMandatory = (is_email_verified: boolean, mandatory: boolean): boolean => {

    return (
        !mandatory
        || is_email_verified
    );
}

export default class VerifyEmailFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.VERIFY_EMAIL);
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: AuthenticationFormParameters } | null {

        const { location_authentication_config, user_authentication_state } = args.authenticationData;
        const { only_email_verified } = location_authentication_config;

        // Email verification is mandatory and the email is not verified
        if (!user_authentication_state.is_anonymous && !isEmailVerifiedIfMandatory(user_authentication_state.is_email_verified, only_email_verified)) {

            log.debug(">-C-> The email verification is mandatory and user didn't very their email address");

            const authenticationFormParameters: AuthenticationFormParameters = {
                forceMandatory: true,
            }
            return { form: this.form, parameters: authenticationFormParameters };
        }

        return null;
    }

};