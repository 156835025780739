import GroupIcon from '@mui/icons-material/Group';
import { Badge, Box, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import locationActions from "../../Locations/redux/LocationActions";
import { getNumberOfContributors } from "../../orders/helpers/OrderHelpers";
import orderActions from "../../orders/redux/OrderActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { RADIUS_CIRCLE_ITEM } from '../configs/styleVariable';
import { getRadiusFromTheme } from '../helper/ThemeHelper';
import { cursor } from "../StyledComponents";

interface ShareOrderButtonProps {
    smallMargin?: boolean;
    noShadows?: boolean
}

const ShareOrderButton: React.FC<ShareOrderButtonProps> = (props) => {

    const {
        smallMargin,
        noShadows
    } = props;

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);

    const theme = useTheme();
    const dispatch = useDispatch();

    // Only show the button if the location allows it
    if (!selectedLocation?.orders?.allow_share_order) {

        return (
            <></>
        );
    }

    return (
        <Badge
            badgeContent={getNumberOfContributors(order.contributors)}
            color="primary"
            overlap="circular"
            invisible={getNumberOfContributors(order.contributors) <= 1}
            data-test="share-order-button.badge"
            componentsProps={{
                badge: {
                    //@ts-ignore we don't care that this proprery is not in the list of properties
                    "data-test": "share-order-button.badge_text",
                }
            }}
        >
            <Box
                borderRadius={getRadiusFromTheme(theme)}
                bgcolor={theme.palette.action.disabledBackground}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                boxShadow={noShadows ? 0 : 3}
                onClick={() => {

                    // If the order has not been created yet, create it
                    // now so that we have an ID to share
                    if (!order.id || order.id === "") {

                        dispatch(orderActions.createDraftOrder());
                    }
                    dispatch(locationActions.setOpenModalMultiUsersOrder(true));
                }}
                style={{
                    ...cursor
                }}
            >
                <Box
                    width={RADIUS_CIRCLE_ITEM}
                    height={RADIUS_CIRCLE_ITEM}
                    m={smallMargin ? 1.1 : 2}
                    data-test="share-order-button"
                >
                    <GroupIcon
                        style={{
                            color: theme.palette.text.disabled,
                        }}
                    />
                </Box>
            </Box>
        </Badge>
    );
}

export default ShareOrderButton;