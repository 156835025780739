import DeleteIcon from '@mui/icons-material/Delete'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import { Box, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { getDiscountInvalidityIntlValues } from '../../discount/helpers/DiscountDescriptionHelper'
import { DiscountInvalidity } from '../../my-lemonade-library/src/discounts/models/DiscountInvalidity'

interface CouponTextFieldProps {
    coupon: string
    handleCoupon: (str: string) => void
    isCouponInvalid: boolean
    handleCouponDeletion: () => void
    isCouponVerified: boolean
    discountInvalidityReason: DiscountInvalidity
}

const CouponTextField = (props: CouponTextFieldProps) => {

    const {
        coupon,
        handleCoupon,
        isCouponInvalid,
        handleCouponDeletion,
        isCouponVerified,
        discountInvalidityReason
    } = props

    const intl = useIntl()
    const theme = useTheme()

    const getInvalidityIntlMessage = getDiscountInvalidityIntlValues(discountInvalidityReason, intl)

    return (
        <Box
            mt={2}
            py={1}
            px={3}
            bgcolor={theme.palette.background.paper}
        >
            <Box
                display='flex'
                justifyContent='space-between'
            >
                <TextField
                    inputProps={{
                        "data-test": "coupon-textfield"
                    }}
                    placeholder={intl.formatMessage({ id: 'order.summary.benefits.coupon.add_coupon' })}
                    fullWidth={true}
                    disabled={!isCouponInvalid && isCouponVerified}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" >
                                <LocalOfferOutlinedIcon style={{ color: theme.palette.grey[500] }} />
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                    value={coupon}
                    onChange={(e) => {
                        handleCoupon(e.target.value)
                    }}
                />
                <IconButton
                    style={{ padding: 0 }}
                    onClick={handleCouponDeletion} size="large"
                    data-test='coupon-delete-button'
                >
                    <DeleteIcon aria-label="delete" style={{ color: theme.palette.grey[500] }} />
                </IconButton>
            </Box>
            {isCouponInvalid &&
                <Typography
                    data-test="coupon-error"
                    variant="caption"
                    color='primary'
                    style={{ marginLeft: theme.spacing(4) }}
                >
                    {
                        intl.formatMessage({
                            id: getInvalidityIntlMessage.id,
                        }, { value: getInvalidityIntlMessage.value })
                    }
                </Typography>
            }
        </Box>
    );
}

export default CouponTextField