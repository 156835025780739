import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import { ROUTE_PAYMENTS_ROOT } from "./PaymentsApiRoutes";

/**
 * Get marketplace invoice for payment fees
 */
export const MARKETPLACE_ID_PARAM_KEY = "marketplaceId";
export const MARKETPLACE_ID_PARAM = `:${MARKETPLACE_ID_PARAM_KEY}`;

export const INVOICE_ID_PARAM_KEY = "invoiceId";
export const INVOICE_ID_PARAM = `:${INVOICE_ID_PARAM_KEY}`;

export const MARKETPLACE_PAYMENT_TYPE_PARAM_KEY = "paymentType";
export const MARKETPLACE_PAYMENT_TYPE_PARAM = `:${MARKETPLACE_PAYMENT_TYPE_PARAM_KEY}`;

/**
 * Generate marketplace invoice for payment fees (e.g. Getresto is taking 1% for every transaction, an invoice must be generated for each restaurant location)
 */
export const ROUTE_MARKETPLACE_PAYMENT_GENERATE_FEE_INVOICES = `${ROUTE_PAYMENTS_ROOT}/generate-fee-invoices`;

export const MARKETPLACE_INVOICE = `${ROUTES_PREFIX_V1}/marketplace-invoices/${MARKETPLACE_PAYMENT_TYPE_PARAM}/${MARKETPLACE_ID_PARAM}/${INVOICE_ID_PARAM}/fee_amount_invoice`;