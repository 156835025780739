import { createTheme, DeprecatedThemeOptions, Theme, ThemeOptions } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { deepmerge } from '@mui/utils';
import _ from 'lodash';
import { LocationTheme } from '../my-lemonade-library/src/theme/models/LocationTheme';

export const DESKTOP_MENU_BORDER_RADIUS = "5px";

// breakpoint in px wich render desktop version (accessible with large tablet or landscape orientation)
export const desktopDisplayBreakpoint: number = 800;
const desktopBreakpointMediaQuery: string = `@media (min-width: ${desktopDisplayBreakpoint}px)`;

// These fonts are always available and will be appended each time a font family is set 
const fallbackFonts: string = ",\"Arial\",\"sans-serif\"";

// Default My Lemonade font family
const defaulMyLemonadeFont: string = "\"Poppins\""

// Default My Lemonade button border radius
export const defaultMyLemonadeButtonBorderRadius: number = 30;

// Default font unset properties like lineHeight and letterSpacing
const defaultFontUnsetProperties: TypographyStyleOptions = {
    lineHeight: "normal",
    letterSpacing: "normal",
}

export const defaultMyLemonadeButtonBoxShadow: string = "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
export const defaultMyLemonadeSummaryBottomShadow: string = "rgb(0 0 0 / 10%) 0px -3px 10px 0px;"

/** const ADOBE_XD_UNIT_TO_REM: number = 0.0643; */

/**
 * The list of typography elements that we use. This list must be updated if a new element is
 * added by the design team.
 */
const listOfAllowedTypographies = ["h1", "h2", "h3", "h4", "h5", "body1", "subtitle1", "caption"]

/**
 * This object represents the values of the My Lemonade default theme.
 * If no theme is defined for the location, this one will be used. It is
 * mainly orange-black with the Google sans font.
 * The values come from this design made by Anaïs:
 * https://xd.adobe.com/view/ea6d07f7-fe53-4086-8090-53291e30151a-bf3e/specs/
 */
const defaultMyLemonadeThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: defaulMyLemonadeFont + fallbackFonts,
        h1: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.7rem",  // 35 xd_unit
            fontWeight: 600,        // semibold
            ...defaultFontUnsetProperties,
        },
        h2: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.286rem",   // 20 xd_unit 
            [desktopBreakpointMediaQuery]: {
                fontSize: '1.1rem',
            },
            fontWeight: 600,        // semibold
            ...defaultFontUnsetProperties,
        },
        h3: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.286rem",   // 20 xd_unit
            fontWeight: 400,        // normal
            ...defaultFontUnsetProperties,
        },
        h4: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "1.0288rem",  // 16 xd_unit
            fontWeight: 400,        // normal
            ...defaultFontUnsetProperties,
        },
        h5: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: "0.9002rem",  // 14 xd_unit
            [desktopBreakpointMediaQuery]: {
                fontSize: '.82rem',
            },
            fontWeight: 600,        // bold
            ...defaultFontUnsetProperties,
        },
        body1: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: ".9002rem",   // 14 xd_unit
            fontWeight: 400,        // regular
            ...defaultFontUnsetProperties,
        },
        caption: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: ".7716rem",   // 12 xd_unit
            fontWeight: 400,        // regular
            ...defaultFontUnsetProperties,
        },
        subtitle1: {
            fontFamily: defaulMyLemonadeFont + fallbackFonts,
            fontSize: ".9002rem",   // 14 xd_unit
            fontWeight: 500,        // medium
            ...defaultFontUnsetProperties,
        }
    },
    palette: {
        primary: {
            main: "#F46524",        // orange
            contrastText: "#FFFFFF" // white
        },
        secondary: {
            main: "#2A2A2A",
            contrastText: "#FFFFFF",
        },
        text: {
            primary: "#2A2A2A",     // black-grey
            secondary: "#2A2A2A",   // black-grey
            disabled: "#949494",       // light grey
        },
        background: {
            default: "#f6f6f6",
            paper: "#FFFFFF"
        },
        action: {
            disabledBackground: "#dfdfdf"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: defaultMyLemonadeButtonBorderRadius,
                }),
                contained: ({ theme }) => ({
                    boxShadow: "none",
                }),
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: ({ theme }) => ({
                    boxShadow: 'none',
                    borderBottom: 'solid 1px #0000001F',
                }),
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingTop: '0',
                    paddingBottom: '0',
                }),
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: ({ theme }) => ({
                    display: 'flex',
                    flexDirection: 'column',
                }),
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    flexGrow: 3,
                }),
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            }
        },
    },
}

/**
 * The theme used by the webapp (if no theme in location) is now set with
 * the options we just set above.
 */
const myLemonadeDefaultTheme: Theme = createTheme(defaultMyLemonadeThemeOptions);


/**
 * If a theme is defined in the location object (from firebase), then we will
 * override the existing theme with the custom values.
 * @param locationTheme 
 * @returns 
 */
export function createLocationTheme(locationTheme?: LocationTheme): Theme {
    if (!locationTheme) {
        return myLemonadeDefaultTheme;
    }

    let finalLocationThemeOptions: DeprecatedThemeOptions = defaultMyLemonadeThemeOptions;

    if (locationTheme.mui_theme_options) {
        // TODO: see why I can't access some properties (like fontFamily) when the object has type DeprecatedThemeOptions
        let newLocationThemeMUIOptions: any = locationTheme.mui_theme_options as any;

        if (newLocationThemeMUIOptions.typography) {
            for (const [key] of Object.entries(newLocationThemeMUIOptions.typography)) {
                if (listOfAllowedTypographies.includes(key)) {
                    newLocationThemeMUIOptions.typography[key].fontFamily = "\""
                        + newLocationThemeMUIOptions.typography[key].fontFamily + "\"" + fallbackFonts;

                    /**
                     * If there is a desktop font size defined: "convert" it to the
                     * MUI format (ex: @media (min-width: 800px): {fontSize: "1.1rem"})
                     * and then delete the desktopFontSize property as it does not make
                     * any sense in CSS
                     */
                    if (newLocationThemeMUIOptions.typography[key].desktopFontSize) {

                        newLocationThemeMUIOptions.typography[key][desktopBreakpointMediaQuery] = {
                            fontSize: newLocationThemeMUIOptions.typography[key].desktopFontSize,
                        };

                        delete newLocationThemeMUIOptions.typography[key].desktopFontSize;
                    }
                }
            }
        }
        _.merge(finalLocationThemeOptions, newLocationThemeMUIOptions);
    }

    if (locationTheme.mui_extended_theme_options) {

        let newLocationThemeExtendedOptions: DeprecatedThemeOptions = {};
        if (locationTheme.mui_extended_theme_options.button_radius) {
            newLocationThemeExtendedOptions = {
                ...newLocationThemeExtendedOptions,
                overrides: {
                    MuiButton: {
                        root: {
                            borderRadius: locationTheme.mui_extended_theme_options.button_radius
                        }
                    }
                }
            }
        }

        if (Object.keys(newLocationThemeExtendedOptions).length > 0) {
            _.merge(finalLocationThemeOptions, newLocationThemeExtendedOptions)
        }
    }
    return createTheme(finalLocationThemeOptions);
}


// GetResto's style. Will be applied if the query string '?theme=getresto' is provided.
// We merge the My Lemonade default options with the new ones specified here.
// NOTE: when used, the location theme will be ignored.
export const getRestoThemeOptions: ThemeOptions = deepmerge(defaultMyLemonadeThemeOptions, {
    palette: {
        primary: {
            main: "#FF5823",  // Purple
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {},
            },
        },
    },
})

// Lyf's style. Will be applied if the query string '?theme=getresto' is provided.
// We merge the My Lemonade default options with the new ones specified here.
// NOTE: when used, the location theme will be ignored.
export const lyfInAppThemeOptions: ThemeOptions = deepmerge(defaultMyLemonadeThemeOptions, {
    palette: {
        primary: {
            main: "#ff6a70",  // Lyf
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {},
            },
        },
    },
})

export default createTheme(myLemonadeDefaultTheme);


export const MOBILE_BREAKPOINT = myLemonadeDefaultTheme.breakpoints.down('sm')

/**
 * TODO: create a function able to reproduce material-UI hook useMediaQuery<boolean>() called in many component using the breakpoint desktopDisplayBreakpoint
 * stock the result globally in the app (maybe using react context) in order to provide variable to all components who needed it with useContext
 * open the functionnality to create a useEffect able to handle this variable who can change the display mode and maybe redirect to a landing page (/categorie)
 * ask Thibaut for this
 */
