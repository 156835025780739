import { Box, Paper, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import DefaultImage from '../../assets/aperitif.png';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import productService from '../services/ProductService';
import Price from './Price';
import UnavailableButton from './UnavailableButton';

type ProductsCardsComponentProps = {
    item: ProductExtended | DealExt,
    categoryIcon?: string,
    openModal: (item: ProductExtended | DealExt) => void
};

const ProductsCardsComponent = (props: ProductsCardsComponentProps) => {

    const intl = useIntl();

    const deal = props.item as DealExt;
    const isDeal = deal.lines && deal.lines.length > 0;
    const product = props.item as ProductExtended;
    const dataTestPrefix = isDeal ? "deal_" : "product_";
    const { selectedCatalog } = useTypedSelector((state: RootState) => state.locations)
    const { order } = useTypedSelector((state: RootState) => state.order)
    let price = isDeal ? deal.starting_price : product.skus[0].reduced_price ? product.skus[0].reduced_price : product.skus[0].price
    const reduced = isDeal || (product.skus && product.skus[0].reduced_price) ? true : false

    if (!selectedCatalog) {
        return null
    }

    return (

        <Box
            data-test={`${dataTestPrefix}${props.item.ref}`}
            my={1.5}
        >
            <Box
                display="inline-block"
                position="relative"
                width="100%"
                overflow="hidden"
            >
                <Box mt="75%">{/*4:3 aspect ratio*/}</Box>

                <Paper
                    elevation={4}
                    id="card"
                    onClick={() => {
                        if (props.openModal && productService.canDisplayItem(order, product, selectedCatalog)) {
                            props.openModal(props.item);
                        }
                    }}
                >
                    {productService.canDisplayItem(order, product, selectedCatalog) && product.display_if_disabled && <UnavailableButton />}
                    {price ? (<Price price={price} reduced={reduced} />) : ""}

                    {props.item.image ?
                        <img style={imgStyle} width="100%" height="100%" src={props.item.image} alt='' /> :
                        props.categoryIcon ?
                            props.categoryIcon.includes("svg") ?
                                <img style={imgStyle} width="50%" height="50%" src={props.categoryIcon} alt='' /> :
                                <img style={imgStyle} width="100%" height="100%" src={props.categoryIcon} alt='' /> :
                            <img width="100%" height="100%" src={DefaultImage} alt='' />
                    }
                </Paper>

            </Box>

            <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                {intl.formatMessage({
                    id: isDeal ?
                        translationService.getDealNameTranslationKey(deal)
                        : translationService.getProductNameTranslationKey(product),
                    defaultMessage: props.item.name
                })}
            </Typography>

        </Box>
    )

}

export default ProductsCardsComponent

const imgStyle: React.CSSProperties = {
    objectFit: "cover",
    objectPosition: "center" /* Center the image within the element */
}