import { all, call, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import _ from "lodash";
import { put } from "redux-saga/effects";
import { checkEmailRegex } from "../../Common/helper/EmailHelper";
import { CommonActions } from "../../Common/redux/CommonActions";
import log, { setLogLevel } from '../../Common/services/LogService';
import firebase, { db, firebaseApp, rsf } from "../../config/firebase";
import { getApiEndpoint, isProdEnv } from "../../config/variables";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { CustomerInformationModalState } from "../../customers/redux/CustomerInformationModalReducer";
import { updateDateFieldsInFirestoreCustomer } from "../../customers/services/CustomerHelper";
import LocationState from "../../Locations/models/LocationState";
import locationActions from "../../Locations/redux/LocationActions";
import { DEFAULT_DEPLOYMENT_NAME, getTimezoneName, Location } from "../../my-lemonade-library/model/Location";
import authenticationsApiRoutes, { AUTHENTICATION_PROVIDER_PARAM } from "../../my-lemonade-library/src/authentications/configs/AuthenticationApiRoutes";
import { AuthenticateSuccessRequest } from "../../my-lemonade-library/src/authentications/models/AuthenticateSuccessRequest";
import AuthorizeResponse from "../../my-lemonade-library/src/authentications/models/AuthorizeResponse";
import BaseUser, { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import { RecoveryPasswordRequest } from "../../my-lemonade-library/src/authentications/models/RecoveryPasswordRequest";
import SigninResponse from "../../my-lemonade-library/src/authentications/models/SigninResponse";
import { TermsTarget } from "../../my-lemonade-library/src/authentications/models/Terms";
import UserClaims from "../../my-lemonade-library/src/authentications/models/UserClaims";
import { getCustomerFirestoreDocPath, getUserFirestoreDocPath } from "../../my-lemonade-library/src/authentications/service/AuthenticationService";
import DeploymentInfo from "../../my-lemonade-library/src/common/models/DeploymentInfo";
import { getErrorMessage, getErrorStack } from "../../my-lemonade-library/src/common/services/LogService";
import { ORDER_ID_PARAM_KEY } from "../../my-lemonade-library/src/orders/configs/OrdersApiRoutes";
import { orderService } from "../../my-lemonade-library/src/orders/services/OrderService";
import { SESSION_ID_HEADER } from "../../my-lemonade-library/src/sessions/services/SessionService";
import { FIRESTORE_USERS_ACCESS_COLLECTION } from "../../my-lemonade-library/src/users/configs/UsersFirestoreConfig";
import { OrderState } from "../../orders/redux/models/OrderState";
import OrderActions, { orderActions, SETUP_SYNC_CUSTOMER } from '../../orders/redux/OrderActions';
import { setupSyncCustomer } from "../../orders/redux/OrderSagas";
import { AppState } from "../../redux/app/AppReducer";
import { RootState } from "../../redux/root-reducer";
import { AuthenticationErrorType } from "../models/AuthenticationErrorType";
import { AuthenticationState } from "../models/AuthenticationState";
import SignUpModel from "../models/SignUpModel";
import { acknowledgeAuthentication, authorizeCode, getFirebaseErrorId, getIdToken, getSigninUrl, keepOrResetCustomerConfig, recoveryPassword } from "../services/AuthenticationService";
import { AcceptTermsAction, ACCEPT_TERMS, AuthenticateUserAction, AuthenticateUserSuccessAction, AUTHENTICATE_USER, AUTHENTICATE_USER_SUCCESS, AuthenticationActions, CompleteUserInformationAction, COMPLETE_USER_INFORMATION, ContinueAsGuestAction, CONTINUE_AS_GUEST, CreateUserAction, CREATE_USER, FirebaseSigninSuccessAction, FIREBASE_SIGNIN_SUCCESS, LoadUserInfoAction, LOAD_USER_INFO, ResetPasswordAction, RESET_PASSWORD, SendVerificationEmailAction, SEND_VERIFICATION_EMAIL, SignInAuthorizeAction, SignInRedirectAction, SignInWithCustomTokenAction, SIGNIN_AUTHORIZE_USER, SIGNIN_REDIRECT, SIGNIN_WITH_CUSTOM_TOKEN, SignoutUserAction, SignoutUserSuccessAction, SIGNOUT_USER, SIGNOUT_USER_SUCCESS, UpdateUserAction, UPDATE_USER } from "./AuthenticationActions";

export function* authenticationSaga() {
    yield all([
        takeLatest(CREATE_USER, createUserSaga),
        takeLatest(AUTHENTICATE_USER, authenticateUserSaga),
        takeLatest(SIGNOUT_USER, signoutUserSaga),
        takeLatest(LOAD_USER_INFO, loadUserInfos),
        takeLatest(SEND_VERIFICATION_EMAIL, sendEmail),
        takeLatest(RESET_PASSWORD, resetPassword),
        takeLatest(COMPLETE_USER_INFORMATION, saveUserInBase),
        takeLatest(UPDATE_USER, updateUser),
        takeLatest(SIGNIN_REDIRECT, signInRedirect),
        takeLatest(SIGNIN_AUTHORIZE_USER, signInAuthorize),
        takeEvery(SIGNIN_WITH_CUSTOM_TOKEN, signInWithCustomToken),
        takeLatest(CONTINUE_AS_GUEST, continueAsGuest),
        takeEvery(AUTHENTICATE_USER_SUCCESS, authenticateUserSuccess),
        takeLatest(SIGNOUT_USER_SUCCESS, signoutUserSuccess),
        takeLatest(FIREBASE_SIGNIN_SUCCESS, signinSuccess),
        takeLatest(ACCEPT_TERMS, acceptTerms),
    ])
}

function* signInRedirect(action: SignInRedirectAction) {
    const signinProvider = action.payload.signinProvider;
    try {
        const { tableLinkId } = yield select((state: RootState) => state.locations)
        const { order } = yield select((state: RootState) => state.order)
        const { themeName } = yield select((state: RootState) => state.theme)
        let orderId: string | undefined = undefined;
        // If the order is fully editable we need to send it to the api to be able to restore it
        if (order.id && orderService.isFullyEditable(order)) {
            orderId = order.id;
        }

        // We need to redirect
        const signinResponse: SigninResponse = yield call(getSigninUrl, signinProvider, tableLinkId, themeName, orderId, action.payload.redirectPath);
        if (signinResponse.authorization_url) {
            log.debug(`Redirecting to ${signinResponse.authorization_url} for ${signinProvider} signin`);
            yield put(CommonActions.setRedirectURL(signinResponse.authorization_url));
        }
    } catch (error) {
        log.error(`error while redirecting for provider ${signinProvider}`)
        yield put(AuthenticationActions.authenticationError(`error while redirecting for provider ${signinProvider}`))
    }
}

function* signInAuthorize(action: SignInAuthorizeAction) {

    const signinParams = action.payload;

    const { sessionId }: LocationState = yield select((state: RootState) => state.locations);

    try {

        const authorizeResponse: AuthorizeResponse = yield call(
            authorizeCode,
            signinParams.provider,
            signinParams.authorizationCode,
            signinParams.authorizationCodeType,
            sessionId,
            signinParams.state,
            signinParams.uuid ?? "",
        );

        if (authorizeResponse.custom_token) {

            // Redirect only if needed
            let redirectPath: string | undefined = undefined;
            if (signinParams.redirect) {
                redirectPath = authorizeResponse.redirect_path;
                if (!redirectPath) {
                    redirectPath = `/${authorizeResponse.table_link_id}`;
                    if (authorizeResponse.order_id) {
                        redirectPath = `${redirectPath}?${ORDER_ID_PARAM_KEY}=${authorizeResponse.order_id}`;
                    }
                }
            }

            const signInWithCustomTokenAction: SignInWithCustomTokenAction = {
                type: SIGNIN_WITH_CUSTOM_TOKEN,
                payload: {
                    state: signinParams.state,
                    custom_token: authorizeResponse.custom_token,
                    provider: signinParams.provider,
                    redirect_path: redirectPath,
                    customer: authorizeResponse.customer,
                }
            }

            yield call(signInWithCustomToken, signInWithCustomTokenAction);


        } else {
            throw new Error(`No custom token in response`);
        }
    } catch (error) {
        log.error(getErrorMessage(error));
        log.error(`error while authorizing for provider ${signinParams.provider}`)
        yield put(AuthenticationActions.authenticationError(`error while authorizing for provider ${signinParams.provider}`))
    }
}

function* signInWithCustomToken(action: SignInWithCustomTokenAction) {
    try {
        log.debug(`Custom token: ${action.payload.custom_token} `);
        const loggedUser: firebase.auth.UserCredential = yield call(rsf.auth.signInWithCustomToken, action.payload.custom_token);
        const user = loggedUser.user
        if (user) {
            // TODO: decode token to get provider
            let provider = action.payload.provider;

            if (!provider) {
                provider = SignInProviders.CUSTOM;
            }

            const isVerifiedEmail = user.emailVerified;
            yield put(AuthenticationActions.authenticateUserSuccess(action.payload.customer, false, isVerifiedEmail, user.isAnonymous, provider, true))
            log.debug('Provider user', user);

            yield call(
                acknowledgeAuthentication,
                provider,
                action.payload.state,
            );

            if (action.payload.redirect_path) {
                log.debug(`Redirecting to ${action.payload.redirect_path}`);
                yield put(CommonActions.setRedirectURL(action.payload.redirect_path));
            }

            if (action.payload.formToDisplay !== undefined) {
                log.debug(`Form to display ${action.payload.formToDisplay}`);
                yield put(CustomerInformationModalActions.setCustomerInformationModal(action.payload.formToDisplay, action.payload.formParameters));
            } else {
                const { formToDisplay }: CustomerInformationModalState = yield select((state: RootState) => state.customerInformationModal);
                log.debug("formToDisplay", formToDisplay)
                if (formToDisplay === CustomerInformationModalFormToDisplay.AUTHENTICATION_PIN_CONFIRMATION || formToDisplay === CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES) {
                    log.debug("Closing form to display")
                    yield put(CustomerInformationModalActions.setCustomerInformationModal(null));
                }
            }
        } else {
            const errorMessage = `No user when signin with custom token`;
            log.error(errorMessage);
            yield put(AuthenticationActions.authenticationError(errorMessage))
        }
    } catch (error) {
        log.error(`Error while signin with custom token: ${getErrorMessage(error)} (${getErrorStack(error)})`)
        yield put(AuthenticationActions.authenticationError(`Error while signin with custom token`))
    }
}

function* saveUserInBase(action: CompleteUserInformationAction) {
    try {
        const { selectedLocation } = yield select((state: RootState) => state.locations)
        const user = firebaseApp.auth().currentUser;
        if (user && user.email && user.uid) {

            const { firstName, lastName, phoneNumber, allow_marketing } = action.payload
            const uid = user.uid;
            const email = user.email
            const isVerifiedEmail = user.emailVerified
            const provider = user.providerData[0]?.providerId


            const { account_id, location_id } = selectedLocation

            const baseUser: BaseUser = {
                uid: uid,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
            }

            const customer: Customer = {
                uid: uid,
                email_marketing: allow_marketing,
                sms_marketing: allow_marketing
            }

            yield call(rsf.firestore.setDocument, getUserFirestoreDocPath(uid), baseUser, { merge: true })

            log.info(`user created in base and assign to account ${account_id} location ${location_id} `)

            yield call(rsf.firestore.setDocument, getCustomerFirestoreDocPath(account_id, location_id, uid), customer, { merge: true })

            yield put(AuthenticationActions.authenticateUserSuccess({ ...baseUser, ...customer }, true, isVerifiedEmail, user.isAnonymous, provider))
            yield put(OrderActions.setCustomerInfo({ ...baseUser, ...customer }))

        } else {
            log.error(`error while saving user for uid ${user?.uid} and email ${user?.email} `)
            yield put(AuthenticationActions.authenticationError(`error while saving user`))
        }
    } catch (error) {
        log.error(`error while saving user ${error} `)
        yield put(AuthenticationActions.authenticationError(`error while saving user`))
    }
}

function* updateUser(action: UpdateUserAction) {
    try {
        const user = firebaseApp.auth().currentUser;

        if (user && user.email && user.uid) {

            const customer = action.payload.customer
            const uid = user.uid;

            yield call(rsf.firestore.updateDocument, getUserFirestoreDocPath(uid), {
                first_name: customer.first_name ?? '',
                last_name: customer.last_name ?? ''
            })

            log.info(`Updated user ${uid} `)

            yield put(OrderActions.setCustomerInfo(customer))
            yield put(AuthenticationActions.updateUserSuccess(customer))
        } else {
            log.error(`Error while updating user for uid ${user?.uid} and email ${user?.email} `)
            yield put(AuthenticationActions.authenticationError(`Error while updating user`))
        }
    } catch (error) {
        log.error(`Error while updating user ${error} `)
        yield put(AuthenticationActions.authenticationError(`Error while updating user`))
    }
}

function* sendEmail(action: SendVerificationEmailAction) {
    try {
        const user = firebaseApp.auth().currentUser;
        const urlBack = window.location.href
        const actionCodeSettings: firebase.auth.ActionCodeSettings = {
            url: urlBack
        }
        if (user) {
            yield call(rsf.auth.sendEmailVerification, actionCodeSettings)
            log.debug('Email sent')
            // action de succés
        }
    } catch (error) {
        log.error(error)
        // action d'erreur
    }
}

function* resetPassword(action: ResetPasswordAction) {
    try {

        const email = action.payload

        const { selectedLocation }: { selectedLocation: Location } = yield select((state: RootState) => state.locations)
        const { deployment }: { deployment: DeploymentInfo } = yield select((state: RootState) => state.app)

        const payload: RecoveryPasswordRequest = {
            account_id: selectedLocation.account_id,
            location_id: selectedLocation.id,
            deployment: deployment?.name,
            email: email
        }

        const response: { [key: string]: string | number } = yield call(recoveryPassword, payload, isProdEnv())

        if (response.status > 300) {
            log.error('Fail to send email', response)
            yield put(AuthenticationActions.resetPasswordFail(response.code as string))
        } else {
            yield put(AuthenticationActions.resetPasswordSuccess())
            log.info('Reset send')
        }

    } catch (error) {
        log.error(`Fail to send recovery password email: ${error}`)
        yield put(AuthenticationActions.resetPasswordFail(error as string))
    }

}

function* createUserSaga(action: CreateUserAction) {

    try {

        const { deployment }: AppState = yield select((state: RootState) => state.app);
        const { selectedLocation, sessionId }: LocationState = yield select((state: RootState) => state.locations);
        const { order }: OrderState = yield select((state: RootState) => state.order);
        const userData: SignUpModel = action.payload.userData;
        const { email, allow_marketing, phoneNumber, firstName, lastName, accept_terms, uid } = userData;

        if (uid) {

            const baseUser: BaseUser = {
                uid: uid,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                sign_in_provider: SignInProviders.PASSWORD,
            }

            if (accept_terms) {
                baseUser.last_accepted_terms = {
                    [TermsTarget.CUSTOMERS]: {
                        [deployment?.name ?? DEFAULT_DEPLOYMENT_NAME]: Date.now(),
                    }
                }
            }

            yield call(rsf.firestore.setDocument, getUserFirestoreDocPath(uid), baseUser, { merge: true })
            log.info('User created')

            // Still write customer to save email marketing & sms marketing specific to location ?
            // TODO: parameter at the location level
            const customer: Customer = {
                uid: baseUser.uid,
                email_marketing: allow_marketing,
                sms_marketing: allow_marketing,
                sign_in_provider: SignInProviders.PASSWORD,
            }

            const location_id = selectedLocation?.id;
            const account_id = selectedLocation?.account_id;

            if (!location_id || !account_id) {
                throw new Error(`Missing Location id or account id, couldn't save user`);
            }

            log.info(`Assign to account ${account_id} location ${location_id}`)

            yield call(rsf.firestore.setDocument, getCustomerFirestoreDocPath(account_id, location_id, uid), customer, { merge: true })
            log.info(`Customer created`)

            yield put(AuthenticationActions.createUserSuccess());
            yield put(AuthenticationActions.sendVerificationEmail())

            yield put(CustomerInformationModalActions.setCustomerInformationModal(
                CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION,
            ));

            yield put(AuthenticationActions.loadUserInfo())
            yield put(OrderActions.loadOrders());
            yield call(signinSuccessApiCall, account_id, location_id, sessionId, SignInProviders.PASSWORD, order.id);

        } else {
            log.error(`User id null, couldn't save user`)
            yield put(AuthenticationActions.authenticationError(`User id null, couldn't save user`))
        }

    } catch (error) {
        log.error(error)
        // TODO: function to get the firestore error code, and avoid using any
        const messageToDisplay = getFirebaseErrorId((error as any).code)
        if (messageToDisplay) {
            yield put(AuthenticationActions.authenticationError(messageToDisplay, AuthenticationErrorType.ERROR_SIGNUP))
        } else {
            yield put(AuthenticationActions.authenticationError('An error happenned while creating user'))
        }
    }
}

function* authenticateUserSaga(action: AuthenticateUserAction) {

    try {

        log.info(`Authenticating user by password (firebase)`)

        yield put(CustomerInformationModalActions.setCustomerInformationModal(
            CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION,
        ));

        yield put(AuthenticationActions.loadUserInfo())
        yield put(OrderActions.loadOrders());
    }
    catch (error) {

        log.error(error)
        // TODO: function to get the firestore error code, and avoid using any
        const messageToDisplay = getFirebaseErrorId((error as any).code)

        if (messageToDisplay) {

            yield put(AuthenticationActions.authenticationError(messageToDisplay, AuthenticationErrorType.ERROR_SIGNIN))
        }
        else {

            yield put(AuthenticationActions.authenticationError('An error happenned while authenticate user'))
        }

    }
}

function* signoutUserSaga(action: SignoutUserAction) {

    const { selectedLocation, sessionId }: LocationState = yield select((state: RootState) => state.locations);
    const { order }: OrderState = yield select((state: RootState) => state.order);

    try {
        yield call(rsf.auth.signOut)
        const anonymousUser: firebase.auth.UserCredential = yield call(rsf.auth.signInAnonymously);
        if (anonymousUser.user) {
            log.info(`User disconnected, signin Anonymously with id ${anonymousUser.user?.uid}`)
        } else {
            log.error(`Unknow user`)
        }
        yield put(AuthenticationActions.signoutUserSuccess())
        yield put(AuthenticationActions.authenticateUserSuccess({ uid: anonymousUser.user?.uid }, false, false, true))
        yield put(OrderActions.setCustomerInfo(null))

        // Reload catalog to reset deals
        yield put(locationActions.reloadCatalog())

        if (!action.payload.doNotResetOrder) {
            yield put(OrderActions.resetOrder())
            yield put(OrderActions.loadOrders(true))
        }

        if (selectedLocation) {
            yield call(signinSuccessApiCall, selectedLocation.account_id, selectedLocation.id, sessionId, SignInProviders.ANONYMOUS, order.id);
        }

    } catch (error) {
        log.error(error)
    }
}

function* loadUserInfos(action: LoadUserInfoAction) {

    try {

        const user = firebaseApp.auth().currentUser;

        if (user) {
            const { selectedLocation, selectedCatalog, selectedTable } = (yield select((state: RootState) => state.locations)) as LocationState;
            const { email_domains_allowed, only_email_verified }: { email_domains_allowed: string[] | null, only_email_verified?: boolean } = yield select((state: RootState) => state.authentication.data.location_authentication_config)
            const { id: location_id, account_id } = selectedLocation!;

            let provider = (user.providerData && user.providerData.length) ? user.providerData[0]?.providerId : "";

            if (!provider && !user.isAnonymous) {
                provider = user.providerId;
                if (!provider) {
                    const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);
                    if (tokenResult.claims) {
                        const userClaims: UserClaims = tokenResult.claims as UserClaims;
                        if (userClaims.signinprovider) {
                            provider = userClaims.signinprovider;
                        }
                    }

                    if (!provider) {
                        provider = SignInProviders.CUSTOM;
                    }
                }
            }
            const isVerifiedEmail = user.emailVerified;
            const uid = user.uid;

            let registerOnLocation: boolean;

            // This object will carry the information of the customer. It will be filled in by
            // the customer (location) & the user (account) information successively
            let locationCustomer: Customer | null = null;

            // First of all, we fetch the customer information from the location.
            const locationCustomerSnapshot: firebase.firestore.DocumentSnapshot = yield call(rsf.firestore.getDocument, getCustomerFirestoreDocPath(account_id, location_id, uid))

            if (locationCustomerSnapshot.exists) {

                locationCustomer = locationCustomerSnapshot.data() as Customer

                // get the uid and apply to the customer object
                locationCustomer.uid = locationCustomerSnapshot.id;
                updateDateFieldsInFirestoreCustomer(locationCustomer, getTimezoneName(selectedLocation));

                // TODO: what does this do?
                registerOnLocation = true;
            } else {
                log.info(`Customer not registered on location`);
                registerOnLocation = false;
                locationCustomer = {
                    uid: user.uid,
                    email: user.email ? user.email : undefined
                }
            }

            // Then we fetch the user info and if it exists, we merge it with the customer set above
            const generalUserSnapshot: firebase.firestore.DocumentSnapshot = yield call(rsf.firestore.getDocument, getUserFirestoreDocPath(uid))

            if (generalUserSnapshot.exists) {

                // General user info at the root level
                const generalUserInfo = generalUserSnapshot.data() as BaseUser

                // Merge general user with location user, in priority user info
                // Accept terms & privacy policy of service
                // TODO: if the location has additional terms & policy, check that too
                locationCustomer = {
                    ...generalUserInfo,
                    ...locationCustomer,
                    first_name: generalUserInfo.first_name ? generalUserInfo.first_name : (locationCustomer.first_name ?? ""),
                    last_name: generalUserInfo.last_name ? generalUserInfo.last_name : (locationCustomer.last_name ?? ""),
                    last_accepted_terms: generalUserInfo.last_accepted_terms,
                    email_verified: isVerifiedEmail,
                }

            } else {
                if (!user.isAnonymous) {
                    log.error(`User ${user.uid} has created an account but no documents exist`);
                }
            }

            // Assign the signin provider if anonymous or not defined
            if (user.isAnonymous) {
                locationCustomer.sign_in_provider = SignInProviders.ANONYMOUS;
            } else if (provider) {
                locationCustomer.sign_in_provider = provider as SignInProviders;
            }
            else if (!locationCustomer.sign_in_provider) {
                locationCustomer.sign_in_provider = SignInProviders.CUSTOM;
            }

            // Check if the email is valid in base
            if (!locationCustomer.email || !checkEmailRegex(locationCustomer.email)) {
                // The email is not valid, let's take the one from firebase auth
                if (user.email) {
                    locationCustomer.email = user.email;
                }
            }

            // check emails domains if exist
            if (email_domains_allowed && email_domains_allowed.length && locationCustomer) {
                const isValidDomain = email_domains_allowed.find((domain: string) => locationCustomer?.email?.includes(domain))
                if (isValidDomain) {
                    log.info(`${locationCustomer.email} is allowed by location`)
                }
                else {
                    log.info(`User ${locationCustomer.email} not allowed by location ${selectedLocation?.id} (account: ${selectedLocation?.account_id})`)
                    yield put(AuthenticationActions.signoutUser())
                    return
                }
            }

            if (locationCustomer) {
                locationCustomer.uid = uid;

                if (locationCustomer.log_level) {
                    log.warn(`Setting log level to ${locationCustomer.log_level} for user ${locationCustomer.uid}`);
                    setLogLevel(locationCustomer.log_level);
                }

                if (!locationCustomer.email_verified && locationCustomer.sign_in_provider === SignInProviders.PASSWORD && only_email_verified) {

                    yield put(CustomerInformationModalActions.setCustomerInformationModal(
                        CustomerInformationModalFormToDisplay.VERIFY_EMAIL,
                    ));
                }

                const customer = keepOrResetCustomerConfig(locationCustomer, getTimezoneName(selectedLocation))
                yield put(AuthenticationActions.authenticateUserSuccess(customer, registerOnLocation, isVerifiedEmail, user.isAnonymous, provider))
                yield put(OrderActions.setCustomerInfo(customer));

                if (selectedCatalog && selectedTable) {
                    yield put(locationActions.updateCatalogDealsAfterLoadingUserInfo(
                        selectedCatalog,
                        selectedTable.service_type ?? null,
                        provider ? provider as SignInProviders : null,
                        customer,
                        selectedTable.area_ref,
                    ));
                }

            } else {
                log.debug("No customer information to set.")
            }
        }
    }
    catch (error) {

        log.error(error)
        yield put(AuthenticationActions.authenticationError('An error happenned while fetching user info'))
    }
}

/**
 * Write to the customer in base that they chose to be anonymous
 * @param action 
 */
function* continueAsGuest(action: ContinueAsGuestAction) {

    // Tells if the action is called at the end of the webapp flow
    const isCalledAtTheEnd: boolean = action.payload;

    try {

        const { selectedLocation } = yield select((state: RootState) => state.locations)
        const location: Location = selectedLocation
        const { account_id, id } = location
        const user = firebaseApp.auth().currentUser;

        if (user) {

            const uid = user.uid;

            log.info(`Setting the has_checked_guest for user: ${uid}, with end: ${isCalledAtTheEnd}`);

            // Here we put only the field(s) to apply to firebase.
            let customerFieldsToApply: Customer = {

                // Setting the last update date to right now
                has_clicked_guest_last_update: new Date(),
            }

            if (isCalledAtTheEnd) {

                customerFieldsToApply.has_clicked_guest_end = true;
            }
            else {

                customerFieldsToApply.has_clicked_guest_beginning = true;
            }

            yield call(rsf.firestore.setDocument, getCustomerFirestoreDocPath(account_id, id, uid), customerFieldsToApply, { merge: true });

        } else {
            log.error('User not found')
            yield put(AuthenticationActions.authenticationError('An error happenned while fetching user info'))
        }


    }
    catch (error) {

        log.error(`error while setting the has_clicked_anonymous`, error);
    }
}

function* authenticateUserSuccess(action: AuthenticateUserSuccessAction) {

    const newCustomer = _.cloneDeep(action.payload.customer);

    const isAnonymous = Boolean(
        newCustomer?.sign_in_provider === SignInProviders.ANONYMOUS
        || action.payload.is_anonymous
    )

    const { order }: OrderState = yield select((state: RootState) => state.order);

    // Only if not anonymous
    if (!isAnonymous && newCustomer?.uid) {
        // PUT because the order might be still loading
        yield put(orderActions.setLoyaltyUserId(newCustomer.uid, true));
    }

    // Replace the customer in the order in case it's not synced with Firestore yet.
    // If it was synced (i.e. order ID was sent), then the API should have done it properly

    let anonymousCustomerId: string | undefined;
    if (!order.id) {

        const currentCustomer = order.customer;
        if (currentCustomer?.sign_in_provider === SignInProviders.ANONYMOUS) {
            anonymousCustomerId = currentCustomer.uid;
        }

        if (newCustomer) {
            if (!newCustomer.old_anonymous_ids) {
                newCustomer.old_anonymous_ids = [];
            }
            if (anonymousCustomerId) {
                newCustomer.old_anonymous_ids?.push(anonymousCustomerId);
            }
        }
        yield put(orderActions.setCustomerInfo(newCustomer, undefined, true));
    }

    // Add the user to the contributors if necessary. Remove the anonymous contributor if necessary too (see above)
    if (
        newCustomer?.uid
        && order?.contributors
        && !order.contributors[newCustomer.uid]
    ) {
        yield put(orderActions.addContributor(newCustomer.uid, newCustomer));

        if (anonymousCustomerId) {
            yield put(orderActions.removeContributorLocally(anonymousCustomerId));
        }
    }

    // Subscribe to the customer in case it is not already done
    yield call(setupSyncCustomer, {
        type: SETUP_SYNC_CUSTOMER,
        payload: {
            userId: action.payload.customer?.uid,
            isAnonymous,
        }
    });
}

function* signoutUserSuccess(action: SignoutUserSuccessAction) {

    // Setup the customer sync in case it is not already done
    yield call(setupSyncCustomer, {
        type: SETUP_SYNC_CUSTOMER,
        payload: {
            isAnonymous: true,
        }
    });
}

function* signinSuccess(action: FirebaseSigninSuccessAction) {

    const { selectedLocation, sessionId }: LocationState = yield select((state: RootState) => state.locations);
    const { order }: OrderState = yield select((state: RootState) => state.order);

    try {

        const accountId = selectedLocation?.account_id;
        const locationId = selectedLocation?.id;

        if (!accountId || !locationId) {
            throw new Error("No account or location selected");
        }

        yield call(signinSuccessApiCall, accountId, locationId, sessionId, SignInProviders.PASSWORD, order.id);
    }
    catch (error) {
        log.error("Error in firebaseSigninSuccess API call", error);
    }
}

const signinSuccessApiCall = async (
    accountId: string,
    locationId: string,
    sessionId: string | undefined,
    signinProvider: SignInProviders,
    orderId: string | undefined
): Promise<void> => {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
    headers.append("Authorization", `Bearer ${tokenResult?.token}`);

    sessionId && headers.append(SESSION_ID_HEADER, sessionId);

    // Building the URL with the params & query
    const fetchUrl = getApiEndpoint() + authenticationsApiRoutes.AUTHENTICATION_PASSWORD_ANONYMOUS_SUCCESS_ROUTE
        .replace(AUTHENTICATION_PROVIDER_PARAM, signinProvider);

    const body: AuthenticateSuccessRequest = {
        account_id: accountId,
        location_id: locationId,
        order_id: orderId,
    }

    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
    };

    const response = await fetch(fetchUrl, requestOptions);

    if (!response.ok) {
        log.error(response.json());
        throw new Error("Error in SigninSuccess API call");
    }
}

function* acceptTerms(action: AcceptTermsAction) {

    try {

        const { terms, deployment }: AppState = yield select((state: RootState) => state.app);
        const { data }: AuthenticationState = yield select((state: RootState) => state.authentication);

        const user = data.user_authentication_state.user;

        if (!user?.uid || !terms) {
            throw new Error("User/uid or terms to accept not found");
        }

        // Updating the object
        const currentAcceptedTerms: { [target: string]: { [deployment: string]: Date } } = {
            ...user.last_accepted_terms,
            [TermsTarget.CUSTOMERS]: {
                ...(user.last_accepted_terms ? user.last_accepted_terms[TermsTarget.CUSTOMERS] : {}),
                [deployment?.name ?? DEFAULT_DEPLOYMENT_NAME]: terms.application_date,
            }
        }

        // Do not try to update a not-existing user document (if anonymous)
        if (action.payload.isLoggedIn) {
            yield call(updateUserAcceptedTerms, user.uid, currentAcceptedTerms, db.collection(FIRESTORE_USERS_ACCESS_COLLECTION));
        }

        yield put(AuthenticationActions.acceptTermsSuccess(currentAcceptedTerms));

    } catch (error) {
        log.error("Could not update user after accepting terms", error);
        yield put(AuthenticationActions.acceptTermsError());
    }
}

const updateUserAcceptedTerms = async (
    userId: string,
    lastAcceptedTerms: { [target: string]: { [deployment: string]: Date } },
    collectionReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
) => {

    await collectionReference.doc(userId).update({
        last_accepted_terms: lastAcceptedTerms,
    });
}