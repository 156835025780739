import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended"
import { SupportedServiceType, Table } from "../../my-lemonade-library/model/Location"

export const LOAD_TABLES = "LOAD_TABLES"
export interface LoadTablesAction {
    type: typeof LOAD_TABLES
    payload: {
        accountId: string,
        locationId: string,
    }

}

export const LOAD_TABLES_SUCCESS = "LOAD_TABLES_SUCCESS"
export interface LoadTablesSuccessAction {
    type: typeof LOAD_TABLES_SUCCESS
    payload: { [key: string]: Table }
}

export const LOAD_TABLES_ERROR = "LOAD_TABLES_ERROR"
export interface LoadTablesErrorAction {
    type: typeof LOAD_TABLES_ERROR
    payload: string
}

export const SET_TABLE_SELECTED = "SET_TABLE_SELECTED"
export interface SetTableSelectedAction {
    type: typeof SET_TABLE_SELECTED
    payload: string
}

export const APPLY_SELECT_SERVICE_TYPE = "APPLY_SELECT_SERVICE_TYPE"
export interface ApplySelectServiceTypeAction {
    type: typeof APPLY_SELECT_SERVICE_TYPE
    payload: {
        catalog: CatalogExtended,
        serviceType: SupportedServiceType,
        tableArea: string | undefined
    }
}

export type TableActionsType = LoadTablesAction | LoadTablesSuccessAction | LoadTablesErrorAction | SetTableSelectedAction;

export const tableActions = {
    loadTables: (account_id: string, location_id: string): LoadTablesAction => {
        return {
            type: LOAD_TABLES,
            payload: {
                accountId: account_id,
                locationId: location_id,
            }
        }
    },

    loadTableSuccess: (data: { [key: string]: Table }): LoadTablesSuccessAction => {
        return {
            type: LOAD_TABLES_SUCCESS,
            payload: data
        }
    },

    loadTableError: (message: string): LoadTablesErrorAction => {
        return {
            type: LOAD_TABLES_ERROR,
            payload: message
        }
    },

    setTableSelected: (tableId: string): SetTableSelectedAction => {
        return {
            type: SET_TABLE_SELECTED,
            payload: tableId
        }
    },

    /**
     * Call this action to set a new service_type to the table. It will
     * set it in the location state, in the order state, and trigger some
     * functions.
     * @param catalog 
     * @param serviceType 
     * @returns 
     */
    applySelectServiceType: (
        catalog: CatalogExtended,
        serviceType: SupportedServiceType,
        tableArea: string | undefined
    ): ApplySelectServiceTypeAction => {

        return {
            type: APPLY_SELECT_SERVICE_TYPE,
            payload: {
                catalog,
                serviceType,
                tableArea
            }
        }
    }
}

export default tableActions;