import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Order, OrderItem, OrderItemExtended, OrderOption } from '../../my-lemonade-library/model/Order';
import { MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import PendingPaymentChip from './PendingPaymentChip';

type ShareDealProps = {
    order: Order;
    dealKey: string;
    dealItems: OrderItemExtended[];
    moreDetails: boolean;
    selectedItems?: OrderItemToPay[];
    onSelect: (val: OrderItemToPay[]) => void;
}

const ShareDeal: React.FC<ShareDealProps> = (props) => {

    const {
        order,
        dealKey,
        dealItems,
        moreDetails,
        selectedItems,
        onSelect,
    } = props;

    const theme = useTheme();

    const [isChecked, setIsChecked] = useState<boolean>(false)

    const orderDeal = useMemo(() => {
        return order.deals[dealKey];
    }, [dealKey, order.deals]);

    useEffect(() => {
        if (selectedItems) {
            const indexes = dealItems.map(dealItem => (
                selectedItems.findIndex(item => item.index === (dealItem as OrderItemExtended).index)
            ))
            if (indexes.every(index => index !== -1)) {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }
        }
    }, [selectedItems])

    const handleCheckbox = () => {
        let itemsToPayArray: OrderItemToPay[] = []
        dealItems.forEach(item => {
            const orderItemExt: OrderItemExtended = item as OrderItemExtended
            itemsToPayArray.push({ index: orderItemExt.index, quantity: isChecked ? 0 : 1 })
        })
        onSelect(itemsToPayArray)
    }

    if (order && orderDeal) {

        return (

            <Box
                data-test={`select-deal-${dealKey}`}
                width="100%"
                p={1}
                my={1}
                border="solid"
                onClick={() => handleCheckbox()}
                style={{
                    borderWidth: "0em 0em .1em 0em",
                    borderColor: theme.palette.grey[100]
                }}
                bgcolor={isChecked
                    ? `${theme.palette.primary.main}26`
                    : theme.palette.background.default
                }
            >

                <Box display="flex" flexDirection="row">
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        pr={3}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                        >
                            <Box display="flex">
                                <Typography
                                    color="textPrimary"
                                    variant='subtitle1'
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    1x
                                </Typography>

                                <Typography color="textPrimary">
                                    {orderDeal.name}
                                </Typography>
                            </Box>

                            {paymentHelper.isItemPendingPayment(dealItems[0], order.payments) && <PendingPaymentChip />}
                        </Box>
                    </Box>


                    <Typography color="textPrimary" variant='subtitle1'>
                        {MoneyToStringWithSymbol(
                            orderDeal.price_with_options
                                ? orderDeal.price_with_options
                                : orderDeal.price
                        )}
                    </Typography>

                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignContent='center'
                >

                    <Box
                        style={
                            !moreDetails
                                ? {
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                }
                                : {}
                        }
                        data-test={`orderItem.options.ref_${orderDeal.ref}`}
                    >
                        {
                            dealItems.map((item: OrderItem, index: number) => {
                                return (
                                    <Box lineHeight="1em" mt={index === 0 ? 1 : 0}>
                                        <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                                            {item.product_name}{item.options.map((elem: OrderOption, index: number) => (index === 0 ? " - " : ", ") + elem.name)}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>

                    <Box
                        ml={4}
                        flex={1}
                        height={theme.spacing(3)}
                    />

                    <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-end'
                    >
                        <CheckIcon
                            color={isChecked ? 'primary' : 'disabled'}
                            style={{ justifySelf: 'flex-end' }}
                            onClick={() => handleCheckbox()}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }
    return null;
}

export default ShareDeal;
