export enum CategoryReferenceLevel3 {
    // Adult Beverages -> Beer & ciders
    BEER = 'Beer',
    BEER_MIXED_DRINK = 'Beer mixed-drink',
    CIDER = 'Cider',
    NON_ALCOHOLIC_BEERS = 'Alcohol-free Beers',

    // Adult Beverages -> Champagne & sparkling wine
    CAVA = 'Cava',
    CHAMPAGNE = 'Champagne',
    CHAMPAGNE_BASED_COCKTAIL = 'Champagne-based cocktail',
    NON_ALCOHOLIC_SPARKLING_WINE = 'Non-Alcoholic Sparkling Wine',
    OTHER_SPARKLING_WINE = 'Other Sparkling Wine',
    PROSECCO_AND_ITALIAN_SPARKLING_WINE = 'Prosecco & Italian Sparkling Wine',

    // Adult Beverages -> Spirits
    ANISE_FLAVORED = 'Anise Flavored',
    BITTERS_AND_SPRAYS = 'Bitters & Sprays',
    COCKTAILS = 'Cocktails',
    COGNAC_AND_ARMAGNAC = 'Cognac & Armagnac',
    FORTIFIED_WINES_AND_DESSERT_WINES = 'Fortified Wines & Dessert Wines',
    GIN = 'Gin',
    LIQUEURS = 'Liqueurs',
    NON_ALCOHOLIC_SPIRIT = 'Non-alcoholic Spirits',
    OTHER_SPIRITS = 'Other Spirits',
    RTD_AND_HARD_SELTZERS = 'RTD & Hard-Seltzers',
    RUM = 'Rum',
    SAKE_SOJU_SHOCHU = 'Sake Soju Shochu',
    TEQUILA_AND_MEZCAL = 'Tequila & Mezcal',
    VERMOUTHS_AND_APERITIFS = 'Vermouths and Aperitifs',
    VODKA = 'Vodka',
    WHISKY = 'Whisky',

    // Adult Beverages -> Wine
    NON_ALCOHOLIC_WINE = 'Non-Alcoholic Wine',
    RED_WINE = 'Red Wine',
    ROSE_WINE = 'Rosé Wine',
    WHITE_WINE = 'White Wine',
    WINE_SPRITZER_AND_WINE_BASED_COCKTAILS = 'Wine Spritzer and wine-based cocktails',

    // Hot Beverages - Tea
    BUBBLE_TEA = 'Bubble Tea',
    MATCHA = 'Matcha',
    TEA = 'Tea',
    INFUSION_OR_HERBAL_TEA = 'Infusion / Herbal tea',

    // Non-Alcoholic Beverages -> NARTD (Non-Alcoholic Ready To Drink)
    CARBONATED_SOFT_DRINK = 'CSD (Carbonated Soft Drinks)',
    COCO = 'Eau de Coco ou Lait de Coco',
    FRUIT_BASED = 'Fruit Base',
    FUNCTIONAL_DRINK = 'Functional Drink',
    JUICES_NECTARS = 'Juices & Nectars',
    MILK_BASED = 'Milk-Based',
    TEA_BASED = 'Tea-Based',

    // Non-Alcoholic Beverages -> Syrup
    SYRUP = 'Syrup',

    // Non-Alcoholic Beverages -> Waters
    SPARKLING_WATER = 'Sparkling Water',
    STILL_WATER = 'Still Water',
    WATER_PITCHER = 'Water Pitcher',
}

export const category3_options = Object.values(CategoryReferenceLevel3);
