/**
 * Configs define how many character can be display before ellipsis
 * MIN = value for mobile device
 * MAX = value for others device
 */

export const MIN_CHAR_NAME_PRODUCT = 20;
export const MAX_CHAR_NAME_PRODUCT = 30;

export const MIN_CHAR_DESCRIPTION_PRODUCT = 30;
export const MAX_CHAR_DESCRIPTION_PRODUCT_WITH_TAGS = 40;
export const MAX_CHAR_DESCRIPTION_PRODUCT = 60;
