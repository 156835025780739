import { Box, Button, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as ROUTES from '../../config/routes';
import { Location, SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderStatus } from '../../my-lemonade-library/model/Order';
import { ORDER_ID_QUERY_PARAM_NAME } from '../../orders/configs/OrdersRouterConfig';
import orderAction from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import ThemeSvg from './ThemeSvg';


const INTL_API_ERROR_PREFIX: string = "apiError.code.";

export const getDefaultOrderErrorTitle = (serviceType?: SupportedServiceType, withIntl?: IntlShape): { value: string; params?: any } => {
    let messageId: string;
    if (serviceType === SupportedServiceType.CHECKOUT) {
        messageId = "orders.payments.error";
    } else {
        messageId = "Order.error";
    }
    if (withIntl) {
        return { value: withIntl.formatMessage({ id: messageId }) };
    } else {
        return { value: messageId };
    }
}

export const getDefaultOrderErrorSubtitle = (selectedLocation?: Location, serviceType?: SupportedServiceType, withIntl?: IntlShape): { value: string; params?: any } => {
    let messageId: string;
    let messageValues: any = undefined;
    if (serviceType === SupportedServiceType.EAT_IN ||
        serviceType === SupportedServiceType.CHECKOUT) {
        messageId = "orders.preparation.contact_waiter";
    } else {
        if (selectedLocation?.phone) {
            messageId = "orders.preparation.contact_location_phone";
            messageValues = { phone: selectedLocation?.phone };
        } else {
            messageId = "orders.preparation.contact_location_nophone";
        }
    }
    if (withIntl) {
        return { value: withIntl.formatMessage({ id: messageId }, messageValues) }
    } else {
        return { value: messageId, params: messageValues };
    }
}

interface CommonOrderErrorPageProps {
    titleId?: string,
    subtitleId?: string,
    returnHomeButton?: boolean
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CommonOrderErrorPage: React.FC<CommonOrderErrorPageProps> = (props) => {

    const { titleId, subtitleId, returnHomeButton } = props;
    const { selectedTable, selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { order, currentOrder: currentOrderState } = useTypedSelector((state: RootState) => state.order);
    const { error: apiSendError, errorCode, errorMessage } = currentOrderState;

    const intl = useIntl();
    const theme = useTheme();
    const query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<ROUTES.RouteParams>();
    const orderId = query.get(ORDER_ID_QUERY_PARAM_NAME);

    const [translatedErrorCodeMessage, setTranslatedErrorCodeMessage] = useState<string>();

    useEffect(() => {

        if (errorCode) {
            const intlErrorCodeMessageId = INTL_API_ERROR_PREFIX + errorCode;
            if (intl.messages[intlErrorCodeMessageId]) {
                setTranslatedErrorCodeMessage(intl.formatMessage({ id: intlErrorCodeMessageId }));
            }
            else {
                log.info(`No translation found for error code ${errorCode} (key: ${intlErrorCodeMessageId})`);
                setTranslatedErrorCodeMessage(errorCode);
            }
        }
        else if (order.status === OrderStatus.REJECTED_PAYMENT) {
            const translatedErrorCodeMessage = intl.formatMessage({ id: "orders.error.rejected_payment" });
            setTranslatedErrorCodeMessage(translatedErrorCodeMessage);
        }
        else {
            setTranslatedErrorCodeMessage("");
        }

    }, [errorCode, intl]);

    const returnHomePage = () => {

        // If the order is rejected, we reset it for now
        // TODO: duplicate it if rejected ?
        if (order.status === OrderStatus.REJECTED) {

            dispatch(orderAction.resetOrder());
        }

        // Reset the error, close the modal and go home
        dispatch(orderAction.resetOrderError());
        dispatch(orderAction.closeModal());
        history.push(ROUTES.getHomeFullRoute(match.params.tableLinkKey));
    }

    return (
        <>
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >

                <Box maxWidth="80%">
                    <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/error.svg`} />
                </Box>

                <Box
                    mt={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                >

                    <Typography variant="h2" color="textSecondary">

                        {intl.formatMessage({ id: "oops" })}
                        <br />
                        {intl.formatMessage({
                            id: titleId ?? (errorCode || orderId)
                                ? getDefaultOrderErrorTitle(selectedTable.service_type).value
                                : "404page.unavailable"
                        }, { br: <br /> })}
                    </Typography>

                    {!translatedErrorCodeMessage && errorMessage &&
                        <>
                            <Typography variant="caption" style={{ color: theme.palette.text.disabled, marginTop: theme.spacing(2) }} >
                                {errorCode ? `${errorMessage}\n(${errorCode})` : errorMessage}
                            </Typography>
                        </>
                    }
                </Box>

                {
                    <Box mt={4}>
                        <Box
                            whiteSpace="pre-wrap"
                            textAlign="center"
                        >

                            {translatedErrorCodeMessage ?

                                <Typography style={{ marginTop: theme.spacing(2) }}>
                                    {translatedErrorCodeMessage}
                                </Typography>

                                :

                                <Typography>
                                    {subtitleId
                                        ? intl.formatMessage({ id: subtitleId })
                                        : intl.formatMessage(
                                            { id: getDefaultOrderErrorSubtitle(selectedLocation, selectedTable.service_type).value },
                                            getDefaultOrderErrorSubtitle(selectedLocation, selectedTable.service_type).params,
                                        )
                                    }
                                </Typography>
                            }

                        </Box>
                    </Box>
                }

            </Box>

            {(returnHomeButton || apiSendError)
                ? <Button
                    variant="contained"
                    color="primary"
                    style={{
                        marginTop: theme.spacing(3),
                        textTransform: "none",
                    }}
                    onClick={returnHomePage}
                >

                    <Typography>
                        {intl.formatMessage({ id: "returnHomePage" }, { br: <br /> })}
                    </Typography>

                </Button>
                : ""
            }
        </>
    )
}

export default CommonOrderErrorPage;

