import Carousel from 'react-material-ui-carousel';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { getTemporallyAvailableCatalogDeals } from '../../my-lemonade-library/src/orders/services/OrderPricing';
import { useTypedSelector } from '../../redux/root-reducer';
import DealsCarouselItem from '../components/DealsCarouselItem';

/*
Component in charge to scan deals available and display them in the deals carousell
render by CategoriesPages.tsx
*/
export default function Deals() {

    const { selectedCatalog } = useTypedSelector(state => state.locations)
    const { order } = useTypedSelector(state => state.order)
    const catalog = selectedCatalog;

    if (catalog && catalog.display && catalog.display.hide_deals_mobile_carousel) {
        return null;
    }

    let available_deals = catalog ? getTemporallyAvailableCatalogDeals(catalog, order, false) : []

    if (available_deals.length === 0) {
        return null
    }

    return (

        // TODO: replace with a more simple carousel module? Like react-carousel?
        <Carousel
            autoPlay={true}
            swipe={false}
        >
            {available_deals.map((elem: DealExt, index) => <DealsCarouselItem key={index} item={elem} />)}
        </Carousel>
    );
}
