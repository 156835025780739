import _ from "lodash";
import { ConnectorWalletConfiguration, DEFAULT_COUNTRY, Location, SupportedPayementType, WalletConfigurationType, WalletPercentageConfiguration, WalletStepConfiguration } from "../../../model/Location";
import { Order, OrderInBase, OrderItem, OrderItemExtended, OrderPayment, OrderStatus, PaymentType } from "../../../model/Order";
import { addMoney, DEFAULT_CURRENCY, getCurrency, Money, moneyToNumber, numberToMoney, substractMoney } from "../../common/models/Money";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { log } from "../../common/services/LogService";
import { CONNECTORS_SUPPORTING_ADDING_PAYMENT } from "../../connectors/configs/ConnectorsSupportConfig";
import { CONNECTORS_COMPATIBLE_CONNECTOR_WALLET, CONNECTOR_CREDIT_WALLET_ITEM } from "../../connectors/models/ConnectorWallet";
import { PaymentFeeConfigPriceParameters } from "../../orders/models/PaymentFeeConfigPriceParameters";
import { getOrderFirestoreDocPath } from "../../orders/services/OrderService";
import { PAYMENTS_SUPPORTING_FULL_REFUND, PAYMENTS_SUPPORTING_PARTIAL_REFUND } from "../configs/PaymentsProviderConfig";
import { MAX_AMOUNT_ALLOWED_RESTAURANT_TICKET, RESTAURANT_TICKET_PAYMENT_TYPES } from "../configs/RestaurantTicketPaymentConfig";
import { LyraMarketplaceCreatePaymentOptions, LyramarketplacePaymentMethod } from "../models/lyramarketplace/LyraMarketplaceCreatePaymentOptions";
import OrderItemUserPayment from "../models/OrderItemUserPayment";
import { OrderPaymentStatus } from "../models/OrderPaymentStatus";
import { OrderRefundStatus } from "../models/OrderRefundStatus";
import OrderSharedPaymentInfo from "../models/OrderSharedPaymentInfo";
import { PaymentAmountType } from "../models/PaymentAmountType";
import PaymentErrors from "../models/PaymentErrors";
import PaymentFeeConfig from '../models/PaymentFeeConfig';
import { PaymentShareInfos } from "../models/PaymentShareInfos";
import { PaymentStatus } from "../models/PaymentStatus";
import { PaymentTypeExtended, SpecificPaymentType } from "../models/PaymentTypeExtended";
import { PaymentTypeUnavailabilityReason } from "../models/PaymentTypeUnavailabilityReason";

export const getPaymentOrderLogFirestoreCollectionPath = (accountId: string, locationId: string, orderId: string): string => {
    return `${getOrderFirestoreDocPath(accountId, locationId, orderId)}/${MylemonadeScopes.PAYMENTS}`;
}

class PaymentHelper {

    /**
     * @param paymentTypeExtended before the conversion if any
     * @param paymentType after the conversion
     * @param total 
     * @param location 
     * @returns 
     */
    isPaymentAvailable = (
        paymentTypeExtended: PaymentTypeExtended,
        paymentType: PaymentType,
        total: number,
        location: Location
    ): SupportedPayementType | null => {

        const supported = location.supported_payment_types;
        const type_found = supported.find(elem => elem.type === paymentType);

        if (!type_found) {
            return null;
        }

        const paymentValid = this.isPaymountAmountValidForPaymentType(type_found, total);

        if (
            !paymentValid.available
            && (
                paymentValid.unavailable_reason !== PaymentTypeUnavailabilityReason.MAX_AMOUNT
                || !this.allowSplittingPaymentAboveMaxLimit(paymentTypeExtended)
            )
        ) {
            return null;
        }

        return type_found;
    }

    getPriceToPayWithoutOverpaying = (order: Order, paymentAmount: Money, tipChargeAmount?: Money) => {
        let priceToPay = moneyToNumber(order.total);
        if (paymentAmount) {
            const requested_payment_amount = moneyToNumber(paymentAmount);
            if (requested_payment_amount) {
                let tip = 0;
                if (tipChargeAmount) {
                    tip = moneyToNumber(tipChargeAmount);
                }
                priceToPay = Math.min(requested_payment_amount - tip, priceToPay) + tip;
            }
        }
        return priceToPay;
    }

    /**
     * Check the min & max limits of the payment type and tells if the amount is valid.
     * @param paymentType 
     * @param total 
     * @param ignoreMaxLimit useful when sharePayment is enabled on the webapp
     * @returns 
     */
    isPaymountAmountValidForPaymentType = (
        paymentType: SupportedPayementType,
        total: number,
        ignoreMaxLimit?: boolean | undefined
    ): {
        available: boolean,
        unavailable_reason?: PaymentTypeUnavailabilityReason,
    } => {

        // Total is below the min amount, minimum is inclusive
        if (paymentType) {

            if (paymentType.min_amount && total < moneyToNumber(paymentType.min_amount)) {
                return { available: false, unavailable_reason: PaymentTypeUnavailabilityReason.MIN_AMOUNT };
            }

            // Total is above the max amount (max was exclusive before, changed it to inclusive)
            if (paymentType.max_amount && total > moneyToNumber(paymentType.max_amount) && !ignoreMaxLimit) {
                return { available: false, unavailable_reason: PaymentTypeUnavailabilityReason.MAX_AMOUNT };
            }
            return { available: true };
        }
        return { available: false, unavailable_reason: PaymentTypeUnavailabilityReason.NOT_FOUND };
    }

    getOrderPaymentStatus = (order: Order, ignoreOverpaid?: boolean): OrderPaymentStatus => {
        return this.getPaymentStatus(order.payments, order.total, order.id, ignoreOverpaid);
    }

    /**
     * Get the order payment status
     */
    getPaymentStatus = (orderPayments: OrderPayment[] | undefined, orderTotal: Money, orderId: string, ignoreOverpaid?: boolean): OrderPaymentStatus => {

        const totalOrderAmount: number = moneyToNumber(orderTotal, true);
        const orderPaidAmount = Math.round(this.getPaidAmount(orderPayments, orderId) * 100);

        log.info(`getPaymentStatus: order ${orderId} total amount: ${totalOrderAmount} ; paid amount: ${orderPaidAmount}`);

        if (!(Array.isArray(orderPayments) && orderPayments.length > 0)) {
            return OrderPaymentStatus.UNPAID;
        }

        if (orderPaidAmount >= totalOrderAmount) {
            if (orderPaidAmount > totalOrderAmount && !ignoreOverpaid) {
                log.error(new Error(`It seems that the order ${orderId} has been overpaid (${orderPaidAmount} paid for total amount of ${totalOrderAmount})`));
            }
            return OrderPaymentStatus.PAID;
        } else {
            const pendingPayments = orderPayments?.filter((orderPayment) => orderPayment.status === PaymentStatus.PENDING);
            log.info(`getPaymentStatus: order ${orderId} ; ${pendingPayments?.length} pending payments`, { pendingPayments });
            if (pendingPayments && pendingPayments.length) {
                return OrderPaymentStatus.PENDING;
            } else {
                if (orderPaidAmount === 0) {
                    const failedPayments = orderPayments?.filter((orderPayment) => orderPayment.status === PaymentStatus.UNPAID);
                    const refundedPayments = orderPayments?.filter((orderPayment) => orderPayment.status === PaymentStatus.REFUND);
                    log.info(`getPaymentStatus: order ${orderId} ; ${failedPayments?.length} failed payments ; ${refundedPayments?.length} refunded payments`, { failedPayments, refundedPayments });
                    if (failedPayments && failedPayments.length) {
                        return OrderPaymentStatus.FAILED;
                    }
                    else if (refundedPayments && refundedPayments.length) {
                        return OrderPaymentStatus.REFUNDED;
                    }
                    else {
                        return OrderPaymentStatus.UNPAID;
                    }
                } else {
                    return OrderPaymentStatus.PARTIALLY_PAID;
                }
            }
        }
    }

    getOrderPaidAmount = (order: Order, count_pending: boolean = false): number => {
        return this.getPaidAmount(order.payments, order.id, count_pending);
    }

    getPaidPaymentsCount = (order: Order): number => {
        const paidPayments = this.getPaidPayments(order);
        return paidPayments ? paidPayments.length : 0
    }

    getPaidPayments = (order: Order): OrderPayment[] | undefined => {
        return order.payments?.filter((payment) => payment.status === PaymentStatus.PAID)
    }

    getPaymentRefundedAmount = (orderPayment: OrderPayment, countPending?: boolean, sellerId?: string): number => {
        let refundedAmount = 0;
        orderPayment?.refunds?.forEach((refund) => {
            if (!sellerId || sellerId === refund.seller_id) {
                if (refund.status === OrderRefundStatus.REFUNDED || (countPending && refund.status === OrderRefundStatus.PENDING)) {
                    refundedAmount += moneyToNumber(refund.amount);
                }
            }
        })
        return refundedAmount;
    }

    /**
     * @param orderPayment Paid amount including refund
     * @returns 
     */
    getPaymentRemainingPaidAmount = (orderPayment: OrderPayment, countPending?: boolean, sellerId?: string): number => {
        let remainingPaidAmount = 0;
        if (orderPayment.status === PaymentStatus.PAID || (countPending && orderPayment.status === PaymentStatus.PENDING)) {
            if (!sellerId) {
                remainingPaidAmount += moneyToNumber(orderPayment.amount);
            } else {
                const sellerPart = orderPayment.split_by_seller ? orderPayment.split_by_seller[sellerId] : undefined;
                if (sellerPart) {
                    remainingPaidAmount += moneyToNumber(addMoney(sellerPart.amount, sellerPart.fee_amount));
                }
            }
            remainingPaidAmount -= this.getPaymentRefundedAmount(orderPayment, countPending, sellerId);
        }
        return Math.round(remainingPaidAmount * 100) / 100;
    }

    /**
     * Get the amount paid for an order.
     * @param order
     * @param count_pending if true, the PENDING payment lines will be taken into account
     */
    getPaidAmount = (orderPayments: OrderPayment[] | undefined, orderId: string, count_pending: boolean = false): number => {

        const alreadyIncludedPaymentId: string[] = []
        let totalPaidAmount = 0;
        orderPayments?.forEach((payment) => {
            if (
                payment.status === PaymentStatus.PAID
                || (count_pending && payment.status === PaymentStatus.PENDING)
            ) {
                // If the payment intent id is not filled, it means that the payment is not from mylemonade but from the POS: trust it
                // Otherwise, avoid duplicate lines
                if (!payment.payment_intent_id || !alreadyIncludedPaymentId.includes(payment.payment_intent_id)) {
                    alreadyIncludedPaymentId.push(payment.payment_intent_id);
                    totalPaidAmount += this.getPaymentRemainingPaidAmount(payment, count_pending);
                } else {
                    log.error(new Error(`The payment ${payment.payment_intent_id} has been added twice to order ${orderId}`));
                }
            }
        });

        return Math.round(totalPaidAmount * 100) / 100;
    }

    /**
     * Only rejected and cancelled orders can be refunded, except if we provide an intentId.
     * In this case, we can refund the payment.
     * @param order 
     * @param intentIdProvided 
     * @returns 
     */
    isRefundFullySupported = (order: Order, payment_intent_id?: string | undefined): boolean => {

        // Only rejected and cancelled orders can be refund
        if (
            !payment_intent_id
            && order.status !== OrderStatus.REJECTED
            && order.status !== OrderStatus.CANCELLED
        ) {
            return false;
        }

        if (order.payments && order.payments.length) {
            for (let iPayment = 0; iPayment < order.payments.length; iPayment++) {

                const orderPayment = order.payments[iPayment];

                if (payment_intent_id && orderPayment.payment_intent_id !== payment_intent_id) {
                    break;
                }

                if (orderPayment.status === PaymentStatus.PAID && (!this.isRefundSupportedForPaymentType(orderPayment.payment_type) || !orderPayment.transaction_id)) {
                    return false;
                }
            }

            const orderPaymentStatus = this.getOrderPaymentStatus(order, true);
            return (orderPaymentStatus === OrderPaymentStatus.PAID || orderPaymentStatus === OrderPaymentStatus.PARTIALLY_PAID);
        }
        return false;
    }

    isRefundSupportedForPayment = (order?: Order | null, paymentIntentId?: string, partialRefund?: boolean): boolean => {
        if (!order) {
            return false;
        }
        let paymentType: PaymentType;
        if (!paymentIntentId) {
            if (!order.payments?.length) {
                return false;
            } else {
                paymentType = order.payments[0].payment_type;
            }
        } else {
            const foundPayment = order.payments?.find((payment) => payment.payment_intent_id === paymentIntentId);
            if (!foundPayment) {
                return false;
            } else {
                paymentType = foundPayment.payment_type;
            }
        }
        return this.isRefundSupportedForPaymentType(paymentType, partialRefund);
    }

    isRefundSupportedForPaymentType = (paymentType: PaymentType, partialRefund?: boolean): boolean => {
        if (partialRefund && !PAYMENTS_SUPPORTING_PARTIAL_REFUND.includes(paymentType)) {
            return false;
        }

        if (!(PAYMENTS_SUPPORTING_FULL_REFUND.includes(paymentType))) {
            return false;
        }
        return true;
    }

    /**
     * Is the user allowed to pay
     * TODO: test it !
     */
    canPay = (location: Location, order: Order, throwError?: boolean): boolean => {

        // The total amount of each payment reach the order total
        if (!this.getOrderRemainingAmount(order)) {
            if (throwError) {
                throw PaymentErrors.ADD_PAYMENT_NOT_ALLOWED.withValue({
                    remaining_amount: 0
                })
            } else {
                return false;
            }
        }

        if (this.isOrderToCreditWallet(location, order)) {
            return true;
        }

        // A draft/waiting submission is not supposed to be paid but first sent to API
        // A cancelled order cannot be paid anymore
        if (order.status === OrderStatus.DRAFT || order.status === OrderStatus.WAITING_SUBMISSION || order.status === OrderStatus.CANCELLED) {
            if (throwError) {
                throw PaymentErrors.ADD_PAYMENT_NOT_ALLOWED.withValue({
                    order_status: order.status
                })
            } else {
                return false;
            }
        }
        // Order not yet finalized
        else if (order.status === OrderStatus.PENDING_PAYMENT || order.status === OrderStatus.REJECTED_PAYMENT) {
            return true;
        } else { // Order finalized, should have been send to the POS if needed
            const finalizedOrderPaymentAllowed = location.orders?.allow_online_payment_for_opened_orders ? true : false;
            if (!finalizedOrderPaymentAllowed) { // Disabled by settings
                if (throwError) {
                    throw PaymentErrors.ADD_PAYMENT_NOT_ALLOWED.withValue({
                        allow_online_payment_for_opened_orders: finalizedOrderPaymentAllowed
                    })
                } else {
                    return false;
                }
            } else {
                if (!location.connector?.type) { // NO POS, can add payment
                    return true;
                } else { // POS, check if already sent and 
                    if (!order.private_ref) { // No private ref = order not yet sent to POS
                        if (throwError) {
                            throw PaymentErrors.ADD_PAYMENT_NOT_ALLOWED.withValue({
                                order_private_ref: "null"
                            })
                        } else {
                            return false;
                        }
                    }
                    else if (!CONNECTORS_SUPPORTING_ADDING_PAYMENT.includes(location.connector.type)) { // POS not supporting adding payment
                        if (throwError) {
                            throw PaymentErrors.ADD_PAYMENT_NOT_ALLOWED.withValue({
                                connector_type: location.connector.type
                            })
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            }
        }
    }

    /**
     * Get the order remaining payment amount
     * Only the PAID lines are taken into account
     */
    getOrderRemainingAmount = (order: Order): number => {
        const totalOrderAmount: number = moneyToNumber(order.total);
        return Math.round((totalOrderAmount - this.getOrderPaidAmount(order)) * 100) / 100;
    }

    /**
     * Get the order remaining payment amount. The PAID and PENDING
     * lines are taken into account
     * WARNING: the remaining amount can be 0 while the order is not
     * fully paid yet!! Use getorderRemainingAmount function to know
     * how much left is actually to pay
     * @param order 
     * @returns 
     */
    getOrderRemainingAmountIncludePending = (order: Order): number => {

        const totalOrderAmount: number = moneyToNumber(order.total);
        const remaining: number = totalOrderAmount - this.getOrderPaidAmount(order, true);

        // If negative, we just return 0, see the comment above the function for more info
        if (remaining < 0) {

            return 0;
        }

        return remaining;
    }

    getSharePaymentLastDivider = (orderPayments: OrderPayment[]): number | undefined => {

        let finalDivider: number | undefined = undefined;
        let selectedOrderPayment: OrderPayment | undefined = undefined;

        for (const elem of orderPayments) {

            if (
                elem.share_payment_divider
                && elem.status === PaymentStatus.PAID
                && (
                    !selectedOrderPayment
                    || !elem.updated_at
                    || !selectedOrderPayment.updated_at
                    || new Date(elem.updated_at) > new Date(selectedOrderPayment.updated_at)
                )
            ) {

                if (elem.share_payment_divider) {

                    finalDivider = elem.share_payment_divider;
                }
                selectedOrderPayment = elem;
            }
        }

        return finalDivider;
    }

    getSharePaymentsInfos = (orderPayments: OrderPayment[] | undefined, paymentType: SupportedPayementType, currency: string): PaymentShareInfos | undefined => {
        let shareSupportedPaymentType: SupportedPayementType = paymentType;

        let thisMinAmount: number = Infinity;
        let thisMaxAmount: number = -Infinity;
        let shareNumberFromPreviousPayments: number | undefined = undefined

        if (shareSupportedPaymentType && shareSupportedPaymentType.share_provider_type) {

            // Updating the min amount (1)
            if (shareSupportedPaymentType.min_amount && moneyToNumber(shareSupportedPaymentType.min_amount) < thisMinAmount) {
                thisMinAmount = moneyToNumber(shareSupportedPaymentType.min_amount);
            }

            // Updating the max amount (1)
            if (shareSupportedPaymentType.max_amount && moneyToNumber(shareSupportedPaymentType.max_amount) > thisMaxAmount) {
                thisMaxAmount = moneyToNumber(shareSupportedPaymentType.max_amount);
            }
        }

        // Updating the min amount (2)
        if (paymentType.min_amount && moneyToNumber(paymentType.min_amount) < thisMinAmount) {
            thisMinAmount = moneyToNumber(paymentType.min_amount);
        }

        // Updating the max amount (2)
        if (paymentType.max_amount && moneyToNumber(paymentType.max_amount) > thisMaxAmount) {
            thisMaxAmount = moneyToNumber(paymentType.max_amount);
        }

        if (orderPayments) {
            shareNumberFromPreviousPayments = paymentHelper.getSharePaymentLastDivider(orderPayments)
        }

        const paymentShareInfos: PaymentShareInfos = {
            payment_type: shareSupportedPaymentType,
            used_payment_type: paymentType,
            share_number: shareNumberFromPreviousPayments ?? undefined,
            min_amount: thisMinAmount !== Infinity ? numberToMoney(thisMinAmount, currency) : undefined,
            max_amount: thisMaxAmount !== -Infinity ? numberToMoney(thisMaxAmount, currency) : undefined
        }

        return paymentShareInfos

    }

    /**
     * Set order shared payment info (items paid, etc.)
     * Warning, suppose to have an order already priced
     * @param order 
     * @param orderPayment 
     * @param orderSharedPaymentInfo 
     */
    setOrderSharedPaymentInfo = (order: Order, orderPayment: OrderPayment, orderSharedPaymentInfo: OrderSharedPaymentInfo) => {

        let validPaymentAmountType = true;
        const paymentAmountInCents = moneyToNumber(orderPayment.amount, true);
        const paymentAmountCurrency = getCurrency(orderPayment.amount);
        const orderAmountInCents = moneyToNumber(order.total, true);

        if (orderAmountInCents === paymentAmountInCents) {
            orderPayment.amount_type = PaymentAmountType.FULL;
            return;
        } else if (orderSharedPaymentInfo.payment_amount_type === PaymentAmountType.MANUAL) {
            orderPayment.amount_type = PaymentAmountType.MANUAL;
            return;
        } else if (orderSharedPaymentInfo.payment_amount_type === PaymentAmountType.FULL) {
            validPaymentAmountType = false;
        }
        else if (orderSharedPaymentInfo.payment_amount_type === PaymentAmountType.ITEMS) {
            let totalItemsPriceInCents = 0;
            if (orderSharedPaymentInfo.payment_items && orderSharedPaymentInfo.payment_items.length) {
                orderSharedPaymentInfo.payment_items?.forEach((paymentItem) => {
                    if (paymentItem.index >= 0 && paymentItem.index < order.items.length) {
                        const orderItem = order.items[paymentItem.index];
                        const orderItemPaidQuantityPrice = paymentItem.quantity * (moneyToNumber(orderItem.price, true) + (orderItem.options_price ? moneyToNumber(orderItem.options_price, true) : 0))
                        totalItemsPriceInCents += orderItemPaidQuantityPrice
                    } else {
                        validPaymentAmountType = false;
                    }
                });
                if (paymentAmountInCents < totalItemsPriceInCents) {
                    // Invalid, because payment amount not reaching the sum for the items``
                    validPaymentAmountType = false;
                } else {
                    // Valid, we can affect it
                    orderPayment.amount_type = PaymentAmountType.ITEMS;
                    orderPayment.manual_additional_amount = numberToMoney(paymentAmountInCents - totalItemsPriceInCents, paymentAmountCurrency, true);
                    orderSharedPaymentInfo.payment_items?.forEach((paymentItem) => {
                        const orderItem = order.items[paymentItem.index];
                        if (!orderItem.payments) {
                            orderItem.payments = [];
                        }
                        let foundOrderItemPayment = orderItem.payments?.find((orderItemPayment) =>
                            orderItemPayment.payment_intent_id === orderPayment.payment_intent_id
                        );
                        if (foundOrderItemPayment) {
                            foundOrderItemPayment.user_id = orderPayment.payment_user_id;
                            foundOrderItemPayment.quantity = paymentItem.quantity;
                        } else {
                            const createdOrderItemPayment: OrderItemUserPayment = {
                                payment_intent_id: orderPayment.payment_intent_id,
                                quantity: paymentItem.quantity,
                                user_id: orderPayment.payment_user_id
                            }
                            orderItem.payments.push(createdOrderItemPayment);
                        }
                    });
                }
            } else {
                validPaymentAmountType = false;
            }
        } else if (orderSharedPaymentInfo.payment_amount_type === PaymentAmountType.SPLIT) {
            const divide_factor = Math.round(orderAmountInCents / paymentAmountInCents);
            const diff = orderAmountInCents - divide_factor * paymentAmountInCents;
            if (Math.abs(diff) <= 1) {
                orderPayment.amount_type = PaymentAmountType.SPLIT;
                orderPayment.share_payment_divider = divide_factor;
            } else {
                validPaymentAmountType = false;
            }
        }

        if (!orderSharedPaymentInfo.payment_amount_type || !validPaymentAmountType) {
            orderPayment.amount_type = PaymentAmountType.MANUAL;
        }
    }

    /**
     * Update order shared payment info status
     * @param order 
     * @param orderPayment 
     * @param orderSharedPaymentInfo 
     */
    updateOrderSharedPaymentStatus = (order: Order, paymentIntentId: string, paymentStatus: PaymentStatus) => {
        order.items?.forEach((orderItem) => {
            orderItem.payments?.forEach((orderItemPayment) => {
                if (orderItemPayment.payment_intent_id === paymentIntentId) {
                    orderItemPayment.payment_status = paymentStatus;
                }
            })
        });
    }

    canSharePay = (
        amountToPay: Money,
        amountRemaining: Money,
        minAmount: Money | undefined,
        maxAmount: Money | undefined
    ): {
        canPay: boolean,
        errorMessageIntlId?: string,
    } => {

        if (!amountToPay || !amountRemaining) {
            return { canPay: false, errorMessageIntlId: "error.internal" }
        }

        if (moneyToNumber(amountToPay) <= 0) {
            return { canPay: false, errorMessageIntlId: "error.null_amount" }
        }

        if (minAmount && moneyToNumber(amountToPay) < moneyToNumber(minAmount)) {
            return { canPay: false, errorMessageIntlId: 'error.min_amount_not_reached' }
        }

        if (maxAmount && moneyToNumber(amountToPay) > moneyToNumber(maxAmount)) {
            return { canPay: false, errorMessageIntlId: 'error.max_amount_reached' }
        }

        if (moneyToNumber(amountToPay) > moneyToNumber(amountRemaining)) {
            return { canPay: false, errorMessageIntlId: 'error.amount_above_remaining' }
        }

        // Now we check the min amount with the remaining amount left after the division
        const leftToPayAfterPartPaid = substractMoney(amountRemaining, amountToPay);
        if (
            minAmount
            && moneyToNumber(leftToPayAfterPartPaid) > 0
            && moneyToNumber(leftToPayAfterPartPaid) < moneyToNumber(minAmount)
        ) {
            return { canPay: false, errorMessageIntlId: 'error.min_amount_will_not_be_reached' }
        }

        return { canPay: true }
    }

    isPaid = ((thisOrder: Order | null | undefined): boolean | undefined => {

        if (thisOrder) {
            return (this.getOrderPaymentStatus(thisOrder) === OrderPaymentStatus.PAID);
        }
        return false;
    });

    /**
     * Returns true if there is at least one payment in the order history
     */
    isPartOfTheOrderPaid = (thisOrder: Order) => {
        return (this.getOrderPaidAmount(thisOrder) > 0);
    }

    isAutoRefundActive = (location: Location, orderPayment: OrderPayment): boolean => {

        if (!PAYMENTS_SUPPORTING_FULL_REFUND.includes(orderPayment.payment_type)) {
            return false;
        }

        const foundSupportedPaymentType = location.supported_payment_types?.find((supportedPaymentType) => {
            return supportedPaymentType.type === orderPayment.payment_type;
        });
        return !foundSupportedPaymentType || !foundSupportedPaymentType.disable_error_auto_refund;
    }

    savePaymentFeeConfigPriceParameters = (order: OrderPayment, paymentFeeConfig: PaymentFeeConfig) => {
        const paymentFeeConfigPriceParameters: PaymentFeeConfigPriceParameters = {
            fixed_fee: paymentFeeConfig.fixed_fee,
            min_fee: paymentFeeConfig.min_fee,
            max_fee: paymentFeeConfig.max_fee,
            percentage_fee: paymentFeeConfig.percentage_fee,
            min_percentage_fee: paymentFeeConfig.min_percentage_fee,
            max_percentage_fee: paymentFeeConfig.max_percentage_fee,
            fee_vat: paymentFeeConfig.fee_vat
        }

        // Remove undefined values
        Object.keys(paymentFeeConfigPriceParameters).forEach(key => {
            if (paymentFeeConfigPriceParameters[key as keyof PaymentFeeConfigPriceParameters] === undefined) {
                delete paymentFeeConfigPriceParameters[key as keyof PaymentFeeConfigPriceParameters]
            }
        });

        order.fee_config_pricing_parameters = paymentFeeConfigPriceParameters;
    }

    /**
     * Set up the max amount for "restaurant ticket" payment types
     * Max 25€ by legislation (setup API call for other countries if needed someday)
     */
    setupRestaurantTicketMaxAmounts = (
        locationPaymentTypes: SupportedPayementType[] | undefined,
        currency: string | undefined,
    ) => {
        locationPaymentTypes?.forEach((paymentTypeConfig) => {
            if (RESTAURANT_TICKET_PAYMENT_TYPES.includes(paymentTypeConfig.type)) {

                const ticketMaxAmount = MAX_AMOUNT_ALLOWED_RESTAURANT_TICKET[paymentTypeConfig.country ?? DEFAULT_COUNTRY] ?? undefined;
                const paymentTypeMaxAmount = paymentTypeConfig.max_amount ? moneyToNumber(paymentTypeConfig.max_amount) : undefined;

                // Replace the value if the legal maximum for restaurant ticket is lower
                if (ticketMaxAmount && (!paymentTypeMaxAmount || ticketMaxAmount < paymentTypeMaxAmount)) {
                    paymentTypeConfig.max_amount = numberToMoney(
                        ticketMaxAmount,
                        currency ?? DEFAULT_CURRENCY,
                    );
                }
            }
        });
    }

    convertSpecificPaymentTypeToStandardWithOptions = (
        originalPaymentType: PaymentTypeExtended,
        originalOptions: any,
    ): {
        newPaymentType: PaymentType,
        newPaymentOptions: any,
    } => {

        switch (originalPaymentType) {

            case SpecificPaymentType.LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA:
                return {
                    newPaymentType: PaymentType.LYRA_MARKETPLACE,
                    newPaymentOptions: {
                        ...(originalOptions as LyraMarketplaceCreatePaymentOptions | undefined),
                        do_no_use_saved_card: true,
                    },
                };

            case SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS:
                return {
                    newPaymentType: PaymentType.LYRA_MARKETPLACE,
                    newPaymentOptions: {
                        ...(originalOptions as LyraMarketplaceCreatePaymentOptions | undefined),
                        do_no_use_saved_card: true,
                        payment_method: LyramarketplacePaymentMethod.CONECS,
                    }
                };

            default:
                return {
                    newPaymentType: originalPaymentType,
                    newPaymentOptions: originalOptions,
                };
        }
    }

    /** 
     * Allow the user to use a payment type even if the max_amount is reached. If so, the user will be asked to pay the
     * rest using another payment type. Useful for "restaurant tickets" configurations: they can pay 25€ with Swile and the
     * remaining 10€ with Lyra (example).
     */
    allowSplittingPaymentAboveMaxLimit = (paymentType: PaymentTypeExtended): boolean => {
        return RESTAURANT_TICKET_PAYMENT_TYPES.includes(paymentType);
    }

    /**
     * If the amount is above max limit, truncate it to the limit itself.
     * WARNING: this function does not check if the replacement is allowed or not.
     * @param previousAmount 
     * @param paymentConfig 
     * @returns 
     */
    changeAmountToPayIfAboveMaxLimit = (previousAmount: Money, paymentConfig: SupportedPayementType): Money => {
        const maxAuthorizedAmount = paymentConfig.max_amount;
        if (maxAuthorizedAmount && moneyToNumber(previousAmount) > moneyToNumber(maxAuthorizedAmount)) {
            log.info(`Payment (type ${paymentConfig.type}, amount ${previousAmount}) is above the max amount (${maxAuthorizedAmount}) but it's a restaurant ticket payment: replacing the paymentAmount by the max authorized amount (${previousAmount} -> ${maxAuthorizedAmount})`);
            return maxAuthorizedAmount;
            // TODO: add information in the payment to trace the fact that the amount has been modified?
        }
        return previousAmount;
    }


    isOrderToCreditWallet = (location: Pick<Location, "supported_payment_types" | "connector">, order: Pick<Order, "items">): boolean => {

        if (!location.connector) {
            return false;
        }

        const isCompatibleConnector = CONNECTORS_COMPATIBLE_CONNECTOR_WALLET.includes(location.connector.type);
        const isConnectorWalletSupported = location.supported_payment_types?.some(type => type.type === PaymentType.CONNECTOR_WALLET);

        return order.items.some((item) =>
            item.product_ref === CONNECTOR_CREDIT_WALLET_ITEM
            && isCompatibleConnector
            && isConnectorWalletSupported
        );
    }

    /**
     * Make sure that all the payments in the order are related to at least 1 item.
     * Necessary condition to show the items choice only in share payment page.
     * @param order 
     * @returns 
     */
    areAllPaymentsLinkedToItems = (order: Pick<OrderInBase, "items" | "payments">): boolean => {
        // If no payment, it's ok
        if (!order.payments) {
            return true;
        }

        return order.payments.every((payment) => {
            if (!payment.payment_intent_id) {
                return false;
            }

            return order.items.some((item) => {
                return item.payments?.some((itemPayment) => itemPayment.payment_intent_id === payment.payment_intent_id);
            });
        });
    }

    separatePaidAndRemainingItems = (order: Pick<OrderInBase, "items" | "payments">): {
        alreadyPaidItems: OrderItemExtended[],
        remainingItems: OrderItemExtended[],
        alreadyPaidDealItems: { [dealKey: string]: OrderItemExtended[] },
        remainingDealItems: { [dealKey: string]: OrderItemExtended[] },
    } => {

        const result: ReturnType<typeof this.separatePaidAndRemainingItems> = {
            alreadyPaidItems: [],
            remainingItems: [],
            alreadyPaidDealItems: {},
            remainingDealItems: {},
        };

        order.items.forEach((item, index) => {

            const itemWithItsIndex: OrderItemExtended = {
                ...(_.cloneDeep(item)),
                index,
            }

            /** 
             * Determine if the item is part of a "multi-deal", meaning a deal with at least
             * 2 items/lines. If it is, we'll add the items to the deal part of the return. If the
             * item is not in a deal or is alone in its deal, we'll add it to the remainingItems part
             */
            const isItemInMultiDeal = (
                item.deal_line?.deal_key
                && order.items.filter(it => it.deal_line?.deal_key === item.deal_line?.deal_key).length > 1
            );

            // Setup the 2 deal arrays, will be deleted at the end if not used
            if (isItemInMultiDeal) {
                if (!result.alreadyPaidDealItems[item.deal_line?.deal_key ?? ""]) {
                    result.alreadyPaidDealItems[item.deal_line?.deal_key ?? ""] = [];
                }
                if (!result.remainingDealItems[item.deal_line?.deal_key ?? ""]) {
                    result.remainingDealItems[item.deal_line?.deal_key ?? ""] = [];
                }
            }

            const remainingArray = isItemInMultiDeal ? result.remainingDealItems[item.deal_line?.deal_key ?? ""] : result.remainingItems;
            const alreadyPaidArray = isItemInMultiDeal ? result.alreadyPaidDealItems[item.deal_line?.deal_key ?? ""] : result.alreadyPaidItems;

            // Not related to any payment
            if (!item.payments || item.payments.length === 0) {
                remainingArray.push(itemWithItsIndex);
                return;
            }

            // There is at least one payment link
            let paidQuantity = 0;
            item.payments.forEach((paymentLink) => {
                const foundPayment = order.payments?.find(p => (
                    p.payment_intent_id === paymentLink.payment_intent_id
                    || p.connector_ref === paymentLink.payment_intent_id
                ));
                if (foundPayment && foundPayment.status === PaymentStatus.PAID) {
                    paidQuantity += paymentLink.quantity;
                }
            });

            // Not paid yet, push as it is (with the current quantity) in remainingItems
            if (paidQuantity === 0) {
                remainingArray.push(itemWithItsIndex);
            }
            // All paid, push as it is (with the current quantity) in paidItems
            else if (paidQuantity === item.quantity) {
                alreadyPaidArray.push(itemWithItsIndex);
            }
            // Partially paid, push in both arrays
            else {
                alreadyPaidArray.push({
                    ...itemWithItsIndex,
                    quantity: paidQuantity,
                });
                remainingArray.push({
                    ...itemWithItsIndex,
                    quantity: item.quantity - paidQuantity,
                });
            }
        });

        // If not used, delete the deal arrays
        Object.entries(result.alreadyPaidDealItems).forEach(([dealKey, dealItems]) => {
            if (dealItems.length === 0) {
                delete result.alreadyPaidDealItems[dealKey];
            }
        });

        Object.entries(result.remainingDealItems).forEach(([dealKey, dealItems]) => {
            if (dealItems.length === 0) {
                delete result.remainingDealItems[dealKey];
            }
        });

        return result;
    }

    isItemPendingPayment = (item: OrderItem | undefined, orderPayments: OrderPayment[] | undefined): boolean => {
        return Boolean(item?.payments?.find((paymentLink => {
            const foundPayment = orderPayments?.find((payment) => (
                payment.payment_intent_id === paymentLink.payment_intent_id
                || payment.connector_ref === paymentLink.payment_intent_id
            ));
            return Boolean(
                foundPayment
                && foundPayment?.status === PaymentStatus.PENDING
            )
        })));
    }


    /**
     * Compute the amount to credit on the wallet based on the payment type configuration and the base amount sent by the customer.
     * @param baseAmount 
     * @param supportedPaymentType 
     */
    computeCreditWalletAmountByConfigType = (baseAmount: Money, walletConfiguration: ConnectorWalletConfiguration | undefined): Money => {
        if (!walletConfiguration) {
            return baseAmount;
        }
        let amount: Money = baseAmount;

        if (walletConfiguration.type === WalletConfigurationType.STEPS) {
            const steps = walletConfiguration.configuration as WalletStepConfiguration[];
            let creditedAmount: Money = "0.00 EUR";
            // Check if we have a step that match with the base amount
            for (const step of steps) {
                if (moneyToNumber(step.amount_to_reach) && moneyToNumber(amount) >= moneyToNumber(step.amount_to_reach)) {
                    creditedAmount = step.credited_amount;
                } else {
                    break;
                }
            }
            // Add the credited amount to the base amount 
            amount = addMoney(amount, creditedAmount);
        } else if (walletConfiguration.type === WalletConfigurationType.PERCENTAGE) {
            const percentage = walletConfiguration.configuration as WalletPercentageConfiguration;
            // Add the percentage to the base amount
            const amountWithPercentage = moneyToNumber(amount) + (percentage.percentage * moneyToNumber(amount));
            amount = numberToMoney(amountWithPercentage, getCurrency(baseAmount));
        }

        return amount;
    }


}

export const paymentHelper = new PaymentHelper();
