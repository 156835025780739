import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import _ from "lodash";
import React from 'react';
import { useIntl } from 'react-intl';
import { cursor } from '../../Common/StyledComponents';
import { OrderItem, OrderOption } from '../../my-lemonade-library/model/Order';

interface OrderDealDetailsModalProps {
    selectedDealName: string,
    dealDetails: OrderItem[],
    open: boolean,
    onClose: () => void,
}

const OrderDealDetailsModal: React.FC<OrderDealDetailsModalProps> = (props) => {

    const {
        selectedDealName,
        dealDetails,
        onClose,
        open,
    } = props

    const intl = useIntl();
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >

            <DialogTitle
                sx={{
                    bgcolor: theme.palette.background.default,
                }}
            >
                <IconButton
                    onClick={onClose}
                    style={{
                        ...cursor,
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        color: theme.palette.text.disabled
                    }}
                    size="large">
                    <CloseRoundedIcon />
                </IconButton>

                <Typography variant="h2">
                    {intl.formatMessage({ id: "order.dealDetailsModal.title" })}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ bgcolor: theme.palette.background.default }}>
                <Typography
                    color="textSecondary"
                    variant='h4'
                    fontWeight={600}
                >
                    {selectedDealName}
                </Typography>
                {dealDetails.map((dealDetailItem: OrderItem, index: number) => (
                    <Box
                        display="flex"
                        flexDirection="column"
                        key={index}
                        px={0}
                        py={1}
                    >
                        <Typography
                            color="textSecondary"
                            variant='h5'
                            fontWeight={500}
                        >
                            {dealDetailItem.product_name}
                        </Typography>
                        {!_.isEmpty(dealDetailItem?.options) &&
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                pt={1}
                            >
                                {dealDetailItem.options.map((elem: OrderOption) =>
                                (
                                    <Typography color="textSecondary" pl={2}>
                                        {`- ${elem.name}`}
                                    </Typography>
                                )
                                )}
                            </Box>
                        }
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    );

}

export default OrderDealDetailsModal;

