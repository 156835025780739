import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Dialog, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { LoyaltyInformationToDisplayConfig } from '../../loyalty/redux/models/LoyaltyState';
import { RootState, useTypedSelector } from "../../redux/root-reducer";

interface LoyaltyExplainationProps {
    useIcon?: boolean;
    asText?: boolean;
}

const LoyaltyExplaination: React.FC<LoyaltyExplainationProps> = (props) => {

    const { useIcon, asText } = props

    const { estimatedLoyaltyConfigContent: loyaltyDisplayContent } = useTypedSelector((state: RootState) => state.loyalty);

    const theme = useTheme();
    const intl = useIntl();

    const [displayInformation, setDislpayInformation] = useState<boolean>(false);

    const renderText = (displayContent: LoyaltyInformationToDisplayConfig) => {
        return (
            <Box
                display="flex"
                flexDirection="column"
                marginTop={2}
            >
                <Typography variant="caption">
                    {displayContent.isPaymentsOnly ? "∙ " + intl.formatMessage({ id: "loyalty_page.payments_only" }) : ""}
                </Typography>
                <Typography variant="caption">
                    {displayContent.onlyPaidOrders ? "∙ " + intl.formatMessage({ id: "loyalty_page.only_paid_orders" }) : ""}
                </Typography>
                <Typography variant="caption">
                    {displayContent.earnExplaination ? "∙ " + displayContent.earnExplaination : ""}
                </Typography>
                <Typography variant="caption" style={{ marginTop: theme.spacing(.5) }}>
                    {displayContent.spendExplaination ? "∙ " + displayContent.spendExplaination : ""}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            {useIcon

                ? <IconButton
                    onClick={() => setDislpayInformation(!displayInformation)}
                    sx={{
                        padding: 0,
                        color: theme.palette.primary.contrastText
                    }}
                >
                    <InfoOutlinedIcon />
                </IconButton>
                : <Typography
                    variant="caption"
                    style={{
                        color: theme.palette.text.disabled,
                        textDecoration: "underline",
                    }}
                    onClick={() => setDislpayInformation(!displayInformation)}
                >
                    {intl.formatMessage({
                        id: `loyalty.${displayInformation ? "less" : "more"}_information`
                    })}
                </Typography>
            }

            {
                loyaltyDisplayContent &&
                <>
                    {asText
                        ? (displayInformation && renderText(loyaltyDisplayContent))
                        : <Dialog
                            open={displayInformation}
                            onClose={() => setDislpayInformation(false)}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                textAlign="start"
                                color={theme.palette.text.disabled}
                                marginTop={1}
                                p={3}
                            >

                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        color: theme.palette.text.disabled,
                                        top: 0,
                                        right: 0,
                                    }}
                                    onClick={() => setDislpayInformation(false)}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>

                                <AutoAwesomeOutlinedIcon
                                    color='primary'
                                    sx={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        height: '100px',
                                        transform: 'scale(3)'
                                    }}
                                />

                                <Typography
                                    variant='h4'
                                    color='secondary'
                                    textAlign='center'
                                    fontWeight='bold'
                                >
                                    {intl.formatMessage({
                                        id: "loyalty_page.card.title"
                                    }, {
                                        a: loyaltyDisplayContent.isCashback
                                            ? intl.formatMessage({ id: "loyalty.type.cashback" }).toLowerCase()
                                            : intl.formatMessage({ id: "loyalty.type.loyalty" }).toLowerCase()
                                    })}
                                </Typography>

                                {renderText(loyaltyDisplayContent)}
                            </Box>
                        </Dialog>
                    }
                </>
            }
        </>
    );
}

export default LoyaltyExplaination;