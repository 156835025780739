import { CatalogDisplayMode, Categorie } from '../../my-lemonade-library/model/Catalog';
import { CatalogExtended } from '../../my-lemonade-library/model/catalogExtended/CatalogExtended';
import { Table } from '../../my-lemonade-library/model/Location';
import { Order } from "../../my-lemonade-library/model/Order";

export const shouldDisplayCategory = (
    order: Order,
    category: Categorie,
    selectedCatalog: CatalogExtended,
    parentCategoryRef: string | null | undefined,
    selectedTable: Table | undefined
) => {
    // Do not display if disable or not matching the parent category
    // WARNING: parentCategoryRef can be null or undefined, to be taken into account in the check
    if (
        (category.disable && !category.display_if_disabled)
        || (!parentCategoryRef && category.parent_ref)
        || (parentCategoryRef && parentCategoryRef !== category.parent_ref)
        || (selectedTable && selectedTable.disabled_categories && selectedTable.disabled_categories.refs.includes(category.ref))
    ) {
        return false;
    } else {
        const subCategory = selectedCatalog.data.categories.filter(
            (cate) => cate.parent_ref === category.ref,
        );
        const products = selectedCatalog.data.productsByCategory ? selectedCatalog.data.productsByCategory[category.ref] : undefined;

        const deals = selectedCatalog.data.dealsByCategory ? selectedCatalog.data.dealsByCategory[category.ref] : undefined;

        // Do not display the category if it's empty (no subcategories and no products or deals)
        if (!subCategory.length && (!products || !products.length) && (!deals || !deals.length)) {
            return false;
        } else {
            return true;
        }
    }
};

/**
 * return the list of subCategories from a parent category
 * fonction use to check if the category has to be display is call in this function
 * @param cate
 * @param selectedCatalog
 */

export const subCategoriesList = (
    order: Order,
    cate: Categorie,
    selectedCatalog: CatalogExtended,
    selectedTable: Table | undefined
) => {
    const subCategoriesList: Categorie[] = [];
    selectedCatalog.data.categories.forEach((category) => {

        if (
            category.parent_ref
            && category.parent_ref === cate.ref
            && shouldDisplayCategory(order, category, selectedCatalog, category.parent_ref, selectedTable)
        ) {
            subCategoriesList.push(category);
        }
    });
    return subCategoriesList;
};

/**
 * Check if there is Icon and if icon extension is an svg
 * @param category 
 * @returns 
 */
export const hasSvgIcon = (category: Categorie): boolean => {
    const icon = category.icon;
    if (icon && icon.includes(".svg")) {
        return true
    } else {
        return false
    }
}

/**
 *  Check if the catalog has to be displayed as grid or scroll
 * @param selectedCatalog 
 * @returns true if the catalog has to be displayed as grid
 */
export const isDisplayedAsGrid = (selectedCatalog: CatalogExtended): boolean => {
    const rootCategoriesCount = (selectedCatalog.data.mainCategories?.length ?? 0);
    if (selectedCatalog.display?.force_display === CatalogDisplayMode.GRID
        || (selectedCatalog.display?.force_display !== CatalogDisplayMode.SCROLL
            && (rootCategoriesCount > 4
                || (rootCategoriesCount === 4
                    && selectedCatalog.data.products.length > 30)))
    ) {
        return true
    }
    return false
}
/**
 * Filter a list of categories and filter categories disable on table
 * Based on ref list save in selectedTable.disabled_categories
 * if no refs to filter is found, all categories are return
 * @param categoryList 
 * @param table 
 * @returns 
 */
export const filterCategoryDisable = (parentCategoryRef: string | null | undefined, categoryList: Categorie[], order: Order, catalog: CatalogExtended, table: Table) => {
    let categoriesToDisplay: Categorie[] = []
    categoryList.forEach(category => {
        // Check if the category is disabled, has restriction or is not allowed on the table
        if (shouldDisplayCategory(order, category, catalog, parentCategoryRef, table)) {
            categoriesToDisplay.push(category)
        }
    })

    return categoriesToDisplay
}


