import _ from 'lodash';
import { PaymentType } from '../../my-lemonade-library/model/Order';
import { Money } from '../../my-lemonade-library/src/common/models/Money';
import { ConnectorType } from '../../my-lemonade-library/src/connectors/models/ConnectorsType';
import { EDENRED_LOGOUT, EDENRED_LOGOUT_FAILURE, EDENRED_LOGOUT_SUCCESS, GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_SUCCESS, IS_THERE_PAYMENT_DATA, IS_THERE_PAYMENT_DATA_FAILURE, IS_THERE_PAYMENT_DATA_SUCCESS, PaymentActionsType } from './PaymentsActions';

export interface PaymentState {
    create_payment: {
        payment_type: PaymentType;
        payment_amount: string;
    }
    isTherePaymentData: {
        [type in PaymentType]?: {
            [userId: string]: boolean | null;
        }
    }
    connector_wallet: {
        [type in ConnectorType]?: {
            wallet_balance: Money,
        } | null;
    }
}

const createPaymentStateDefault: PaymentState = {
    create_payment: {
        payment_type: PaymentType.LYRA,
        payment_amount: "",
    },
    isTherePaymentData: {},
    connector_wallet: {},
};

export const paymentsReducer = (state = createPaymentStateDefault, action: PaymentActionsType) => {

    switch (action.type) {

        case IS_THERE_PAYMENT_DATA:

            const newState = _.cloneDeep(state);
            // We want to reset the state while waiting for the answer to show the loader when launching the page
            action.payload.paymentTypes.forEach((paymentType) => {
                newState.isTherePaymentData[paymentType] = {
                    ...newState.isTherePaymentData[paymentType],
                    [action.payload.userId]: null,
                }
            });
            return newState;

        case IS_THERE_PAYMENT_DATA_SUCCESS:
            return {
                ...state,
                isTherePaymentData: {
                    ...state.isTherePaymentData,
                    [action.payload.paymentType]: {
                        ...state.isTherePaymentData[action.payload.paymentType],
                        [action.payload.userId]: action.payload.result,
                    }
                },
            }

        case IS_THERE_PAYMENT_DATA_FAILURE:
            return {
                ...state,
                isTherePaymentData: {
                    ...state.isTherePaymentData,
                    [action.payload.paymentType]: {
                        ...state.isTherePaymentData[action.payload.paymentType],
                        [action.payload.userId]: false,
                    }
                }
            }

        case EDENRED_LOGOUT:
            return {
                ...state,
                isTherePaymentData: {
                    ...state.isTherePaymentData,
                    [PaymentType.EDENRED]: {
                        ...state.isTherePaymentData[PaymentType.EDENRED],
                        [action.payload.userId]: null,
                    }
                }
            }

        case EDENRED_LOGOUT_SUCCESS:
            return {
                ...state,
                isTherePaymentData: {
                    ...state.isTherePaymentData,
                    [PaymentType.EDENRED]: {
                        ...state.isTherePaymentData[PaymentType.EDENRED],
                        [action.payload.userId]: false,
                    }
                }
            }

        case EDENRED_LOGOUT_FAILURE:
            return {
                ...state,
                isTherePaymentData: {
                    ...state.isTherePaymentData,
                    [PaymentType.EDENRED]: {
                        ...state.isTherePaymentData[PaymentType.EDENRED],
                        [action.payload.userId]: true,
                    }
                }
            }

        case GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_SUCCESS:
            return {
                ...state,
                connector_wallet: {
                    ...state.connector_wallet,
                    [action.payload.connector_type]: {
                        wallet_balance: action.payload.wallet_balance,
                    }
                }
            }


        default:
            return state;
    }
}