import React, { useEffect, useRef, useState } from 'react';
interface IconTintProps {
    src: string,
    color: string,
    maxWidth: number,
    maxHeight: number

}
/**
 * component take an url and tint image with color given
 * maxWidth and maxHeight are number in px => use to define a ration beetween original img and size wanted 
 * @param props 
 * @returns 
 */
const IconTint: React.FC<IconTintProps> = (props) => {

    const { src, color, maxHeight, maxWidth } = props
    const canvasRef = useRef<HTMLCanvasElement>(null);
    // @ts-ignore
    const [size, setSize] = useState<{ width: string, height: string }>({ width: 20, height: 20 });
    // @ts-ignore
    const _scaleImage = (srcWidth, srcHeight, maxWidth, maxHeight) => {
        if (maxWidth && maxHeight) {
            const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return { width: srcWidth * ratio, height: srcHeight * ratio };
        }
        if ((maxWidth && !maxHeight) || (!maxWidth && maxHeight)) {
            throw new Error('If you are going to provide width, make sure to provide height as well');
        }
        return { width: srcWidth, height: srcHeight };
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            // eslint-disable-next-line no-undef
            const pic = new Image();
            pic.src = src;
            const tintCanvas = document.createElement('canvas');
            const tintCtx = tintCanvas.getContext('2d');
            const ctx = canvas.getContext('2d');
            if (tintCtx && ctx) {
                pic.onload = () => {
                    const result = _scaleImage(pic.width, pic.height, maxWidth, maxHeight);
                    setSize(result);
                    tintCanvas.width = result.width;
                    tintCanvas.height = result.height;
                    tintCtx.fillStyle = color;
                    tintCtx.fillRect(0, 0, result.width, result.height);
                    tintCtx.globalCompositeOperation = 'destination-atop';
                    tintCtx.drawImage(pic, 0, 0, result.width, result.height);
                    ctx.globalAlpha = 1;
                    ctx.drawImage(tintCanvas, 0, 0, result.width, result.height);
                };
            }
        }
    }, [color, maxWidth, maxHeight, src]);

    if (typeof window !== 'undefined' && window.document && window.document.createElement) {
        return (<canvas width={size.width} height={size.height} ref={canvasRef} />);
    }
    return null;
}


export default IconTint;
