import { Table } from "../../my-lemonade-library/model/Location";
import { LOAD_TABLES, LOAD_TABLES_ERROR, LOAD_TABLES_SUCCESS, TableActionsType } from "./TableActions";

export interface TableState {
    loading: boolean
    error: boolean
    errorMessage: string | null
    data: {
        [key: string]: Table
    } | null
}

const defaultTableState: TableState = {
    loading: false,
    error: false,
    errorMessage: null,
    data: null
}

export const tableReducer = (state: TableState = defaultTableState, action: TableActionsType): TableState => {
    switch (action.type) {
        case LOAD_TABLES:
            return {
                ...state,
                loading: true
            }
        case LOAD_TABLES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case LOAD_TABLES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            }
        default:
            return state;
    }
}