import { useEffect, useState } from 'react';
import { Categorie } from '../../my-lemonade-library/model/Catalog';
import { useTypedSelector } from '../../redux/root-reducer';
import { shouldDisplayCategory, subCategoriesList } from '../services/categoriesServices';

/**
 * Custom hook to retrieve available categories depending on service type, restrictions, etc.
 * @returns 
 */
function useAvailableCategories(parentCategoryRef: string | null | undefined, getAllSubCategories?: boolean): Categorie[] {

    const [categories, setCategories] = useState<Categorie[]>([]);
    const { selectedCatalog, selectedTable } = useTypedSelector(state => state.locations);
    const { order } = useTypedSelector(state => state.order);

    useEffect(() => {
        // Find the categories
        if (selectedCatalog && selectedTable) {

            if (getAllSubCategories) {
                const categoriesList = selectedCatalog.data.mainCategories as Categorie[];

                let displayedCategories = categoriesList.reduce((acc: Categorie[], current) => {
                    const subCat = subCategoriesList(order, current, selectedCatalog, selectedTable)

                    if (subCat.length > 0) {
                        subCat.forEach(cat => acc.push(cat))
                    }
                    return acc
                }, [])
                setCategories(displayedCategories);

            } else {
                const categoriesList = selectedCatalog?.data.categories as Categorie[];

                let displayedCategories = categoriesList.filter((category) => shouldDisplayCategory(order, category, selectedCatalog, parentCategoryRef, selectedTable));
                setCategories(displayedCategories);
            }
        } else {
            setCategories([]);
        }
    }, [selectedCatalog, parentCategoryRef, selectedTable.service_type]);

    return categories
}

export default useAvailableCategories;