import { CustomerInformationFormParameters } from "../models/CustomerInformationFormParameters";
import { CustomerInformationModalFormToDisplay } from "../models/CustomerInformationModalFormToDisplay";

export const SET_CUSTOMER_INFORMATION_MODAL = "SET_CUSTOMER_INFORMATION_MODAL";
export interface SetCustomerInformationModalAction {
    type: typeof SET_CUSTOMER_INFORMATION_MODAL;
    payload: {
        formToDisplay: CustomerInformationModalFormToDisplay | null;
        formParameters: CustomerInformationFormParameters;
    }
}

export const CLOSE_CUSTOMER_INFORMATION_MODAL_IF_DISPLAYED = "CLOSE_CUSTOMER_INFORMATION_MODAL_IF_DISPLAYED";
export interface CloseCustomerInformationModalIfDisplayedAction {
    type: typeof
    CLOSE_CUSTOMER_INFORMATION_MODAL_IF_DISPLAYED;
    payload: {
        formToClose: CustomerInformationModalFormToDisplay,
    }
}

export type CustomerInformationModalActionTypes =
    SetCustomerInformationModalAction
    | CloseCustomerInformationModalIfDisplayedAction;

export const CustomerInformationModalActions = {

    /**
     * Change the state of the customer information modal
     * @param formToDisplay the form type. If null, the modal won't be displayed
     * @param redirect_url if null, the popup will just close itself at then end. If true, there will be a redirection
     * @param hideWelcomeHeader? if true, the header with the logo and the welcome text will be hidden
     * @param isEndAuthentication? set to true if you call the popup to authenticate at the end of the order process. False if empty
     * @returns 
     */
    setCustomerInformationModal: (
        formToDisplay: CustomerInformationModalFormToDisplay | null,
        formParameters?: CustomerInformationFormParameters
    ): SetCustomerInformationModalAction => ({
        type: SET_CUSTOMER_INFORMATION_MODAL,
        payload: {
            formToDisplay,
            formParameters: formParameters ?? null
        }
    }),

    closeCustomerInformationModalIfDisplayed: (
        formToClose: CustomerInformationModalFormToDisplay,
    ): CloseCustomerInformationModalIfDisplayedAction => ({
        type: CLOSE_CUSTOMER_INFORMATION_MODAL_IF_DISPLAYED,
        payload: {
            formToClose,
        }
    }),
}

