import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useAcceptedTerms } from "../../authentication/hooks/useAcceptedTerms";
import ThemeSvg from "../../Common/components/ThemeSvg";
import { cursor } from "../../Common/StyledComponents";
import * as ROUTES from '../../config/routes';
import { desktopDisplayBreakpoint } from "../../config/theme";
import { OrderStatus } from "../../my-lemonade-library/model/Order";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import orderActions from "../redux/OrderActions";
import { finalizeOrderInfo } from "../services/OrderService";

interface SharedOrderLandingProps {

};

const SharedOrderLanding: React.FC<SharedOrderLandingProps> = (props: SharedOrderLandingProps) => {

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));

    const { tableLinkId, selectedLocation, selectedTable, selectedCatalog, fromApp } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state: RootState) => state.order);
    const { use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const user = data.user_authentication_state.user;
    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);

    const hasAcceptedTerms = useAcceptedTerms();

    const isPayButtonEnabled = (): boolean => {

        if (
            order.status === OrderStatus.PENDING_PAYMENT
            && paymentHelper.getOrderRemainingAmountIncludePending(order) > 0
        ) {

            return true;
        }

        return false;
    }

    if (!selectedLocation || !selectedCatalog) {
        return null;
    }

    return (

        <Box
            bgcolor="background.paper"
            height={1}
            width={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            py={isDesktopRender ? 5 : 4}
            px={isDesktopRender ? 15 : 4}
            data-test="shared-order-landing.root"
        >

            {
                // CENTER CONTENT (GLASSES, MAIN TEXT, SECONDARY TEXT)
            }
            < Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
                px={4}
            >
                <Box mt={5} display="flex" justifyContent="center">
                    <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/payment_processing.svg`}
                        maxWidth={"95%"}
                    />
                </Box>

                <Box mt={5}>

                    <Box mx={3}>

                        <Typography variant="h2" style={{ textAlign: "center" }}>

                            {intl.formatMessage({ id: "orders.multi_users.landing.title" })}

                        </Typography>

                    </Box>

                </Box>

                <Box mt={4} px={2}>

                    <Typography
                        style={{
                            whiteSpace: "pre-wrap",
                            textAlign: "center"
                        }}
                    >

                        {intl.formatMessage({ id: "orders.multi_users.landing.description" })}

                    </Typography>

                </Box>

            </Box>

            {
                // BOTTOM CONTENT: BUTTON & LINK
            }
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                px={2}
                mt={isDesktopRender ? 6 : 0}
            >

                <Typography
                    variant="subtitle1"
                    style={{
                        textDecoration: "underline",
                        ...cursor,
                        marginBottom: theme.spacing(1.5),
                        color: theme.palette.text.disabled,
                    }}
                    data-test="shared-order-landing.link_new_order"
                    onClick={() => {

                        // Reset the order, create a new one to firestore and go back to the catalog
                        dispatch(orderActions.resetOrder());
                        dispatch(orderActions.createDraftOrder());
                        history.push(ROUTES.getCategoriesFullRoute(tableLinkId))
                    }}
                >

                    {intl.formatMessage({ id: "orders.multi_users.landing.new_order" })}

                </Typography>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        padding: theme.spacing(1.45, 0),
                        width: "65%",
                        textTransform: "none",
                    }}
                    data-test="shared-order-landing.pay_button"
                    onClick={() => {

                        // Directly go to share payment page
                        //history.push(`/${tableLinkId}${SharePayment}?${ORDER_ID_QUERY_PARAM_NAME}=${order.id}`);

                        finalizeOrderInfo(
                            selectedLocation,
                            order,
                            user?.uid ?? "",
                            tableLinkId,
                            selectedTable.restrictions,
                            history,
                            dispatch,
                            selectedCatalog,
                            fromApp,
                            isTherePaymentData,
                            hasAcceptedTerms,
                            undefined,
                            localLoyaltyUsePoints,
                        );
                    }}
                    disabled={!isPayButtonEnabled()}
                >

                    <Typography>

                        {intl.formatMessage({ id: "Summary.sharePayment.pay" })}

                    </Typography>

                </Button>

            </Box>

        </Box>
    );
}

export default SharedOrderLanding;