import { Box, useMediaQuery, useTheme } from '@mui/material';
import { isEntityOrderableNow } from '../../catalog/services/CatalogService';
import AddButton from '../../Common/components/AddButton';
import EntityUnavailableText from '../../Common/components/EntityUnavailableText';
import { AddButtonRedirect } from '../../Common/models/AddButtonRedirect';
import { Product } from '../../my-lemonade-library/model/Catalog';
import PlusMinusQuantityButton from '../../orders/components/PlusMinusQuantityButton';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

interface AddProductButtonProps {
    onAddProduct: (redirect: AddButtonRedirect) => void;
    buttonTitleId?: string;
    hideQuantityButton?: boolean;
    price: string;
    quantity: number;
    onAdd: () => void;
    onRemove: () => void;
    hidePrice?: boolean;
    isButtonDisabled: boolean;
    product: Product;
    addToDeal: boolean;  // Tells if the product is being added to a deal
    displayFastCheckoutButton: boolean;
}

const AddProductButton: React.FC<AddProductButtonProps> = (props) => {

    const { hideQuantityButton, onAddProduct, buttonTitleId, price, quantity, onAdd, onRemove,
        hidePrice, isButtonDisabled, product, addToDeal, displayFastCheckoutButton } = props;

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { selectedCatalog, selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const theme = useTheme();

    const isEntityOrderableNowResult = isEntityOrderableNow(selectedCatalog, selectedLocation, selectedTable, product);

    // Those values will change the buttons so that they fit for small screens
    const S_BREAKPOINT: number = 390;

    const isSRender = useMediaQuery(theme.breakpoints.up(S_BREAKPOINT));

    // Only check availability if added as a product and not in a deal
    if (!addToDeal && !isEntityOrderableNowResult) {

        return (
            <EntityUnavailableText
                serviceType={order.service_type}
                entity={product}
            />
        );
    }

    return (

        <Box
            display="flex"
            flexDirection='column'
            justifyContent='space-around'
            width={1}
        >

            {!hideQuantityButton &&
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={3}
                >
                    <PlusMinusQuantityButton
                        quantity={quantity}
                        onAdd={onAdd}
                        onMinus={onRemove}
                        isSRender={isSRender}
                    />
                </Box>
            }

            <AddButton
                onSubmit={onAddProduct}
                price={price}
                isButtonDisabled={isButtonDisabled}
                hidePrice={hidePrice}
                displayFastCheckoutButton={displayFastCheckoutButton}
                buttonTitleId={buttonTitleId}
            />
        </Box>
    );
}

export default AddProductButton;