import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { Box } from "@mui/material";
import log from "loglevel";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getApiEndpoint } from "../../config/variables";
import { PaymentType } from "../../my-lemonade-library/model/Order";
import { Money, getCurrency, moneyToNumber, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import AdyenPaymentIntentInfos from "../../my-lemonade-library/src/payments/models/adyen/AdyenPaymentIntentInfos";
import AdyenPaymentMethodsRequest from "../../my-lemonade-library/src/payments/models/adyen/AdyenPaymentMethodsRequest";
import AdyenPaymentMethodsResponse from "../../my-lemonade-library/src/payments/models/adyen/AdyenPaymentMethodsResponse";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import { SESSION_ID_HEADER } from "../../my-lemonade-library/src/sessions/services/SessionService";
import OrderHeader from "../../orders/components/OrderHeader";
import { sendOrPayOrder } from "../../orders/services/OrderService";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import paymentsActions from "../redux/PaymentsActions";

interface PaymentAdyenProps { }

const PaymentAdyen: React.FC<PaymentAdyenProps> = (props) => {

    const intl = useIntl();
    const paymentContainersRef = useRef<HTMLDivElement>();
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [paymentComponent, setPaymentComponent] = useState<any>()

    const { order, location_id, account_id, payment_infos } = useTypedSelector((state: RootState) => state.order);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { selectedLocation, sessionId } = useTypedSelector((state: RootState) => state.locations);
    const { use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);

    const dispatch = useDispatch();
    //const { currentLang } = useTypedSelector((state) => state.lang)

    const onSubmit = (state: any, component: any) => {

        if (state.isValid) {
            // Store the component to be able to handle the action
            setPaymentComponent(component);
            const amountToPay: Money = numberToMoney(
                paymentHelper.getOrderRemainingAmount(order),
                getCurrency(order.total)
            );
            sendOrPayOrder(
                dispatch,
                order,
                data.user_authentication_state.user?.uid ?? "",
                PaymentType.ADYEN,
                amountToPay,
                state.data,
                undefined,
                undefined,
                undefined,
                selectedLocation?.enable_share_payment,
                localLoyaltyUsePoints,
            );
        }
    }

    const onAdditionalDetails = (state: any, component: any) => {
        dispatch(paymentsActions.sendAdyenPaymentDetails(state.data));
        setPaymentComponent(component);
    }

    const getPaymentMethods = async () => {

        log.debug(`Fetching payment methods for account ${account_id}, location ${location_id}`);
        // TODO: use state instead of navigator.language
        const adyenPaymentMethodsRequest: AdyenPaymentMethodsRequest = {
            account_id: account_id!,
            location_id: location_id!,
            amount: order.total,
            locale: navigator.language
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        sessionId && headers.append(SESSION_ID_HEADER, sessionId);
        const response = await fetch(`${getApiEndpoint()}/api/v1/payments/adyen/methods`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(adyenPaymentMethodsRequest)
        });
        const responseData = await response.json();
        const paymentMethodsResponse = responseData as AdyenPaymentMethodsResponse;

        const checkout = new AdyenCheckout({
            clientKey: paymentMethodsResponse.client_key,
            environment: paymentMethodsResponse.environment,
            paymentMethodsResponse: paymentMethodsResponse.payment_methods_response,
            paymentMethodsConfiguration: {
                card: {
                    showPayButton: true,
                    amount: {
                        value: moneyToNumber(order.total, true),
                        currency: getCurrency(order.total)
                    }
                }
            },
            onAdditionalDetails: onAdditionalDetails,
            onSubmit: onSubmit,
        });
        if (paymentContainersRef.current) {
            checkout.create("dropin").mount(paymentContainersRef.current);
            setIsInitialized(true);
        } else {
            log.debug(`Impossible to mount because no ref`)
        }
    };

    useEffect(() => {
        // Fetch config
        if (account_id && location_id && !isInitialized) {
            getPaymentMethods();
        }
    }, [paymentContainersRef, order]);

    useEffect(() => {
        // Fetch config
        if (payment_infos && payment_infos.payment_type === PaymentType.ADYEN) {
            const adyenPaymentInfo = payment_infos as AdyenPaymentIntentInfos;
            if (paymentComponent && adyenPaymentInfo.action) {
                paymentComponent.handleAction(adyenPaymentInfo.action);
            }
        }
    }, [payment_infos]);

    return (
        <Box>
            <OrderHeader
                titleId={intl.formatMessage({ id: "Payment.choix" })}
                closeIcon
                hideTable
                resetOrderOnBack={false}
            />
            <Box padding={2} {...{ ref: paymentContainersRef } as any}></Box>
        </Box>
    );
}

export default PaymentAdyen;
