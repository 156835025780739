import { Catalog, Categorie, Deal, Product } from "../../../../model/Catalog";
import { Discount } from "../../../discounts/models/Discount";
import BaseCatalogAnomaly from "./BaseCatalogAnomaly";
import CatalogAnomalyType from "./CatalogAnomalyType";

export type CatalogEntityWithRestriction = Catalog | Product | Categorie | Deal | Discount;

export interface InvalidRestriction extends BaseCatalogAnomaly {

    entity: CatalogEntityWithRestriction;
    restriction_index: number;

    anomaly_type: typeof CatalogAnomalyType.INVALID_RESTRICTION;
}

export interface InconsistentRestriction extends BaseCatalogAnomaly {

    entity: CatalogEntityWithRestriction;
    top_level_restriction_entity: TopLevelRestrictionEntity;

    anomaly_type: typeof CatalogAnomalyType.INCONSISTENT_RESTRICTION;
}

export enum TopLevelRestrictionEntity {
    LOCATION = "location",
    CATALOG = "catalog",
}