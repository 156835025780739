import { Box, Button, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import LoaderComponent from '../../Common/components/LoaderComponent';
import { CommonActions } from '../../Common/redux/CommonActions';
import * as ROUTES from '../../config/routes';
import { PaymentStatus } from '../../my-lemonade-library/src/payments/models/PaymentStatus';
import { SwilePaymentIntentInfos } from '../../my-lemonade-library/src/payments/models/swile/SwilePaymentIntentInfo';
import { LOADING_ORDER_TIMEOUT } from '../../orders/configs/OrderConfig';
import orderAction from '../../orders/redux/OrderActions';
import { useTypedSelector } from '../../redux/root-reducer';

interface PaymentSwileProps { }

// TODO: merge with Edenred as it's the same behavior?
const PaymentSwile: React.FC<PaymentSwileProps> = (props) => {

    const { tableLinkId } = useTypedSelector(state => state.locations);
    const { order, payment_infos } = useTypedSelector(state => state.order);

    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const match = useRouteMatch<ROUTES.RouteParams>();

    const returnHomePage = () => {
        dispatch(orderAction.closeModal());
        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
    }

    /**
     * Wait for the correct redirection to happen
     */
    useEffect(() => {
        if (payment_infos && order) {

            const swilePaymentIntentInfos = payment_infos as SwilePaymentIntentInfos;
            if (swilePaymentIntentInfos.authentication_url && window.top) {
                log.debug(`Found authentication_url, redirecting to ${swilePaymentIntentInfos.authentication_url}`);
                window.top.location.href = swilePaymentIntentInfos.authentication_url;
            }
            else if (swilePaymentIntentInfos.order_payment.status === PaymentStatus.PAID) {
                log.debug(`Payment is PAID, redirecting to order confirmation page`);
                dispatch(CommonActions.setRedirectURL(ROUTES.getOrderConfirmationFullRoute(tableLinkId, order.id)))
            }
        }
    }, [payment_infos, order]);


    /**
     * This useEffect is triggered ONLY when loading the page. We then set a timeout (20s).
     * When it's done, we go to error page (if no error, we should have been redirected before)
     */
    useEffect(() => {

        if (order) {

            log.debug(`Setting timeout for payment Swile`);
            const timeoutId = setTimeout(() => {
                log.debug(`Timout reached for payment Swile, go to error page`);
                dispatch(CommonActions.setRedirectURL(ROUTES.getErrorFullRoute(tableLinkId)));
            }, LOADING_ORDER_TIMEOUT);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [order]);


    return (
        <Box
            py={3}
            px={5}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
        >

            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >

                <Box
                    mt={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                >

                    <LoaderComponent />

                    <Typography variant="h2" color="textSecondary">
                        {intl.formatMessage({ id: "payments.edenred.redirect_authentication" })}
                    </Typography>

                </Box>

            </Box>


            <Button
                variant="contained"
                color="primary"
                style={{
                    marginTop: theme.spacing(3),
                    textTransform: "none",
                }}
                onClick={returnHomePage}
            >

                <Typography>
                    {intl.formatMessage({ id: "returnHomePage" }, { br: <br /> })}
                </Typography>

            </Button>

        </Box>
    );
}

export default PaymentSwile
