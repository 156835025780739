import LyraPaymentMetadata from "./LyraPaymentMetadata";

/**
 * See https://docs.lyra.com/en/rest/V4.0/api/playground/answer/Transaction/
 * Missing some parts of the model, see on the api
 */
export default interface LyraBaseTransaction {

    /**
     * Unique transaction ID generated by the payment gateway.
     */
    uuid?: string;

    /**
     * Payment amount in its smallest currency unit (cent for euro).
     */
    amount?: number;

    /**
     * Payment currency. Alphabetic code in uppercase according to alpha-3 (ISO 4217).
     */
    currency?: string;

    /**
     * Date and time when the transaction was recorded.
     */
    creationDate?: string;

    /**
     * Simplified transaction status. It is the same for all payment methods. It allows you to implement a generic code compatible with all payment methods.
     * Each payment method also has dedicated statuses. See the detailedStatus property for more information.
     */
    status?: LyraTransactionStatus;

    /**
     * The detailedStatus parameter provides detailed information on the payment status. Each of the statuses is specific to the used payment method.
     */
    detailedStatus?: LyraTransactionDetailedStatus;

    /**
     * TODO: to be completed ?
     */
    orderDetails?: LyraTransactionOrderDetails;

    /**
     * See https://docs.lyra.com/en/rest/V4.0/api/errors-reference.html
     */
    errorCode?: string | null;

    /**
     * See https://docs.lyra.com/en/rest/V4.0/api/errors-reference.html
     */
    errorMessage?: string | null;

    /**
     * Detailed unfiltered and unmodified error code returned by the Acquirer.
     */
    detailedErrorCode?: string | null;

    /**
     * Detailed unfiltered error message returned by the Acquirer or the payment application. It contains contextual information to help understand the error.
     */
    detailedErrorMessage?: string | null;

    /**
     * Indicates if the payment was made with strong authentication (such as 3D Secure).
     */
    effectiveStrongAuthentication: LyraEffectiveStringAuthentication;

    /**
     * Custom values linked to the transaction, in JSON format.
     */
    metadata?: LyraPaymentMetadata;

    /**
     * 
     */
    operationType?: LyraOperationType;

    /**
     * Token associated with a payment method.
     * Only tokens associated with a credit card are supported.
     */
    paymentMethodToken?: string | null;

    paymentMethodType?: LyraPaymentMethodType;

    /**
     * Shop ID.
     */
    shopId?: string;

    /**
     * transactionDetails: 
     * TODO
     */
    transactionDetails?: any;

    _type: "V4/Transaction";
}

/**
 * PAID	The transaction is paid.
 * UNPAID	The transaction is not paid.
 * RUNNING	The transaction is in progress.
 * PARTIALLY_PAID	The transaction is partially paid.
 */
export enum LyraTransactionStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
    RUNNING = "RUNNING",
    /**
     * Can occur for restaurant ticket (max amount of 19€ for example)
     * See: https://payzen.io/fr-FR/rest/V4.0/api/kb/conecs.html
     */
    PARTIALLY_PAID = "PARTIALLY_PAID",
}

/**
 * The detailedStatus parameter provides detailed information on the payment status. Each of the statuses is specific to the used payment method.
 * 
 */
export enum LyraTransactionDetailedStatus {

    /**
     * Status of a VERIFICATION type transaction for which the authorization or inquiry has been accepted. This status cannot change. Transactions with the "ACCEPTED" status are never captured at the bank. A VERIFICATION transaction is created when a token without payment is created or updated.
     */
    ACCEPTED = "ACCEPTED",

    /**
     * The amount is authorized and will be captured automatically.
     */
    AUTHORISED = "AUTHORISED",

    /**
     * The transaction amount has been captured, but a manual intervention is necessary for capturing the funds before the expiration date.
     */
    AUTHORISED_TO_VALIDATE = "AUTHORISED_TO_VALIDATE",

    /**
     * The transaction has been canceled.
     */
    CANCELLED = "CANCELLED",

    /**
     * The transaction amount has been captured.
     */
    CAPTURED = "CAPTURED",

    /**
     * The expiry date of the authorization request has been reached and the Merchant has not validated the transaction. The cardholder will therefore not be debited.
     */
    EXPIRED = "EXPIRED",

    /**
     * The transaction has been partially paid.
     */
    PARTIALLY_AUTHORISED = "PARTIALLY_AUTHORISED",

    /**
     * The transaction has been rejected.
     */
    REFUSED = "REFUSED",

    /**
     * Current acquirer verification processes. This status is not final.
     */
    UNDER_VERIFICATION = "UNDER_VERIFICATION",

    /**
     * The capture delay is longer than the authorization validity period.
     */
    WAITING_AUTHORISATION = "WAITING_AUTHORISATION",

    /**
     * The capture delay is longer than the authorization validity period. The Merchant must manually validate the transaction in order for the authorization request and the capture to take place.
     */
    WAITING_AUTHORISATION_TO_VALIDATE = "WAITING_AUTHORISATION_TO_VALIDATE",

    /**
     * An unexpected error has occurred.
     */
    ERROR = "ERROR",
}

export interface LyraTransactionOrderDetails {

    /**
     * Total amount of the order expressed in the smallest monetary unit (cent for euro).
     */
    orderTotalAmount?: number;

    /**
     * Initial transaction amount in case of currency conversion.
     */
    orderEffectiveAmount: number | null;

    /**
     * Order currency code, in the 4217 alpha-3 format.
     */
    orderCurrency?: string;

    /**
     * Allows to define the mode in which the order was created.
     */
    mode?: LyraTransactionMode;

    /**
     * Order reference defined by the Merchant.
     */
    orderId?: string;

    metadata?: any;

    _type: "V4/OrderDetails";
}


/**
 * 
 */
export enum LyraTransactionMode {

    /**
     * For a test order
     */
    TEST = "TEST",

    /**
     * For the actual order
     */
    PRODUCTION = "PRODUCTION",
}


/**
 * Indicates if the payment was made with strong authentication (such as 3D Secure).
 */
export enum LyraEffectiveStringAuthentication {

    /**
     * Strong authentication has been applied.
     */
    ENABLED = "ENABLED",

    /**
     * Strong authentication has not been applied.
     */
    DISABLED = "DISABLED",
}

/**
 * Type of operation.
 */
export enum LyraOperationType {
    /**
     * Debit operation.
     */
    DEBIT = "DEBIT",

    /**
     * Refund operation.
     */
    CREDIT = "CREDIT",

    /**
     * Payment method verification operation.
     */
    VERIFICATION = "VERIFICATION",
}


/**
 * 
 */
export enum LyraPaymentMethodType {
    /**
     * Payment by card
     */
    CARD = "CARD",
}

