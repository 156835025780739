import { DateTime } from "luxon";
import { log } from "./LogService";

export function extractDateFromTimestampOrString(date: Date): Date | null {
    const timeStamp = date as any;
    if (timeStamp && typeof timeStamp.toDate === "function") { // Timestamp object
        return timeStamp.toDate();
    } else if (timeStamp && timeStamp.seconds) { // Serialized firestore timestamp
        return DateTime.fromSeconds(timeStamp.seconds).toJSDate();
    }
    else {
        try {
            // Fallback to the standard date parsing
            const dateJS = new Date(date);
            if (dateJS.toString() !== "Invalid Date") {
                return dateJS;
            }
        } catch (error) {
            log.debug(error);
        }
    }
    log.debug(`Invalid timestamp or string date`, date)
    return null;
}

export const getMostRecentDate = (dates: (Date | null | undefined)[]): Date | undefined => {

    let mostRecentDate: Date | undefined;

    dates.forEach((date) => {

        if (!date) {
            return;
        }

        if (!mostRecentDate || date > mostRecentDate) {
            mostRecentDate = date;
        }
    });

    return mostRecentDate;
}

export const parseBaseModelDates = (baseModel: { created_at: Date; updated_at?: Date }) => {
    const parsedCreatedAt = extractDateFromTimestampOrString(baseModel.created_at);
    if (parsedCreatedAt) {
        baseModel.created_at = parsedCreatedAt;
    }
    if (baseModel.updated_at) {
        const parsedUpdatedAt = extractDateFromTimestampOrString(baseModel.updated_at);
        if (parsedUpdatedAt) {
            baseModel.updated_at = parsedUpdatedAt;
        }
    }
}

const dateHelper = {
    parseBaseModelDates,
    extractDateFromTimestampOrString,
    getMostRecentDate,
}
export default dateHelper;