import { Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getCategoriesFullRoute } from '../../config/routes';
import { CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y } from '../../customers/configs/CustomerInformationModalConfig';
import { CustomerInformationModalFormToDisplay } from '../../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../../customers/redux/CustomerInformationModalActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { ReactComponent as InsentiveEmailSuccess } from '../assets/insentives_email_success.svg';


interface EmailSentConfirmationProps {
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}

const EmailSentConfirmation = (props: EmailSentConfirmationProps) => {

    const intl = useIntl()
    const theme = useTheme()
    const history = useHistory();
    const dispatch = useDispatch();

    const { tableLinkId } = useTypedSelector((state: RootState) => state.locations);

    const onClose = useCallback(() => {

        // Redirect to menu 
        history.push(getCategoriesFullRoute(tableLinkId));

        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.EMAIL_SENT));

    }, [dispatch, tableLinkId]);

    useEffect(() => {
        // Register the on close action
        props.setOnModalCloseAction({
            formToDisplay: CustomerInformationModalFormToDisplay.EMAIL_SENT,
            onClose: onClose
        })
    }, [onClose])

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <SvgIcon
                viewBox='0 0 113 104'
                sx={{
                    width: '111px',
                    height: '102px',
                }}
            >
                <InsentiveEmailSuccess
                    stroke={theme.palette.primary.main}
                    fill={theme.palette.background.paper}
                />
            </SvgIcon>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                my={3}
            >
                <Typography textAlign='center' color={theme.palette.text.disabled}>
                    {intl.formatMessage({ id: 'authentication.insentives.email_sent' })}
                </Typography>
                <Typography textAlign='center' color={theme.palette.text.disabled}>
                    {intl.formatMessage({ id: 'authentication.insentives.email_sent.check_emails' })}
                </Typography>
            </Box>

            <Button
                fullWidth
                data-test="authentication.insentives.email_sent.continue_as_guest"
                variant='contained'
                disableElevation
                color={"primary"}
                style={{
                    textTransform: "none",
                    padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                }}
                onClick={onClose}
            >
                {intl.formatMessage({ id: 'orders.order_awaiting_payment.see_menu' })}
            </Button>
        </Box>
    )
}

export default EmailSentConfirmation