import { PaymentType } from "../../my-lemonade-library/model/Order";
import { Money } from "../../my-lemonade-library/src/common/models/Money";
import { ConnectorType } from "../../my-lemonade-library/src/connectors/models/ConnectorsType";
import CreatePaymentData from "../../my-lemonade-library/src/payments/models/CreatePaymentData";
import { OrderItemToPay } from "../../my-lemonade-library/src/payments/models/OrderItemToPay";
import { PaymentAmountType } from "../../my-lemonade-library/src/payments/models/PaymentAmountType";
import { PaymentTypeExtended } from "../../my-lemonade-library/src/payments/models/PaymentTypeExtended";

export const SEND_ADYEN_PAYMENT_DETAILS = "SEND_ADYEN_PAYMENT_DETAILS"
export interface SendAdyenPaymentDetailsAction {
    type: typeof SEND_ADYEN_PAYMENT_DETAILS
    payload: any

}

export const CREATE_ORDER_PAYMENT = "CREATE_ORDER_PAYMENT"
export interface CreateOrderPaymentAction {
    type: typeof CREATE_ORDER_PAYMENT;
    payload: {
        payment_type: PaymentTypeExtended;
        payment_amount: string;
        order_id: string;
        payment_items?: OrderItemToPay[];
        payment_amount_type?: PaymentAmountType;
        payment_data?: CreatePaymentData;
        tip_charge_amount?: Money
        /**
         * The previous payment to be canceled
         */
        cancel_payment_intent_id?: string;
        use_loyalty_points?: boolean;
    }
}


export const IS_THERE_PAYMENT_DATA = "IS_THERE_PAYMENT_DATA";
export const IS_THERE_PAYMENT_DATA_SUCCESS = "IS_THERE_PAYMENT_DATA_SUCCESS";
export const IS_THERE_PAYMENT_DATA_FAILURE = "IS_THERE_PAYMENT_DATA_FAILURE";

export interface IsTherePaymentDataAction {
    type: typeof IS_THERE_PAYMENT_DATA;
    payload: {
        accountId: string;
        locationId: string;
        paymentTypes: PaymentType[];
        userId: string;
    }
}
export interface IsTherePaymentDataSuccessAction {
    type: typeof IS_THERE_PAYMENT_DATA_SUCCESS;
    payload: {
        paymentType: PaymentType;
        userId: string;
        result: boolean | null | undefined;
    }
}
export interface IsTherePaymentDataFailureAction {
    type: typeof IS_THERE_PAYMENT_DATA_FAILURE;
    payload: {
        paymentType: PaymentType;
        userId: string;
    }
}


export const EDENRED_LOGOUT = "EDENRED_LOGOUT";
export const EDENRED_LOGOUT_SUCCESS = "EDENRED_LOGOUT_SUCCESS";
export const EDENRED_LOGOUT_FAILURE = "EDENRED_LOGOUT_FAILURE";

export interface EdenredLogoutAction {
    type: typeof EDENRED_LOGOUT;
    payload: {
        accountId: string;
        locationId: string;
        userId: string;
    }
}

export interface EdenredLogoutSuccessAction {
    type: typeof EDENRED_LOGOUT_SUCCESS;
    payload: {
        userId: string;
    }
}

export interface EdenredLogoutFailAction {
    type: typeof EDENRED_LOGOUT_FAILURE;
    payload: {
        userId: string;
    }
}


export const GET_CUSTOMER_CONNECTOR_WALLET_BALANCE = "GET_CUSTOMER_CONNECTOR_WALLET_BALANCE";
export const GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_SUCCESS = "GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_SUCCESS";
export const GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_FAILURE = "GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_FAILURE";

export interface GetCustomerConnectorWalletBalance {
    type: typeof GET_CUSTOMER_CONNECTOR_WALLET_BALANCE;
    payload: {
        customer_email: string | undefined;
        phone: string | undefined;
    }
}

export interface GetCustomerConnectorWalletBalanceSuccess {
    type: typeof GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_SUCCESS;
    payload: {
        connector_type: ConnectorType;
        wallet_balance: Money;
    }

}

export interface GetCustomerConnectorWalletBalanceFailure {
    type: typeof GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_FAILURE;
    payload: {
        error?: string;
    }
}

export type PaymentActionsType =
    SendAdyenPaymentDetailsAction
    | CreateOrderPaymentAction
    | IsTherePaymentDataAction
    | IsTherePaymentDataSuccessAction
    | IsTherePaymentDataFailureAction
    | EdenredLogoutAction
    | EdenredLogoutSuccessAction
    | EdenredLogoutFailAction
    | GetCustomerConnectorWalletBalance
    | GetCustomerConnectorWalletBalanceSuccess
    | GetCustomerConnectorWalletBalanceFailure

export const paymentsActions = {

    sendAdyenPaymentDetails: (detailsData: any): SendAdyenPaymentDetailsAction => {
        return {
            type: SEND_ADYEN_PAYMENT_DETAILS,
            payload: detailsData
        }
    },

    createOrderPayment: (
        payment_type: PaymentTypeExtended,
        payment_amount: string,
        order_id: string,
        payment_items?: OrderItemToPay[],
        payment_amount_type?: PaymentAmountType,
        tipChargeAmount?: Money,
        payment_data?: CreatePaymentData,
        cancel_payment_intent_id?: string,
        use_loyalty_points?: boolean,
    ): CreateOrderPaymentAction => ({
        type: CREATE_ORDER_PAYMENT,
        payload: {
            payment_type,
            payment_amount,
            order_id: order_id,
            payment_items,
            payment_amount_type,
            payment_data,
            tip_charge_amount: tipChargeAmount,
            cancel_payment_intent_id,
            use_loyalty_points,
        }
    }),

    isTherePaymentData: (accountId: string, locationId: string, paymentTypes: PaymentType[], userId: string): IsTherePaymentDataAction => ({
        type: IS_THERE_PAYMENT_DATA,
        payload: {
            accountId,
            locationId,
            paymentTypes,
            userId,
        }
    }),

    isTherePaymentDataSuccess: (paymentType: PaymentType, userId: string, result: boolean | null | undefined): IsTherePaymentDataSuccessAction => ({
        type: IS_THERE_PAYMENT_DATA_SUCCESS,
        payload: {
            paymentType,
            userId,
            result,
        }
    }),

    isTherePaymentDataFailure: (paymentType: PaymentType, userId: string): IsTherePaymentDataFailureAction => ({
        type: IS_THERE_PAYMENT_DATA_FAILURE,
        payload: {
            paymentType,
            userId,
        }
    }),

    edenredLogout: (accountId: string, locationId: string, userId: string): EdenredLogoutAction => ({
        type: EDENRED_LOGOUT,
        payload: {
            accountId,
            locationId,
            userId,
        }
    }),

    edenredLogoutSuccess: (userId: string): EdenredLogoutSuccessAction => ({
        type: EDENRED_LOGOUT_SUCCESS,
        payload: {
            userId
        }
    }),

    edenredLogoutFailure: (userId: string): EdenredLogoutFailAction => ({
        type: EDENRED_LOGOUT_FAILURE,
        payload: {
            userId
        }
    }),

    getCustomerConnectorWalletBalance: (customer_email: string | undefined, phone: string | undefined): GetCustomerConnectorWalletBalance => ({
        type: GET_CUSTOMER_CONNECTOR_WALLET_BALANCE,
        payload: {
            customer_email,
            phone,
        }
    }),
    getCustomerConnectorWalletBalanceSuccess: (connector_type: ConnectorType, wallet_balance: Money): GetCustomerConnectorWalletBalanceSuccess => ({
        type: GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_SUCCESS,
        payload: {
            connector_type,
            wallet_balance
        }
    }),

    getCustomerConnectorWalletBalanceFailure: (error?: string): GetCustomerConnectorWalletBalanceFailure => ({
        type: GET_CUSTOMER_CONNECTOR_WALLET_BALANCE_FAILURE,
        payload: {
            error
        }
    }),

}

export default paymentsActions;