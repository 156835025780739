import { Box, Typography, useTheme } from '@mui/material';
import Lottie from 'react-lottie-player';
import { useThemeType } from '../customHooks/useThemeType';

/**
 * loader to use on Application
 */
const LoaderComponent = () => {

    const theme = useTheme()
    const themeType = useThemeType(theme.palette.background.default)

    return (

        <Box
            data-test='loader'
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            p={5}
        >

            <Lottie
                loop
                path={`${process.env.PUBLIC_URL}/loading.json`}
                play
                style={{ width: 250, height: 250 }}
            />

            <Box
                width={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="caption">Powered by</Typography>
                <Box mt={1}>
                    <img src={themeType === "light" ? `${process.env.PUBLIC_URL}/assets/logo.svg` : `${process.env.PUBLIC_URL}/assets/logo-white.svg`} style={{ height: "22px" }} alt="" />
                </Box>
            </Box>

        </Box>

    )
}

export default LoaderComponent