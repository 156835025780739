import CustomerInformationModalFormOpenArgs from "../../customers/models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "../../customers/services/BaseFormModalService";
import { isUserLoggedIn } from "../helpers/AuthenticationHelpers";

export default class ForgetPasswordFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.FORGET_PASSWORD);
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: null } | null {

        const { user_authentication_state } = args.authenticationData;

        // Only used to autoclose the form, signup form will never open automatically
        if (forAutoclosing) {
            // If already logged in, automatically close the form
            if (isUserLoggedIn(user_authentication_state)) {
                return null;
            } else {
                return { form: this.form, parameters: null };
            }
        }

        return null;
    }
};