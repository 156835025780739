import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { isTablet } from "react-device-detect";
import log from '../Common/services/LogService';


export interface DeviceContextModel {
    mobile_device: boolean,
    tablet_device: boolean,
    tablet_portrait: boolean,
    tablet_landscape: boolean
    desktop_device: boolean
}
/**
 * Mobile first logic
 */
export const DEFAULT_DEVICE_CONTEXT: DeviceContextModel = {
    mobile_device: true,
    tablet_device: false,
    tablet_portrait: false,
    tablet_landscape: false,
    desktop_device: false,
}
/**
 * innerwidth breakpoint for large tablet
 */
const LARGE_TABLET_WIDTH_BREAKPOINT = 1000
/**
 * 
 */
export const EXTRA_SMALL_HEIGHT_TABLET = 600
/**
 * Hook use to save to global level the user device. 
 * This context can be consume in the app without use props or multiple useMediaQuery
 * Called in app.tsx to be set and provided from app root
 * @param theme 
 * @returns 
 */
function useDeviceContext(theme: Theme) {

    const [deviceContext, setDeviceContext] = useState<DeviceContextModel>(DEFAULT_DEVICE_CONTEXT)
    const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth)
    const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight)
    const xsView = useMediaQuery(theme.breakpoints.down('sm'))
    const tabletDevice = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
    const tabletPortrait = useMediaQuery(theme.breakpoints.only('sm'))
    const tabletLandscape = useMediaQuery(theme.breakpoints.only('md'))
    const desktopDevice = useMediaQuery(theme.breakpoints.up('lg'))


    useEffect(() => {
        function handleResize() {
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize)
    }, [])


    useEffect(() => {
        // log.info("isTablet", isTablet);
        /**
         * exeption for extra large Tablet to force portrait render based on innerWidth & innerHeight diff
         */
        if (isTablet && innerWidth > LARGE_TABLET_WIDTH_BREAKPOINT) {
            log.info('tablet device detetected with desktop device break point => extra large tablet, force tablet mode')
            setDeviceContext({
                mobile_device: false,
                tablet_device: isTablet,
                tablet_portrait: innerWidth < innerHeight,
                tablet_landscape: innerWidth > innerHeight,
                desktop_device: false
            })
        } else {
            setDeviceContext({
                mobile_device: xsView,
                tablet_device: tabletDevice,
                tablet_portrait: tabletPortrait,
                tablet_landscape: tabletLandscape,
                desktop_device: desktopDevice
            })
        }

    }, [xsView, tabletDevice, tabletPortrait, tabletLandscape, desktopDevice, innerWidth, innerHeight])

    return deviceContext
}

export default useDeviceContext;