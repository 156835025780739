import { Theme } from "@mui/material";
import { defaultMyLemonadeButtonBorderRadius } from "../../config/theme";

/**
 * This function fetches the value in the theme to apply it on
 * buttons for example. We have to use ts-ignore because we overriden
 * a property in the theme (see theme.ts) and TS isn't aware of this.
 * @param theme 
 * @returns 
 */
export const getRadiusFromTheme = (theme: Theme): number => {

    //@ts-ignore
    if (theme.overrides?.MuiButton?.root?.borderRadius) {

        //@ts-ignore
        return theme.overrides?.MuiButton?.root?.borderRadius;
    }
    else {

        return defaultMyLemonadeButtonBorderRadius;
    }
}