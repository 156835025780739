import { Location } from "history";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CommonActions } from "../redux/CommonActions";

const useHistoryTracking = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const getFullUrl = (location: Location<unknown>): string => {
        return location.pathname + location.search;
    }

    useEffect(() => {

        // First time: we push the current URL
        if (history.location.pathname) {
            dispatch(CommonActions.addUrlToNavigationHistory(getFullUrl(history.location)));
        }

        // Then we setup the listener for all the other URL changes
        history.listen((loc) => {
            dispatch(CommonActions.addUrlToNavigationHistory(getFullUrl(loc)));
        });

    }, [history, dispatch]);
}

export default useHistoryTracking;