import { ACCOUNT_PARAM } from "../../accounts/configs/AccountsApiRoutes";
import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_PARAM, LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

export const RECEIPT_REF_PARAM_KEY = "receiptRef";
export const RECEIPT_REF_PARAM = `:${RECEIPT_REF_PARAM_KEY}`

export const PRINTER_TYPE_KEY = 'printerType';
export const PRINTER_TYPE_PARAM = `:${PRINTER_TYPE_KEY}`

export const PRINTER_ID_KEY = 'printerId';
export const PRINTER_ID_PARAM = `:${PRINTER_ID_KEY}`

export const PRINTJOBS_KEY = "printjobs";

export const LOCATION_PRINTERS_ROUTE_ROOT = `${LOCATION_ROUTE}/${MylemonadeScopes.PRINTERS}`;
export const LOCATION_PRINTERS_JOBS_ROUTE_ROOT = `${LOCATION_PRINTERS_ROUTE_ROOT}/${PRINTJOBS_KEY}`;
export const PRINTERS_ROUTE_ROOT = `${ROUTES_PREFIX_V1}/printers`;
export const PRINTERS_TYPE_ROUTE_ROOT = `${PRINTERS_ROUTE_ROOT}/${PRINTER_TYPE_PARAM}`;
export const PRINTER_BY_ID_ROUTE_ROOT_NO_TYPE = `${PRINTERS_ROUTE_ROOT}/${PRINTER_ID_PARAM}`;
export const PRINTER_BY_ID_ROUTE_ROOT = `${PRINTERS_TYPE_ROUTE_ROOT}/${PRINTER_ID_PARAM}`;


const printersApiRoutes = {
    GET_PRINTERS: PRINTERS_ROUTE_ROOT,// deprecated, printer type removed
    GET_PRINTER: PRINTER_BY_ID_ROUTE_ROOT,// deprecated, printer type removed
    GET_PRINTER_NO_TYPE: PRINTER_BY_ID_ROUTE_ROOT_NO_TYPE,
    GET_UPDATE_JOBS: `${PRINTERS_TYPE_ROUTE_ROOT}/jobs`,
    SAVE_RECEIPT: `${PRINTER_BY_ID_ROUTE_ROOT}/receipts`,
    SAVE_RECEIPT_NO_TYPE: `${PRINTER_BY_ID_ROUTE_ROOT_NO_TYPE}/receipts`,
    CREATE_PRINT_JOB: `${PRINTER_BY_ID_ROUTE_ROOT}/${PRINTJOBS_KEY}`,
    LOCATION_PRINTERS_JOBS_ROUTE_ROOT: LOCATION_PRINTERS_JOBS_ROUTE_ROOT,
    LOCATION_CREATE_PRINTER_ROUTE: LOCATION_PRINTERS_ROUTE_ROOT,
    LOCATION_UPDATE_PRINTER_ROUTE: `${LOCATION_PRINTERS_ROUTE_ROOT}/${PRINTER_ID_PARAM}`,
    LOCATION_DELETE_PRINTER_ROUTE: `${LOCATION_PRINTERS_ROUTE_ROOT}/${PRINTER_ID_PARAM}`,
};

export const getCreateLocationPrinterRoute = (accountId: string, locationId: string): string => {
    return printersApiRoutes.LOCATION_CREATE_PRINTER_ROUTE.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId);
}

export const getUpdateLocationPrinterRoute = (accountId: string, locationId: string, printerId: string): string => {
    return printersApiRoutes.LOCATION_UPDATE_PRINTER_ROUTE.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(PRINTER_ID_PARAM, printerId);
}

export const getDeleteLocationPrinterRoute = (accountId: string, locationId: string, printerId: string): string => {
    return printersApiRoutes.LOCATION_DELETE_PRINTER_ROUTE.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId).replace(PRINTER_ID_PARAM, printerId);
}

export default printersApiRoutes;
