import AuthenticationAdvantagesFormService from "../../authentication/services/AuthenticationAdvantagesFormService";
import AuthenticationErrorFormService from "../../authentication/services/AuthenticationErrorFormService";
import ForgetPasswordFormService from "../../authentication/services/ForgetPasswordFormService";
import SignupPasswordFormService from "../../authentication/services/SignupPasswordFormService";
import VerifyEmailFormService from "../../authentication/services/VerifyEmailFormService";
import log from "../../Common/services/LogService";
import OrderLoadedPayOrUseLoyaltyFormService from "../../orders/services/OrderLoadedPayOrUseLoyaltyFormService";
import SelectTableFormService from "../../tables/services/SelectTableFormService";
import { CustomerInformationFormParameters } from "../models/CustomerInformationFormParameters";
import CustomerInformationModalFormOpenArgs from "../models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "./BaseFormModalService";
import ServiceTypeOrTakeoutFormService from "./ServiceTypeOrTakeoutFormService";

class CustomerInformationModalService {

    /**
     * The registered forms
     */
    forms: Array<BaseFormModalService>;

    constructor() {
        this.forms = [];

        // WARNING: order is important to check which form should be displayed
        this.forms.push(new AuthenticationErrorFormService());
        this.forms.push(new SelectTableFormService());
        this.forms.push(new AuthenticationAdvantagesFormService());
        this.forms.push(new VerifyEmailFormService());
        this.forms.push(new ServiceTypeOrTakeoutFormService());
        this.forms.push(new OrderLoadedPayOrUseLoyaltyFormService());
        this.forms.push(new ForgetPasswordFormService());
        this.forms.push(new SignupPasswordFormService());
    }

    shouldDisplayLogo(formToDisplay: CustomerInformationModalFormToDisplay) {
        const form = this.forms.find(form => form.form === formToDisplay);
        if (form) {
            return form.shouldDisplayLogo();
        }
        return false;
    }

    getFormToOpen(args: CustomerInformationModalFormOpenArgs): { form: CustomerInformationModalFormToDisplay, parameters: CustomerInformationFormParameters } | null {

        // Take the first form that should be opened
        for (const form of this.forms) {
            const shouldOpenForm = form.shouldOpenForm(args, false);
            if (shouldOpenForm) {
                return shouldOpenForm;
            }
        }

        return null;
    }

    shouldOpenAnotherFormAfter(currentForm: CustomerInformationModalFormToDisplay, args: CustomerInformationModalFormOpenArgs): { form: CustomerInformationModalFormToDisplay, parameters: CustomerInformationFormParameters } | null {

        log.debug(`Checking if another form should be opened after ${currentForm}`)
        for (const form of this.forms) {
            const shouldOpenForm = form.shouldOpenForm(args, false);
            if (shouldOpenForm && shouldOpenForm.form !== currentForm) {
                return shouldOpenForm;
            }
        }

        args.message = `Method shouldOpenForm not implemented`
        return null;
    }

    shouldKeepFormOpened(formToDisplay: CustomerInformationModalFormToDisplay, args: CustomerInformationModalFormOpenArgs): boolean {
        log.debug(`Checking if form ${formToDisplay} should be kept opened`, { args })
        const displayedForm = this.forms?.find((formModaleService) => formModaleService.form === formToDisplay);
        if (displayedForm) {
            return Boolean(displayedForm.shouldOpenForm(args, true));
        }

        // If not registered, by default keep it opened
        return true;
    }
}
const customerInformationModalService = new CustomerInformationModalService();
export default customerInformationModalService;