/**
 * An order display item is used to display order item or order deal information
 * The information is less structured and used to ease the display
 */
export default interface OrderDisplayItem {
    name: string;

    /**
     * Used to display all the options for instance
     */
    additional_info?: string;

    price?: string;

    quantity?: number;

    type: OrderDisplayItemType;

    /**
     * Use for all order items of a deal for instance
     */
    subItems?: OrderDisplayItem[];
}

export enum OrderDisplayItemType {

    ORDER_ITEM = "order_item",
    ORDER_ITEM_IN_DEAL = "order_item_in_deal",
    ORDER_OPTION = "order_option",
    ORDER_ITEM_NOTES = "order_item_notes",

    DEAL_CATEGORY = "deal_category",
    /**
     * Use to split items which are not part of a deal
     */
    NO_DEAL_CATEGORY = "no_deal_category",

    DISCOUNT = "discount",
    CHARGE = "charge",
    TIP = "tip",

    CUSTOMER_NOTES = "customer_notes",
    TOTAL = "total",
}