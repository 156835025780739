import { Box, Button, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useAcceptedTerms } from "../../authentication/hooks/useAcceptedTerms";
import { isClosedByClickingOutside } from "../../Common/helper/ModalHelper";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { isUserMasterOfTheOrder } from "../helpers/OrderHelpers";
import orderActions from "../redux/OrderActions";
import { finalizeOrderInfo } from "../services/OrderService";

interface SharedOrderFinalizeProps {
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}

const SharedOrderFinalize: React.FC<SharedOrderFinalizeProps> = (props) => {

    const { setOnModalCloseAction } = props;

    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { selectedCatalog, selectedLocation, tableLinkId, selectedTable, fromApp } = useTypedSelector((state: RootState) => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const user = data.user_authentication_state.user;
    const { use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);
    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);

    const hasAcceptedTerms = useAcceptedTerms();

    const onClose = useCallback((reason?: string) => {

        // If the user clicks outside
        if (isClosedByClickingOutside(reason)) {

            // If it's the master user, we consider it as a "cancel" action
            if (isUserMasterOfTheOrder(order.master_user_uid, user?.uid)) {
                dispatch(orderActions.setMasterUser(null));
            }
            // We don't want the modal to be closed for the others users
            else {
                return;
            }
        }

        dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE));

    }, [dispatch, order.master_user_uid, user?.uid]);

    useEffect(() => {
        setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE, onClose: onClose })
    }, [onClose])

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            data-test="shared-order-finalize.root"
        >

            <Typography
                variant="h2"
                color="textSecondary"
                style={{
                    flexGrow: 1,
                    textAlign: "center",
                }}
            >
                {intl.formatMessage({ id: "order.dialogValidatingOrder.title" })}
            </Typography>

            <Typography variant="body1">
                {
                    intl.formatMessage({
                        id: isUserMasterOfTheOrder(order.master_user_uid, user?.uid) ?
                            "order.dialogValidatingOrder.description_master"
                            :
                            "order.dialogValidatingOrder.description_not_master"
                    })
                }
            </Typography>

            <Box
                display="flex"
                justifyContent="center"
                bgcolor="transparent"
            >

                {
                    // Yes button
                    isUserMasterOfTheOrder(order.master_user_uid, user?.uid) &&

                    <Button
                        variant="outlined"
                        color="primary"
                        data-test="shared-order-finalize.yes_button"
                        onClick={() => {

                            dispatch(orderActions.setStatusWaitingSubmission(true));

                            if (selectedLocation && selectedCatalog) {
                                finalizeOrderInfo(
                                    selectedLocation,
                                    order,
                                    user?.uid ?? "",
                                    tableLinkId,
                                    selectedTable.restrictions,
                                    history,
                                    dispatch,
                                    selectedCatalog,
                                    fromApp,
                                    isTherePaymentData,
                                    hasAcceptedTerms,
                                    undefined,
                                    localLoyaltyUsePoints,
                                );
                            }

                            onClose();
                        }}
                        style={{
                            padding: theme.spacing(1, 3),
                            textTransform: "none",
                            marginRight: theme.spacing(1.5),
                        }}
                    >
                        <Typography>
                            {intl.formatMessage({ id: "Yes" })}
                        </Typography>

                    </Button>
                }

                {
                    // Cancel button
                }
                <Button
                    variant={isUserMasterOfTheOrder(order.master_user_uid, user?.uid) ? "contained" : "outlined"}
                    color="primary"
                    data-test="shared-order-finalize.cancel_button"
                    onClick={() => {
                        dispatch(orderActions.setMasterUser(null));
                        onClose();
                    }}
                    style={{
                        padding: theme.spacing(1, 3),
                        textTransform: "none",
                    }}
                >
                    <Typography>
                        {intl.formatMessage({ id: "cancel" })}
                    </Typography>

                </Button>
            </Box>
        </Box>
    );
}

export default SharedOrderFinalize;