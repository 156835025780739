import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from './AppProvider';
import { EnvNames } from './my-lemonade-library/src/common/configs/EnvNames';

/**
 * Condition temporary, 
 * use to clear error from material ui findDOMNode by clearing strictMode in prod
 * Waiting for version 5 => should be clean this error
 */
(process.env.REACT_APP_ENV !== EnvNames.PROD && process.env.REACT_APP_ENV !== EnvNames.PREPROD) ? (
    ReactDOM.render(
        <AppProvider><App /></AppProvider>,
        document.getElementById('root')
    )
) : (
        ReactDOM.render(
            <React.StrictMode>
                <AppProvider><App /></AppProvider>
            </React.StrictMode>,
            document.getElementById('root')
        )
    )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
