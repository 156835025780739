import { CatalogDisplayConfiguration } from "../../../model/Catalog";
import { LocationPaletteOptions } from "./LocationPaletteOptions";
import { LocationShapeOptions } from "./LocationShapeOptions";
import { LocationTypographyOptions } from "./LocationTypographyOptions";

export enum ImageMode {
    DEFAULT = "DEFAULT",
    LOGO = "LOGO",
    CUSTOM = "CUSTOM",
    NONE = "NONE"
}

export interface LocationTheme {
    images?: WebappImages
    messages?: CustomMessages;
    mui_theme_options?: LocationThemeMUIOptions;
    mui_extended_theme_options?: LocationThemeMUIExtendedOptions;
    extended_theme_options?: LocationThemeExtendedOptions;
}

export interface LocationThemeMUIOptions {

    palette?: LocationPaletteOptions;
    typography?: LocationTypographyOptions;
    shape?: LocationShapeOptions;
}

export interface LocationThemeMUIExtendedOptions {
    button_radius?: number;
}

/**
 * These settings apply to the webapp. They influence the display.
 * NOTE: the interface extends CatalogDisplayConfiguration because we want to
 * migrate progressively the settings from catalog to location.
 * The webapp will use the location settings with a higher priority than the catalog ones.
 */
export interface LocationThemeExtendedOptions extends CatalogDisplayConfiguration {

    hide_flag_button?: boolean;
    hide_account_button?: boolean;
    display_back_to_home_button?: boolean;
    display_location_name?: boolean;
    hide_welcome_title?: boolean;
}
/**
 * interface define what images use at different state in the webapp. each images have a mode and eventually an URL
 */
export interface WebappImages {
    order_confirmation_image_mode: ImageMode
    order_confirmation_image?: string
}

/**
 * Interface hold all custom messages displayed in webapp
 * if not fill, default message will be display
 */

export interface CustomMessages {
    // message in last modal when order is send
    order_confirmation_message?: string
}