import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { loadAndSelectAvailableTimeSlots } from '../../Common/helper/Timeslots';
import { getTimezoneName } from '../../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

// Search automatically within 1 week, no more
const MAX_DAY_FOR_ASAP = 7;

interface ExpectedTimeAsapProps {
    deliveryZoneRef?: string;
    onChange: (asap: boolean, value?: Date) => void
    checked: boolean | undefined
    dateTimeSelected: Date | undefined
    serviceType: SupportedServiceType
}

const ExpectedTimeAsap: React.FC<ExpectedTimeAsapProps> = (props) => {

    const {
        onChange,
        checked,
        dateTimeSelected,
        serviceType,
        deliveryZoneRef
    } = props

    const { currentLang } = useSelector((state: RootState) => state.lang)
    const { selectedTable, selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    const theme = useTheme()
    const intl = useIntl()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [firstDateAvailable, setFirstDateAvailable] = useState<DateTime | undefined>(dateTimeSelected ? DateTime.fromJSDate(dateTimeSelected) : undefined)

    /**
     * Get the first timeslot available and sets it
     */
    const getFirstAvailable = async () => {
        if (selectedLocation && selectedCatalog) {
            setIsLoading(true)

            const nowDate = DateTime.now();
            const maxDays =
                selectedLocation.orders?.default_timeslots_display_time_limit ||
                MAX_DAY_FOR_ASAP;

            for (let iDay = 0; iDay < maxDays; iDay++) {
                let forDate = nowDate;
                if (iDay > 0) {
                    forDate = nowDate.plus({ days: iDay }).set({ hour: 1 });
                }
                try {
                    const res = await loadAndSelectAvailableTimeSlots(
                        undefined,
                        selectedLocation,
                        selectedCatalog,
                        selectedTable,
                        serviceType,
                        deliveryZoneRef,
                        forDate
                    );
                    if (res.first_time_available) {
                        const firstAvailableDateTime = DateTime.fromISO(
                            res.first_time_available
                        );
                        setFirstDateAvailable(firstAvailableDateTime);
                        break;
                    }
                } catch (e) {
                    log.error(e);
                }
            }
            setIsLoading(false)
        }
    }

    // Triggered when the component is displayed
    useEffect(() => {
        getFirstAvailable();
    }, [deliveryZoneRef])

    const isRestrictedDelivery = (): boolean => {
        return selectedTable.delivery_restrictions?.address ? true : false;
    }

    /**
     * If loading ASAP timeslots, display a circular loader. When the fetch is finished, display
     * the result (can be "no slots available")
     * @returns 
     */
    const renderTimeOrLoader = () => {

        if (isLoading) {

            return (
                <CircularProgress
                    data-test="delivery-selected_time-asap-loader"
                    size="1em"
                    style={{
                        color: theme.palette.primary.main,
                        margin: theme.spacing(0, 2, 0, 0)
                    }}
                />
            );
        }


        const dayStr = firstDateAvailable && firstDateAvailable
            .setZone(getTimezoneName(selectedCatalog))
            .setLocale(currentLang)
            .toLocaleString({ weekday: 'short', day: 'numeric', month: 'short' });

        // If not today, display the day in color
        let changeDayColor: string | undefined;
        if (firstDateAvailable && !firstDateAvailable.hasSame(DateTime.now(), "day")) {
            changeDayColor = theme.palette.primary.main;
        }

        const timeStr = firstDateAvailable && firstDateAvailable
            .setZone(getTimezoneName(selectedCatalog))
            .setLocale(currentLang)
            .toLocaleString(DateTime.TIME_24_SIMPLE);


        return (
            <Typography
                variant="caption"
                data-test="delivery-selected_time-asap-time"
                style={{ color: theme.palette.grey[400], width: "100%" }}
                component="div"
            >
                {
                    firstDateAvailable
                        ? <p style={{ margin: 0 }}>
                            <span style={{
                                color: changeDayColor,
                                fontWeight: changeDayColor ? "bold" : undefined,
                            }}>
                                {dayStr}
                            </span>
                            {", " + timeStr}
                        </p>
                        : intl.formatMessage({ id: 'customer_information_modal.dateTime.no_slot' })
                }
            </Typography>
        );
    }

    return (
        <Box
            data-test="delivery-selected_time-asap"
            display="flex"
            flexDirection={"row"}
            alignItems="flex-start"
            onClick={() => {
                if (firstDateAvailable) {
                    onChange(true, firstDateAvailable.toJSDate())
                }
            }}
        >
            <Box
                mr={2}
                display="flex"
                alignItems="center"
            >
                <AlarmOutlinedIcon style={{ color: theme.palette.grey[500] }} />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                flex={1}
            >
                <Typography>
                    {intl.formatMessage({
                        id: isRestrictedDelivery()
                            ? "customer_information_modal.dateTime.next_slot"
                            : 'customer_information_modal.dateTime.asap',
                        defaultMessage: 'As soon as possible'
                    })}
                </Typography>

                {renderTimeOrLoader()}

            </Box>

            {checked
                ? <CheckCircleRoundedIcon color='primary' />
                : <RadioButtonUncheckedIcon style={{ color: theme.palette.grey[300] }} />
            }
        </Box>
    )
}

export default ExpectedTimeAsap

