import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import ExpectedTimeAsap from "../../delivery/components/ExpectedTimeAsap";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { OrderExpectation } from "../models/OrderExpectation";
import CustomerAddressDisplay from './CustomerAddressDisplay';
import TimeSelectCard from "./TimeSelectCard";
import TimeSelectForm from "./TimeSelectForm";

interface CollectionFullFormProps {
    showTimeSelectScreen?: boolean;
    setShowTimeSelectScreen: (param: boolean) => void;
    isGetRestoTheme?: boolean;
    orderExpectation: OrderExpectation;
    onOrderExpectationChange: (orderExpectation: OrderExpectation) => void;

}

/**
 * This component will render the collection information selector form. 
 * The user can see the address and select the time
 */
const CollectionFullForm: React.FC<CollectionFullFormProps> = (props) => {

    const {
        showTimeSelectScreen,
        isGetRestoTheme = false,
        orderExpectation,
        onOrderExpectationChange
    } = props;

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);

    const [displayDateTimeSelection, setDisplayDateTimeSelection] = useState<boolean>(false)

    const theme = useTheme();
    const intl = useIntl()
    const setDateTimeAndAsap = (isAsap: boolean, date?: Date) => {
        onOrderExpectationChange({
            ...orderExpectation,
            expected_asap: isAsap,
            dateTimeSelected: date
        })
    }
    return (

        <Box
            width={1}
            height={showTimeSelectScreen ? 1 : "inherit"}
        >

            {!showTimeSelectScreen &&

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent='center'
                    width={1}
                >

                    {/**
                     * ADDRESS
                    */}

                    <CustomerAddressDisplay
                        isGetRestoTheme={isGetRestoTheme}
                        address={selectedLocation?.address}
                        postalCode={selectedLocation?.postal_code}
                        city={selectedLocation?.city}
                        name={selectedLocation?.name}
                    />

                    {isGetRestoTheme && <Box my={1}></Box>}

                    {
                        !showTimeSelectScreen &&

                        <Box
                            display="flex"
                            flexDirection='column'
                            width={1}
                            style={{ backgroundColor: theme.palette.background.paper }}
                            p={isGetRestoTheme ? 2 : 0}
                            border={isGetRestoTheme ? 1 : 0}
                            borderLeft={0}
                            borderRight={0}
                            borderColor='grey.300'
                        >
                            <Typography variant='h5' style={{ marginBottom: theme.spacing(2) }}>
                                {intl.formatMessage({
                                    id: 'customer_information_modal.dateTime.schedule',
                                    defaultMessage: 'Schedule'
                                })}
                            </Typography>



                            <ExpectedTimeAsap
                                onChange={setDateTimeAndAsap}
                                checked={orderExpectation.expected_asap}
                                dateTimeSelected={orderExpectation.dateTimeSelected}
                                serviceType={SupportedServiceType.COLLECTION}
                            />


                            <TimeSelectCard
                                onClick={() => {
                                    setDateTimeAndAsap(false)
                                    setDisplayDateTimeSelection(true);
                                }}
                                checked={orderExpectation.expected_asap === false}
                            />
                        </Box>
                    }

                </Box>
            }

            {
                displayDateTimeSelection
                && !orderExpectation.expected_asap
                && setDateTimeAndAsap
                &&

                <TimeSelectForm
                    serviceType={SupportedServiceType.COLLECTION}
                    dateTimeSelected={orderExpectation.dateTimeSelected}
                    onDateTimeSelectedChange={setDateTimeAndAsap}
                    isGetRestoTheme={isGetRestoTheme}
                />

            }

        </Box>
    );
}

export default CollectionFullForm;