import { useEffect, useState } from 'react';
import lightOrDark from '../../config/lightOrDark';

export function useThemeType(color: any) {

    const [themeType, setThemeType] = useState<"light" | "dark">("light")

    useEffect(() => {
        setThemeType(lightOrDark(color))
    }, [color])

    return themeType
}

