import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface DisclaimerProps {
    onYes: any,
    onNo: any,
    open: boolean
}
/**
 * Modal use in case of alcohol item in order to check if user is above 18 yo
 * Not Really Implemented
 */
function Disclaimer({ onYes, onNo, open }: DisclaimerProps) {

    const intl = useIntl();

    return (

        <Dialog
            style={{
                width: "100%",
                position: 'relative',
                bottom: 0,
                margin: 0,
                padding: 0
            }}
            open={open}
            onClose={onNo}
        >

            <DialogTitle>

                <Typography>
                    {intl.formatMessage({ id: "Summary.disclaimer" })}
                </Typography>

            </DialogTitle>

            <DialogContent>

                <DialogContentText>

                    <Typography>
                        {intl.formatMessage({ id: "Summary.disclaimerQuestion" })}
                    </Typography>

                </DialogContentText>

            </DialogContent>

            <DialogActions>

                <Button onClick={onNo} color="primary">

                    <Typography>
                        {intl.formatMessage({ id: "No" })}
                    </Typography>

                </Button>

                <Button onClick={onYes} color="primary" autoFocus>

                    <Typography>
                        {intl.formatMessage({ id: "Yes" })}
                    </Typography>

                </Button>

            </DialogActions>

        </Dialog >
    );
}

export default Disclaimer