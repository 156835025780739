import { ThemeList } from "../../my-lemonade-library/src/theme/models/ThemesList";

export const CHANGE_THEME = 'CHANGE_THEME'

export interface ChangeThemeAction {
    type: typeof CHANGE_THEME
    payload: {
        themeName: ThemeList
    }

}

export type ThemeActionsType = ChangeThemeAction

export const themeActions = {
    changeTheme: (themeName: ThemeList): ChangeThemeAction => {
        return {
            type: CHANGE_THEME,
            payload: {
                themeName
            }
        }
    },

}

export default themeActions