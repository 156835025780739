import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import { PaymentType } from "../../my-lemonade-library/model/Order";
import EDENRED from '../../Payment/assets/EDENRED.svg';
import SWILE from '../../Payment/assets/SWILE.svg';
import paymentsActions from "../../Payment/redux/PaymentsActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

// TODO: get only the needed variables for the selected payment type
interface RestaurantTicketButtonProps {

    paymentType: PaymentType;

    isEdenredEnabled: boolean | undefined;
    edenredIsLogged: boolean | null | undefined;
    setWantedToLogoutEdenred: (value: boolean) => void;

    isSwileEnabled: boolean | undefined;
    swileIsLogged: boolean | null | undefined;
}

const RestaurantTicketButton: React.FC<RestaurantTicketButtonProps> = (props) => {

    const { paymentType: type, isEdenredEnabled, edenredIsLogged, setWantedToLogoutEdenred,
        isSwileEnabled, swileIsLogged } = props;

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const userId = data.user_authentication_state.user?.uid;

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    if (!selectedLocation) {
        return null;
    }

    let isTypeEnabled = undefined;
    let isLoggedForType = undefined;
    switch (type) {
        case PaymentType.EDENRED:
            isTypeEnabled = isEdenredEnabled;
            isLoggedForType = edenredIsLogged;
            break;
        case PaymentType.SWILE:
            isTypeEnabled = isSwileEnabled;
            isLoggedForType = swileIsLogged;
            break;
    }

    if (_.isNil(isTypeEnabled) || !isUserLoggedIn(data.user_authentication_state)) {
        return null;
    }

    const noMargin = Boolean(type === PaymentType.SWILE && isEdenredEnabled);
    let logo: string | undefined;
    switch (type) {
        case PaymentType.EDENRED:
            logo = EDENRED;
            break;
        case PaymentType.SWILE:
            logo = SWILE;
            break;
    }

    // Payment type with capital first letter
    const formattedType = type.charAt(0).toUpperCase() + type.slice(1);

    const renderButtonOrDivContent = (): JSX.Element => {
        return (
            <>
                <img
                    alt={type}
                    height="20"
                    src={logo}
                    style={{ marginRight: theme.spacing(1) }}
                />

                <Typography>
                    {intl.formatMessage({ id: `auth.my_account.restaurant_ticket.${type === PaymentType.EDENRED ? "remove" : "connected"}` })}
                </Typography>
            </>
        );
    }

    const renderButtonOrDiv = (): JSX.Element => {

        if (type === PaymentType.EDENRED) {
            return (
                <Button
                    color="secondary"
                    style={{ marginTop: theme.spacing(1), textTransform: "none" }}
                    variant="outlined"
                    onClick={() => {
                        setWantedToLogoutEdenred(true);
                        userId && dispatch(paymentsActions.edenredLogout(selectedLocation.account_id, selectedLocation.id, userId));
                    }}
                    data-test={`my-information.${type}.logout`}
                >
                    {renderButtonOrDivContent()}
                </Button>
            );
        }

        // SWILE
        return (
            <Box
                display="flex"
                alignItems="center"
                gap={1}
                padding={1}
            >
                {renderButtonOrDivContent()}
            </Box>
        );
    }

    return (
        <Box
            mt={noMargin ? 0 : 3}
            padding={3}
            paddingTop={noMargin ? 0 : 3}
            bgcolor='background.paper'
            gap={2}
            data-test="my-information.edenred"
        >

            <Typography>
                {intl.formatMessage(
                    { id: "auth.my_account.restaurant_ticket.title" },
                    { type: formattedType }
                )}
            </Typography>

            {
                _.isNil(isLoggedForType)
                    ? <CircularProgress color="primary" size="1rem" />
                    : isLoggedForType
                        ? renderButtonOrDiv()
                        : <Typography variant="caption" data-test={`my-information.${type}.no_card`}>
                            {intl.formatMessage(
                                { id: "auth.my_account.restaurant_ticket.add" },
                                { type: formattedType }
                            )}
                        </Typography>
            }
        </Box>
    )
}

export default RestaurantTicketButton;