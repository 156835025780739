import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Icon, List, ListItem, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-scroll';
import IconTint from "../../../Common/components/IconTint";
import { cursor } from '../../../Common/StyledComponents';
import dataTestConst from '../../../config/DataTestConst';
import * as ROUTES from '../../../config/routes';
import { Categorie } from '../../../my-lemonade-library/model/Catalog';
import categoriesAndProductsTranslationsService from '../../../Products/services/categoriesAndProductsTranslationsService';
import { useTypedSelector } from '../../../redux/root-reducer';
import { DEFAULT_SIZE_ICON } from '../../configs/CategoryIconConst';
import { hasSvgIcon, shouldDisplayCategory, subCategoriesList } from '../../services/categoriesServices';

/**
 * Component render list of category on desktop version
 * Display only category with product and not disable 
 */
export default function CategoriesList() {

    const { selectedCatalog, selectedTable } = useTypedSelector(state => state.locations)
    const { order } = useTypedSelector(state => state.order)
    const history = useHistory();
    const match = useRouteMatch<ROUTES.RouteParams>();

    // state use to track wich category is selected (use to display subCate if necessary)
    const [categoryOn, setCategoryOn] = useState<string>("")

    const theme = useTheme();
    const { getCatTranslation } = categoriesAndProductsTranslationsService();

    if (selectedCatalog && selectedTable) {

        return (

            <List style={{ padding: theme.spacing(3) }} disablePadding>

                {selectedCatalog.data.categories.filter(cate => !cate.parent_ref).map((cate: Categorie, index: number) =>

                    shouldDisplayCategory(order, cate, selectedCatalog, null, selectedTable) &&

                    <Box key={index}>

                        <ListItem data-test={`${dataTestConst.CATEGORY_PREFIX}${cate.ref}`} style={{ padding: 0 }} >

                            <Link
                                key={index}
                                style={{ ...cursor, width: "100%", color: theme.palette.text.primary, borderBottom: "solid 1px" }}
                                containerId="productContainer"
                                to={cate.ref}
                                onClick={() => {
                                    setCategoryOn(categoryOn === cate.ref ? "" : cate.ref)
                                    history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
                                }}
                                onSetActive={() => {
                                    setCategoryOn(cate.ref)
                                }}
                                spy={true}
                                hashSpy={true}
                                isDynamic={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    paddingY={1}
                                >
                                    {hasSvgIcon(cate) && cate.icon ? (
                                        <Box width={DEFAULT_SIZE_ICON} height={DEFAULT_SIZE_ICON} display="flex" justifyContent="center" alignItems="center">
                                            <IconTint src={cate.icon} color={cate.ref === categoryOn ? theme.palette.primary.main : theme.palette.text.primary} maxWidth={DEFAULT_SIZE_ICON} maxHeight={DEFAULT_SIZE_ICON} />
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                    <Typography variant="subtitle1" style={{ flexGrow: 1, padding: theme.spacing(1, 1, 1, 2) }}>
                                        {getCatTranslation(cate)}
                                    </Typography>

                                    {
                                        // Check first if there is subCategory for this category then display icon oriented bottom or top if the category is selected
                                        // TODO: simplify this double if statement (make a dedicated function?)

                                        subCategoriesList(order, cate, selectedCatalog, selectedTable)
                                            && subCategoriesList(order, cate, selectedCatalog, selectedTable).length ? categoryOn === cate.ref ?
                                            (
                                                <Icon component={'span'} style={{ padding: 0, margin: 0, width: 'fit-content' }}>
                                                    <ExpandLess />
                                                </Icon>
                                            ) : (
                                                <Icon component={'span'} style={{ padding: 0, margin: 0, width: 'fit-content' }}>
                                                    <ExpandMore />
                                                </Icon>
                                            ) : ""}
                                </Box>
                            </Link>

                        </ListItem>


                        { // Check again if there is subCategory then render Collapse, materialUI component

                            subCategoriesList(order, cate, selectedCatalog, selectedTable)
                                && subCategoriesList(order, cate, selectedCatalog, selectedTable).length ?

                                (<Collapse in={categoryOn === cate.ref} unmountOnExit>

                                    <List style={{ marginTop: theme.spacing(1) }}>

                                        {
                                            subCategoriesList(order, cate, selectedCatalog, selectedTable).map((subCategory: Categorie, subCategoryIndex: number) => (

                                                <Link
                                                    key={subCategoryIndex}
                                                    style={{ ...cursor, color: theme.palette.text.primary }}
                                                    containerId="productContainer"
                                                    to={subCategory.ref}
                                                    onClick={() => {
                                                        history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
                                                    }}
                                                    spy={true}
                                                    hashSpy={true}
                                                    isDynamic={true}
                                                    smooth={true}
                                                    offset={0}
                                                    duration={500}
                                                >
                                                    <ListItem data-test={`${dataTestConst.CATEGORY_PREFIX}${subCategory.ref}`} style={{ padding: 0 }} >
                                                        <Typography variant="body2">
                                                            {subCategory.name}
                                                        </Typography>
                                                    </ListItem>
                                                </Link>
                                            ))
                                        }

                                    </List>

                                </Collapse>) : ""
                        }
                    </Box>
                )}
            </List>
        )
    }
    return null
}


