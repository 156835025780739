import { PaymentType } from "../../../model/Order";
import { ACCOUNT_PARAM } from "../../accounts/configs/AccountsApiRoutes";
import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_PARAM, LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

export const ROUTE_PAYMENTS_ROOT = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.PAYMENTS}`;

export const PAYMENT_ID_PARAM_KEY = "paymentId";
export const PAYMENT_ID_PARAM = `:${PAYMENT_ID_PARAM_KEY}`;
export const PAYMENT_TYPE_KEY = "paymentType";
export const PAYMENT_TYPE_PARAM = `:${PAYMENT_TYPE_KEY}`;

export const LYRA_MARKETPLACE_UUID_PARAM = "marketplaceUuid";

export const PAYMENT_ROUTE = `${LOCATION_ROUTE}/${MylemonadeScopes.PAYMENTS}/${PAYMENT_TYPE_PARAM}`;
const PAYMENT_CREDENTIALS = `${PAYMENT_ROUTE}/credentials`;

const PAYMENT_IS_THERE_SAVED_USER_PAYMENT_DATA = `${LOCATION_ROUTE}/${MylemonadeScopes.PAYMENTS}/isThereSavedUserPaymentData`;
export const PAYMENT_TYPES_PARAM_KEY = "payment_types";

const EDENRED_BASE_ROUTE = `${LOCATION_ROUTE}/${MylemonadeScopes.PAYMENTS}/${PaymentType.EDENRED}`;
export const EDENRED_LOGOUT = `${EDENRED_BASE_ROUTE}/logout`;

export const PAYMENT_CONNECTOR_CREDIT_WALLET = `${LOCATION_ROUTE}/${MylemonadeScopes.PAYMENTS}/credit-wallet`;

export const getPaymentConnectorCreditWalletRoute = (accountId: string, locationId: string) => {
    return PAYMENT_CONNECTOR_CREDIT_WALLET.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId);
}

// TODO: to be renamed
export const LOCATIONS_FEES_ROUTE = `${ROUTES_PREFIX_V1}/payment-location-fees`;

const paymentsApiRoutes = {
    CREDENTIALS: PAYMENT_CREDENTIALS,
    EDENRED_LOGOUT,
    LOCATIONS_FEES_ROUTE,
    PAYMENT_IS_THERE_SAVED_USER_PAYMENT_DATA,
    PAYMENT_CONNECTOR_CREDIT_WALLET
};
export default paymentsApiRoutes;