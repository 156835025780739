import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Dialog, IconButton, Link, Typography } from "@mui/material";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ReactMarkdown from 'react-markdown';
import { DEFAULT_COUNTRY } from "../../my-lemonade-library/model/Location";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

interface TermsAndConditionsTextWithModalProps {
    customIntlId?: string;
}

const TermsAndConditionsTextWithModal: React.FC<TermsAndConditionsTextWithModalProps> = (props) => {

    const { customIntlId } = props;

    const { terms } = useTypedSelector((state: RootState) => state.app);
    const { currentLang } = useTypedSelector((state: RootState) => state.lang);

    const intl = useIntl();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [termsMarkdown, setTermsMarkdown] = React.useState<string>("");

    // TODO: duplicated code in BO. Put logic in library?
    useEffect(() => {

        const fetchContent = async (fetchUrl: string) => {
            try {

                const response = await fetch(fetchUrl);
                const text = await response.text();
                if (text) {
                    setTermsMarkdown(text);
                }
                else {
                    throw new Error("No content found");
                }
            }
            catch (error) {
                log.error("Cannot fetch the terms: closing the modal, will ask again at next login", error);
            }
        }

        if (!termsMarkdown && openModal) {

            if (!terms) {
                log.error("Terms not found");
                return;
            }

            let termsUrl: string | undefined = terms.localized_content_url?.[currentLang.toLowerCase()];  // Interrogation point on mandatory field in case of legacy model

            if (!termsUrl) {
                termsUrl = terms.localized_content_url?.[DEFAULT_COUNTRY.toLowerCase()];  // First fallback: default language
            }
            if (!termsUrl) {
                termsUrl = terms.content_url;  // Second fallback: old model
            }

            if (!termsUrl) {
                log.error("Terms url not found");
                return;
            }

            fetchContent(termsUrl);
        }
    });

    return (
        <>
            {openModal &&
                <Dialog
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    fullScreen
                    sx={{
                        zIndex: 10000,  // Sometimes called within a modal, has to be above
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        width={1}
                        minHeight={1}
                    >

                        <Box
                            display="flex"
                            width={1}
                            justifyContent="flex-end"
                        >
                            <IconButton
                                onClick={() => setOpenModal(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box
                            paddingX={2}
                        >
                            {termsMarkdown
                                ? <ReactMarkdown
                                    children={termsMarkdown}
                                    components={{
                                        h1: ({ node, ...props }) => <Typography variant="h4" {...props} />,
                                        h2: ({ node, ...props }) => <Typography variant="subtitle1" {...props} />,
                                        body: ({ node, ...props }) => <Typography {...props} />,
                                        img: ({ node, ...props }) => <img style={{ maxWidth: "100%" }} {...props} />,
                                    }}
                                />
                                : <Box
                                    display="flex"
                                    justifyContent="center"
                                    width={1}
                                >
                                    <CircularProgress />
                                </Box>
                            }
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    marginY: 2,
                                }}
                                onClick={() => setOpenModal(false)}
                            >
                                {intl.formatMessage({ id: "common.close" })}
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            }

            <Typography color="textSecondary">

                {intl.formatMessage(
                    { id: customIntlId ?? "orders.servicePickupInfo.accept_terms_and_privacy_policy" },
                    {
                        termsAndConditionsLink: (
                            <Link key={1} onClick={() => setOpenModal(true)} >
                                {intl.formatMessage({ id: "orders.servicePickupInfo.terms_and_conditions_privacy_policy" })}
                            </Link>
                        )
                    }
                )}
            </Typography>
        </>
    );
}

export default TermsAndConditionsTextWithModal;
