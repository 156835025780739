import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { DeviceContext } from '../../App';
import * as ROUTES from '../../config/routes';
import { RouteParams } from '../../config/routes';
import { defaultMyLemonadeButtonBoxShadow } from '../../config/theme';
import { DEFAULT_CURRENCY, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { getFabOrderTitleId, getFabPrice } from '../../orders/helpers/FabOrderHelpers';
import orderActions from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import ShareOrderButton from './ShareOrderButton';

// Those values will change the buttons so that they fit for small screens
const S_BREAKPOINT: number = 390;

/**
 * Function in charge to display orderbutton
 * Props.title is require
 * Other props are necessary for Menu and take the priority if they are specify
 * Otherwise, Order state define variables
 */
// TODO: Fix fontFamily

interface FabButtonOrderProps {
    overrideTitleId?: string,
    overridePrice?: string | undefined,
    overrideOnClick?: () => void,
    disabled?: boolean,
    hideQuantity?: boolean
    disableShareButton?: boolean
}

// INFO: FAB = First Access Button
const FabButtonOrder: React.FC<FabButtonOrderProps> = (props) => {

    const {
        overrideTitleId,
        overridePrice,
        overrideOnClick,
        disabled,
        disableShareButton
    } = props

    const theme = useTheme();

    const { mobile_device } = useContext(DeviceContext)

    const isSRender = useMediaQuery(theme.breakpoints.up(S_BREAKPOINT));

    const history = useHistory();
    const match = useRouteMatch<RouteParams>();
    const intl = useIntl();
    const dispatch = useDispatch();

    const { order } = useTypedSelector((state: RootState) => state.order);
    const { selectedLocation, selectedCatalog } = useTypedSelector((state: RootState) => state.locations);

    const onValidation = () => {
        dispatch(orderActions.openModal())
        history.push(generatePath(ROUTES.LocationHome + ROUTES.OrderSummaryPage, { tableLinkKey: match.params.tableLinkKey }))
    }

    // Is the share button displayed
    const shareButton: boolean = selectedLocation?.orders?.allow_share_order
        ? selectedLocation?.orders?.allow_share_order
        : false;

    const getPriceToDisplay = () => {

        let currency = selectedCatalog?.currency ?? selectedLocation?.currency ?? DEFAULT_CURRENCY;
        let finalPrice = getFabPrice(selectedLocation, order, currency)

        if (overridePrice) {
            finalPrice = overridePrice
        }

        return (
            <Box
                m={theme.spacing(0, 1, 0, 1)}
                style={{ whiteSpace: "nowrap" }}
            >

                <Typography style={{ marginRight: theme.spacing(1) }}>
                    {MoneyToStringWithSymbol(finalPrice)}
                </Typography>
            </Box>
        );
    }

    if (!selectedLocation) {
        return null;
    }

    // Do not the display the button if this condition is true
    if (
        !order
        || order.items.length === 0
        || (
            !paymentHelper.canPay(selectedLocation, order)
            && !orderService.hasItemToBeAdded(order)
        )
    ) {
        return null;
    }

    return (
        <Box
            display="flex"
            position={mobile_device ? 'fixed' : 'sticky'}
            bottom={2}
            left={2}
            right={2}
            p={2}
            style={{ gap: theme.spacing(1) }}
            justifyContent={order.items.length > 0 ? "flex-start" : "flex-end"}
            mx={
                // If we're above S_BREAKPOINT or if the share button is not displayed,
                // set a margin. Otherwise, remove it
                isSRender || !shareButton || disableShareButton ? 1 : 0
            }
        >

            {(overrideOnClick || order.items.length > 0) &&

                <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    data-test="order_orderButton"
                    disableElevation
                    onClick={() => {
                        if (overrideOnClick) {
                            overrideOnClick()
                        } else {
                            onValidation()
                        }
                    }}
                    style={{
                        textTransform: "none",
                        padding: theme.spacing(1.5, 2),
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                        boxShadow: defaultMyLemonadeButtonBoxShadow,
                    }}
                >
                    <Typography variant='h5' style={{ marginLeft: theme.spacing(1) }}>
                        {intl.formatMessage({
                            id: overrideTitleId ?? getFabOrderTitleId(selectedLocation, order)
                        })}
                    </Typography>
                    {
                        getPriceToDisplay()
                    }
                </Button>


            }

            {!disableShareButton &&
                <ShareOrderButton />
            }

        </Box>
    )
}

export default FabButtonOrder

