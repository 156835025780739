import { Helmet } from 'react-helmet';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

const HelmetComponent = () => {

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { deployment } = useTypedSelector((state: RootState) => state.app);


    const getFavicon = (): string => {
        if (selectedLocation) {
            if (selectedLocation.icon) {
                return selectedLocation.icon;
            } else if (selectedLocation.logo) {
                return selectedLocation.logo;
            }
        }
        return `${process.env.PUBLIC_URL}/assets/icon/favicon.png`
    }

    const getTouchIcon = (): string => {
        if (selectedLocation) {
            if (selectedLocation.logo) {
                return selectedLocation.logo;
            }
        }
        return `${process.env.PUBLIC_URL}/assets/icon/logo192.png`
    }

    const getTitle = (): string => {
        if (selectedLocation && selectedLocation.name) {
            return selectedLocation.name;
        }
        return translationService.getAppTitleTranslation(deployment?.name)
    }
    return (
        <Helmet>
            <meta
                name="description"
                content={translationService.getAppDescriptionTranslation(deployment?.name)}
            />
            <title>{getTitle()}</title>
            <link rel="icon" href={getFavicon()} />
            <link rel="apple-touch-icon" href={getTouchIcon()} />
        </Helmet>
    );
};

export default HelmetComponent;
