import isSvg from 'is-svg';
import React, { useEffect, useState } from 'react';
import SvgParser from './SvgParser';

interface SvgColorProps {
    svg: string;
    colorMap?: { [previousColor: string]: string };
    width?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;
    rotate?: number;
}

const SvgColor: React.FC<SvgColorProps> = (props) => {

    const {
        svg,
        colorMap,
        width,
        maxWidth,
        maxHeight,
        rotate
    } = props
    const [svgData, setSvgData] = useState<string | null>(null)

    const getSvgData = async () => {

        let data = await fetch(svg).then(res => {
            if (res.body) {
                let reader = res.body.getReader();
                let decoder = new TextDecoder('utf-8');

                return reader.read().then(result => {
                    return decoder.decode(result.value);
                })
            }
            return null;
        })

        setSvgData(data)
    }

    useEffect(() => {
        getSvgData()
    }, [])

    if (svgData != null && isSvg(svgData)) {

        let svgDataModified = SvgParser.decode(svgData)
        svgDataModified = (colorMap && Object.keys(colorMap).length) ? SvgParser.changeColors(svgDataModified, colorMap) : svgDataModified
        svgDataModified = (rotate != null && !isNaN(rotate)) ? SvgParser.changeRotate(svgDataModified, rotate) : svgDataModified
        svgDataModified = SvgParser.encode(svgDataModified)

        const style: any = (width != null) ? { width: width } : {}
        if (maxWidth) {
            style.maxWidth = maxWidth;
        }
        if (maxHeight) {
            style.maxHeight = maxHeight
        }

        return (
            React.createElement("img", { src: svgDataModified, style: style })
        )
    }
    else {
        return React.createElement("div", null)
    }
}
export default SvgColor;