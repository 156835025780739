import { RedirectUrl } from "../models/RedirectUrlModels";
import { ADD_URL_TO_NAVIGATION_HISTORY, CommonActionsType, SET_REDIRECT_URL } from "./CommonActions";

export interface CommonState {
    redirect: RedirectUrl | null;
    navigation_history_urls: string[];
}

const commonInitialState: CommonState = {
    redirect: null,
    navigation_history_urls: [],
}

export default function reducer(
    state: CommonState = commonInitialState,
    action: CommonActionsType,
): CommonState {

    switch (action.type) {

        case SET_REDIRECT_URL:
            return {
                ...state,
                redirect: action.payload.redirect,
            }

        case ADD_URL_TO_NAVIGATION_HISTORY:
            return {
                ...state,
                navigation_history_urls: [...state.navigation_history_urls, action.payload.url],
            }

        default:
            return state;
    }
}