import { DEFAULT_DEPLOYMENT_NAME } from "../../my-lemonade-library/model/Location";
import { TermsTarget } from "../../my-lemonade-library/src/authentications/models/Terms";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

export const useAcceptedTerms = () => {

    const { terms, deployment } = useTypedSelector((state: RootState) => state.app);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { user_authentication_state } = data;
    const { user } = user_authentication_state;

    const lastAcceptedDate = user?.last_accepted_terms?.[TermsTarget.CUSTOMERS]?.[deployment?.name ?? DEFAULT_DEPLOYMENT_NAME];

    return Boolean(
        lastAcceptedDate
        && terms?.application_date
        && new Date(lastAcceptedDate) >= new Date(terms.application_date)
    );
}
