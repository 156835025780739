import OrderNotification from "../notifications/models/OrderNotification";
import { Customer } from "../src/authentications/models/Customer";
import { ErrorWithCode } from "../src/common/models/HttpError";
import HypermediaLink from "../src/common/models/HypermediaLink";
import { Money } from "../src/common/models/Money";
import ConnectorStatus from "../src/connectors/models/ConnectorStatus";
import OrderDeliveryInfos from "../src/delivery/models/OrderDeliveryInfos";
import { OrderDiscount } from "../src/discounts/models/OrderDiscount";
import OrderInvoices from "../src/invoices/models/OrderInvoices";
import { LoyaltyLocationConfig } from "../src/loyalties/models/LoyaltyLocationConfig";
import { OrderLoyaltyOperations } from "../src/loyalties/models/OrderLoyaltyOperations";
import ChildOrderItemState from "../src/orders/models/ChildOrderItemState";
import ChildOrderState from "../src/orders/models/ChildOrderState";
import OrderChannels from "../src/orders/models/OrderChannels";
import OrderContributor from "../src/orders/models/OrderContributor";
import { OrderPaymentConnectorInfo } from "../src/orders/models/OrderPaymentsConnectorInfo";
import OrderUpdateLog from "../src/orders/models/OrderUpdateLog";
import OrderUpdateReason from "../src/orders/models/OrderUpdateReason";
import { PaymentFeeConfigPriceParameters } from "../src/orders/models/PaymentFeeConfigPriceParameters";
import { OrderItemToPay } from "../src/payments/models/OrderItemToPay";
import OrderItemUserPayment from "../src/payments/models/OrderItemUserPayment";
import OrderPaymentRefund from "../src/payments/models/OrderPaymentRefund";
import { PaymentAmountType } from "../src/payments/models/PaymentAmountType";
import PaymentSplitBySeller from "../src/payments/models/PaymentSplitBySeller";
import { PaymentStatus } from "../src/payments/models/PaymentStatus";
import OrderPrintedReceipt from "../src/printers/models/OrderPrintedReceipt";
import OrderQrCode from "../src/qrcodes/models/OrderQrCodes";
import { ProductReference } from "../src/referential/models/ProductRefrence";
import OrderSupport from "../src/supports/models/OrderSupport";
import { OrderTax } from "../src/taxes/models/OrderTax";
import { SupportedServiceType } from "./Location";

export const DISPLAY_ID_LENGTH = 4;

export interface Order {

    id: string

    /**
     * The id of the update in case a validated order as been updated (e.g. item added)
     * Supposed to be equal to id if the order has not been updated
     */
    update_id?: string;

    update_id_history?: string[];

    /**
     * The id of the parent order if the order has been splitted (see parent_location_id)
     */
    parent_order_id?: string;
    parent_location_id?: string;

    /**
     * the created child orders
     */
    child_orders?: { [childOrderId: string]: ChildOrderState }

    /**
     * @deprecated use collection_code instead
     * First 4 characters of the id in capital
     * // WARNING: not unique
     */
    display_id: string;

    /**
    * First 4 characters of the id in capital
    * // WARNING: not unique
    */
    collection_code: string;

    /**
     * ID or reference of the order in the remote connector system.
     */
    private_ref?: string | null;

    /**
     * ID or reference of the receipt provided by the remote connector system.
     * // WARN: for onebox receipt ref, use receipt_ref.
     */
    connector_receipt_ref?: string | null;

    /**
     * Reference of the receipt if coming from a onebox printer
     * // WARN: for connector receipt ID/ref, use connector_receipt_ref
     */
    receipt_ref?: string;

    /**
     * The onebox id / printer serial number
     */
    printer_ref?: string;

    /**
     * Total for items already sent to the POS
     * Used when resetting order (removing not yet sent items)
     * // TODO: see if something better can be done
     */
    sent_total?: Money;

    /**
     * Total for both item sent and draft items without discounts and charges
     */
    subtotal?: Money;

    /**
     * Total for both item sent and draft items including discounts and charges
     */
    total: Money;

    /**
     * 
     */
    channel?: OrderChannels;

    status: OrderStatus;

    /**
     * Explanation when the order has been cancelled
     */
    cancellation_reason?: string;

    shipping_price?: string;

    customer_notes?: string;

    /**
     * @deprecated: can have multiple payments of different types per order
     */
    payment_type?: PaymentType

    items: OrderItem[]

    deals: {
        [key: string]: OrderDeal
    }

    service_type: SupportedServiceType,

    service_type_ref?: string

    customer?: Customer,

    // payment
    payments?: OrderPayment[],

    notifications?: OrderNotification[],

    printed_receipts?: OrderPrintedReceipt[];

    queue_index?: number,

    // Dates and times are encoded in ISO 8601 format in Hubrise

    //Date at wich customer has to get his order
    expected_time?: Date,

    /**
     * boolean set to true if the order is expected as soon as possible
     * Set false every tile customers set a custom expected_time
     */
    expected_time_asap?: boolean,

    //Date at wich location will complete the order (including delivery delay)
    // Updated by delivery provider
    confirmed_time?: Date

    //Date at wich the order has to be ready for delivery 
    end_preparation_time?: Date,

    //Date at wich location will finish to prepare the order
    confirmed_preparation_time?: Date,

    qr_code?: OrderQrCode;

    // The UID of the user who is collectiong/spending loyalty
    loyalty_user_id?: string;

    // The UID of the user who CREATED the order
    user_id?: string;

    /**
     * All the users who contributed to the order. The key is the user UID,
     * the value is the Customer object.
     */
    contributors?: { [key: string]: OrderContributor }

    /**
     * List of contributor ids, allows to easily search all orders of a contributor
     */
    contributor_ids?: string[];

    links?: { [key: string]: HypermediaLink };

    discounts?: OrderDiscount[],

    charges?: OrderCharge[],

    taxes?: OrderTax[];

    delivery_zone_ref?: string;

    delivery_provider_ref?: string;

    delivery?: OrderDeliveryInfos;

    /**
     * Order support: link to chat, etc.
     */
    support?: OrderSupport;

    draft_created_at?: any,

    /**
     * This user is the "master" of the order. It only makes sens in a shared order.
     * He is the one who confirms the order and makes the (first) payment. While he makes
     * it, the other users will be redirected to a landing page
     */
    master_user_uid?: string;

    loyalty_operations?: OrderLoyaltyOperations[];

    loyalty_config?: LoyaltyLocationConfig;

    custom_fields?: { [key: string]: any };

    /**
     * Track information about the payments regarding the POS.
     * Useful with POS which work with callbacks, for example Lightspeed.
     * Key is orderPayment.ref, value contains the status, date of reception, ...
     */
    payments_connector_info?: { [key: string]: OrderPaymentConnectorInfo };

    invoices?: OrderInvoices;

    /**
     * Order language
     */
    language?: string;

    /**
     * Timezone name for the catalog associated to this order
     */
    timezone_name?: string;

    /**
     * Default language for the catalog associated to this order
     */
    default_language?: string;

    /**
     * The name of the waiter who managed this order
     */
    waiter?: string;

    /**
     * Number of guests for this order
     */
    guests_count?: number;

    deployment_name?: string;

    connector_send_count?: number; // Keep track of the number of times the order has been sent to the connector
}

export interface OrderStatusUpdate {

    status: OrderStatus;

    updated_at: Date;

    expected_time?: Date;

    private_ref?: string;
}

/**
 * Extends order to add additional properties not in hubrise
 * // TODO: To be merged into order because other parts have been extended
 */
export interface OrderInBase extends Order {

    table_id: string;
    table_link_id?: string;
    table_ref?: string;
    table_name?: string;
    table_area_ref?: string;

    account_id: string;

    location_ref?: string;
    location_id: string;
    location_anonymous_id?: string;
    location_name?: string;

    catalog_id: string;

    created_at: any;
    updated_at?: Date;
    last_update_reason?: OrderUpdateReason;
    updates_history?: OrderUpdateLog[];

    error_export?: ErrorWithCode;
}

export enum PaymentType {

    TABLE = "table",

    STRIPE = "stripe",
    STRIPE_CONNECT = "stripe_connect",

    LYRA = "lyra",
    LYRA_MARKETPLACE = "lyra_marketplace",

    LYF_CB = "lyf_cb",
    LYF_WEB = "lyf_web",
    LYF_IN_APP = "lyf_in_app",

    EDENRED = "edenred",
    SWILE = "swile",

    ADYEN = "adyen",

    POS = "pos",  // Payment made on location pos
    NONE = 'none',
    OTHER_CB = "other_cb",

    // For now, only used for Orchestra if they want to use the wallet as payment method
    CONNECTOR_WALLET = "connector_wallet",

    WORLDLINE = "worldline",

    WORLDLINE_SMART_POS = "worldline_smart_pos"
}

/**
 * Payment types that are hubs and which can contain multiple payment methods inside. 
 * (e.g: we can pay with Swile in Lyf_cb)
 */
export const PAYMENT_TYPE_IS_HUB: PaymentType[] = [
    PaymentType.LYRA_MARKETPLACE,
    PaymentType.LYF_CB
]

export type MarketplacePaymentType = PaymentType.LYRA_MARKETPLACE | PaymentType.STRIPE_CONNECT;

/**
 * See https://www.hubrise.com/fr/developers/api/order-management/#order-status
 */
export enum OrderStatus {

    /**
     * WARNING: DEPRECATED, should not be used anymore
     * Rejected payment status: not existing in hubrise
     * Status when an order payment has been rejected. Therefore, the order has not been sent to the pos (only exists in mylemonade)
     */
    REJECTED_PAYMENT = "rejected_payment",

    /**
     * Pending payment status: not existing in hubrise
     * Status when an order has not been paid yet and has not been sent to the pos (only exists in mylemonade)
     */
    PENDING_PAYMENT = "pending_payment",

    /**
     * New Order placed but not received yet in the EPOS.Default status for a new order placed outside of the EPOS (eg an online order).
     */
    NEW = "new",

    /**
     * Order accepted by the store. Default status for an order created from within the POS.
     */
    ACCEPTED = "accepted",

    /**
     * Order which was previously new, but it has later been received by the POS.
     */
    RECEIVED = "received",

    /**
     * Order could not be transmitted to the store, generally because of a system error.
     */
    REJECTED = "rejected",

    /**
     * Order has been cancelled by either the customer or the store.
     */
    CANCELLED = "cancelled",

    /**
     * Order could not be delivered.
     */
    DELIVERY_FAILED = "delivery_failed",

    /**
     * Order is being prepared.
     */
    IN_PREPARATION = "in_preparation",

    /**
     * Order is ready to be shipped.
     */
    AWAITING_SHIPMENT = "awaiting_shipment",

    /**
     * Order is ready to be collected by the customer.
     */
    AWAITING_COLLECTION = "awaiting_collection",

    /**
     * Order has been sent out for delivery.
     */
    IN_DELIVERY = "in_delivery",

    /**
     * Order successfully delivered to the customer.
     */
    COMPLETED = "completed",


    /**
     * The order is a draft, meaning that it can be modified at any time by the user.
     */
    DRAFT = "draft",

    /**
     * Only happens with "shared" or "synced" orders. When the master user clicks
     * on "confirm the order" and then on "yes" (he is sure that everybody finished to add
     * their items), the order status will change to WAITING_SUBMISSION. Then all the other users 
     * will be redirected to a landing page while the master user pays.
     */
    WAITING_SUBMISSION = "waiting_submission"

}

export const order_status_options = Object.values(OrderStatus);

export interface OrderItem {

    product_ref: string;

    /**
     * Product categories from bottom to root
     */
    categories_refs?: string[];

    /**
     * TODO: to be filled in order pricing
     */
    categories_names?: string[];

    product_name: string

    /**
     * The unit price of the sku, without the cost of options.
     */
    price: Money;

    /**
     * The unit price for all options
     */
    options_price?: Money;

    /**
     * Calculated by HubRise. It is the sum of the price of the item and its options, multiplied by the quantity
     */
    subtotal?: Money;

    sku_ref: string;

    referential?: ProductReference;

    sku_name?: string; // Add to match with Hubrise model

    quantity: number

    options: OrderOption[],

    deal_line?: OrderDealLine

    tax_rate?: number;  // 0-100

    tax_price?: Money;

    tax_ref?: string;

    contributor_user_id?: string;  // The customer who added the item

    payments?: OrderItemUserPayment[];  // The customer who has paid the item (if item explicitely selected only)

    /**
     * the created child order item
     */
    child_orders_item?: ChildOrderItemState

    /**
     * When the item was added to the cart
     */
    created_at?: string;

    /**
     * This ID is useful for the opened orders, where items are added
     * after the order is sent. 
     * All the items added before sending the orders will have the orderId as their
     * update_id. Then when adding new items, they won't have an update_id until
     * the API receives the order and set them.
     */

    customer_notes?: string;

    /**
     * Ref of the order item line in the connector
     */
    ref?: string;

    /**
     * Id of the update when the line has been sent to the connector
     * Not filled for draft orders
     */
    update_id?: string;
    loyalty_operation_earning_id?: string;
    loyalty_operation_spending_id?: string;
    points_used?: number;
    points_earned?: number;

    /**
     * Is the item correctly sent to the pos
     */
    connector_status?: ConnectorStatus;

    /**
     * Production level, not implemented for now
     */
    production_level?: number;

    /**
     * Total weight for this item (take into account the quantity)
     * Used for the delivery, the weight has to be in grams
     */
    weight?: number;
}

/**
 * Helper interface when deal_line is required within the order
 */
export interface DealOrderItem extends OrderItem {
    deal_line: OrderDealLine
}

export interface OrderItemExtended extends OrderItem {
    /**
     * Index of the item in the main order.items array
     */
    index: number;

    /**
     * Payment status of the item. Helps us differentiate between paid and unpaid items in the UI
     * 
     * Check separateItems function in ManageOrderHelpers.ts for more details
     */
    payment_status?: PaymentStatus;
}

export interface OrderDealLine {

    deal_key: string

    deal_line_ref: string

    deal_line_index: number

    label?: string;

    /**
     * True if only one choice for this deal line
     */
    no_choice?: boolean;
}

export interface OrderDeal {

    ref: string,

    name: string,

    price: Money,

    price_with_options?: Money,

    contributor_user_id?: string,

    line_count?: number

}

export interface OrderOption {

    name: string;

    ref: string;

    option_list_name: string;

    option_list_ref: string;

    /**
     * Index of the option list in the product sku option list refs array
     * Usefull for connectors where order of options must be respected. E.g. Untill
     */
    option_list_index?: number;

    connector_type?: string;

    price: string;

    tax_rate?: number;  // 0-1

}

export interface OrderPayment {

    /**
     * @deprecated
     */
    type?: OrderPayementType,

    // The name of the payment method.
    name?: string,

    // Identifies the payment method.
    ref?: string,

    // Does not exist in Hubrise
    payment_type: PaymentType,

    /**
     * Amount paid with this payment method.
     * 
     * - Includes tip_charge_amount
     */
    amount: Money,

    /**
     * Amount paid with lunch card
     */
    lunch_card_amount?: Money

    /**
     * How the amount was entered by the user
     */
    amount_type?: PaymentAmountType;

    /**
     * @deprecated: use order.items.payments instead
     */
    items_to_pay?: OrderItemToPay[];

    /**
     * For items amount type, additional amount added to all items amount
     */
    manual_additional_amount?: Money;

    /**
     * Use points when the payment is confirmed to create an order discount
     */
    use_loyalty_points?: boolean;

    tip_charge_id?: string;
    tip_charge_amount?: Money;

    /**
     * The detected divider if the amount is inferior to the total
     * Automatically filled by api
     */
    share_payment_divider?: number;

    status: PaymentStatus;

    /**
     * Is the payment correctly sent to the pos
     */
    connector_status?: ConnectorStatus;

    /**
     * Reference in the connector
     */
    connector_ref?: string;

    // Id for the payment intent (created by Mylemonade)
    payment_intent_id: string;

    // Id for the user who has made the payment
    payment_user_id?: string;

    // Id for the payment transaction (usually the payment reference in the PSP)
    transaction_id?: string;

    /**
     * The status if any defined by the payment provider
     */
    provider_status?: string;

    /**
     * The detailed status if any defined by the provider
     */
    provider_detailed_status?: string;

    /**
     * Id of the refund
     * @deprecated: refunds array must be preferred
     */
    refund_id?: string;

    /**
     * User id for the user triggering the refund
     * @deprecated, refunds array must be preferred
     */
    refund_user_id?: string;

    /**
     * List of refunds for this payment
     */
    refunds?: OrderPaymentRefund[]

    // Updated at in iso format
    updated_at?: string;

    // Temporary payment data (used by Adyen)
    payment_data?: any;

    /**
     * @deprecated
     * Additional info on the payment: transaction id, etc. The content is free and typically depends on the payment method.
     */
    info?: any

    /**
     * Used to be able to redirect to the right url after payment
     * ONLY filled for specific payment provider: e.g. Edenred
     */
    success_url?: string;

    /**
     * Used to be able to redirect to the right url after payment cancelation
     * ONLY filled for specific payment provider: e.g. Edenred
     */
    cancel_url?: string;

    /**
     * Used to be able to redirect to the right url after payment failure
     * ONLY filled for specific payment provider: e.g. Edenred
     */
    error_url?: string;

    /**
     * When using marketplace such as lyra, fee config ref used to compute fee amount
     */
    fee_config_ref?: string;

    /**
     * Marketplace fee amount
     * NOTE: this amount represents what is charged from the total transaction, and not
     * what we actually earn. There might be a difference because the PSP takes a commission.
     * Example: fee config is 1%+15cts but the PSP takes 0.2%+5cts so at the end we earn 0.8%+10cts.
     * The fee amount here will be computed using the 1%+15cts rule.
     */
    fee_amount?: Money;

    /**
     * Fee config pricing parameters
     */
    fee_config_pricing_parameters?: PaymentFeeConfigPriceParameters;

    /**
     * url to be able to download invoice
     */
    invoice_url?: string;

    /**
     * The id of the update to track order changes
     */
    update_id?: string;

    /**
     * Saves the update_id when the payment is confirmed.
     * // TODO: keep a trace of all update_ids in an array (like done in Order)?
     */
    paid_update_id?: string;

    /**
     * Payment main seller id for marketplace
     */
    seller_id?: string;

    /**
     * Filled when food court config: split by each marketplace seller
     */
    split_by_seller?: PaymentSplitBySeller;
}

export enum OrderPayementType {

    CASH = "cash",

    ONLINE = "online",

    THIRD_PARTY = "third_party",

    // not present in Hubrise
    CB = "cb"

}

export interface OrderCharge {

    type: OrderChargeType,
    name: string,
    ref?: string,
    tax_ref?: string,
    tax_rate?: number,
    tax_price?: Money,
    contributor_id?: string
    price: Money,
    id?: string;
    updated_id?: string;
}

export enum OrderChargeType {

    DELIVERY = "delivery",
    SERVICE_FEE = "service_fee",
    TIP = "tip",
}

