import { useField } from "formik";
import RoundedCheckBox from "../CustomComponents/RoundedCheckBox";

export const FormikRoundedCheckBox = ({ ...props }) => {

    const [field] = useField({ ...props, name: props.name, type: 'checkbox' });

    return (
        <RoundedCheckBox
            {...field}
            {...props}
        />
    );
}