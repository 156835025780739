import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface ConfirmationActionModalProps {
    open: boolean
    onClose: () => void,
    onConfirmAction: () => void
    titleMessageId: string
    textMessageId: string
}

/**
 * *Disclaimer use to get a confirmation from user before an irreversible action
 * @param props 
 * @returns 
 */
const ConfirmationActionModal: React.FC<ConfirmationActionModalProps> = (props) => {

    const {
        open,
        onClose,
        onConfirmAction,
        titleMessageId,
        textMessageId
    } = props

    const theme = useTheme()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            data-test="confirmation-action-modal"
        >

            <DialogTitle>
                <Typography
                    variant="h4"
                    align='center'
                    style={{ fontWeight: "bold" }}
                >
                    <FormattedMessage id={titleMessageId} />
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Typography variant='body1' align='center'>
                    <FormattedMessage id={textMessageId} />
                </Typography>
            </DialogContent>

            <DialogActions style={{
                padding: theme.spacing(2, 2),
                display: "flex",
                width: "100%",
                justifyContent: "space-between"
            }}>

                <Button
                    variant='outlined'
                    onClick={onClose}
                    color='primary'
                    style={{
                        textTransform: 'capitalize',
                        flex: 1
                    }}
                    data-test="confirmation-action-modal-button-back"
                >
                    <FormattedMessage id="confirmation_action_modal.button.back" />
                </Button>


                <Button
                    onClick={onConfirmAction}
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'capitalize',
                        flex: 1
                    }}
                    data-test="confirmation-action-modal-button-confirm"
                >
                    <FormattedMessage id="confirmation_action_modal.button.confirm" />
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationActionModal;