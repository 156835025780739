import { Box, BoxProps, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import Logo from '../../assets/logo_orange_circle.svg';
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { MAX_LOGO_HEIGHT, MAX_LOGO_WIDTH } from "../configs/LocationConfig";

type LocationLogoProps = {
    titleId?: string
} & BoxProps

/**
 * Display the location logo with a title if needed
 */
const LocationLogo: React.FC<LocationLogoProps> = (props) => {

    const {
        titleId,
        ...remainingBoxProps
    } = props;

    const intl = useIntl();
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);

    return (
        <Box
            {...remainingBoxProps}
            display="flex"
            flexDirection="column"
            alignItems="center"
        >

            <Box maxWidth={MAX_LOGO_WIDTH}>
                {selectedLocation?.logo ? (
                    <img width="100%"
                        src={selectedLocation?.logo}
                        style={{
                            objectFit: 'scale-down',
                            maxWidth: MAX_LOGO_WIDTH,
                            maxHeight: MAX_LOGO_HEIGHT
                        }}
                        alt="" />
                ) : (
                    < img src={Logo} alt="" />
                )}
                {/** TODO: logo depending on deployment */}
            </Box>
            {titleId ?
                <Box textAlign="center">
                    <Typography variant="h1">
                        {intl.formatMessage({ id: titleId })}
                    </Typography>
                </Box> :
                null
            }

        </Box>

    );
}

export default LocationLogo;
