
/**
 * A transtration object used to save product translation for example
 * See TranslationsMap
 */
export default interface Translation {
    id: string;
    source: TranslationSource;
    translation: string;
}

export enum TranslationSource {
    /**
     * What has been entered/imported in the catalog
     */
    CATALOG_LANGUAGE = "catalog",
    DEEPL = "deepl",
    MANUAL = "manual"
}