import { Box, Divider, Typography, useTheme } from '@mui/material'
import log from 'loglevel'
import { DateTime } from 'luxon'
import { useIntl } from 'react-intl'
import { Tags } from '../../my-lemonade-library/model/Catalog'
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt'
import { ProductExtended, SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended'
import { moneyToNumber, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money'
import { canOrderEntityNow } from '../../my-lemonade-library/src/restrictions/services/RestrictionsService'
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService'
import { RootState, useTypedSelector } from '../../redux/root-reducer'
import tagService, { MAX_TAGS_DISPLAY } from '../../tags/services/tagService'
import productService from '../services/ProductService'

type ProductScrollCardProps = {
    product: ProductExtended | DealExt;
    height: number;
    isDeal?: boolean;
    isWithoutImage: boolean;
}

const ProductScrollCard: React.FC<ProductScrollCardProps> = (props) => {

    const { product, height, isDeal, isWithoutImage } = props;

    const { selectedLocation, selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const canOrderEntityNowResult = canOrderEntityNow(selectedLocation, selectedCatalog, product, selectedTable.service_type, DateTime.now());

    const intl = useIntl();
    const theme = useTheme();

    const getDisplayPrice = (): string => {

        if (isDeal) {
            const deal = product as DealExt
            if (!deal.starting_price) {
                return ''
            }
            if (moneyToNumber(deal.starting_price) === 0) {
                return intl.formatMessage({ id: 'free' })
            }
            if (deal.starting_price === deal.max_price) {
                return MoneyToStringWithSymbol(deal.starting_price)
            }
            return intl.formatMessage({
                id: "deal.deal_starting.price.text"
            }, {
                price: MoneyToStringWithSymbol(deal.starting_price)
            })
        }

        const sku = ((product as ProductExtended).skus[0] as SkuExtended)
        const price = sku.reduced_price ?? sku.price

        return moneyToNumber(price) > 0 ? MoneyToStringWithSymbol(price) : intl.formatMessage({ id: 'free' })
    }

    const getDealOrProductTranslationText = () => {

        let translationId: string | undefined;

        if (isDeal) {
            translationId = translationService.getDealNameTranslationKey(product as DealExt);
        }
        else {
            translationId = translationService.getProductNameTranslationKey(product as ProductExtended);
        }

        if (translationId && intl.messages[translationId]) {
            return intl.formatMessage({ id: translationId })
        }
        else {
            log.debug(`Missing translation for product or deal ${product.ref}`);
            return product.name || product.ref;
        }
    }

    if (!selectedCatalog) {
        return null
    }

    return (
        <Box
            data-test={`product_${product.ref}`}
            my={1}
            display='flex'
            flexDirection='column'
            height={height}
            sx={{
                opacity: canOrderEntityNowResult.canOrderEntityNow ? 1 : 0.4,
            }}
        >
            <Box
                display='flex'
                justifyContent='space-between'
                flexGrow={3}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent={isWithoutImage ? 'flex-start' : 'space-between'}
                    maxWidth={isWithoutImage ? '75%' : '100%'}
                >
                    <Typography
                        variant='h5'
                        color='textSecondary'
                        style={{
                            marginBottom: theme.spacing(1),
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                        }}
                    >
                        {getDealOrProductTranslationText()}
                    </Typography>

                    {product.description &&
                        <Typography
                            variant='caption'
                            style={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                color: theme.palette.text.disabled,
                            }}
                        >
                            {intl.formatMessage(
                                isDeal
                                    ? {
                                        id: translationService.getDealDescriptionTranslationKey(product as DealExt),
                                        defaultMessage: product.description
                                    }
                                    : {
                                        id: translationService.getProductDescriptionTranslationKey(product as ProductExtended),
                                        defaultMessage: product.description
                                    })}
                        </Typography>
                    }

                    {product.tags && product.tags.length > 0 && !isWithoutImage &&
                        <Box
                            display="flex"
                            alignItems="center"
                            data-test={`product_${product.tags}_tags`}

                        >
                            {product.tags.map((tag: Tags, index: number) => {
                                if (index < MAX_TAGS_DISPLAY) {
                                    return (
                                        <Box key={index} >
                                            {tagService.renderTag(tag, theme.palette.secondary.main, false)}
                                        </Box>
                                    );
                                }
                                else if (index === MAX_TAGS_DISPLAY) {

                                    return (
                                        <Typography
                                            key={index}
                                            color="secondary"
                                            variant="h3"
                                            style={{ marginLeft: theme.spacing(1) }}
                                        >
                                            ...
                                        </Typography>
                                    )
                                }
                                return null;
                            })}
                        </Box>
                    }

                    {
                        !isWithoutImage &&
                        <Box
                            display="flex"
                            alignItems="center"
                            marginTop={1}
                            gap={1}
                        >
                            <Typography
                                variant='subtitle1'
                                color="primary"
                                data-test={`product_${product.ref}_price`}
                                sx={{
                                    textDecoration: canOrderEntityNowResult.canOrderEntityNow ? "none" : 'line-through',
                                }}
                            >
                                {getDisplayPrice()}
                            </Typography>

                            {!canOrderEntityNowResult.canOrderEntityNow &&

                                <Typography
                                    color={theme.palette.text.disabled}
                                    data-test="unavailable_text"
                                >
                                    {intl.formatMessage({ id: 'product.disable' })}
                                </Typography>
                            }
                        </Box>
                    }
                </Box>
                {
                    isWithoutImage &&
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Box
                            alignSelf="flex-end"
                        >
                            <Typography
                                variant='subtitle1'
                                color="primary"
                                data-test={`product_${product.ref}_price`}
                                sx={{
                                    textDecoration: canOrderEntityNowResult.canOrderEntityNow ? "none" : 'line-through',
                                }}
                            >
                                {getDisplayPrice()}
                            </Typography>
                        </Box>
                        <Box>
                            {!canOrderEntityNowResult.canOrderEntityNow &&

                                <Typography
                                    color={theme.palette.text.disabled}
                                    data-test="unavailable_text"
                                >
                                    {intl.formatMessage({ id: 'product.disable' })}
                                </Typography>
                            }
                        </Box>
                    </Box>
                }
                {!isWithoutImage &&
                    <Box width='90px' height='90px' mr={2} alignSelf='start'>
                        <img
                            src={productService.getProdImage(product, selectedCatalog)}
                            alt={product.name}
                            style={{
                                height: '90px',
                                width: '90px',
                                objectFit: 'cover',
                                marginTop: theme.spacing(1),
                                marginLeft: theme.spacing(2),
                                borderRadius: '8px'
                            }}
                        />
                    </Box>
                }
            </Box>

            <Divider style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                width: '100%'
            }}
            />
        </Box>
    )
}

export default ProductScrollCard;