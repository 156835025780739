import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Chip, CircularProgress, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";

interface PendingPaymentChipProps { }

const PendingPaymentChip: React.FC<PendingPaymentChipProps> = (props) => {

    const intl = useIntl();

    const [showPendingPaymentDetails, setShowPendingPaymentDetails] = useState<boolean>(false);

    return (
        <Tooltip
            open={showPendingPaymentDetails}
            onClose={() => setShowPendingPaymentDetails(false)}
            title={intl.formatMessage({ id: "orders.sharePayment.item_pending_payment" })}
        >
            <Chip
                variant="outlined"
                color="primary"
                onClick={() => setShowPendingPaymentDetails(a => !a)}
                label={
                    <Box display="flex" alignItems="center" gap={1}>
                        <CreditCardIcon sx={{ fontSize: "1rem" }} />
                        <CircularProgress size=".8rem" />
                    </Box>
                }
                sx={{
                    height: "24px",
                    "& .MuiChip-label": {
                        padding: "8px",
                    }
                }}
            />
        </Tooltip>
    );
}

export default PendingPaymentChip;