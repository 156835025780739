import 'firebase/firestore';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import log from '../../Common/services/LogService';
import { rsf } from '../../config/firebase';
import TranslationsMap from '../../my-lemonade-library/src/translations/models/TranslationsMap';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import actions, { LoadTranslationsAction } from './actions';

function* loadTranslation(action: LoadTranslationsAction) {
    try {
        const catalog = action.payload.catalog;

        // TODO: unknown and any, please type it
        const catalogTranslationSnapshot: unknown = yield call(
            rsf.firestore.getCollection,
            `accounts/${catalog.account_id}/locations/${catalog.location_id}/catalogs/${catalog.id}/translations`
        );

        let catalogTranslations: { [key: string]: TranslationsMap } = {};
        (catalogTranslationSnapshot as any).forEach((trad: any) => {
            const translationsMap = trad.data() as TranslationsMap;
            catalogTranslations[trad.id] = translationsMap;
        })
        log.debug(`Found ${Object.keys(catalogTranslations).length} supported translation languages`);

        // Change the format to match with i18n format 
        const catalogTranslationsReformat = reformatTranslations(catalogTranslations)

        if (catalog.language) {
            const defaultLanguageTranslations = translationService.getDefaultMap(catalog);
            const translationsKeys = Object.keys(defaultLanguageTranslations);
            const nStringsToBeTranslated = translationsKeys.length;
            log.debug(`Found ${nStringsToBeTranslated} catalog strings to be translated`);
            catalogTranslationsReformat[catalog.language] = defaultLanguageTranslations;
            // Check that each supported language has the right number of strings
            if (catalog.supported_languages) {
                catalog.supported_languages.forEach((supportedLanguage) => {
                    if (supportedLanguage !== catalog.language) {
                        if (catalogTranslationsReformat[supportedLanguage]) {
                            const nTranslatedStrings = Object.keys(catalogTranslationsReformat[supportedLanguage]).length;
                            if (nTranslatedStrings !== nStringsToBeTranslated) {
                                log.warn(`Catalog ${catalog.id} seems to have invalid translation number (${nTranslatedStrings} vs ${nStringsToBeTranslated}) for supported language ${supportedLanguage} (accountId: ${catalog.account_id}, locationId: ${catalog.location_id}`);
                            }
                        } else {
                            log.warn(`Catalog ${catalog.id} is missing all translations for supported language ${supportedLanguage} (accountId: ${catalog.account_id}, locationId: ${catalog.location_id}`);
                        }
                    }
                })
            }
        } else {
            log.error(`Undefined catalog default language for catalog ${catalog.id} (accountId: ${catalog.account_id}, locationId: ${catalog.location_id}`);
        }

        yield put(actions.loadTranslationsSuccess(catalogTranslationsReformat, catalog.supported_languages))
    } catch (error) {
        log.error("Error while loading translations");
        log.error(error);
        yield put(actions.loadError(error))
    }
}

/**
 * Translation objects keep a track of the status / origin
 * Reformat to only keep the translation value 
 * @param translations
 */
function reformatTranslations(translations: { [key: string]: TranslationsMap }): { [key: string]: { [key: string]: string } } {
    const translationsReformatted: { [key: string]: { [key: string]: string } } = {};

    if (translations) {

        Object.keys(translations).forEach(keyLang => {
            Object.keys(translations[keyLang]).forEach((key: any) => {
                if (!translationsReformatted[keyLang]) {
                    translationsReformatted[keyLang] = {}
                }
                translationsReformatted[keyLang][key] = translations[keyLang][key].translation;
            })
        })
    }
    return translationsReformatted
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_TRANSLATIONS, loadTranslation)
    ])
}