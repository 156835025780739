import { AutoId } from "./AutoId";

/**
 * Topic used to submit file upload requests
 */
export const UPLOAD_FILE_PUB_SUB_TOPIC = "image_import";
export const STORAGE_GOOGLE_PUBLIC_HOST = "https://storage.googleapis.com";


export const getStoragePublicUrl = (gcpProjectId: string, storagePath: string, token?: string, bucket?: string): string => {
    const bucketName = `${gcpProjectId}.appspot.com`;
    return getBucketStoragePublicUrl(bucketName, storagePath, token);
}

export const getBucketStoragePublicUrl = (bucket: string, storagePath: string, token?: string, noToken?: boolean): string => {
    // Add a token to force cache refresh
    let tokenQueryParam = "";
    if (!noToken) {
        tokenQueryParam = `?token=${token ? token : AutoId.newId("")}`;
    }
    return encodeURI(`${STORAGE_GOOGLE_PUBLIC_HOST}/${bucket}/${storagePath}${tokenQueryParam}`);
}