import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";

/**
 * Key used in route or query params
 */
export const AUTHENTICATE_STATE_QUERY_PARAM_NAME = "state";
export const AUTHENTICATE_USER_REF_QUERY_PARAM_NAME = "uuid";
export const AUTHENTICATE_JWT_QUERY_PARAM_NAME = "jwt";
export const AUTHENTICATION_ACCESS_TOKEN_KEYWORD = "access_token";
export const AUTHENTICATION_AUTHORIZE_KEYWORD = "authorize";
export const AUTHENTICATION_ACKNOWLEDGE_KEYWORD = "acknowledge";
export const AUTHENTICATION_AUTHENTICATE_KEYWORD = "authenticate";
export const AUTHENTICATION_SUCCESS_KEYWORD = "success";
export const AUTHENTICATION_PROVIDER_KEY = 'authProvider';
export const AUTHENTICATION_PROVIDER_PARAM = `:${AUTHENTICATION_PROVIDER_KEY}`;
export const AUTHENTICATION_PROVIDER_BASE_ROUTE = `${ROUTES_PREFIX_V1}/authentications/${AUTHENTICATION_PROVIDER_PARAM}`;
export const AUTHENTICATION_PROVIDER_SIGNIN__WITH_EMAIL_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/signin-with-email`;
export const AUTHENTICATION_PROVIDER_CUSTOMERS_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/${MylemonadeScopes.CUSTOMERS}`;
export const AUTHENTICATION_PROVIDER_SIGNIN_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/signin`;
export const AUTHENTICATION_PROVIDER_SIGNUP_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/signup`;
export const AUTHENTICATION_PROVIDER_AUTHORIZE_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/${AUTHENTICATION_AUTHORIZE_KEYWORD}`;
export const AUTHENTICATION_PROVIDER_ACKNOWLEDGE_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/${AUTHENTICATION_ACKNOWLEDGE_KEYWORD}`;
export const AUTHENTICATION_PROVIDER_AUTHENTICATE_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/${AUTHENTICATION_AUTHENTICATE_KEYWORD}`;
export const AUTH_ISSUE_SIGNIN_LINK_ROUTE = `${ROUTES_PREFIX_V1}/auth/signin/request`;
export const AUTH_VERIFY_SIGNIN_LINK_ROUTE = `${ROUTES_PREFIX_V1}/auth/signin/verify`;
export const AUTHENTICATION_PASSWORD_ANONYMOUS_SUCCESS_ROUTE = `${AUTHENTICATION_PROVIDER_BASE_ROUTE}/${AUTHENTICATION_SUCCESS_KEYWORD}`
export const API_KEY_HEADER2 = "api-key"
export const API_KEY_HEADER = "x-api-key"

const authenticationsApiRoutes = {

    /**
     * Request the authentication provider with the email
     */
    PROVIDER_SIGNIN_WITH_EMAIL_ROUTE: AUTHENTICATION_PROVIDER_SIGNIN__WITH_EMAIL_ROUTE,

    /**
     * Go to the sign in oauth2 page
     */
    PROVIDER_SIGNIN_ROUTE: AUTHENTICATION_PROVIDER_SIGNIN_ROUTE,

    /**
     * Create a user or trigger a signup flow in the provider
     */
    PROVIDER_SIGNUP_ROUTE: AUTHENTICATION_PROVIDER_SIGNUP_ROUTE,

    /**
     * Exchange the authorization code for an access token (oauth2 code grant flow)
     */
    PROVIDER_AUTHORIZE_ROUTE: AUTHENTICATION_PROVIDER_AUTHORIZE_ROUTE,

    /**
     * Webapp acknowledgment that the custo token has been used
     */
    PROVIDER_ACKNOWLEDGE_ROUTE: AUTHENTICATION_PROVIDER_ACKNOWLEDGE_ROUTE,

    /**
     * Authenticate with the signin provider token
     */
    PROVIDER_AUTHENTICATE_ROUTE: AUTHENTICATION_PROVIDER_AUTHENTICATE_ROUTE,

    AUTHENTICATION_PASSWORD_ANONYMOUS_SUCCESS_ROUTE,

    AUTHENTICATION_PROVIDER_CUSTOMERS_ROUTE,

    AUTH_ISSUE_SIGNIN_LINK_ROUTE,

    AUTH_VERIFY_SIGNIN_LINK_ROUTE,
};
export default authenticationsApiRoutes;