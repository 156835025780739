import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import FormikTextInput from "../../Common/components/Formik/FormikTextInput";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import { DeploymentName } from "../../my-lemonade-library/src/common/models/DeploymentInfo";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { MAX_HEIGHT_CUSTOMER_FORM } from '../configs/variables';
import AuthenticationFormParameters from "../models/AuthenticationFormParameters";
import { resetPassword } from "../services/AuthenticationService";

interface ResetPasswordProps {
    parameters: AuthenticationFormParameters;
    setOnModalCloseAction: (onModalCloseParams: { formToDisplay: CustomerInformationModalFormToDisplay; onClose: () => void; } | null) => void,
}
const ResetPassword: React.FC<ResetPasswordProps> = (props) => {

    const { parameters, setOnModalCloseAction } = props;

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [errorCode, setErrorCode] = useState<string>("");
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { error: authStoreError } = useTypedSelector((state: RootState) => state.authentication)
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)
    const { deployment } = useTypedSelector((state: RootState) => state.app)

    const onForgetPasswordClose = useCallback(() => {
        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
            parameters?.previousForm ?? CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
            parameters?.previousFormParameters
        ));
    }, [dispatch, parameters]);

    useEffect(() => {
        setOnModalCloseAction({ formToDisplay: CustomerInformationModalFormToDisplay.FORGET_PASSWORD, onClose: onForgetPasswordClose })
    }, [onForgetPasswordClose])

    const getRecoveryPasswordErrorMessage = (code: string) => {
        switch (code) {
            case "auth/email-not-found":
                return "authentication.forget_password.unknow_email";
            case "auth/invalid-email":
                return "authentication.forget_password.invalid_email";
            default:
                return "authentication.forget_password.unknow_reason";

        }
    }

    interface FormFields {
        email: string;
    }

    const initialValues: FormFields = {
        email: "",
    }

    const validationSchema: yup.SchemaOf<FormFields> = yup.object().shape({
        email: yup
            .string()
            .max(70, "authentication.signin.email.validation.maxString70")
            .email("authentication.signin.email.validation.emailFormat")
            .required("authentication.signin.email.validation.emailRequired")
    });

    const clickHandler = async (data: FormFields) => {

        setLoading(true);
        setEmailSent(false);
        setErrorCode("");

        if (selectedLocation && data.email) {

            const response = await resetPassword(
                data.email,
                selectedLocation.account_id,
                selectedLocation.id,
                deployment?.name ?? DeploymentName.MY_LEMONADE
            );

            if (response?.code) {
                setErrorCode(response.code);
            }
            else {
                setEmailSent(true);
            }
        }
        setLoading(false);
    }

    return (

        <Box
            width="100%"
            display='flex'
            flexDirection='column'
            maxWidth={MAX_HEIGHT_CUSTOMER_FORM}
            data-test="customer.modal.reset_password"
        >

            <Box justifyContent="flex-end" display="flex">
                <IconButton
                    style={{ padding: 0 }}
                    aria-label="Close box"
                    onClick={onForgetPasswordClose}
                    size="large">
                    <Close />
                </IconButton>
            </Box>

            <Box textAlign="center">
                <Typography variant="h3">
                    {intl.formatMessage({ id: "authentication.forget_password.title" }, { br: <br /> })}
                </Typography>
            </Box>

            <Formik<FormFields>
                initialValues={initialValues}
                onSubmit={clickHandler}
                validationSchema={validationSchema}
            >

                <Form>

                    <Box
                        marginTop={2}
                        display="flex"
                        flexDirection="column"
                        paddingX={2}
                        gap={2}
                    >

                        <Typography variant="body1">
                            {intl.formatMessage({ id: "authentication.forget_password.content" })}
                        </Typography>

                        <FormikTextInput
                            name="email"
                            style={{ width: "100%" }}
                            inputProps={{
                                "data-test": "customer.modal.reset_password.email_input"
                            }}
                            label={intl.formatMessage({ id: "authentication.forget_password.label" })}
                        />

                        {(errorCode || authStoreError) &&
                            <Typography
                                data-test={`customer.modal.reset_password.error.${errorCode}`}
                                color={theme.palette.error.main}>
                                {intl.formatMessage({ id: getRecoveryPasswordErrorMessage(errorCode) })}
                            </Typography>
                        }

                        {emailSent &&
                            <Typography
                                data-test="customer.modal.reset_password.success_message"
                                color={theme.palette.success.main}>
                                {intl.formatMessage({ id: "authentication.forget_password.email_send" })}
                            </Typography>
                        }

                        <Box
                            display="flex"
                            justifyContent="center"
                        >

                            <Button
                                data-test="customer.modal.reset_password.button"
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{
                                    width: "100%",
                                }}
                            >
                                {loading
                                    ? <CircularProgress
                                        data-test="customer.modal.reset_password.loader"
                                        disableShrink
                                        size="1rem"
                                        style={{ color: theme.palette.primary.contrastText }}
                                    />
                                    : <Typography textTransform="none">
                                        {intl.formatMessage({
                                            id: "authentication.verify_email.send_email"
                                        })}
                                    </Typography>
                                }
                            </Button>
                        </Box>
                    </Box>
                </Form>
            </Formik>
        </Box >
    );
}

export default ResetPassword;