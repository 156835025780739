import { Box } from '@mui/material';
import { useTypedSelector } from '../../redux/root-reducer';
import useAvailableCategories from '../hooks/useAvailableCategories';
import CatalogsCardComponent from './CatalogsCardsComponent';

interface CatalogsGridComponentProps {
    parentCategoryRef?: string;
}
/**
 * Component in charge to display a list of CatalogsCard
 * @param props 
 */
const CatalogsGridComponent = (props: CatalogsGridComponentProps) => {

    const { selectedCatalog } = useTypedSelector(state => state.locations);

    const categories = useAvailableCategories(props.parentCategoryRef);

    return (
        <Box mb={5}>
            {selectedCatalog && categories.map((category, index) => index % 2 === 1 ? null :

                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} key={index}>

                    <Box width={'40%'}>
                        <CatalogsCardComponent category={category} />
                    </Box>

                    {categories[index + 1] ?

                        <Box width={'40%'}>
                            <CatalogsCardComponent category={categories[index + 1]} />
                        </Box>
                        :
                        <Box width={'40%'} />
                    }
                </Box>
            )}
        </Box>
    );
}

export default CatalogsGridComponent;
