export enum OrderRefusalReason {
    ENTITY_DISABLE = "entity_disable",
    ENTITY_UNAVAILABLE = "entity_unavailable",
    ENTITY_OUT_OF_STOCK = "entity_out_of_stock",
    ENTITY_WITH_INVALID_CONTENT = "entity_with_invalid_content",
    CATEGORY_DISABLED = "category_disabled",
    CATEGORY_UNAVAILABLE = "category_unavailable",
    IN_DEAL_WITH_ITEM_INVALID = "in_deal_with_item_invalid",
    IN_INVALID_DEAL = "in_invalid_deal",
    INVALID_OPTION = "invalid_option",
    OPTION_DISABLED = "option_disabled",
    MAX_USAGE_COUNT_REACHED = "max_usage_count_reached",
}