import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Typography, useTheme } from '@mui/material';
import { getRadiusFromTheme } from '../../Common/helper/ThemeHelper';
import DataTestConst from '../../config/DataTestConst';

type PlusMinusQuantityButtonProps = {
    quantity: number,
    onAdd: () => void,
    onMinus: () => void,
    padding: number,
    isSRender?: boolean,
    smallButtons?: boolean
    dataTestSuffix?: string
};

//This component take all available space it needs a contaier to the right size
const PlusMinusQuantityButton = (props: PlusMinusQuantityButtonProps) => {

    const {
        quantity,
        onAdd,
        onMinus,
        padding,
        isSRender,
        smallButtons,
    } = props;

    const theme = useTheme();

    // The size of + and - buttons
    const squareSize: string = smallButtons ? "35px" : "58px";
    const digitSquareSize: string = smallButtons ? "12px" : "20px";

    return (
        <Box
            display="flex"
            height="100%"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
            px={padding + "px"}
            borderRadius={getRadiusFromTheme(theme)}
            gap={smallButtons ? 2 : 4}
        >

            <Box
                data-test={props.dataTestSuffix ? `${DataTestConst.DECREASE_QUANTITY}_${props.dataTestSuffix}` : DataTestConst.DECREASE_QUANTITY}
                onClick={() => {
                    if (quantity > 0) {
                        onMinus()
                    }
                }}
                height={squareSize}
                width={squareSize}
                borderRadius={getRadiusFromTheme(theme)}
                border={`1px solid ${quantity === 0 ? theme.palette.secondary.light : theme.palette.secondary.main}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ cursor: quantity === 0 ? 'not-allowed' : 'pointer' }}
            >
                <RemoveIcon color={quantity === 0 ? 'disabled' : 'secondary'} fontSize='small' />
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                height={digitSquareSize}
                width={digitSquareSize}
            >
                <Typography
                    variant={isSRender ? "h2" : "h5"}
                    color='secondary'
                >
                    {quantity}
                </Typography>
            </Box>


            <Box
                data-test={props.dataTestSuffix ? `${DataTestConst.INCREASE_QUANTITY}_${props.dataTestSuffix}` : DataTestConst.INCREASE_QUANTITY}
                onClick={() => onAdd()}
                height={squareSize}
                width={squareSize}
                borderRadius={getRadiusFromTheme(theme)}
                border={`1px solid ${theme.palette.secondary.main}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ cursor: 'pointer' }}
            >
                <AddIcon color='secondary' fontSize='small' />
            </Box>

        </Box >
    );

}

PlusMinusQuantityButton.defaultProps = { padding: 10 }

export default PlusMinusQuantityButton

