import { Review } from "../../my-lemonade-library/src/reviews/models/Review";

export const CREATE_LOCATION_REVIEW = 'CREATE_LOCATION_REVIEW';

export interface CreateLocationReviewAction {
    type: typeof CREATE_LOCATION_REVIEW,
    payload: {
        review: Review
    }
}

export const CREATE_LOCATION_REVIEW_SUCCESS = 'CREATE_LOCATION_REVIEW_SUCCESS';

export interface CreateLocationReviewSuccessAction {
    type: typeof CREATE_LOCATION_REVIEW_SUCCESS,
}

export type ReviewActions =
    CreateLocationReviewAction
    | CreateLocationReviewSuccessAction;

export const reviewActions = {
    createLocationReview: (review: Review): CreateLocationReviewAction => ({
        type: CREATE_LOCATION_REVIEW,
        payload: {
            review
        }
    }),

    createLocationReviewSuccess: (): CreateLocationReviewSuccessAction => ({
        type: CREATE_LOCATION_REVIEW_SUCCESS
    }),
}