enum OrderUpdateReason {
    CREATION = "creation",
    ADD_PAYMENT = "add_payment",
    UPDATE_PAYMENT = "update_payment",
    CONFIRM_PAYMENT = "confirm_payment",
    REFUND_PAYMENT = "refund_payment",
    ADD_ITEMS = "add_items",
    MANUAL_STATUS_UPDATE = "manual_status_update",
    DELIVERY_UPDATE = "delivery_update",
    CONNECTOR_UPDATE = "connector_update",
    AUTH_PASSWORD_ANONYMOUS_SUCCESS = "auth_password_anonymous_success",
    ADD_CONTRIBUTOR = "add_contributor",
    UPDATE_CONTRIBUTORS = "update_contributors",
    TOGGLE_CONTRIBUTOR_USE_LOYALTY_POINTS = "toggle_contributor_use_loyalty_points",
    UPDATE_DISCOUNTS = "update_discounts",
    CREATE_DRAFT_ORDER = "create_draft_order",
    UPDATE_ITEM_NOTE = "update_item_note",
    UPDATE_DISCOUNTS_AND_CONTRIBUTORS = "update_discounts_and_contributors",
    UPDATE_ITEMS = "update_items",
    UPDATE_MASTER_USER = "update_master_user",
    UPDATE_CUSTOMER_NOTES = "update_customer_notes",
    UPDATE_CUSTOMER = "update_customer",
    UPDATE_EXPECTED_OR_END_PREPARATION_TIME = "update_expected_or_end_preparation_time",
    UPDATE_LOYALTY_USER_ID = "update_loyalty_user_id",
    UPDATE_STATUS = "update_status",
    UPDATE_SERVICE_TYPE = "update_service_type",
}
export default OrderUpdateReason;