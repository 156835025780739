import { Box, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useIntl } from "react-intl";
import { Categorie, Deal, Product } from "../../my-lemonade-library/model/Catalog";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { canOrderEntityNow, canPlaceOrderNow } from "../../my-lemonade-library/src/restrictions/services/RestrictionsService";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

interface EntityUnavailableTextProps {
    serviceType: SupportedServiceType;
    entity: Product | Categorie | Deal;
}

/**
 * Don't forget to update the function "useIsEntityOrderableNow" (CatalogService) if you
 * add new display conditions here
 * @param props 
 * @returns 
 */
const EntityUnavailableText: React.FC<EntityUnavailableTextProps> = (props) => {

    const { serviceType, entity } = props;

    const theme = useTheme();
    const intl = useIntl();

    const { selectedLocation, selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);

    const canPlaceOrderNowResult = canPlaceOrderNow(selectedLocation, selectedCatalog, selectedTable, DateTime.now());
    const canOrderEntityNowResult = canOrderEntityNow(selectedLocation, selectedCatalog, entity, selectedTable.service_type, DateTime.now());

    const renderFormattedDateTime = (dateTime: DateTime | undefined, baseIntlId: string): JSX.Element => {

        const now = DateTime.local();

        if (!dateTime || !dateTime.hasSame(now, "day")) {
            return renderMessage(intl.formatMessage({ id: baseIntlId }));
        }

        return renderMessage(intl.formatMessage({ id: `${baseIntlId}.withTime` }, { time: dateTime.toLocaleString(DateTime.TIME_SIMPLE) }));
    }

    const renderMessage = (message: string): JSX.Element => {
        return (
            <Typography
                variant="caption"
                style={{
                    color: theme.palette.text.disabled,
                }}
            >
                {message}
            </Typography>
        );
    }

    if (serviceType === SupportedServiceType.VIEW) {
        return renderMessage(intl.formatMessage({ id: "product.add_button_product.view_mode" }));
    }

    // Not view: we can have the "online ordering" and/or "product" disabled message
    return (

        <Box
            width={1}
            display="flex"
            flexDirection="column"
            gap={1}
            data-test="entity-unavailable-text"
        >
            {!canPlaceOrderNowResult.canPlaceOrderNow && renderFormattedDateTime(canPlaceOrderNowResult.nextAvailbleTime, "orders.order_awaiting_payment.cant_order")}
            {!canOrderEntityNowResult.canOrderEntityNow && renderFormattedDateTime(canOrderEntityNowResult.nextAvailbleTime, "product.add_button_product.disable")}

        </Box>
    );
}

export default EntityUnavailableText;