import moment, { Moment } from "moment";
import { Catalog, CatalogData, getTimezoneName } from "../../../model/Catalog";
import { Location, SupportedServiceType, Table } from "../../../model/Location";
import { OrderInBase } from "../../../model/Order";
import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import { Restriction } from "../../restrictions/model/Restriction";
import { getFirstMatchingRestriction, getRestrictionsArray, getSupportedServiceTypesWithRestrictions, intersectCatalogLocationTableRestrictions, intersectServiceTypeLists } from "../../restrictions/services/RestrictionsService";
import { FIRESTORE_TABLELINKS_COLLECTION, FIRESTORE_TABLES_COLLECTION } from "../configs/TableFirestoreConfig";

// TABLE LINKS

export const getTableLinkFirestoreCollectionPath = (): string => {
    return `${FIRESTORE_TABLELINKS_COLLECTION}`;
}

export const getTableLinkFirestoreDocPath = (tableLinkId: string): string => {
    return `${getTableLinkFirestoreCollectionPath()}/${tableLinkId}`;
}

// TABLES

export const getTableFirestoreDocPathFromOrder = (order: OrderInBase): string => {
    return getTableFirestoreDocPath(order.account_id, order.location_id, order.table_id);
}

export const getTableFirestoreCollectionPath = (accountId: string, locationId: string): string => {
    return `${getLocationFirestoreDocPath(accountId, locationId)}/${FIRESTORE_TABLES_COLLECTION}`;
}

export const getTableFirestoreDocPath = (accountId: string, locationId: string, tableId: string): string => {
    return `${getTableFirestoreCollectionPath(accountId, locationId)}/${tableId}`;
}

/**
 * Get the service type(s) in an array for a given table. If no service type at all,
 * it returns undefined
 * TODO: to be tested
 * @param table 
 * @returns 
 */
export const getTableServiceTypes = (table?: Table | null, setInRestriction?: boolean, location?: Location, catalog?: Catalog): SupportedServiceType[] | undefined => {

    const locationCatalogServiceType = location?.supported_service_types
    let tableServiceTypes: SupportedServiceType[] | undefined = undefined;
    if (table) {

        // Put the service type in an array
        if (table.service_type) {

            tableServiceTypes = locationCatalogServiceType ? intersectServiceTypeLists(locationCatalogServiceType, [table.service_type]) : [table.service_type];
            // Set it in the restriction if needed
            if (setInRestriction) {
                if (!table.restrictions) {
                    table.restrictions = {}
                }
                table.restrictions.service_types = tableServiceTypes;
            }
        }

        // Return the array of service types which are in the restriction
        else if (
            table.restrictions
            && table.restrictions.service_types
            && table.restrictions.service_types.length > 0
        ) {
            tableServiceTypes = locationCatalogServiceType ? intersectServiceTypeLists(locationCatalogServiceType, table.restrictions.service_types) : table.restrictions.service_types;
        } else {
            tableServiceTypes = locationCatalogServiceType;
        }
    }

    return tableServiceTypes;
}

export const mergeTableServiceTypes = (table: Table, location: Location, catalog: Catalog, momentNow?: Moment) => {

    /**
     * The goal here is to set the local (redux) service_type(s) based
     * on the settings of the table in firestore.
     * The properties changed as we updated the model (the restriction is now
     * more important than the "main" service_type field of the table. See
     * https://www.notion.so/mylemonade/d6b8c6b524eb478bb6ac0989d36c7733?v=2825f0ea5e5f4dc6a66b908beb435f6a&p=f15c8065b9cf4356b083813e12d13535)
     * 
     * Now the fallbacks are, in order:
     * table.restriction.service_types >> table.service_type >> all restrictions of location&catalog >> eat_in
     */

    let supportedServiceTypes = getSupportedServiceTypesWithRestrictions(catalog, location, table);

    // Legacy service type to be removed, taken into account by getSupportedServiceTypesWithRestrictions
    if (table.service_type) {
        table.service_type = undefined;
    }

    // If no service type found, log an error and set a default one
    if (supportedServiceTypes.length === 0) {

        /**
         * TODO: before, we took one even if it wasn't the intersection.
         * For ex: location.service_type = [view, delivery]
         * and catalog.service_type = [collection]
         * before : service_type would be set to view or collection, without error.
         * Now we set to the default one (eat_in) and we log an error
         */
        console.warn("No table supported service type, set it to eat in")
        table.service_type = SupportedServiceType.EAT_IN;

        // There is a restriction with only one service_type set : we take it
        // and set it to the state as table.service_type
    } else if (supportedServiceTypes.length === 1) {
        table.service_type = supportedServiceTypes[0];
    } else {
        // If eat in, check if currently supported
        if (supportedServiceTypes.includes(SupportedServiceType.EAT_IN)) {
            const allRestrictions = intersectCatalogLocationTableRestrictions(catalog, location, table);
            if (!momentNow) {
                momentNow = moment();
            }
            const foundRestriction = (allRestrictions && allRestrictions.length > 0)
                ? getFirstMatchingRestriction(momentNow, getTimezoneName(catalog), allRestrictions, SupportedServiceType.EAT_IN)
                : undefined;

            // If not currently allowed, remove it from the list
            if (!foundRestriction) {
                supportedServiceTypes = supportedServiceTypes.filter((serviceType) =>
                    serviceType !== SupportedServiceType.EAT_IN);
            }
        }

        // If any other service type is supported, remove it from the list in order to avoid extra tabs
        //TODO: If no timeslots in delivery, why the user couldn't want to view the menu?
        const filteredServiceTypes = supportedServiceTypes.filter((serviceType) =>
            serviceType !== SupportedServiceType.VIEW &&
            serviceType !== SupportedServiceType.CHECKOUT
        );

        if (filteredServiceTypes.length) {
            supportedServiceTypes = filteredServiceTypes;
        }

        // Service types may have been removed, take the first one if only one left
        if (supportedServiceTypes.length === 1) {
            table.service_type = supportedServiceTypes[0];
        }

        if (!table.restrictions) {
            table.restrictions = {};
        }

        // We know the restriction has no service_type (we checked before)
        // so we can set directly
        table.restrictions.service_types = supportedServiceTypes;
    }

    return supportedServiceTypes;
}

export const updateTableAreaRefInCatalog = (catalogData: CatalogData, oldRef: string, newRef: string | null) => {

    // Products
    catalogData.products.forEach((product) => {
        getRestrictionsArray(product.restrictions)?.forEach((restriction) => {
            updateTableAreaRefInRestriction(restriction, oldRef, newRef);
        });
    });

    // Categories
    catalogData.categories.forEach((category) => {
        getRestrictionsArray(category.restrictions)?.forEach((restriction) => {
            updateTableAreaRefInRestriction(restriction, oldRef, newRef);
        });
    });

    // Deals
    catalogData.deals.forEach((deal) => {
        getRestrictionsArray(deal.restrictions)?.forEach((restriction) => {
            updateTableAreaRefInRestriction(restriction, oldRef, newRef);
        });
    });

    // Discounts
    catalogData.discounts?.forEach((discount) => {
        getRestrictionsArray(discount.restrictions)?.forEach((restriction) => {
            updateTableAreaRefInRestriction(restriction, oldRef, newRef);
        });
    });
}

/**
 * Replace the ref or remove it from the array.
 * @param tableAreasRefs 
 * @param oldRef 
 * @param newRef 
 */
const updateTableAreaRefInRestriction = (restriction: Restriction | undefined | null, oldRef: string, newRef: string | null) => {

    const tableAreasRefs = restriction?.table_areas_refs;

    if (tableAreasRefs && restriction) {

        const index = tableAreasRefs.indexOf(oldRef);
        if (index > -1) {
            if (newRef) {
                tableAreasRefs[index] = newRef;
            }
            else {
                tableAreasRefs.splice(index, 1);
            }
        }

        if (tableAreasRefs.length === 0) {
            delete restriction.table_areas_refs;
        }
    }
}

/**
 * Merge 2 tables. Very simple function at the moment, complexity can be added later.
 * @param oldTable 
 * @param newTable 
 * @returns 
 */
export const mergeTables = (oldTable: Table, newTable: Table): Table => {

    const mergedTable: Table = { ...oldTable, ...newTable };
    return mergedTable;
}