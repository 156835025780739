enum CatalogAnomalyType {
    DUPLICATE_ENTITY = "duplicate_entity",
    INVALID_REFERENCE = "invalid_reference",
    MINIMUM_ONE_REF_IN_ARRAY = "minimum_one_ref_in_array",
    MISSING_ENTITY_REF = "missing_entity_ref",
    INVALID_PRICE = "invalid_price",
    INVALID_PERCENTAGE = "invalid_percentage",
    INVALID_TABLE_AREA_REF = "invalid_table_area_ref",
    INVALID_RESTRICTION = "invalid_restriction",
    INCONSISTENT_RESTRICTION = "inconsistent_restriction",
}
export default CatalogAnomalyType;