import { MAX_CHAR_DESCRIPTION_PRODUCT, MAX_CHAR_DESCRIPTION_PRODUCT_WITH_TAGS, MAX_CHAR_NAME_PRODUCT, MIN_CHAR_DESCRIPTION_PRODUCT, MIN_CHAR_NAME_PRODUCT } from "../configs/truncateTextConfig";

/**
 * slice text if text.length > limitation
 * return text if no limitation is provided
 * @param text 
 * @param limitation 
 * @returns 
 */
export function truncateText(text: string | undefined, limitation: number | null) {
    if (text && limitation && text.length > limitation) {
        return `${text.slice(0, limitation)} ...`
    }
    return text

}

/**
 * get limitation according to type
 * return null if type unknow 
 * @param isMobile boolean
 * @param type name | descriptions
 * @returns 
 */
export function getCharacterLengthToDisplay(isMobile: boolean, type: "name" | "description", hasTag?: boolean) {
    switch (type) {
        case "name":
            return isMobile ? MIN_CHAR_NAME_PRODUCT : MAX_CHAR_NAME_PRODUCT
        case "description":
            return isMobile ? MIN_CHAR_DESCRIPTION_PRODUCT : (hasTag ? MAX_CHAR_DESCRIPTION_PRODUCT_WITH_TAGS : MAX_CHAR_DESCRIPTION_PRODUCT)
        default:
            return null
    }

}