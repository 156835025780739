export const MIN_AMOUNT_SHARE_PAYMENT = 20

export type MinAmountSharePaymentByCountry = {
    [countryCode: string]: number
}

export const minAmountSharePaymentByCountry: MinAmountSharePaymentByCountry = {
    'FR': 20,
}

export const MIN_ITEM_QUANTITY_FOR_SHARE_PAYMENT = 2;