import { useTheme } from '@mui/material';
import React from 'react';
import { DEFAULT_MYLEMONADE_PRIMARY_COLOR } from '../../my-lemonade-library/src/theme/config/DefaultTheme';
import SvgColor from './SvgColor';

interface ThemeSvgProps {
    svg: string;
    width?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;
}

const ThemeSvg: React.FC<ThemeSvgProps> = (props: ThemeSvgProps) => {

    const {
        svg,
        width,
        maxWidth,
        maxHeight
    } = props

    const theme = useTheme();
    const colorMap: { [previousColor: string]: string } = {};
    colorMap[DEFAULT_MYLEMONADE_PRIMARY_COLOR] = theme.palette.primary.main;

    return <SvgColor
        svg={svg}
        width={width}
        colorMap={colorMap}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
    />
}
export default ThemeSvg;