import { Location } from "../../my-lemonade-library/model/Location";
import { Order } from "../../my-lemonade-library/model/Order";
import { addMoney, Money, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { orderService } from "../../my-lemonade-library/src/orders/services/OrderService";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import { FabType } from "../models/OrderButtonsModels";

/**
 * Get the number of items displayed on the Fab button
 * @param order
 */
export const getFabOrderItemsCount = (order: Order) => {
    let count: number = 0;
    const hasItemToBeAdded = orderService.hasItemToBeAdded(order);
    // if not item to be added, we want to display the right number of lines to be paid
    const productsNotSent = order.items.filter(item => !hasItemToBeAdded || !item.update_id);
    const productsWithoutDeal = productsNotSent.filter(item => !item.deal_line)
    productsWithoutDeal.forEach(pdt => {
        count += pdt.quantity
    })

    const dealKeysNotSent: string[] = []
    productsNotSent.forEach(item => {
        if (item.deal_line && item.deal_line.deal_key && !dealKeysNotSent.includes(item.deal_line.deal_key)) {
            dealKeysNotSent.push(item.deal_line.deal_key);
            count += item.quantity
        }
    })

    return count
}

/**
 * Get the type of the FAB, which will decide what to display
 * in the text and price section.
 * @param location 
 * @param order 
 * @returns 
 */
export const getFabType = (location: Location | undefined, order: Order): FabType => {

    if (orderService.isFullyEditable(order)) {
        return FabType.ORDER;
    }
    else {

        if (orderService.hasItemToBeAdded(order)) {
            return FabType.ADD_ITEMS;
        }
        else if (location && paymentHelper.canPay(location, order)) {
            return FabType.PAY;
        }
    }

    return FabType.ORDER;
}

/**
 * Get the intl formatMessage ID of the text to display in the FAB.
 * @param location 
 * @param order 
 * @returns 
 */
export const getFabOrderTitleId = (location: Location | undefined, order: Order): string => {

    const fabType: FabType = getFabType(location, order);

    switch (fabType) {

        case FabType.ADD_ITEMS:
            return "Order.addButton.add";

        case FabType.PAY:
            return "Order.pay";

        case FabType.ORDER:
        default:
            return "Order.Order";
    }
}

/**
 * Get the order price
 * @param order 
 * @param currency 
 * @returns 
 */
export function getFabOrderPriceWithoutDiscountCharges(order: Order, currency: string): Money {

    if (order.items.length === 0) {

        return numberToMoney(0, currency);
    }

    let totalOrder = numberToMoney(0, currency);
    order.items.forEach((item) => {
        // Only not yet sent items (except if no added items to display the price to pay)
        if ((!orderService.hasItemToBeAdded(order) || !item.update_id) && item.subtotal) {
            totalOrder = addMoney(totalOrder, item.subtotal)
        }
    })

    return totalOrder
}

/**
 * A generic function to return the price to display in the FAB.
 * It gets the type of button and returns the result of the right function
 * @param location 
 * @param order 
 * @param currency 
 * @returns 
 */
export const getFabPrice = (
    location: Location | undefined,
    order: Order,
    currency: string,
): Money => {

    const fabType = getFabType(location, order);

    switch (fabType) {

        case FabType.PAY:

            return numberToMoney(paymentHelper.getOrderRemainingAmount(order), currency);

        case FabType.ADD_ITEMS:
        case FabType.ORDER:
        default:

            return getFabOrderPriceWithoutDiscountCharges(order, currency);
    }
}