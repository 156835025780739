import log, { LogLevelDesc } from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { getApiEndpoint, isDevEnv } from '../../config/variables';
import { Log, LogLevels } from '../../my-lemonade-library/model/Common/Log';
import { MyLemonadeApplications } from '../../my-lemonade-library/model/Common/MyLemonadeApplications';
import { MylemonadeContext } from '../../my-lemonade-library/src/common/models/MyLemonadeContext';
import { setLoggerInstance } from '../../my-lemonade-library/src/common/services/LogService';

let mylemonadeContext: MylemonadeContext = {
    application: MyLemonadeApplications.WEBAPP,
    version: process.env.REACT_APP_VERSION
}

export const getMylemonadeContext = (): MylemonadeContext => {
    return mylemonadeContext;
}

// Send the error logs to our backend
const customJSON = (log: any): Log => {
    const formattedLog: Log & { [key: string]: any } = {
        ...mylemonadeContext,
        url: window.location.href,
        level: log.level.label.toLowerCase(),
        message: log.message,
    };
    return formattedLog;
};

export const getRemoteLogsCapacity = function (logLevel: LogLevels): number {
    if (logLevel === LogLevels.ERROR) {
        return 1;
    } else if (logLevel === LogLevels.WARN) {
        return 5;
    } else if (logLevel === LogLevels.INFO) {
        return 10;
    } else {
        return 20;
    }
}

let remoteAlreadyConfigured = false;
let remoteToken = "";
export const setRemoteLogsConfig = function (token?: string) {
    try {
        if (remoteAlreadyConfigured) {
            log.info(`Remote logs already configured, disable it first`);
            remote.disable();
        }
        if (token) {
            remoteToken = token;
        }
        const logLevel = isDevEnv() ? LogLevels.ERROR : currentLogLevel;
        log.warn(`Set remote logs config with level: ${logLevel}`);
        // Only send errors
        remote.apply(log, {
            url: `${getApiEndpoint()}/logs/batch`,
            method: 'POST',
            level: logLevel,
            token: remoteToken,
            capacity: getRemoteLogsCapacity(logLevel),
            format: customJSON
        });
        log.warn(`Remote log correctly configured: ${logLevel}`);
        remoteAlreadyConfigured = true;
    } catch (error) {
        // Can crash if called several time
        // Generally in debug mode
    }
}

let currentLogLevel: LogLevels = LogLevels.WARN;
export const setLogLevel = (logLevel: LogLevels) => {
    currentLogLevel = logLevel;
    if (!currentLogLevel) {
        currentLogLevel = LogLevels.ERROR;
    }
    try {
        log.setLevel(currentLogLevel as LogLevelDesc);
    }
    catch (error) {
        log.info("LogLevel from .env file is not recognized, setting 'error' instead");
        currentLogLevel = LogLevels.ERROR;
        log.setLevel(currentLogLevel);
    }
    // Refresh the remote log config because loglevel can't be modified
    setRemoteLogsConfig()
}
setLogLevel(process.env.REACT_APP_LOGLEVEL as LogLevels);



// Set library logger
setLoggerInstance(log);

export default log;