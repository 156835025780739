import { DeploymentName } from "../../common/models/DeploymentInfo";

export interface Terms {

    id: string;
    created_at: Date;
    name?: string;

    // Identification
    application_date: Date;
    deployment_name: DeploymentName;
    target: TermsTarget;

    /**
     * @deprecated: use localized_content_url instead
     */
    content_url?: string;

    // An URL which points to the .md file containing the localized terms.
    localized_content_url: {
        [key: string]: string
    }
}

export enum TermsTarget {
    CUSTOMERS = "customers",  // Typically: GTCU of the webapp, targetting the Mylemonade.app or GetResto app customers.
    PROFESSIONNALS = "professionnals", // Typically: GTCU of the backoffice, targetting the restaurants, pubs, etc.
    PARTNERS = "partners",
}