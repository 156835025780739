const colorRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/

export const decode = (svg: string) => decodeURIComponent(svg)

export const encode = (svg: string) => {
    svg = encodeURI(svg)
    svg = svg.replace(/%20/g, ' ')
    svg = svg.replace(/#/g, '%23')
    svg = "data:image/svg+xml," + svg

    return svg
}

export const changeColors = (svg: string, colorMap: { [previousColor: string]: string }) => {
    svg = changeExistingColor(svg, colorMap)

    return svg
}

const changeExistingColor = (svg: string, colorMap: { [previousColor: string]: string }) => {
    let svgArray = svg.split(colorRegex)

    for (let i = 1; i < svgArray.length; i++) {

        if (i % 2 !== 0) {
            const previousColor = svgArray[i];
            const previousColorWithSharp = `#${previousColor}`;
            if (colorMap[previousColorWithSharp] && isColorValid(colorMap[previousColorWithSharp])) {
                const newColor = colorMap[previousColorWithSharp];

                if (previousColor.match(/([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/)) {
                    svgArray[i] = svgArray[i].replace(previousColor, newColor)
                }
            }
            else {
                svgArray[i] = `#${svgArray[i]}`
            }
        }
    }

    return svgArray.join("");
}

/*
const changeColorPath = (svg: string, colorMap: { [previousColor: string]: string }) => {
    let svgArray = svg.split("<path")

    for (let i = 0; i < svgArray.length; i++) {

        if (!svgArray[i].match(colorRegex) && svgArray[i].match(/^ /)) {
            if (colorMap[previousColor] && isColorValid(colors[colorsIndex])) {
                let pathSplit = svgArray[i].split("/>")
                pathSplit[0] += ` fill="${colors[colorsIndex]}"`
                svgArray[i] = pathSplit.join("/>")
            }
        }
    }

    return svgArray.join("<path")
}*/

const isColorValid = (color: string) => {
    if (color !== undefined && color !== null) {
        if (color.trim() !== "") {
            if (color.match(colorRegex)) {
                return true
            }
        }
        return false
    }
    return false
}

const isTransformFieldExist = (svg: string, field: string) => {
    let regex = new RegExp(`transform=.*${field}`)
    return svg.match(regex)
}

export const changeRotate = (svg: string, rotate: number): string => {
    let svgArray = svg.split("<svg")
    let svgArray2 = svgArray[1].split(">")

    if (svgArray2[0].match(/transform=.*/)) {
        if (isTransformFieldExist(svgArray2[0], "rotate")) {
            if (isTransformFieldExist(svgArray2[0], "scale")) {
                if (svgArray2[0].match(/scale.*rotate/)) {
                    svgArray2[0] = svgArray2[0].replace(/scale\(.*\)/, ` scale(0.8) rotate(${rotate})`)
                }
                else {
                    svgArray2[0] = svgArray2[0].replace(/rotate\(.*\)/, ` scale(0.8) rotate(${rotate})`)
                }
            }
            else {
                svgArray2[0] = svgArray2[0].replace(/rotate\(.*\)/, ` scale(0.8) rotate(${rotate})`)
            }
        }
        else {
            if (isTransformFieldExist(svgArray2[0], "scale")) {
                svgArray2[0] = svgArray2[0].replace(/scale\(.*\)/, ` scale(0.8) rotate(${rotate})`)
            }
            else {
                let svgArray3 = svgArray2[0].split('transform="')
                let svgArray4 = svgArray3[1].split('"')
                svgArray4[0] += `scale(0.8) rotate(${rotate})`
                svgArray3[1] = svgArray4.join('"')
                svgArray2[0] = svgArray3.join('transform="')
            }
        }
    }
    else {
        svgArray2[0] += ` transform="scale(0.8) rotate(${rotate})"`
    }

    svgArray[1] = svgArray2.join(">")
    const finalSvg = svgArray.join("<svg")
    return finalSvg;
}

const SvgParser = {
    changeColors,
    changeRotate,
    decode,
    encode
}
export default SvgParser;