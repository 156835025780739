import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { Box, Typography, useTheme } from "@mui/material"
import { DateTime } from "luxon"
import { useIntl } from "react-intl"
import { OrderInBase } from "../../my-lemonade-library/model/Order"
import { MoneyToStringWithSymbol } from "../../my-lemonade-library/src/common/models/Money"
import { RootState, useTypedSelector } from "../../redux/root-reducer"

interface InfoOrderDisplayProps {
    selectedOrder: OrderInBase
}

export const InfoOrderDisplay = (props: InfoOrderDisplayProps) => {

    const {
        selectedOrder
    } = props

    const intl = useIntl()
    const theme = useTheme()

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    if (!selectedLocation) {
        return null
    }

    const getOrderDateString = () => {
        if (DateTime.fromJSDate(selectedOrder.created_at).toISODate() === DateTime.local().toISODate()) {
            return intl.formatMessage({ id: 'today' })
        } else {
            return DateTime
                .fromJSDate(selectedOrder.created_at)
                .setLocale(selectedLocation.language ?? 'en-US')
                .toLocaleString()
        }
    }

    return (
        <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
        >
            <Box
                display='flex'
                flexDirection='column'
            >
                <Typography>
                    {`${getOrderDateString()} 
                    ${intl.formatMessage({ id: 'common.at.label' })} 
                    ${DateTime
                            .fromJSDate(selectedOrder.created_at)
                            .setLocale(selectedLocation.language ?? 'en-US')
                            .toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                        `
                    }
                </Typography>
                <Typography
                    variant='caption'
                    color='text.disabled'
                    sx={{ marginTop: theme.spacing(1) }}
                >
                    {`${intl.formatMessage(
                        { id: 'order.items_and_price' },
                        {
                            items_number: selectedOrder.items.length,
                            price: MoneyToStringWithSymbol(selectedOrder.total)
                        })}`}
                </Typography>
            </Box>

            <NavigateNextRoundedIcon fontSize='large' sx={{ color: 'text.disabled' }} />
        </Box>
    )
}

export default InfoOrderDisplay