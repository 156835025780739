export interface LyraMarketplaceCreatePaymentOptions {

    do_no_use_saved_card?: boolean;

    // Do not save the card for a next purchase
    do_not_save_card?: boolean;

    payment_method?: LyramarketplacePaymentMethod;
}

export enum LyramarketplacePaymentMethod {
    CONECS = "conecs",
}