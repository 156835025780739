import { CustomerInformationFormParameters } from "../models/CustomerInformationFormParameters";
import CustomerInformationModalFormOpenArgs from "../models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../models/CustomerInformationModalFormToDisplay";

class BaseFormModalService {

    form: CustomerInformationModalFormToDisplay

    constructor(form: CustomerInformationModalFormToDisplay) {
        this.form = form;
    }

    shouldDisplayLogo() {
        return false;
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: CustomerInformationFormParameters } | null {
        throw new Error("Not implemented");
    }

}
export default BaseFormModalService;