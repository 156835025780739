import log from "../../Common/services/LogService";
import { SignInProviders } from "../../my-lemonade-library/src/authentications/models/BaseUser";
import { UserAuthenticationState } from "../models/AuthenticationState";

/**
 * Say if the user is logged in based on some state variables.
 * @param user_authentication_state 
 * @returns 
 */
export const isUserLoggedIn = (user_authentication_state: UserAuthenticationState): boolean => {

    if (user_authentication_state.user &&
        !user_authentication_state.user.uid) {
        log.error("User has no uid");
    }
    if (user_authentication_state.is_anonymous &&
        user_authentication_state.user?.sign_in_provider &&
        user_authentication_state.user?.sign_in_provider !== SignInProviders.ANONYMOUS) {
        log.error(`User is logged in anonymously but has a sign in provider ${user_authentication_state.user?.sign_in_provider}`);
    }

    if (!user_authentication_state.is_anonymous &&
        !user_authentication_state.user) {
        log.error("User is not anonymous but has no user info");
    }

    if (
        user_authentication_state.user
        && user_authentication_state.user.uid
        && !user_authentication_state.is_anonymous
    ) {

        return true;
    }

    return false;
}

export const getAuthenticationProviderButtonColor = (signInProvider: SignInProviders): string | undefined => {
    if (signInProvider === SignInProviders.GETRESTO) {
        return "#FF5823"
    }
    return undefined;
}

export const getAuthenticationProviderName = (signInProvider: SignInProviders): string => {
    if (signInProvider === SignInProviders.GETRESTO) {
        return "GetResto"
    }
    else if (signInProvider === SignInProviders.PASSWORD) {
        return "My Lemonade"
    }
    return signInProvider;
}
