import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import orderActions from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

/**
 * Header buttons used to access & change user information
 * Includes also access to help and logout
 * @param props
 */
const UserHeaderButton: React.FC = () => {

    const { tableLinkId } = useTypedSelector((state: RootState) => state.locations);

    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <Box onClick={() => {
            dispatch(orderActions.openModal())
            history.push(`/${tableLinkId}/myinformations`)
        }
        }>
            <IconButton
                data-test="toolbar_accountButton"
                style={{ padding: theme.spacing(0.5, 0, 0.5, 0.5) }}
                size="large"
            >
                <AccountCircleIcon sx={{ color: theme.palette.secondary.main }} />
            </IconButton>
        </Box>
    );
};

export default UserHeaderButton;


