import { combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { authenticationReducer } from '../authentication/redux/AuthenticationReducer';
import CommonReducer from '../Common/redux/CommonReducer';
import CustomerInformationModalReducer from '../customers/redux/CustomerInformationModalReducer';
import DealListItems from '../deals/redux/reducer';
import Locations from '../Locations/redux/LocationReducers';
import loyaltyReducer from '../loyalty/redux/LoyaltyReducer';
import Order from '../orders/redux/OrderReducers';
import { paymentsReducer } from '../Payment/redux/PaymentsReducer';
import { tableReducer } from '../tables/redux/TableReducer';
import { themeReducer } from '../theme/redux/ThemeReducer';
import Language from '../translations/redux/reducers';
import { appReducer } from './app/AppReducer';



const rootReducer = combineReducers({
    app: appReducer,
    locations: Locations,
    order: Order,
    loyalty: loyaltyReducer,
    lang: Language,
    deals: DealListItems,
    authentication: authenticationReducer,
    table: tableReducer,
    customerInformationModal: CustomerInformationModalReducer,
    payment: paymentsReducer,
    common: CommonReducer,
    theme: themeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;


export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
