import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import { FIRESTORE_SESSIONS_COLLECTION } from "../configs/SessionConfig";
import { Session } from "../models/Session";

export const SESSION_ID_HEADER = 'x-session-id';

export const getSessionsFirestoreCollectionPath = (accountId: string, locationId: string): string => {
    return `${getLocationFirestoreDocPath(accountId, locationId)}/${FIRESTORE_SESSIONS_COLLECTION}`;
}

export const getSessionFirestoreDocPath = (accountId: string, locationId: string, sessionId: string): string => {
    return `${getSessionsFirestoreCollectionPath(accountId, locationId)}/${sessionId}`;
}

export const replaceUserInSession = async (session: Session, oldUserId: string, newUserId: string): Promise<void> => {

    if (session.user_id === oldUserId) {
        session.user_id = newUserId;
    }
}