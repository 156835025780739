import { Alert, Box, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import AuthenticationAdvantagesFormDialog from '../../authentication/components/AuthenticationAdvantagesFormDialog';
import AuthenticationConfirmation from '../../authentication/components/AuthenticationConfirmation';
import AuthenticationError from '../../authentication/components/AuthenticationError';
import AuthenticationPinConfirmation from '../../authentication/components/AuthenticationPinConfirmation';
import EmailSentConfirmation from '../../authentication/components/EmailSentConfirmation';
import ResetPassword from '../../authentication/components/ResetPassword';
import { default as Signup, default as SignupPassword } from '../../authentication/components/SignupPassword';
import VerifyEmail from '../../authentication/components/VerifyEmail';
import AuthenticationErrorFormParameters from '../../authentication/models/AuthenticationErrorFormParameters';
import AuthenticationFormParameters from '../../authentication/models/AuthenticationFormParameters';
import AuthenticationPinConfirmationFormParameters from '../../authentication/models/AuthenticationPinConfirmationFormParameters';
import LoaderComponent from '../../Common/components/LoaderComponent';
import { MEDIUM_DIALOG_WIDTH } from '../../Common/configs/DialogSizeConst';
import { AUTHENTICATION_MODAL } from '../../Common/configs/Zindex';
import { isClosedByClickingOutside } from '../../Common/helper/ModalHelper';
import log from '../../Common/services/LogService';
import { customerModalGetResto } from '../../Common/StyledComponents';
import { isHomePage } from '../../config/routes';
import { desktopDisplayBreakpoint } from '../../config/theme';
import { LocationLinksDisplay } from '../../linktree/components/LocationLinksDisplay';
import LocationLogo from '../../Locations/components/LocationLogo';
import { Location } from '../../my-lemonade-library/model/Location';
import { ERROR_QUERY_PARAM_NAME } from '../../my-lemonade-library/src/common/configs/CommonApiRoutes';
import { ThemeList } from '../../my-lemonade-library/src/theme/models/ThemesList';
import themeHelper from '../../my-lemonade-library/src/theme/services/ThemeHelper';
import OrderLoadedPayOrUseLoyalty from '../../orders/components/OrderLoadedPayOrUseLoyaltyForm';
import SharedOrderFinalize from '../../orders/components/SharedOrderFinalize';
import { ORDER_ID_QUERY_PARAM_NAME, ORDER_REF_QUERY_PARAM_NAME } from '../../orders/configs/OrdersRouterConfig';
import ReceiptViewer from '../../printers/components/ReceiptViewer';
import ReceiptFormParameters from '../../printers/models/ReceiptFormParameters';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import SelectTableForm from '../../tables/components/SelectTableFormDialog';
import ServiceTypeOrTakeoutForm from '../components/ServiceTypeOrTakeoutForm';
import CustomerInformationModalFormOpenArgs from '../models/CustomerInformationModalFormOpenArgs';
import { CustomerInformationModalFormToDisplay } from '../models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../redux/CustomerInformationModalActions';
import customerInformationModalService from '../services/CustomerInformationModalService';


// Those values will change the buttons so that they fit for small screens
const S_BREAKPOINT: number = 415;
// const XS_BREAKPOINT: number = 395;
// const XXS_BREAKPOINT: number = 360;

const DISPLAY_LINKS_FORMS: CustomerInformationModalFormToDisplay[] = [
    CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
    CustomerInformationModalFormToDisplay.SERVICE_TYPES_OR_TAKEOUT,
    CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY
]
export const shouldDisplayLinksWithForm = (form?: CustomerInformationModalFormToDisplay | null): boolean => {

    if (form && DISPLAY_LINKS_FORMS.includes(form)) {
        return true;
    }
    return false;
}

interface CustomerInformationModalProps {
    isSelectedTableServiceRestricted?: boolean;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AUTO_CLOSE_KEY = "autoClose";

/**
 * This modal asks for many things: authentication, table choice, delivery address, 
 * payment for an unpaid order, ... The logic is a bit complex.
 * First, the hook useCustomerInformationModalDecision is called in locationHome. It 
 * checks the conditions one by one following a precise order and displays the modal
 * with the corresponding form if one condition is not met. This hook has a useEffect and is
 * triggered on startup, but also each time the popup is closed. It means that if we close the
 * modal while it's still missing information (actually it's not supposed* to happen because
 * of the checks in the onModalClose function, but still), it'll open again. Then there is another
 * useEffect in this file, but its purpose is a bit different. This useEffect works only when the
 * modal is opened. It checks if the conditions of the currently displayed form are already met.
 * This helps with the delay, for example if you login and the hook still thinks you're not logged
 * in, you'll see the LOGIN form but when the login will be successful, the useEffect will close the
 * modal.
 * So at the end, there's a sort of concurrency between those 2. If you want to trace what's happening,
 * set the loglevel to debug and follow the >-C-> and >-D-> logs.
 * @param props 
 * @returns 
 */
const CustomerInformationModal: React.FC<CustomerInformationModalProps> = (props) => {

    const { isSelectedTableServiceRestricted = false } = props;

    const dispatch = useDispatch();
    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();
    const query = useQuery();
    const orderIdURL = query.get(ORDER_ID_QUERY_PARAM_NAME);
    const orderRefURL = query.get(ORDER_REF_QUERY_PARAM_NAME);
    const errorQueryParam = query.get(ERROR_QUERY_PARAM_NAME);

    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));
    const isNotDesktopRender = useMediaQuery(theme.breakpoints.down(desktopDisplayBreakpoint));
    const isSRender = useMediaQuery(theme.breakpoints.up(S_BREAKPOINT));

    const { formToDisplay, formParameters } = useTypedSelector((state: RootState) => state.customerInformationModal);

    const { tableLinkId, selectedTable, selectedLocation: rawSelectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { order, initOrders: initOrdersState, currentOrder: currentOrderState } = useTypedSelector((state: RootState) => state.order);
    const { data } = useTypedSelector((state: RootState) => state.authentication);

    const selectedLocation: Location = {
        ...rawSelectedLocation,
        ...(rawSelectedLocation as any)?.i18n?.[intl.locale]
    }

    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [onModalCloseAction, setOnModalCloseAction] = useState<({
        formToDisplay: CustomerInformationModalFormToDisplay;
        onClose: (reason?: string) => void;
    }) | null>(null);

    const { user_authentication_state } = data;
    const { is_email_verified } = user_authentication_state;

    // Theme
    const [isGetRestoTheme, setIsGetRestoTheme] = useState<boolean>(false)
    const { themeName } = useTypedSelector((state: RootState) => state.theme)

    useEffect(() => {
        if (
            themeName
            && themeName === ThemeList.GET_RESTO
            && isNotDesktopRender
            && formToDisplay === CustomerInformationModalFormToDisplay.SERVICE_TYPES_OR_TAKEOUT
        ) {
            setIsGetRestoTheme(true)
        }
    }, [themeName, isNotDesktopRender, formToDisplay])

    /**
     * This useEffect checks if the conditions to display the modal are met. For example,
     * we can display the authentication modal but the user is logged in. If so, we close the modal.
     */
    useEffect(() => {

        if (selectedLocation && formToDisplay) {

            let closeModal: boolean = false;

            const args: CustomerInformationModalFormOpenArgs = {
                tableLinkId,
                currentPathname: history.location.pathname,
                authenticationData: data,
                selectedTable,
                selectedLocation,
                order,
                initOrders: initOrdersState,
                orderIdQueryParam: orderIdURL,
                orderRefQueryParam: orderRefURL,
                errorQueryParam,
                query,
                message: "",  // Empty message at the moment
            }

            if (!customerInformationModalService.shouldKeepFormOpened(formToDisplay, args)) {
                closeModal = true;
            }

            if (closeModal) {
                log.debug(`>-D-> ${args.message}, autoclosing the ${formToDisplay} modal`);
                onModalClose(undefined, AUTO_CLOSE_KEY);
            }
            else {
                log.debug(`>-D-> ${args.message}, not autoclosing the ${formToDisplay} modal`);
            }
        }

    }, [
        formToDisplay,
        user_authentication_state,
        is_email_verified,
        selectedTable.id,
        selectedTable.service_type,
        JSON.stringify(order.customer),
        initOrdersState,
        selectedLocation,
        history.location.pathname,
        orderRefURL,
        order.expected_time,
    ]);

    /**
     * This function is triggered when the modal wants to close. It means that it can be triggered after we
     * executed the onClose callback, but it can also mean that the user clicked outside or pressed the
     * escape key. In this case, we have to execute the onClose callback because it was not executed yet.
     * This callback will then decide if the modal should be closed or not.
     */
    const onModalClose = useCallback((
        event?: object,
        reason?: string
    ) => {

        if (isClosedByClickingOutside(reason) && !isSelectedTableServiceRestricted) {
            if (onModalCloseAction !== null && onModalCloseAction.formToDisplay === formToDisplay) {
                onModalCloseAction.onClose(reason);
            }
            // The modal doesn't have a callback registered? Close it
            else if (formToDisplay) {
                dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(formToDisplay));
            }
        }
        else if (reason === AUTO_CLOSE_KEY && formToDisplay) {
            dispatch(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(formToDisplay));
        }

    }, [history, onModalCloseAction]);


    const shouldOpenAnotherFormAfter = (formToDisplay: CustomerInformationModalFormToDisplay): boolean => {
        if (selectedLocation) {
            const args: CustomerInformationModalFormOpenArgs = {
                tableLinkId,
                currentPathname: history.location.pathname,
                authenticationData: data,
                selectedTable,
                selectedLocation,
                order,
                initOrders: initOrdersState,
                orderIdQueryParam: orderIdURL,
                orderRefQueryParam: orderRefURL,
                errorQueryParam,
                query,
                message: "",
            }
            const shouldOpenAnotherFormAfterResult = customerInformationModalService.shouldOpenAnotherFormAfter(formToDisplay, args);
            return Boolean(shouldOpenAnotherFormAfterResult);
        }
        return false;
    }

    /**
     * Render the content on the modal based on the formToDisplay state variable.
     * Return a component to display in the modal
     * @returns the component to render
     */
    const renderModalContent = () => {
        if (isSelectedTableServiceRestricted) {
            return (
                <OrderLoadedPayOrUseLoyalty
                    isSelectedTableServiceRestricted={isSelectedTableServiceRestricted}
                    setOnModalCloseAction={setOnModalCloseAction}
                />
            );
        }

        switch (formToDisplay) {

            case CustomerInformationModalFormToDisplay.SIGNUP_PASSWORD:

                return (
                    <SignupPassword
                        setOnModalCloseAction={setOnModalCloseAction}
                        parameters={formParameters as AuthenticationFormParameters}
                    />
                );

            case CustomerInformationModalFormToDisplay.SELECT_TABLE:

                return (
                    <SelectTableForm
                        setOnModalCloseAction={setOnModalCloseAction}
                    />
                );

            case CustomerInformationModalFormToDisplay.SERVICE_TYPES_OR_TAKEOUT:

                return (

                    <ServiceTypeOrTakeoutForm
                        // Set full screen when entering address due to keyboard
                        setFullScreen={(param: boolean) => {
                            if (param) {
                                if (!isDesktopRender) {
                                    setFullScreen(true);
                                }
                            }
                            else {
                                setFullScreen(false);
                            }
                        }}
                        isGetRestoTheme={isGetRestoTheme}
                    />
                );

            case CustomerInformationModalFormToDisplay.AUTHENTICATION_ERROR:

                return (
                    <AuthenticationError
                        setOnModalCloseAction={setOnModalCloseAction}
                        parameters={formParameters as AuthenticationErrorFormParameters}
                    />
                );

            case CustomerInformationModalFormToDisplay.VERIFY_EMAIL:

                return (
                    <VerifyEmail
                        setOnModalCloseAction={setOnModalCloseAction}
                    />
                );

            case CustomerInformationModalFormToDisplay.FORGET_PASSWORD:

                return (
                    <ResetPassword
                        setOnModalCloseAction={setOnModalCloseAction}
                        parameters={formParameters as AuthenticationFormParameters}
                    />
                );


            case CustomerInformationModalFormToDisplay.MISSING_USER_INFORMATION:

                return (
                    <Signup
                        setOnModalCloseAction={setOnModalCloseAction}
                        onlyUserInformation
                    />
                );

            case CustomerInformationModalFormToDisplay.ORDER_LOADED_PAY_OR_USE_LOYALTY:

                return (
                    <OrderLoadedPayOrUseLoyalty
                        setOnModalCloseAction={setOnModalCloseAction}
                    />
                );

            case CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES:

                return (
                    <AuthenticationAdvantagesFormDialog
                        shouldOpenAnotherFormAfter={shouldOpenAnotherFormAfter}
                        setOnModalCloseAction={setOnModalCloseAction}
                    />
                );

            case CustomerInformationModalFormToDisplay.AUTHENTICATION_CONFIRMATION:
                return (
                    <AuthenticationConfirmation setOnModalCloseAction={setOnModalCloseAction} />
                )

            case CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE:
                return (
                    <SharedOrderFinalize setOnModalCloseAction={setOnModalCloseAction} />
                )

            case CustomerInformationModalFormToDisplay.EMAIL_SENT:
                return (
                    <EmailSentConfirmation setOnModalCloseAction={setOnModalCloseAction} />
                )

            case CustomerInformationModalFormToDisplay.RECEIPT:
                return (
                    <ReceiptViewer receiptUrl={(formParameters as ReceiptFormParameters)?.receipt_url ?? ""} />
                )
            case CustomerInformationModalFormToDisplay.AUTHENTICATION_PIN_CONFIRMATION:
                return (
                    <AuthenticationPinConfirmation
                        setOnModalCloseAction={setOnModalCloseAction}
                        parameters={formParameters as AuthenticationPinConfirmationFormParameters} />
                )
            default:
                return (<Typography>Not supported modal ${formToDisplay}</Typography>)
        }
    }

    return (
        <Dialog
            data-test="customer.modal.customer_information_modal"
            maxWidth={isGetRestoTheme ? undefined : 'md'}
            fullWidth={!isDesktopRender}
            fullScreen={fullScreen}
            open={formToDisplay !== null}
            onClose={onModalClose}
            PaperProps={{
                style: (isGetRestoTheme)
                    ? { ...customerModalGetResto }
                    : {
                        borderRadius: fullScreen ? 0 : 20,
                        margin: fullScreen ? theme.spacing(2, 0) : theme.spacing(2, 1),
                        width: isDesktopRender ? MEDIUM_DIALOG_WIDTH : "100%",
                    }
            }}
            style={{
                padding: fullScreen || isGetRestoTheme ? 0 : theme.spacing(1),
                zIndex: AUTHENTICATION_MODAL,
            }}
        >

            {

                currentOrderState.refreshing
                    ? <LoaderComponent />
                    : <Box
                        p={(isGetRestoTheme || formToDisplay === CustomerInformationModalFormToDisplay.RECEIPT) ? 0 : 3}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width={1}
                        height={1}
                    >

                        <Box
                            display="flex"
                            flexDirection="column"
                            width={isSRender ? "80%" : "100%"}
                        >

                            {
                                // Displaying the header of the popup: LOGO + "welcome to <location name>"
                                // Only for some specific forms, and if it's not at the end
                                formToDisplay && customerInformationModalService.shouldDisplayLogo(formToDisplay)
                                    ? <LocationLogo
                                        titleId={themeHelper.shouldDisplayWelcomeText(selectedLocation) ? "homeForm.welcome1" : ""}
                                        mb={2}
                                    />
                                    : null
                            }

                            {renderModalContent()}
                        </Box>

                        {isHomePage(history.location.pathname, tableLinkId) && selectedLocation?.information_banner &&
                            <Alert severity="info" sx={{ marginTop: 2 }}>
                                <Typography>
                                    {selectedLocation.information_banner}
                                </Typography>
                            </Alert>
                        }

                        {isHomePage(history.location.pathname, tableLinkId) && shouldDisplayLinksWithForm(formToDisplay)
                            ? <Box width={1} px={isGetRestoTheme ? 2 : 0}>
                                <LocationLinksDisplay />
                            </Box>
                            : null
                        }
                    </Box>
            }
        </Dialog >
    )
}

export default CustomerInformationModal;

