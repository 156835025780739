import reduce from "lodash/reduce";
import { OptionList, Product, Sku } from "../../../model/Catalog";
import { SupportedServiceType } from "../../../model/Location";
import { Order, OrderInBase, OrderItem, OrderStatus } from "../../../model/Order";
import { Money, addMoney, moneyToNumber, multiplyMoney, numberToMoney, substractMoney } from "../../common/models/Money";
import { MylemonadeContext } from "../../common/models/MyLemonadeContext";
import { log } from "../../common/services/LogService";
import productService from "../../products/services/ProductService";
import { OrderItemExtendedForOptions } from "../models/OrderItemExtendedForOptions";

export const setContextFromOrder = (context: MylemonadeContext, order: OrderInBase) => {
    if (order) {
        if (!context.account_id) {
            context.account_id = order.account_id;
        }
        if (!context.location_id) {
            context.location_id = order.location_id;
        }
        if (!context.order_id) {
            context.order_id = order.id;
        }
        if (!context.catalog_id) {
            context.catalog_id = order.catalog_id;
        }
        if (!context.table_id) {
            context.table_id = order.table_id;
        }
        if (!context.user_id) {
            context.user_id = order.user_id;
        }
    }
}

// TODO: test it
// TODO: make a precise schema and finish implementing this function
// TODO: manipulate objects instead of arrays for unicity?
/**
 * Defines what are the possible next status for an order depending on the current status
 * ! depends on service type: for now, only eat-in / collection / delivery are supported
 * ! gives all the status if the service type is not supported
 * ! doesn't include status relative to payment state
 * @param currentStatus 
 * @param serviceType 
 * @param isManual set it to true if the status manipulation is made by a human. False if by an algorithm
 * @returns 
 */
export const getFollowingOrderStatus = (
    currentStatus: OrderStatus,
    serviceType: SupportedServiceType,
    isManual: boolean = false,
): OrderStatus[] => {

    const allOrderStatus = Object.values(OrderStatus);
    let allStatusFilteredServiceType: OrderStatus[] = []

    // * Get all status possible for a service type

    if (serviceType === SupportedServiceType.EAT_IN) {

        allStatusFilteredServiceType.push(OrderStatus.NEW);
        allStatusFilteredServiceType.push(OrderStatus.ACCEPTED);
        allStatusFilteredServiceType.push(OrderStatus.IN_PREPARATION);
        // Collection at the bar
        allStatusFilteredServiceType.push(OrderStatus.AWAITING_COLLECTION);
        allStatusFilteredServiceType.push(OrderStatus.COMPLETED);

        allStatusFilteredServiceType.push(isManual ? OrderStatus.CANCELLED : OrderStatus.REJECTED);

    } else if (serviceType === SupportedServiceType.COLLECTION) {

        allStatusFilteredServiceType.push(OrderStatus.NEW);
        allStatusFilteredServiceType.push(OrderStatus.ACCEPTED);
        allStatusFilteredServiceType.push(OrderStatus.IN_PREPARATION);
        allStatusFilteredServiceType.push(OrderStatus.AWAITING_COLLECTION);
        allStatusFilteredServiceType.push(OrderStatus.COMPLETED);

        allStatusFilteredServiceType.push(isManual ? OrderStatus.CANCELLED : OrderStatus.REJECTED);

    } else if (serviceType === SupportedServiceType.DELIVERY) {

        allStatusFilteredServiceType.push(OrderStatus.NEW);
        allStatusFilteredServiceType.push(OrderStatus.ACCEPTED);
        allStatusFilteredServiceType.push(OrderStatus.IN_PREPARATION);
        allStatusFilteredServiceType.push(OrderStatus.AWAITING_SHIPMENT);
        allStatusFilteredServiceType.push(OrderStatus.IN_DELIVERY);
        allStatusFilteredServiceType.push(OrderStatus.DELIVERY_FAILED);
        allStatusFilteredServiceType.push(OrderStatus.COMPLETED);

        allStatusFilteredServiceType.push(isManual ? OrderStatus.CANCELLED : OrderStatus.REJECTED);

    } else {
        log.debug(`Service type ${serviceType} doesn't require to filter status available => all status take`)
        allStatusFilteredServiceType = allOrderStatus
    }

    const finalAvailableStatus: OrderStatus[] = []
    allStatusFilteredServiceType.forEach(statusToKeepOrNot => {

        // For each one, test if we can keep it in the array or not.
        // To understand this swith case, you have to read each case from bottom to top, see
        // the example for ACCEPTED
        switch (statusToKeepOrNot) {

            // If the current status is PENDING_PAYMENT, then we can go to NEW.
            case OrderStatus.NEW:

                if (currentStatus === OrderStatus.PENDING_PAYMENT) {
                    finalAvailableStatus.push(statusToKeepOrNot)
                }
                break;

            // If the current status is NEW or PENDING_PAYMENT, then we can go to ACCEPTED.
            case OrderStatus.ACCEPTED:

                if (currentStatus === OrderStatus.NEW || currentStatus === OrderStatus.PENDING_PAYMENT) {
                    finalAvailableStatus.push(statusToKeepOrNot)
                }
                break;

            case OrderStatus.IN_PREPARATION:

                if (currentStatus === OrderStatus.NEW || currentStatus === OrderStatus.ACCEPTED) {
                    finalAvailableStatus.push(statusToKeepOrNot)
                }
                break;

            case OrderStatus.AWAITING_COLLECTION:

                if (serviceType === SupportedServiceType.COLLECTION ||
                    serviceType === SupportedServiceType.EAT_IN) {

                    const goodStatus = [OrderStatus.NEW, OrderStatus.ACCEPTED, OrderStatus.IN_PREPARATION];

                    if (goodStatus.includes(currentStatus)) {
                        finalAvailableStatus.push(statusToKeepOrNot)
                    }
                }
                break;

            case OrderStatus.AWAITING_SHIPMENT:

                if (serviceType === SupportedServiceType.DELIVERY) {

                    const goodStatus = [OrderStatus.NEW, OrderStatus.ACCEPTED, OrderStatus.IN_PREPARATION];

                    if (goodStatus.includes(currentStatus)) {
                        finalAvailableStatus.push(statusToKeepOrNot)
                    }

                }
                break;

            case OrderStatus.IN_DELIVERY:

                if (serviceType === SupportedServiceType.DELIVERY) {

                    const goodStatus = [OrderStatus.NEW, OrderStatus.ACCEPTED, OrderStatus.IN_PREPARATION, OrderStatus.AWAITING_SHIPMENT];

                    if (goodStatus.includes(currentStatus)) {
                        finalAvailableStatus.push(statusToKeepOrNot)
                    }

                }
                break;

            case OrderStatus.DELIVERY_FAILED:

                if (serviceType === SupportedServiceType.DELIVERY) {

                    const goodStatus = [OrderStatus.NEW, OrderStatus.ACCEPTED, OrderStatus.IN_PREPARATION, OrderStatus.AWAITING_SHIPMENT, OrderStatus.IN_DELIVERY];

                    if (goodStatus.includes(currentStatus)) {
                        finalAvailableStatus.push(statusToKeepOrNot)
                    }

                }
                break;

            case OrderStatus.CANCELLED:

                if (currentStatus !== OrderStatus.CANCELLED) {
                    finalAvailableStatus.push(statusToKeepOrNot)
                }
                break;

            case OrderStatus.REJECTED:

                if (currentStatus !== OrderStatus.COMPLETED && currentStatus !== OrderStatus.REJECTED) {
                    finalAvailableStatus.push(statusToKeepOrNot)
                }
                break;

            case OrderStatus.COMPLETED:

                if (currentStatus !== OrderStatus.CANCELLED && currentStatus !== OrderStatus.COMPLETED) {
                    finalAvailableStatus.push(statusToKeepOrNot)
                }
                break;

            default:
                break;
        }
    })

    return finalAvailableStatus;
}

export const isOrderStatusUpdateAllowedForOrder = (order: OrderInBase, updatedStatus: OrderStatus, isManual: boolean = false): boolean => {
    return isOrderStatusUpdateAllowed(order.status, updatedStatus, order.service_type, isManual);
}

export const isOrderStatusUpdateAllowed = (currentStatus: OrderStatus,
    updatedStatus: OrderStatus,
    serviceType: SupportedServiceType,
    isManual: boolean = false): boolean => {
    return getFollowingOrderStatus(currentStatus, serviceType, isManual).includes(updatedStatus);
}

/**
 * Tell whether or not the order has new items in it, meaning
 * items without update_ids (which have not been sent to the API yet)
 */
export const orderHasNewItems = (order: Order): boolean => {

    for (const item of order.items) {

        if (!item.update_id || item.update_id === "") {

            return true;
        }
    }

    return false;
}

const countSentAndUnsentItems = (order: OrderInBase): [number, number] => {

    let numberOfSentItems = 0;
    let numberOfUnsentItems = 0;
    for (const item of order.items) {

        if (item.update_id) {
            numberOfSentItems++;
        }
        else {
            numberOfUnsentItems++;
        }
    }

    return [numberOfSentItems, numberOfUnsentItems];
}

// TODO: test it
/**
 * Get the number of items in order already sent to the API, i.e. which have
 * an update_id
 * @param order 
 * @returns 
 */
export const getNumberOfSentItems = (order: OrderInBase): number => {

    return countSentAndUnsentItems(order)[0];
}

// TODO: test it
/**
 * Get the number of items in order not sent yet to the API (draft items),
 * i.e. which do not have an update_id
 * @param order 
 * @returns 
 */
export const getNumberOfUnsentItems = (order: OrderInBase): number => {

    return countSentAndUnsentItems(order)[1];
}

// TODO: test it
/**
 * Get the total of an order but do not take the unsent (draft) items,
 * i.e. the ones which do not have an update_id.
 * @param order 
 * @returns 
 */
export const getOrderTotalWithoutDraftItems = (order: OrderInBase): Money => {

    let total: Money = order.total;

    order.items.forEach((item) => {

        if (!item.update_id) {

            const itemSubtotal: Money = item.subtotal ?? multiplyMoney(item.price, item.quantity);
            total = substractMoney(total, itemSubtotal);
        }
    });

    return total;
}

// TODO: test it
/**
 * Get the total price of all the unsent (draft) items in the order,
 * i.e. the ones which do not have an update_id.
 * @param order 
 * @returns 
 */
export const getTotalPriceOfDraftItems = (order: OrderInBase, currency: string): Money => {

    let total: Money = numberToMoney(0, currency);

    order.items.forEach((item) => {

        if (!item.update_id) {

            const itemSubtotal: Money = item.subtotal ?? multiplyMoney(item.price, item.quantity);
            total = addMoney(total, itemSubtotal);
        }
    });

    return total;
}

/**
 * Iterates through the order items and returns true if any OptionList misses the
 * "connector_type" property.
 * Also returns true if any OrderOption does not have a matching OptionList
 * @param order 
 * @returns
 */
export const missingAnyOptionTypeInOrder = (order: OrderInBase, optionLists: OptionList[]): boolean => {

    for (const item of order.items) {

        for (const option of item.options) {

            const optionListFound: OptionList | undefined = optionLists.find(
                optionList => optionList.ref === option.option_list_ref
            );

            if (!optionListFound || !optionListFound.connector_type || optionListFound.connector_type === "") {
                return true;
            }
        }
    }

    return false;
}

/**
 * Add to orderItem, product, sku and optionLists 
 * // TODO: test it
 */
export const getOrderItemExtended = (orderItem: OrderItem, products: Product[], optionLists: OptionList[], catalogId: string, locationId: string) => {

    // We tell the functions to throw if error, meaning that if they don't the value is not undefined
    const product: Product = productService.getProductBasedOnProductRef(orderItem.product_ref, products, locationId, catalogId, false) as Product;
    const sku: Sku = productService.getSkuBasedOnSkuRefAndProduct(orderItem.sku_ref, product, locationId, catalogId, false) as Sku;

    const skuOptionList: OptionList[] | null = productService.getSkuOptionList(sku, optionLists)

    const OrderItemExtended: OrderItemExtendedForOptions = {
        ...orderItem,
        product: product,
        sku: sku,
        skuOptionList: skuOptionList,
    }
    return OrderItemExtended
}

export function getItemSubtotal(item: OrderItem): number {
    /**
      * Get item subtotal if exists
      */
    let subtotal: number = item.subtotal ? moneyToNumber(item.subtotal) : 0;

    const item_price = moneyToNumber(item.price);

    /**
     * If it does not exist, calculate subtotal
     */
    if (typeof subtotal !== "number" || !subtotal) {
        subtotal = reduce(
            item.options,
            (prev, option) => prev += moneyToNumber(option.price),

            // Get item total price
            item_price * item.quantity
        );
    }

    return subtotal;
}