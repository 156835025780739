import HttpError from "../../common/models/HttpError";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";

export default class LoyaltyError {

    static EARNING_RULE_AMOUNT_ERROR: HttpError = new HttpError("LOYALTY_MISSING_EARNING_RULE_AMOUNT", "The earning rule amount is not defined", 500, MylemonadeScopes.LOYALTIES);
    static SPENDING_RULE_AMOUNT_ERROR: HttpError = new HttpError("LOYALTY_MISSING_SPENDING_RULE_AMOUNT", "The spending rule amount is not defined", 500, MylemonadeScopes.LOYALTIES);

    static EARNING_RULE_POINTS_ERROR: HttpError = new HttpError("LOYALTY_MISSING_EARNING_RULE_POINTS", "The earning rule points are not defined", 500, MylemonadeScopes.LOYALTIES);
    static SPENDING_RULE_POINTS_ERROR: HttpError = new HttpError("LOYALTY_MISSING_SPENDING_RULE_POINTS", "The spending rule points are not defined", 500, MylemonadeScopes.LOYALTIES);

    static ALREADY_EARNT_POINTS: HttpError = new HttpError("ALREADY_EARNT_POINTS", "The loyalty points have already been collected", 500, MylemonadeScopes.LOYALTIES);

    static EARN_POINTS_INVALID_ORDER_STATUS: HttpError = new HttpError("EARN_POINTS_INVALID_ORDER_STATUS", "Loyalty points can be earnt only for orders not in DWP status", 500, MylemonadeScopes.LOYALTIES);
    static EARN_POINTS_WITH_DRAFT_ITEMS: HttpError = new HttpError("EARN_POINTS_WITH_DRAFT_ITEMS", "Loyalty points can't be earnt for orders with draft items", 500, MylemonadeScopes.LOYALTIES);
    static EARN_POINTS_WITHOUT_LOYALTY_CONFIG: HttpError = new HttpError("EARN_POINTS_WITHOUT_LOYALTY_CONFIG", "Loyalty points can't be earnt without loyalty config", 500, MylemonadeScopes.LOYALTIES);
    static NO_LOYALTY_CONFIG: HttpError = new HttpError("NO_LOYALTY_CONFIG", "Active loyalty config is needed for this operation", 400, MylemonadeScopes.LOYALTIES);
    static INCORRECT_LOYALTY_CONFIG: HttpError = new HttpError("INCORRECT_LOYALTY_CONFIG", "The loyalty config is incorrect for this operation", 500, MylemonadeScopes.LOYALTIES);

    static DISABLED_LOYALTY_CONFIG: HttpError = new HttpError("DISABLED_LOYALTY_CONFIG", "Loyalty config is disabled", 400, MylemonadeScopes.LOYALTIES);
    static EARNING_ON_RECEIPT_NOT_ALLOWED: HttpError = new HttpError("EARNING_ON_RECEIPT_NOT_ALLOWED", "Earning points on receipt is not allowed", 400, MylemonadeScopes.LOYALTIES);

    static LOYALTY_MISSING_CUSTOMER_ID: HttpError = new HttpError("LOYALTY_MISSING_CUSTOMER_ID", "Missing customer id to be able to compute loyalty", 400, MylemonadeScopes.LOYALTIES);
    static LOYALTY_MISSING_ORDER: HttpError = new HttpError("LOYALTY_MISSING_ORDER", "Missing customer order to be able to compute loyalty", 400, MylemonadeScopes.LOYALTIES);

    static LOYALTY_CONNECTOR_MISSING_ORDER_REF: HttpError = new HttpError("LOYALTY_CONNECTOR_MISSING_ORDER_REF", "Missing order connector id, not possible to confirm loyalty usage", 400, MylemonadeScopes.LOYALTIES);

    static LOYALTY_CONNECTOR_ORDER_ALREADY_PROCESSED: HttpError = new HttpError("LOYALTY_CONNECTOR_ORDER_ALREADY_PROCESSED", "Loyalty has been already processed for this order", 400, MylemonadeScopes.LOYALTIES);

    static LOYALTY_CONNECTOR_CONFIRMED_ORDER_LOYALTY_DISCOUNT_MISMATCH: HttpError = new HttpError("LOYALTY_CONNECTOR_CONFIRMED_ORDER_LOYALTY_DISCOUNT_MISMATCH", "The order confirmed by the connector has a different loyalty discount value than expected", 400, MylemonadeScopes.LOYALTIES);
}