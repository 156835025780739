import moment from "moment";
import log from "../../Common/services/LogService";
import * as ROUTES from '../../config/routes';
import { isAddressSet } from "../../delivery/helpers/DeliveryHelpers";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import CustomerInformationModalFormOpenArgs from "../models/CustomerInformationModalFormOpenArgs";
import { CustomerInformationModalFormToDisplay } from "../models/CustomerInformationModalFormToDisplay";
import BaseFormModalService from "./BaseFormModalService";

/**
 * Checking if all the information needed for delivery
 * is there (the address and the delivery time)
 * @param orderCustomer 
 * @param orderPickupTime 
 * @returns 
 */
export const isDeliveryInformationComplete = (
    orderCustomer: Customer | undefined,
    expectedTime: Date | undefined,
): boolean => {

    return (
        isAddressSet(orderCustomer)
        && Boolean(expectedTime)
    );
}

/**
 * Checking if all the information needed for collection
 * is there (the collection time)
 * @param orderCustomer 
 * @param orderPickupTime 
 * @returns 
 */
export const isCollectionInformationComplete = (
    orderPickupTime: Date | undefined
): boolean => {

    return (
        orderPickupTime ? true : false
            && moment(orderPickupTime).isValid()
    )
}

export default class ServiceTypeOrTakeoutFormService extends BaseFormModalService {

    constructor() {
        super(CustomerInformationModalFormToDisplay.SERVICE_TYPES_OR_TAKEOUT);
    }

    shouldDisplayLogo() {
        return false;
    }

    shouldOpenForm(args: CustomerInformationModalFormOpenArgs, forAutoclosing: boolean): { form: CustomerInformationModalFormToDisplay, parameters: null } | null {

        const { selectedTable, orderIdQueryParam, order, currentPathname } = args;

        // The table is in delivery mode and there is no address or expected_time set
        if (
            (
                (!selectedTable.service_type && !orderIdQueryParam)  // If no service_type, it means there are multiple ones
                || (

                    (selectedTable.service_type === SupportedServiceType.DELIVERY || (orderIdQueryParam && order.service_type === SupportedServiceType.DELIVERY))
                    && !isDeliveryInformationComplete(order.customer, order.expected_time)
                )
                || (
                    (selectedTable.service_type === SupportedServiceType.COLLECTION || (orderIdQueryParam && order.service_type === SupportedServiceType.COLLECTION))
                    && !isCollectionInformationComplete(order.expected_time)
                )
            )
            && !currentPathname.includes(ROUTES.OrderConfirmationPage)
            && !currentPathname.includes(ROUTES.OrderConfirmPaymentPage)
        ) {

            log.debug(`>-C-> Takeout incomplete information OR service_type not defined (${selectedTable.service_type}) -> showing the SERVICE_TYPES_OR_TAKEOUT form`);

            return { form: this.form, parameters: null };
        }

        return null;
    }
};