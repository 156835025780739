import MylemonadeScopes from "../src/common/models/MyLemonadeScopes";

// TODO: Move HttpError to library and extend
export class OrderError extends Error {

    static EXISTING_ORDER_SAME_ID = new OrderError("EXISTING_ORDER_SAME_ID", "Not supposed to call send order with an existing order", 400, MylemonadeScopes.ORDERS);
    static NOT_AUTHORIZED = new OrderError("NOT_AUTHORIZED", "Not authorized", 401);
    static NOT_FOUND = new OrderError("NOT_FOUND", "Not found", 404);
    static ACCOUNT_REQUIRED = new OrderError("ACCOUNT_REQUIRED", "AccountId required", 422);
    static LOCATION_REQUIRED = new OrderError("LOCATION_REQUIRED", "LocationId required", 422);
    static CATALOG_REQUIRED = new OrderError("CATALOG_REQUIRED", "CatalogId required", 422);
    static ITEMS_REQUIRED = new OrderError("ITEMS_REQUIRED", "Items required", 422);
    static PAYMENT_TYPE_REQUIRED = new OrderError("PAYMENT_TYPE_REQUIRED", "Payment_type required", 422);
    static TABLE_REQUIRED = new OrderError("TABLE_REQUIRED", "TableId required", 422);
    static ORDER_REF_REQUIRED = new OrderError("ORDER_REF_REQUIRED", "OrderRef required", 422);

    static CATALOG_NOT_FOUND = new OrderError("CATALOG_NOT_FOUND", "Catalog not found", 422);
    static TABLE_NOT_FOUND = new OrderError("TABLE_NOT_FOUND", "Catalog not found", 422);
    static PRODUCT_NOT_FOUND = new OrderError("PRODUCT_NOT_FOUND", "Product not found", 422);
    static SKU_NOT_FOUND = new OrderError("SKU_NOT_FOUND", "Sku not found", 422);
    static OPTION_LIST_REF_NOT_FOUND_IN_PRODUCT = new OrderError("OPTION_LIST_REF_NOT_FOUND_IN_PRODUCT", "Option list ref not found in product", 422);
    static OPTION_LIST_NOT_FOUND = new OrderError("OPTION_LIST_NOT_FOUND", "Option list not found", 422);
    static OPTION_NOT_FOUND = new OrderError("OPTION_NOT_FOUND", "Option not found", 422);
    static OPTION_NOT_REPEATABLE = new OrderError("OPTION_NOT_REPEATABLE", "Multiple occurences of an unreapeatable option", 422);
    static LOCATION_NOT_FOUND = new OrderError("LOCATION_NOT_FOUND", "Location not found", 422);
    static INVALID_OPTIONS = new OrderError("INVALID_OPTIONS", "invalid options", 400)
    static DISABLE_OPTIONS_LIST = new OrderError("DISABLE_OPTIONS_LIST", "options list disable", 400)
    static DISABLE_OPTIONS = new OrderError("DISABLE_OPTIONS", "disable options", 400)
    static DELIVERY_ZONE_NOT_FOUND = new OrderError("DELIVERY_ZONE_NOT_FOUND", "Delivery zone not found", 400)

    static DIFFERENT_PRICE = new OrderError("DIFFERENT_PRICE", "Calculated price is different", 412);
    static CATEGORY_DISABLED = new OrderError("CATEGORY_DISABLED", "Category is disabled for the order time", 422);
    static PRODUCT_DISABLED = new OrderError("PRODUCT_DISABLED", "Product is disabled for the order time", 422);
    static INVALID_ORDER_CONTENT = new OrderError("INVALID_ORDER_CONTENT", "Order contain invalid entities", 400)
    static LOCATION_DISABLED = new OrderError("LOCATION_DISABLED", "Location is disabled", 422);
    static TABLE_DISABLED = new OrderError("TABLE_DISABLED", "Table is disabled", 422);
    static RESTRICTIONS_NOT_VERIFIED = new OrderError("RESTRICTIONS_NOT_VERIFIED", "Restrictions not met", 422, MylemonadeScopes.ORDERS);
    static RESTRICTIONS_MIN_ORDER_AMOUNT = new OrderError("RESTRICTIONS_MIN_ORDER_AMOUNT", "Order minimum amount not reached", 422);
    static CURRENCY_NOT_SUPPORTED = new OrderError("CURRENCY_NOT_SUPPORTED", "Currency not supported", 422);
    static CURRENCIES_DIFFERENT = new OrderError("CURRENCIES_DIFFERENT", "Currencies are different, cannot perform operation", 422);
    static PAYMENT_TYPE_NOT_SUPPORTED = new OrderError("PAYMENT_TYPE_NOT_SUPPORTED", "Payment type not supported", 422)
    static PAYMENT_TYPE_NOT_IMPLEMENTED = new OrderError("PAYMENT_TYPE_NOT_IMPLEMENTED", "Payment type not implemented", 422)
    static SERVICE_TYPE_NOT_SUPPORTED = new OrderError("SERVICE_TYPE_NOT_SUPPORTED", "Service type not supported", 422)
    static PROBLEM_WITH_CONFIGURATION_CONNECTOR = new OrderError("PROBLEM_WITH_CONFIGURATION_CONNECTOR", "Problem has occured with connector configuration", 500, MylemonadeScopes.CONNECTORS)
    static PROBLEM_WITH_CONNECTOR = new OrderError("PROBLEM_WITH_CONNECTOR", "Problem has occured with connector", 500, MylemonadeScopes.CONNECTORS)
    static PRICING_VALUE_NEEDED = new OrderError("PRICING_VALUE_NEEDED", "Pricing value of a deal which needs it is undefined", 422)
    static EXPECTED_TIME_TOO_SHORT = new OrderError("EXPECTED_TIME_TOO_SHORT", "Expected time too short", 422)
    static ORDER_STATUS_INVALID_FOR_PAYMENT = new OrderError("ORDER_STATUS_INVALID_FOR_PAYMENT", "Order status is invalid to be able to confirm the payment", 500)
    static NOT_SUPPORTED_PARTIAL_PAYMENT = new OrderError("NOT_SUPPORTED_PARTIAL_PAYMENT", "Partial payment is not supported", 500)
    static ORDER_UPDATE_ERROR = new OrderError("ORDER_UPDATE_ERROR", "Order update error", 500)
    static ORDER_UPDATE_MISSING_STATUS = new OrderError("ORDER_UPDATE_MISSING_STATUS", "Order update missing status", 500)
    static ORDER_STATUS_UPDATE_NOT_ALLOWED = new OrderError("ORDER_STATUS_UPDATE_NOT_ALLOWED", "The order status update is not allowed by status state graph", 500, MylemonadeScopes.ORDERS)
    static CUSTOMER_INFORMATIONS_MISSING = new OrderError("CUSTOMER_INFORMATIONS_MISSING", "Customer informations are missing", 422)
    static CUSTOMER_UNKNOW = new OrderError("CUSTOMER_UNKNOW", "Customer unknow in base", 500)
    static CUSTOMER_EMAIL_NOT_VERIFIED = new OrderError("CUSTOMER_EMAIL_NOT_VERIFIED", "Customer email is not verified by firebase service", 500)
    static NOT_AUTHENTICATED_CUSTOMER = new OrderError("NOT_AUTHENTICATED_CUSTOMER", "Authentication is mandatory but customer is not authenticated", 500)
    static EMAIL_DOMAIN_NOT_ALLOWED = new OrderError("EMAIL_DOMAIN_NOT_ALLOWED", "Email domain not allowed", 500)
    static USER_HAVENOT_EMAIL_REGISTER = new OrderError("USER_HAVENOT_EMAIL_REGISTER", "User have not email register", 500)
    static EXPECTED_TIME_REQUIRED = new OrderError("EXPECTED_TIME_REQUIRED", "Expected time is require", 400)
    static MISSING_ORDER_MOMENT = new OrderError("MISSING_ORDER_MOMENT", "Missing order moment to check restrictions", 400)
    static DELIVERY_DELAY_REQUIRE = new OrderError("DELIVERY_DELAY_REQUIRE", "Delivery delay is require", 500)
    static TOO_MANY_ORDERS_IN_SLOT = new OrderError("TOO_MANY_ORDERS_IN_SLOT", "Too many orders in slot", 400)
    static TIME_SLOT_NOT_FOUND = new OrderError("TIME_SLOT_NOT_FOUND", "Time slot not found", 404)
    static TAX_NULL_ORDER_ITEM_SUBTOTAL = new OrderError("TAX_NULL_ORDER_ITEM_SUBTOTAL", "The tax amount can't be computed because the subtotal is null", 500, MylemonadeScopes.TAXES)
    static ADD_ITEMS_NOT_ALLOWED = new OrderError("ADD_ITEMS_NOT_ALLOWED", "Adding items to this order is not supported", 400, MylemonadeScopes.ORDERS);
    static CHILD_ORDER_CANCEL_NOT_ALLOWED = new OrderError("CHILD_ORDER_CANCEL_NOT_ALLOWED", "Cancelling manually child order is not allowed", 400, MylemonadeScopes.ORDERS);
    static CHILD_ORDER_PARTS_COMPUTED_WITHOUT_SUBTOTAL = new OrderError("CHILD_ORDER_PARTS_COMPUTED_WITHOUT_SUBTOTAL", "Child order parts cannot be computed without item subtotals", 400, MylemonadeScopes.ORDERS);

    static CANNOT_LOAD_LATEST_ORDERS = new OrderError("CANNOT_LOAD_LATEST_ORDERS", "Cannot load the latestOrders", 422);
    static CANNOT_LOAD_ORDER_BY_REF = new OrderError("CANNOT_LOAD_ORDER_BY_REF", "Cannot load order identified by its ref", 422);
    static CANNOT_LOAD_ORDER_BY_ID = new OrderError("CANNOT_LOAD_ORDER_BY_ID", "Cannot load order identified by its ID", 422);

    static INVALID_PARAM_FROM = new OrderError("INVALID_PARAM_FROM", "Invalid 'from' parameter in the request. Must be seconds", 500, MylemonadeScopes.ORDERS);
    static CHILD_ORDER_NOT_FOUND = new OrderError("CHILD_ORDER_NOT_FOUND", "Child order not found in the parent order", 500, MylemonadeScopes.ORDERS);
    static RETRY_LIMIT_REACHED = new OrderError("RETRY_LIMIT_REACHED", "Retry limit reached", 422, MylemonadeScopes.ORDERS);

    private value: any

    private constructor(public code: string, message: string, public httpErrorCode: number, public scope?: MylemonadeScopes) {
        super()
        this.message = message
        this.value = ""
        Object.setPrototypeOf(this, OrderError.prototype);
    }

    withCustomMessage(message: string, value: any): OrderError {
        return new OrderError(this.code, message, this.httpErrorCode, this.scope).withValue(value);
    }

    withValue(value: any): OrderError {
        this.value = value
        return this
    }

    getValue(): any {
        return this.value;
    }

    getErrorMessage() {
        return this.value
    }
}
