export enum CustomerInformationModalFormToDisplay {

    /**
     * Signup with email / password
     */
    SIGNUP_PASSWORD = "signup",

    RECEIPT = "receipt",

    /**
     * The modal asking for "login" or "continue as guest"
     * and showing the advantages to signin.
     */
    AUTHENTICATION_ADVANTAGES = "authentication_advantages",

    /**
     * This page shows (if needed): a select to select the
     * table if the tableLinkId does not have a table ID set
     */
    SELECT_TABLE = "select_table",


    /**
     * This page can show 3 things :
     * 
     * - If multiple service_types are available for the table
     *  (in the restriction), display tabs for the user to select one
     * 
     * - If delivery (or if there is delivery in the tabs), display a
     * form with the map, ability to chose the address, delivery time, ...
     * 
     * - If checkout (or if there is checkout in the tabs), display a 
     * form with information on the location, and the ability to chose
     * a pickup time
     */
    SERVICE_TYPES_OR_TAKEOUT = "service_types_or_takeout",

    /**
     * This page shows 2 or 3 buttons: pay the order, browse the
     * catalog, or use the loyalty advantages
     */
    ORDER_LOADED_PAY_OR_USE_LOYALTY = "order_loaded_pay_or_use_loyalty",

    /**
     * This form shows an error message if there is one
     */
    AUTHENTICATION_ERROR = "authentication_error",

    VERIFY_EMAIL = "verify_email",
    FORGET_PASSWORD = "forget_password",

    /**
     * This form shows some of the SIGNUP fields with a phrase to
     * collect basic information about the user. It happens if the user
     * exists in firebase but doesn't have an associated document in firestore
     */
    MISSING_USER_INFORMATION = "missing_user_information",

    /**
     * Display simply use for UX
     * give confirmation after a signup or a signin that the user is correctly authenticate
     * Don't use if verify_email is mandatory and the email is not verify
     */
    AUTHENTICATION_CONFIRMATION = "authentication_confirmation",

    /**
     * This modal is displayed at the end of the order flow when several users are
     * registered on the order. It asks the "main" user if the others finished adding
     * their products, and allow the others to cancel the validation in case they were not
     * finished yet.
     */
    SHARED_ORDER_FINALIZE = "shared_order_finalize",

    /**
     * Modal display after the customer asked to receive the insentives by email
     * and the email is sent
     */
    EMAIL_SENT = "email_sent",

    /**
     * Modal to enter the pin code received by email to confirm authentication
     */
    AUTHENTICATION_PIN_CONFIRMATION = "authentication_pin_confirmation",

}