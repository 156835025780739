import { Box, Button, Snackbar, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import * as ROUTES from '../../config/routes';
import WorldlinePaymentIntentInfos from '../../my-lemonade-library/src/payments/models/worldline/WorldlinePaymentIntentInfos';
import { useTypedSelector } from '../../redux/root-reducer';

const PaymentWorldline = () => {
    const params = useParams<ROUTES.RouteParams>();
    const paymentInfos = useTypedSelector(state => state.order.payment_infos) as WorldlinePaymentIntentInfos;
    
    let [error] = useState(false)
    let [open, setOpen] = useState(false)

    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();

    //Change page if order secret is not defined
    useEffect(() => {
        if (paymentInfos && paymentInfos.redirectUrl) {
            window.location.assign(paymentInfos.redirectUrl);
        } else {
            history.push(ROUTES.getCategoriesFullRoute(params.tableLinkKey))
        }

    }, [paymentInfos])


    const handleClose = (event?: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Box
            justifyContent="flex-start"
            style={{ backgroundColor: theme.palette.background.default }}
            height={{ xs: '100%', sm: 'unset' }}
        >
            <Box height={'100%'}>
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >
                    <Snackbar open={open} autoHideDuration={6000} onClose={(e, m) => handleClose(e, m)}>
                        <Box
                            height="60px"
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            p={1}
                            mb={3}
                        >
                            <Typography color="error">
                                {intl.formatMessage({ id: "Payment.error" })}
                            </Typography>
                        </Box>
                    </Snackbar>
                    {
                        error && (
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                >
                                    <Typography variant="h4">
                                        {intl.formatMessage({ id: "authentication.authentication_error.button" })}
                                    </Typography>
                                </Button>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default PaymentWorldline
