import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { PaymentType } from '../../my-lemonade-library/model/Order';
import { StripeSupportedPaymentType } from '../../my-lemonade-library/src/payments/models/stripe/StripeSupportedPaymentType';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

export let stripeService: Promise<Stripe | null> | null = null;

interface StripeRootProps { }
const StripeRoot: React.FC<StripeRootProps> = (props) => {
    const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);

    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    useEffect(() => {
        let stripePromise: Promise<Stripe | null> | null = null
        if (selectedLocation) {
            const stripeSupportedPayment: StripeSupportedPaymentType = selectedLocation.supported_payment_types?.find((supportedPayment) =>
                supportedPayment.type === PaymentType.STRIPE ||
                supportedPayment.type === PaymentType.STRIPE_CONNECT) as StripeSupportedPaymentType;
            if (stripeSupportedPayment && stripeSupportedPayment.public_key) {
                stripePromise = loadStripe(stripeSupportedPayment.public_key);
            }
        }
        stripeService = stripePromise;
        setStripe(stripePromise);

    }, [selectedLocation]);

    if (stripe) {
        return <Elements stripe={stripe}>{props.children}</Elements>
    }

    return <>{props.children}</>;
}
export default StripeRoot;