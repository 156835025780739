enum ConnectorStatus {

    /**
     * The payment or item has been correctly sent to the pos
     */
    ACCEPTED = "accepted",

    /**
     * The payment or item has NOT been correctly sent to the pos
     */
    REJECTED = "rejected"
}
export default ConnectorStatus;