import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Alert, AlertTitle } from "@mui/lab";
import { Box, Dialog, InputAdornment, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Position as TurfPosition } from '@turf/helpers';
import _ from 'lodash';
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { DeviceContext } from "../../App";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import { useAcceptedTerms } from '../../authentication/hooks/useAcceptedTerms';
import AuthenticationFormParameters from '../../authentication/models/AuthenticationFormParameters';
import { AuthenticationActions } from "../../authentication/redux/AuthenticationActions";
import HideOnScroll from "../../Common/components/HideOnScroll";
import LoaderComponent from "../../Common/components/LoaderComponent";
import { SMALL_DIALOG_WIDTH } from "../../Common/configs/DialogSizeConst";
import { loadAndSelectAvailableTimeSlots } from "../../Common/helper/Timeslots";
import log from "../../Common/services/LogService";
import { mobilePaperProps } from "../../Common/StyledComponents";
import * as ROUTES from '../../config/routes';
import { OrderSummary } from "../../config/routes";
import { DeviceContextModel } from "../../config/useDeviceContext";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import DeliveryErrorModal from '../../delivery/components/DeliveryErrorModal';
import { checkDeliveryAvailableAPI } from '../../delivery/helpers/DeliveryHelpers';
import { Catalog, getTimezoneName, Sku } from "../../my-lemonade-library/model/Catalog";
import { CustomerInfo, CustomerInfoFlowChoice, SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { Order, OrderInBase, OrderItem, OrderOption, OrderStatus, PaymentType } from "../../my-lemonade-library/model/Order";
import { ProductExtended } from "../../my-lemonade-library/model/ProductExtended/ProductExtended";
import { getCurrency, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import { getErrorMessage, getErrorStack } from '../../my-lemonade-library/src/common/services/LogService';
import { getOrderRefFromBase64IfNeeded } from "../../my-lemonade-library/src/connectors/services/ConnectorHelper";
import { getAddressFromCustomer } from '../../my-lemonade-library/src/customers/services/CustomerHelper';
import DeliveryUnavailableReason from '../../my-lemonade-library/src/delivery/models/DeliveryUnavailableReason';
import DiscountConfig from '../../my-lemonade-library/src/discounts/models/DiscountConfig';
import { DiscountInvalidity } from '../../my-lemonade-library/src/discounts/models/DiscountInvalidity';
import { DiscountType } from '../../my-lemonade-library/src/discounts/models/OrderDiscount';
import discountService from '../../my-lemonade-library/src/discounts/service/DiscountService';
import { WebappTableOrders } from "../../my-lemonade-library/src/orders/models/LocationOrdersConfig";
import { OrderPriceArg } from "../../my-lemonade-library/src/orders/models/OrderPriceArg";
import { orderPrice } from "../../my-lemonade-library/src/orders/services/OrderPricing";
import { orderService } from "../../my-lemonade-library/src/orders/services/OrderService";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import { MAX_PRODUCT_SUGGEST, MIN_PRODUCT_SUGGEST } from "../../my-lemonade-library/src/suggestions/configs/variables";
import suggestionService from "../../my-lemonade-library/src/suggestions/services/SuggestionService";
import paymentsActions from '../../Payment/redux/PaymentsActions';
import Cards from "../../Products/component/Card";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { SuggestionListToAdd } from "../../suggestions/models/SuggestionsListToAdd";
import SuggestionModal from "../../suggestions/pages/SuggestionModal";
import CouponTextField from "../components/CouponTextField";
import Disclaimer from "../components/Disclaimer";
import EmptyCart from "../components/EmptyCart";
import LoyaltySummary from "../components/LoyaltySummary";
import OrderBottom from "../components/OrderBottom";
import OrderHeader from "../components/OrderHeader";
import OrderList from "../components/OrderList";
import { ORDER_ID_QUERY_PARAM_NAME, ORDER_REF_QUERY_PARAM_NAME } from "../configs/OrdersRouterConfig";
import { getMinOrderAmountIfNotReached, getNumberOfContributors, hasToRefreshOrderFromConnector, isOrderStatusDWP } from "../helpers/OrderHelpers";
import { ListItemsToAdd } from "../models/ListItemsToAdd";
import { OrderSummaryButtonActionTypes } from '../models/OrderButtonsModels';
import OrderAction, { orderActions } from '../redux/OrderActions';
import { getCustomerInfoFieldsToAsk } from "../services/CustomerService";
import { finalizeOrderInfo, getAvailablePaymentTypes, shouldRedirectToSharePayment } from "../services/OrderService";
import ServicePickUpInfo from "./ServicePickupInfo";

/*
End of order Processus, the component redirect to the right service or payment screen by the send function from ../helper
*/

interface AlertState {
    show: boolean;
    message: string;
    severity: "error" | "success" | "info" | "warning";
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OrderSummaryPage: React.FC = () => {

    const { mobile_device, tablet_device, desktop_device } = React.useContext<DeviceContextModel>(DeviceContext)
    const [discalimerOpen, setDiscalimerOpen] = useState(false);
    const query = useQuery();

    const orderIdURL = query.get(ORDER_ID_QUERY_PARAM_NAME);
    const orderRefURL = query.get(ORDER_REF_QUERY_PARAM_NAME);

    const [alert, setAlert] = useState<AlertState>({ show: false, message: "", severity: "error" });

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [suggestionModal, setSuggestionModal] = useState<boolean>(false)
    const [orderToDisplay, setOrderToDisplay] = useState<OrderInBase | null>();
    const [suggestionListToAdd, setSuggestionListToAdd] = useState<SuggestionListToAdd[] | null>(null)
    const [allowMarketing, setAllowMarketing] = useState<boolean>(false)
    const [customerNotes, setCustomerNotes] = useState<string>('')
    const [timeChecked, setTimeChecked] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openDeliveryErrorModal, setOpenDeliveryErrorModal] = useState<boolean>(false)
    const [isCheckingDelivery, setIsCheckingDelivery] = useState<boolean>(false)
    const [undeliverableReason, setUndeliverableReason] = useState<DeliveryUnavailableReason>(DeliveryUnavailableReason.DELIVERY_SERVICE_ERROR)

    const hasAcceptedTerms = useAcceptedTerms();

    interface CouponState {
        isValid: boolean;
        isImported: boolean;  // true if the coupon has not checked from the text input but from a useEffect
        invalidityReason: DiscountInvalidity | undefined;
    }
    const [couponText, setCouponText] = useState<string>('');
    const [couponState, setCouponState] = useState<CouponState>();

    const [waitingForAuthentication, setWaitingForAuthentication] = useState<boolean>(false)

    const [orderBottomHeight, setOrderBottomHeight] = useState<number>(50);
    const orderBottomRef = useRef<HTMLDivElement>(null);

    const { selectedLocation, selectedCatalog, tableLinkId, selectedTable, fromApp, discount_ref_from_url } = useTypedSelector((state) => state.locations);
    const { data } = useTypedSelector(((state: RootState) => state.authentication))
    const orderState = useTypedSelector((state: RootState) => state.order);
    const orderInState = orderState.order as OrderInBase;
    const initOrdersState = orderState.initOrders;
    const currentOrderState = orderState.currentOrder;
    const { location_authentication_config, user_authentication_state } = data
    const { authentication_process, authentication_mandatory } = location_authentication_config
    const { user } = user_authentication_state;
    const { use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);
    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);

    const isLyramarketplaceEnabled = selectedLocation?.supported_payment_types?.some(p => p.type === PaymentType.LYRA_MARKETPLACE);

    // list of product sinDeal or product from happyHour(one line deal)
    // It's a double array because we separate the new items from the old items (the ones already sent to the API)
    // [0] is the list of new products, [1] is the list of old products
    const [productList, setProductList] = useState<OrderItem[][]>();

    // Array of array use to considering a menu deal type as one product with a composition
    // It's a double array because we separate the new deals from the old deals (the ones already sent to the API)
    // [0] is the list of new deals, [1] is the list of old deals
    const [dealList, setDealList] = useState<[OrderItem[]][]>();

    // If one of those is set to true, it means that there is at least one item in the corresponding
    // field (at least one new item, at least one old item)
    const [atLeastOneNewItem, setAtLeastOneNewItem] = useState<boolean>();
    const [atLeastOneOldItem, setAtLeastOneOldItem] = useState<boolean>();

    // The current action that will be triggered when the button will be pressed
    const [orderButtonActionType, setOrderButtonActionType] = useState<OrderSummaryButtonActionTypes>(OrderSummaryButtonActionTypes.CONFIRM);

    const [openCheckBoxesModal, setOpenCheckBoxesModal] = useState<boolean>(false);

    const [customerNotesAllowed, setCustomerNotesAllowed] = useState<boolean>(false)

    useEffect(() => {
        if (selectedLocation && selectedTable && selectedTable.service_type) {
            const infosRequire: CustomerInfo | null = selectedLocation.require_customer_info?.[selectedTable.service_type] ?? null;
            setCustomerNotesAllowed(Boolean(infosRequire?.customer_notes));
        }
    }, [selectedLocation, selectedTable]);

    // Trigger an API call which will eventually refresh the order from the connector.
    useEffect(() => {
        if (
            selectedLocation
            && selectedTable
            && selectedCatalog
            && hasToRefreshOrderFromConnector(
                selectedLocation.connector,
                selectedLocation.orders,
                currentOrderState.last_refreshed_at,
                selectedTable.service_type,
            )
        ) {
            dispatch(orderActions.refreshOrderFromConnector(
                selectedLocation.account_id,
                selectedLocation.id,
                selectedCatalog.id ?? "",
                selectedTable.id,
                orderToDisplay?.id ?? orderState.order.id,
            ));
        }
    }, [selectedLocation, selectedTable, selectedCatalog]);

    /**
     * Refresh the LyraMarketplace user payment data
     */
    useEffect(() => {
        if (selectedLocation && user?.uid && isLyramarketplaceEnabled) {
            dispatch(paymentsActions.isTherePaymentData(selectedLocation.account_id, selectedLocation.id, [PaymentType.LYRA_MARKETPLACE], user?.uid));
        }
    }, [selectedLocation, user]);

    /**
     * Fetch and set the list of suggestions
     * Check on every order changes, state will be set to null if no suggestion are found
     * use to handle dialog size
     */
    useEffect(() => {

        if (orderToDisplay) {

            const suggestions = suggestionService.getSuggestionProductsByOrder(orderToDisplay, selectedCatalog as Catalog);

            if (suggestions) {
                const suggestionToDisplay = mobile_device ? MIN_PRODUCT_SUGGEST : MAX_PRODUCT_SUGGEST
                const suggestionListToDisplay = suggestionService.suggestionSkuExtendedToDisplay(suggestions, suggestionToDisplay);
                let defaultSuggestionListToAdd: SuggestionListToAdd[] | null = null;

                suggestionListToDisplay.forEach(suggestion => {
                    if (!defaultSuggestionListToAdd) {
                        defaultSuggestionListToAdd = [];
                    }
                    defaultSuggestionListToAdd.push({ suggestion: suggestion, quantity: 0, orderOptions: [] });
                });
                setSuggestionListToAdd(defaultSuggestionListToAdd);
            } else {
                setSuggestionListToAdd(null)
            }

            if (!timeChecked) {
                checkExpectedTime()
            }
        }

    }, [orderToDisplay, selectedCatalog, mobile_device, timeChecked])

    useEffect(() => {
        if (orderState.currentOrder.error) {
            createAlert('error', 'error')
        }
    }, [orderState.currentOrder.error]);

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false })
            }, 5000)
        }
    }, [alert.show])

    /**
     * This useEffect setups the order which will be displayed. It checks if there is a specific
     * order to display. It can be the current order in state or a specific order (if orderIdURL is set
     * which means that the order ID is in the URL)
     */
    useEffect(() => {

        // First case: the URL gives a specific order to display
        if (
            (orderIdURL && orderIdURL !== orderInState.id)
            || (orderRefURL
                && getOrderRefFromBase64IfNeeded(orderRefURL) !== orderInState.private_ref
                && !selectedLocation?.orders?.allow_fetch_external_table_orders
            )
        ) {
            let selectedOrder: OrderInBase | null = null;

            // First, search for the order in the userOrders
            initOrdersState.latestUserOrders.forEach(thisOrder => {
                if (
                    thisOrder.id === orderIdURL
                    || (orderRefURL && thisOrder.private_ref === getOrderRefFromBase64IfNeeded(orderRefURL))
                    || (orderRefURL && thisOrder.receipt_ref === getOrderRefFromBase64IfNeeded(orderRefURL))
                ) {
                    selectedOrder = thisOrder;
                }
            });

            // Then if not found, search in the tableOrders
            if (!selectedOrder) {
                initOrdersState.latestTableOrders.forEach(thisOrder => {
                    if (
                        thisOrder.id === orderIdURL
                        || (
                            orderRefURL && thisOrder.private_ref === getOrderRefFromBase64IfNeeded(orderRefURL)
                        )
                    ) {
                        selectedOrder = thisOrder;
                    }
                });
            }

            // Because the total in base is without discounts and charges, we calculate it
            // again to display it properly
            if (selectedOrder && selectedCatalog && selectedLocation) {
                selectedOrder = selectedOrder as OrderInBase;
                const orderPriceArg: OrderPriceArg = {
                    order: selectedOrder,
                    catalog: selectedCatalog,
                    location: selectedLocation,
                    table: selectedTable,
                    disableExpectedTimeResetForEatin: false,
                    checkMinAmount: false,
                    doNotThrow: true,
                    patchOrder: true
                }
                if (orderService.orderNeedsPricing(selectedOrder)) {
                    try {
                        selectedOrder.total = numberToMoney(
                            orderPrice(orderPriceArg).price,
                            selectedCatalog.currency
                        );
                    } catch (error) {

                    }
                }
            }

            // If not found, nothing will be displayed (we set to null)
            setOrderToDisplay(selectedOrder);
        }
        // The order ID is not in the URL so we will display the current order
        else {
            setOrderToDisplay(orderInState)
        }

    }, [initOrdersState, orderInState, orderIdURL, orderRefURL]);

    /**
     * Check if there's already a coupon available and that we can set and check.
     * It can be already in the order (probably added by orderPricing if auto_apply) or it can
     * be in the redux state (coming from the URL)
     */
    useEffect(() => {

        if (!orderToDisplay || !selectedCatalog) {
            return;
        }

        if (couponState) {
            log.debug(`OrderSummaryPage auto discount useEffect: a discount has already been checked, aborting`);
            return;
        }

        // If there's a coupon in the URL and we've not already checked it
        if (discount_ref_from_url) {

            const foundOrderDiscount = orderToDisplay.discounts?.find(d => d.ref === discount_ref_from_url);
            const foundCatalogDiscount = selectedCatalog.data.discounts?.find(d => d.ref === discount_ref_from_url);
            if (foundOrderDiscount) {
                log.debug(`OrderSummaryPage auto discount useEffect: the coupon from URL ${discount_ref_from_url} is already applied`);
                setCouponText(foundCatalogDiscount?.coupon_codes?.[0] ?? discount_ref_from_url);
                setCouponState({ isImported: true, isValid: true, invalidityReason: undefined });
            } else {

                if (!foundCatalogDiscount?.coupon_codes || foundCatalogDiscount.coupon_codes.length === 0) {
                    log.warn(`Coupon code ${discount_ref_from_url} from URL is not found in catalog ${selectedCatalog?.name ?? selectedCatalog?.id}`);
                    return;
                }

                const orderDiscounts = orderToDisplay.discounts ?? [];

                // If there's an auto applied discount here, we remove it.
                const foundAutoAppliedOrderDiscount = orderDiscounts.find(d => d.type !== DiscountType.LOYALTY);

                if (foundAutoAppliedOrderDiscount) {
                    const foundAutoAppliedOrderDiscountIndex = orderDiscounts.findIndex(d => d.type !== DiscountType.LOYALTY);
                    const userId = data.user_authentication_state.user?.uid;
                    const foundAutoApplyCatalogDiscount = selectedCatalog.data.discounts?.find(
                        d => d.auto_apply && d.ref !== discount_ref_from_url && d.ref === foundAutoAppliedOrderDiscount?.ref
                    );
                    if (!_.isNil(foundAutoAppliedOrderDiscountIndex) && foundAutoApplyCatalogDiscount && userId && selectedLocation) {
                        log.info(`Removing auto applied coupon ${foundAutoApplyCatalogDiscount.ref} from order, replacing with coupon from URL ${discount_ref_from_url}`);
                        dispatch(orderActions.removeDiscountFromOrder(
                            foundAutoApplyCatalogDiscount,
                            userId,
                            selectedCatalog,
                            selectedLocation,
                        ));
                        orderDiscounts.splice(foundAutoAppliedOrderDiscountIndex, 1);
                    }
                }

                log.info(`Checking coupon from URL ${discount_ref_from_url} with code ${foundCatalogDiscount.coupon_codes[0]}`, {
                    currentOrderDiscounts: _.cloneDeep(orderToDisplay.discounts)
                });
                setCouponText(foundCatalogDiscount.coupon_codes[0]);
                checkCoupon(foundCatalogDiscount.coupon_codes[0], orderDiscounts, true);
            }
        }
        else if (orderToDisplay.discounts && orderToDisplay.discounts.length > 0) {

            const discount = orderToDisplay.discounts.find(d => d.type !== DiscountType.LOYALTY)
            const discounts = selectedCatalog.data.discounts
            if (discount && discounts) {
                const coup = discounts.find(d => d.ref === discount.ref)
                if (coup && coup.coupon_codes) {
                    setCouponText(coup.coupon_codes[0]);
                    setCouponState({ isImported: true, isValid: true, invalidityReason: undefined });
                }
            }
        } else {
            setCouponText("");
            setCouponState(undefined);
        }
    }, [orderToDisplay?.discounts, selectedCatalog, discount_ref_from_url, data.user_authentication_state.user?.uid, selectedLocation]);

    /**
     * Set the current action for the button in orderBottom
     */
    useEffect(() => {
        if (orderToDisplay) {

            if (couponText !== '' && !couponState) {
                setOrderButtonActionType(OrderSummaryButtonActionTypes.VERIFY_COUPON)
            }
            else {

                // The order is a draft
                if (orderToDisplay.status === OrderStatus.DRAFT || orderToDisplay.status === OrderStatus.WAITING_SUBMISSION) {

                    // We're displaying an order which is not in the state
                    if (orderIdURL !== null && orderIdURL !== orderInState.id) {
                        setOrderButtonActionType(OrderSummaryButtonActionTypes.LOAD);
                    }

                    // We're displaying the current state order
                    else {
                        setOrderButtonActionType(OrderSummaryButtonActionTypes.CONFIRM);
                    }
                }
                // Not a draft
                else {
                    // Can add items (and there are items to be added)
                    if (selectedLocation && orderService.canAddItems(selectedLocation, orderToDisplay) && orderService.hasItemToBeAdded(orderToDisplay)) {
                        setOrderButtonActionType(OrderSummaryButtonActionTypes.SEND_ADDED_ITEMS);
                    }
                    // Order is fully paid
                    else if (paymentHelper.isPaid(orderToDisplay)) {

                        // Location allows to add items for this order: do not propose to re-order, but instead to fill it with new items
                        if (selectedLocation && orderService.canAddItems(selectedLocation, orderToDisplay)) {
                            setOrderButtonActionType(OrderSummaryButtonActionTypes.BACK_TO_CATALOG);
                        }
                        else {
                            setOrderButtonActionType(OrderSummaryButtonActionTypes.REORDER);
                        }
                    }
                    else {

                        setOrderButtonActionType(OrderSummaryButtonActionTypes.PAY);
                    }
                }
            }
        }

    }, [orderToDisplay, couponText, couponState]);

    /**
     * This useEffect setups the items and deals to display whenever the
     * order given in parameters of the component changes. It also separates the new
     * and old items
     */
    useEffect(() => {

        // Checking orderToDisplay first
        if (!orderToDisplay) {
            return;
        }

        setAtLeastOneNewItem(false);
        setAtLeastOneOldItem(false);

        /// --- PRODUCT LIST  --
        let productAloneList: OrderItem[];

        //Global itemsList
        const listItems: OrderItem[] = orderToDisplay.items;

        // Take products sin deal
        const productSinDeal = listItems.filter(item => !item.deal_line);

        productSinDeal.forEach(item => {
            log.debug(`${item.product_name} of ref ${item.product_ref} is considering as product sin deal`)
        });
        productAloneList = productSinDeal;

        // Identify and take product from happyHour deal => select only product with a deal_line field but with a deal-key unique
        const happyHourProduct = listItems.filter(
            item => item.deal_line &&
                !listItems.filter(otherItem => otherItem.sku_ref !== item.sku_ref) // exclude the current product check
                    .find(otherItem => otherItem.deal_line && otherItem.deal_line.deal_key === item.deal_line?.deal_key)
        ); // find if an other product got the same deal_key

        log.debug("happyHourProduct", happyHourProduct);

        happyHourProduct.forEach(item => {
            log.debug(`Product ${item.product_name} of ref ${item.product_ref} is considering as a product from HappyHour deal`);
            productAloneList.push(item);
        })

        // Separating new products from old products
        const productListNew: OrderItem[] = [];
        const productListOld: OrderItem[] = [];

        // Filling the 2 arrays
        productAloneList.forEach((elem) => {
            if (elem.update_id) {
                productListOld.push(elem);
                setAtLeastOneOldItem(true);
            }
            else {
                productListNew.push(elem);
                setAtLeastOneNewItem(true);
            }
        });

        setProductList([productListNew, productListOld]);

        /// --- DEALS ---

        let menuList: [OrderItem[]] = [[]];
        const deals = orderToDisplay.deals;

        if (deals) {
            const keys = Object.keys(deals);
            keys.forEach(key => {
                const dealItems = listItems.filter(item => item.deal_line?.deal_key === key);

                // take only those with more of one item
                if (dealItems.length > 1) {
                    dealItems.forEach(item => {
                        log.debug(`${item.product_name} compose the menu deal ${orderToDisplay.deals[key].name}`)
                    });
                    menuList.push(dealItems)
                }
            });
        }

        // Separating new products from old products
        const dealsListNew: [OrderItem[]] = [[]];
        const dealsListOld: [OrderItem[]] = [[]];

        menuList.forEach((elem) => {
            // We just check the first item of the deal, as they all should
            // have an update_id set if the deal was sent to the API
            if (elem[0]) {
                if (elem[0].update_id) {
                    dealsListOld.push(elem);
                    setAtLeastOneOldItem(true);
                }
                else {
                    dealsListNew.push(elem);
                    setAtLeastOneNewItem(true);
                }
            }
        });
        setDealList([dealsListNew, dealsListOld]);

    }, [orderToDisplay]);

    /**
     * This useEffect is reponsible for what happens in the AuthenticationAdvantages modal.
     */
    useEffect(() => {

        if (
            waitingForAuthentication
            && !history.location.pathname.includes(ROUTES.ConfirmationWord)
            && !history.location.pathname.includes(ROUTES.ConfirmPayment)
        ) {
            // Go to next step if user has clicked on "guest end" and not mandatory
            // Or if user has log in
            if (
                isUserLoggedIn(data.user_authentication_state)
                || (
                    user?.has_clicked_guest_end
                    && !authentication_mandatory
                )
            ) {
                log.info(`User ${user?.uid} has clicked on "guest end" and auth is not mandatory -> simulate click`, {
                    waitingForAuthentication,
                    user,
                    pathname: history.location.pathname,
                });
                onMainButtonPressed();
            }
        }

    }, [waitingForAuthentication, user, data]);

    const availablePaymentTypes = useMemo(() => {

        if (!selectedLocation) {
            return []
        }

        return getAvailablePaymentTypes(
            selectedLocation.supported_payment_types,
            selectedTable.restrictions,
            isTherePaymentData,
            user?.uid ?? "",
        );
    }, [isTherePaymentData, selectedLocation, selectedTable.restrictions, user?.uid]);

    /**
     * Not an exact value because based on the 1st available payment (which is
     * not table) type. More like a guess.
     */
    const shouldGoToSharePaymentPage = useMemo(() => {
        if (!selectedLocation) {
            return false;
        }
        return shouldRedirectToSharePayment(
            orderInState,
            availablePaymentTypes.find((pt) => pt.type !== PaymentType.TABLE)?.type ?? PaymentType.TABLE,  // TODO: Handle this better?
            selectedLocation.country,
            selectedLocation.enable_share_payment,
        );
    }, [orderInState, selectedLocation, availablePaymentTypes]);

    // Dispatch the customer's notes to the store
    const setCustomerNotesToStore = (notes: string): void => {
        // set the notes to the order
        dispatch(OrderAction.setCustomerNotes(notes));
    }

    /**
     * The displayed order cannot be edited
     */
    const isDisplayReadOnly = (): boolean => {
        return Boolean(

            // Showing an order from its ID which is not the one loaded in state
            (orderIdURL !== null && orderIdURL !== orderInState.id)

            // Showing an order identified by its ref (by default, not editable)
            || orderRefURL !== null

            // Showing an order which is not DWP
            || !isOrderStatusDWP(orderInState.status)
        );
    }

    /**
     * Check if the timeslot selected is still available or if asap, 
     * which one is the first available
     */
    const checkExpectedTime = async () => {

        if (selectedLocation && selectedCatalog && selectedTable &&
            (orderInState?.service_type === SupportedServiceType.DELIVERY ||
                orderInState?.service_type === SupportedServiceType.COLLECTION
            )) {

            try {
                const expectedTime = (orderInState && orderInState.expected_time) ? DateTime.fromJSDate(orderInState.expected_time).setZone(getTimezoneName(selectedCatalog)) : undefined;
                const when = expectedTime ? expectedTime.startOf('day') : undefined;
                const timeslotsRes = await loadAndSelectAvailableTimeSlots(
                    expectedTime,
                    selectedLocation,
                    selectedCatalog,
                    selectedTable,
                    orderInState.service_type,
                    orderInState.delivery_zone_ref,
                    when
                )
                log.debug("timeslotsRes", timeslotsRes);

                let expectedTimeToUpdate: Date | undefined = undefined;
                let endPreparationTimeToUpdate: Date | undefined = orderInState.end_preparation_time;
                // If in asap, we move directly to the first available time
                if (orderInState.expected_time_asap && timeslotsRes.first_time_available !== null) {
                    const firstTimeAvailable = DateTime.fromISO(timeslotsRes.first_time_available);
                    const firstDeliveryDelay = timeslotsRes.delivery_delay;
                    expectedTimeToUpdate = firstTimeAvailable.toJSDate();
                    if (orderInState.service_type === SupportedServiceType.DELIVERY) {
                        endPreparationTimeToUpdate = firstTimeAvailable.minus({ minutes: firstDeliveryDelay }).toJSDate();
                    } else {
                        endPreparationTimeToUpdate = new Date(expectedTimeToUpdate);
                    }
                }
                // TODO: open modal to select a new slot if not asap slot is full

                // Now that we have moved to the first available time, we check the delivery availability
                if (orderInState.service_type === SupportedServiceType.DELIVERY) { // TODO: only for uber

                    if (endPreparationTimeToUpdate) {
                        setIsCheckingDelivery(true);

                        // TODO: check available
                        const latitude = parseFloat(orderInState.customer?.latitude || '0');
                        const longitude = parseFloat(orderInState.customer?.longitude || '0');

                        const coordinates: TurfPosition = [longitude, latitude];
                        const checkAvailabilityResult = await checkDeliveryAvailableAPI(
                            coordinates,
                            getAddressFromCustomer(orderInState.customer) ?? undefined,
                            selectedLocation,
                            selectedTable,
                            endPreparationTimeToUpdate
                        );
                        if (checkAvailabilityResult.checked_delivery_zone) {
                            if (checkAvailabilityResult.checked_delivery_zone.estimated_expected_time && checkAvailabilityResult.checked_delivery_zone.estimated_delivery_delay) {
                                expectedTimeToUpdate = checkAvailabilityResult.checked_delivery_zone.estimated_expected_time;
                            }
                            setOpenDeliveryErrorModal(false);
                        } else {
                            setOpenDeliveryErrorModal(true);
                            setUndeliverableReason(checkAvailabilityResult.unavailable_reason || undeliverableReason)
                        }
                        setIsCheckingDelivery(false);
                    } else {
                        log.error(`No end preparation time found in order ${orderInState.id} for service type ${orderInState.service_type}`);
                    }
                }

                if (expectedTimeToUpdate) {
                    // Not in delivery, expected time is equal to endpreparation time
                    dispatch(orderActions.setExpectedTime(expectedTimeToUpdate, undefined, endPreparationTimeToUpdate))
                    setTimeChecked(true)
                }
            } catch (e) {
                log.error(`Error checking timeslot in cart for service type ${orderInState.service_type}, expected time ${orderInState.expected_time}: ${getErrorMessage(e)} (${getErrorStack(e)})`);
            }
        }
    }

    const needsToDisplayShareOrderModal = (order: Order | null | undefined): boolean => {

        return Boolean(
            order
            && getNumberOfContributors(order.contributors) > 1
            && (
                order.status === OrderStatus.DRAFT
                || order.status === OrderStatus.WAITING_SUBMISSION
            )
        )
    }

    const createAlert = (message: string, type: 'error' | 'warning') => {
        setAlert({
            show: true,
            message,
            severity: type
        })
    }

    const handleCouponDeletion = () => {

        if (orderToDisplay?.contributors
            && data.user_authentication_state.user?.uid
            && selectedCatalog?.data.discounts
            && selectedLocation
        ) {

            const foundDiscount = selectedCatalog.data.discounts?.find(discount => discount.coupon_codes?.includes(couponText));
            if (foundDiscount) {
                dispatch(orderActions.removeDiscountFromOrder(
                    foundDiscount,
                    data.user_authentication_state.user.uid,
                    selectedCatalog,
                    selectedLocation
                ));
            }
        }

        setCouponState(undefined);
        setCouponText("");
    }

    /**
     * Method use to check and price the order with the discount if coupon code is valid
     * @param couponCode
     * @param automatic true if the coupon is not checked by a user action (from a useEffect for ex)
     */
    const checkCoupon = (couponCode: string, orderDiscounts: OrderInBase["discounts"], automatic: boolean) => {

        if (!couponCode) {
            log.error(`No coupon code provided, could not check`, { couponCode, automatic });
            return;
        }

        const userId = data.user_authentication_state.user?.uid;
        if (!selectedCatalog || !orderToDisplay || !selectedLocation || !userId) {
            log.warn(`Missing information, cannot apply discount`, {
                catalogId: selectedCatalog?.id,
                orderId: orderToDisplay?.id,
                locationId: selectedLocation?.id,
                userId,
                couponCode,
                automatic,
            });
            return;
        }

        const newCouponState: CouponState = {
            isImported: automatic,
            isValid: false,
            invalidityReason: undefined,
        }

        const catalogDiscounts = selectedCatalog.data.discounts;
        const foundDiscount = catalogDiscounts?.find(discount => discount.coupon_codes?.includes(couponCode));
        if (!foundDiscount) {
            newCouponState.invalidityReason = DiscountInvalidity.DISCOUNT_NOT_FOUND;
            setCouponState(newCouponState);
            return;
        }

        const discountConfig: DiscountConfig = selectedCatalog.discounts ?? {};
        const catalogTimeZone = getTimezoneName(selectedCatalog);
        const isDiscountAvailable = discountService.checkDiscountRestrictionsReason(
            DateTime.now(),
            orderToDisplay,
            userId,
            foundDiscount,
            catalogTimeZone,
            discountConfig,
            selectedCatalog.id!,
            false,
            selectedTable.area_ref,
            orderDiscounts,
        );
        if (isDiscountAvailable.result) {
            newCouponState.isValid = true;
            setCouponState(newCouponState);
            dispatch(orderActions.addDiscountToOrder(foundDiscount, selectedCatalog, selectedLocation, selectedTable, userId));
        }
        else {
            newCouponState.invalidityReason = isDiscountAvailable.reason;
            setCouponState(newCouponState);
        }
    }

    const displayAuthenticationFormIfNeeded = (): boolean => {
        // User is not logged in AND location asks for authentication at the end of the process
        if (
            !isUserLoggedIn(data.user_authentication_state)
            && authentication_process
            && (
                !user?.has_clicked_guest_end
                || authentication_mandatory
            )
            && (
                authentication_process === CustomerInfoFlowChoice.BOTH
                || authentication_process === CustomerInfoFlowChoice.END
            )
        ) {

            // As we have to ask for authentication again, set the has_chosen_to_be_anonymous to false
            dispatch(AuthenticationActions.resetGuestState());
            log.debug(">-OrderSummaryPage-> Need to display authentication form again: resetting the has_chosen_to_be_anonymous state");

            // TODO: why still two different modals
            // Besides, should we just define the next modal to be displayed instead of authenticationOpensShareOrderModal
            setWaitingForAuthentication(true);
            const formParameters: AuthenticationFormParameters = {
            }
            dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
                formParameters,
            ));

            return true;
        }
        return false;
    }

    const isPaymentPossible = (): boolean => {

        if (!selectedLocation || !user?.uid) {
            log.error("No location or userId");
            return false
        }

        const supportedPaymentTypes = selectedLocation?.supported_payment_types ?? []
        const alreadySent = orderService.alreadySent(orderInState)
        const availablePaymentTypes = getAvailablePaymentTypes(
            supportedPaymentTypes,
            selectedTable.restrictions,
            isTherePaymentData,
            user.uid,
            alreadySent,
            fromApp
        );

        if (availablePaymentTypes.length === 0) {
            log.error("No payment type available")
            const message = intl.formatMessage({ id: 'payment.non_available' })
            createAlert(message, 'warning')
            return false
        }

        const minPayment = availablePaymentTypes.reduce((min, p) => {
            if (!p.min_amount) {
                return 0
            }

            return moneyToNumber(p.min_amount) < min ? moneyToNumber(p.min_amount) : min
        }, Number.MAX_SAFE_INTEGER)

        if (moneyToNumber(orderInState.total) < minPayment) {
            log.error("Amount is too small")
            const message = intl.formatMessage(
                { id: 'payment.amount_too_small' },
                { amount: MoneyToStringWithSymbol(numberToMoney(minPayment, selectedLocation.currency)) }
            )
            createAlert(message, 'warning')
            return false
        }

        return true
    }

    /**
     * The logic that will be triggered when the user presses on the button
     * // TODO: make this function testable and test it
     */
    const onMainButtonPressed = () => {

        // Check if the min preparation time is respected
        checkExpectedTime()

        // Check authentication
        if (selectedLocation && orderState && tableLinkId && selectedCatalog && data) {

            const infosRequire = selectedLocation.require_customer_info?.[selectedTable.service_type ?? orderInState.service_type] ?? null;
            const infoNeeded = getCustomerInfoFieldsToAsk(orderInState, infosRequire ?? null, true, user_authentication_state);

            if (orderButtonActionType === OrderSummaryButtonActionTypes.VERIFY_COUPON) {
                checkCoupon(couponText, orderToDisplay?.discounts, false)
                return;
            }

            if (!isPaymentPossible()) {
                return;
            }

            // CONFIRM
            if (orderButtonActionType === OrderSummaryButtonActionTypes.CONFIRM) {

                const displayAuthenticationForm = displayAuthenticationFormIfNeeded();

                // Authentication form is not displayed, we can move forward
                if (!displayAuthenticationForm) {

                    if (needsToDisplayShareOrderModal(orderToDisplay)) {
                        dispatch(orderActions.setMasterUser(data.user_authentication_state.user?.uid));
                        dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                            CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE,
                            null,
                        ));
                    }
                    // ! checkBoxes modal is usefull only on mobile, others device can keep same flow
                    // Do not show the checkboxes modal if there is other information to ask.
                    else if (!hasAcceptedTerms && infoNeeded.length === 0 && mobile_device) {
                        setOpenCheckBoxesModal(true);
                    }
                    else {
                        finalizeOrderInfo(
                            selectedLocation,
                            orderInState,
                            user?.uid ?? "",
                            tableLinkId,
                            selectedTable.restrictions,
                            history,
                            dispatch,
                            selectedCatalog,
                            fromApp,
                            isTherePaymentData,
                            hasAcceptedTerms,
                            data,
                            localLoyaltyUsePoints,
                        );
                    }
                }
            }
            else if (orderToDisplay) {
                // Send added items and pay
                if (orderButtonActionType === OrderSummaryButtonActionTypes.SEND_ADDED_ITEMS) {

                    setIsLoading(true)
                    dispatch(OrderAction.setOrder(orderToDisplay, selectedCatalog, selectedLocation, selectedTable));

                    const displayAuthenticationForm = displayAuthenticationFormIfNeeded();
                    if (!displayAuthenticationForm) {
                        // Authentication form is not displayed, we can move forward

                        finalizeOrderInfo(
                            selectedLocation,
                            orderToDisplay,
                            user?.uid ?? "",
                            tableLinkId,
                            selectedTable.restrictions,
                            history,
                            dispatch,
                            selectedCatalog,
                            fromApp,
                            isTherePaymentData,
                            hasAcceptedTerms,
                            data,
                            localLoyaltyUsePoints,
                        );
                    }
                    // PAY
                } else if (orderButtonActionType === OrderSummaryButtonActionTypes.PAY) {

                    // Set the order as the current order
                    dispatch(OrderAction.setOrder(orderToDisplay, selectedCatalog, selectedLocation, selectedTable));

                    const displayAuthenticationForm = displayAuthenticationFormIfNeeded();
                    if (!displayAuthenticationForm) {
                        // Authentication form is not displayed, we can move forward

                        if (!hasAcceptedTerms && infoNeeded.length === 0 && mobile_device) {
                            setOpenCheckBoxesModal(true);
                        }
                        else {
                            finalizeOrderInfo(
                                selectedLocation,
                                orderToDisplay,
                                user?.uid ?? "",
                                tableLinkId,
                                selectedTable.restrictions,
                                history,
                                dispatch,
                                selectedCatalog,
                                fromApp,
                                isTherePaymentData,
                                hasAcceptedTerms,
                                data,
                                localLoyaltyUsePoints,
                            );
                        }
                    }
                }

                // REORDER
                else if (orderButtonActionType === OrderSummaryButtonActionTypes.REORDER) {
                    if (!selectedLocation.orders?.allow_edit_opened_orders) {
                        // Reset the order in state
                        dispatch(OrderAction.resetOrder());
                    }

                    // Load the items
                    dispatch(OrderAction.addItems(
                        orderToDisplay.items,
                        selectedCatalog,
                        selectedLocation,
                        selectedTable,
                    ));

                    // Redirect to summary without the old order ID
                    history.push(`/${tableLinkId}${OrderSummary}`);
                }

                // LOAD
                else if (orderButtonActionType === OrderSummaryButtonActionTypes.LOAD) {

                    // It's not the one in the state so we load it
                    if (orderInState.id !== orderToDisplay.id) {
                        if (data.user_authentication_state.user?.uid) {
                            if (!orderService.checkIfUserIsAContributor(
                                orderInState,
                                data.user_authentication_state.user.uid
                            )) {
                                dispatch(OrderAction.addContributor(
                                    data.user_authentication_state.user.uid,
                                    data.user_authentication_state.user
                                ));
                            }
                        }

                        dispatch(orderActions.setOrder(orderToDisplay, selectedCatalog, selectedLocation, selectedTable));
                        // Resetting the loyalty master user
                        if (data.user_authentication_state.user?.uid) {
                            dispatch(orderActions.setLoyaltyUserId(data.user_authentication_state.user.uid));
                        }
                    }

                    // We remove the orderIdURL so that we can edit
                    history.push(`/${tableLinkId}${OrderSummary}`);
                }

                // BACK TO CATALOG
                else if (orderButtonActionType === OrderSummaryButtonActionTypes.BACK_TO_CATALOG) {
                    history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: tableLinkId }))
                }
            }
        }
    };

    const addAndGoBackToCatalog = () => {
        if (orderButtonActionType === OrderSummaryButtonActionTypes.SEND_ADDED_ITEMS) {
            setIsLoading(true)
            dispatch(OrderAction.sendAddedItems(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: tableLinkId })));
        }
    }

    const displaySuggestion = () => {
        if (orderButtonActionType === OrderSummaryButtonActionTypes.VERIFY_COUPON) {
            checkCoupon(couponText, orderToDisplay?.discounts, false)
            return
        }

        if (suggestionListToAdd) {
            setSuggestionModal(true)
        }
    }

    const closeDisclaimer = () => {
        setDiscalimerOpen(false);
    };

    // Handle suggestion products
    // TODO: move all suggestion related stuff to a separate component
    const onSuggestionAdd = (skuRef: string, suggestionList: SuggestionListToAdd[]) => {
        const newSuggestionList = [...suggestionList]
        const suggestionToAddIndex = newSuggestionList.findIndex(suggestion => suggestion.suggestion.ref === skuRef)
        if (suggestionToAddIndex !== -1) {
            newSuggestionList[suggestionToAddIndex].quantity += 1
            setSuggestionListToAdd(newSuggestionList)
        } else {
            log.error(`Suggestion for sku ${skuRef} not found`)
        }
    }

    const onSuggestionMinus = (skuRef: string, suggestionList: SuggestionListToAdd[]) => {
        const newSuggestionList = [...suggestionList]
        const suggestionToAddIndex = newSuggestionList.findIndex(suggestion => suggestion.suggestion.ref === skuRef)
        if (suggestionToAddIndex !== -1) {
            if (newSuggestionList[suggestionToAddIndex].quantity > 0) {
                newSuggestionList[suggestionToAddIndex].quantity -= 1
                setSuggestionListToAdd(newSuggestionList)
            } else {
                log.info(`${skuRef} quantity = 0`)
            }
        } else {
            log.error(`Suggestion for sku ${skuRef} not found`)
        }
    }

    const onSuggestionOptionChange = (skuRef: string, suggestionList: SuggestionListToAdd[], orderOptions: OrderOption[]) => {

        const newSuggestionList = [...suggestionList]
        const suggestionToAddIndex = newSuggestionList.findIndex(suggestion => suggestion.suggestion.ref === skuRef)

        if (suggestionToAddIndex !== -1) {
            newSuggestionList[suggestionToAddIndex].orderOptions = orderOptions;
            setSuggestionListToAdd(newSuggestionList);
        }
        else {
            log.error(`Suggestion for sku ${skuRef} not found`);
        }
    }

    const onSuggestionOrderValidation = (suggestionList: SuggestionListToAdd[]) => {
        const listItemsToAdd: ListItemsToAdd[] = [];
        suggestionList.forEach((suggestion) => {
            if (suggestion.quantity) {
                const item: ListItemsToAdd = {
                    product_ref: suggestion.suggestion.product_ref,
                    sku_ref: suggestion.suggestion.ref,
                    quantity: suggestion.quantity,
                    options: suggestion.orderOptions ? suggestion.orderOptions : []
                }
                listItemsToAdd.push(item)
            }
        })
        if (selectedCatalog && selectedLocation) {
            if (listItemsToAdd.length) {
                dispatch(OrderAction.addItems(listItemsToAdd, selectedCatalog, selectedLocation, selectedTable));
            }
            setSuggestionModal(false)
            onMainButtonPressed()
        }
    }

    /**
     * Decide if the button is enabled or disabled. Also sets
     * the warning message
     */
    const isOrderButtonEnabled = (): [boolean, string | undefined] => {

        if (!selectedCatalog) {
            return [false, undefined];
        }

        // No order to display
        if (!orderToDisplay) {
            log.debug("Button disabled: no order to display");
            return [false, undefined];
        }

        // No items
        if (orderToDisplay.items.length === 0) {
            log.debug("Button disabled: no items");
            return [false, undefined];
        }


        // The order has a different table id, or catalog id than the location
        if (
            orderToDisplay.table_id !== selectedTable.id
            || orderToDisplay.catalog_id !== selectedCatalog.id
        ) {
            // TODO: check if still valid for other actions
            if (orderButtonActionType !== OrderSummaryButtonActionTypes.PAY) {
                log.debug("Button disabled: table id or catalog id different");
                return [false, intl.formatMessage({ id: "orders.draft.denied" })];
            }
        }

        // CONFIRM
        if (orderButtonActionType === OrderSummaryButtonActionTypes.CONFIRM) {

            const minAmountIfNotReached = getMinOrderAmountIfNotReached(
                orderToDisplay,
                selectedCatalog,
            );

            // Delivery and no address
            if (orderToDisplay.service_type === SupportedServiceType.DELIVERY
                && (
                    !orderToDisplay.customer
                    || !orderToDisplay.customer.country
                )
            ) {

                log.debug("Button disabled: no address & delivery");
                return [false, undefined];
            }

            // Min order amount is not reached
            if (minAmountIfNotReached) {
                log.debug("Button disabled: min order amount not reached");
                return [false,
                    intl.formatMessage({
                        id: "Order.cart.error_below_minimum_amount"
                    }, {
                        min_amount: MoneyToStringWithSymbol(minAmountIfNotReached)
                    })
                ];
            }
        }

        // TODO: Add_ITEM ?

        // PAY
        if (orderButtonActionType === OrderSummaryButtonActionTypes.PAY && selectedLocation) {
            if (!paymentHelper.canPay(selectedLocation, orderToDisplay)) {
                log.debug("Button disabled: canPay says it's not possible to pay it");
                return [false, undefined];
            }
        }

        // LOAD OR REORDER
        if (
            orderButtonActionType === OrderSummaryButtonActionTypes.REORDER
            || orderButtonActionType === OrderSummaryButtonActionTypes.LOAD
        ) {

            if (orderToDisplay.service_type !== selectedTable.service_type) {
                log.debug("Button disabled: order service type != table service type");
                let message = (selectedTable.service_type !== SupportedServiceType.CHECKOUT &&
                    selectedTable.service_type !== SupportedServiceType.VIEW) ? intl.formatMessage({ id: "orders.draft.denied" }) : undefined
                return [false, message];
            }

            // Check if the order is in the user latest orders
            let foundInUserLatestOrders: boolean = false;
            for (const elem of initOrdersState.latestUserOrders) {
                if (elem.id === orderToDisplay.id) {
                    foundInUserLatestOrders = true;
                }
            }

            // If it's not in the user latest orders, we don't allow to load it
            if (
                !foundInUserLatestOrders
                && selectedLocation?.orders?.webapp_table_orders !== WebappTableOrders.FORCE_LOAD
            ) {
                log.debug("Button disabled: location does not allow loading table orders");
                return [false, undefined];
            }
        }

        // BACK TO CATALOG
        if (orderButtonActionType === OrderSummaryButtonActionTypes.BACK_TO_CATALOG) {
            return [true, intl.formatMessage({ id: "Summary.all_items_already_sent" })];
        }

        // If no condition was verified, the button is enabled
        return [true, undefined];
    }

    const getSuggestionProductCard = (suggestion: SuggestionListToAdd): JSX.Element | null => {

        if (!selectedLocation || !selectedCatalog || !selectedTable) {
            log.error("Missing location or catalog or table");
            return null;
        }

        let product: ProductExtended | null = null

        if (suggestion.suggestion.product) {
            product = suggestion.suggestion.product
        } else {
            const productSeek = selectedCatalog.data.products.find(pdt => pdt.ref === suggestion.suggestion.product_ref)
            if (productSeek) {
                product = productSeek
            }
        }

        if (product) {
            const sku: Sku | undefined = product.skus.find(sk => sk.ref === suggestion.suggestion.ref)

            if (sku) {
                return (
                    <Cards
                        item={product}
                        openModal={() => log.debug('OrderSummaryPage suggestionProductCard openModal onClick')}
                        quickAddIcon
                        onQuickAddIconClick={() => {
                            if (product) {
                                dispatch(orderActions.addItem(
                                    product.ref,
                                    sku.ref,
                                    [],
                                    selectedCatalog,
                                    selectedLocation,
                                    selectedTable,
                                    product.name,
                                    1,
                                    sku.price,
                                    "",
                                    undefined
                                ));
                            }
                        }}
                    />
                )
            } else {
                log.error(`Sku ${suggestion.suggestion.ref} of product ${product.ref} not found`)
                return null
            }
        } else {
            log.error(`Product of suggestion ${suggestion.suggestion.ref} not found in catalog ${selectedCatalog.ref}`)
            return null
        }
    }

    const forceLoadOnValidation = useMemo((): boolean => {

        if (!selectedLocation) {
            log.error("Missing location");
            return false;
        }

        if (availablePaymentTypes.length === 0) {
            log.error("No payment type available")
            return false;
        }

        if (availablePaymentTypes.length === 1) {
            return !shouldGoToSharePaymentPage;
        }

        return false;

    }, [availablePaymentTypes.length, selectedLocation, shouldGoToSharePaymentPage]);

    if (!selectedLocation || !selectedCatalog) {
        return null
    }

    // Warning => if suggestion are in mode only_not_in_order they will be disappear if on is selected 
    return (
        isLoading
            ? <LoaderComponent />
            : <>
                {mobile_device && <Dialog
                    fullWidth
                    open={openCheckBoxesModal}
                    onClose={() => setOpenCheckBoxesModal(false)}
                    PaperProps={{
                        style: mobile_device ? { ...mobilePaperProps, height: "unset", borderRadius: "15px 15px 0 0 " } : {
                            backgroundColor: theme.palette.background.paper,
                            width: SMALL_DIALOG_WIDTH,
                            maxWidth: "unset",
                        },
                    }}
                >
                    <ServicePickUpInfo
                        onClearIconClick={() => setOpenCheckBoxesModal(false)}
                        forceLoadOnValidation={forceLoadOnValidation}
                        isDisplayedInDialog
                    />
                </Dialog>}
                {
                    openDeliveryErrorModal &&
                    <Box
                        height="100%"
                    >
                        <DeliveryErrorModal
                            open={openDeliveryErrorModal}
                            retryCheckAvailable={checkExpectedTime}
                            isCheckingDelivery={isCheckingDelivery}
                            undeliverableReason={undeliverableReason}
                        />
                    </Box>
                }

                <Box
                    display="flex"
                    minHeight="100%"
                    style={{ backgroundColor: mobile_device ? 'default' : 'paper' }}
                >
                    {suggestionListToAdd
                        && tablet_device
                        ? (
                            <Box display="flex" flexDirection="column" alignItems="center" borderRight={`${theme.palette.divider} solid 1px`}>
                                <Box p={2}>
                                    <Typography variant="h2" style={{ color: theme.palette.primary.main }}>
                                        <FormattedMessage id="order.order_summary.suggestion.title" />
                                    </Typography>
                                </Box>
                                <Box width="320px" display="flex" flexWrap="wrap" alignItems="flex-start" justifyContent="flex-start">
                                    {suggestionListToAdd.map((suggestion: SuggestionListToAdd, index: number) =>
                                        <Box key={index}>
                                            {getSuggestionProductCard(suggestion)}
                                        </Box>
                                    )
                                    }
                                </Box>
                            </Box>)
                        : ""}

                    <Box
                        display="flex"
                        flexDirection="column"
                        style={{ backgroundColor: desktop_device ? theme.palette.background.paper : undefined }}
                        minHeight="100%"
                        flex={1}
                    >

                        <Disclaimer
                            open={discalimerOpen}
                            onYes={() => finalizeOrderInfo(
                                selectedLocation,
                                orderInState,
                                user?.uid ?? "",
                                tableLinkId,
                                selectedTable.restrictions,
                                history,
                                dispatch,
                                selectedCatalog,
                                fromApp,
                                isTherePaymentData,
                                hasAcceptedTerms,
                                undefined,
                                localLoyaltyUsePoints,
                            )}
                            onNo={() => closeDisclaimer()}
                        />

                        { // SUGGESTIONS
                            suggestionListToAdd
                                && suggestionModal
                                && !tablet_device
                                && !isDisplayReadOnly()
                                ?
                                (
                                    <SuggestionModal
                                        onOrderValidation={onSuggestionOrderValidation}
                                        onSuggestionAdd={onSuggestionAdd}
                                        onSuggestionMinus={onSuggestionMinus}
                                        open={suggestionModal}
                                        suggestionListToAdd={suggestionListToAdd}
                                        onClose={() => setSuggestionModal(false)}
                                        onSuggestionOptionChange={onSuggestionOptionChange}
                                    />
                                )
                                : ""
                        }

                        {
                            // HEADER
                        }
                        <HideOnScroll>
                            <Box>
                                <OrderHeader
                                    readOnly={isDisplayReadOnly()}
                                    closeIcon={!desktop_device}
                                    accountButton={!desktop_device}
                                    titleId={intl.formatMessage({
                                        id: "Summary.myCart",
                                        defaultMessage: "Mon Panier"
                                    })}
                                    backgroundColor={desktop_device ? theme.palette.background.paper : undefined}
                                    fromSummaryPage
                                    orderToDisplay={orderToDisplay}
                                />
                            </Box>

                        </HideOnScroll>

                        {!atLeastOneNewItem && !atLeastOneOldItem

                            // If no item in the cart
                            ? <EmptyCart />
                            : <Box>

                                <OrderList
                                    desktop={desktop_device}
                                    orderToDisplay={orderToDisplay}
                                    readOnly={isDisplayReadOnly() && orderButtonActionType !== OrderSummaryButtonActionTypes.SEND_ADDED_ITEMS}
                                    atLeastOneNewItem={atLeastOneNewItem}
                                    atLeastOneOldItem={atLeastOneOldItem}
                                    dealList={dealList}
                                    productList={productList}
                                />

                                {
                                    /* CUSTOMER NOTES */
                                    (
                                        (!isDisplayReadOnly() && customerNotesAllowed)
                                        || (isDisplayReadOnly() && orderToDisplay?.customer_notes)
                                    )
                                    &&

                                    <Box
                                        mt={2}
                                        py={1}
                                        px={3}
                                        bgcolor='white'>
                                        <TextField
                                            placeholder={intl.formatMessage({ id: 'Order.cart.customer_notes.add_note' })}
                                            fullWidth={true}
                                            multiline
                                            onBlur={() => setCustomerNotesToStore(customerNotes)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" >
                                                        <CommentOutlinedIcon style={{ color: theme.palette.grey[500] }} />
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true
                                            }}
                                            value={isDisplayReadOnly() ? orderToDisplay?.customer_notes : customerNotes}
                                            onChange={(e) => {
                                                setCustomerNotes(e.target.value)
                                            }}
                                            disabled={isDisplayReadOnly()}
                                        />
                                    </Box>
                                }

                                {
                                    /* COUPONS */
                                    !isDisplayReadOnly() &&

                                    <CouponTextField
                                        coupon={couponText}
                                        handleCoupon={(s: string) => {
                                            setCouponText(s);
                                            setCouponState(undefined);
                                        }}
                                        isCouponInvalid={Boolean(couponState && !couponState.isValid)}
                                        handleCouponDeletion={handleCouponDeletion}
                                        isCouponVerified={Boolean(couponState)}
                                        discountInvalidityReason={couponState?.invalidityReason ?? DiscountInvalidity.INVALID_COUPON_CODE}
                                    />
                                }

                                {
                                    /* LOYALTY */

                                    (isDisplayReadOnly() || isUserLoggedIn(user_authentication_state)) &&

                                    <LoyaltySummary
                                        selectedOrder={orderToDisplay}
                                        readOnly={isDisplayReadOnly()}
                                        orderButtonActionType={orderButtonActionType}
                                    />
                                }

                                {
                                    // EMPTY CONTAINER FOR THE FOOTER NOT TO OVERLAP ON CONTENT
                                    <Box height={orderBottomHeight}></Box>
                                }

                                {
                                    // FOOTER
                                }
                                <Box
                                    width={1}
                                    mt={mobile_device ? 2 : 0}
                                    style={
                                        mobile_device
                                            ? { position: 'relative', bottom: 0 }
                                            : { position: 'sticky', bottom: 0 }
                                    }>

                                    <OrderBottom
                                        disableButton={!isOrderButtonEnabled()[0]}
                                        hideShareButtonOnDesktop={isDisplayReadOnly()}
                                        atLeastOneOldItem={atLeastOneOldItem}
                                        atLeastOneNewItem={atLeastOneNewItem}
                                        allowMarketing={allowMarketing}
                                        setAllowMarketing={setAllowMarketing}
                                        selectedOrder={orderToDisplay}
                                        orderButtonActionType={orderButtonActionType}
                                        onPress={
                                            suggestionListToAdd && !tablet_device && !isDisplayReadOnly()
                                                ? displaySuggestion
                                                : onMainButtonPressed
                                        }
                                        warningMessage={isOrderButtonEnabled()[1]}
                                        remainingAmount={
                                            orderToDisplay && orderToDisplay !== undefined
                                                ? !paymentHelper.isPartOfTheOrderPaid(orderToDisplay)
                                                    ? undefined
                                                    : numberToMoney(
                                                        paymentHelper.getOrderRemainingAmount(orderToDisplay),
                                                        getCurrency(orderToDisplay.total)
                                                    )
                                                : undefined
                                        }
                                        readOnly={isDisplayReadOnly()}
                                        orderBottomRef={orderBottomRef}
                                        updateOrderBottomHeight={(height: number) => setOrderBottomHeight(height)}
                                        addItemAndGoBack={addAndGoBackToCatalog}
                                        shouldGoToSharePaymentPage={shouldGoToSharePaymentPage}
                                    />

                                </Box>

                            </Box>
                        }

                        {alert.show
                            ? <Alert
                                severity={alert.severity}
                                onClose={() => {
                                    setAlert({ ...alert, show: false });
                                }}
                                sx={{ position: 'fixed', top: 10, left: 10, right: 10, zIndex: 1000 }}
                            >
                                <AlertTitle>{intl.formatMessage({ id: alert.severity })}</AlertTitle>
                                {alert.message}
                            </Alert>
                            : null
                        }
                    </Box>
                </Box>
            </>
    );

};

export default OrderSummaryPage;
