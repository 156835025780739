import { OrderDiscount } from "../models/OrderDiscount"

export function getBaseDiscountPriceOff(currency: string) {
    return `0.00 ${currency}`
}

export function getDefaultOrderDiscount(ref: string, name: string, currency: string, userId: string) {

    const defaultOrderDiscount: OrderDiscount = {
        ref: ref,
        name: name,
        user_id: userId,
        price_off: getBaseDiscountPriceOff(currency)
    }

    return defaultOrderDiscount
}