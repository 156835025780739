import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AuthenticationFormParameters from '../../authentication/models/AuthenticationFormParameters';
import AuthenticationActions from '../../authentication/redux/AuthenticationActions';
import * as ROUTES from '../../config/routes';
import { desktopDisplayBreakpoint } from '../../config/theme';
import { CustomerInformationModalFormToDisplay } from '../../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../../customers/redux/CustomerInformationModalActions';
import { SupportedPayementType } from '../../my-lemonade-library/model/Location';
import { Order, OrderCharge, OrderChargeType, PaymentType } from '../../my-lemonade-library/model/Order';
import { addMoney, getCurrency, Money, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { AutoId } from '../../my-lemonade-library/src/common/services/AutoId';
import { log } from '../../my-lemonade-library/src/common/services/LogService';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { SupportedPaymentTypeExtended } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { PaymentTypeUnavailabilityReason } from '../../my-lemonade-library/src/payments/models/PaymentTypeUnavailabilityReason';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import OrderHeader from '../../orders/components/OrderHeader';
import orderActions from '../../orders/redux/OrderActions';
import { getAvailablePaymentTypes, sendOrPayOrder, shouldRedirectToSharePayment } from '../../orders/services/OrderService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import PaymentCard from '../components/PaymentCard';
import StripePaymentRequestButton from '../components/StripePaymentRequestButton';
import { stripeService } from '../components/StripeRoot';
import TipModal from "../components/TipModal";
import WalletPaymentCard from '../components/WalletPaymentCard';
import useTipConfiguration, { getTipConfiguration } from '../hooks/useTipConfiguration';

interface PaymentChoiceProps { }

const PaymentChoice: React.FC<PaymentChoiceProps> = (props) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();

    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint))

    const { order } = useTypedSelector((state: RootState) => state.order)
    const { selectedLocation, tableLinkId, selectedTable, selectedCatalog, fromApp } = useTypedSelector((state: RootState) => state.locations)
    const { data, login } = useTypedSelector((state: RootState) => state.authentication);
    const userId = data.user_authentication_state.user?.uid;
    const { use_points: localLoyaltyUsePoints } = useTypedSelector((state: RootState) => state.loyalty);
    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);
    const { connector_wallet } = useTypedSelector((state: RootState) => state.payment);
    const walletBalance = selectedLocation?.connector?.type ? connector_wallet[selectedLocation.connector.type]?.wallet_balance ?? undefined : undefined
    const { navigation_history_urls } = useTypedSelector((state: RootState) => state.common);

    const [alreadyPaidAmount, setAlreadyPaidAmount] = useState<Money>();
    const [amountToPay, setAmountToPay] = useState<Money>();
    const [pendingLoginPaymentType, setPendingLoginPaymentType] = useState<PaymentType | null>();
    const [availablePaymentTypes, setAvailablePaymentTypes] = useState<SupportedPaymentTypeExtended[]>([]);
    const [openTipModal, setOpenTipModal] = useState<boolean>(false)
    const [paymentTypeSelected, setPaymentTypeSelected] = useState<SupportedPaymentTypeExtended | null>()

    const { tip, askForTip } = useTipConfiguration(paymentTypeSelected?.type ?? undefined);
    const orderToCreditWallet = selectedLocation ? paymentHelper.isOrderToCreditWallet(selectedLocation, order) : false;

    /**
    * Charge to add in order
    */
    const [charge, setCharge] = useState<OrderCharge>()

    /**
     * Prevent going back to paymentChoice after sending an order (confirmation page) and
     * when canPay of this order says false.
     * If the user did a goBack and the conditions are met, we goForward.
     */
    useEffect(() => {

        const latestPagePathname = navigation_history_urls.length > 1 ? navigation_history_urls[navigation_history_urls.length - 2] : undefined;

        if (
            selectedLocation
            && latestPagePathname
            && (
                latestPagePathname.includes(ROUTES.OrderConfirmPaymentPage)
                || latestPagePathname.includes(ROUTES.OrderConfirmationPage)
            )
            && !paymentHelper.canPay(selectedLocation, order)
        ) {
            log.info(`PaymentChoice: last page was confirmation and we can't pay -> goForward!`, latestPagePathname)
            history.goForward();
        }
    }, [history, navigation_history_urls, order, selectedLocation]);

    /**
     * // TODO: why here? Why in a useEffect?
     */
    useEffect(() => {
        if (!charge && selectedCatalog) {
            const newCharge: OrderCharge = {
                id: AutoId.newId("", 28),
                type: OrderChargeType.TIP,
                contributor_id: data.user_authentication_state.user?.uid,
                name: OrderChargeType.TIP,
                price: `0.00 ${selectedCatalog.currency}`
            }
            setCharge(newCharge)
        }
    }, [charge, data.user_authentication_state])

    useEffect(() => {
        if (pendingLoginPaymentType) {
            // The user is now logged in, continue to send order
            if (!data.user_authentication_state.is_anonymous) {
                sendOrPayOrder(
                    dispatch,
                    order,
                    data.user_authentication_state.user?.uid ?? "",
                    pendingLoginPaymentType,
                    amountToPay,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    selectedLocation?.enable_share_payment,
                    localLoyaltyUsePoints,
                );
                setPendingLoginPaymentType(null)
            }
        }
    }, [pendingLoginPaymentType, data.user_authentication_state.is_anonymous]);

    useEffect(() => {
        if (order && selectedLocation && userId) {

            // The amount to pay depends on the potential previous payments 
            const newAmountToPay = numberToMoney(
                paymentHelper.getOrderRemainingAmount(order),
                getCurrency(order.total)
            );
            setAmountToPay(newAmountToPay);

            const newAlreadyPaidAmount = numberToMoney(
                paymentHelper.getOrderPaidAmount(order, false),
                getCurrency(order.total),
            );
            setAlreadyPaidAmount(newAlreadyPaidAmount);

            setAvailablePaymentTypes(getAvailablePaymentTypes(
                selectedLocation.supported_payment_types,
                selectedTable.restrictions,
                isTherePaymentData,
                userId,
                !orderService.isFullyEditable(order),
                fromApp
            ));
        }
    }, [order, selectedLocation, userId, isTherePaymentData]);

    const goToPayment = (elem: SupportedPaymentTypeExtended, order: Order, tipCharge: OrderCharge = charge!) => {
        // if (elem.type === PaymentType.ADYEN) {
        //     history.push(`/${tableLinkId}${ROUTES.PaymentAdyen}`);
        // }

        // If (not authenticated or anonymous) AND edenred payment
        // display the login popup

        if (
            (
                elem.type === PaymentType.EDENRED
                || elem.type === PaymentType.SWILE
            )
            && (!login || data.user_authentication_state.is_anonymous)
        ) {
            setPendingLoginPaymentType(elem.type);

            // Reset the has_chosen_to_be_anonymous state
            dispatch(AuthenticationActions.resetGuestState());

            const formParams: AuthenticationFormParameters = {
                forceMandatory: true,
                hideIncentives: true,
                forPayment: true,
            }
            dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                CustomerInformationModalFormToDisplay.AUTHENTICATION_ADVANTAGES,
                formParams,
            ));
        } else {
            if (shouldRedirectToSharePayment(order, elem.type, selectedLocation?.country, selectedLocation?.enable_share_payment)) {
                dispatch(orderActions.setPaymentType(elem.type))
                history.push(ROUTES.getPaymentShareItemsFullRoute(tableLinkId))

            } else {
                let finalAmountToPay = amountToPay
                setPendingLoginPaymentType(null);
                const isTipChargeToAdd = tipCharge && moneyToNumber(tipCharge.price) > 0
                if (amountToPay && selectedLocation && selectedCatalog && isTipChargeToAdd) {
                    // dispatch(orderActions.setCharge(tipCharge, selectedCatalog, selectedLocation))
                    finalAmountToPay = addMoney(amountToPay, tipCharge.price)
                    log.debug(`Add ${tipCharge.price} to aumount to pay ${amountToPay} as tip for a total of ${finalAmountToPay}`)
                }
                sendOrPayOrder(
                    dispatch,
                    order,
                    data.user_authentication_state.user?.uid ?? "",
                    elem.type,
                    finalAmountToPay,
                    undefined,
                    undefined,
                    undefined,
                    isTipChargeToAdd ? tipCharge : undefined,
                    selectedLocation?.enable_share_payment,
                    localLoyaltyUsePoints,
                );
            }
        }
    }

    const onPaymentClick = (elem: SupportedPaymentTypeExtended) => {

        const tipConfig = getTipConfiguration(selectedLocation, selectedTable, order, elem.type);

        if (
            tipConfig.tip
            && tipConfig.askForTip
            && elem.type !== PaymentType.TABLE
            && !shouldRedirectToSharePayment(order, elem.type, selectedLocation?.country, selectedLocation?.enable_share_payment)
            && !orderToCreditWallet
        ) {
            setPaymentTypeSelected(elem)
            setOpenTipModal(true)
        } else {
            goToPayment(elem, order)
        }
    }

    const isDisabled = (paymentConfigParam: SupportedPaymentTypeExtended): PaymentTypeUnavailabilityReason | boolean | null => {

        // Casting to standard payment config because it will be retrieved just below
        let paymentConfigToUse = paymentConfigParam as SupportedPayementType;

        // Get the "standard" payment type params if the current one is a specific one
        const { newPaymentType: paymentTypeToFind } = paymentHelper.convertSpecificPaymentTypeToStandardWithOptions(paymentConfigParam.type, {});

        const foundConfig = selectedLocation?.supported_payment_types.find((pt) => pt.type === paymentTypeToFind);
        if (!foundConfig) {
            log.error(`PaymentChoice: Payment type ${paymentTypeToFind} not found in supported_payment_types`);
            return PaymentTypeUnavailabilityReason.NOT_FOUND;
        }
        paymentConfigToUse = foundConfig;

        // Check for "global" availability
        const isAvailable = paymentHelper.isPaymountAmountValidForPaymentType(
            paymentConfigToUse,
            moneyToNumber(amountToPay ?? order.total),
            shouldRedirectToSharePayment(order, paymentConfigToUse.type, selectedLocation?.country, selectedLocation?.enable_share_payment),
        );

        if (!isAvailable.available) {
            switch (isAvailable.unavailable_reason) {
                case "min_amount": return PaymentTypeUnavailabilityReason.MIN_AMOUNT;
                case "max_amount": return PaymentTypeUnavailabilityReason.MAX_AMOUNT;
                case "not_found": return PaymentTypeUnavailabilityReason.NOT_FOUND;
            }
        }
        if (
            paymentConfigToUse.type === PaymentType.CONNECTOR_WALLET
            && (
                !walletBalance
                || moneyToNumber(walletBalance) < moneyToNumber(order.total)
            )
        ) {
            return true
        }
        // No table payment for an order which has already been sent to the API (pending payment, new, ...)
        if (
            paymentConfigToUse.type === PaymentType.TABLE
            && (
                !orderService.isFullyEditableStatus(order.status)
                || orderToCreditWallet
            )
        ) {
            return true;  // Unavailable
        }

        return null;  // Available
    }

    const getPaidProvider = (order: Order): string | undefined => {

        if (!order.payments || order.payments.length === 0) {
            return undefined;
        }

        const paymentTypes = order.payments.map(p => p.payment_type);
        const uniquePaymentTypesSet = new Set(paymentTypes);
        const uniquePaymentTypes = Array.from(uniquePaymentTypesSet);

        if (uniquePaymentTypes.length !== 1) {
            return undefined;
        }

        let finalString = "";
        const paymentType = uniquePaymentTypes[0];
        switch (paymentType) {
            case PaymentType.EDENRED: finalString = "Edenred"; break;
            default: return undefined;
        }

        if (finalString) {
            return ` (${finalString})`;
        }
    }

    if (!selectedLocation) {
        return null
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            width={1}
            height={1}
        >

            {tip && askForTip && paymentTypeSelected && amountToPay && !orderToCreditWallet &&
                <TipModal
                    open={openTipModal}
                    tip={tip}
                    onFinalAction={(charge?: OrderCharge) => goToPayment(paymentTypeSelected, order, charge)}
                    currency={selectedLocation.currency}
                    paymentAmount={amountToPay}
                    charge={charge}
                    onChargeChange={setCharge}
                />
            }

            <OrderHeader
                titleId={intl.formatMessage({ id: "Payment.choix" })}
                closeIcon
                hideTable
                readOnly
            />

            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                width={1}
                style={{
                    backgroundColor: isDesktopRender ? theme.palette.background.paper : theme.palette.background.default
                }}
            >

                {alreadyPaidAmount && moneyToNumber(alreadyPaidAmount) > 0
                    && amountToPay && moneyToNumber(amountToPay) > 0
                    &&
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={.5}
                        bgcolor={theme.palette.background.paper}
                        padding={2}
                    >
                        <Typography>
                            {intl.formatMessage(
                                { id: "payment.paymentChoice.multiple_types.already_paid" },
                                {
                                    provider: getPaidProvider ?? "",
                                    amount: MoneyToStringWithSymbol(alreadyPaidAmount),
                                }
                            )}
                        </Typography>

                        <Typography style={{ fontWeight: "bold" }}>
                            {intl.formatMessage(
                                { id: "payment.paymentChoice.multiple_types.remaining" },
                                { amount: MoneyToStringWithSymbol(amountToPay) }
                            )}
                        </Typography>
                    </Box>
                }

                <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                    paddingX={isDesktopRender ? 5 : 2}
                    paddingBottom={isDesktopRender ? 5 : 2}
                    paddingTop={isDesktopRender ? 5 : 0}
                    data-test="payment-choice.root"
                    gap={2}
                >

                    <Box>
                        <Typography variant="caption">
                            {amountToPay && intl.formatMessage(
                                { id: "payment.paymentChoice.chosePaymentType" },
                                { total: MoneyToStringWithSymbol(amountToPay) }
                            )}
                        </Typography>
                    </Box>

                    {(stripeService && false) && <StripePaymentRequestButton amountToPay={amountToPay} />}

                    {availablePaymentTypes.map((elem, i) => {
                        if (((elem.type !== PaymentType.TABLE && elem.type !== PaymentType.CONNECTOR_WALLET) || (orderService.isFullyEditable(order) && elem.type !== PaymentType.CONNECTOR_WALLET))) {
                            return (
                                <PaymentCard
                                    key={i}
                                    paymentType={elem}
                                    allPaymentTypes={availablePaymentTypes}
                                    disabled={isDisabled(elem)}
                                    onClick={() => onPaymentClick(elem)}
                                    serviceType={selectedTable.service_type}
                                    leftToPay={amountToPay ?? order.total}
                                />
                            )
                        } else if (elem.type === PaymentType.CONNECTOR_WALLET && !orderToCreditWallet) {
                            return (
                                <Box>
                                    <WalletPaymentCard
                                        key={i}
                                        paymentType={elem}
                                        allPaymentTypes={availablePaymentTypes}
                                        disabled={isDisabled(elem)}
                                        onClick={() => onPaymentClick(elem)}
                                        serviceType={selectedTable.service_type}
                                        leftToPay={amountToPay ?? order.total}
                                    />
                                </Box>
                            )
                        }
                        return null
                    })}
                </Box>
            </Box>
        </Box>
    );
}

export default PaymentChoice;
