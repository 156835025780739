import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Add, ArrowBackIos } from "@mui/icons-material";
import { Box, Button, CircularProgress, Icon, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import lookup from 'country-code-lookup';
import log from "loglevel";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import MatGeocoder from "react-mui-mapbox-geocoder";
import { DELIVERY_MODAL } from "../../Common/configs/Zindex";
import { desktopDisplayBreakpoint } from "../../config/theme";
import { DeliveryOrderExpectation } from "../../customers/models/OrderExpectation";
import Position from "../../my-lemonade-library/src/common/models/Position";
import DeliveryUnavailableReason from "../../my-lemonade-library/src/delivery/models/DeliveryUnavailableReason";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { checkDeliveryAvailableAPI } from "../helpers/DeliveryHelpers";
import { getAddressFromMapboxResult, getNewAddressPlaceHolder } from "../services/DeliveryServices";


// TODO: Apply Anais design with a background in grey and white cases
interface DeliveryAddressTextFieldProps {
    onPreviousClick: () => void;
    onNewAddressEntered?: (deliveryAddressExpectation: DeliveryOrderExpectation) => void;
    hideHint?: boolean;
    isGetRestoTheme?: boolean;
    hideButton?: boolean;
    updateOnlyNote?: boolean
    addressToEdit: DeliveryOrderExpectation
    onAddressToEditChange: (address: DeliveryOrderExpectation) => void
}

const DeliveryAddressTextField: React.FC<DeliveryAddressTextFieldProps> = (props) => {

    const {
        onPreviousClick,
        onNewAddressEntered,
        hideHint,
        isGetRestoTheme = false,
        hideButton,
        updateOnlyNote,
        addressToEdit,
        onAddressToEditChange
    } = props;

    const intl = useIntl();
    const theme = useTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));

    const { selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations)

    /**
     * If true, we display a loading circle in the main button (at the bottom).
     * This loader lets the user know that we are waiting for the API to answer
     */
    const [loadingAddressAPI, setLoadingAddressAPI] = useState<boolean>(false);

    const [addressIsOkMessage, setAddressIsOkMessage] = useState<string>("");

    const [displayNoteTextfield, setDisplayNoteTextfield] = useState<boolean>(updateOnlyNote ? true : false)

    const [newNote, setNewNote] = useState<string>(addressToEdit.delivery_note || "")

    /**
     * Triggered when an address is selected (not just suggested)
     * @param result 
     */
    const onSelectHandler = async (result: MapboxGeocoder.Result) => {

        if (!selectedLocation) {

            setAddressIsOkMessage(intl.formatMessage({ id: "customer_information_modal.delivery.error" }));
            log.error("No location defined for the delivery address textField")
            return;
        }

        const address = getAddressFromMapboxResult(result);

        // We start loading
        setLoadingAddressAPI(true);

        const checkDeliveryAvailableResult = await checkDeliveryAvailableAPI(
            result.geometry.coordinates,
            address,
            selectedLocation,
            selectedTable
        );
        const zoneFound = checkDeliveryAvailableResult?.checked_delivery_zone;
        if (zoneFound) {
            setAddressIsOkMessage("");
            onAddressToEditChange({
                ...addressToEdit,
                delivery_zone: zoneFound,
                address,
            })
            setDisplayNoteTextfield(true)
        }
        else {
            if (checkDeliveryAvailableResult.unavailable_reason === DeliveryUnavailableReason.NO_MORE_COURIERS_AVAILABLE) {
                setAddressIsOkMessage(intl.formatMessage({
                    id: "customer_information_modal.delivery.no_more_couriers_available"
                }));
            } else if (checkDeliveryAvailableResult.unavailable_reason === DeliveryUnavailableReason.DELIVERY_SERVICE_ERROR ||
                checkDeliveryAvailableResult.unavailable_reason === DeliveryUnavailableReason.DELIVERY_SERVICE_CONFIGURATION_ERROR) {
                setAddressIsOkMessage(intl.formatMessage({
                    id: "customer_information_modal.delivery.delivery_service_error"
                }));
            } else {
                setAddressIsOkMessage(intl.formatMessage({ id: "customer_information_modal.delivery.error" }));
            }
        }

        setLoadingAddressAPI(false);
    }

    const getLocationPosition = (): Position | undefined => {

        if (
            selectedLocation
            && selectedLocation.position
            && selectedLocation.position.latitude
            && selectedLocation.position.longitude
        ) {

            return selectedLocation.position;
        }
    }

    const getLocationCountryCode = () => {

        if (selectedLocation && selectedLocation.country) {

            if (selectedLocation.country.length === 2) {

                return selectedLocation.country;
            }
            else {

                const foundCountry = lookup.byCountry(selectedLocation.country);

                if (foundCountry) {

                    return foundCountry.iso2;
                }
                else {

                    log.error(`Country ${selectedLocation.country} seems to be invalid`);
                    return "fr";
                }
            }
        }

        log.error(`No country for this location`);
        return "fr";
    }

    return (
        <Box
            width={1}
            height={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{
                backgroundColor: theme.palette.background.paper,
            }}
        >

            {

                ///////////////
                // Header
                ///////////////

                !hideHint &&

                <Box
                    py={isGetRestoTheme ? 3 : 2}
                    width={1}
                    px={isGetRestoTheme ? 2.5 : 0}
                    textAlign="center"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                        backgroundColor: isGetRestoTheme ? theme.palette.background.default : "transparent",
                    }}
                    border={isGetRestoTheme ? 1 : 0}
                    borderTop={0}
                    borderLeft={0}
                    borderRight={0}
                    borderColor='grey.300'
                >
                    <IconButton
                        onClick={onPreviousClick}
                        style={{ padding: 0 }}
                        size="large"
                        data-test="delivery-info-back-button"
                    >
                        <ArrowBackIos sx={{ color: theme.palette.secondary.main }} />
                    </IconButton>

                    <Typography variant={isGetRestoTheme ? 'h2' : 'h3'}>
                        {intl.formatMessage({
                            id: updateOnlyNote ? "customer_information_modal.delivery.form.title_edit" : "customer_information_modal.delivery.form.title_new"
                        })}
                    </Typography>

                    {// For alignment only
                    }

                    <Icon style={{ opacity: 0 }}>
                        <ArrowBackIos sx={{ color: theme.palette.secondary.main }} />
                    </Icon>
                </Box>
            }

            <Box
                width={1}
                height={1}
                paddingTop={isGetRestoTheme ? 2 : 0}
                px={isGetRestoTheme ? 3 : 0}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {updateOnlyNote
                    ? <Typography>{getNewAddressPlaceHolder(addressToEdit.address)[0]}</Typography>
                    : <>
                        <Box width={1} my={4}>
                            <MatGeocoder
                                data-test="address-input"
                                inputPlaceholder={intl.formatMessage({ id: "auth.address.inputplaceholder" })}
                                inputValue={getNewAddressPlaceHolder(addressToEdit.address)[1] ? getNewAddressPlaceHolder(addressToEdit.address)[0] : undefined}
                                accessToken={process.env.REACT_APP_MAPBOX_API_KEY ?? ""}
                                onSelect={onSelectHandler}
                                types="address"
                                country={getLocationCountryCode()}
                                proximity={getLocationPosition()}
                                showLoader={true}
                                inputPaperProps={{
                                    elevation: 0,
                                    style: {
                                        padding: 0,
                                        width: "100%",
                                    }
                                }}
                                suggestionsPaperProps={{
                                    style: {
                                        zIndex: DELIVERY_MODAL,
                                        position: "fixed",
                                        maxWidth: isDesktopRender ? "400px" : "85%",
                                        marginLeft: "auto",
                                        marginRight: "auto",

                                    }
                                }}
                                textFieldProps={{
                                    variant: "standard",
                                    fullWidth: true,
                                    autoFocus: true,
                                    inputProps: {
                                        "data-test": "delivery-address-textfield",
                                    },
                                }}
                                onInputFocus={(evt) => {
                                    setAddressIsOkMessage("");
                                }}
                            />
                        </Box>

                        <Box>
                            {
                                loadingAddressAPI &&

                                <Box
                                    data-test="loader-check-new-address"
                                    marginTop={2}
                                    display="flex"
                                    width={1}
                                    justifyContent="center"
                                    alignItems="center"
                                >

                                    <CircularProgress
                                        size="1em"
                                        style={{
                                            color: theme.palette.primary.main,
                                            margin: theme.spacing(0, 2, 0, 0)
                                        }}
                                    />

                                    <Typography color="primary">

                                        {intl.formatMessage({ id: "customer_information_modal.delivery.form.loading" })}

                                    </Typography>

                                </Box>
                            }

                            {addressIsOkMessage !== "" &&

                                <Typography variant="caption" style={{ color: theme.palette.primary.main, marginBottom: theme.spacing(2) }}>
                                    {addressIsOkMessage}
                                </Typography>
                            }
                        </Box>
                    </>
                }
                <Box flexGrow={30} width={1} my={4}>
                    <Box display={"flex"} alignItems="center">
                        <Add color="primary" />
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                            <FormattedMessage id="customer_information_modal.form.delivery_note.title" />
                        </Typography>
                    </Box>
                    <TextField
                        data-test="delivery-note-input"
                        multiline={true}
                        style={{
                            width: "100%",
                        }}
                        placeholder={intl.formatMessage({ id: "customer_information_modal.form.delivery_note.placeholder" })}
                        InputProps={{ disableUnderline: true }}
                        rows={10}
                        value={newNote}
                        onChange={(e) => {
                            const note = e.target.value as string
                            setNewNote(note)
                            if (!onNewAddressEntered) {
                                onAddressToEditChange({
                                    ...addressToEdit,
                                    delivery_note: note
                                })
                            }
                        }}
                        inputProps={{
                            "data-test": "delivery-address-note-textfield",
                        }}
                    />
                </Box>

                {!hideButton &&

                    <Button
                        data-test="delivery-info-confirm-button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (!addressIsOkMessage && !loadingAddressAPI && addressToEdit.address && addressToEdit.delivery_zone && addressToEdit.addressType) {
                                onAddressToEditChange({
                                    ...addressToEdit,
                                    delivery_note: newNote
                                })
                                if (onNewAddressEntered) {
                                    onNewAddressEntered({
                                        ...addressToEdit,
                                        delivery_note: newNote
                                    })
                                }
                                onPreviousClick()
                            } else {
                                onPreviousClick()
                            }
                        }}
                        style={{
                            textTransform: "none",
                            width: "60%",
                            position: isGetRestoTheme ? 'fixed' : 'inherit',
                            bottom: theme.spacing(6),
                            padding: isGetRestoTheme ? 12 : 6
                        }}
                        disabled={!displayNoteTextfield || loadingAddressAPI}
                    >
                        <Typography>
                            {intl.formatMessage({ id: "customer_information_modal.address_edition.form.confirm" }, { br: <br /> })}
                        </Typography>

                    </Button>
                }

            </Box>
        </Box>
    );
}

export default DeliveryAddressTextField;