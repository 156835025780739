
export const getBaseCloudFunctionsUrl = (isProdEnvironment: boolean) => {
    return `https://europe-west1-my-lemonade${isProdEnvironment ? "" : "-dev"}.cloudfunctions.net`
    // return `http://127.0.0.1:5001/my-lemonade${isProdEnvironment ? "" : "-dev"}/us-central1`
}

const CF_RECOVERY_PASSWORD_ENDPOINT = "/recoveryPassword";
const CF_SEND_INVOICE_ENDPOINT = "/sendInvoice";

export const cloudFunctionsEndpoints = {
    recovery_password: CF_RECOVERY_PASSWORD_ENDPOINT,
    send_invoice: CF_SEND_INVOICE_ENDPOINT,
}