import { Box } from '@mui/material';
import React from 'react';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { Money } from '../../my-lemonade-library/src/common/models/Money';
import { SupportedPaymentTypeExtended } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { PaymentTypeUnavailabilityReason } from '../../my-lemonade-library/src/payments/models/PaymentTypeUnavailabilityReason';
import PaymentCard from './PaymentCard';
import PaymentWalletBottomButtons from './PaymentWalletBottomButtons';
interface WalletPaymentCardProps {
    paymentType: SupportedPaymentTypeExtended;
    allPaymentTypes: SupportedPaymentTypeExtended[];
    serviceType: SupportedServiceType | undefined;
    disabled?: PaymentTypeUnavailabilityReason | boolean | null;
    onClick: () => void;
    leftToPay: Money;
}

const WalletPaymentCard: React.FC<WalletPaymentCardProps> = (props) => {

    const { paymentType, allPaymentTypes, serviceType, disabled, leftToPay, onClick } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
        >
            <PaymentCard
                paymentType={paymentType}
                allPaymentTypes={allPaymentTypes}
                disabled={disabled}
                onClick={onClick}
                serviceType={serviceType}
                leftToPay={leftToPay}
            />
            <PaymentWalletBottomButtons />

        </Box>
    );

}

export default WalletPaymentCard;