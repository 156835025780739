import { MenuItem, Select } from '@mui/material';
import log from 'loglevel';
import React from 'react';
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from 'react-redux';
import AppLocale from '../../config/translation';
import { guessCountryCode } from '../../my-lemonade-library/src/common/services/LangCodeServices';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import actions from '../redux/actions';

interface FlagComponentProps { }

const FlagComponent: React.FC<FlagComponentProps> = (props) => {

    const { currentLang } = useSelector((state: any) => state.lang)
    // const { selectedLocation } = useSelector((state: any) => state.locations) //Changer en langue du Catalog quand le champs langue du catalogue sera implémenté
    const { supportedLanguages } = useTypedSelector((state: RootState) => state.lang)
    const dispatch = useDispatch()


    /* const changeFlag = () => {
         if (!AppLocale.hasOwnProperty(locale) && currentLang !== selectedLocation.country) {
             log.debug(`${currentLang} !== ${selectedLocation.country} && ${locale} doesn't exist in appLocale`)
             dispatch(actions.changeLanguage(selectedLocation.country))
         } else if (currentLang === locale) {
             log.debug(`${currentLang} === ${locale} actions to => ${selectedLocation.country}`)
             dispatch(actions.changeLanguage(selectedLocation.country))
         } else if (currentLang !== locale) {
             log.debug(`${currentLang} !== ${locale}`)
             if (!AppLocale.hasOwnProperty(locale)) {
                 log.debug(`setCurrentLang to en`)
                 dispatch(actions.changeLanguage("en"))
             } else {
                 log.debug(`setCurrentLang to ${locale}`)
                 dispatch(actions.changeLanguage(locale))
             }
         }
     }*/

    const selectLanguage = (lang: string) => {
        if (!AppLocale.hasOwnProperty(lang)) {
            log.error(`selected language "${lang}" is not supported => redirect to EN translation`)
            dispatch(actions.changeLanguage("en"))
        } else {
            dispatch(actions.changeLanguage(lang === "gb" ? "en" : lang))
        }
    }

    if (supportedLanguages && supportedLanguages.length > 1) {
        return (
            <Select
                value={currentLang}
                onChange={(e) => selectLanguage(e.target.value as string)}
                disableUnderline
            >
                {supportedLanguages.map((lang: string, index: number) => {
                    return (
                        <MenuItem value={lang} key={index}>
                            <ReactCountryFlag style={{ width: '20px', height: '20px' }} countryCode={guessCountryCode(lang)} svg />
                        </MenuItem>
                    )
                })}
            </Select >
        )
    } else if (supportedLanguages) {
        return (
            <ReactCountryFlag style={{ width: '20px', height: '20px' }} countryCode={currentLang === "en" ? "gb" : currentLang} svg />
        )
    }
    return null

}
/*
            {!AppLocale.hasOwnProperty(locale) ? (
                <ReactCountryFlag style={{ width: '20px', height: '20px' }} countryCode={currentLang === "en" ? 'gb' : currentLang} onClick={() => changeFlag()} svg />
            ) :
                (<ReactCountryFlag style={{ width: '20px', height: '20px' }} countryCode={currentLang === "en" ? 'gb' : currentLang} onClick={() => changeFlag()} svg />)}

*/

export default FlagComponent