import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { cursor } from "../../Common/StyledComponents";

interface TimeSelectCardProps {
    onClick: (event: any) => void;
    checked: boolean | undefined
}

/**
 * This is a really simple component. It shows the selected time or a placeholder. 
 * It is used by DeliveryFullForm and CollectionFullForm
 */
const TimeSelectCard: React.FC<TimeSelectCardProps> = (props) => {

    const {
        onClick,
        checked,
    } = props;

    const intl = useIntl();
    const theme = useTheme()

    return (

        <Box
            width={1}
        >

            <Box
                data-test="radio-expected-time-time-selected"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                mt={2}
                mb={1}
                onClick={onClick}
                style={{
                    ...cursor,
                }}
            >

                <Box display="flex" alignItems="center">

                    <Box mr={2} display="flex" alignItems="center">
                        <ScheduleIcon style={{ color: theme.palette.grey[500] }} />
                    </Box>

                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography>
                            {intl.formatMessage({ id: "customer_information_modal.dateTime.placeholder" })}
                        </Typography>
                    </Box>
                </Box>

                {checked
                    ? <CheckCircleRoundedIcon color='primary' />
                    : <RadioButtonUncheckedIcon style={{ color: theme.palette.grey[300] }} />
                }

            </Box>
        </Box>
    );
}

export default TimeSelectCard;
