import { Slide, useScrollTrigger } from '@mui/material';
import React from 'react';

type Props = { children: React.ReactElement }

/**
 * Component in charge to hide children on down scrolling
 * @param param0 
 */
export default function HideOnScroll({ children }: Props) {
    const trigger = useScrollTrigger();

    if (children.props) {

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        )
    } return null
}