import { ConnectorType } from "../models/ConnectorsType";

export const CONNECTORS_SUPPORTING_FETCHING_TABLE_ORDERS: ConnectorType[] = [ConnectorType.ZELTY, ConnectorType.CARREPOS, ConnectorType.LIGHTSPEED, ConnectorType.UNTILL, ConnectorType.PI, ConnectorType.OPOSSUM, ConnectorType.CASHPAD]
export const CONNECTORS_SUPPORTING_ADDING_PAYMENT: ConnectorType[] = [ConnectorType.ZELTY, ConnectorType.CARREPOS, ConnectorType.LIGHTSPEED, ConnectorType.UNTILL, ConnectorType.PI, ConnectorType.OPOSSUM, ConnectorType.CASHPAD]
export const CONNECTORS_SUPPORTING_ADDING_ITEMS: ConnectorType[] = [ConnectorType.ZELTY, ConnectorType.LIGHTSPEED, ConnectorType.UNTILL, ConnectorType.CASHPAD];

export const CONNECTOR_FOR_WHICH_TABLE_NUMBER_IS_TABLE_REF: ConnectorType[] = [ConnectorType.LIGHTSPEED];

export const CONNECTORS_SUPPORTING_BUCKET_IMPORT = [
    ConnectorType.ADDITION,
    ConnectorType.JALIA,
    ConnectorType.POPINA
];