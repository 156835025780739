import { Order, OrderInBase } from "../../../model/Order";
import { moneyToNumber, numberToMoney } from "../../common/models/Money";
import { Tax } from "../../taxes/models/Tax";
import taxHelper from "../../taxes/services/TaxHelper";
import { computeOrderItemTotals } from "./OrderPricing";

/**
 * Takes an order and computes its total, subtotal, and also each
 * item subtotal.
 * @param order 
 * @param catalog 
 * @returns 
 */
export function computeOrderTotals(order: OrderInBase, dataWithTax: {
    currency: string;
    taxes?: Tax[]
}): number {
    const total = computeOrderTotalsWithoutTaxUpdate(order, dataWithTax.currency);
    taxHelper.computeOrderTaxes(order, dataWithTax);
    return total;
}

/**
 * TODO: we had a case where the charges of a sent order didn't have update_ids -> the total was wrongly computed
 * Where do we use this function? Do we use the onlyWithUpdateId parameter outside of the BO? Is it still necessary?
 * @param order 
 * @param currency 
 * @param onlyChargeDiscount 
 * @param onlyWithUpdateId 
 * @returns 
 */
export function computeOrderTotalsWithoutTaxUpdate(order: Order, currency: string, onlyChargeDiscount?: boolean, onlyWithUpdateId?: boolean): number {

    let total = 0;
    if (!onlyChargeDiscount || !order.subtotal) {
        order.items.forEach((orderItem) => {
            if (!onlyWithUpdateId || orderItem.update_id) {
                total += computeOrderItemTotals(orderItem, currency);
            }
        });

        if (order.deals) {
            for (let dealKey in order.deals) {
                const dealItems = order.items.filter((orderItem) => orderItem.deal_line?.deal_key === dealKey);
                let dealTotalPrice = 0;
                let dealPrice = 0;
                dealItems?.forEach((orderItem) => {
                    dealTotalPrice += orderItem.subtotal ? moneyToNumber(orderItem.subtotal) : 0;
                    dealPrice += orderItem.price ? moneyToNumber(orderItem.price) : 0;
                });
                order.deals[dealKey].price = numberToMoney(dealPrice, currency);
                order.deals[dealKey].price_with_options = numberToMoney(dealTotalPrice, currency);
            }
        }
        order.subtotal = numberToMoney(total, currency);
    } else {
        total = moneyToNumber(order.subtotal);
    }

    // Add charges
    order.charges?.forEach((charge) => {
        if (!onlyWithUpdateId || charge.updated_id) {
            // Apply a charge ONLY IF positive value. Price cannot be a discount
            const chargePrice = moneyToNumber(charge.price);
            if (chargePrice > 0) {
                total += chargePrice;
            }
        }
    });
    order.discounts?.forEach((discount) => {
        if (!onlyWithUpdateId || discount.update_id) {
            const discontPriceOff = moneyToNumber(discount.price_off);
            total -= discontPriceOff;
        }
    })

    order.total = numberToMoney(total, currency);

    return total;
}