import { LoyaltyInformationToDisplayConfig, LoyaltyInformationToDisplayOrder } from "./models/LoyaltyState";

export const UPDATE_LOYALTY_INFORMATION = 'UPDATE_LOYALTY_INFORMATION';
export interface UpdateLoyaltyInformationAction {
    type: typeof UPDATE_LOYALTY_INFORMATION;
    payload: {
        estimatedLoyaltyConfigContent?: LoyaltyInformationToDisplayConfig;
        estimatedLoyaltyOrderContent?: LoyaltyInformationToDisplayOrder;
    }
}

export const TOGGLE_LOCAL_LOYALTY_USE_POINTS = 'TOGGLE_LOCAL_LOYALTY_USE_POINTS';
export interface ToggleLocalLoyaltyUsePointsAction {
    type: typeof TOGGLE_LOCAL_LOYALTY_USE_POINTS;
    payload: {
        use_points: boolean;
    }
}

export type LoyaltyActionTypes =
    UpdateLoyaltyInformationAction
    | ToggleLocalLoyaltyUsePointsAction;

export const loyaltyActions = {

    updateLoyaltyInformation: (
        estimatedLoyaltyConfigContent?: LoyaltyInformationToDisplayConfig,
        estimatedLoyaltyOrderContent?: LoyaltyInformationToDisplayOrder,
    ): UpdateLoyaltyInformationAction => ({
        type: UPDATE_LOYALTY_INFORMATION,
        payload: {
            estimatedLoyaltyConfigContent,
            estimatedLoyaltyOrderContent,
        }
    }),

    toggleLocalLoyaltyUsePoints: (use_points: boolean): ToggleLocalLoyaltyUsePointsAction => ({
        type: TOGGLE_LOCAL_LOYALTY_USE_POINTS,
        payload: {
            use_points,
        }
    }),
}