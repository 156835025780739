import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../Common/helper/TextHelper';
import { Sku } from '../../my-lemonade-library/model/Catalog';
import { SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { CatalogEntityType } from '../../my-lemonade-library/src/catalogs/models/CatalogEntity';
import { moneyToNumber, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RADIO_SIZE } from '../configs/styleConfig';

interface ProductOptionsRadioListProps {
    selected?: Sku
    items: Sku[];
    onChange: (item: Sku) => void;
    readonly: boolean;
}

const ProductSkuSelect = (props: ProductOptionsRadioListProps) => {
    const { items: availableItems, onChange, selected } = props;

    const theme = useTheme();

    const intl = useIntl();

    const renderItem = useCallback((item: Sku, index: number) => {
        let itemPrice = item.price

        return (
            <Box key={`${item.ref}-${index}`}>
                <Divider />
                <Box
                    display='flex'
                    width={1}
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    data-test={`option_${item.ref}`}
                    my={1}
                    onClick={() => {
                        if (item.disable) {
                            return
                        }

                        onChange(item)
                    }}
                >
                    <FormControlLabel
                        value={item.ref}
                        disabled={item.disable}
                        style={{
                            display: "flex",
                            flex: 1
                        }}
                        control={
                            !props.readonly ?
                                <Radio
                                    data-test={`controller_option_${item.ref}`}
                                    icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color='primary'
                                /> :
                                <Box data-test={`box_option_${item.ref}`} width={RADIO_SIZE} height={RADIO_SIZE} />
                        }
                        label={
                            `${capitalizeFirstLetter(
                                intl.formatMessage({
                                    id: translationService.getSkuNameTranslationKey(item),
                                    defaultMessage: item.name
                                }))}${item.disable ? ` (${intl.formatMessage({ id: 'product.disable' })})` : ''}`
                        }
                    />

                    <Box display='flex' flexDirection='row'>
                        {
                            itemPrice && moneyToNumber(itemPrice) !== 0 &&

                            <Typography
                                variant="body1"
                                style={{
                                    color: theme.palette.text.disabled,
                                    textDecoration: ((item as SkuExtended).reduced_price)
                                        ? 'line-through'
                                        : 'none'
                                }}
                            >
                                {MoneyToStringWithSymbol(itemPrice)}
                            </Typography>
                        }

                        {/* Display reduced price is deal ongoing */
                            ((item as SkuExtended).reduced_price) &&
                            <Box ml={1}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: theme.palette.text.disabled
                                    }}
                                >
                                    {MoneyToStringWithSymbol((item as SkuExtended).reduced_price as string)}
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        );
    }, [intl, onChange, props.readonly, theme.palette.text.disabled])

    return (
        <RadioGroup
            style={{ width: '100%' }}
            aria-label={CatalogEntityType.SKU}
            name={CatalogEntityType.SKU}
            value={selected?.ref}
        >
            {availableItems.map(renderItem)}
        </RadioGroup >
    )
}

export default ProductSkuSelect;