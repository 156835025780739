import { ThemeList } from "../../my-lemonade-library/src/theme/models/ThemesList";
import { CHANGE_THEME, ThemeActionsType } from "./ThemeActions";

interface ThemeState {
    themeName: ThemeList,
}
const themeState: ThemeState = {
    themeName: ThemeList.DEFAULT
}

export const themeReducer = (state = themeState, action: ThemeActionsType) => {

    const { type, payload } = action

    switch (type) {

        case CHANGE_THEME:
            return {
                ...state,
                themeName: payload.themeName
            }

        default:
            return state;
    }
}