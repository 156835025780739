import { Box, Collapse, Typography } from "@mui/material"
import log from "loglevel"
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router"
import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeList } from "react-window"
import useAvailableCategories from "../../Categories/hooks/useAvailableCategories"
import { getDealRoute } from "../../deals/configs/DealRoutes"
import { isTemporallyAvailableForOrder } from "../../my-lemonade-library/functions/Helpers"
import { Categorie, CategoriesAndProducts, CategoryDisplayMode } from "../../my-lemonade-library/model/Catalog"
import DealExt from "../../my-lemonade-library/model/DealExtended/DealExt"
import { getTimezoneName } from "../../my-lemonade-library/model/Location"
import { ProductExtended } from "../../my-lemonade-library/model/ProductExtended/ProductExtended"
import { CatalogEntityType } from "../../my-lemonade-library/src/catalogs/models/CatalogEntity"
import translationService from "../../my-lemonade-library/src/translations/services/TranslationService"
import { RootState, useTypedSelector } from "../../redux/root-reducer"
import useCategoriesAndProducts from "../hooks/useCategoriesAndProducts"
import { Heights } from "../model/scrollItemsHeights"
import ProductModal from "../pages/ProductModal"
import CategorieSwiper from "./CategorieSwiper"
import ProductScrollCard from "./ProductScrollCard"

interface DisplayProductsScrollProps {
    rootCatRef?: string
}

export const DisplayProductsScroll = (props: DisplayProductsScrollProps) => {

    const { rootCatRef } = props

    const { selectedCatalog, selectedTable, tableLinkId } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state) => state.order);

    const intl = useIntl()
    const history = useHistory()

    // Used to display which category or sub-category is selected
    const [selectedCategoryRef, setSelectedCategoryRef] = useState<string>()
    const [selectedSubCategoryRef, setSelectedSubCategoryRef] = useState<string>()

    const rootCategories = useAvailableCategories(null);
    const subCatsToDisplay = useAvailableCategories(selectedCategoryRef);

    const isSubCatOpened = (rootCatRef && subCatsToDisplay.length) ? true : false
    const [selectedItem, setSelectedItem] = useState<ProductExtended | DealExt>()

    const categoriesAndProducts = useCategoriesAndProducts(rootCatRef);

    // Is the product modal opened
    const [productOpen, setProductOpen] = useState<boolean>(false)
    const [note, setNote] = useState<string>('')

    // Used to scroll to a category
    const ref = useRef<VariableSizeList<CategoriesAndProducts[]>>(null)

    // The resetAfterIndex is used to rerender the list.
    // It's needed as if the list is changed, we need to be sure that the heights are recalculated
    useEffect(() => {

        if (ref !== null && ref.current !== null) {
            ref.current.resetAfterIndex(0)
        }

    }, [categoriesAndProducts])


    // Set the mainCategory as selected
    useEffect(() => {
        if (
            rootCategories
            && rootCategories.length > 0
            && !selectedCategoryRef
        ) {
            setSelectedCategoryRef(rootCategories[0].ref)
        }
    }, [rootCategories])

    useEffect(() => {
        if (rootCatRef && subCatsToDisplay.length > 0 && !selectedCategoryRef) {
            setSelectedSubCategoryRef(subCatsToDisplay[0].ref)
        }

    }, [subCatsToDisplay, rootCatRef])


    /**
     * Sets the mainCategory selected, check if this category has sub-categories,
     * if so, open the sub-categories part and finaly, scroll to the category
     * @param categoryRef 
     */
    const setCategoryAndScroll = (categoryRef: string) => {
        setSelectedCategoryRef(categoryRef)

        if (categoriesAndProducts && ref && ref.current) {
            const itemNumber: number = categoriesAndProducts.findIndex(item => item.data.ref === categoryRef)

            ref.current.scrollToItem(itemNumber, 'start')
        }
    }

    /**
     * Scroll to the sub-category selected
     * @param subCatRef 
     */
    const setSubCategoryAndScroll = (subCatRef: string) => {
        setSelectedSubCategoryRef(subCatRef)

        if (categoriesAndProducts && ref && ref.current) {
            const itemNumber: number = categoriesAndProducts.findIndex(item => item.data.ref === subCatRef)
            ref.current.scrollToItem(itemNumber, 'start')
        }
    }

    /**
     * Selected a product and open ProductModal
     * @param product 
     */
    const handleProductClick = (product: ProductExtended) => {
        setSelectedItem(product)
        setProductOpen(true)
    }

    const goToDeal = (deal: DealExt) => {
        history.push(getDealRoute(tableLinkId, deal));
    }

    const navBarScroll = useCallback((index: number, elem: CategoriesAndProducts) => {
        if (categoriesAndProducts[index - 4]) {
            categoriesAndProducts[index - 3].subCat && setSelectedSubCategoryRef(categoriesAndProducts[index - 3].subCat)
            setSelectedCategoryRef(categoriesAndProducts[index - 4].mainCat)

        } else {
            elem.subCat && setSelectedSubCategoryRef(elem.subCat)
            setSelectedCategoryRef(elem.mainCat)
        }
    }, [categoriesAndProducts])

    /**
     * Check what is the item type and return it's height
     * VariableSizeList needs to have the exact item's height
     * The resetAfterIndex() method must be used if the array provided changes 
     * while the component is rendered (see in the useEffect above)
     * @param index Item index
     * @returns The item size
     */
    const getItemSize = (index: number, filteredItems: CategoriesAndProducts[]): number => {

        if (filteredItems && filteredItems[index]) {

            const elem: CategoriesAndProducts = filteredItems[index];

            // Do not display disabled items for which the checkbox has not been checked.
            if (elem.data.disable && !elem.data.display_if_disabled) {
                return 0;
            }

            if (isProductWithoutImage(elem)) {
                return Heights.WithoutImage;
            }

            switch (elem.type) {
                case CatalogEntityType.CATEGORY:
                    return Heights.MainCat;
                case CatalogEntityType.SUB_CATEGORY:
                    return Heights.SubCat;
                case CatalogEntityType.PRODUCT:
                    return Heights.Prod;
                case CatalogEntityType.DEAL:
                    return Heights.Prod;
                default:
                    log.error('Error getting item size');
            }
        }
        return 0;
    }


    const getCategoryTranslation = (category: Categorie): string => {

        const translationId = translationService.getCategorytNameTranslationKey(category);
        if (translationId && intl.messages[translationId]) {
            return intl.formatMessage({ id: translationId });
        }
        else {
            log.debug(`Missing translation for category ${category.name}`);
            return category.name;
        }
    }

    /**
     * Render category or sub-category name
     * @param category 
     * @returns 
     */
    const renderCategory = (category: Categorie): JSX.Element => {
        const isSubCat: boolean = category.entity_type === CatalogEntityType.SUB_CATEGORY
        return (
            <Box pb={2} pt={isSubCat ? 0 : 2} height={isSubCat ? `${Heights.SubCat}px` : `${Heights.MainCat}px`}>
                <Typography variant={isSubCat ? 'h3' : 'h2'}>
                    {getCategoryTranslation(category)}
                </Typography>
            </Box>
        )
    }

    const isProductWithoutImage = (elem: CategoriesAndProducts): boolean => {

        if (elem.type === CatalogEntityType.PRODUCT || elem.type === CatalogEntityType.DEAL) {
            const productCategory = categoriesAndProducts.find(item => item.data.ref === (elem.data as ProductExtended).category_ref);
            if (productCategory && (productCategory.data as Categorie).display === CategoryDisplayMode.WITHOUT_IMAGE) {
                return true;
            }
        }
        return false
    }

    const filteredProducts = (categoriesAndProducts: CategoriesAndProducts[]): CategoriesAndProducts[] => {
        return categoriesAndProducts.filter((item: CategoriesAndProducts) => {
            if (item?.data?.restrictions) {
                return isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), item?.data?.restrictions, false);
            }
            return true;
        });
    }


    const renderLineContent = (elem: CategoriesAndProducts): JSX.Element => {

        if (elem.type === CatalogEntityType.CATEGORY || elem.type === CatalogEntityType.SUB_CATEGORY) {
            return renderCategory(elem.data as Categorie);
        }

        let onClick: MouseEventHandler<HTMLDivElement> | undefined;
        let isDeal: boolean | undefined;

        if (elem.type === CatalogEntityType.DEAL) {
            onClick = () => goToDeal(elem.data as DealExt);
            isDeal = true;
        }
        else {
            onClick = () => handleProductClick(elem.data as ProductExtended);
        }
        let isWithoutImage = false;
        if (isProductWithoutImage(elem)) {
            isWithoutImage = true;
        }

        return (
            <Box
                position="relative"
                onClick={onClick}
                sx={{ cursor: "pointer" }}
            >

                <ProductScrollCard
                    product={elem.data as DealExt | ProductExtended}
                    height={isWithoutImage ? Heights.WithoutImage : Heights.Prod}
                    isDeal={isDeal}
                    isWithoutImage={isWithoutImage}
                />
            </Box>
        );
    }

    return (

        <Box
            display='flex'
            flexDirection='column'
            height='100%'
            data-test='display_product_scroll'
        >

            {selectedItem &&
                <ProductModal
                    selectedProduct={selectedItem as ProductExtended}
                    open={productOpen}
                    closeModal={() => setProductOpen(false)}
                    note={note}
                    setNote={setNote}
                    backToMenu
                />
            }

            {selectedCatalog &&
                selectedCatalog.data.mainCategories &&
                selectedTable &&
                !rootCatRef &&

                <Box my={1}>
                    <CategorieSwiper
                        currentRef={selectedCategoryRef ? selectedCategoryRef : ''}
                        categoryList={rootCategories}
                        onSelect={(categoryRef: string) => setCategoryAndScroll(categoryRef)}
                    />
                </Box>
            }

            <Collapse in={isSubCatOpened}>
                <Box mb={1} mt={-1}>
                    {selectedCatalog && subCatsToDisplay && subCatsToDisplay.length > 0
                        ? <CategorieSwiper
                            currentRef={selectedSubCategoryRef ? selectedSubCategoryRef : ''}
                            categoryList={subCatsToDisplay ? subCatsToDisplay : []}
                            onSelect={(categoryRef: string) => setSubCategoryAndScroll(categoryRef)}
                        />
                        : <Box height={46} />
                    }
                </Box>
            </Collapse>

            {categoriesAndProducts &&
                <Box
                    px={2}
                    bgcolor='background.paper'
                    flex={1}
                >
                    <AutoSizer>
                        {({ height, width }) => {
                            const filteredItems = filteredProducts(categoriesAndProducts);

                            return (
                                <VariableSizeList
                                    ref={ref}
                                    height={height}
                                    width={width}
                                    itemCount={filteredItems.length}
                                    itemSize={(index) => getItemSize(index, filteredItems)}
                                >
                                    {({ index, style }) => {

                                        const elem: CategoriesAndProducts = filteredItems[index];

                                        // Do not display disabled items which are not authorized.
                                        if (elem.data.disable && !elem.data.display_if_disabled) {
                                            return null;
                                        }

                                        navBarScroll(index, elem);

                                        return (
                                            <Box
                                                style={style}
                                                data-test={`display_scroll_entity_ref_${elem.data.ref}`}
                                            >
                                                {renderLineContent(elem)}
                                            </Box>
                                        );
                                    }}
                                </VariableSizeList>
                            );
                        }}
                    </AutoSizer>
                </Box>
            }

        </Box >
    )
}

export default DisplayProductsScroll;
