import log from "loglevel";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { Location, SupportedServiceType, Table } from "../../my-lemonade-library/model/Location";
import { OrderInBase, OrderStatus } from "../../my-lemonade-library/model/Order";
import { orderService } from "../../my-lemonade-library/src/orders/services/OrderService";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import { isOrderStatusDWP } from "./OrderHelpers";

/**
 * DWP = DRAFT || WAITING_SUBMISSION || PENDNING_PAYMENT
 * Browse the latest table orders and get the latest DWP if it exists.
 * If idToCheck is defined, then the IDs have to match for the order to
 * be selected. If not defined, this check will be ignored.
 * @returns 
 */
export const getLatestTableDWPOrder = (
    location: Location,
    tableOrders: OrderInBase[],
    selectedTable: Table,
    selectedCatalog: CatalogExtended | undefined,
): OrderInBase | undefined => {

    // Will stay at undefined if not found. Will be the most recent one if found
    let tableOrderFound: OrderInBase | undefined = undefined;

    log.info(">-A-> looking for a DWP order in tableOrders")

    // Browsing the table orders
    for (const elem of tableOrders) {

        const orderStatusDWP = isOrderStatusDWP(elem.status)
        const canAddItems = orderService.canAddItems(location, elem, false);
        const canPay = paymentHelper.canPay(location, elem);

        log.debug(`>-A-> Checking order ${elem.id} with status ${elem.status} and serviceType ${elem.service_type} (dwp: ${orderStatusDWP}, canAddItems: ${canAddItems}, canPay: ${canPay}})`);

        /**
         * We check some details: is it a DWP? Does it have the
         * same service_type, catalog & table as the current session?
         * If there's another draft found, is this one more recent?
         */
        if (
            (
                orderStatusDWP
                || canAddItems
                || canPay
            )
            && elem.table_id === selectedTable.id
            && selectedCatalog
            && elem.catalog_id === selectedCatalog.id
            && selectedTable.service_type
            && (
                elem.service_type === selectedTable.service_type
                || (
                    elem.service_type === SupportedServiceType.EAT_IN
                    && selectedTable.service_type === SupportedServiceType.CHECKOUT
                )
            )
        ) {

            if (!tableOrderFound) {
                tableOrderFound = elem;
                log.info(`>-A-> looking for a DWP order in tableOrders: found with id ${elem.id}`);
            } else {
                const tableOrderFoundDate = tableOrderFound.updated_at ? tableOrderFound.updated_at : tableOrderFound.created_at;
                const elemDate = elem.updated_at ? elem.updated_at : elem.created_at;
                if (tableOrderFoundDate && elemDate && elemDate > tableOrderFoundDate) {
                    // Setting or overriding the found order
                    tableOrderFound = elem;
                    log.info(`>-A-> looking for a DWP order in tableOrders: replaced (more recent) with id ${elem.id}`);
                }
            }

        }
    }

    if (!tableOrderFound) {
        log.info(`>-A-> looking for a DWP order in tableOrders: not found`);
    }

    return tableOrderFound;
}

/**
 * Get the latest DWP (draft, waiting submission, pending payment) order from the userOrders
 */
export const getLatestUserDWPOrder = (
    selectedTable: Table,
    selectedCatalog: CatalogExtended | undefined,
    userOrders: OrderInBase[],
): OrderInBase | undefined => {

    // Will stay at undefined if not found. Will be the most recent one if found
    let userOrderFound: OrderInBase | undefined = undefined;

    log.info(">-A-> looking for a DWP order in userOrders");

    if (selectedTable && selectedCatalog) {
        // Browsing the first user orders (ordered by date desc)
        if (userOrders && userOrders.length) {
            const elem = userOrders[0];

            /**
             * We check some details: is it a draft? Does it have the
             * same service_type, catalog & table as the current session?
             * If there's another draft found, is this one more recent?
             */
            if (
                (
                    elem.status === OrderStatus.DRAFT
                    || elem.status === OrderStatus.WAITING_SUBMISSION
                    || elem.status === OrderStatus.PENDING_PAYMENT
                )
                && elem.table_id === selectedTable.id
                && selectedCatalog
                && elem.catalog_id === selectedCatalog.id
                && (
                    (
                        selectedTable.service_type
                        && elem.service_type === selectedTable.service_type
                    )
                    || ( // Service type is not defined yet (user choice), let's take the one from the order (see choseOrderAndApplyLogic, just after this function call)
                        elem.service_type
                        && selectedTable.restrictions?.service_types?.includes(elem.service_type)
                    )
                )
            ) {

                log.info(`>-A-> Found a DWP order in userOrders: id ${elem.id}`);
                // Setting or overriding the found order
                userOrderFound = elem;
            } else {
                log.info(`>-A-> Order ${elem.id} found but then conditions to load it are not met`);
            }
        }
    } else {
        log.warn(`>-A-> Unable to find a DWP order, no service type ${selectedTable?.service_type} or catalog (${selectedCatalog?.id})`);
    }

    if (!userOrderFound) {
        log.info(`>-A-> No DWP order found in userOrders`);
    }

    return userOrderFound;
}

/**
 * Browse the tableOrders and return the order matching with the specified ID
 * @param orderId 
 * @returns 
 */
export const getOrderInLatestOrdersById = (
    orderId: string,
    selectedTable: Table,
    selectedCatalog: CatalogExtended | undefined,
    userOrders: OrderInBase[],
    tableOrders: OrderInBase[],
    ignoreServiceType?: boolean,
): OrderInBase | undefined => {

    const latestOrders = { userOrders, tableOrders };

    log.info(`>-A-> looking for a specific order in tableOrders (${tableOrders.length}) or userOrders (${userOrders.length})`)

    for (const [, value] of Object.entries(latestOrders)) {

        // Browsing the table orders
        for (const elem of value) {

            /**
             * We check some details: does it have the same ID as
             * the one we're looking for? Does it have the same service_type, catalog
             * & table as the current session?
             */
            if (elem.id === orderId
                && elem.table_id === selectedTable.id
                && selectedCatalog
                && elem.catalog_id === selectedCatalog.id
                && (
                    (ignoreServiceType && selectedTable.restrictions?.service_types?.includes(elem.service_type))
                    || (
                        selectedTable.service_type
                        && elem.service_type === selectedTable.service_type
                    )
                )

            ) {

                log.info(">-A-> looking for a specific order in tableOrders or userOrders: found", elem);
                return elem;
            }
        }
    }

    log.info(">-A-> looking for a specific order in tableOrders or userOrders: not found", {
        orderId,
        userOrders,
        tableOrders,
    });

    return undefined;
}