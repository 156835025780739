import { Terms } from "../../my-lemonade-library/src/authentications/models/Terms";
import DeploymentInfo from "../../my-lemonade-library/src/common/models/DeploymentInfo";

/**
 * Fetch deployment info by loading file located in public folder
 */
export const FETCH_DEPLOYMENT = "FETCH_DEPLOYMENT"
export interface FetchDeploymentAction {
    type: typeof FETCH_DEPLOYMENT;
}

export const FETCH_DEPLOYMENT_SUCCESS = "FETCH_DEPLOYMENT_SUCCESS"
export interface FetchDeploymentSuccessAction {
    type: typeof FETCH_DEPLOYMENT_SUCCESS;
    payload: {
        deployment: DeploymentInfo;
        terms: Terms | null;
    }
}

//Action type the reducer can receive
export type AppActionTypes =
    FetchDeploymentAction |
    FetchDeploymentSuccessAction;

//Actions creator
export const appActions = {
    fetchDeployment: (): FetchDeploymentAction => {
        return {
            type: FETCH_DEPLOYMENT
        }
    },
    fetchDeploymentSuccess: (deployment: DeploymentInfo, terms: Terms | null): FetchDeploymentSuccessAction => {
        return {
            type: FETCH_DEPLOYMENT_SUCCESS,
            payload: {
                deployment,
                terms
            }
        }
    }
};

export default appActions;
