import { Box, Divider, Link, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CustomerInformationModalFormToDisplay } from '../../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../../customers/redux/CustomerInformationModalActions';
import { Table } from '../../my-lemonade-library/model/Location';
import { OrderInBase } from '../../my-lemonade-library/model/Order';
import linktreeHelper from '../../my-lemonade-library/src/linktree/services/LinktreeHelper';
import LocationLink from '../../my-lemonade-library/src/locations/models/LocationLink';
import { useTypedSelector } from '../../redux/root-reducer';


export const shouldDisplayLinks = (selectedTable?: Table, order?: OrderInBase): boolean => {

    if (selectedTable && selectedTable.links && selectedTable.links.length > 0) {
        return selectedTable.links.some((link) => {
            return linktreeHelper.shouldDisplayLinkInLinktree(link, order)
        });
    }
    return false;
}

const LINK_ICON_SIZE = 28;

/**
 * Component in charge to display contact information and restriction of a location
 * 
 */
export const LocationLinksDisplay = () => {

    const { selectedLocation, selectedTable } = useTypedSelector(state => state.locations);
    const { order } = useTypedSelector(state => state.order)

    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [links, setLinks] = useState<LocationLink[] | null>(null)

    const getLinkText = (link: LocationLink): string => {

        if (link.title && intl.messages[link.title]) {
            return intl.formatMessage({ id: link.title });
        }

        log.debug(`Missing translation for link name ${link.title}`);
        return link.title;
    }

    useEffect(() => {
        if (selectedLocation && selectedTable) {
            // Pay, Menu and Loyalty links should'nt be displayed here
            // as they would be bound to buttons

            // TODO: check if order exists
            setLinks(
                selectedTable.links?.filter(l => linktreeHelper.shouldDisplayLinkInLinktree(l, order)
                ) ?? []
            )
        }
    }, [selectedTable, selectedLocation, order])

    return (
        <>
            {
                (links && links.length > 0) &&

                < Box
                    display="flex"
                    mt={3}
                    flexDirection='column'
                    alignItems="flex-start"
                    width={1}
                >

                    <Typography variant="subtitle1">
                        {intl.formatMessage({ id: "locations.links.title" })}
                    </Typography>

                    <Box
                        marginTop={0.5}
                        display="flex"
                        flexDirection="column"
                        width={1}
                    >

                        {links.map((raw_link, index) => {
                            const localeField = (raw_link as any)?.i18n?.[intl.locale];
                            const link = {
                                ...raw_link,
                                ...localeField
                            }

                            if (!link.disable) {

                                return (
                                    <Link
                                        href={!linktreeHelper.isReceiptLink(link) ? linktreeHelper.replacePlaceholdersInLink(link.href, selectedLocation, selectedTable, order) : undefined}
                                        target={!linktreeHelper.isReceiptLink(link) ? "_blank" : undefined}
                                        key={index}
                                        onClick={() => {
                                            if (linktreeHelper.isReceiptLink(link)) {
                                                dispatch(CustomerInformationModalActions.setCustomerInformationModal(CustomerInformationModalFormToDisplay.RECEIPT, {
                                                    receipt_url: linktreeHelper.replacePlaceholdersInLink(link.href, selectedLocation, selectedTable, order)
                                                }))
                                            }
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection='column'
                                            width={1}
                                            key={index}
                                            color={theme.palette.text.primary}
                                        >
                                            {(index > 0) &&

                                                <Box width={1} marginY={1}>
                                                    <Divider variant='fullWidth' />
                                                </Box>
                                            }

                                            <Box
                                                display="flex"
                                                flexDirection='row'
                                                height={1}
                                                alignItems="center"
                                                paddingY={1}
                                                paddingLeft={1}
                                            >
                                                <img
                                                    src={link.icon}
                                                    alt={link.title}
                                                    width={LINK_ICON_SIZE}
                                                    height={LINK_ICON_SIZE}
                                                />

                                                <Box marginLeft={1.5}>
                                                    <Typography>
                                                        {link.title ? getLinkText(link) : ""}
                                                    </Typography>
                                                </Box>
                                            </Box>



                                        </Box>
                                    </Link>
                                );
                            }
                            return null;
                        })}

                    </Box>
                </Box>
            }
        </>
    );

}