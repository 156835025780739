import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Card, CardHeader, CardMedia, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { DeviceContext } from '../../App';
import DefaultImage from '../../assets/aperitif.png';
import { TEXT_COLOR_ON_DARK } from '../../config/color';
import * as ROUTES from '../../config/routes';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { ProductExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import '../pages/ProductsPage.css';
import UnavailableButton from './UnavailableButton';

interface ProductsCardsComponentProps {
    item: ProductExtended | DealExt,
    categoryIcon?: string,
    containerWidth?: number,
    openModal: (item: ProductExtended | DealExt) => void,
    quickAddIcon?: boolean,
    onQuickAddIconClick?: () => void,
    disable?: boolean
};

const Cards: React.FC<ProductsCardsComponentProps> = (props) => {

    const intl = useIntl();
    const { quickAddIcon, onQuickAddIconClick, containerWidth, disable } = props
    const deal = props.item as DealExt;
    const isDeal = deal.lines && deal.lines.length > 0;
    const product = props.item as ProductExtended;
    const match = useRouteMatch<ROUTES.RouteParams>();
    const theme = useTheme();
    // const dataTestPrefix = isDeal ? "deal_" : "product_";
    let price = isDeal ? deal.starting_price : ((product.skus && product.skus.length) ? (product.skus[0].reduced_price ? product.skus[0].reduced_price : product.skus[0].price) : "")
    // const reduced = isDeal || (product.skus && product.skus[0].reduced_price) ? true : false
    const { tablet_portrait } = React.useContext(DeviceContext)

    const marginCard = theme.spacing(0.5)

    const getCardSize = (width: number | undefined) => {

        const maxCardInRow = tablet_portrait ? 3 : 4

        const marginFactor = 2 * maxCardInRow

        if (width) {
            const size = (width - parseInt(marginCard) * marginFactor) / maxCardInRow
            return `${size}px`
        } else {
            return '150px'
        }
    }

    const imageToRender = (product: ProductExtended | DealExt, categoryIcon?: string) => {
        if (product.image) {
            return product.image
        } else {
            if (categoryIcon) {
                return categoryIcon
            } else {
                return DefaultImage
            }
        }
    }

    return (
        <Card elevation={0} onClick={() => {

            if (match.params.tableLinkKey && props.item.category_ref && props.item.ref && !quickAddIcon && !disable) {
                props.openModal(props.item);
            }
        }}
            style={{ width: getCardSize(containerWidth), height: getCardSize(containerWidth), borderRadius: "10px", margin: marginCard, position: "relative", cursor: disable ? "" : "pointer" }}>
            {props.item.disable && props.item.display_if_disabled && <UnavailableButton />}
            <CardHeader
                style={{ position: "absolute", background: 'linear-gradient(black, transparent)', top: 0, width: "100%", padding: 0 }}
                title={(
                    <Box p={1} display="flex">
                        <Typography variant="caption" style={{ flex: 1, paddingRight: theme.spacing(1), overflowWrap: "break-word", color: TEXT_COLOR_ON_DARK }}>
                            {intl.formatMessage({
                                id: isDeal ?
                                    translationService.getDealNameTranslationKey(deal)
                                    : translationService.getProductNameTranslationKey(product),
                                defaultMessage: props.item.name
                            })}
                        </Typography>
                        {price ? (<Typography variant="caption" style={{ color: TEXT_COLOR_ON_DARK, fontWeight: "bold" }}>
                            {MoneyToStringWithSymbol(price)}
                        </Typography>) : ("")}
                    </Box>

                )} />
            <CardMedia
                component="div"
                style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", backgroundImage: `url(${imageToRender(props.item, props.categoryIcon)})`, width: "100%", height: "100%", backgroundSize: "contain" }}
            >
                {quickAddIcon && onQuickAddIconClick ? (
                    <IconButton
                        onClick={() => onQuickAddIconClick()}
                        color="primary"
                        style={{
                            marginTop: "auto",
                            marginLeft: "auto",

                        }}
                        size="large">
                        <AddCircleIcon />
                    </IconButton>
                ) : ""}
            </CardMedia>

        </Card >
    );

}

export default Cards