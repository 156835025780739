import { Box, List, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import LogoArrow from "../../assets/arrow_down.svg";
import DefaultBackground from "../../assets/bg_home.png";
import LogoImage from "../../assets/logo_white.svg";
import { ImageContainer } from "../../Common/StyledComponents";
import * as ROUTES from "../../config/routes";
import { RouteParams } from "../../config/routes";
import { MAX_LOGO_WIDTH } from "../../Locations/configs/LocationConfig";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { hasToRefreshOrderFromConnector } from "../../orders/helpers/OrderHelpers";
import orderActions from "../../orders/redux/OrderActions";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

/**
 * Home page with background image, redirect to LocationHome
 */


const LocationHomeComponent: React.FC = () => {

    const { selectedLocation, selectedTable, selectedCatalog } = useTypedSelector((state: RootState) => state.locations);
    const { order, currentOrder: currentOrderState } = useTypedSelector((state: RootState) => state.order);
    const { formToDisplay } = useTypedSelector((state: RootState) => state.customerInformationModal);

    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();

    // Trigger an API call which will eventually refresh the order from the connector
    useEffect(() => {
        if (
            selectedLocation
            && selectedTable
            && selectedCatalog
            && hasToRefreshOrderFromConnector(
                selectedLocation.connector,
                selectedLocation.orders,
                currentOrderState.last_refreshed_at,
                selectedTable.service_type,
            )
        ) {
            dispatch(orderActions.refreshOrderFromConnector(
                selectedLocation.account_id,
                selectedLocation.id,
                selectedCatalog.id ?? "",
                selectedTable.id,
                order.id,
            ));
        }
    }, [selectedLocation, selectedTable, selectedCatalog]);

    let match = useRouteMatch<RouteParams>();
    let url = match.url;
    if (url[url.length - 1] === "/") {
        url = url.slice(0, url.length - 1);
    }
    const history = useHistory();

    const link = () => {
        if (selectedTable.service_type !== SupportedServiceType.CHECKOUT) {
            history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }));
        }
    };

    return (
        <>
            <ImageContainer
                image={selectedLocation?.background_image ? selectedLocation?.background_image : DefaultBackground}
                default={selectedLocation?.background_image ? false : true}
                data-test="locationhome_background"
                onClick={link}
            >

                <List
                    style={{
                        flexGrow: 1,
                        padding: theme.spacing(2),
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >

                    {selectedLocation?.logo &&

                        <Box
                            style={{ margin: theme.spacing(0, 0, 1, 0) }}
                            display="flex"
                            justifyContent="center"
                        >

                            <Box maxWidth={MAX_LOGO_WIDTH}>
                                {selectedLocation?.logo ? (
                                    <img width="100%" src={selectedLocation?.logo} alt="" />
                                ) : (
                                    <img src={LogoImage} alt="" />
                                )}
                            </Box>

                        </Box>
                    }

                    <Typography variant="h1" style={{ color: theme.palette.common.white }}>
                        {intl.formatMessage({ id: "locationhome.welcome" })}
                    </Typography>

                    <Typography variant="h3" style={{ color: theme.palette.common.white }}>
                        {selectedLocation?.name}
                    </Typography>

                </List>

                {!formToDisplay && <Box
                    position="absolute"
                    height={1}
                    mb={10}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Box mb="20px">
                        <Typography variant="subtitle1" style={{ textAlign: "center", color: theme.palette.common.white }}>
                            {intl.formatMessage({ id: "locationhome.orderbutton" }, { br: <br /> })}
                        </Typography>
                    </Box>

                    <img src={LogoArrow} alt="" data-test="locationhome_orderbutton" onClick={link} />

                </Box>}


            </ImageContainer>
        </>
    );
};
export default LocationHomeComponent;
