import { Location } from "../../../model/Location";

export const shouldDisplayWelcomeText = (location?: Location): boolean => {
    if (location && location.theme && location.theme.extended_theme_options &&
        location.theme.extended_theme_options.hide_welcome_title) {
        return false;
    }
    return true;
}

const themeHelper = {
    shouldDisplayWelcomeText
}
export default themeHelper;