import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Categorie } from '../../my-lemonade-library/model/Catalog';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';

type MyProps = { elem: Categorie, selected: boolean, onClick: any };

const SwiperItem = (props: MyProps) => {

    const [opacity, setOpacity] = useState(1);
    const intl = useIntl();
    const theme = useTheme();

    const ref: any = React.useRef();

    const onClick = () => {
        props.onClick(props.elem.ref)
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }

    useEffect(() => {
        if (props.selected) {
            ref.current.scrollIntoView();
        }
    }, [props.selected])


    return (

        <Box
            mr="10px"
            p={theme.spacing(.5, 1, .5, 1)}
            height="30px"
            whiteSpace="nowrap"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                borderRadius: "20px",
                backgroundColor: props.selected ? theme.palette.primary.main : "transparent",
                color: props.selected ? theme.palette.primary.contrastText : theme.palette.secondary.main,
                opacity: opacity,
            }}
            {...{ ref: ref }}
            onClick={onClick}
            onTouchStart={() => setOpacity(0.7)}
            onTouchEnd={() => setOpacity(1)}
            data-test={`category_swipper_${props.elem.ref}`}
        >

            <Typography variant="body1">

                {intl.formatMessage({
                    id: translationService.getCategorytNameTranslationKey(props.elem),
                    defaultMessage: props.elem.name
                })}

            </Typography>

        </Box>


    )

}

SwiperItem.defaultProps = { selected: false, }


export default SwiperItem

