
/**
 * Download data for the order. Can be used to download QR codes or PDF for example.
 * The file name will be prefix_ID
 * @param data the data URI
 * @param prefix ex: QRCode
 * @param orderIdentifier order display id or collection code
 * @param fileName if specified, the prefix and orderIdentifier will be ignored
 */
export const downloadFile = (data: string, prefix?: string, orderIdentifier?: string, fileName?: string) => {
    const a = document.createElement("a");
    a.href = data;
    a.download = fileName ?? `${prefix}_${orderIdentifier}` ?? "Invoice_QRCode";
    a.click();
}