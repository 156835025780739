import log from "loglevel";
import { getIdToken } from "../../authentication/services/AuthenticationService";
import * as ROUTES from "../../config/routes";
import { getApiEndpoint } from "../../config/variables";
import { OrderInBase, PaymentType } from "../../my-lemonade-library/model/Order";
import { PaymentInfos } from "../../my-lemonade-library/model/payments/PaymentInfos";
import { Money } from "../../my-lemonade-library/src/common/models/Money";
import { getPaymentConnectorCreditWalletRoute } from "../../my-lemonade-library/src/payments/configs/PaymentsApiRoutes";

class PaymentService {
    getPaymentPage(tableLinkId: string, paymentTypeInfo?: PaymentInfos, doNotUseSavedCard?: boolean): string | undefined {

        const tableLinkHome = ROUTES.LocationHome.replace(":tableLinkKey", tableLinkId);
        switch (paymentTypeInfo?.payment_type) {

            case PaymentType.STRIPE_CONNECT:
            case PaymentType.STRIPE:
                return tableLinkHome + ROUTES.PaymentStripe;

            case PaymentType.WORLDLINE:
                return tableLinkHome + ROUTES.PaymentWorldline;

            case PaymentType.LYRA:
            case PaymentType.LYRA_MARKETPLACE: {

                let url = tableLinkHome + ROUTES.PaymentLyra;
                if (doNotUseSavedCard) {
                    url += `?${ROUTES.PAYMENT_DO_NOT_USE_SAVED_CARD}=true`;
                }
                return url;
            }

            case PaymentType.EDENRED:
                return tableLinkHome + ROUTES.PaymentEdenred;

            case PaymentType.SWILE:
                return tableLinkHome + ROUTES.PaymentSwile;

            case PaymentType.LYF_WEB:
            case PaymentType.LYF_CB:
            case PaymentType.LYF_IN_APP:
                return tableLinkHome + ROUTES.PaymentLyf;
        }
    }

    async sendInfosToCreditWallet(accountId: string, locationId: string, email: string, phone: string, amount: Money): Promise<OrderInBase> {
        const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${tokenResult?.token}`);

        const fetchUrl = getApiEndpoint() + getPaymentConnectorCreditWalletRoute(accountId, locationId);

        log.debug(`Sending API request to create a credit wallet payment: ${fetchUrl}`);

        const requestOptions: RequestInit = {
            method: "POST",
            headers,
            body: JSON.stringify({
                email,
                phone,
                amount
            })
        };

        const response: Response = await fetch(fetchUrl, requestOptions);
        if (!response.ok) {
            log.error(response);
            throw new Error("Error while sending the payment to the credit wallet");
        }

        const order: OrderInBase = await response.json();
        return order;

    }


}
const paymentService = new PaymentService();
export default paymentService;