export enum UserAccessRole {
    ACCOUNT_MANAGER = "account_manager",
    ACTIVATION_USER = "activation_user",
    LOCATION_ADMIN = "location_admin",
    LOCATION_ENHANCED_USER = "location_enhanced_user",
    LOCATION_MANAGER = "location_manager",
    LOCATION_USER = "location_user",

    WAITER = "waiter",

    /**
     * User role used for patner integrations with limited access
     */
    PARTNER_USER = "partner_user"
}

export const UserAccessRoleValues = Object.values(UserAccessRole);