import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { Box, Button, CircularProgress, FormHelperText, TextField, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getAuthenticationProviderName } from '../authentication/helpers/AuthenticationHelpers';
import { getIdToken } from '../authentication/services/AuthenticationService';
import log from '../Common/services/LogService';
import { getApiEndpoint } from '../config/variables';
import { CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y } from '../customers/configs/CustomerInformationModalConfig';
import { CustomerInformationModalFormToDisplay } from '../customers/models/CustomerInformationModalFormToDisplay';
import { CustomerInformationModalActions } from '../customers/redux/CustomerInformationModalActions';
import { Deal, DealLine, DealSku, PricingEffect } from '../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../my-lemonade-library/model/Location';
import authenticationsApiRoutes, { AUTHENTICATION_PROVIDER_PARAM } from '../my-lemonade-library/src/authentications/configs/AuthenticationApiRoutes';
import { SignInProviders } from '../my-lemonade-library/src/authentications/models/BaseUser';
import SignupRequest from '../my-lemonade-library/src/authentications/models/SignupRequest';
import { moneyToNumber } from '../my-lemonade-library/src/common/models/Money';
import { getDealLinePricingValueString, getDealsAvailableProvider } from '../my-lemonade-library/src/deals/services/DealHelper';
import { Discount } from '../my-lemonade-library/src/discounts/models/Discount';
import discountService from '../my-lemonade-library/src/discounts/service/DiscountService';
import { getRestrictionsArray } from '../my-lemonade-library/src/restrictions/services/RestrictionsService';
import { SESSION_ID_HEADER } from '../my-lemonade-library/src/sessions/services/SessionService';
import translationService from '../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../redux/root-reducer';
import { getDiscountDescriptionIntlValues } from './helpers/DiscountDescriptionHelper';


type ProvidersDealsAndDiscount = {
    [key in SignInProviders]?: {
        deals?: Deal[]
        discounts?: Discount[]
    }
}

interface ProvidersDiscountsProps { }

const ProvidersDiscounts: React.FC<ProvidersDiscountsProps> = (props) => {

    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()

    const { deployment } = useTypedSelector((state: RootState) => state.app)
    const { selectedCatalog, tableLinkId, selectedTable, sessionId } = useTypedSelector((state: RootState) => state.locations)
    const { data } = useTypedSelector((state: RootState) => state.authentication)
    const authenticationProviders = data.location_authentication_config.authentication_providers

    const [signupLoading, setSignupLoading] = useState<boolean>(false);
    const [signupConfirmation, setSignupConfirmation] = useState<string>("");
    const [signupError, setSignupError] = useState<string>("");
    const [providersDealsAndDiscounts, setProvidersDealsAndDiscounts] = useState<ProvidersDealsAndDiscount>({})

    useEffect(() => {
        if (selectedCatalog?.data.discounts && authenticationProviders) {
            const updatedProvidersDealsAndDiscounts: ProvidersDealsAndDiscount = {}
            const discounts = discountService.getDiscountAutoApplyAvailableProvider(selectedCatalog.data.discounts, authenticationProviders)
            if (discounts.length > 0) {
                discounts.forEach(discount => {
                    updatedProvidersDealsAndDiscounts[discount.auth_provider] = {
                        ...updatedProvidersDealsAndDiscounts[discount.auth_provider],
                        discounts: discount.discounts
                    }
                })
            }

            const deals = getDealsAvailableProvider(selectedCatalog.data.deals, authenticationProviders)
            if (deals.length > 0) {
                deals.forEach(deal => {
                    discounts.forEach(discount => {
                        updatedProvidersDealsAndDiscounts[discount.auth_provider] = {
                            ...updatedProvidersDealsAndDiscounts[discount.auth_provider],
                            deals: deal.deals
                        }
                    })
                })
            }

            setProvidersDealsAndDiscounts(updatedProvidersDealsAndDiscounts)
        }
    }, [selectedCatalog?.data.discounts, authenticationProviders])

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(intl.formatMessage({ id: "authentication.email.invalid" }))
                .required(intl.formatMessage({ id: "authentication.email.required" })),
        }),
        onSubmit: (values) => {
        },
    });

    const handleSignupClicked = async (signinProvider: SignInProviders, email: string) => {
        try {
            const signupRequest: SignupRequest = {
                deployment_name: deployment?.name,
                email: email,
                table_link_id: tableLinkId
            };
            const signupUrl = `${getApiEndpoint()}${authenticationsApiRoutes.PROVIDER_SIGNUP_ROUTE.replace(AUTHENTICATION_PROVIDER_PARAM, signinProvider)}`;
            const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${tokenResult?.token}`);
            sessionId && headers.append(SESSION_ID_HEADER, sessionId);
            const requestOptions: RequestInit = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(signupRequest),
            };
            const response = await fetch(signupUrl, requestOptions)
            if (response.ok) {
                setSignupError("");
                setSignupConfirmation("authentication.signin_provider_discount.email_success");
            } else {
                throw new Error("Error sending signup email");
            }

            dispatch(CustomerInformationModalActions.setCustomerInformationModal(
                CustomerInformationModalFormToDisplay.EMAIL_SENT
            ));
            setSignupLoading(false);
        } catch (error) {
            log.error(error);
            setSignupError("authentication.signin_provider_discount.email_error");
            setSignupLoading(false);
        }
    }

    const getDealCategoryName = (deal: Deal) => {
        const dealCategory = selectedCatalog?.data.categories.find(category => category.ref === deal.category_ref)
        return dealCategory?.name ?? ''
    }

    const getSkuName = (dealSku: DealSku) => {
        const translationId = translationService.getSkuNameTranslationKey(dealSku)
        return intl.formatMessage({ id: translationId })
    }

    const isFreeDealLine = (dealLine: DealLine): boolean => {
        if (dealLine.pricing_value) {
            if (typeof dealLine.pricing_value === 'number' && !Number.isNaN(dealLine.pricing_value)) {
                return dealLine.pricing_value === 0
            }

            if (typeof dealLine.pricing_value === 'string') {
                return moneyToNumber(dealLine.pricing_value, false, dealLine) === 0
            }
        }

        return false
    }

    const isButtonDisabled = (): boolean => {
        return (
            signupLoading
            || Boolean(signupConfirmation)
            || Boolean(formik.errors.email)
            || !formik.values.email
        )
    }

    if (Object.keys(providersDealsAndDiscounts).length === 0) {
        return null
    }


    return (

        <Box
            display="flex"
            flexDirection="column"
            width={1}
        >

            {Object.entries(providersDealsAndDiscounts).map(([authProvider, providerDealsAndDiscounts], providerIndex) => {

                const discountNumber = (providerDealsAndDiscounts.discounts?.length ?? 0) + (providerDealsAndDiscounts.deals?.length ?? 0)

                return (

                    <Box
                        key={`authProvider_${providerIndex}`}
                        data-test={`discounts.${authProvider}.box`}
                    >
                        <Box>
                            <Typography variant="caption" align='center'>
                                {intl.formatMessage(
                                    { id: "authentication.authentication_form.welcomAuthentication.signin_provider_discount" },
                                    {
                                        discountProviders: getAuthenticationProviderName(authProvider as SignInProviders),
                                        discountNumber: discountNumber
                                    }
                                )}
                            </Typography>
                        </Box>

                        {/* Provider's discounts */}
                        {providerDealsAndDiscounts.discounts?.map((discount, discountIndex) => {
                            const intlValues = getDiscountDescriptionIntlValues(discount, intl);

                            return (
                                <Box
                                    key={`discount_${discountIndex}`}
                                    data-test={`discounts.${authProvider}.discount.${discount.ref}`}
                                    display='flex'
                                    alignItems='center'
                                    gap={1}
                                    mt={1}
                                >
                                    <TaskAltOutlinedIcon color='primary' />

                                    <Box display="flex" alignItems="baseline">
                                        <Typography variant="caption">

                                            {
                                                discount.description ?? intl.formatMessage({
                                                    id: intlValues.id,
                                                }, { ...intlValues.values, bold: (str: string) => <b>{str}</b> })
                                            }

                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        })
                        }

                        {/* Provider's deals */}
                        {providerDealsAndDiscounts.deals?.map((deal, dealIndex) => {

                            const dealRestrictions = getRestrictionsArray(deal.restrictions);
                            const moreThanOneItem = deal.lines[0].skus.length > 1

                            let allServiceTypes: SupportedServiceType[] | undefined = undefined;
                            dealRestrictions?.forEach((restriction) => {
                                if (restriction.service_types) {
                                    if (!allServiceTypes) {
                                        allServiceTypes = restriction.service_types;
                                    }
                                    else {
                                        allServiceTypes.push(...restriction.service_types);
                                    }
                                }
                            });

                            const serviceTypesString = (allServiceTypes as SupportedServiceType[] | undefined)
                                ?.map((serviceType) =>
                                    intl.formatMessage({ id: `authentication.signin_provider_deal.service_type_${serviceType}` })
                                )
                                .join(` ${intl.formatMessage({ id: "common.or.label" })} `)
                                ?? "";

                            return (
                                <Box
                                    key={`deal_${dealIndex}`}
                                    data-test={`deals.${authProvider}.deal.${deal.ref}`}
                                    display='flex'
                                    alignItems='center'
                                    gap={1}
                                    mt={1}
                                >
                                    <TaskAltOutlinedIcon color='primary' />

                                    {deal.description
                                        ? <Typography>
                                            {deal.description}
                                        </Typography>

                                        : <Box display="flex" alignItems="baseline">
                                            <Typography variant="caption">
                                                {intl.formatMessage({ id: `authentication.authentication_form.welcomAuthentication.signin_provider_deal.description${deal.lines[0].pricing_effect === PricingEffect.EFFECT_FIXED_PRICE ? '.fixed_price' : ''}` },
                                                    {
                                                        value: getDealLinePricingValueString(deal.lines[0]),
                                                        bold: str => <b>{str}</b>,
                                                        number: !_.isNil(deal.usage_restriction?.max_per_customer)
                                                            ? deal.usage_restriction?.max_per_customer
                                                            : 1,
                                                        product: moreThanOneItem ? getDealCategoryName(deal) : getSkuName(deal.lines[0].skus[0]),
                                                        isCategory: moreThanOneItem,
                                                        service_types: serviceTypesString,
                                                        isFree: deal.lines[0].pricing_effect === PricingEffect.EFFECT_FIXED_PRICE
                                                            ? isFreeDealLine(deal.lines[0])
                                                            : undefined
                                                    }
                                                )}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            )
                        })}

                        {selectedTable.service_type === SupportedServiceType.VIEW &&

                            <Box
                                display='flex'
                                id="authentication.provider_advantages.form_container"
                                flexDirection='column'
                                alignItems='center'
                                width={1}
                            >

                                <form style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Box
                                        width="100%"
                                        id="authentication.provider_advantages.form_box"
                                        display='flex'
                                        flexDirection='column'
                                        justifyContent='center'
                                        alignItems='center'
                                        gap={1}
                                    >

                                        <TextField
                                            fullWidth
                                            id='email'
                                            data-test='authentication.provider_advantages.email'
                                            type='email'
                                            variant="standard"
                                            name="email"
                                            placeholder={intl.formatMessage({ id: 'common.your_email' })}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            sx={{
                                                margin: theme.spacing(2, 0, 1),
                                                width: '90%'
                                            }}
                                        />

                                        {signupError &&

                                            <FormHelperText
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    marginBottom: theme.spacing(1),
                                                }}
                                                error
                                            >

                                                <Typography>
                                                    {intl.formatMessage({ id: signupError })}
                                                </Typography>

                                            </FormHelperText>
                                        }

                                        <Button
                                            fullWidth
                                            data-test={`get_coupon_button`}
                                            variant='contained'
                                            disabled={isButtonDisabled()}
                                            disableElevation
                                            color={"primary"}
                                            style={{
                                                textTransform: "none",
                                                padding: theme.spacing(CUSTOMER_INFORMATION_MODAL_BUTTONS_PADDING_Y, 0),
                                            }}
                                            onClick={() => {
                                                if (!Boolean(formik.errors.email) && formik.values.email) {
                                                    setSignupLoading(true);
                                                    handleSignupClicked(authProvider as SignInProviders, formik.values.email)
                                                }
                                            }}
                                        >
                                            {signupLoading && <CircularProgress
                                                data-test='signin_loader'
                                                disableShrink
                                                size="1rem"
                                                style={{
                                                    color: theme.palette.primary.contrastText,
                                                    marginRight: theme.spacing(1)
                                                }}
                                            />}
                                            <Typography variant="subtitle1">
                                                {intl.formatMessage({ id: `authentication.get_coupon` })}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        }
                    </Box >
                )
            })}
        </Box >
    )
}

export default ProvidersDiscounts