enum DeliveryUnavailableReason {
    /**
     * Temporary unavailability of couriers
     */
    NO_MORE_COURIERS_AVAILABLE = 'no_more_couriers_available',

    /**
     * We can't deliver at this place
     */
    AREA_NOT_COVERED = 'area_not_covered',

    DELIVERY_SERVICE_CONFIGURATION_ERROR = 'delivery_zervice_configuration_error',

    DELIVERY_SERVICE_ERROR = 'delivery_service_error'
}
export default DeliveryUnavailableReason;