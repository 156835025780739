import { Box, Button, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import DefaultBackground from '../../assets/bg_home.png';
import { getRestrictionsDayHourDescription } from '../../Common/helper/Restriction';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { getRestrictionsArray } from '../../my-lemonade-library/src/restrictions/services/RestrictionsService';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import Price from '../../Products/component/Price';
import { useTypedSelector } from '../../redux/root-reducer';
import { getDealRoute } from '../configs/DealRoutes';

interface DealsCarouselItemProps {
    item: DealExt;
}

/*
Component Card of the Deals Caroussel component
render by Deals.tsx
*/
export default function DealsCarouselItem(props: DealsCarouselItemProps) {

    const history = useHistory();
    const { selectedLocation, tableLinkId } = useTypedSelector(state => state.locations);
    const theme = useTheme();
    const intl = useIntl();

    const goToDeal = () => {
        history.push(getDealRoute(tableLinkId, props.item));
    }

    const getDealTranslated = (): string => {

        const translationId = translationService.getDealNameTranslationKey(props.item);

        if (translationId && intl.messages[translationId]) {

            return intl.formatMessage({ id: translationId })
        }

        log.debug(`Missing translation for deal name ${props.item.name}`);
        return props.item.name;
    }

    return (
        <Box
            height={180}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            style={{
                backgroundColor: "grey",
                backgroundImage: selectedLocation && selectedLocation.background_image ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) , url(${selectedLocation.background_image})` : `url(${DefaultBackground})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >

            {props.item.starting_price && <Price price={props.item.starting_price} reduced={false} />}

            <Box
                style={{
                    color: theme.palette.common.white
                }}
                textAlign="center"
            >
                <Typography variant="h2">
                    {getDealTranslated()}
                </Typography>
            </Box>

            <Box
                style={{
                    color: theme.palette.common.white,
                    opacity: .8,
                }}
            >
                <Typography variant="body1">
                    {
                        // TODO: do not take the first restriction!!
                        props.item.restrictions && getRestrictionsDayHourDescription(getRestrictionsArray(props.item.restrictions)![0])
                    }
                </Typography>

            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={goToDeal}
                style={{
                    marginTop: theme.spacing(1),
                    textTransform: "none",
                }}
            >
                <Typography>
                    {intl.formatMessage({ id: "CategoriesPage.deals" })}
                </Typography>

            </Button>

        </Box>
    );
}
