export enum RedirectUrlType {
    CONFIRM = "confirm",
}

export enum RedirectUrlFrom {
    PAYMENT_LYRA = "pay_lyra",
    ORDER_SAGA = "order_saga",
}

export interface RedirectUrl {
    url: string;
    replaceHistory: boolean;
    type?: RedirectUrlType;
    from?: RedirectUrlFrom;
}