import { CustomerInfo, CustomerInfoFlowChoice, Location } from "../../../model/Location";
import { OrderInBase } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import BaseUser from "../../authentications/models/BaseUser";
import { Customer } from "../../authentications/models/Customer";
import Address from "../../common/models/Address";
import { addMoney } from "../../common/models/Money";
import Position from "../../common/models/Position";
import { DiscountType } from "../../discounts/models/OrderDiscount";
import CustomerErrors from "../models/CustomerErrors";

/**
 * Copy the order info from order to customer
 * TODO: to be unit tested
 * @param customer 
 * @param order 
 * @returns 
 */
const updateCustomerInfoFromOrder = (customer: Customer, order: OrderInBase, onlyIfContributor: boolean = true): Customer => {

    // Be robust to function beeing called several times
    if (customer.last_order_id !== order.id) {
        if (customer.uid && (!onlyIfContributor || (order.contributors && order.contributors[customer.uid]))) {
            if (!customer.first_order_date) {
                customer.first_order_date = order.updated_at;
            }
            customer.last_order_date = order.updated_at;
            customer.last_order_id = order.id;

            customer.nb_orders = customer.nb_orders ? customer.nb_orders + 1 : 1;
            // TODO: compute the total for each user
            customer.order_total = customer.order_total ? addMoney(customer.order_total, order.total) : order.total;
            // Update the email, address, etc if defined
            if (order.customer && customer.uid === order.customer?.uid) {
                /**
                 * Email, first_name and phone are informations not supposed to change for customer (baseUser informations)
                 * that didn't avoid to get different mail or address etc because the order can hold differents informations
                 */
                if (order.customer.email && !customer.email) {
                    customer.email = order.customer.email;
                }
                if (order.customer.first_name && !customer.first_name) {
                    customer.first_name = order.customer.first_name;
                }
                if (order.customer.last_name && !customer.last_name) {
                    customer.last_name = order.customer.last_name;
                }
                if (order.customer.phone && !customer.phone) {
                    customer.phone = order.customer.phone;
                }
                if (order.customer.address_1) {
                    customer.address_1 = order.customer.address_1;
                }
                if (order.customer.address_2) {
                    customer.address_2 = order.customer.address_2;
                }
                if (order.customer.city) {
                    customer.city = order.customer.city;
                }
                if (order.customer.postal_code) {
                    customer.postal_code = order.customer.postal_code;
                }
                if (order.customer.country) {
                    customer.country = order.customer.country;
                }
                if (order.customer.delivery_notes) {
                    customer.delivery_notes = order.customer.delivery_notes;
                }
                if (order.customer.last_name) {
                    customer.last_name = order.customer.last_name;
                }
                if (order.customer.latitude) {
                    customer.latitude = order.customer.latitude;
                }
                if (order.customer.longitude) {
                    customer.longitude = order.customer.longitude;
                }
            }

            // update the used discounts
            order.discounts?.forEach((orderDiscount) => {
                if (orderDiscount.type !== DiscountType.LOYALTY &&
                    orderDiscount.user_id === customer.uid) {
                    if (!customer.used_discounts) {
                        customer.used_discounts = {}
                    }
                    if (!customer.used_discounts[order.catalog_id]) {
                        customer.used_discounts[order.catalog_id] = {};
                    }
                    const catalogUsedDiscounts = customer.used_discounts[order.catalog_id];
                    let usedCount = 0;
                    if (catalogUsedDiscounts[orderDiscount.ref] && catalogUsedDiscounts[orderDiscount.ref].used_count) {
                        usedCount = catalogUsedDiscounts[orderDiscount.ref].used_count;
                    }
                    catalogUsedDiscounts[orderDiscount.ref] = {
                        last_used_order_id: order.id,
                        last_used_at: order.updated_at ?? new Date(),
                        ref: orderDiscount.ref,
                        used_count: usedCount + 1
                    }
                }
            })

            // Update the used deals
            Object.values(order.deals).forEach((orderDeal) => {

                if (orderDeal.contributor_user_id && customer.uid && orderDeal.contributor_user_id === customer.uid) {

                    if (!customer.used_deals) {
                        customer.used_deals = {};
                    }

                    if (!customer.used_deals[order.catalog_id]) {
                        customer.used_deals[order.catalog_id] = {};
                    }

                    const catalogUsedDeals = customer.used_deals[order.catalog_id];
                    let currentUsedCount = 0;

                    if (catalogUsedDeals[orderDeal.ref]?.used_count) {
                        currentUsedCount = catalogUsedDeals[orderDeal.ref].used_count;
                    }

                    catalogUsedDeals[orderDeal.ref] = {
                        last_used_order_id: order.id,
                        last_used_at: order.updated_at ?? new Date(),
                        ref: orderDeal.ref,
                        used_count: currentUsedCount + 1,
                    }
                }
            });
        }
        else {
            throw CustomerErrors.CUSTOMER_NOT_FOUND_IN_ORDER.withValue({ order_id: order.id, customer_id: customer.uid })
        }
    }
    return customer;
}

export const checkRequireCustomerInfoForUser = (requireCustomerInfo: CustomerInfo, user: { email?: string; emailVerified: boolean; }) => {
    if (requireCustomerInfo.authentication_process !== CustomerInfoFlowChoice.NONE) {
        if (requireCustomerInfo.authentication_mandatory && !user.email) {
            throw OrderError.NOT_AUTHENTICATED_CUSTOMER;
        }

        if (user.email) {
            if (requireCustomerInfo.customer_email_verified) {
                const isVerifiedEmail = user.emailVerified
                if (!isVerifiedEmail) {
                    throw OrderError.CUSTOMER_EMAIL_NOT_VERIFIED
                }
            }

            if (requireCustomerInfo.email_domains_allowed && requireCustomerInfo.email_domains_allowed.length) {
                const userEmail: string | undefined = user.email
                const isEmailAllowed = requireCustomerInfo.email_domains_allowed.find(domain => user.email?.includes(domain))

                if (!userEmail) {
                    throw OrderError.USER_HAVENOT_EMAIL_REGISTER
                }

                if (userEmail && !isEmailAllowed) {
                    throw OrderError.EMAIL_DOMAIN_NOT_ALLOWED
                }
            }
        }
    }
}

export const getCustomerFullName = (order: OrderInBase): string | undefined => {
    if (order.customer) {
        if (order.customer?.first_name) {
            if (order.customer?.last_name) {
                return `${order.customer?.first_name} ${order.customer?.last_name}`;
            } else {
                return order.customer?.first_name;
            }
        } else {
            return order.customer?.last_name;
        }
    }
    return undefined;
}

/**
 * Get customer info from an existing user,
 * Remove information not supposed to exist
 * @param user 
 * @returns 
 */
export const getCustomerFromUser = (user: BaseUser): Customer => {
    const customer: Customer = {
        ...user,
        loyalty_balance: 0,
        nb_orders: 0,
        used_discounts: {},
        used_deals: {}
    }
    delete customer.order_total;
    delete customer.last_order_date;
    delete customer.last_order_id;
    delete customer.first_order_date;
    delete customer.first_order_date;
    delete customer.loyalty_cards;
    return customer;
}

/**
 * Gets an address and a customer object in parameters, and fills the
 * address fields of the customer, extracting them from the address given
 * // TODO: test it
 * @param address 
 * @param customer 
 */
export const getAddressFromCustomer = (customer: Customer | undefined): Address | null => {

    if (
        customer
        && customer.address_1
        && customer.postal_code
        && customer.city
        && customer.country
    ) {

        let position: Position | undefined = undefined;

        if (customer.latitude && customer.longitude) {

            position = {
                latitude: parseFloat(customer.latitude),
                longitude: parseFloat(customer.longitude),
            }
        }

        const address: Address = {
            address_1: customer.address_1,
            address_2: customer.address_2 ? customer.address_2 : undefined,
            postal_code: customer.postal_code,
            city: customer.city,
            country: customer.country,
            position: position ? position : undefined,
        }

        return address;
    }
    else {

        return null;
    }
}

/**
 * Get a full-string address from a Customer object. Similar to "getFullAddressFromAddress"
 * @param customer 
 * @returns 
 */
export const getFullAddressFromCustomer = (customer: Customer | undefined): string | null => {

    const address: Address | null = getAddressFromCustomer(customer);

    if (address) {
        return getFullAddressFromAddress(address);
    }
    else {
        return null;
    }
}

/**
 * Get a full-string address from an Address object. Similar to "getFullAddressFromCustomer"
 * @param address 
 * @returns 
 */
export const getFullAddressFromAddress = (address: Address): string => {

    let addressString: string = "";

    // Check if addtess is valid
    if (address.address_1 && address.postal_code && address.city) {
        addressString += address.address_1;
        addressString += address.address_2 ? " " + address.address_2 : "";
        addressString += ", " + address.postal_code;
        addressString += " " + address.city;
    }

    return addressString;
}

// TODO: new file AddressHelper?
// TODO: test it
export const getAddressFromLocation = (
    location: Pick<Location, "address" | "zipcode" | "country" | "city" | "postal_code" | "position">
): Address | null => {

    if (
        location.address
        && (location.postal_code || location.zipcode)
        && location.city
        && location.country
    ) {

        const address: Address = {
            address_1: location.address,
            postal_code: location.postal_code ?? location.zipcode!,
            city: location.city,
            country: location.country,
        }

        if (location.position) {
            address.position = location.position;
        }

        return address;
    }

    return null;
}

export const customerHelper = {
    updateCustomerInfoFromOrder,
    checkRequireCustomerInfoForUser,
    getCustomerFullName,
    getCustomerFromUser,
    getFullAddressFromCustomer,
}
export default customerHelper;