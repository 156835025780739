import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import log from 'loglevel';
import { DateTime } from 'luxon';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { EnvNames } from '../my-lemonade-library/src/common/configs/EnvNames';

export function extractDateFromTimestamp(date: Date, timezoneName: string): Date | null {
    const timeStamp = date as unknown as firebase.firestore.Timestamp;
    if (timeStamp && typeof timeStamp.toDate === "function") {
        return DateTime.fromJSDate(timeStamp.toDate()).setZone(timezoneName).toJSDate();
    } else {
        log.debug(`Invalid timestamp`, date)
        return null;
    }
}

const FIREBASE_MYLEMONADE_APP = "mylemonade";
const prodConfig = {
    apiKey: "AIzaSyDAFoFU728Td-K4UWYTQxLSj9nwpxTLjVA",
    authDomain: "my-lemonade.firebaseapp.com",
    databaseURL: "https://my-lemonade.firebaseio.com",
    projectId: "my-lemonade",
    storageBucket: "my-lemonade.appspot.com",
    messagingSenderId: "117447382232",
    appId: "1:117447382232:web:bc24fa40fce960652ab3ad",
    measurementId: "G-LBL1MD789G"
};

const devConfig = {
    apiKey: "AIzaSyBV9Yria2rwFvHpPebM0R8Hza6LRGazmnA",
    authDomain: "my-lemonade-dev.firebaseapp.com",
    databaseURL: "https://my-lemonade-dev.firebaseio.com",
    projectId: "my-lemonade-dev",
    storageBucket: "my-lemonade-dev.appspot.com",
    messagingSenderId: "726339339881",
    appId: "1:726339339881:web:8459b53a287338bb28c4f8",
    measurementId: "G-LXZB4HTKVX"
};

// In preprod, use the prod database
const config = (process.env.REACT_APP_ENV === EnvNames.PROD || process.env.REACT_APP_ENV === EnvNames.PREPROD) ? prodConfig : devConfig
if (process.env.REACT_APP_ENV !== EnvNames.PROD) {
    log.warn(`Using env: ${config.projectId}`);
}

let analytics: firebase.analytics.Analytics;
export const setAnalyticsFirebase = (firebaseConfig: any): firebase.analytics.Analytics => {
    const firebaseAnalyticsConfig = firebaseConfig ? firebaseConfig : config;
    let foundApp: firebase.app.App | null = null;
    for (let existingApp of firebase.apps) {
        if (existingApp.name === "[DEFAULT]") {
            foundApp = existingApp;
        }
    }
    // Initialize Firebase if needed
    if (!foundApp) {
        log.info(`Setting analytics firebase with appId: ${firebaseAnalyticsConfig.appId} and measurementId ${firebaseAnalyticsConfig.measurementId}`)
        foundApp = firebase.initializeApp(firebaseAnalyticsConfig, "[DEFAULT]");
    } else {
        log.info(`A default app has already been initialized for analytics`);
    }
    analytics = firebase.analytics(foundApp);
    return analytics;
}

export const firebaseApp = !firebase.apps.length
    ? firebase.initializeApp(config, FIREBASE_MYLEMONADE_APP)
    : firebase.app(FIREBASE_MYLEMONADE_APP);
export const auth = firebase.auth(firebaseApp);
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
export const db = firebase.firestore(firebaseApp);
export const storage = firebase.storage(firebaseApp);
export const rsf = new ReduxSagaFirebase(firebaseApp);
export const firestore = firebaseApp.firestore()
export default firebase;
