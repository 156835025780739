
import moment from "moment";
import { MIN_DEFAULT_PREPARATION_TIME } from "../../../model/Catalog";
import { SupportedServiceType } from "../../../model/Location";
import { Restriction } from "../../restrictions/model/Restriction";
import { CheckTimeMode, getAllowedOrderTimeSlots, getDayNumberFromMoment, getNextSlotStart, isMatchingTemporalRestriction, TIME_FORMAT } from "../../restrictions/services/RestrictionsService";

export const DEFAULT_MAX_COLLECTION_DAYS = 15;
export const COLLECTION_DATE_FORMAT = "YYYY-MM-DD";
// One slot every 15 minutes
export const DEFAULT_COLLECTION_SLOT_MINUTES = 10;

const collectionService = {

    /**
     * Get the list of allowed days given the restrictions
     * @param res The restrictions (e.g. opening days)
     * @param today Starting from today
     * @param maxDays Maximum duration (in terms of number of days after today) for the returned dates (ex: only opening days before 10 days after now)
     * @param minDays Minimum number (in terms of number of days after today) for the returned dates
     */
    getAllowedDays(
        restrictionsList: Restriction[] | undefined,
        today: Date,
        timezone: string,
        serviceType: SupportedServiceType,
        maxDays: number = DEFAULT_MAX_COLLECTION_DAYS,
        minDays: number = 0
    ): moment.Moment[] {

        const openedDays: moment.Moment[] = [];

        // The number of days for which we will display the timeslots. We set it to 
        // maxDays by default. If wee see a value in one or several location restrictions,
        // we override it with the lowest value found.
        let numberOfDays: number = maxDays;

        if (restrictionsList) {

            for (const restriction of restrictionsList) {

                // Only replace if the value is lower than the current one
                if (
                    restriction.timeslots_display_time_limit
                    && restriction.timeslots_display_time_limit >= 1
                    && restriction.timeslots_display_time_limit < numberOfDays
                ) {

                    numberOfDays = restriction.timeslots_display_time_limit;
                }
            }
        }

        // If there was a change, remove one as we want to count today as a day.
        // If we don't do that, setting to 1 would display today AND tomorrow
        if (numberOfDays !== maxDays) {

            numberOfDays--;
        }

        for (let iDay = minDays; iDay <= numberOfDays; iDay++) {

            const dayMoment = moment(today).tz(timezone);
            dayMoment.add(iDay, "d");
            if (!restrictionsList) {
                openedDays.push(dayMoment);
            } else {
                const dayIndex = getDayNumberFromMoment(dayMoment);
                for (let iRestriction = 0; iRestriction < restrictionsList.length; iRestriction++) {
                    const restriction = restrictionsList[iRestriction]
                    if (!restriction.dow || restriction.dow.includes(dayIndex.toString())) {
                        if (isMatchingTemporalRestriction(dayMoment, timezone, restriction, serviceType, iDay === 0 ? CheckTimeMode.MAX_ONLY : CheckTimeMode.NONE, undefined, undefined)) {
                            if (iDay === 0) {
                                const nextSlotStart = getNextSlotStart(dayMoment, timezone, restriction.min_preparation_time, restriction.order_slot_time, restriction.start_time, restriction.end_time);
                                if (nextSlotStart) {
                                    //console.log(`Newt slot ${nextSlotStart.toISOString()} for date ${dayMoment.toISOString()}`);
                                    openedDays.push(dayMoment);
                                    break;
                                }
                            } else {
                                openedDays.push(dayMoment);
                                break;
                            }
                        }
                    }
                }
            }
        }

        return openedDays;
    },

    getFormattedAllowedDays(restrictionsList: Restriction[] | undefined, today: Date, timezone: string, serviceType: SupportedServiceType, maxDays: number = DEFAULT_MAX_COLLECTION_DAYS, minDays: number = 0): string[] {
        return this.getAllowedDays(restrictionsList, today, timezone, serviceType, maxDays, minDays).map((dayMoment) => dayMoment.format(COLLECTION_DATE_FORMAT));
    },

    /**
     * Get allowed time slots given a start time, 
     * @param start_time 
     * @param end_time 
     * @param date Date 
     * @param selectedCatalog 
     */
    getAllowedTimeSlots(
        forDate: moment.Moment,
        timezone: string,
        currentTime: moment.Moment,
        start_time?: string,
        end_time?: string,
        slotDurationInMinutes: number = DEFAULT_COLLECTION_SLOT_MINUTES,
        minPreparationTime: number = MIN_DEFAULT_PREPARATION_TIME,
        deliveryDelay: number = 0
    ): string[] {

        const timeSlots: string[] = []

        const orderTimeSlots = getAllowedOrderTimeSlots(forDate, timezone, currentTime, start_time, end_time, slotDurationInMinutes, minPreparationTime);

        orderTimeSlots.forEach((orderTimeSlot) => {

            timeSlots.push(
                moment(orderTimeSlot.start_time).tz(timezone).add(deliveryDelay, "minutes").format(TIME_FORMAT)
            );
        })

        return timeSlots;
    }
}
export default collectionService;



