import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import orderAction from '../../orders/redux/OrderActions';

/**
 * Fallback Page used to close the modal
 */
function PageCloseModal() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(orderAction.closeModal());

    }, []);

    return (
        <></>
    );
}

export default PageCloseModal;